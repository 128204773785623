import { defineMessages } from "react-intl";
export var messages = defineMessages({
  uploadSelectedMessage: {
    id: "jutro-components.widgets.input.FileUploadField.selected",
    defaultMessage: "Selected"
  },
  uploadProgressMessage: {
    id: "jutro-components.widgets.input.FileUploadField.{completed} of {total}",
    defaultMessage: "{completed} of {total}"
  },
  uploadCompletedMessage: {
    id: "jutro-components.widgets.input.FileUploadField.done",
    defaultMessage: "Done"
  },
  uploadCancelledMessage: {
    id: "jutro-components.widgets.input.FileUploadField.cancelled",
    defaultMessage: "Cancelled"
  },
  uploadFailedMessage: {
    id: "jutro-components.widgets.input.FileUploadField.failed",
    defaultMessage: "Failed"
  },
  backgroundUploadFileMessage: {
    id: "jutro-components.widgets.input.FileUploadField.uploading",
    defaultMessage: "Uploading..."
  },
  removeFileAriaLabel: {
    id: "jutro-components.widgets.input.FileUploadField.removeFileAriaLabel",
    defaultMessage: "Delete {fileName}"
  }
});