import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./Container.module.css";
var containerPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fluid: PropTypes.bool
};
export var Container = function (_ref) {
  var children = _ref.children,
    className = _ref.className,
    fluid = _ref.fluid,
    containerClasses = cx(styles.container, className, _defineProperty({}, styles.fluid, fluid));
  return React.createElement("div", {
    className: containerClasses
  }, children);
};
Container.propTypes = containerPropTypes, Container.__docgenInfo = {
  description: "Container element which wraps some content.",
  methods: [],
  displayName: "Container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The container's content."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Span the entire width."
    }
  }
}, Container.__docgenInfo = {
  componentName: "Container",
  packageName: "@jutro/components",
  description: "Container element which wraps some content.",
  displayName: "Container",
  methods: [],
  actualName: "Container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The container's content."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Span the entire width."
    }
  }
};