import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { log } from "@jutro/logger";
export var DynamicStyleSheet = function () {
  function DynamicStyleSheet() {
    _classCallCheck(this, DynamicStyleSheet);
  }
  return _createClass(DynamicStyleSheet, [{
    key: "mount",
    value: function () {
      var name = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "dynamicStyleSheet";
      this.styleTag ? log.warning("Dynamic stylesheet was already mounted: '".concat(name, "'")) : (this.name = name, this.styleTag = this.createStyleTag(name));
    }
  }, {
    key: "unmount",
    value: function () {
      this.styleTag ? (this.removeStyleTag(this.styleTag), this.styleTag = null) : log.warning("Dynamic stylesheet was already unmounted: '".concat(this.name, "'"));
    }
  }, {
    key: "createStyleTag",
    value: function (name) {
      if (!_Array$from(document.querySelectorAll("style")).some(function (style) {
        return style.title === name;
      })) {
        var style = document.createElement("style");
        return style.setAttribute("title", name), style.setAttribute("type", "text/css"), style.appendChild(document.createTextNode("")), document.head.appendChild(style), style;
      }
    }
  }, {
    key: "removeStyleTag",
    value: function (styleTag) {
      document.head.removeChild(styleTag);
    }
  }, {
    key: "getStyleSheet",
    value: function () {
      return this.styleTag ? this.styleTag.sheet : {};
    }
  }, {
    key: "findRuleIndex",
    value: function (selector) {
      var sheet = this.getStyleSheet(),
        cssRules = _Array$from(sheet.cssRules);
      return _findIndexInstanceProperty(cssRules).call(cssRules, function (rule) {
        return rule.selectorText === selector;
      });
    }
  }, {
    key: "addRule",
    value: function (selector, properties) {
      var _context,
        sheet = this.getStyleSheet(),
        ruleIndex = this.findRuleIndex(selector);
      -1 !== ruleIndex && sheet.deleteRule(ruleIndex);
      var _context3,
        rulePosition = -1 === ruleIndex ? sheet.cssRules.length : ruleIndex,
        propertyStrings = _mapInstanceProperty(_context = _Object$keys(properties)).call(_context, function (propertyKey) {
          var _context2;
          return _concatInstanceProperty(_context2 = "".concat(propertyKey, ": ")).call(_context2, properties[propertyKey]);
        }).join("; ");
      sheet.insertRule ? sheet.insertRule(_concatInstanceProperty(_context3 = "".concat(selector, " { ")).call(_context3, propertyStrings, " }"), rulePosition) : sheet.addRule && sheet.addRule(selector, propertyStrings, rulePosition);
    }
  }, {
    key: "applyRules",
    value: function (styleRules) {
      var _this = this;
      this.styleTag && styleRules && styleRules.forEach(function (_ref) {
        var selector = _ref.selector,
          properties = _ref.properties;
        return _this.addRule(selector, properties);
      });
    }
  }]), DynamicStyleSheet;
}();