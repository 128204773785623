import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import { Accordion } from "../../../../accordion/Accordion";
import { AccordionCard } from "../../../../accordion/AccordionCard";
import { UploadingProgress } from "../../UploadingProgress/UploadingProgress";
import { MultipleFilesUploadStatusBar } from "./MultipleFilesUploadStatusBar";
import { useSortedFileNames } from "../helpers/useSortedFileNames";
import styles from "./MultipleFilesUploadAccordion.module.css";
export var MultipleFilesUploadAccordion = function (_ref) {
  var id = _ref.id,
    uploadedFiles = _ref.uploadedFiles,
    _ref$uploadState = _ref.uploadState,
    uploadState = void 0 === _ref$uploadState ? {} : _ref$uploadState,
    uploadErrors = _ref.uploadErrors,
    filesErrors = _ref.filesErrors,
    _ref$total = _ref.total,
    total = void 0 === _ref$total ? {} : _ref$total,
    _ref$completed = _ref.completed,
    completed = void 0 === _ref$completed ? {} : _ref$completed,
    messageProps = _ref.messageProps,
    onActionClick = _ref.onActionClick,
    disableFileRemoveButton = _ref.disableFileRemoveButton,
    sortedFileNames = useSortedFileNames(uploadedFiles, uploadState, filesErrors);
  return uploadedFiles.length > 0 ? React.createElement(Accordion, {
    id: "".concat(id, "_accordion"),
    accordionStates: ["".concat(id, "_accordionCard")],
    className: styles.statusBarAccordion
  }, React.createElement(AccordionCard, {
    id: "".concat(id, "_accordionCard"),
    renderHeader: function (isOpen) {
      return React.createElement(MultipleFilesUploadStatusBar, {
        isOpen: isOpen,
        uploadedFilesNumber: uploadedFiles.length,
        uploadState: uploadState,
        uploadErrors: _objectSpread(_objectSpread({}, uploadErrors), filesErrors)
      });
    },
    className: styles.statusBarAccordionCard,
    cardBodyClassName: styles.statusBarAccordionCardBody
  }, React.createElement("div", {
    role: "list",
    className: styles.container
  }, _mapInstanceProperty(sortedFileNames).call(sortedFileNames, function (name) {
    var _context, _context2;
    return React.createElement(UploadingProgress, {
      variant: "multiple",
      id: _concatInstanceProperty(_context = "".concat(id)).call(_context, name),
      filename: name,
      messageProps: messageProps,
      state: uploadState[name],
      completed: completed[name],
      total: total[name],
      onActionClick: onActionClick(name),
      errorMessages: _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray((null == uploadErrors ? void 0 : uploadErrors[name]) || []), _toConsumableArray(filesErrors[name] || [])),
      key: name,
      role: "listitem",
      disableFileRemoveButton: disableFileRemoveButton
    });
  })))) : null;
};
MultipleFilesUploadAccordion.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "MultipleFilesUploadAccordion",
  props: {
    uploadState: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    },
    total: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    },
    completed: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    }
  }
}, MultipleFilesUploadAccordion.__docgenInfo = {
  componentName: "MultipleFilesUploadAccordion",
  packageName: "@jutro/components",
  description: "",
  displayName: "MultipleFilesUploadAccordion",
  methods: [],
  actualName: "MultipleFilesUploadAccordion",
  props: {
    uploadState: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    },
    total: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    },
    completed: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    }
  }
};