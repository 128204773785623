import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import { log } from "@jutro/logger";
import { HttpRequest } from "./HttpRequest";
import { fastOptionsMerge } from "./helper";
export var REQUEST_HANDLER_TYPE;
!function (REQUEST_HANDLER_TYPE) {
  REQUEST_HANDLER_TYPE.AUTH = "onAuth", REQUEST_HANDLER_TYPE.FETCH = "onFetch", REQUEST_HANDLER_TYPE.ENCODE = "onEncode", REQUEST_HANDLER_TYPE.ERROR = "onErrorResponse", REQUEST_HANDLER_TYPE.EXCEPTION = "onException", REQUEST_HANDLER_TYPE.RESPONSE = "onResponse", REQUEST_HANDLER_TYPE.TRACE = "onTrace";
}(REQUEST_HANDLER_TYPE || (REQUEST_HANDLER_TYPE = {}));
var REQUEST_HANDLER_TYPE_VALUES = _Object$values(REQUEST_HANDLER_TYPE);
export var HttpRequestBuilder = _createClass(function HttpRequestBuilder() {
  var _this = this,
    baseUrl = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "";
  _classCallCheck(this, HttpRequestBuilder), _defineProperty(this, "baseUrl", void 0), _defineProperty(this, "options", void 0), _defineProperty(this, "handlers", void 0), _defineProperty(this, "addOptions", function (options) {
    return _this.options.push(options), _this;
  }), _defineProperty(this, "addHandler", function (event, callback) {
    return "string" == typeof event ? _includesInstanceProperty(REQUEST_HANDLER_TYPE_VALUES).call(REQUEST_HANDLER_TYPE_VALUES, event) && callback ? _this.handlers.push(_defineProperty({}, event, callback)) : log.warning('Invalid event type "'.concat(event, '" supplied to "HttpRequestBuilder.addHandler()"')) : REQUEST_HANDLER_TYPE_VALUES.some(function (t) {
      return event[t];
    }) ? _this.handlers.push(event) : log.warning('Invalid event type supplied to "HttpRequestBuilder.addHandler()"'), _this;
  }), _defineProperty(this, "build", function () {
    var _context,
      _context2,
      options = _this.options.length ? _reduceInstanceProperty(_context = _this.options).call(_context, function (list, values) {
        return fastOptionsMerge(list, values);
      }) : void 0,
      handlers = _this.handlers.length ? _reduceInstanceProperty(_context2 = _this.handlers).call(_context2, function (list, values) {
        return _objectSpread(_objectSpread({}, list), values);
      }) : void 0;
    return new HttpRequest(_this.baseUrl, options, handlers);
  }), this.baseUrl = baseUrl, this.handlers = [], this.options = [];
});