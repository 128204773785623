import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { getConfigValue } from "@jutro/config";
import { createTSMRequest } from "./tsmHelpers";
export var fetchTsmLanguages = (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(worksetUid, stringGroup) {
  var _context,
    serverUrl,
    stringVersion,
    jutroVersion,
    url,
    httpRequest,
    parameters,
    _args = arguments;
  return _regeneratorRuntime.wrap(function (_context2) {
    for (;;) switch (_context2.prev = _context2.next) {
      case 0:
        return serverUrl = _args.length > 2 && void 0 !== _args[2] ? _args[2] : getConfigValue("JUTRO_TSM_SERVER"), stringVersion = _args.length > 3 ? _args[3] : void 0, jutroVersion = _args.length > 4 ? _args[4] : void 0, url = _concatInstanceProperty(_context = "/api/v2/worksets/".concat(worksetUid, "/string-groups/")).call(_context, stringGroup), httpRequest = createTSMRequest(serverUrl).build(), parameters = {
          jutroVersion: jutroVersion || "8.10.0"
        }, stringVersion && (parameters.version = stringVersion), _context2.next = 9, httpRequest.get(url, parameters);
      case 9:
        return _context2.abrupt("return", _context2.sent.data);
      case 10:
      case "end":
        return _context2.stop();
    }
  }, _callee);
})), function (_x, _x2) {
  return _ref.apply(this, arguments);
});
var _ref;