import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { Icon, InlineLabel, ModalNextContext } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import navBarStyles from "../NavBar/NavBarItem.module.css";
import burgerStyles from "./BurgerMenu.module.css";
import { AppSwitcherModal } from "../AppSwitcher/AppSwitcherModal";
import { BurgerMenuItem } from "./BurgerMenuItem";
var burgerAppSwitcherButtonPropTypes = {
  className: PropTypes.string,
  title: intlMessageShape.isRequired,
  icon: PropTypes.string,
  renderAppSwitcher: PropTypes.func
};
export var BurgerAppSwitcherButton = function (_ref) {
  var title = _ref.title,
    icon = _ref.icon,
    className = _ref.className,
    renderAppSwitcher = _ref.renderAppSwitcher,
    translator = useTranslator(),
    iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: navBarStyles.navBarItemIcon
    }),
    showModal = useContext(ModalNextContext).showModal,
    onClickCallback = useCallback(function () {
      showModal(React.createElement(AppSwitcherModal, {
        title: title,
        renderAppSwitcher: renderAppSwitcher
      }));
    }, [title, renderAppSwitcher]);
  return React.createElement(BurgerMenuItem, {
    id: "burger-app-switcher",
    tag: "button",
    className: className,
    onClick: onClickCallback
  }, React.createElement(InlineLabel, {
    tag: "div",
    icon: iconComponent,
    className: navBarStyles.titleWrapper
  }, React.createElement("span", {
    className: burgerStyles.burgerAppSwitcherButtonTitle
  }, translator(title))));
};
BurgerAppSwitcherButton.propTypes = burgerAppSwitcherButtonPropTypes, BurgerAppSwitcherButton.__docgenInfo = {
  description: "The `BurgerAppSwitcherButton` component is designed as a container for burgerMenu appSwitcherAccordion",
  methods: [],
  displayName: "BurgerAppSwitcherButton",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for component"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Button title"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the title"
    },
    renderAppSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function which takes the 'onRouteClick' callback and returns an AppSwitcher"
    }
  }
}, BurgerAppSwitcherButton.__docgenInfo = {
  componentName: "BurgerAppSwitcherButton",
  packageName: "@jutro/router",
  description: "The `BurgerAppSwitcherButton` component is designed as a container for burgerMenu appSwitcherAccordion",
  displayName: "BurgerAppSwitcherButton",
  methods: [],
  actualName: "BurgerAppSwitcherButton",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for component"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Button title"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the title"
    },
    renderAppSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function which takes the 'onRouteClick' callback and returns an AppSwitcher"
    }
  }
};