import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _repeatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/repeat";
var PART_TYPES_MAP = {
  year: "y",
  month: "M"
};
export function getMonthDatePatternFromLocale(locale) {
  var parts = new Intl.DateTimeFormat(locale.code || "en", {
    month: "2-digit",
    year: "numeric"
  }).formatToParts(new Date("1970-01-02T00:00"));
  return _reduceInstanceProperty(parts).call(parts, function (acc, _ref) {
    var _context,
      type = _ref.type,
      value = _ref.value;
    return type in PART_TYPES_MAP ? acc + _repeatInstanceProperty(_context = PART_TYPES_MAP[type]).call(_context, value.length) : acc + value;
  }, "");
}