import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import textAreaStyles from "./TextAreaField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
export var TextAreaField = function (_FieldComponent) {
  _inherits(TextAreaField, FieldComponent);
  var _super = _createSuper(TextAreaField);
  function TextAreaField(props) {
    var _this;
    return _classCallCheck(this, TextAreaField), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "onKeyPress", function (evt) {
      if ("Enter" === evt.key && evt.altKey) {
        var _context,
          _evt$target = evt.target,
          selectionStart = _evt$target.selectionStart,
          selectionEnd = _evt$target.selectionEnd,
          _this$props$value = _this.props.value,
          value = void 0 === _this$props$value ? "" : _this$props$value,
          newValue = _concatInstanceProperty(_context = "".concat(value.substring(0, selectionStart), "\n")).call(_context, value.substring(selectionEnd));
        _this.textAreaRef.current.value = newValue, _this.textAreaRef.current.selectionEnd = selectionStart + 1, _this.notifyChange(newValue);
      }
    }), _this.textAreaRef = React.createRef(), _this;
  }
  return _createClass(TextAreaField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(TextAreaField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var modifiedProps = _objectSpread(_objectSpread({}, breakpointProps), {}, {
        className: cx(breakpointProps.className, textAreaStyles.textAreaReadOnly)
      });
      return _get(_getPrototypeOf(TextAreaField.prototype), "renderControlReadOnly", this).call(this, modifiedProps);
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var value = breakpointProps.value,
        disabled = breakpointProps.disabled,
        required = breakpointProps.required,
        maxLength = breakpointProps.maxLength,
        rows = breakpointProps.rows,
        controlClassName = breakpointProps.controlClassName,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        fieldUniqueId = this.fieldUniqueId,
        accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields"),
        focusHandlers = this.getInputFocusHandlers(),
        validationMessages = this.getValidationMessages(value),
        messageStyle = this.getValidationMessageStyle(validationMessages),
        classes = cx(inputStyles.input, textAreaStyles.textArea, _defineProperty({
          disabled: disabled
        }, messageStyle, !options.isValid), controlClassName),
        accessibleDisabledProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks(_objectSpread({
          onChange: this.handleChange,
          onKeyPress: this.onKeyPress
        }, focusHandlers))), {}, {
          "aria-disabled": !0,
          "aria-readonly": !0,
          readOnly: !0,
          disabled: void 0
        }) : {};
      return React.createElement("textarea", _extends({
        ref: this.textAreaRef,
        id: fieldUniqueId,
        className: classes,
        value: value || "",
        onChange: this.handleChange,
        onKeyPress: this.onKeyPress
      }, focusHandlers, {
        disabled: disabled,
        required: required,
        maxLength: maxLength,
        rows: rows
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), {
        "data-testid": testId || id
      }, accessibleDisabledProps));
    }
  }]), TextAreaField;
}();
_defineProperty(TextAreaField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  rows: PropTypes.number,
  maxLength: PropTypes.number
})), _defineProperty(TextAreaField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  rows: 3
})), TextAreaField.__docgenInfo = {
  description: "Renders a multi-line text area.\n@typedef {typeof TextAreaField.propTypes} TextAreaFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<TextAreaFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "renderControlReadOnly",
    docblock: "Render readonly control for TextArea.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for TextArea."
  }, {
    name: "onKeyPress",
    docblock: "Custom onKeyPress handler for `textarea` element. Allows to break line when pressing Alt/Option + Enter. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom onKeyPress handler for `textarea` element. Allows to break line when pressing Alt/Option + Enter. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "TextAreaField",
  props: {
    rows: {
      defaultValue: {
        value: "3",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "The rows attribute specifies the visible height of a text area, in lines."
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Restricts the max length of the text in the text area field"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, TextAreaField.__docgenInfo = {
  componentName: "TextAreaField",
  packageName: "@jutro/components",
  description: "Renders a multi-line text area.",
  displayName: "TextAreaField",
  methods: [{
    name: "renderControlReadOnly",
    docblock: "Render readonly control for TextArea.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for TextArea."
  }, {
    name: "onKeyPress",
    docblock: "Custom onKeyPress handler for `textarea` element. Allows to break line when pressing Alt/Option + Enter. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom onKeyPress handler for `textarea` element. Allows to break line when pressing Alt/Option + Enter. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "TextAreaField",
  metadataType: "field",
  props: {
    rows: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The rows attribute specifies the visible height of a text area, in lines.",
      defaultValue: {
        value: "3",
        computed: !1
      }
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Restricts the max length of the text in the text area field"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};