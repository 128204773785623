import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import { log } from "@jutro/logger";
var errorCategories,
  errorCategoryKnockoutPaths,
  defaultErrorCategory = {};
export function getErrorCategory(errorCode) {
  if (errorCategories && errorCategories[errorCode]) {
    var errorCategory = errorCategories[errorCode],
      categoryMapping = errorCategory.categoryMapping,
      appErrorCode = errorCategory.appErrorCode,
      knockoutPath = function (errorCategory, knockoutPaths) {
        var knockoutPath = knockoutPaths[errorCategory];
        return knockoutPath || log.warning("Missing KnockoutPath configuration for error category: ".concat(errorCategory)), knockoutPath;
      }(categoryMapping, errorCategoryKnockoutPaths);
    return {
      categoryMapping: categoryMapping,
      appErrorCode: appErrorCode,
      knockoutPath: knockoutPath
    };
  }
  return defaultErrorCategory;
}
export function setErrorCategoryKnockoutMaps(appErrorCategories, appKnockoutPaths) {
  if (!(appErrorCategories instanceof Object)) throw new TypeError("Expected an object for 'errorCategories', got a '".concat(_typeof(appErrorCategories), "' instead"));
  if (errorCategories = appErrorCategories, !(appKnockoutPaths instanceof Object)) throw new TypeError("Expected an object for 'knockoutPaths', got a '".concat(_typeof(appKnockoutPaths), "' instead"));
  errorCategoryKnockoutPaths = appKnockoutPaths;
}