import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "title", "footerLink", "renderTrigger", "children", "noDeprecationWarnings"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import PropTypes from "prop-types";
import { intlMessageShape, linkShape } from "@jutro/prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import { isIntlShape } from "@jutro/locale";
import { Popover } from "../Popover/Popover";
import { PopoverContainer } from "../PopoverContainer/PopoverContainer";
import styles from "./HelpPopover.module.css";
import { messages } from "./HelpPopover_DEPRECATED.messages";
var helpPopoverPropTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: intlMessageShape,
  footerLink: PropTypes.oneOfType([intlMessageShape, linkShape]),
  renderTrigger: PropTypes.func.isRequired
};
export var HelpPopover = function (_ref) {
  var _context,
    id = _ref.id,
    _ref$title = _ref.title,
    title = void 0 === _ref$title ? messages.title : _ref$title,
    footerLink = _ref.footerLink,
    renderTrigger = _ref.renderTrigger,
    children = _ref.children,
    noDeprecationWarnings = _ref.noDeprecationWarnings,
    popoverProps = _objectWithoutProperties(_ref, _excluded);
  return useDeprecationWarning({
    componentName: "HelpPopover",
    noDeprecationWarnings: noDeprecationWarnings
  }), footerLink = "string" == typeof footerLink || isIntlShape(footerLink) ? {
    label: messages.viewAll,
    href: footerLink
  } : footerLink, React.createElement(Popover, {
    id: id,
    renderTrigger: renderTrigger,
    className: styles.HelpPopover,
    isFlipEnabled: !1
  }, React.createElement(PopoverContainer, _extends({
    title: title,
    footerLink: footerLink,
    className: styles.popoverContainer,
    internalClassNames: {
      body: styles.content,
      footer: styles.footer
    }
  }, popoverProps), _mapInstanceProperty(_context = React.Children).call(_context, children, function (child) {
    if (React.isValidElement(child)) {
      var _child$props$noDeprec,
        _context2,
        prev = null !== (_child$props$noDeprec = child.props.noDeprecationWarnings) && void 0 !== _child$props$noDeprec ? _child$props$noDeprec : [];
      return React.cloneElement(child, {
        noDeprecationWarnings: _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(prev), _toConsumableArray(null != noDeprecationWarnings ? noDeprecationWarnings : []))
      });
    }
    return child;
  })));
};
HelpPopover.propTypes = helpPopoverPropTypes, HelpPopover.defaultProps = _objectSpread(_objectSpread({}, Popover.defaultProps), {}, {
  title: messages.title
}), HelpPopover.__docgenInfo = {
  description: "@type {React.FC<PropTypes.InferProps<typeof helpPopoverPropTypes>>}\n@deprecated since v8.9.0",
  methods: [],
  displayName: "HelpPopover",
  props: {
    title: {
      defaultValue: {
        value: "messages.title",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Notification popover title"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Help popover content"
    },
    footerLink: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "intlMessageShape"
        }, {
          name: "custom",
          raw: "linkShape"
        }]
      },
      required: !1,
      description: "Description for the footer link"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    }
  }
}, HelpPopover.__docgenInfo = {
  componentName: "HelpPopover",
  packageName: "@jutro/components",
  description: "",
  displayName: "HelpPopover",
  methods: [],
  actualName: "HelpPopover",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Help popover content"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Notification popover title",
      defaultValue: {
        value: "messages.title",
        computed: !0
      }
    },
    footerLink: {
      type: {
        name: "union",
        value: [{
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }, {
          name: "shape",
          value: {
            label: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: !0
            },
            onClick: {
              name: "func",
              required: !1
            },
            href: {
              name: "custom",
              raw: "intlMessageShape",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Description for the footer link"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    }
  }
};