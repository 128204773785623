import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { createContext, memo, useMemo, useReducer } from "react";
import noop from "lodash/noop";
var messagesInitialState = {
    messages: {},
    isMessagesLoaded: !1,
    isMessagesLoadedTSM: !1,
    languagesLoaded: !1
  },
  messagesReducer = function (state, action) {
    switch (action.type) {
      case "languagesLoaded":
        return _objectSpread(_objectSpread({}, state), {}, {
          languagesLoaded: !0
        });
      case "messagesLoaded":
        return _objectSpread(_objectSpread({}, state), {}, {
          isMessagesLoaded: !0,
          messages: action.payload
        });
      case "messagesLoadedTSM":
        return _objectSpread(_objectSpread({}, state), {}, {
          isMessagesLoadedTSM: !0,
          messages: _objectSpread(_objectSpread({}, state.messages), action.payload)
        });
      default:
        return state;
    }
  };
export var MessagesContext = createContext({
  messagesState: messagesInitialState,
  messagesDispatch: noop
});
var MessagesContextProviderInternal = function (_ref) {
  var children = _ref.children,
    _useReducer = useReducer(messagesReducer, messagesInitialState),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    messagesState = _useReducer2[0],
    messagesDispatch = _useReducer2[1],
    context = useMemo(function () {
      return {
        messagesState: messagesState,
        messagesDispatch: messagesDispatch
      };
    }, [messagesState]);
  return React.createElement(MessagesContext.Provider, {
    value: context
  }, children);
};
MessagesContextProviderInternal.displayName = "MessagesContextProvider";
export var MessagesContextProvider = memo(MessagesContextProviderInternal);
MessagesContextProviderInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "MessagesContextProvider"
}, MessagesContextProviderInternal.__docgenInfo = {
  componentName: "MessagesContextProvider",
  packageName: "@jutro/app",
  description: "",
  displayName: "MessagesContextProvider",
  methods: [],
  actualName: "MessagesContextProviderInternal"
};