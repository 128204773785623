import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "selectProps"];
import React, { useRef } from "react";
import { useTranslator } from "@jutro/locale";
import { messages } from "../LookupField.messages";
import { GenericSelectComponents as components } from "../../GenericSelectControl/GenericSelectControl";
export var LookupMenu = function (_ref) {
  var children = _ref.children,
    selectProps = _ref.selectProps,
    props = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    showRecentBar = useRef(!0),
    showRecent = selectProps.showRecent,
    recentlyViewedMessage = selectProps.recentlyViewedMessage,
    customStyles = selectProps.customStyles;
  return showRecentBar.current && (showRecentBar.current = showRecent), React.createElement(components.Menu, _extends({}, props, {
    selectProps: selectProps
  }), showRecentBar.current && React.createElement("div", {
    className: customStyles.recentBar
  }, translator(recentlyViewedMessage || messages.recentlyViewed)), children);
};
LookupMenu.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupMenu"
}, LookupMenu.__docgenInfo = {
  componentName: "LookupMenu",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupMenu",
  methods: [],
  actualName: "LookupMenu"
};