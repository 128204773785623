import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { publish, JUTRO_TOPICS } from "@jutro/events";
var routeTrackerPropTypes = {
  track: PropTypes.func
};
export var RouteTracker = function (_ref) {
  var track = _ref.track,
    history = useHistory(),
    handleRouteChange = useCallback(function (params, state) {
      track && track(params, state), publish(JUTRO_TOPICS.ROUTE_CHANGED, {
        pathname: params.pathname,
        state: state
      });
    }, [track]);
  return useEffect(function () {
    if (history) return "REPLACE" === history.action && handleRouteChange(history.location, history.action), history.listen(handleRouteChange);
    throw new Error("RouteTracker needs to be inside Router component");
  }, [handleRouteChange, history]), React.createElement(React.Fragment, null);
};
RouteTracker.propTypes = routeTrackerPropTypes, RouteTracker.__docgenInfo = {
  description: "RouteTracker is a component that will register a listener with route history.\nDrop into any component render that is contained in a <Router>.\n\n@example\n<RouteTracker track={this.callback} />",
  methods: [],
  displayName: "RouteTracker",
  props: {
    track: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback function(location, action)"
    }
  }
}, RouteTracker.__docgenInfo = {
  componentName: "RouteTracker",
  packageName: "@jutro/router",
  description: "RouteTracker is a component that will register a listener with route history.\nDrop into any component render that is contained in a <Router>.",
  displayName: "RouteTracker",
  methods: [],
  actualName: "RouteTracker",
  props: {
    track: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback function(location, action)"
    }
  }
};