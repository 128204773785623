import { defineMessages } from "react-intl";
export var messages = defineMessages({
  dd: {
    id: "jutro-components.widgets.inputs.DatePicker.dayFormat",
    defaultMessage: "dd"
  },
  mm: {
    id: "jutro-components.widgets.inputs.DatePicker.monthFormat",
    defaultMessage: "MM"
  },
  yy: {
    id: "jutro-components.widgets.inputs.DatePicker.yearFormat",
    defaultMessage: "yyyy"
  },
  incorrectInput: {
    id: "jutro-components.widgets.inputs.Incorrect input",
    defaultMessage: "Incorrect input"
  },
  validationMinDate: {
    id: "jutro-components.widgets.inputs.DatePicker.validationMinDate",
    defaultMessage: "The minimum allowed date is {minDate}"
  },
  validationMaxDate: {
    id: "jutro-components.widgets.inputs.DatePicker.validationMaxDate",
    defaultMessage: "The maximum allowed date is {maxDate}"
  },
  previous: {
    id: "jutro-components.widgets.inputs.DatePicker.previous",
    defaultMessage: "Previous"
  },
  next: {
    id: "jutro-components.widgets.inputs.DatePicker.next",
    defaultMessage: "Next"
  },
  openCalendar: {
    id: "jutro-components.widgets.inputs.DatePicker.openCalendar",
    defaultMessage: "Open calendar"
  },
  selectYear: {
    id: "jutro-components.widgets.inputs.DatePicker.selectYear",
    defaultMessage: "Select year"
  },
  selectMonth: {
    id: "jutro-components.widgets.inputs.DatePicker.selectMonth",
    defaultMessage: "Select month"
  }
});