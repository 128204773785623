import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button } from "@jutro/components";
import styles from "./Pagination.module.css";
export var NavButton = function (_ref) {
  var onClick = _ref.onClick,
    children = _ref.children,
    className = _ref.className,
    icon = _ref.icon,
    iconPosition = _ref.iconPosition;
  return React.createElement(Button, {
    className: cx(styles.paginationNavButton, className),
    type: "text",
    icon: icon,
    iconPosition: iconPosition,
    onClick: onClick
  }, children);
};
NavButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func
}, NavButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NavButton",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when NavButton is clicked"
    }
  }
}, NavButton.__docgenInfo = {
  componentName: "NavButton",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "NavButton",
  methods: [],
  actualName: "NavButton",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when NavButton is clicked"
    }
  }
};