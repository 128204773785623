import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["placeholder"],
  _excluded2 = ["placeholder", "value"];
import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber as IntlFormattedNumber } from "react-intl";
import { isEmptyValue } from "@jutro/data";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { FieldComponent } from "../inputs/FieldComponent/FieldComponent";
function getFormattedNumberProps(props, number) {
  var placeholder = props.placeholder,
    format = props.format,
    value = props.value,
    showGrouping = props.showGrouping,
    showFractions = props.showFractions,
    maximumFractionDigits = props.maximumFractionDigits,
    valueToFormat = number || value;
  return !isEmptyValue(valueToFormat) ? {
    value: valueToFormat,
    style: format,
    useGrouping: showGrouping,
    minimumFractionDigits: 0,
    maximumFractionDigits: showFractions ? maximumFractionDigits : 0
  } : {
    placeholder: null != placeholder ? placeholder : FieldComponent.defaultROEmptyValue
  };
}
export var formattedNumberPropTypes = {
  placeholder: intlMessageShape,
  format: PropTypes.oneOf(["decimal", "percent"]),
  showFractions: PropTypes.bool,
  maximumFractionDigits: PropTypes.number,
  showGrouping: PropTypes.bool,
  value: PropTypes.number
};
export var FormattedNumber = function (props) {
  var _getFormattedNumberPr = getFormattedNumberProps(props),
    placeholder = _getFormattedNumberPr.placeholder,
    formattedNumberProps = _objectWithoutProperties(_getFormattedNumberPr, _excluded),
    translator = useTranslator();
  return placeholder ? React.createElement(React.Fragment, null, translator(placeholder)) : React.createElement(IntlFormattedNumber, formattedNumberProps);
};
FormattedNumber.propTypes = formattedNumberPropTypes, FormattedNumber.defaultProps = {
  format: "decimal",
  placeholder: FieldComponent.defaultROEmptyValue,
  showGrouping: !0,
  maximumFractionDigits: 3
};
export function formatNumber(intl, props, number) {
  var _getFormattedNumberPr2 = getFormattedNumberProps(props, number),
    placeholder = _getFormattedNumberPr2.placeholder,
    value = _getFormattedNumberPr2.value,
    formattedNumberProps = _objectWithoutProperties(_getFormattedNumberPr2, _excluded2);
  return placeholder || intl.formatNumber(value, formattedNumberProps);
}
FormattedNumber.__docgenInfo = {
  description: "The `FormattedNumber` component is used to render number value localized.\n\n@type {React.FC<PropTypes.InferProps<typeof formattedNumberPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FormattedNumber",
  props: {
    format: {
      defaultValue: {
        value: "'decimal'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'decimal'",
          computed: !1
        }, {
          value: "'percent'",
          computed: !1
        }]
      },
      required: !1,
      description: "How to display the number in this format, 'decimal' or 'percentage'"
    },
    placeholder: {
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The string to display in the UI if 'value' is undefined/null"
    },
    showGrouping: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays grouping separators with the value"
    },
    maximumFractionDigits: {
      defaultValue: {
        value: "3",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of fraction digits"
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays fractions with the value"
    },
    value: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The numeric value to display"
    }
  }
}, FormattedNumber.__docgenInfo = {
  componentName: "FormattedNumber",
  packageName: "@jutro/components",
  description: "The `FormattedNumber` component is used to render number value localized.",
  displayName: "FormattedNumber",
  methods: [],
  actualName: "FormattedNumber",
  metadataType: "element",
  props: {
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The string to display in the UI if 'value' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      }
    },
    format: {
      type: {
        name: "enum",
        value: [{
          value: "'decimal'",
          computed: !1
        }, {
          value: "'percent'",
          computed: !1
        }]
      },
      required: !1,
      description: "How to display the number in this format, 'decimal' or 'percentage'",
      defaultValue: {
        value: "'decimal'",
        computed: !1
      }
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays fractions with the value"
    },
    maximumFractionDigits: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of fraction digits",
      defaultValue: {
        value: "3",
        computed: !1
      }
    },
    showGrouping: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays grouping separators with the value",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    value: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The numeric value to display"
    }
  }
};