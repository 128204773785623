import { defineMessages } from "react-intl";
export var messages = defineMessages({
  completed: {
    id: "jutro-components.widgets.progressIndicator.status.completed",
    defaultMessage: "completed"
  },
  inProgress: {
    id: "jutro-components.widgets.progressIndicator.status.inProgress",
    defaultMessage: "{progress} percent completed"
  },
  visited: {
    id: "jutro-components.widgets.progressIndicator.progressStep.status.visited",
    defaultMessage: "visited"
  },
  ariaLabel: {
    id: "jutro-components.widgets.progressIndicator.ariaLabel",
    defaultMessage: "{progressTitle} {progressLabel}"
  }
});