import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["title", "readOnly", "onEditClick", "onSaveClick", "onCancelClick", "isEditMode", "isSaveEnabled", "labels", "internalClassNames"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { Flex, FlexItem, useBreakpoint } from "@jutro/layout";
import { Button } from "../../button/Button";
import { ActionTitleBar } from "./ActionTitleBar";
import { TitleElement } from "./TitleElement";
import styles from "./ActionTitleBar.module.css";
var ViewModeAction = function (_ref) {
    var onEditClick = _ref.onEditClick,
      label = _ref.label,
      buttonClassName = _ref.buttonClassName,
      translator = useTranslator();
    return React.createElement(Button, {
      className: null != buttonClassName ? buttonClassName : void 0,
      onClick: onEditClick
    }, translator(label));
  },
  EditModeAction = function (_ref2) {
    var isSaveEnabled = _ref2.isSaveEnabled,
      labels = _ref2.labels,
      onSaveClick = _ref2.onSaveClick,
      onCancelClick = _ref2.onCancelClick,
      buttonClassName = _ref2.buttonClassName,
      translator = useTranslator();
    return React.createElement(Flex, {
      gap: "small"
    }, React.createElement(FlexItem, {
      grow: "1"
    }, React.createElement(Button, {
      className: null != buttonClassName ? buttonClassName : void 0,
      type: "outlined",
      onClick: onCancelClick
    }, translator(labels.cancel))), React.createElement(FlexItem, {
      grow: "1"
    }, React.createElement(Button, {
      className: null != buttonClassName ? buttonClassName : void 0,
      onClick: onSaveClick,
      disabled: !isSaveEnabled
    }, translator(labels.save))));
  },
  EditCancelSaveActions = function (_ref3) {
    var onEditClick = _ref3.onEditClick,
      onSaveClick = _ref3.onSaveClick,
      onCancelClick = _ref3.onCancelClick,
      isEditMode = _ref3.isEditMode,
      labels = _ref3.labels,
      isSaveEnabled = _ref3.isSaveEnabled,
      internalClassNames = _ref3.internalClassNames;
    return isEditMode ? React.createElement(EditModeAction, {
      isSaveEnabled: isSaveEnabled,
      labels: labels,
      onSaveClick: onSaveClick,
      onCancelClick: onCancelClick,
      buttonClassName: null == internalClassNames ? void 0 : internalClassNames.button
    }) : React.createElement(ViewModeAction, {
      onEditClick: onEditClick,
      label: labels.edit,
      buttonClassName: null == internalClassNames ? void 0 : internalClassNames.button
    });
  };
export var EditCancelSaveTitleBar = function (props) {
  var breakpointProps = useBreakpoint(props).breakpointProps,
    title = breakpointProps.title,
    _breakpointProps$read = breakpointProps.readOnly,
    readOnly = void 0 !== _breakpointProps$read && _breakpointProps$read,
    onEditClick = breakpointProps.onEditClick,
    onSaveClick = breakpointProps.onSaveClick,
    onCancelClick = breakpointProps.onCancelClick,
    isEditMode = breakpointProps.isEditMode,
    isSaveEnabled = breakpointProps.isSaveEnabled,
    labels = breakpointProps.labels,
    internalClassNames = breakpointProps.internalClassNames,
    rest = _objectWithoutProperties(breakpointProps, _excluded),
    translator = useTranslator();
  return React.createElement(ActionTitleBar, rest, React.createElement(TitleElement, null, React.createElement("h4", {
    className: cx(styles.title, null == internalClassNames ? void 0 : internalClassNames.title)
  }, translator(title))), readOnly ? null : React.createElement(EditCancelSaveActions, {
    onEditClick: onEditClick,
    onSaveClick: onSaveClick,
    onCancelClick: onCancelClick,
    isEditMode: isEditMode,
    labels: labels,
    isSaveEnabled: isSaveEnabled,
    internalClassNames: internalClassNames
  }));
};
var editCancelSaveTitleBarBasePropTypes = {
  className: PropTypes.string,
  title: intlMessageShape,
  readOnly: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    edit: intlMessageShape.isRequired,
    save: intlMessageShape.isRequired,
    cancel: intlMessageShape.isRequired
  }).isRequired,
  isEditMode: PropTypes.bool,
  isSaveEnabled: PropTypes.bool,
  internalClassNames: PropTypes.shape({
    button: PropTypes.string
  })
};
EditCancelSaveTitleBar.propTypes = _objectSpread(_objectSpread({}, editCancelSaveTitleBarBasePropTypes), {}, {
  phone: PropTypes.shape(editCancelSaveTitleBarBasePropTypes),
  phoneWide: PropTypes.shape(editCancelSaveTitleBarBasePropTypes),
  tablet: PropTypes.shape(editCancelSaveTitleBarBasePropTypes)
}), EditCancelSaveTitleBar.displayName = "EditCancelSaveTitleBar", EditCancelSaveTitleBar.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "EditCancelSaveTitleBar",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Title to render inside title bar"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true action buttons are not rendered"
    },
    onEditClick: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback invoked on edit button click, () => void"
    },
    onSaveClick: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback invoked on save button click, () => void"
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback invoked on cancel button click, () => void"
    },
    labels: {
      type: {
        name: "shape",
        value: {
          edit: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          },
          save: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          },
          cancel: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          }
        }
      },
      required: !0,
      description: "Labels for rendered buttons"
    },
    isEditMode: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true save and cancel buttons will be rendered"
    },
    isSaveEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Enables the save button"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          button: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "ClassNames for internal components"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title to render inside title bar",
            required: !1
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: !1
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: !0
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: !0
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: !0
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              },
              save: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              },
              cancel: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              }
            },
            description: "Labels for rendered buttons",
            required: !0
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: !1
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: !1
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: !1
              }
            },
            description: "ClassNames for internal components",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title to render inside title bar",
            required: !1
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: !1
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: !0
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: !0
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: !0
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              },
              save: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              },
              cancel: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              }
            },
            description: "Labels for rendered buttons",
            required: !0
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: !1
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: !1
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: !1
              }
            },
            description: "ClassNames for internal components",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          title: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Title to render inside title bar",
            required: !1
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: !1
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: !0
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: !0
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: !0
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              },
              save: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              },
              cancel: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                required: !0
              }
            },
            description: "Labels for rendered buttons",
            required: !0
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: !1
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: !1
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: !1
              }
            },
            description: "ClassNames for internal components",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, ViewModeAction.__docgenInfo = {
  componentName: "ViewModeAction",
  packageName: "@jutro/components",
  description: "",
  displayName: "ViewModeAction",
  methods: [],
  actualName: "ViewModeAction"
}, EditModeAction.__docgenInfo = {
  componentName: "EditModeAction",
  packageName: "@jutro/components",
  description: "",
  displayName: "EditModeAction",
  methods: [],
  actualName: "EditModeAction"
}, EditCancelSaveActions.__docgenInfo = {
  componentName: "EditCancelSaveActions",
  packageName: "@jutro/components",
  description: "",
  displayName: "EditCancelSaveActions",
  methods: [],
  actualName: "EditCancelSaveActions"
}, EditCancelSaveTitleBar.__docgenInfo = {
  componentName: "EditCancelSaveTitleBar",
  packageName: "@jutro/components",
  description: "",
  displayName: "EditCancelSaveTitleBar",
  methods: [],
  actualName: "EditCancelSaveTitleBar",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Title to render inside title bar"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true action buttons are not rendered"
    },
    onEditClick: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback invoked on edit button click, () => void"
    },
    onSaveClick: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback invoked on save button click, () => void"
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback invoked on cancel button click, () => void"
    },
    labels: {
      type: {
        name: "shape",
        value: {
          edit: {
            name: "union",
            required: !0,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          save: {
            name: "union",
            required: !0,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          cancel: {
            name: "union",
            required: !0,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !0,
      description: "Labels for rendered buttons"
    },
    isEditMode: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true save and cancel buttons will be rendered"
    },
    isSaveEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Enables the save button"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          button: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "ClassNames for internal components"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          title: {
            name: "union",
            description: "Title to render inside title bar",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: !1
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: !0
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: !0
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: !0
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              save: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              cancel: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Labels for rendered buttons",
            required: !0
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: !1
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: !1
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: !1
              }
            },
            description: "ClassNames for internal components",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          title: {
            name: "union",
            description: "Title to render inside title bar",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: !1
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: !0
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: !0
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: !0
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              save: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              cancel: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Labels for rendered buttons",
            required: !0
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: !1
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: !1
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: !1
              }
            },
            description: "ClassNames for internal components",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          title: {
            name: "union",
            description: "Title to render inside title bar",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: !1
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: !0
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: !0
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: !0
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              save: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              },
              cancel: {
                name: "union",
                required: !0,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: !1
                    },
                    defaultMessage: {
                      name: "string",
                      required: !1
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: !1
                    }
                  }
                }]
              }
            },
            description: "Labels for rendered buttons",
            required: !0
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: !1
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: !1
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: !1
              }
            },
            description: "ClassNames for internal components",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};