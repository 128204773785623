import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import { gaps } from "@jutro/layout";
import PropTypes from "prop-types";
export var layoutShape = PropTypes.shape({
  repeat: PropTypes.oneOfType([PropTypes.oneOf(["auto-fit", "auto-fill"]), PropTypes.number, PropTypes.string]),
  gap: PropTypes.oneOf(_Object$keys(gaps)),
  columns: PropTypes.array,
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
});
export var layoutSizes = {
  default: "1224px",
  expanded: "1392px",
  narrow: "720px"
};