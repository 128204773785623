import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _URL from "@babel/runtime-corejs3/core-js-stable/url";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import { HttpTransport } from "./HttpTransport";
import { defaultEncoder } from "./defaults/defaultEncoder";
import { defaultResponseHandler } from "./defaults/defaultResponseHandler";
import { defaultErrorResponseHandler } from "./defaults/defaultErrorResponseHandler";
import { defaultExceptionHandler } from "./defaults/defaultExceptionHandler";
import { fastOptionsMerge } from "./helper";
function buildUrl(url, baseUrl) {
  return baseUrl ? new _URL(url, baseUrl).toString() : url;
}
export var HttpRequest = _createClass(function HttpRequest(baseUrl, defaultOptions, handlerOverrides) {
  var _this = this;
  _classCallCheck(this, HttpRequest), _defineProperty(this, "baseUrl", void 0), _defineProperty(this, "defaultOptions", void 0), _defineProperty(this, "transport", void 0), _defineProperty(this, "handlers", void 0), _defineProperty(this, "onEncode", void 0), _defineProperty(this, "get", function (url, params, optionOverrides) {
    var _context,
      requestOptions = fastOptionsMerge(_this.defaultOptions, optionOverrides),
      requestUrl = _concatInstanceProperty(_context = "".concat(buildUrl(url, _this.baseUrl))).call(_context, function (params) {
        if (!params) return "";
        var fakeUrl = new _URL("http://www.gw.com");
        return _Object$keys(params).forEach(function (key) {
          var param = params[key];
          (Array.isArray(param) ? param : [param]).forEach(function (arrayValue) {
            return fakeUrl.searchParams.append(key, arrayValue);
          });
        }), fakeUrl.search;
      }(params));
    return _this.transport.fetch(requestUrl, "GET", requestOptions);
  }), _defineProperty(this, "post", function (url, data, optionOverrides) {
    return _this.request(url, "POST", data, optionOverrides);
  }), _defineProperty(this, "put", function (url, data, optionOverrides) {
    return _this.request(url, "PUT", data, optionOverrides);
  }), _defineProperty(this, "patch", function (url, data, optionOverrides) {
    return _this.request(url, "PATCH", data, optionOverrides);
  }), _defineProperty(this, "delete", function (url, data, optionOverrides) {
    return _this.request(url, "DELETE", data, optionOverrides);
  }), _defineProperty(this, "request", function (url, method, data, optionOverrides) {
    var requestOptions = fastOptionsMerge(_this.defaultOptions, optionOverrides);
    return data && (requestOptions.body = _this.onEncode(data, requestOptions)), _this.transport.fetch(buildUrl(url, _this.baseUrl), method, requestOptions);
  }), this.baseUrl = baseUrl, this.defaultOptions = defaultOptions, this.onEncode = (null == handlerOverrides ? void 0 : handlerOverrides.onEncode) || defaultEncoder, this.handlers = function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({
    onErrorResponse: defaultErrorResponseHandler,
    onException: defaultExceptionHandler,
    onResponse: defaultResponseHandler
  }, handlerOverrides), this.transport = new HttpTransport(this.handlers);
});