import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _endsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/ends-with";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
export var isMatches = function (file) {
  return function (type) {
    var _context;
    return isExtension(type) ? _endsWithInstanceProperty(_context = file.name.toLowerCase()).call(_context, type.toLowerCase()) : isCovers(type, file.type);
  };
};
var isExtension = function (type) {
    return _startsWithInstanceProperty(type).call(type, ".");
  },
  isCovers = function (pattern, mimeType) {
    var _pattern$toLowerCase$ = pattern.toLowerCase().split("/"),
      _pattern$toLowerCase$2 = _slicedToArray(_pattern$toLowerCase$, 2),
      patternType = _pattern$toLowerCase$2[0],
      patternSubtype = _pattern$toLowerCase$2[1],
      _mimeType$toLowerCase = mimeType.toLowerCase().split("/"),
      _mimeType$toLowerCase2 = _slicedToArray(_mimeType$toLowerCase, 2),
      type = _mimeType$toLowerCase2[0],
      subtype = _mimeType$toLowerCase2[1];
    return patternType === type && ("*" === patternSubtype || patternSubtype === subtype);
  };