import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { intlMessageShape, appSwitcherRoutePropTypes, appSwitcherRouteGroupPropTypes, separatorPropType } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import cx from "classnames";
import { uniqueInnerId } from "@jutro/platform";
import { Popover, PopoverContainer, Link, InputField } from "@jutro/components";
import { AppSwitcherButton, availableIconColors } from "./AppSwitcherButton";
import { AppSwitcherContent } from "./AppSwitcherContent";
import { isGroup, isApp } from "./helpers";
import styles from "./AppSwitcher.module.css";
import { messages } from "./AppSwitcher.messages";
var appSwitcherPropTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([appSwitcherRoutePropTypes, appSwitcherRouteGroupPropTypes, separatorPropType]).isRequired).isRequired,
  className: PropTypes.string,
  onRouteClick: PropTypes.func,
  iconColor: PropTypes.oneOf(availableIconColors),
  iconClassName: PropTypes.string,
  footerText: intlMessageShape,
  footerUrl: intlMessageShape,
  onFooterClick: PropTypes.func,
  searchEnabled: PropTypes.bool,
  collapsibleGroupsThreshold: PropTypes.number,
  hideFooter: PropTypes.bool
};
function renderLink(_ref) {
  var label = _ref.label,
    onClick = _ref.onClick,
    href = _ref.href;
  if (!label || !onClick && !href) return null;
  var props = {
    children: label,
    href: href,
    onClick: onClick
  };
  return React.createElement(Link, props);
}
export var AppSwitcher = function (_ref2) {
  var id = _ref2.id,
    className = _ref2.className,
    items = _ref2.items,
    onRouteClick = _ref2.onRouteClick,
    iconColor = _ref2.iconColor,
    iconClassName = _ref2.iconClassName,
    footerText = _ref2.footerText,
    footerUrl = _ref2.footerUrl,
    onFooterClick = _ref2.onFooterClick,
    isDropdown = _ref2.isDropdown,
    searchEnabled = _ref2.searchEnabled,
    collapsibleGroupsThreshold = _ref2.collapsibleGroupsThreshold,
    hideFooter = _ref2.hideFooter,
    translator = useTranslator(),
    _useState = useState(""),
    _useState2 = _slicedToArray(_useState, 2),
    searchPhrase = _useState2[0],
    setSearchPhrase = _useState2[1],
    containerClasses = cx(styles.appSwitcher, className),
    AppSwitcherButtonId = uniqueInnerId(id, ["button"]).button,
    itemsCount = useMemo(function () {
      return _reduceInstanceProperty(items).call(items, function (numberOfItems, item) {
        return numberOfItems + (isGroup(item) ? item.items.length + 1 : 0) + (isApp(item) ? 1 : 0);
      }, 0);
    }, [items]),
    itemsWithId = useMemo(function () {
      return _mapInstanceProperty(items).call(items, function (item, index) {
        var _context,
          itemId = "".concat(index);
        return isGroup(item) ? _objectSpread(_objectSpread({}, item), {}, {
          items: _mapInstanceProperty(_context = item.items).call(_context, function (itemInner, indexInner) {
            var _context2;
            return _objectSpread(_objectSpread({}, itemInner), {}, {
              id: _concatInstanceProperty(_context2 = "".concat(itemId, "_")).call(_context2, indexInner)
            });
          }),
          id: itemId
        }) : _objectSpread(_objectSpread({}, item), {}, {
          id: itemId
        });
      });
    }, [items]),
    matchesSearchPhrase = useCallback(function (app) {
      var _context3,
        translatedTitle = translator(app.title);
      return "string" == typeof translatedTitle && _includesInstanceProperty(_context3 = translatedTitle.toLowerCase()).call(_context3, searchPhrase.toLowerCase());
    }, [searchPhrase, translator]),
    filteredItems = useMemo(function () {
      return searchPhrase ? _reduceInstanceProperty(itemsWithId).call(itemsWithId, function (apps, item) {
        return isGroup(item) && item.items.forEach(function (app) {
          matchesSearchPhrase(app) && apps.push(app);
        }), isApp(item) && matchesSearchPhrase(item) && apps.push(item), apps;
      }, []) : itemsWithId;
    }, [searchPhrase, itemsWithId, matchesSearchPhrase]),
    footerLinkProps = {
      label: footerText || "",
      href: footerUrl,
      onClick: function (event) {
        onFooterClick && onFooterClick(event), onRouteClick && onRouteClick();
      }
    },
    handleRenderTrigger = useCallback(function (toggle, ref, hasShownPopover) {
      return React.createElement(AppSwitcherButton, {
        id: AppSwitcherButtonId,
        togglePopover: toggle,
        ref: ref,
        hasShownPopover: hasShownPopover,
        iconColor: iconColor,
        iconClassName: iconClassName
      });
    }, [AppSwitcherButtonId, iconClassName, iconColor]),
    displaySearch = searchEnabled && itemsCount >= collapsibleGroupsThreshold,
    onSearchValueChange = function (newValue) {
      setSearchPhrase(newValue);
    },
    resetSearch = function () {
      setSearchPhrase("");
    },
    renderSearch = function () {
      return React.createElement(InputField, {
        id: "app-switcher-search",
        placeholder: translator(messages.searchPlaceholder),
        icon: "gw-search",
        iconPosition: "right",
        value: searchPhrase,
        onValueChange: onSearchValueChange,
        className: styles.appSwitcherSearch
      });
    },
    lessThanCollapsibleGroupsThreshold = itemsCount < collapsibleGroupsThreshold,
    renderContent = function (closePopover) {
      return React.createElement(AppSwitcherContent, {
        items: filteredItems,
        displaySearch: displaySearch,
        renderSearch: renderSearch,
        resetSearch: resetSearch,
        onRouteClick: onRouteClick,
        closePopover: closePopover,
        isCollapsible: !lessThanCollapsibleGroupsThreshold
      });
    };
  return isDropdown ? React.createElement(Popover, {
    id: id,
    renderTrigger: handleRenderTrigger,
    isFlipEnabled: !1,
    className: containerClasses
  }, function (closePopover) {
    return React.createElement(PopoverContainer, {
      className: styles.appSwitcherPopoverContainer,
      hideFooter: hideFooter,
      hideHeader: !0,
      internalClassNames: {
        body: styles.appSwitcherPopoverBody,
        footer: styles.appSwitcherPopoverFooter
      },
      footerLink: footerLinkProps
    }, renderContent(closePopover));
  }) : React.createElement(React.Fragment, null, React.createElement("div", {
    className: className
  }, renderContent()), !hideFooter && React.createElement("div", {
    className: styles.footerModal
  }, renderLink(footerLinkProps)));
};
AppSwitcher.displayName = "AppSwitcher", AppSwitcher.propTypes = appSwitcherPropTypes, AppSwitcher.defaultProps = {
  isDropdown: !0,
  searchEnabled: !0,
  items: [],
  collapsibleGroupsThreshold: 15,
  hideFooter: !1
}, AppSwitcher.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AppSwitcher",
  props: {
    isDropdown: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    searchEnabled: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Enables apps search field if the number of items equals or is above `collapsibleGroupsThreshold` value"
    },
    items: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "appSwitcherRoutePropTypes"
          }, {
            name: "custom",
            raw: "appSwitcherRouteGroupPropTypes"
          }, {
            name: "custom",
            raw: "separatorPropType"
          }]
        }
      },
      required: !1,
      description: "Array of Items to be displayed in the menu"
    },
    collapsibleGroupsThreshold: {
      defaultValue: {
        value: "15",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of items from which the groups are collapsible and the search is displayed"
    },
    hideFooter: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Prop to specify if the footer should be displayed or not"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify this component."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for the wrapper div"
    },
    onRouteClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback invoked when any route is clicked"
    },
    iconColor: {
      type: {
        name: "enum",
        computed: !0,
        value: "availableIconColors"
      },
      required: !1,
      description: "The color of trigger icon"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for trigger icon"
    },
    footerText: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text displayed on footer link"
    },
    footerUrl: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "URL to which footer link leads"
    },
    onFooterClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which will be triggered when footer link is clicked"
    }
  }
}, renderLink.__docgenInfo = {
  componentName: "renderLink",
  packageName: "@jutro/router",
  description: "",
  displayName: "renderLink",
  methods: [],
  actualName: "renderLink"
}, AppSwitcher.__docgenInfo = {
  componentName: "AppSwitcher",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcher",
  methods: [],
  actualName: "AppSwitcher",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify this component."
    },
    items: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                description: "Title that will be displayed below the icon",
                required: !0
              },
              to: {
                name: "custom",
                raw: "intlToShape",
                description: "Internal path to the application",
                required: !1
              },
              href: {
                name: "custom",
                raw: "intlMessageShape",
                description: "External path",
                required: !1
              },
              imageUrl: {
                name: "string",
                description: "Source of the image",
                required: !1
              },
              imageAlt: {
                name: "string",
                description: "Alternative text of the image",
                required: !1
              },
              exact: {
                name: "bool",
                description: "Flag for the exact path matching in react-router",
                required: !1
              },
              onClick: {
                name: "func",
                description: "Callback on item click event handler",
                required: !1
              },
              isFocused: {
                name: "bool",
                description: "Flag to indicate if the current item is focused",
                required: !1
              }
            }
          }, {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                description: "Title of the application group",
                required: !0
              },
              items: {
                name: "arrayOf",
                value: {
                  name: "custom",
                  raw: "appSwitcherRoutePropTypes.isRequired"
                },
                description: "Array of the route items",
                required: !0
              },
              isInitiallyCollapsed: {
                name: "bool",
                description: "Default state for the expandable application group",
                required: !1
              }
            }
          }, {
            name: "shape",
            value: {
              separator: {
                name: "bool",
                required: !1
              }
            }
          }]
        }
      },
      required: !1,
      description: "Array of Items to be displayed in the menu",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for the wrapper div"
    },
    onRouteClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback invoked when any route is clicked"
    },
    iconColor: {
      type: {
        name: "enum",
        computed: !0,
        value: "availableIconColors"
      },
      required: !1,
      description: "The color of trigger icon"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for trigger icon"
    },
    footerText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text displayed on footer link"
    },
    footerUrl: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "URL to which footer link leads"
    },
    onFooterClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback which will be triggered when footer link is clicked"
    },
    searchEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Enables apps search field if the number of items equals or is above `collapsibleGroupsThreshold` value",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    collapsibleGroupsThreshold: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of items from which the groups are collapsible and the search is displayed",
      defaultValue: {
        value: "15",
        computed: !1
      }
    },
    hideFooter: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Prop to specify if the footer should be displayed or not",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    isDropdown: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  }
};