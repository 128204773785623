import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useId } from "@jutro/platform";
import { Popper } from "../Popper/Popper";
import { popperPlacement } from "../Popper/types";
var popoverPropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  renderTrigger: PropTypes.func.isRequired,
  align: PropTypes.oneOf(popperPlacement),
  isFlipEnabled: PropTypes.bool,
  onClosed: PropTypes.func
};
export var Popover = function (_ref) {
  var renderTrigger = _ref.renderTrigger,
    align = _ref.align,
    children = _ref.children,
    className = _ref.className,
    idProp = _ref.id,
    isFlipEnabled = _ref.isFlipEnabled,
    onClosed = _ref.onClosed,
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    triggerElement = _useState2[0],
    setTriggerElement = _useState2[1],
    _useState3 = useState(!1),
    _useState4 = _slicedToArray(_useState3, 2),
    isOpen = _useState4[0],
    setIsOpen = _useState4[1],
    _useState5 = useState(!1),
    _useState6 = _slicedToArray(_useState5, 2),
    isRendered = _useState6[0],
    setRendered = _useState6[1],
    id = useId({
      default: idProp
    }),
    close = useCallback(function () {
      setIsOpen(!1), setRendered(!1);
    }, []),
    toggle = useCallback(function () {
      setRendered(!isOpen), setTimeout(function () {
        return setIsOpen(!isOpen);
      });
    }, [isOpen]),
    content = useMemo(function () {
      return "function" == typeof children ? children(close) : children;
    }, [children, close]);
  if (useEffect(function () {
    !isOpen && onClosed && onClosed();
  }, [isOpen]), !renderTrigger) return null;
  var placement = align && _includesInstanceProperty(popperPlacement).call(popperPlacement, align) ? align : "bottom-end",
    modifiers = [{
      name: "offset",
      options: {
        offset: [4, 4]
      }
    }, {
      name: "flip",
      enabled: isFlipEnabled
    }];
  return React.createElement("div", {
    id: id
  }, renderTrigger(toggle, setTriggerElement, isOpen), isRendered && React.createElement(Popper, {
    isOpen: isOpen,
    placement: placement,
    triggerElement: triggerElement,
    modifiers: modifiers,
    onClose: close,
    noPortal: !0
  }, React.createElement("div", {
    className: className,
    role: "menu",
    "aria-labelledby": id
  }, content)));
};
Popover.propTypes = popoverPropTypes, Popover.defaultProps = {
  align: "bottom-end",
  isFlipEnabled: !0
}, Popover.__docgenInfo = {
  description: "Popover",
  methods: [],
  displayName: "Popover",
  props: {
    align: {
      defaultValue: {
        value: "'bottom-end'",
        computed: !1
      },
      type: {
        name: "enum",
        computed: !0,
        value: "popperPlacement"
      },
      required: !1,
      description: "Popover alignment (relative to trigger element)"
    },
    isFlipEnabled: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the popover flips when it is about to overflow the visible area"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify this component."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "Notification popover content or renderProp to render this content"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An onClick callback function\n- An object of props for the component\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    }
  }
}, Popover.__docgenInfo = {
  componentName: "Popover",
  packageName: "@jutro/components",
  description: "Popover",
  displayName: "Popover",
  methods: [],
  actualName: "Popover",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify this component."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "Notification popover content or renderProp to render this content"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An onClick callback function\n- An object of props for the component\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    align: {
      type: {
        name: "enum",
        computed: !1,
        value: [{
          value: "'auto'",
          computed: !1
        }, {
          value: "'auto-end'",
          computed: !1
        }, {
          value: "'auto-start'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }, {
          value: "'bottom-end'",
          computed: !1
        }, {
          value: "'bottom-start'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'left-end'",
          computed: !1
        }, {
          value: "'left-start'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'right-end'",
          computed: !1
        }, {
          value: "'right-start'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }, {
          value: "'top-end'",
          computed: !1
        }, {
          value: "'top-start'",
          computed: !1
        }]
      },
      required: !1,
      description: "Popover alignment (relative to trigger element)",
      defaultValue: {
        value: "'bottom-end'",
        computed: !1
      }
    },
    isFlipEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the popover flips when it is about to overflow the visible area",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    }
  }
};