import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["isDisabled", "innerProps", "selectProps"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React from "react";
import { components } from "react-select";
export var GenericSelectControlSingleValue = function (_ref) {
  var isDisabled = _ref.isDisabled,
    innerProps = _ref.innerProps,
    selectProps = _ref.selectProps,
    rest = _objectWithoutProperties(_ref, _excluded),
    genericSelectStyles = selectProps.genericSelectStyles,
    customProps = function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({
      "aria-disabled": isDisabled
    }, innerProps);
  return React.createElement(components.SingleValue, _extends({}, rest, {
    className: genericSelectStyles.SingleValue,
    innerProps: customProps
  }));
};
GenericSelectControlSingleValue.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlSingleValue"
}, GenericSelectControlSingleValue.__docgenInfo = {
  componentName: "GenericSelectControlSingleValue",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlSingleValue",
  methods: [],
  actualName: "GenericSelectControlSingleValue"
};