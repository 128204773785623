import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useCallback, useMemo, useContext, memo } from "react";
import { GlobalizationProvider, LocaleServiceGlobalizationSettings } from "@jutro/locale";
import { GlobalizationMessages } from "./GlobalizationMessages";
import { GlobalizedHead } from "./GlobalizedHead";
import { LanguagesLoader } from "./LanguagesLoader";
import { tsmMessageLoader } from "./tsm/tsmMessageLoader";
import { isTSMEnabled } from "./tsm/tsmHelpers";
import { MessagesContext } from "./MessagesContext";
var tsmLoaders = [tsmMessageLoader],
  GlobalizedContentInternal = function (_ref) {
    var globalizationSettingsProp = _ref.globalizationSettings,
      appName = _ref.appName,
      messageLoader = _ref.messageLoader,
      coreMessageLoader = _ref.coreMessageLoader,
      intlTextComponent = _ref.intlTextComponent,
      persistToLocalStorage = _ref.persistToLocalStorage,
      persistToURL = _ref.persistToURL,
      children = _ref.children,
      _useContext = useContext(MessagesContext),
      _useContext$messagesS = _useContext.messagesState,
      messages = _useContext$messagesS.messages,
      isMessagesLoaded = _useContext$messagesS.isMessagesLoaded,
      messagesDispatch = _useContext.messagesDispatch,
      onLoadMessages = useCallback(function (newMessages) {
        return messagesDispatch({
          type: "messagesLoaded",
          payload: newMessages
        });
      }, [messagesDispatch]),
      loaders = useMemo(function () {
        return [coreMessageLoader, messageLoader];
      }, [coreMessageLoader, messageLoader]),
      globalizationSettings = useMemo(function () {
        return globalizationSettingsProp || new LocaleServiceGlobalizationSettings({
          skipLocalStoragePersistence: !persistToLocalStorage,
          skipURLPersistence: !persistToURL
        });
      }, [globalizationSettingsProp, persistToURL, persistToLocalStorage]),
      _useMemo = useMemo(function () {
        return {
          onLanguageOrLocaleChange: function (change) {
            var _globalizationSetting;
            return null === (_globalizationSetting = globalizationSettings.onGlobalizationChange) || void 0 === _globalizationSetting ? void 0 : _globalizationSetting.call(globalizationSettings, change);
          },
          onLocaleChange: function (locale) {
            var _globalizationSetting2;
            return null === (_globalizationSetting2 = globalizationSettings.onGlobalizationChange) || void 0 === _globalizationSetting2 ? void 0 : _globalizationSetting2.call(globalizationSettings, {
              locale: locale,
              localeChanged: !0
            });
          },
          onLanguageChange: function (language) {
            var _globalizationSetting3;
            return null === (_globalizationSetting3 = globalizationSettings.onGlobalizationChange) || void 0 === _globalizationSetting3 ? void 0 : _globalizationSetting3.call(globalizationSettings, {
              language: language,
              languageChanged: !0
            });
          }
        };
      }, [globalizationSettings]),
      onLanguageOrLocaleChange = _useMemo.onLanguageOrLocaleChange,
      onLocaleChange = _useMemo.onLocaleChange,
      onLanguageChange = _useMemo.onLanguageChange,
      globalizationDefaults = {
        defaultLocale: globalizationSettings.getPreferredLocale(),
        defaultLanguage: globalizationSettings.getPreferredLanguage(),
        availableLanguages: globalizationSettings.getAvailableLanguages(),
        availableLocales: globalizationSettings.getAvailableLocales(),
        defaultCountryCode: globalizationSettings.getDefaultCountryCode(),
        defaultCurrency: globalizationSettings.getDefaultCurrency(),
        defaultTimeZone: globalizationSettings.getDefaultTimeZone()
      };
    return React.createElement(GlobalizationProvider, _extends({
      messages: messages
    }, globalizationDefaults, {
      onLanguageChange: onLanguageChange,
      onLocaleChange: onLocaleChange,
      onLanguageOrLocaleChange: onLanguageOrLocaleChange,
      intlTextComponent: intlTextComponent
    }), React.createElement(GlobalizationMessages, {
      loaders: loaders,
      onLoad: onLoadMessages
    }), isMessagesLoaded && React.createElement(GlobalizedHead, {
      appName: appName
    }), children);
  };
GlobalizedContentInternal.displayName = "GlobalizedContent";
export var GlobalizedContent = memo(GlobalizedContentInternal);
var AuthGlobalizedContent = function () {
  var messagesDispatch = useContext(MessagesContext).messagesDispatch,
    onLoadLanguages = useCallback(function () {
      return messagesDispatch({
        type: "languagesLoaded"
      });
    }, [messagesDispatch]),
    onLoadMessagesTSM = useCallback(function (newMessages) {
      return messagesDispatch({
        type: "messagesLoadedTSM",
        payload: newMessages
      });
    }, [messagesDispatch]);
  return React.createElement(React.Fragment, null, isTSMEnabled() && React.createElement(GlobalizationMessages, {
    loaders: tsmLoaders,
    onLoad: onLoadMessagesTSM
  }), React.createElement(LanguagesLoader, {
    onLoad: onLoadLanguages
  }));
};
AuthGlobalizedContent.displayName = "AuthenticatedGlobalizedContent";
export var AuthenticatedGlobalizedContent = memo(AuthGlobalizedContent);
GlobalizedContentInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GlobalizedContent"
}, AuthGlobalizedContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AuthenticatedGlobalizedContent"
}, GlobalizedContentInternal.__docgenInfo = {
  componentName: "GlobalizedContent",
  packageName: "@jutro/app",
  description: "",
  displayName: "GlobalizedContent",
  methods: [],
  actualName: "GlobalizedContentInternal"
}, AuthGlobalizedContent.__docgenInfo = {
  componentName: "AuthenticatedGlobalizedContent",
  packageName: "@jutro/app",
  description: "",
  displayName: "AuthenticatedGlobalizedContent",
  methods: [],
  actualName: "AuthGlobalizedContent"
};