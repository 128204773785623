import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "hideSeparator", "separatorClassName", "className"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { DropdownMenuSeparator, DropdownMenuLink } from "@jutro/components";
import styles from "./BurgerMenu.module.css";
export var BurgerMenuLink = function (_ref) {
  var id = _ref.id,
    hideSeparator = _ref.hideSeparator,
    separatorClassName = _ref.separatorClassName,
    className = _ref.className,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(React.Fragment, null, !hideSeparator && React.createElement(DropdownMenuSeparator, {
    className: cx(styles.sidebarSeparator, separatorClassName)
  }), React.createElement(DropdownMenuLink, _extends({
    id: id,
    className: cx(styles.sidebarItem, styles.sidebarItemInteractive, className)
  }, props)));
};
BurgerMenuLink.propTypes = _objectSpread(_objectSpread({}, DropdownMenuLink.propTypes), {}, {
  id: PropTypes.string.isRequired,
  hideSeparator: PropTypes.bool,
  separatorClassName: PropTypes.string,
  children: PropTypes.node
}), BurgerMenuLink.__docgenInfo = {
  description: "BurgerMenuLink renders single burger menu content element as a DropdownMenuLink",
  methods: [],
  displayName: "BurgerMenuLink",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    hideSeparator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, does not render a separator"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger sidebar item separator"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content of the item"
    }
  },
  composes: ["@jutro/components"]
}, BurgerMenuLink.__docgenInfo = {
  componentName: "BurgerMenuLink",
  packageName: "@jutro/router",
  description: "BurgerMenuLink renders single burger menu content element as a DropdownMenuLink",
  displayName: "BurgerMenuLink",
  methods: [],
  actualName: "BurgerMenuLink",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    hideSeparator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, does not render a separator"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger sidebar item separator"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content of the item"
    }
  },
  composes: ["@jutro/components"]
};