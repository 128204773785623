import React from "react";
import { InlineLoader } from "../../../loading/inlineLoader/InlineLoader";
export var GenericSelectControlLoadingIndicator = function () {
  return React.createElement(InlineLoader, {
    loading: !0
  });
};
GenericSelectControlLoadingIndicator.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlLoadingIndicator"
}, GenericSelectControlLoadingIndicator.__docgenInfo = {
  componentName: "GenericSelectControlLoadingIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlLoadingIndicator",
  methods: [],
  actualName: "GenericSelectControlLoadingIndicator"
};