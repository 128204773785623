import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "placement", "fieldLabelId"];
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import isUndefined from "lodash/isUndefined";
import cloneDeep from "lodash/cloneDeep";
import { TranslatorContext } from "@jutro/locale";
import { intlMessageShape, DATA_TYPE_OBJECT, getDisplayName, DATA_TYPE_STRING, nestedTooltipShape, booleanValidationRuleShape, deprecated } from "@jutro/prop-types";
import { ContextConsumer, uniqueInnerId, generateComponentId } from "@jutro/platform";
import { publish, JUTRO_TOPICS } from "@jutro/events";
import { metadataTypes } from "@jutro/uimetadata";
import { BreakpointTracker, BreakpointTrackerContext } from "@jutro/layout";
import { isEmptyValue, isNilValue, VISIBLE_IF_REQUIRED } from "@jutro/data";
import { getConfigValue } from "@jutro/config";
import { getValidationMessageStyle as _getValidationMessageStyle } from "@jutro/validation/internal";
import { warning } from "@jutro/logger";
import styles from "./FieldComponent.module.css";
import { tryToCastToBoolean, findAvailableValue } from "./utils";
import { TooltipIcon } from "../../Tooltip/TooltipIcon";
import { InlineFieldLayout } from "./InlineFieldLayout";
import { FieldLabel } from "./FieldLabel";
import { Icon } from "../../Icon/Icon";
import { FieldComponentValidationImplementation } from "./FieldComponentValidationImplementation";
import { OldFieldComponentValidationImplementation } from "./OldFieldComponentValidationImplementation";
export var FieldComponent = function (_PureComponent) {
  _inherits(FieldComponent, PureComponent);
  var _super = _createSuper(FieldComponent);
  function FieldComponent(_props) {
    var _this;
    _classCallCheck(this, FieldComponent), _this = _super.call(this, _props), _defineProperty(_assertThisInitialized(_this), "state", _objectSpread({}, FieldComponent.defaultState)), _defineProperty(_assertThisInitialized(_this), "pristine", !0), _defineProperty(_assertThisInitialized(_this), "focused", !1), _defineProperty(_assertThisInitialized(_this), "focusPristine", !0), _defineProperty(_assertThisInitialized(_this), "beforeValue", null), _defineProperty(_assertThisInitialized(_this), "valid", !0), _defineProperty(_assertThisInitialized(_this), "fieldUniqueId", getConfigValue("generateUniqueId") ? generateComponentId(_this.props.id) : _this.props.id), _defineProperty(_assertThisInitialized(_this), "labelId", uniqueInnerId(_this.fieldUniqueId, "labelId")), _defineProperty(_assertThisInitialized(_this), "validationDependencyProps", []), _defineProperty(_assertThisInitialized(_this), "getDataTypeAwareSelectedValue", function () {
      var _this$props = _this.props,
        dataType = _this$props.dataType,
        value = _this$props.value;
      if (dataType === DATA_TYPE_OBJECT && !isEmptyValue(value)) {
        var selectedValue = isNilValue(value.code) ? value.id : value.code;
        return !isNilValue(selectedValue) && selectedValue.toString() || void 0;
      }
      return value;
    }), _defineProperty(_assertThisInitialized(_this), "handleAvailableValuesValueChange", function (targetValue) {
      var value,
        _this$props2 = _this.props,
        dataType = _this$props2.dataType,
        availableValues = _this$props2.availableValues;
      value = dataType === DATA_TYPE_OBJECT ? _findInstanceProperty(availableValues).call(availableValues, findAvailableValue(targetValue)) : tryToCastToBoolean(targetValue), _this.notifyChange(value);
    }), _defineProperty(_assertThisInitialized(_this), "handleChange", function (evt) {
      _this.notifyChange(evt.target.value);
    }), _defineProperty(_assertThisInitialized(_this), "handleFocus", _this.handleFocus.bind(_assertThisInitialized(_this))), _defineProperty(_assertThisInitialized(_this), "handleBlur", _this.handleBlur.bind(_assertThisInitialized(_this))), _defineProperty(_assertThisInitialized(_this), "renderFieldComponent", function (breakpoint) {
      var _props$inputType,
        _cx,
        _cx2,
        props = BreakpointTracker.applyBreakpointOverrides(_objectSpread(_objectSpread({}, _this.props), {}, {
          phone: _objectSpread({
            labelPosition: "top"
          }, _this.props.phone)
        }), breakpoint),
        breakpointProps = _objectSpread(_objectSpread({}, props), {}, {
          inputType: null !== (_props$inputType = props.inputType) && void 0 !== _props$inputType ? _props$inputType : "text"
        }),
        layout = breakpointProps.layout,
        inputType = breakpointProps.inputType,
        className = breakpointProps.className,
        contentContainerClassName = breakpointProps.contentContainerClassName,
        readOnly = breakpointProps.readOnly,
        hideLabel = breakpointProps.hideLabel,
        label = breakpointProps.label,
        visible = breakpointProps.visible,
        isDesignMode = breakpointProps.isDesignMode,
        required = breakpointProps.required,
        schemaRequired = breakpointProps.schemaRequired,
        showValidationIcon = breakpointProps.showValidationIcon,
        successMessage = breakpointProps.successMessage,
        labelPosition = breakpointProps.labelPosition,
        showInlineLabel = breakpointProps.showInlineLabel,
        labelId = _this.labelId.labelId,
        layoutComponent = _this.getLayoutComponent(),
        isHidden = !1 === visible;
      visible === VISIBLE_IF_REQUIRED && (isHidden = !0 !== required && !0 !== schemaRequired);
      var validationMessages = _this.getValidationMessages();
      (validationMessages && !isEmpty(validationMessages) || !_this.pristine) && (isHidden = !1);
      var isValid = _this.isValid(validationMessages);
      if (!0 === isHidden && !isDesignMode) return null;
      var labelContent = _this.renderLabel(breakpointProps),
        controlContent = readOnly ? _this.renderControlReadOnly(breakpointProps, labelContent ? {
          labelId: labelId
        } : void 0) : _this.renderControl(breakpointProps, labelContent ? {
          isValid: isValid,
          labelId: labelId
        } : {
          isValid: isValid
        }),
        messageContent = _this.renderMessages(validationMessages, successMessage, isValid),
        relativeClass = _this.shouldRenderTooltipIcon() && !0 !== hideLabel && "" !== label ? styles.relative : null;
      if (layoutComponent) return layoutComponent({
        labelContent: labelContent,
        controlContent: controlContent,
        messageContent: messageContent,
        className: cx(relativeClass, className)
      });
      var outerStyle = cx((_defineProperty(_cx = {}, styles.fieldComponent, "plain" !== layout), _defineProperty(_cx, styles.left, "left" === labelPosition), _defineProperty(_cx, styles.reversed, "reversed" === layout), _defineProperty(_cx, styles.fullWidth, "full-width" === layout), _defineProperty(_cx, styles.fullWidthSimple, "full-width-simple" === layout), _defineProperty(_cx, styles.hidden, "hidden" === inputType), _cx), relativeClass, className),
        innerStyle = cx(styles.contentContainer, (_defineProperty(_cx2 = {}, styles.controls, "plain" !== layout), _defineProperty(_cx2, styles.controlReadOnly, readOnly), _cx2), contentContainerClassName),
        validationIcon = _this.renderValidationIcon(showValidationIcon, readOnly, isValid, styles);
      return React.createElement("div", {
        className: outerStyle
      }, labelContent, React.createElement("div", {
        className: innerStyle
      }, controlContent, React.createElement("div", {
        className: styles.validationMessages
      }, validationIcon, (!isValid || successMessage) && messageContent)), "left" === labelPosition && !showInlineLabel && _this.renderTooltipIcon(breakpointProps));
    }), _defineProperty(_assertThisInitialized(_this), "getLayoutComponent", function () {
      return "inline" === _this.props.layout ? InlineFieldLayout : void 0;
    });
    var ValidationImplementation = _props.registerValidation ? new FieldComponentValidationImplementation() : new OldFieldComponentValidationImplementation();
    return _this.validationImplementation = ValidationImplementation.bind(_assertThisInitialized(_this)), _this;
  }
  return _createClass(FieldComponent, [{
    key: "componentDidUpdate",
    value: function () {
      var _this$validationImple;
      return (_this$validationImple = this.validationImplementation).componentDidUpdate.apply(_this$validationImple, arguments);
    }
  }, {
    key: "componentDidMount",
    value: function () {
      var _this$props3 = this.props,
        defaultValue = _this$props3.defaultValue,
        value = _this$props3.value,
        availableValues = _this$props3.availableValues,
        dataType = _this$props3.dataType,
        isDesignMode = _this$props3.isDesignMode,
        newValue = null;
      this.validationImplementation.componentDidMount(), isDesignMode ? newValue = defaultValue : void 0 !== defaultValue && null == value ? defaultValue === FieldComponent.firstAvailable ? availableValues && (newValue = getConvertedAvailableValue(availableValues[0], dataType)) : defaultValue === FieldComponent.lastAvailable ? availableValues && (newValue = getConvertedAvailableValue(availableValues[availableValues.length - 1], dataType)) : defaultValue === FieldComponent.onlyAvailable ? availableValues && 1 === availableValues.length && (newValue = getConvertedAvailableValue(availableValues[0], dataType)) : newValue = defaultValue : this.validate(value), (isDesignMode && value || null != newValue) && this.notifyChange(newValue, !1), publish(JUTRO_TOPICS.COMPONENT_LOADED, FieldComponent.fieldEventFormatter(_objectSpread({
        componentName: this.constructor.name
      }, this.props)));
    }
  }, {
    key: "componentWillUnmount",
    value: function () {
      var _this$validationImple2;
      return (_this$validationImple2 = this.validationImplementation).componentWillUnmount.apply(_this$validationImple2, arguments);
    }
  }, {
    key: "isRequired",
    value: function () {
      return this.validationImplementation.isRequired();
    }
  }, {
    key: "showErrors",
    value: function () {
      if (this.focused && !this.focusPristine) return !1;
      var showErrors = this.props.showErrors;
      return !0 === showErrors || !this.pristine;
    }
  }, {
    key: "getValidationConfig",
    value: function () {
      var _this$validationImple3;
      return (_this$validationImple3 = this.validationImplementation).getValidationConfig.apply(_this$validationImple3, arguments);
    }
  }, {
    key: "handleFocus",
    value: function (evt) {
      var onFocus = this.props.onFocus;
      this.focused = !0, this.focusPristine = !0, this.validationImplementation.handleFocus(), onFocus && onFocus(evt, this.beforeValue), this.beforeValue = cloneDeep(this.props.value);
    }
  }, {
    key: "getInputFocusHandlers",
    value: function () {
      return {
        onFocus: this.handleFocus,
        onBlur: this.handleBlur
      };
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      var value = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.getValue();
      return this.validationImplementation.getValidationMessages(value);
    }
  }, {
    key: "getValue",
    value: function (value) {
      return null != value ? value : this.props.value;
    }
  }, {
    key: "isEmpty",
    value: function (value) {
      return isEmptyValue(value || this.getValue());
    }
  }, {
    key: "handleBlur",
    value: function (evt) {
      var _this2 = this,
        _this$props4 = this.props,
        onBlur = _this$props4.onBlur,
        autoTrim = _this$props4.autoTrim,
        validationMessages = this.getValidationMessages(),
        translator = this.translator;
      if (this.focused = !1, this.validationImplementation.handleBlur(), this.focusPristine || isEmpty(validationMessages) && this.pristine || (this.updateTimeout = setTimeout(function () {
        _this2.forceUpdate();
      }, 200)), onBlur) {
        var _this$props5 = this.props,
          label = _this$props5.label,
          value = _this$props5.value,
          model = _this$props5.model,
          path = _this$props5.path,
          dataPath = _this$props5.dataPath;
        onBlur(evt, {
          fieldUniqueId: this.fieldUniqueId,
          label: translator(label),
          value: value,
          beforeValue: this.beforeValue,
          model: model || path,
          dataPath: dataPath
        });
      }
      if (autoTrim) {
        var _value = this.props.value || evt.target.value;
        "string" == typeof _value && this.notifyChange(_trimInstanceProperty(_value).call(_value));
      }
      publish(JUTRO_TOPICS.FIELD_BLUR, FieldComponent.fieldEventFormatter(_objectSpread(_objectSpread({}, this.props), {}, {
        beforeValue: this.beforeValue
      })));
    }
  }, {
    key: "validate",
    value: function () {
      var _this$validationImple4;
      return (_this$validationImple4 = this.validationImplementation).validate.apply(_this$validationImple4, arguments);
    }
  }, {
    key: "getRules",
    value: function () {
      return this.validationImplementation.getRules();
    }
  }, {
    key: "getValidationMessageStyle",
    value: function (messageList) {
      return _getValidationMessageStyle(messageList);
    }
  }, {
    key: "getValidationRules",
    value: function () {
      var _this$validationImple5;
      return (_this$validationImple5 = this.validationImplementation).getValidationRules.apply(_this$validationImple5, arguments);
    }
  }, {
    key: "touch",
    value: function () {
      this.pristine = !1, this.focusPristine = !1;
    }
  }, {
    key: "notifyChange",
    value: function (value) {
      var touch = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
        translator = this.translator,
        fieldUniqueId = this.fieldUniqueId,
        _this$props6 = this.props,
        onValueChange = _this$props6.onValueChange,
        model = _this$props6.model,
        path = _this$props6.path,
        label = _this$props6.label,
        dataPath = _this$props6.dataPath,
        nullable = _this$props6.nullable;
      touch && this.touch(), nullable && "" === value && (value = void 0), onValueChange && onValueChange(value, model || path, {
        fieldUniqueId: fieldUniqueId,
        label: translator(label),
        beforeValue: this.beforeValue,
        dataPath: dataPath
      }), publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread(_objectSpread({}, this.props), {}, {
        value: value,
        beforeValue: this.beforeValue
      }))), this.validationImplementation.notifyChange(value);
    }
  }, {
    key: "renderTooltipIcon",
    value: function (breakpointProps) {
      var fieldUniqueId = this.fieldUniqueId,
        labelId = this.labelId;
      if (!this.shouldRenderTooltipIcon()) return null;
      var labelPosition = breakpointProps.labelPosition,
        showInlineLabel = breakpointProps.showInlineLabel,
        tooltipId = uniqueInnerId(fieldUniqueId, "tooltipId").tooltipId,
        _this$getTooltipIconP = this.getTooltipIconProps(),
        _this$getTooltipIconP2 = _this$getTooltipIconP.id,
        id = void 0 === _this$getTooltipIconP2 ? tooltipId : _this$getTooltipIconP2,
        _this$getTooltipIconP3 = _this$getTooltipIconP.placement,
        placement = void 0 === _this$getTooltipIconP3 ? "top" : _this$getTooltipIconP3,
        _this$getTooltipIconP4 = _this$getTooltipIconP.fieldLabelId,
        fieldLabelId = void 0 === _this$getTooltipIconP4 ? labelId.labelId : _this$getTooltipIconP4,
        tooltipProps = _objectSpread(_objectSpread({
          id: id,
          placement: placement
        }, _objectWithoutProperties(_this$getTooltipIconP, _excluded)), {}, {
          labelPosition: labelPosition,
          showInlineLabel: showInlineLabel,
          fieldLabelId: fieldLabelId
        });
      return React.createElement(TooltipIcon, tooltipProps);
    }
  }, {
    key: "getTooltipIconProps",
    value: function () {
      var tooltip = this.props.tooltip;
      return tooltip instanceof Object ? tooltip : {
        text: tooltip
      };
    }
  }, {
    key: "shouldRenderTooltipIcon",
    value: function () {
      var layout = this.props.layout,
        _this$getTooltipIconP5 = this.getTooltipIconProps(),
        text = _this$getTooltipIconP5.text,
        title = _this$getTooltipIconP5.title,
        renderContent = _this$getTooltipIconP5.renderContent;
      return !!(text || title || renderContent) && "plain" !== layout;
    }
  }, {
    key: "getLabelledControlId",
    value: function (_breakpointProps) {
      return this.fieldUniqueId;
    }
  }, {
    key: "renderLabel",
    value: function (breakpointProps, className, secondaryClassName) {
      var translator = this.translator,
        labelId = this.labelId,
        label = breakpointProps.label,
        readOnly = breakpointProps.readOnly,
        disabled = breakpointProps.disabled,
        hideLabel = breakpointProps.hideLabel,
        labelClassName = breakpointProps.labelClassName,
        showRequired = breakpointProps.showRequired,
        showOptional = breakpointProps.showOptional,
        secondaryLabel = breakpointProps.secondaryLabel,
        secondaryLabelClassName = breakpointProps.secondaryLabelClassName,
        labelPosition = breakpointProps.labelPosition,
        showInlineLabel = breakpointProps.showInlineLabel,
        labelContainerClassName = breakpointProps.labelContainerClassName,
        secondaryLabelId = breakpointProps.secondaryLabelId,
        tooltipIcon = ("left" !== labelPosition || showInlineLabel) && this.renderTooltipIcon(breakpointProps),
        layoutComponent = this.getLayoutComponent(),
        layoutComponentClassName = null == layoutComponent ? void 0 : layoutComponent.labelClass,
        combinedClassName = cx(layoutComponentClassName, labelClassName, className),
        combinedSecondaryClassName = cx(secondaryLabelClassName, secondaryClassName),
        labelProps = {
          id: labelId.labelId,
          htmlFor: this.getLabelledControlId(breakpointProps),
          label: label,
          required: this.isRequired(),
          readOnly: readOnly,
          disabled: disabled,
          hideLabel: hideLabel,
          showRequired: showRequired,
          showOptional: showOptional,
          translator: translator,
          className: combinedClassName,
          secondaryLabel: secondaryLabel,
          secondaryLabelClassName: combinedSecondaryClassName,
          labelPosition: labelPosition,
          showInlineLabel: showInlineLabel,
          labelContainerClassName: labelContainerClassName,
          secondaryLabelId: secondaryLabelId,
          onClick: function (e) {
            e.target.closest("[data-tippy-root]") && "A" !== e.target.nodeName && e.preventDefault();
          }
        };
      return label && "" !== label ? React.createElement(FieldLabel, _extends({}, labelProps, {
        tooltipIcon: tooltipIcon
      })) : null;
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var value = breakpointProps.value,
        availableValues = breakpointProps.availableValues,
        className = breakpointProps.className,
        id = breakpointProps.id,
        primaryValue = value,
        secondaryValue = null;
      if (availableValues && null != value) {
        var translator = this.translator,
          actualValue = isNilValue(value.code) ? value.id : value.code,
          valueAsString = !isNilValue(actualValue) && actualValue.toString() || value.toString(),
          selectedValue = _findInstanceProperty(availableValues).call(availableValues, findAvailableValue(valueAsString));
        if (selectedValue) primaryValue = translator(selectedValue.name || selectedValue.displayName), secondaryValue = translator(selectedValue.secondaryLabel);
      }
      this.shouldDisplayDefaultROValue(primaryValue) && (primaryValue = FieldComponent.defaultROEmptyValue, secondaryValue = null);
      var classes = cx(styles.controlReadOnly, className);
      return React.createElement("div", _extends({
        id: id,
        "data-read-only": !0,
        className: classes
      }, this.generateReadOnlyAccessibilityProperties()), primaryValue, secondaryValue ? React.createElement("span", {
        className: styles.controlReadOnlySecondary
      }, secondaryValue) : "");
    }
  }, {
    key: "shouldDisplayDefaultROValue",
    value: function (value) {
      return !value;
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var _context,
        _context2,
        inputType = breakpointProps.inputType,
        fieldUniqueId = this.fieldUniqueId;
      "development" === process.env.NODE_ENV && warning('"renderControl()" method is not overridden in component' + _concatInstanceProperty(_context = _concatInstanceProperty(_context2 = "'<".concat(getDisplayName(this), ' id="')).call(_context2, fieldUniqueId, '" inputType="')).call(_context, inputType, "\" />'"));
      return this.renderControlReadOnly(breakpointProps);
    }
  }, {
    key: "generateDataPathProperty",
    value: function () {
      return {
        "data-path": this.props.dataPath
      };
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function () {
      var translator = this.translator,
        fieldUniqueId = this.fieldUniqueId,
        _this$props7 = this.props,
        placeholder = _this$props7.placeholder,
        label = _this$props7.label,
        readOnly = _this$props7.readOnly,
        successMessage = _this$props7.successMessage,
        formattedPlaceholder = translator(placeholder),
        formattedLabel = translator(label),
        props = _objectSpread(_objectSpread({
          "aria-required": this.isRequired(),
          "aria-label": null != formattedLabel ? formattedLabel : formattedPlaceholder
        }, !this.isValid(this.getValidationMessages()) && {
          "aria-invalid": !0
        }), {}, {
          placeholder: formattedPlaceholder
        });
      if (!readOnly) {
        var messageId = uniqueInnerId(fieldUniqueId, "messageId").messageId;
        return _objectSpread(_objectSpread({}, props), {}, {
          "aria-describedby": !this.isValid(this.getValidationMessages()) || successMessage ? messageId : null
        });
      }
      return props;
    }
  }, {
    key: "generateReadOnlyAccessibilityProperties",
    value: function () {
      var translator = this.translator,
        labelId = this.labelId,
        _this$props8 = this.props,
        placeholder = _this$props8.placeholder,
        disabled = _this$props8.disabled,
        label = _this$props8.label;
      return label && "" !== label ? {
        role: "textbox",
        "aria-readonly": !0,
        "aria-placeholder": isFunction(translator) ? translator(placeholder) : "",
        "aria-labelledby": labelId.labelId,
        "aria-disabled": disabled
      } : {};
    }
  }, {
    key: "getMessages",
    value: function () {
      var _this$validationImple6;
      return (_this$validationImple6 = this.validationImplementation).getMessages.apply(_this$validationImple6, arguments);
    }
  }, {
    key: "renderMessages",
    value: function () {
      var _this$validationImple7;
      return (_this$validationImple7 = this.validationImplementation).renderMessages.apply(_this$validationImple7, arguments);
    }
  }, {
    key: "renderValidationIcon",
    value: function (showValidationIcon, readOnly, isValid) {
      if (!showValidationIcon || readOnly) return null;
      var iconClasses = cx(styles.validationIcon, isValid ? styles.validStateIcon : styles.invalidStateIcon),
        icon = isValid ? "gw-check-circle" : "gw-error",
        isDirty = !this.pristine && !this.focused;
      return !isValid || isDirty ? React.createElement(Icon, {
        icon: icon,
        className: iconClasses
      }) : null;
    }
  }, {
    key: "isValid",
    value: function () {
      var _this$validationImple8;
      return (_this$validationImple8 = this.validationImplementation).isValid.apply(_this$validationImple8, arguments);
    }
  }, {
    key: "translator",
    get: function () {
      return this.context;
    }
  }, {
    key: "render",
    value: function () {
      return React.createElement(ContextConsumer, {
        contexts: [BreakpointTrackerContext]
      }, this.renderFieldComponent);
    }
  }]), FieldComponent;
}();
_defineProperty(FieldComponent, "defaultState", {
  isFocused: !1
}), _defineProperty(FieldComponent, "metadataType", metadataTypes.FIELD), _defineProperty(FieldComponent, "propTypes", {
  id: PropTypes.string.isRequired,
  label: intlMessageShape,
  secondaryLabel: intlMessageShape,
  secondaryLabelId: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  placeholder: intlMessageShape,
  required: booleanValidationRuleShape,
  schemaRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  visible: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  autoTrim: PropTypes.bool,
  onValueChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  model: PropTypes.object,
  path: PropTypes.string,
  showErrors: PropTypes.bool,
  showRequired: PropTypes.bool,
  showOptional: PropTypes.bool,
  validationMessages: PropTypes.arrayOf(intlMessageShape),
  layout: PropTypes.string,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  contentContainerClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  secondaryLabelClassName: PropTypes.string,
  labelContainerClassName: PropTypes.string,
  showValidationIcon: PropTypes.bool,
  dataPath: PropTypes.string,
  validator: PropTypes.shape({
    pattern: PropTypes.string.isRequired,
    message: intlMessageShape.isRequired
  }),
  requiredFieldValidationMessage: intlMessageShape,
  successMessage: intlMessageShape,
  messageProps: PropTypes.shape({
    requiredField: intlMessageShape
  }),
  labelPosition: PropTypes.oneOf(["top", "left"]),
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  inputType: PropTypes.string,
  testId: PropTypes.string,
  registerValidation: deprecated(PropTypes.func, void 0, "Please do not use registerValidation as it is used by @jutro/validation package that was deprecated"),
  enableMultipleValidation: deprecated(PropTypes.bool, void 0, "Please do not use enableMultipleValidation as it is used by @jutro/validation package that was deprecated")
}), _defineProperty(FieldComponent, "defaultProps", {
  autoTrim: !1,
  required: !1,
  schemaRequired: !1,
  readOnly: !1,
  disabled: !1,
  showErrors: !1,
  hideLabel: !1,
  showValidationIcon: !1,
  dataPath: "",
  labelPosition: "top",
  dataType: DATA_TYPE_STRING
}), _defineProperty(FieldComponent, "contextType", TranslatorContext), _defineProperty(FieldComponent, "defaultROEmptyValue", "-"), _defineProperty(FieldComponent, "firstAvailable", "__FIRST_AVAILABLE__"), _defineProperty(FieldComponent, "lastAvailable", "__LAST_AVAILABLE__"), _defineProperty(FieldComponent, "onlyAvailable", "__ONLY_AVAILABLE__"), _defineProperty(FieldComponent, "fieldEventFormatter", function (props) {
  return omitBy(props, function (val) {
    return isFunction(val) || isUndefined(val);
  });
});
var getConvertedAvailableValue = function (availableValue, dataType) {
  return dataType === DATA_TYPE_OBJECT ? availableValue : availableValue.code;
};
FieldComponent.__docgenInfo = {
  description: "Base class for field components. A field component renders label, control and messages. Other fields should extend\nthis class. Override any methods as needed: `renderLabel()`, `renderControl()`, `renderMessages()`. By default,\nthis class will render an <input> control.\n\n@template P\n@extends PureComponent<PropTypes.InferProps<FieldComponent.propTypes> & P>\n\n@metadataType field",
  methods: [{
    name: "fieldEventFormatter",
    docblock: "Removes props which are functions or undefined\n\n@param {object} props - component props\n@returns {object}",
    modifiers: ["static"],
    params: [{
      name: "props",
      description: "component props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Removes props which are functions or undefined"
  }, {
    name: "isRequired",
    docblock: "Determines requiredness of the field.\n\n@returns {boolean} true if field is required",
    modifiers: [],
    params: [],
    returns: {
      description: "true if field is required",
      type: {
        name: "boolean"
      }
    },
    description: "Determines requiredness of the field."
  }, {
    name: "showErrors",
    docblock: "Determines whether errors should be displayed. Errors are displayed as highlight of control and as separate\nmessage block.\n\n@returns {boolean} true if errors should be shown; false if errors should be hidden",
    modifiers: [],
    params: [],
    returns: {
      description: "true if errors should be shown; false if errors should be hidden",
      type: {
        name: "boolean"
      }
    },
    description: "Determines whether errors should be displayed. Errors are displayed as highlight of control and as separate\nmessage block."
  }, {
    name: "getDataTypeAwareSelectedValue",
    docblock: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT, return the `code` from the value.\nOtherwise return the value itself\n\n@returns {any} - The selected value",
    modifiers: [],
    params: [],
    returns: {
      description: "The selected value",
      type: {
        name: "any"
      }
    },
    description: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT, return the `code` from the value.\nOtherwise return the value itself"
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      type: null
    }],
    returns: null
  }, {
    name: "handleAvailableValuesValueChange",
    docblock: "Change handler to be shared by components using availableValues\n\n@param {any} targetValue - The new value to set",
    modifiers: [],
    params: [{
      name: "targetValue",
      description: "The new value to set",
      type: {
        name: "any"
      },
      optional: !1
    }],
    returns: null,
    description: "Change handler to be shared by components using availableValues"
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "handleFocus",
    docblock: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method"
  }, {
    name: "getInputFocusHandlers",
    docblock: "Get focus/blur handlers for use in derived input fields. This optimizes the validation behavior when the\ninput field is in focus.\n\n@returns {object} focus and blur handlers to be added to `<input>` fields",
    modifiers: [],
    params: [],
    returns: {
      description: "focus and blur handlers to be added to `<input>` fields",
      type: {
        name: "object"
      }
    },
    description: "Get focus/blur handlers for use in derived input fields. This optimizes the validation behavior when the\ninput field is in focus."
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@param {*} value current value of input\n@returns {Array} validation messages",
    modifiers: [],
    params: [{
      name: "value",
      description: "current value of input",
      type: {
        name: "mixed"
      },
      optional: !1
    }],
    returns: {
      description: "validation messages",
      type: {
        name: "Array"
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getValue",
    docblock: "Get current value\n\n@param {*} [value]\n@returns *",
    modifiers: [],
    params: [{
      name: "value"
    }],
    returns: null,
    description: "Get current value"
  }, {
    name: "isEmpty",
    docblock: "Tests if component is empty\n\n@param {*} value value to check\n@returns {boolean}",
    modifiers: [],
    params: [{
      name: "value",
      description: "value to check",
      type: {
        name: "mixed"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "boolean"
      }
    },
    description: "Tests if component is empty"
  }, {
    name: "handleBlur",
    docblock: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method"
  }, {
    name: "validate",
    docblock: "validate the selected value for 'input' elements.\nThis method will triggered validation if value is changed for required field.\n\n@param {any} value  - selected value to validate\n@param {boolean} [noNotification=false]  - optional flag for skipping the validation change notification",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: null,
    description: "validate the selected value for 'input' elements.\nThis method will triggered validation if value is changed for required field."
  }, {
    name: "getRules",
    docblock: "get the validation rules for the field component",
    modifiers: [],
    params: [],
    returns: null,
    description: "get the validation rules for the field component"
  }, {
    name: "getValidationMessageStyle",
    docblock: "get the style class for validation messages",
    modifiers: [],
    params: [{
      name: "messageList"
    }],
    returns: null,
    description: "get the style class for validation messages"
  }, {
    name: "getValidationRules",
    docblock: "get the validation rules config for field component",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: null,
    description: "get the validation rules config for field component"
  }, {
    name: "touch",
    docblock: "Mark as not pristine anymore",
    modifiers: [],
    params: [],
    returns: null,
    description: "Mark as not pristine anymore"
  }, {
    name: "notifyChange",
    docblock: "Helper method to invoke callback when value is changed; useful in derived classes\n\n@param {any} value - new value of control to send with notification\n@param {boolean} touch - mark field as touched",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value of control to send with notification",
      type: {
        name: "any"
      },
      optional: !1
    }, {
      name: "touch",
      description: "mark field as touched",
      type: {
        name: "boolean"
      },
      optional: !1
    }],
    returns: null,
    description: "Helper method to invoke callback when value is changed; useful in derived classes"
  }, {
    name: "renderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }],
    returns: null
  }, {
    name: "getTooltipIconProps",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "shouldRenderTooltipIcon",
    docblock: "Determines whether tooltip icon should be rendered\n\n@param {string|object} [tooltip] - tooltip icon component\n@param {string} [layout] - tooltip icon component\n@returns {boolean} true/false value indicating whether tooltip icon should be rendered",
    modifiers: [],
    params: [],
    returns: null,
    description: "Determines whether tooltip icon should be rendered"
  }, {
    name: "getLabelledControlId",
    docblock: "Get control that the label points to.\nUseful for components that have multiple controls, each with suffixed id.\n\n@param {object} breakpointProps - object with props according to the breakpoint\n@returns {string} control id",
    modifiers: [],
    params: [{
      name: "_breakpointProps"
    }],
    returns: {
      description: "control id",
      type: {
        name: "string"
      }
    },
    description: "Get control that the label points to.\nUseful for components that have multiple controls, each with suffixed id."
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Apply 'required' styling as appropriate.\n\n@param {object} breakpointProps - object with props according to the breakpoint\n@param {string} [className] - custom class applied to the label\n@param {string} [secondaryClassName] - custom class applied to the secondary label\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "object with props according to the breakpoint",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "className"
    }, {
      name: "secondaryClassName"
    }],
    returns: null,
    description: "Render label for this component. Apply 'required' styling as appropriate."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "shouldDisplayDefaultROValue",
    docblock: "Should display default readOnly char?\n\n@param {string} value\n\n@returns {boolean} true if value is empty",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "string"
      },
      optional: !1
    }],
    returns: {
      description: "true if value is empty",
      type: {
        name: "boolean"
      }
    },
    description: "Should display default readOnly char?"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - additional options\n\n@returns {React.ReactNode} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "additional options",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }, {
    name: "generateDataPathProperty",
    docblock: "generates the data-path attribute\n\n@returns {object} the data-path attribute when dataPath is provided via props",
    modifiers: [],
    params: [],
    returns: {
      description: "the data-path attribute when dataPath is provided via props",
      type: {
        name: "object"
      }
    },
    description: "generates the data-path attribute"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "generateReadOnlyAccessibilityProperties",
    docblock: "generates accessibility properties for the read only field component\n\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the read only field component"
  }, {
    name: "getMessages",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      type: null
    }],
    returns: null
  }, {
    name: "renderMessages",
    docblock: "Render messages for this component. Messages are expected to be translated before passing in.\n\n@param {Array<any>} validationMessages - error messages to render\n@param {string} successMessage - info message to render\n@param {boolean} isValid - indicates whether the component is valid or not\n@returns {React.ReactElement}   JSX for the messages",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: {
      description: "JSX for the messages",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render messages for this component. Messages are expected to be translated before passing in."
  }, {
    name: "renderValidationIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "showValidationIcon",
      type: null
    }, {
      name: "readOnly",
      type: null
    }, {
      name: "isValid",
      type: null
    }],
    returns: null
  }, {
    name: "isValid",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      type: null
    }],
    returns: null
  }, {
    name: "translator",
    docblock: "Return translator from context or default translator if\nthe former is undefined\n\n@returns {Function}",
    modifiers: ["get"],
    params: [],
    returns: {
      description: null,
      type: {
        name: "Function"
      }
    },
    description: "Return translator from context or default translator if\nthe former is undefined"
  }, {
    name: "renderFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      type: null
    }],
    returns: null
  }, {
    name: "getLayoutComponent",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  displayName: "FieldComponent",
  props: {
    autoTrim: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, will automatically trim string values on change"
    },
    required: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "booleanValidationRuleShape"
      },
      required: !1,
      description: 'If true, this field is required. If used with `@jutro/validation`, this prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: required: [true, "a custom message"].'
    },
    schemaRequired: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is required by the schema"
    },
    readOnly: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is readonly"
    },
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is disabled"
    },
    showErrors: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show errors for this field, works only when field is pristine"
    },
    hideLabel: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides the label on any layout"
    },
    showValidationIcon: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Used to display the validation icon"
    },
    dataPath: {
      defaultValue: {
        value: "''",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of the view model"
    },
    labelPosition: {
      defaultValue: {
        value: "'top'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Allows to select label position"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      required: !1
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component. fieldUniqueId is generated based on this id, applied to the control, and referenced by the label"
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Label text to display; if not provided, uses '[id]' for development"
    },
    secondaryLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Secondary label text to display; if not provided, uses '[id]' for development"
    },
    secondaryLabelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Secondary label id, uses for accessibility, to link secondary label with input element"
    },
    tooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip text to display or tooltip object to pass to TooltipIcon"
    },
    placeholder: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Placeholder to display on an empty component"
    },
    nullable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field returns undefined when the user deletes the data/selection on the input"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is visible"
    },
    value: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Value to display in the control"
    },
    defaultValue: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Sets the default field value on render if there is a default value; needs `onValueChange` to work"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value is changed; receives new value and (model or path) for this component"
    },
    onValidationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when validation is changed; receives 'isValid', (model or path) and validation message for this component"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the blur event is fired"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the focus event is fired"
    },
    model: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Passed as the second argument to `onValueChange`"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Passed as the second argument to `onValueChange`, if `model` is not present"
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show required indicator"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show optional indicator"
    },
    validationMessages: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "intlMessageShape"
        }
      },
      required: !1,
      description: "Validation messages to show for this field; only rendered if `showErrors` is `true`"
    },
    layout: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Layout to use with this field; the default is more control and less labels; the other option is 'reversed'"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    contentContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the content container of the component"
    },
    controlClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the control of the component"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the label of the component"
    },
    secondaryLabelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the secondary label of the component"
    },
    labelContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the label container of the component"
    },
    validator: {
      type: {
        name: "shape",
        value: {
          pattern: {
            name: "string",
            required: !0
          },
          message: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          }
        }
      },
      required: !1,
      description: "An object which should contain a regex pattern as string and a validation message as string"
    },
    requiredFieldValidationMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Used to override the default required field message"
    },
    successMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Success message to apply to the component if it is valid"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          requiredField: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Required message",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FieldComponent property to use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FieldComponent property to use 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FieldComponent property to use at 'tablet', 'phoneWide' and 'phone' breakpoint"
    },
    inputType: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Type attribute specifies the type of <input> element to display"
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id"
    },
    registerValidation: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.func,\n    undefined,\n    'Please do not use registerValidation as it is used by @jutro/validation package that was deprecated'\n)"
      },
      required: !1,
      description: "Optional callback used by the `@jutro/validation` package to register field validation to use validation hook"
    },
    enableMultipleValidation: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.bool,\n    undefined,\n    'Please do not use enableMultipleValidation as it is used by @jutro/validation package that was deprecated'\n)"
      },
      required: !1,
      description: "Used by the `@jutro/validation` package: Displays multiple field validation messages all at once"
    }
  }
}, FieldComponent.__docgenInfo = {
  componentName: "FieldComponent",
  packageName: "@jutro/components",
  description: "Base class for field components. A field component renders label, control and messages. Other fields should extend\nthis class. Override any methods as needed: `renderLabel()`, `renderControl()`, `renderMessages()`. By default,\nthis class will render an <input> control.",
  displayName: "FieldComponent",
  methods: [{
    name: "fieldEventFormatter",
    docblock: "Removes props which are functions or undefined\n\n@param {object} props - component props\n@returns {object}",
    modifiers: ["static"],
    params: [{
      name: "props",
      description: "component props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Removes props which are functions or undefined"
  }, {
    name: "isRequired",
    docblock: "Determines requiredness of the field.\n\n@returns {boolean} true if field is required",
    modifiers: [],
    params: [],
    returns: {
      description: "true if field is required",
      type: {
        name: "boolean"
      }
    },
    description: "Determines requiredness of the field."
  }, {
    name: "showErrors",
    docblock: "Determines whether errors should be displayed. Errors are displayed as highlight of control and as separate\nmessage block.\n\n@returns {boolean} true if errors should be shown; false if errors should be hidden",
    modifiers: [],
    params: [],
    returns: {
      description: "true if errors should be shown; false if errors should be hidden",
      type: {
        name: "boolean"
      }
    },
    description: "Determines whether errors should be displayed. Errors are displayed as highlight of control and as separate\nmessage block."
  }, {
    name: "getDataTypeAwareSelectedValue",
    docblock: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT, return the `code` from the value.\nOtherwise return the value itself\n\n@returns {any} - The selected value",
    modifiers: [],
    params: [],
    returns: {
      description: "The selected value",
      type: {
        name: "any"
      }
    },
    description: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT, return the `code` from the value.\nOtherwise return the value itself"
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleAvailableValuesValueChange",
    docblock: "Change handler to be shared by components using availableValues\n\n@param {any} targetValue - The new value to set",
    modifiers: [],
    params: [{
      name: "targetValue",
      description: "The new value to set",
      type: {
        name: "any"
      },
      optional: !1
    }],
    returns: null,
    description: "Change handler to be shared by components using availableValues"
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "handleFocus",
    docblock: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method"
  }, {
    name: "getInputFocusHandlers",
    docblock: "Get focus/blur handlers for use in derived input fields. This optimizes the validation behavior when the\ninput field is in focus.\n\n@returns {object} focus and blur handlers to be added to `<input>` fields",
    modifiers: [],
    params: [],
    returns: {
      description: "focus and blur handlers to be added to `<input>` fields",
      type: {
        name: "object"
      }
    },
    description: "Get focus/blur handlers for use in derived input fields. This optimizes the validation behavior when the\ninput field is in focus."
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@param {*} value current value of input\n@returns {Array} validation messages",
    modifiers: [],
    params: [{
      name: "value",
      description: "current value of input",
      type: {
        name: "mixed"
      },
      optional: !1
    }],
    returns: {
      description: "validation messages",
      type: {
        name: "Array"
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getValue",
    docblock: "Get current value\n\n@param {*} [value]\n@returns *",
    modifiers: [],
    params: [{
      name: "value"
    }],
    returns: null,
    description: "Get current value"
  }, {
    name: "isEmpty",
    docblock: "Tests if component is empty\n\n@param {*} value value to check\n@returns {boolean}",
    modifiers: [],
    params: [{
      name: "value",
      description: "value to check",
      type: {
        name: "mixed"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "boolean"
      }
    },
    description: "Tests if component is empty"
  }, {
    name: "handleBlur",
    docblock: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method"
  }, {
    name: "validate",
    docblock: "validate the selected value for 'input' elements.\nThis method will triggered validation if value is changed for required field.\n\n@param {any} value  - selected value to validate\n@param {boolean} [noNotification=false]  - optional flag for skipping the validation change notification",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: null,
    description: "validate the selected value for 'input' elements.\nThis method will triggered validation if value is changed for required field."
  }, {
    name: "getRules",
    docblock: "get the validation rules for the field component",
    modifiers: [],
    params: [],
    returns: null,
    description: "get the validation rules for the field component"
  }, {
    name: "getValidationMessageStyle",
    docblock: "get the style class for validation messages",
    modifiers: [],
    params: [{
      name: "messageList"
    }],
    returns: null,
    description: "get the style class for validation messages"
  }, {
    name: "getValidationRules",
    docblock: "get the validation rules config for field component",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: null,
    description: "get the validation rules config for field component"
  }, {
    name: "touch",
    docblock: "Mark as not pristine anymore",
    modifiers: [],
    params: [],
    returns: null,
    description: "Mark as not pristine anymore"
  }, {
    name: "notifyChange",
    docblock: "Helper method to invoke callback when value is changed; useful in derived classes\n\n@param {any} value - new value of control to send with notification\n@param {boolean} touch - mark field as touched",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value of control to send with notification",
      type: {
        name: "any"
      },
      optional: !1
    }, {
      name: "touch",
      description: "mark field as touched",
      type: {
        name: "boolean"
      },
      optional: !1
    }],
    returns: null,
    description: "Helper method to invoke callback when value is changed; useful in derived classes"
  }, {
    name: "renderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getTooltipIconProps",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "shouldRenderTooltipIcon",
    docblock: "Determines whether tooltip icon should be rendered\n\n@param {string|object} [tooltip] - tooltip icon component\n@param {string} [layout] - tooltip icon component\n@returns {boolean} true/false value indicating whether tooltip icon should be rendered",
    modifiers: [],
    params: [],
    returns: null,
    description: "Determines whether tooltip icon should be rendered"
  }, {
    name: "getLabelledControlId",
    docblock: "Get control that the label points to.\nUseful for components that have multiple controls, each with suffixed id.\n\n@param {object} breakpointProps - object with props according to the breakpoint\n@returns {string} control id",
    modifiers: [],
    params: [{
      name: "_breakpointProps"
    }],
    returns: {
      description: "control id",
      type: {
        name: "string"
      }
    },
    description: "Get control that the label points to.\nUseful for components that have multiple controls, each with suffixed id."
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Apply 'required' styling as appropriate.\n\n@param {object} breakpointProps - object with props according to the breakpoint\n@param {string} [className] - custom class applied to the label\n@param {string} [secondaryClassName] - custom class applied to the secondary label\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "object with props according to the breakpoint",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "className"
    }, {
      name: "secondaryClassName"
    }],
    returns: null,
    description: "Render label for this component. Apply 'required' styling as appropriate."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "shouldDisplayDefaultROValue",
    docblock: "Should display default readOnly char?\n\n@param {string} value\n\n@returns {boolean} true if value is empty",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "string"
      },
      optional: !1
    }],
    returns: {
      description: "true if value is empty",
      type: {
        name: "boolean"
      }
    },
    description: "Should display default readOnly char?"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - additional options\n\n@returns {React.ReactNode} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "additional options",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }, {
    name: "generateDataPathProperty",
    docblock: "generates the data-path attribute\n\n@returns {object} the data-path attribute when dataPath is provided via props",
    modifiers: [],
    params: [],
    returns: {
      description: "the data-path attribute when dataPath is provided via props",
      type: {
        name: "object"
      }
    },
    description: "generates the data-path attribute"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "generateReadOnlyAccessibilityProperties",
    docblock: "generates accessibility properties for the read only field component\n\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the read only field component"
  }, {
    name: "getMessages",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderMessages",
    docblock: "Render messages for this component. Messages are expected to be translated before passing in.\n\n@param {Array<any>} validationMessages - error messages to render\n@param {string} successMessage - info message to render\n@param {boolean} isValid - indicates whether the component is valid or not\n@returns {React.ReactElement}   JSX for the messages",
    modifiers: [],
    params: [{
      name: "...args"
    }],
    returns: {
      description: "JSX for the messages",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render messages for this component. Messages are expected to be translated before passing in."
  }, {
    name: "renderValidationIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "showValidationIcon",
      optional: void 0,
      type: null
    }, {
      name: "readOnly",
      optional: void 0,
      type: null
    }, {
      name: "isValid",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "isValid",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "translator",
    docblock: "Return translator from context or default translator if\nthe former is undefined\n\n@returns {Function}",
    modifiers: ["get"],
    params: [],
    returns: {
      description: null,
      type: {
        name: "Function"
      }
    },
    description: "Return translator from context or default translator if\nthe former is undefined"
  }, {
    name: "renderFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getLayoutComponent",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "FieldComponent",
  metadataType: "field",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component. fieldUniqueId is generated based on this id, applied to the control, and referenced by the label"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Label text to display; if not provided, uses '[id]' for development"
    },
    secondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Secondary label text to display; if not provided, uses '[id]' for development"
    },
    secondaryLabelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Secondary label id, uses for accessibility, to link secondary label with input element"
    },
    tooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip text to display or tooltip object to pass to TooltipIcon"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Placeholder to display on an empty component"
    },
    required: {
      type: {
        name: "custom",
        raw: "booleanValidationRuleShape"
      },
      required: !1,
      description: 'If true, this field is required. If used with `@jutro/validation`, this prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: required: [true, "a custom message"].',
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    schemaRequired: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is required by the schema",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is readonly",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    nullable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field returns undefined when the user deletes the data/selection on the input"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is visible"
    },
    value: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Value to display in the control"
    },
    defaultValue: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Sets the default field value on render if there is a default value; needs `onValueChange` to work"
    },
    autoTrim: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, will automatically trim string values on change",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value is changed; receives new value and (model or path) for this component"
    },
    onValidationChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when validation is changed; receives 'isValid', (model or path) and validation message for this component"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the blur event is fired"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the focus event is fired"
    },
    model: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Passed as the second argument to `onValueChange`"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Passed as the second argument to `onValueChange`, if `model` is not present"
    },
    showErrors: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show errors for this field, works only when field is pristine",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show required indicator"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show optional indicator"
    },
    validationMessages: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }
      },
      required: !1,
      description: "Validation messages to show for this field; only rendered if `showErrors` is `true`"
    },
    layout: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Layout to use with this field; the default is more control and less labels; the other option is 'reversed'"
    },
    hideLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides the label on any layout",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    contentContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the content container of the component"
    },
    controlClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the control of the component"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the label of the component"
    },
    secondaryLabelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the secondary label of the component"
    },
    labelContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the label container of the component"
    },
    showValidationIcon: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Used to display the validation icon",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    dataPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of the view model",
      defaultValue: {
        value: "''",
        computed: !1
      }
    },
    validator: {
      type: {
        name: "shape",
        value: {
          pattern: {
            name: "string",
            required: !0
          },
          message: {
            name: "union",
            required: !0,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "An object which should contain a regex pattern as string and a validation message as string"
    },
    requiredFieldValidationMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Used to override the default required field message"
    },
    successMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Success message to apply to the component if it is valid"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          requiredField: {
            name: "union",
            description: "Required message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Allows to select label position",
      defaultValue: {
        value: "'top'",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FieldComponent property to use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FieldComponent property to use 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FieldComponent property to use at 'tablet', 'phoneWide' and 'phone' breakpoint"
    },
    inputType: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Type attribute specifies the type of <input> element to display"
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id"
    },
    registerValidation: {
      type: {
        name: "func"
      },
      required: !1,
      description: "@deprecated Optional callback used by the `@jutro/validation` package to register field validation to use validation hook",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    enableMultipleValidation: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "@deprecated Used by the `@jutro/validation` package: Displays multiple field validation messages all at once",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      required: !1
    }
  }
};