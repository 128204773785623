import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { useEffect } from "react";
import { logDeprecationMessage } from "../utils/logDeprecationMessage";
export function useDeprecationWarning(opts, alternativeComponent, extraMessage, dropTarget, noDeprecationWarnings) {
  useEffect(function () {
    var _context,
      resolvedOpts = "object" === _typeof(opts) ? opts : {
        componentName: opts,
        alternativeComponent: alternativeComponent,
        extraMessage: extraMessage,
        dropTarget: dropTarget,
        noDeprecationWarnings: noDeprecationWarnings
      };
    void 0 !== resolvedOpts.noDeprecationWarnings && _includesInstanceProperty(_context = resolvedOpts.noDeprecationWarnings).call(_context, resolvedOpts.componentName) || logDeprecationMessage(resolvedOpts.componentName, resolvedOpts.alternativeComponent, resolvedOpts.extraMessage, resolvedOpts.dropTarget);
  });
}