import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Number$MIN_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/min-safe-integer";
import _Number$MAX_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/max-safe-integer";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isArray from "lodash/isArray";
import { isExists as doesDateExist, isValid } from "date-fns";
import { formatDateToLocalePattern, getDateInputFormats, isDateInRange, isValidatePhoneNumber, parseDateFromDataType, validateDateUserInput } from "@jutro/locale";
import { messages } from "./fieldValidation.messages";
import { isComplete } from "../helpers/inputMaskHelpers";
import * as mimeTypes from "../helpers/fileUploadHelpers";
var getMessage = function (key, message) {
    return message || messages[key];
  },
  handleTranslation = function (message, translator, translatorArgs) {
    return message instanceof Object && "type" in message ? {
      message: translator(message.message, translatorArgs),
      details: translator(message.details, translatorArgs),
      type: message.type
    } : translator(message, translatorArgs);
  },
  inputPatterns = {
    email: function (input, message) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(input).toLowerCase()) ? void 0 : message;
    },
    alphanumeric: function (input, message) {
      return /^[a-z0-9]+$/i.test(input) ? void 0 : message;
    },
    lowercase: function (input, message) {
      return input !== (null == input ? void 0 : input.toLowerCase()) ? message : void 0;
    },
    uppercase: function (input, message) {
      return input !== (null == input ? void 0 : input.toUpperCase()) ? message : void 0;
    },
    url: function (input, message) {
      return new RegExp("^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$", "i").test(input) ? void 0 : message;
    }
  };
export var fieldRulesFactory = {
  required: function (_ref, options) {
    var message = _slicedToArray(_ref, 2)[1];
    return function (value, path, translator) {
      var errorMessage = null == options || !options.label || null != options && options.hideLabel ? getMessage("required", message) : getMessage("requiredWithLabel", message);
      return isNil(value) || "" === value ? handleTranslation(errorMessage, translator, {
        label: null == options ? void 0 : options.label
      }) : void 0;
    };
  },
  minValue: function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      minValue = _ref4[0],
      message = _ref4[1];
    return function (value, path, translator) {
      if (value) return value < (null != minValue ? minValue : _Number$MIN_SAFE_INTEGER) ? handleTranslation(getMessage("minValue", message), translator, {
        value: String(minValue)
      }) : void 0;
    };
  },
  maxValue: function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      maxValue = _ref6[0],
      message = _ref6[1];
    return function (value, path, translator) {
      if (value) return value > (null != maxValue ? maxValue : _Number$MAX_SAFE_INTEGER) ? handleTranslation(getMessage("maxValue", message), translator, {
        value: String(maxValue)
      }) : void 0;
    };
  },
  inputFormat: function (_ref23, options) {
    var message = _slicedToArray(_ref23, 2)[1];
    return function (value, path, translator) {
      var valueToCheck;
      if ((valueToCheck = value) instanceof Object && "year" in valueToCheck && "month" in valueToCheck && "day" in valueToCheck) {
        var day = value.day,
          month = value.month,
          year = value.year;
        return isNil(day) || isNil(month) || isNil(year) || !doesDateExist(year, month, day) ? handleTranslation(getMessage("invalidDate", message), translator) : void 0;
      }
      if ("string" == typeof value && options && !validateDateUserInput(value, getDateInputFormats(options.localePattern))) return handleTranslation(getMessage("invalidDate", message), translator);
    };
  },
  maxDate: function (_ref15, options) {
    var _ref16 = _slicedToArray(_ref15, 2),
      maxDate = _ref16[0],
      message = _ref16[1];
    return function (value, path, translator) {
      if (value) {
        var date = parseDateFromDataType(value),
          fulfillMaxRequirement = isDateInRange(date, void 0, maxDate).fulfillMaxRequirement,
          parsedMaxDate = parseDateFromDataType(maxDate);
        if ((!maxDate || "object" !== _typeof(parsedMaxDate) || !isEmpty(_Object$keys(parsedMaxDate)) || isValid(parsedMaxDate)) && !fulfillMaxRequirement && maxDate && options) {
          var intl = options.intl,
            displayFormat = options.displayFormat,
            localePattern = options.localePattern,
            formattedMaxDate = formatDateToLocalePattern(parsedMaxDate, intl.formatDate, displayFormat, localePattern);
          return handleTranslation(getMessage("maxDate", message), translator, {
            maxDate: formattedMaxDate || ""
          });
        }
      }
    };
  },
  minDate: function (_ref13, options) {
    var _ref14 = _slicedToArray(_ref13, 2),
      minDate = _ref14[0],
      message = _ref14[1];
    return function (value, path, translator) {
      if (value) {
        var date = parseDateFromDataType(value),
          fulfillMinRequirement = isDateInRange(date, minDate, void 0).fulfillMinRequirement,
          parsedMinDate = parseDateFromDataType(minDate);
        if ((!minDate || "object" !== _typeof(parsedMinDate) || !isEmpty(_Object$keys(parsedMinDate)) || isValid(parsedMinDate)) && !fulfillMinRequirement && minDate && options) {
          var intl = options.intl,
            displayFormat = options.displayFormat,
            localePattern = options.localePattern,
            formattedMinDate = formatDateToLocalePattern(parsedMinDate, intl.formatDate, displayFormat, localePattern);
          return handleTranslation(getMessage("minDate", message), translator, {
            minDate: formattedMinDate || ""
          });
        }
      }
    };
  },
  minYear: function (_ref29) {
    var _ref30 = _slicedToArray(_ref29, 2),
      minYear = _ref30[0],
      message = _ref30[1];
    return function (value, path, translator) {
      if (value) return value < (null != minYear ? minYear : _Number$MIN_SAFE_INTEGER) ? handleTranslation(getMessage("minYear", message), translator, {
        year: String(minYear)
      }) : void 0;
    };
  },
  maxYear: function (_ref31) {
    var _ref32 = _slicedToArray(_ref31, 2),
      maxYear = _ref32[0],
      message = _ref32[1];
    return function (value, path, translator) {
      if (value) return value > (null != maxYear ? maxYear : _Number$MAX_SAFE_INTEGER) ? handleTranslation(getMessage("maxYear", message), translator, {
        year: String(maxYear)
      }) : void 0;
    };
  },
  pattern: function (_ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      pattern = _ref8[0],
      message = _ref8[1],
      presetPattern = inputPatterns[String(pattern)];
    if (presetPattern) return function (value, path, translator) {
      if (value) return presetPattern(String(value), handleTranslation(getMessage(pattern, message), translator));
    };
    var customRegex = new RegExp(String(pattern));
    return function (value, path, translator) {
      return customRegex.test(String(value)) ? void 0 : handleTranslation(getMessage("regex", message), translator, {
        value: String(pattern)
      });
    };
  },
  mask: function (_ref9) {
    var _ref10 = _slicedToArray(_ref9, 2),
      mask = _ref10[0],
      message = _ref10[1];
    return function (value, path, translator) {
      if (value) return isComplete(String(value), String(mask)) ? void 0 : handleTranslation(getMessage("incompleteInput", message), translator);
    };
  },
  countryCode: function (_ref11) {
    var _ref12 = _slicedToArray(_ref11, 2),
      countryCode = _ref12[0],
      message = _ref12[1];
    return function (value, path, translator) {
      if (countryCode && value && isComplete(String(value))) return isValidatePhoneNumber(String(value), String(countryCode)) ? void 0 : handleTranslation(getMessage("invalidPhone", message), translator);
    };
  },
  accept: function (_ref17) {
    var _ref18 = _slicedToArray(_ref17, 2),
      accept = _ref18[0],
      message = _ref18[1];
    return function (value, path, translator) {
      var _context;
      if (value instanceof Object && accept) {
        var types = _mapInstanceProperty(_context = String(accept).toLowerCase().split(",")).call(_context, function (item) {
          return _trimInstanceProperty(item).call(item);
        });
        return isArray(value) && types ? _filterInstanceProperty(value).call(value, function (file) {
          return !types.some(mimeTypes.isMatches(file));
        }).length > 0 ? handleTranslation(getMessage("incorrectFileTypeMessage", message), translator) : void 0 : types.some(mimeTypes.isMatches(value)) ? void 0 : handleTranslation(getMessage("incorrectFileTypeMessage", message), translator);
      }
    };
  },
  maxFileSizeKB: function (_ref19) {
    var _ref20 = _slicedToArray(_ref19, 2),
      maxFileSizeKB = _ref20[0],
      message = _ref20[1];
    return function (value, path, translator) {
      if (value instanceof Object) return isArray(value) && maxFileSizeKB ? _filterInstanceProperty(value).call(value, function (file) {
        return (null == file ? void 0 : file.size) > 1e3 * Number(maxFileSizeKB);
      }).length > 0 ? handleTranslation(getMessage("maxFileSizeKBMessage", message), translator, {
        value: String(maxFileSizeKB)
      }) : void 0 : maxFileSizeKB && value && value.size && value.size > 1e3 * Number(maxFileSizeKB) ? handleTranslation(getMessage("maxFileSizeKBMessage", message), translator, {
        value: String(maxFileSizeKB)
      }) : void 0;
    };
  },
  maxLength: function (_ref21) {
    var _ref22 = _slicedToArray(_ref21, 2),
      maxLength = _ref22[0],
      message = _ref22[1];
    return function (value, path, translator) {
      if (value instanceof Object) return isArray(value) && maxLength ? _filterInstanceProperty(value).call(value, function (file) {
        return String(file.name).length > maxLength;
      }).length > 0 ? handleTranslation(getMessage("maxFileNameLengthMessage", message), translator, {
        value: String(maxLength)
      }) : void 0 : maxLength && value && String(value.name).length > maxLength ? handleTranslation(getMessage("maxFileNameLengthMessage", message), translator, {
        value: String(maxLength)
      }) : void 0;
    };
  },
  uploadError: function (_ref25) {
    var _ref26 = _slicedToArray(_ref25, 2),
      uploadError = _ref26[0],
      serverMessage = _ref26[1];
    return function (value, path, translator) {
      if (uploadError) return handleTranslation(serverMessage || getMessage("uploadError"), translator);
    };
  },
  uploadErrors: function (_ref27) {
    var _ref28 = _slicedToArray(_ref27, 2),
      uploadErrors = _ref28[0],
      serverMessage = _ref28[1];
    return function (value, path, translator) {
      if (_Object$keys(uploadErrors).length) return handleTranslation(serverMessage || getMessage("uploadError"), translator);
    };
  },
  value: function (_ref33, options) {
    var message = _slicedToArray(_ref33, 2)[1];
    return function (value, path, translator) {
      if (value) {
        var getInstance = null == options ? void 0 : options.getInstance;
        if (!getInstance()) return;
        var _context2,
          isValidPhoneNumber = getInstance().isValidNumber(),
          validationError = getInstance().getValidationError();
        if (!isValidPhoneNumber) return _concatInstanceProperty(_context2 = "".concat(handleTranslation(getMessage("validatePhone", message), translator), " ")).call(_context2, handleTranslation(getMessage("validatePhoneError".concat(validationError), message), translator));
      }
    };
  }
};