import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "className", "prefix", "suffix", "role", "aria-readonly", "aria-placeholder", "aria-labelledby", "aria-disabled", "tag", "component"];
import React from "react";
import omit from "lodash/omit";
import { useTranslator } from "@jutro/locale";
var ValueComponentInternal = function (_ref) {
  var id = _ref.id,
    className = _ref.className,
    prefix = _ref.prefix,
    suffix = _ref.suffix,
    role = _ref.role,
    ariaReadOnly = _ref["aria-readonly"],
    ariaPlaceholder = _ref["aria-placeholder"],
    ariaLabelledBy = _ref["aria-labelledby"],
    ariaDisabled = _ref["aria-disabled"],
    Tag = _ref.tag,
    Component = _ref.component,
    rest = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator();
  return React.createElement(Tag, {
    id: id,
    className: className,
    "data-read-only": !0,
    role: role,
    "aria-readonly": ariaReadOnly,
    "aria-placeholder": ariaPlaceholder,
    "aria-labelledby": ariaLabelledBy,
    "aria-disabled": ariaDisabled
  }, prefix && translator(prefix), React.createElement(Component, omit(rest, ["path", "onValueChange"])), suffix && translator(suffix));
};
ValueComponentInternal.defaultProps = {
  tag: "span"
};
export var ValueComponentWrapper = function (ValueComponent) {
  return function (props) {
    return React.createElement(ValueComponentInternal, _extends({
      component: ValueComponent
    }, props));
  };
};
ValueComponentInternal.__docgenInfo = {
  componentName: "ValueComponentInternal",
  packageName: "@jutro/components",
  description: "",
  displayName: "ValueComponentInternal",
  methods: [],
  actualName: "ValueComponentInternal",
  props: {
    tag: {
      defaultValue: {
        value: "'span'",
        computed: !1
      },
      required: !1
    }
  }
};