import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { useMemo } from "react";
import { useTranslator } from "@jutro/locale";
import { timezones } from "./timezones";
import { messages } from "./DateTimeZoneField.messages";
var currentTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone,
  isCurrentIanaInTimeZone = function (_ref) {
    var name = _ref.name,
      group = _ref.group;
    return name === currentTimeZone || _includesInstanceProperty(group).call(group, currentTimeZone);
  };
export var useTimeZoneDropdownValues = function (timezoneIncludeList) {
  var translator = useTranslator(),
    filteredTimeZones = useMemo(function () {
      return function (timezoneIncludeList) {
        var timezoneArray = _Object$keys(timezones),
          timezoneList = _mapInstanceProperty(timezoneArray).call(timezoneArray, function (key) {
            return {
              name: key,
              group: timezones[key]
            };
          });
        return null != timezoneIncludeList && timezoneIncludeList.length ? _filterInstanceProperty(timezoneList).call(timezoneList, function (timezone) {
          return _includesInstanceProperty(timezoneIncludeList).call(timezoneIncludeList, timezone.name);
        }) : timezoneList;
      }(timezoneIncludeList);
    }, [timezoneIncludeList]);
  return useMemo(function () {
    var _filteredTimeZones$fi,
      _context,
      timezoneValue = null === (_filteredTimeZones$fi = _findInstanceProperty(filteredTimeZones).call(filteredTimeZones, isCurrentIanaInTimeZone)) || void 0 === _filteredTimeZones$fi ? void 0 : _filteredTimeZones$fi.name,
      timezoneValues = _mapInstanceProperty(filteredTimeZones).call(filteredTimeZones, function (_ref2) {
        var name = _ref2.name;
        return {
          code: name,
          name: translator(messages[name])
        };
      });
    return timezoneValue ? [timezoneValue, timezoneValues] : [currentTimeZone, _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(timezoneValues), [{
      code: currentTimeZone,
      name: messages[currentTimeZone]
    }])];
  }, [filteredTimeZones, translator]);
};