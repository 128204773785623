import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["steps", "className", "isVertical"];
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useContext } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { BreakpointTrackerContext } from "@jutro/layout";
import PropTypes from "prop-types";
import styles from "./StepProgressBar.module.css";
import { ProgressStep } from "./ProgressStep";
export var progressStepPropType = {
  title: intlMessageShape.isRequired,
  visited: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
export var stepProgressBarPropTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape(progressStepPropType).isRequired).isRequired,
  className: PropTypes.string,
  isVertical: PropTypes.bool
};
export var StepProgressBar = function (_ref) {
  var steps = _ref.steps,
    className = _ref.className,
    isVertical = _ref.isVertical,
    rest = _objectWithoutProperties(_ref, _excluded),
    classes = cx(styles.stepProgressBar, className, _defineProperty({}, styles.vertical, isVertical)),
    translator = useTranslator(),
    breakpoint = useContext(BreakpointTrackerContext),
    activeStep = _filterInstanceProperty(steps).call(steps, function (stepProps) {
      return stepProps.active;
    })[0];
  return React.createElement("nav", rest, React.createElement("div", {
    className: classes,
    role: "menu"
  }, _mapInstanceProperty(steps).call(steps, function (stepProps, index) {
    var _context,
      id = _concatInstanceProperty(_context = "".concat(stepProps.title)).call(_context, index);
    return React.createElement(ProgressStep, {
      step: stepProps,
      id: id,
      key: id,
      isVertical: isVertical,
      index: index
    });
  })), activeStep && "phone" === breakpoint && !isVertical ? React.createElement("div", {
    className: cx(styles.phoneTitle, _defineProperty({}, styles.titleDisabled, activeStep.disabled)),
    "aria-disabled": !!activeStep.disabled || void 0
  }, translator(activeStep.title)) : null);
};
StepProgressBar.propTypes = stepProgressBarPropTypes, StepProgressBar.displayName = "StepProgressBar", StepProgressBar.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "StepProgressBar",
  props: {
    steps: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            title: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              description: "Text part for labeling the step",
              required: !0
            },
            visited: {
              name: "bool",
              description: "Indicates if a step marker should be displayed as visited.",
              required: !1
            },
            active: {
              name: "bool",
              description: "Indicates if a step marker should be displayed as the currently active step.",
              required: !1
            },
            disabled: {
              name: "bool",
              description: "Indicates if a step marker should be displayed as disabled.",
              required: !1
            },
            onClick: {
              name: "func",
              description: "Handler for the click event on the progress marker.",
              required: !1
            }
          }
        }
      },
      required: !0,
      description: "Steps data"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar styles"
    },
    isVertical: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the component is oriented vertically"
    }
  }
}, StepProgressBar.__docgenInfo = {
  componentName: "StepProgressBar",
  packageName: "@jutro/components",
  description: "",
  displayName: "StepProgressBar",
  methods: [],
  actualName: "StepProgressBar",
  props: {
    steps: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            title: {
              name: "union",
              description: "Text part for labeling the step",
              required: !0,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            visited: {
              name: "bool",
              description: "Indicates if a step marker should be displayed as visited.",
              required: !1
            },
            active: {
              name: "bool",
              description: "Indicates if a step marker should be displayed as the currently active step.",
              required: !1
            },
            disabled: {
              name: "bool",
              description: "Indicates if a step marker should be displayed as disabled.",
              required: !1
            },
            onClick: {
              name: "func",
              description: "Handler for the click event on the progress marker.",
              required: !1
            }
          }
        }
      },
      required: !0,
      description: "Steps data"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar styles"
    },
    isVertical: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the component is oriented vertically"
    }
  }
};