import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["availableValues", "className", "components", "createNewMessage", "defaultValue", "disabled", "filter", "getOptionLabel", "getOptionValue", "id", "internalClassNames", "isClearable", "allowNewValue", "allowNew", "labelId", "loadValues", "noOptionsMessage", "onBlur", "onClick", "onCreateOption", "onFocus", "onInputChange", "onMenuClose", "onMenuOpen", "onKeyDown", "onValueChange", "placeholder", "readOnly", "readOnlySeparator", "required", "searchable", "multiSelect", "stickyIndicator", "valid", "value", "usePortal", "isInitiallyOpen", "fieldUniqueId", "testId"],
  _excluded2 = ["Value"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useEffect, useMemo, useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable";
import AsyncCreatable from "react-select/async-creatable";
import cx from "classnames";
import identity from "lodash/identity";
import constant from "lodash/constant";
import { useTranslator } from "@jutro/locale";
import { booleanValidationRuleShape, intlMessageShape } from "@jutro/prop-types";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
import { GenericSelectControlClearIndicator as ClearIndicator } from "./components/GenericSelectControlClearIndicator";
import { GenericSelectControlControl as Control } from "./components/GenericSelectControlControl";
import { GenericSelectControlDropdownIndicator as DropdownIndicator } from "./components/GenericSelectControlDropdownIndicator";
import { GenericSelectControlInput as Input } from "./components/GenericSelectControlInput";
import { GenericSelectControlLoadingIndicator as LoadingIndicator } from "./components/GenericSelectControlLoadingIndicator";
import { GenericSelectControlMenu as Menu } from "./components/GenericSelectControlMenu";
import { GenericSelectControlMenuList as MenuList } from "./components/GenericSelectControlMenuList";
import { GenericSelectControlMultiValue as MultiValue } from "./components/GenericSelectControlMultiValue";
import { GenericSelectControlOption as Option } from "./components/GenericSelectControlOption";
import { GenericSelectControlPlaceholder as Placeholder } from "./components/GenericSelectControlPlaceholder";
import { GenericSelectControlSingleValue as SingleValue } from "./components/GenericSelectControlSingleValue";
import { GenericSelectControlValueContainer as ValueContainer } from "./components/GenericSelectControlValueContainer";
import styles from "./GenericSelectControl.module.css";
import { messages } from "./GenericSelectControl.messages";
import { useDefaultAriaLiveMessages } from "./ariaLiveMessages";
var defaultComponents = {
  ClearIndicator: ClearIndicator,
  Control: Control,
  DropdownIndicator: DropdownIndicator,
  Input: Input,
  LoadingIndicator: LoadingIndicator,
  Menu: Menu,
  MenuList: MenuList,
  MultiValue: MultiValue,
  Option: Option,
  Placeholder: Placeholder,
  SingleValue: SingleValue,
  ValueContainer: ValueContainer
};
export { defaultComponents as GenericSelectComponents };
export var genericSelectInternalClassNamesShape = PropTypes.shape({
  control: PropTypes.string,
  controlFocused: PropTypes.string,
  menuList: PropTypes.string,
  option: PropTypes.string,
  selectIcon: PropTypes.string,
  optionDisabled: PropTypes.string,
  focusedDisabled: PropTypes.string
});
var genericSelectControlPropTypes = {
  availableValues: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  components: PropTypes.shape({
    ClearIndicator: PropTypes.elementType,
    Control: PropTypes.elementType,
    DropdownIndicator: PropTypes.elementType,
    IndicatorSeparator: PropTypes.elementType,
    Input: PropTypes.elementType,
    LoadingIndicator: PropTypes.elementType,
    Menu: PropTypes.elementType,
    MenuList: PropTypes.elementType,
    Option: PropTypes.elementType,
    Placeholder: PropTypes.elementType,
    Value: PropTypes.elementType,
    ValueContainer: PropTypes.elementType
  }),
  internalClassNames: genericSelectInternalClassNamesShape,
  createNewMessage: intlMessageShape,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  allowNew: PropTypes.bool,
  allowNewValue: PropTypes.func,
  labelId: PropTypes.string,
  loadValues: PropTypes.func,
  noOptionsMessage: intlMessageShape,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onCreateOption: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onKeyDown: PropTypes.func,
  onValueChange: PropTypes.func,
  placeholder: intlMessageShape,
  readOnly: PropTypes.bool,
  readOnlySeparator: PropTypes.string,
  required: booleanValidationRuleShape,
  searchable: PropTypes.bool,
  multiSelect: PropTypes.bool,
  stickyIndicator: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.any,
  usePortal: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  fieldUniqueId: PropTypes.string,
  testId: PropTypes.string
};
export var GenericSelectControl = function (_ref) {
  var availableValues = _ref.availableValues,
    className = _ref.className,
    components = _ref.components,
    createNewMessage = _ref.createNewMessage,
    defaultValue = _ref.defaultValue,
    disabled = _ref.disabled,
    filter = _filterInstanceProperty(_ref),
    getOptionLabel = _ref.getOptionLabel,
    getOptionValue = _ref.getOptionValue,
    id = _ref.id,
    internalClassNames = _ref.internalClassNames,
    isClearable = _ref.isClearable,
    allowNewValue = _ref.allowNewValue,
    allowNew = _ref.allowNew,
    labelId = _ref.labelId,
    loadValues = _ref.loadValues,
    noOptionsMessage = _ref.noOptionsMessage,
    onBlur = _ref.onBlur,
    onClick = _ref.onClick,
    onCreateOption = _ref.onCreateOption,
    onFocus = _ref.onFocus,
    onInputChange = _ref.onInputChange,
    onMenuCloseProp = _ref.onMenuClose,
    onMenuOpenProp = _ref.onMenuOpen,
    onKeyDownProp = _ref.onKeyDown,
    onValueChange = _ref.onValueChange,
    placeholder = _ref.placeholder,
    readOnly = _ref.readOnly,
    readOnlySeparator = _ref.readOnlySeparator,
    required = _ref.required,
    searchable = _ref.searchable,
    multiSelect = _ref.multiSelect,
    stickyIndicator = _ref.stickyIndicator,
    valid = _ref.valid,
    value = _ref.value,
    usePortal = _ref.usePortal,
    isInitiallyOpen = _ref.isInitiallyOpen,
    fieldUniqueId = _ref.fieldUniqueId,
    testId = _ref.testId,
    customProps = _objectWithoutProperties(_ref, _excluded),
    accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields"),
    translator = useTranslator(),
    selectRef = useRef(null),
    defaultAriaLiveMessages = useDefaultAriaLiveMessages(allowNew, accessibleDisabled),
    _useState = useState(isInitiallyOpen),
    _useState2 = _slicedToArray(_useState, 2),
    menuIsOpen = _useState2[0],
    setMenuIsOpen = _useState2[1];
  useEffect(function () {
    var _selectRef$current,
      inputRef = null === (_selectRef$current = selectRef.current) || void 0 === _selectRef$current ? void 0 : _selectRef$current.inputRef;
    !searchable && inputRef && (accessibleDisabled || inputRef.removeAttribute("readonly"), inputRef.setAttribute("data-testid", testId || id), required ? (inputRef.setAttribute("required", "true"), inputRef.setAttribute("aria-required", "true")) : (inputRef.removeAttribute("required"), inputRef.removeAttribute("aria-required")));
  }, [searchable, required, accessibleDisabled]);
  var onKeyDown = useCallback(function (event) {
      "ArrowDown" !== event.key && "ArrowUp" !== event.key || setMenuIsOpen(!0), "Enter" !== event.key && " " !== event.key || setMenuIsOpen(!menuIsOpen), null == onKeyDownProp || onKeyDownProp(event);
    }, [menuIsOpen, onKeyDownProp]),
    genericSelectStyles = useMemo(function () {
      return _objectSpread(_objectSpread({}, styles), {}, {
        control: cx(styles.control, internalClassNames.control),
        controlFocused: cx(styles.controlFocused, internalClassNames.controlFocused),
        menuList: cx(styles.menuList, internalClassNames.menuList),
        option: cx(styles.option, internalClassNames.option),
        selectIcon: cx(styles.selectIcon, internalClassNames.selectIcon, _defineProperty({}, styles.stickySelectIcon, stickyIndicator)),
        labpreviewBorder: styles.labpreviewBorder,
        optionDisabled: cx(styles.optionDisabled, internalClassNames.optionDisabled),
        focusedDisabled: internalClassNames.focusedDisabled
      });
    }, [internalClassNames, stickyIndicator]),
    selectClasses = cx(genericSelectStyles.genericSelect, {
      disabled: disabled
    }, className),
    creatableComponents = useMemo(function () {
      var _ref2 = components || {},
        Value = _ref2.Value,
        additionalComponents = _objectWithoutProperties(_ref2, _excluded2),
        valueComponent = multiSelect ? {
          MultiValue: Value
        } : {
          SingleValue: Value
        };
      return _objectSpread(_objectSpread(_objectSpread({
        IndicatorSeparator: null
      }, defaultComponents), additionalComponents), Value && valueComponent);
    }, [multiSelect, components]),
    formatCreateLabel = useCallback(function (newOptionValue) {
      return translator(createNewMessage || messages.createNewMessage, {
        message: newOptionValue
      });
    }, [translator, createNewMessage]),
    translatedPlaceholder = useMemo(function () {
      return translator(placeholder) || null;
    }, [placeholder, translator]),
    translatedNoOptionsMessage = useMemo(function () {
      return constant(translator(noOptionsMessage));
    }, [noOptionsMessage, translator]),
    filterOption = useMemo(function () {
      return filter && function (_ref3, text) {
        var data = _ref3.data;
        return data.__isNew__ || filter(data, text);
      };
    }, [filter]),
    loadOptions = useMemo(function () {
      if (loadValues) return function () {
        return _Promise.resolve(loadValues.apply(void 0, arguments));
      };
    }, [loadValues]),
    screenReaderStatus = useCallback(function (_ref4) {
      var count = _ref4.count;
      return translator(searchable ? messages.numberOfAvailableResults : messages.listOfAvailableOptions, {
        count: count
      });
    }, [translator, searchable]),
    accessibleDisabledProps = useMemo(function () {
      return accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
        onBlur: onBlur,
        onChange: onValueChange,
        onClick: onClick,
        onFocus: onFocus,
        onKeyDown: onKeyDown
      })), {}, {
        onMenuOpen: function () {},
        onMenuClose: function () {},
        onInputChange: function () {},
        "aria-disabled": !0,
        "aria-readonly": !0,
        isDisabled: void 0,
        isSearchable: !0,
        menuIsOpen: !1
      }) : {};
    }, [accessibleDisabled, onBlur, onClick, onFocus, onKeyDown, onValueChange]);
  if (readOnly) {
    if (!value) return null;
    var values = Array.isArray(value) ? value : [value],
      stringValue = _mapInstanceProperty(values).call(values, getOptionLabel).join(readOnlySeparator) || null;
    return stringValue && React.createElement("span", {
      id: id
    }, stringValue);
  }
  var SelectComponent = loadValues ? AsyncCreatable : Creatable,
    selectComponentId = "".concat(id, "-wrapper"),
    ariaLabel = labelId ? void 0 : translatedPlaceholder;
  return React.createElement(SelectComponent, _extends({
    id: selectComponentId,
    inputId: fieldUniqueId,
    "aria-labelledby": labelId,
    "aria-label": ariaLabel,
    blurInputOnSelect: !1,
    className: selectClasses,
    components: creatableComponents,
    defaultValue: defaultValue,
    filterOption: filterOption,
    formatCreateLabel: formatCreateLabel,
    getOptionLabel: getOptionLabel,
    getOptionValue: getOptionValue,
    isClearable: isClearable,
    isDisabled: disabled,
    isMulti: multiSelect,
    isValidNewOption: allowNewValue,
    isSearchable: searchable,
    loadOptions: loadOptions,
    noOptionsMessage: translatedNoOptionsMessage,
    onBlur: onBlur,
    onChange: onValueChange,
    onClick: onClick,
    onCreateOption: onCreateOption,
    onFocus: onFocus,
    onInputChange: onInputChange,
    onMenuClose: function () {
      setMenuIsOpen(!1), null == onMenuCloseProp || onMenuCloseProp();
    },
    onMenuOpen: function () {
      setMenuIsOpen(!0), null == onMenuOpenProp || onMenuOpenProp();
    },
    onKeyDown: onKeyDown,
    options: availableValues,
    placeholder: translatedPlaceholder,
    required: required,
    ref: selectRef,
    value: value,
    menuIsOpen: menuIsOpen,
    genericSelectStyles: genericSelectStyles,
    invalid: !valid,
    styles: usePortal ? {
      menuPortal: function (style) {
        return _objectSpread(_objectSpread({}, style), {}, {
          zIndex: "var(--GW-Z-INDEX-4)"
        });
      }
    } : {
      menuPortal: function (style) {
        return _objectSpread(_objectSpread({}, style), {}, {
          zIndex: "var(--GW-Z-INDEX-4)",
          top: void 0,
          left: void 0
        });
      }
    },
    ariaLiveMessages: defaultAriaLiveMessages,
    menuPortalTarget: usePortal && document.querySelector("body"),
    menuPlacement: "auto",
    menuPosition: "fixed",
    minMenuHeight: 64,
    testId: testId || id,
    screenReaderStatus: screenReaderStatus
  }, customProps, accessibleDisabledProps));
};
GenericSelectControl.propTypes = genericSelectControlPropTypes, GenericSelectControl.defaultProps = {
  getOptionLabel: function (value) {
    return value.toString();
  },
  getOptionValue: identity,
  internalClassNames: {},
  noOptionsMessage: messages.noOptionsMessage,
  createNewMessage: messages.createNewMessage,
  readOnlySeparator: ", ",
  valid: !0,
  usePortal: !0,
  allowNewValue: function () {
    return !1;
  }
}, GenericSelectControl.__docgenInfo = {
  description: "Generic component for dropdowns.\nYou can make your custom dropdown component inherit from the generic field component.\nThat way, your component will have default FieldComponent props, such as ’required' and methods (e.g. validation)`.\n@type {React.FC<PropTypes.InferProps<typeof genericSelectControlPropTypes>>}",
  methods: [],
  displayName: "GenericSelectControl",
  props: {
    getOptionLabel: {
      defaultValue: {
        value: "value => value.toString()",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to get label for each option"
    },
    getOptionValue: {
      defaultValue: {
        value: "identity",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to get value for each option"
    },
    internalClassNames: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "genericSelectInternalClassNamesShape"
      },
      required: !1,
      description: "Map of CSS class names for individual parts of component's styles"
    },
    noOptionsMessage: {
      defaultValue: {
        value: "messages.noOptionsMessage",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to display where there are no options that match\nthe text the user enters."
    },
    createNewMessage: {
      defaultValue: {
        value: "messages.createNewMessage",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to display when a new options is being created by the user."
    },
    readOnlySeparator: {
      defaultValue: {
        value: "', '",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Separator for the readonly value list"
    },
    valid: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If false component will be highlighted as invalid"
    },
    usePortal: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent"
    },
    allowNewValue: {
      defaultValue: {
        value: "() => false",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to determine if creating new values is allowed"
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Array of choice objects to display"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    components: {
      type: {
        name: "shape",
        value: {
          ClearIndicator: {
            name: "elementType",
            required: !1
          },
          Control: {
            name: "elementType",
            required: !1
          },
          DropdownIndicator: {
            name: "elementType",
            required: !1
          },
          IndicatorSeparator: {
            name: "elementType",
            required: !1
          },
          Input: {
            name: "elementType",
            required: !1
          },
          LoadingIndicator: {
            name: "elementType",
            required: !1
          },
          Menu: {
            name: "elementType",
            required: !1
          },
          MenuList: {
            name: "elementType",
            required: !1
          },
          Option: {
            name: "elementType",
            required: !1
          },
          Placeholder: {
            name: "elementType",
            required: !1
          },
          Value: {
            name: "elementType",
            required: !1
          },
          ValueContainer: {
            name: "elementType",
            required: !1
          }
        }
      },
      required: !1,
      description: "Components to customize separate parts of select"
    },
    defaultValue: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is disabled"
    },
    filter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to perform custom item filtering"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component. applied to control and referenced by label"
    },
    isClearable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true ClearIndicator will be shown"
    },
    allowNew: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Boolean that is passed for a11y and can be passed to allowNewValue callback indicating if new dropdown values could be added"
    },
    labelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of label element for this field"
    },
    loadValues: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for asynchronous data loading"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when blur event is fired"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when click event is fired"
    },
    onCreateOption: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when new option created\n(when new option created GenericSelectControl won't invoke onValueChange() if onCreateOption() provided)"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when focus event is fired"
    },
    onInputChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when text input value changed"
    },
    onMenuClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when menu closed"
    },
    onMenuOpen: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when menu opened"
    },
    onKeyDown: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when key is pressed"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value is changed; receives new value and (model or path) for this component"
    },
    placeholder: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Placeholder to display on empty component"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is readonly"
    },
    required: {
      type: {
        name: "custom",
        raw: "booleanValidationRuleShape"
      },
      required: !1,
      description: "Specifies that the input field must be filled"
    },
    searchable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Allows you to type in dropdown field to find options."
    },
    multiSelect: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should component allow a multiple selection"
    },
    stickyIndicator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines if dropdown indicator will be sticky"
    },
    value: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Value to display in control"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should dropdown be initially opened"
    },
    controlShouldRenderValue: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should control render value"
    },
    backspaceRemovesValue: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should backspace remove value"
    },
    fieldUniqueId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Field unique id generated by field component. If GenericSelect is used as a standalone component use generateComponentId() helper function to ensure the id is uniqe."
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided data attribute set to id."
    }
  }
}, GenericSelectControl.__docgenInfo = {
  componentName: "GenericSelectControl",
  packageName: "@jutro/components",
  description: "Generic component for dropdowns.\nYou can make your custom dropdown component inherit from the generic field component.\nThat way, your component will have default FieldComponent props, such as ’required' and methods (e.g. validation)`.",
  displayName: "GenericSelectControl",
  methods: [],
  actualName: "GenericSelectControl",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "any"
        }
      },
      required: !1,
      description: "Array of choice objects to display"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    components: {
      type: {
        name: "shape",
        value: {
          ClearIndicator: {
            name: "elementType",
            required: !1
          },
          Control: {
            name: "elementType",
            required: !1
          },
          DropdownIndicator: {
            name: "elementType",
            required: !1
          },
          IndicatorSeparator: {
            name: "elementType",
            required: !1
          },
          Input: {
            name: "elementType",
            required: !1
          },
          LoadingIndicator: {
            name: "elementType",
            required: !1
          },
          Menu: {
            name: "elementType",
            required: !1
          },
          MenuList: {
            name: "elementType",
            required: !1
          },
          Option: {
            name: "elementType",
            required: !1
          },
          Placeholder: {
            name: "elementType",
            required: !1
          },
          Value: {
            name: "elementType",
            required: !1
          },
          ValueContainer: {
            name: "elementType",
            required: !1
          }
        }
      },
      required: !1,
      description: "Components to customize separate parts of select"
    },
    internalClassNames: {
      type: {
        name: "custom",
        raw: "genericSelectInternalClassNamesShape"
      },
      required: !1,
      description: "Map of CSS class names for individual parts of component's styles",
      defaultValue: {
        value: "{}",
        computed: !1
      }
    },
    createNewMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to display when a new options is being created by the user.",
      defaultValue: {
        value: "messages.createNewMessage",
        computed: !0
      }
    },
    defaultValue: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is disabled"
    },
    filter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to perform custom item filtering"
    },
    getOptionLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to get label for each option",
      defaultValue: {
        value: "value => value.toString()",
        computed: !1
      }
    },
    getOptionValue: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to get value for each option",
      defaultValue: {
        value: "identity",
        computed: !0
      }
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component. applied to control and referenced by label"
    },
    isClearable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true ClearIndicator will be shown"
    },
    allowNew: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Boolean that is passed for a11y and can be passed to allowNewValue callback indicating if new dropdown values could be added"
    },
    allowNewValue: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to determine if creating new values is allowed",
      defaultValue: {
        value: "() => false",
        computed: !1
      }
    },
    labelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of label element for this field"
    },
    loadValues: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for asynchronous data loading"
    },
    noOptionsMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to display where there are no options that match\nthe text the user enters.",
      defaultValue: {
        value: "messages.noOptionsMessage",
        computed: !0
      }
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when blur event is fired"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when click event is fired"
    },
    onCreateOption: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when new option created\n(when new option created GenericSelectControl won't invoke onValueChange() if onCreateOption() provided)"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when focus event is fired"
    },
    onInputChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when text input value changed"
    },
    onMenuClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when menu closed"
    },
    onMenuOpen: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when menu opened"
    },
    onKeyDown: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when key is pressed"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value is changed; receives new value and (model or path) for this component"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Placeholder to display on empty component"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is readonly"
    },
    readOnlySeparator: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Separator for the readonly value list",
      defaultValue: {
        value: "', '",
        computed: !1
      }
    },
    required: {
      type: {
        name: "custom",
        raw: "booleanValidationRuleShape"
      },
      required: !1,
      description: "Specifies that the input field must be filled"
    },
    searchable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Allows you to type in dropdown field to find options."
    },
    multiSelect: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should component allow a multiple selection"
    },
    stickyIndicator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines if dropdown indicator will be sticky"
    },
    valid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If false component will be highlighted as invalid",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    value: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Value to display in control"
    },
    usePortal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should dropdown be initially opened"
    },
    controlShouldRenderValue: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should control render value"
    },
    backspaceRemovesValue: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Should backspace remove value"
    },
    fieldUniqueId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Field unique id generated by field component. If GenericSelect is used as a standalone component use generateComponentId() helper function to ensure the id is uniqe."
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided data attribute set to id."
    }
  }
};