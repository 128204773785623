import React from "react";
import { Icon } from "../../Icon/Icon";
import styles from "./FieldMessage.module.css";
export var ValidationIconAndMessage = function (_ref) {
  var message = _ref.message,
    _ref$iconWrapperClass = _ref.iconWrapperClassName,
    iconWrapperClassName = void 0 === _ref$iconWrapperClass ? styles.iconWrapper : _ref$iconWrapperClass,
    _ref$iconClassName = _ref.iconClassName,
    iconClassName = void 0 === _ref$iconClassName ? styles.error : _ref$iconClassName,
    icon = _ref.icon,
    isFocused = _ref.isFocused,
    errorDetails = _ref.errorDetails;
  return React.createElement("span", {
    className: iconWrapperClassName
  }, React.createElement(Icon, {
    className: iconClassName,
    icon: icon,
    size: "large"
  }), React.createElement("span", {
    role: "alert"
  }, message, !isFocused && errorDetails ? "..." : null));
};
ValidationIconAndMessage.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ValidationIconAndMessage",
  props: {
    iconWrapperClassName: {
      defaultValue: {
        value: "styles.iconWrapper",
        computed: !0
      },
      required: !1
    },
    iconClassName: {
      defaultValue: {
        value: "styles.error",
        computed: !0
      },
      required: !1
    }
  }
}, ValidationIconAndMessage.__docgenInfo = {
  componentName: "ValidationIconAndMessage",
  packageName: "@jutro/components",
  description: "",
  displayName: "ValidationIconAndMessage",
  methods: [],
  actualName: "ValidationIconAndMessage",
  props: {
    iconWrapperClassName: {
      defaultValue: {
        value: "styles.iconWrapper",
        computed: !0
      },
      required: !1
    },
    iconClassName: {
      defaultValue: {
        value: "styles.error",
        computed: !0
      },
      required: !1
    }
  }
};