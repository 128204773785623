import { createContext, useContext } from "react";
import LocaleService from "./LocaleService";
var LanguageContext = createContext({
  availableLanguages: [],
  get language() {
    return LocaleService.getCurrentLanguage() || "";
  },
  languageOnChangeCallback: void 0
});
export var useLanguage = function () {
  var contextValue = useContext(LanguageContext);
  if (!contextValue) throw new Error("Please check that your app is wrapped in LanguageProvider or GlobalizationProvider");
  return contextValue;
};
export var LanguageContextProvider = LanguageContext.Provider;
export var LanguageContextConsumer = LanguageContext.Consumer;
export default LanguageContext;