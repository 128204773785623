import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { useMemo, useState } from "react";
export var TYPE_LARGE = "large";
export var TYPE_THIN = "thin";
export var useIsDraggedOver = function () {
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    counter = _useState2[0],
    setCounter = _useState2[1],
    funcs = useMemo(function () {
      return {
        pushDrag: function () {
          return setCounter(function (prev) {
            return prev + 1;
          });
        },
        popDrag: function () {
          return setCounter(function (prev) {
            return Math.max(0, prev - 1);
          });
        },
        resetIsDraggedOver: function () {
          return setCounter(0);
        }
      };
    }, []);
  return useMemo(function () {
    return _objectSpread(_objectSpread({}, funcs), {}, {
      isDraggedOver: counter > 0
    });
  }, [funcs, counter]);
};
export var getFileName = function (filePathOrObj) {
  var filePath = "object" === _typeof(filePathOrObj) ? filePathOrObj.name : filePathOrObj;
  return null == filePath ? void 0 : filePath.split(/[/\\]/).pop();
};