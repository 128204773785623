import PropTypes from "prop-types";
export var languageSelectorPropTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  toggleClassName: PropTypes.string,
  languageValue: PropTypes.string,
  skipPropagation: PropTypes.bool,
  hideDropdownIndicator: PropTypes.bool,
  hideLabel: PropTypes.bool,
  availableLanguageValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  renderLanguageLabel: PropTypes.func,
  onLanguageValueChange: PropTypes.func
};
export var languageSelectorShape = PropTypes.shape(languageSelectorPropTypes);