import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _Grid$propTypes,
  _Grid$propTypes2,
  _excluded = ["gridItems", "defaultGridItem", "children", "gap"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { Grid } from "./Grid";
import { GridItem } from "./GridItem";
import { useBreakpoint } from "../breakpoint/useBreakpoint";
var gridItemOptions = omit(GridItem.propTypes, ["children"]),
  gridItemOptionsShape = PropTypes.shape(gridItemOptions),
  gridLayoutProps = _objectSpread(_objectSpread({}, Grid.propTypes), {}, {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    defaultGridItem: gridItemOptionsShape,
    gridItems: PropTypes.arrayOf(gridItemOptionsShape),
    gap: null === (_Grid$propTypes = Grid.propTypes) || void 0 === _Grid$propTypes ? void 0 : _Grid$propTypes.gap,
    tag: null === (_Grid$propTypes2 = Grid.propTypes) || void 0 === _Grid$propTypes2 ? void 0 : _Grid$propTypes2.tag,
    phone: PropTypes.object,
    phoneWide: PropTypes.object,
    tablet: PropTypes.object
  });
export var GridLayout = function (props) {
  var _context,
    breakpointProps = useBreakpoint(props).breakpointProps,
    gridItems = breakpointProps.gridItems,
    defaultGridItem = breakpointProps.defaultGridItem,
    children = breakpointProps.children,
    gap = breakpointProps.gap,
    other = _objectWithoutProperties(breakpointProps, _excluded);
  return React.createElement(Grid, _extends({}, other, {
    gap: gap
  }), _mapInstanceProperty(_context = React.Children).call(_context, children, function (child, index) {
    if (!child) return null;
    var itemOptions = null == gridItems ? void 0 : gridItems[index];
    return React.createElement(GridItem, _extends({
      clone: !0
    }, defaultGridItem, itemOptions), child);
  }));
};
GridLayout.propTypes = gridLayoutProps, GridLayout.defaultProps = _objectSpread(_objectSpread({}, omit(Grid.defaultProps, ["children"])), {}, {
  gridItems: [],
  tablet: {
    columns: [1],
    gridItems: []
  },
  phoneWide: {
    columns: [1],
    gridItems: []
  },
  phone: {
    columns: [1],
    gridItems: []
  },
  gap: "large",
  tag: "section"
}), GridLayout.displayName = "GridLayout", GridLayout.__docgenInfo = {
  description: "Renders a CSS Grid and applies GridItem styles to each child\n(using GridItem's `clone` property (React.cloneElement)).\nFor tablet and mobile renders single column grid by default.\n\n@metadataType layout",
  methods: [],
  displayName: "GridLayout",
  props: {
    gridItems: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "gridItemOptionsShape"
        }
      },
      required: !1,
      description: "An array of GridItem properties to be applied to child with the same index.\nIf provided, it will extends/override the `defaultGridItem` properties for each item.\nPassing `null` or `undefined` will skip applying it to GridItem."
    },
    tablet: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridLayout property for use at 'tablet' and 'phone' breakpoint;"
    },
    phoneWide: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridLayout property for use at 'phoneWide' breakpoint"
    },
    phone: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridLayout property for use at 'phone' breakpoint"
    },
    gap: {
      defaultValue: {
        value: "'large'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "Grid.propTypes?.gap"
      },
      required: !1,
      description: "Gap between rows and columns"
    },
    tag: {
      defaultValue: {
        value: "'section'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "Grid.propTypes?.tag"
      },
      required: !1,
      description: "Optional dom tag to render"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "React elements"
    },
    defaultGridItem: {
      type: {
        name: "custom",
        raw: "gridItemOptionsShape"
      },
      required: !1,
      description: "`GridItem` properties that will to be applied to each child by default."
    }
  },
  composes: ["./Grid"]
}, GridLayout.__docgenInfo = {
  componentName: "GridLayout",
  packageName: "@jutro/layout",
  description: "Renders a CSS Grid and applies GridItem styles to each child\n(using GridItem's `clone` property (React.cloneElement)).\nFor tablet and mobile renders single column grid by default.",
  displayName: "GridLayout",
  methods: [],
  actualName: "GridLayout",
  metadataType: "layout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "React elements"
    },
    defaultGridItem: {
      type: {
        name: "custom",
        raw: "gridItemOptionsShape"
      },
      required: !1,
      description: "`GridItem` properties that will to be applied to each child by default."
    },
    gridItems: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "gridItemOptionsShape"
        }
      },
      required: !1,
      description: "An array of GridItem properties to be applied to child with the same index.\nIf provided, it will extends/override the `defaultGridItem` properties for each item.\nPassing `null` or `undefined` will skip applying it to GridItem.",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    gap: {
      type: {
        name: "custom",
        raw: "Grid.propTypes?.gap"
      },
      required: !1,
      description: "Gap between rows and columns",
      defaultValue: {
        value: "'large'",
        computed: !1
      }
    },
    tag: {
      type: {
        name: "custom",
        raw: "Grid.propTypes?.tag"
      },
      required: !1,
      description: "Optional dom tag to render",
      defaultValue: {
        value: "'section'",
        computed: !1
      }
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridLayout property for use at 'phone' breakpoint",
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      }
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridLayout property for use at 'phoneWide' breakpoint",
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      }
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridLayout property for use at 'tablet' and 'phone' breakpoint;",
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      }
    }
  },
  composes: ["./Grid"]
};