import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isFunction from "lodash/isFunction";
import { logDeprecationMessage, useDeprecationWarning } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./LogoTitle.module.css";
export var LogoTitle = function (_ref) {
  var src = _ref.src,
    titleProp = _ref.title,
    renderTitleProp = _ref.renderTitle,
    alt = _ref.alt,
    className = _ref.className,
    titleClassName = _ref.titleClassName,
    logoClassName = _ref.logoClassName,
    noDeprecationWarnings = _ref.noDeprecationWarnings;
  useDeprecationWarning({
    componentName: "LogoTitle",
    noDeprecationWarnings: noDeprecationWarnings
  });
  var translator = useTranslator(),
    classes = cx(styles.logoTitle, className),
    logoClasses = cx(styles.logo, logoClassName),
    titleClasses = cx(styles.title, titleClassName),
    title = isFunction(titleProp) ? void 0 : titleProp,
    renderTitle = isFunction(titleProp) ? titleProp : renderTitleProp,
    displayTitle = title || renderTitle;
  return isFunction(titleProp) && logDeprecationMessage("Passing a function to 'title' prop in LogoTitle", "'renderTitle' prop"), React.createElement("span", {
    className: classes,
    "data-testid": "logo-title-wrapper"
  }, src && React.createElement("img", {
    className: logoClasses,
    src: src,
    alt: translator(alt)
  }), displayTitle && React.createElement("span", {
    className: titleClasses,
    "data-testid": "logo-title-title-wrapper"
  }, renderTitle ? renderTitle(translator) : translator(title)));
};
var logoTitlePropTypes = {
  src: PropTypes.string,
  alt: intlMessageShape,
  title: PropTypes.oneOfType([PropTypes.string, intlMessageShape, PropTypes.func]),
  renderTitle: PropTypes.func,
  className: PropTypes.string,
  logoClassName: PropTypes.string,
  titleClassName: PropTypes.string
};
LogoTitle.propTypes = logoTitlePropTypes, LogoTitle.__docgenInfo = {
  description: "LogoTitle\n\n@metadataType element\n@deprecated since v8.9.0",
  methods: [],
  displayName: "LogoTitle",
  props: {
    src: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Src for the logo image"
    },
    alt: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Alternative text for the logo if the image does not exist"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "Title as string, intl message, or func"
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Title rendered by a callback"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class names"
    },
    logoClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the logo"
    },
    titleClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the title"
    }
  }
}, LogoTitle.__docgenInfo = {
  componentName: "LogoTitle",
  packageName: "@jutro/components",
  description: "LogoTitle",
  displayName: "LogoTitle",
  methods: [],
  actualName: "LogoTitle",
  metadataType: "element",
  props: {
    src: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Src for the logo image"
    },
    alt: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Alternative text for the logo if the image does not exist"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "Title as string, intl message, or func"
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Title rendered by a callback"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class names"
    },
    logoClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the logo"
    },
    titleClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for the title"
    }
  }
};