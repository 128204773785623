import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { Flex } from "@jutro/layout";
import { intlMessageShape, nestedTooltipShape } from "@jutro/prop-types";
import styles from "./Card.module.css";
import { TooltipIcon } from "../Tooltip/TooltipIcon";
export var cardHeaderAlignment = ["center", "left", "right"];
var cardHeaderPropTypes = {
  headerClassName: PropTypes.string,
  title: intlMessageShape,
  subTitle: intlMessageShape,
  headerAlignment: PropTypes.oneOf(cardHeaderAlignment),
  tooltip: nestedTooltipShape,
  renderHeader: PropTypes.func
};
export var CardHeader = function (_ref) {
  var headerClassName = _ref.headerClassName,
    tooltip = _ref.tooltip,
    renderHeader = _ref.renderHeader,
    headerAlignment = _ref.headerAlignment,
    title = _ref.title,
    subTitle = _ref.subTitle,
    translator = useTranslator();
  if (!title && !renderHeader) return null;
  var headerClasses = cx(styles.header, headerAlignment && styles[headerAlignment], headerClassName);
  return React.createElement(Flex, {
    "data-testid": "card-header",
    className: headerClasses,
    alignItems: "baseline",
    direction: "column",
    gap: "none"
  }, React.createElement(Flex, {
    alignItems: "top",
    gap: "small",
    wrap: !1
  }, React.createElement("h4", {
    className: styles.headerText
  }, renderHeader ? renderHeader(title) : translator(title)), tooltip && React.createElement("div", null, React.createElement(TooltipIcon, tooltip))), title && subTitle && React.createElement("div", {
    className: styles.subTitle
  }, translator(subTitle)));
};
CardHeader.propTypes = cardHeaderPropTypes, CardHeader.__docgenInfo = {
  componentName: "CardHeader",
  packageName: "@jutro/components",
  description: "CardHeader",
  displayName: "CardHeader",
  methods: [],
  actualName: "CardHeader",
  props: {
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles to be applied to CardHeader"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The header title to be displayed in the Card"
    },
    subTitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Subtitle of the Card"
    },
    headerAlignment: {
      type: {
        name: "enum",
        value: [{
          value: "'center'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Alignment of the header"
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "nestedTooltipShape"
      },
      required: !1,
      description: "Card header tooltip"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a header to be displayed in the Card"
    }
  }
};