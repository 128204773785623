import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Flex } from "@jutro/layout";
import styles from "./ActionTitleBar.module.css";
export var TitleElement = function (_ref) {
  var children = _ref.children,
    className = _ref.className;
  return React.createElement(Flex, {
    className: cx(styles.titleElement, className),
    alignItems: "stretch"
  }, children);
};
TitleElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}, TitleElement.displayName = "TitleElement", TitleElement.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "TitleElement",
  props: {
    children: {
      type: {
        name: "union",
        value: [{
          name: "arrayOf",
          value: {
            name: "node"
          }
        }, {
          name: "node"
        }]
      },
      required: !0,
      description: ""
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    }
  }
}, TitleElement.__docgenInfo = {
  componentName: "TitleElement",
  packageName: "@jutro/components",
  description: "",
  displayName: "TitleElement",
  methods: [],
  actualName: "TitleElement",
  metadataType: "container",
  props: {
    children: {
      type: {
        name: "union",
        value: [{
          name: "arrayOf",
          value: {
            name: "node"
          }
        }, {
          name: "node"
        }]
      },
      required: !0,
      description: ""
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    }
  }
};