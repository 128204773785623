import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { createRef } from "react";
import PropTypes from "prop-types";
import { isNilValue } from "@jutro/data";
import cx from "classnames";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import styles from "./SwitchField.module.css";
function isChecked(value) {
  return !isNilValue(value) && "true" === value.toString();
}
export var SwitchField = function (_FieldComponent) {
  _inherits(SwitchField, FieldComponent);
  var _super = _createSuper(SwitchField);
  function SwitchField(props) {
    var _this;
    return _classCallCheck(this, SwitchField), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "handleCheckChange", function (event) {
      var value = event.target.checked;
      _this.notifyChange(value);
    }), _defineProperty(_assertThisInitialized(_this), "handleKeyPress", function (event) {
      "Enter" === event.key && _this.toggleValue(event);
    }), _defineProperty(_assertThisInitialized(_this), "handleClick", function (event) {
      _this.inputRef.current.focus(), _this.toggleValue(event);
    }), _defineProperty(_assertThisInitialized(_this), "isValueChangeAllowed", function () {
      return !(_this.props.disabled || _this.props.readOnly);
    }), _defineProperty(_assertThisInitialized(_this), "toggleValue", function (event) {
      if (_this.isValueChangeAllowed()) {
        event.preventDefault();
        var checked = isChecked(_this.props.value);
        _this.notifyChange(!checked);
      }
    }), _this.inputRef = createRef(), _this;
  }
  return _createClass(SwitchField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(SwitchField.prototype), "render", this).call(this);
    }
  }, {
    key: "shouldRenderTooltipIcon",
    value: function () {
      return !1;
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps, theme) {
      return this.renderControl(breakpointProps, theme);
    }
  }, {
    key: "renderLabel",
    value: function () {
      return null;
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _cx,
        _this2 = this,
        value = breakpointProps.value,
        disabled = breakpointProps.disabled,
        label = breakpointProps.label,
        required = breakpointProps.required,
        controlClassName = breakpointProps.controlClassName,
        hideLabel = breakpointProps.hideLabel,
        children = breakpointProps.children,
        detailElement = breakpointProps.detailElement,
        labelPosition = breakpointProps.labelPosition,
        readOnly = breakpointProps.readOnly,
        fullWidth = breakpointProps.fullWidth,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        translator = this.translator,
        fieldUniqueId = this.fieldUniqueId,
        checked = isChecked(value),
        isLabelTop = "top" === labelPosition,
        classes = cx(styles.switch, (_defineProperty(_cx = {}, styles.switchLabelTop, isLabelTop), _defineProperty(_cx, styles.fullWidth, fullWidth), _cx)),
        iconClasses = cx(_defineProperty({}, styles.inputLabel, !isLabelTop), styles.switchIcon),
        detailVisible = checked && (children || detailElement),
        detail = React.createElement("div", {
          className: cx(_defineProperty({}, styles.detailVisible, detailVisible)),
          role: "region",
          "aria-live": "assertive"
        }, detailVisible && (children || detailElement)),
        accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields"),
        accessibleDisabledProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
          onKeyPress: this.onKeyPress,
          onChange: this.handleCheckChange,
          onBlur: this.handleBlur,
          onFocus: this.handleFocus
        })), {}, {
          className: cx({
            disabled: disabled
          }),
          disabled: void 0,
          "aria-readonly": !0,
          "aria-disabled": !0,
          readOnly: !0
        }) : {},
        switchInput = React.createElement("input", _extends({
          id: fieldUniqueId,
          type: "checkbox",
          ref: this.inputRef,
          value: id,
          checked: checked,
          onKeyPress: this.handleKeyPress,
          onChange: this.handleCheckChange,
          onBlur: this.handleBlur,
          onFocus: this.handleFocus,
          disabled: disabled || readOnly,
          required: required,
          role: "switch",
          "aria-checked": checked,
          "aria-label": hideLabel ? translator(label) : null
        }, this.generateDataPathProperty(), {
          "data-testid": testId || id
        }, accessibleDisabledProps)),
        labelNode = _get(_getPrototypeOf(SwitchField.prototype), "renderLabel", this).call(this, breakpointProps, styles.label);
      return React.createElement(React.Fragment, null, React.createElement("div", {
        className: classes
      }, !hideLabel && React.createElement("div", {
        className: styles.labelSection
      }, labelNode), React.createElement("div", {
        className: cx(styles.controlSection, controlClassName)
      }, switchInput, React.createElement("span", _extends({
        onClick: this.handleClick,
        className: iconClasses,
        "data-testid": "switch-field-control"
      }, accessibleDisabled && {
        onClick: function () {
          _this2.inputRef.current.focus();
        }
      })))), detail);
    }
  }]), SwitchField;
}();
_defineProperty(SwitchField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  detailElement: PropTypes.node,
  labelPosition: PropTypes.oneOf(["left", "top"])
})), _defineProperty(SwitchField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  labelPosition: "left",
  phone: {
    labelPosition: "left"
  }
})), _defineProperty(SwitchField, "contextType", FieldComponent.contextType), SwitchField.__docgenInfo = {
  description: 'Renders a "switch" with a label. Holds a value of `true` or `false`.\n@typedef {typeof SwitchField.propTypes} SwitchFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<SwitchFieldPropTypes>>\n\n@metadataType field',
  methods: [{
    name: "handleCheckChange",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleKeyPress",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleClick",
    docblock: "Handles the click on the switch button and focuses the invisible input element\n\n@param {Event} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "Event"
      },
      optional: !1
    }],
    returns: null,
    description: "Handles the click on the switch button and focuses the invisible input element"
  }, {
    name: "isValueChangeAllowed",
    docblock: "Checks if component state allows for a change to be propagated ei. disabled and readOnly are set to false\n\n@returns {boolean|boolean}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "boolean"
        }, {
          name: "boolean"
        }]
      }
    },
    description: "Checks if component state allows for a change to be propagated ei. disabled and readOnly are set to false"
  }, {
    name: "toggleValue",
    docblock: "Controls toggling values of the switch button\n\n@param {Event} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "Event"
      },
      optional: !1
    }],
    returns: null,
    description: "Controls toggling values of the switch button"
  }, {
    name: "shouldRenderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }, {
      name: "theme",
      type: null
    }],
    returns: null
  }, {
    name: "renderLabel",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "SwitchField",
  props: {
    labelPosition: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets the position of the label"
    },
    phone: {
      defaultValue: {
        value: "{ labelPosition: 'left' }",
        computed: !1
      },
      required: !1
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Default value"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, splits the label and control as far apart as possible."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to render when checkbox is selected, as processed by the `MetadataContent` component (metadata 3.0)"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children to render when checkbox is selected (metadata 3.0)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, SwitchField.__docgenInfo = {
  componentName: "SwitchField",
  packageName: "@jutro/components",
  description: 'Renders a "switch" with a label. Holds a value of `true` or `false`.',
  displayName: "SwitchField",
  methods: [{
    name: "handleCheckChange",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleKeyPress",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleClick",
    docblock: "Handles the click on the switch button and focuses the invisible input element\n\n@param {Event} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "Event"
      },
      optional: !1
    }],
    returns: null,
    description: "Handles the click on the switch button and focuses the invisible input element"
  }, {
    name: "isValueChangeAllowed",
    docblock: "Checks if component state allows for a change to be propagated ei. disabled and readOnly are set to false\n\n@returns {boolean|boolean}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "boolean"
        }, {
          name: "boolean"
        }]
      }
    },
    description: "Checks if component state allows for a change to be propagated ei. disabled and readOnly are set to false"
  }, {
    name: "toggleValue",
    docblock: "Controls toggling values of the switch button\n\n@param {Event} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "Event"
      },
      optional: !1
    }],
    returns: null,
    description: "Controls toggling values of the switch button"
  }, {
    name: "shouldRenderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }, {
      name: "theme",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderLabel",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "SwitchField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Default value"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, splits the label and control as far apart as possible."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to render when checkbox is selected, as processed by the `MetadataContent` component (metadata 3.0)"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children to render when checkbox is selected (metadata 3.0)"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets the position of the label",
      defaultValue: {
        value: "'left'",
        computed: !1
      }
    },
    phone: {
      defaultValue: {
        value: "{ labelPosition: 'left' }",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};