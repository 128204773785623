import { defineMessages } from "react-intl";
export var messages = defineMessages({
  filterLabel: {
    id: "jutro-components.TreeView.filterLabel",
    defaultMessage: "Search - entering text will update the data below"
  },
  filterPlaceholder: {
    id: "jutro-components.TreeView.filterPlaceholder",
    defaultMessage: "Search"
  },
  collapseAll: {
    id: "jutro-components.TreeView.collapseAll",
    defaultMessage: "Collapse all"
  },
  expandAll: {
    id: "jutro-components.TreeView.expandAll",
    defaultMessage: "Expand all"
  },
  noMatchesFound: {
    id: "jutro-components.TreeView.noMatchesFound",
    defaultMessage: "No matches found"
  },
  clearFilter: {
    id: "jutro-components.TreeView.clearFilter",
    defaultMessage: "Clear filter"
  }
});