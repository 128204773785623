import React, { useCallback } from "react";
import { useKeyPress, useAccessibleRef, combineRefs } from "@jutro/platform";
import { IconButton } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { messages } from "./AppSwitcher.messages";
export var availableIconColors = ["light", "neutral", "dark"];
export var AppSwitcherButton = React.forwardRef(function (_ref, ref) {
  var id = _ref.id,
    togglePopover = _ref.togglePopover,
    hasShownPopover = _ref.hasShownPopover,
    iconColor = _ref.iconColor,
    iconClassName = _ref.iconClassName,
    isFocused = _ref.isFocused,
    onIndexFocusChange = _ref.onIndexFocusChange,
    iconRef = useAccessibleRef(isFocused),
    translator = useTranslator(),
    showPopover = useCallback(function () {
      !hasShownPopover && togglePopover && togglePopover();
    }, [hasShownPopover, togglePopover]),
    handleArrowUpPress = useCallback(function (event, isDownKeyPress) {
      isDownKeyPress && (onIndexFocusChange && onIndexFocusChange(-1), showPopover()), event.preventDefault();
    }, [onIndexFocusChange, showPopover]),
    handleArrowDownPress = useCallback(function (event, isDownKeyPress) {
      isDownKeyPress && (onIndexFocusChange && onIndexFocusChange(0), showPopover()), event.preventDefault();
    }, [onIndexFocusChange, showPopover]);
  return useKeyPress(iconRef, ["ArrowUp"], handleArrowUpPress), useKeyPress(iconRef, ["ArrowDown"], handleArrowDownPress), React.createElement(IconButton, {
    id: id,
    icon: "gw-apps",
    ref: combineRefs(ref, iconRef),
    onClick: togglePopover,
    iconColor: iconColor,
    className: iconClassName,
    "aria-haspopup": "menu",
    "aria-expanded": hasShownPopover,
    "aria-label": translator(messages.appSwitcher),
    noDeprecationWarnings: ["IconButton"]
  });
});
AppSwitcherButton.displayName = "AppSwitcherButton", AppSwitcherButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AppSwitcherButton"
}, AppSwitcherButton.__docgenInfo = {
  componentName: "AppSwitcherButton",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcherButton",
  methods: [],
  actualName: "AppSwitcherButton"
};