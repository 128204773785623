import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
var getReducerObject = function (acc, messageList, key) {
  return messageList.length > 0 ? _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, messageList)) : acc;
};
export var excludeMultiMessageWarningsFromMessages = function (validationMessages) {
  var _context;
  return _reduceInstanceProperty(_context = _Object$entries(validationMessages)).call(_context, function (accu, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      messageList = _ref2[1];
    if (Array.isArray(messageList)) {
      var errorList = _filterInstanceProperty(messageList).call(messageList, function (message) {
        return "error" === message.type;
      });
      return isEmpty(errorList) ? _objectSpread(_objectSpread({}, accu), {}, _defineProperty({}, key, _filterInstanceProperty(messageList).call(messageList, function (message) {
        return "warning" === message.type;
      }))) : _objectSpread(_objectSpread({}, accu), {}, _defineProperty({}, key, _filterInstanceProperty(messageList).call(messageList, function (message) {
        return "error" === message.type;
      })));
    }
    return _objectSpread(_objectSpread({}, accu), {}, _defineProperty({}, key, messageList));
  }, {});
};
export var groupCurrentValidationMessages = function (messageObject) {
  var _context2;
  return _reduceInstanceProperty(_context2 = _Object$entries(messageObject)).call(_context2, function (accu, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      messageList = _ref4[1],
      formattedMessageList = Array.isArray(messageList) ? messageList : [messageList],
      errorList = _filterInstanceProperty(formattedMessageList).call(formattedMessageList, function (message) {
        return "error" === message.type;
      }),
      warningList = _filterInstanceProperty(formattedMessageList).call(formattedMessageList, function (message) {
        return "warning" === message.type;
      });
    return {
      error: getReducerObject(null == accu ? void 0 : accu.error, errorList, key),
      warning: getReducerObject(null == accu ? void 0 : accu.warning, warningList, key)
    };
  }, {
    error: {},
    warning: {}
  });
};
export var getMessagesCount = function (messageObject) {
  var _context3;
  return isUndefined(messageObject) || isEmpty(messageObject) ? 0 : _reduceInstanceProperty(_context3 = _Object$keys(messageObject)).call(_context3, function (accu, keyName) {
    var messageDetails = messageObject[keyName];
    return accu + (Array.isArray(messageDetails) ? messageDetails.length : 1);
  }, 0);
};