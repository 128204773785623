import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import logger from "loglevel";
import prefix from "loglevel-plugin-prefix";
import { getConfigValue } from "@jutro/config";
var DEFAULT_LOGGER_CONFIG = {
    level: getConfigValue("JUTRO_LOGGER_LEVEL", "WARN"),
    prefixTemplate: "%l:",
    loggerName: "jutro-logger"
  },
  Logger = function () {
    function Logger(loggerConfig) {
      _classCallCheck(this, Logger), _defineProperty(this, "log", void 0);
      var config = loggerConfig || DEFAULT_LOGGER_CONFIG;
      this.setConfig(config);
    }
    return _createClass(Logger, [{
      key: "setConfig",
      value: function (config, replace) {
        var configs = replace ? config : _objectSpread(_objectSpread({}, DEFAULT_LOGGER_CONFIG), config),
          level = configs.level,
          prefixTemplate = configs.prefixTemplate,
          loggerName = configs.loggerName;
        prefix.reg(logger), prefix.apply(logger, {
          template: prefixTemplate
        }), this.log = logger.getLogger(loggerName), this.log.setLevel(level);
      }
    }, {
      key: "resetConfig",
      value: function () {
        this.setConfig(DEFAULT_LOGGER_CONFIG);
      }
    }, {
      key: "trace",
      value: function (message) {
        this.log.trace(message);
      }
    }, {
      key: "debug",
      value: function (message) {
        this.log.debug(message);
      }
    }, {
      key: "info",
      value: function (message) {
        this.log.info(message);
      }
    }, {
      key: "warning",
      value: function (message, options) {
        options && options.bold ? this.log.warn("%c".concat(message), "font-weight: bold") : this.log.warn(message);
      }
    }, {
      key: "error",
      value: function (message) {
        this.log.error(message);
      }
    }]), Logger;
  }();
export { Logger as default };
export var log = new Logger();
export var trace = function (message) {
  log.trace(message);
};
export var debug = function (message) {
  log.debug(message);
};
export var info = function (message) {
  log.info(message);
};
export var warning = function (message, options) {
  log.warning(message, options);
};
export var error = function (message) {
  log.error(message);
};
export var makeUniqueWarningLog = function (message) {
  return messageFunction = function () {
    return warning(message);
  }, called = !1, function (message) {
    called || (called = !0, messageFunction(message));
  };
  var messageFunction, called;
};