import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _get from "@babel/runtime-corejs3/helpers/get";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import cx from "classnames";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { Checkbox } from "./Checkbox";
import styles from "./CheckboxField.module.css";
function isChecked(value) {
  return !isNil(value) && "true" === value.toString();
}
export var CheckboxField = function (_FieldComponent) {
  _inherits(CheckboxField, FieldComponent);
  var _super = _createSuper(CheckboxField);
  function CheckboxField() {
    var _context, _thisSuper, _this;
    _classCallCheck(this, CheckboxField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "renderInlineLabel", function () {
      var classes = cx(styles.checkboxElementWrapper);
      return React.createElement("div", {
        className: classes
      }, _get((_thisSuper = _assertThisInitialized(_this), _getPrototypeOf(CheckboxField.prototype)), "renderLabel", _thisSuper).call(_thisSuper, _this.props, styles.inlineLabel));
    }), _defineProperty(_assertThisInitialized(_this), "isInlineLabelVisible", function () {
      var _this$props = _this.props,
        showInlineLabel = _this$props.showInlineLabel,
        hideLabel = _this$props.hideLabel;
      return showInlineLabel || hideLabel;
    }), _defineProperty(_assertThisInitialized(_this), "renderControl", function (breakpointProps) {
      var testId = breakpointProps.testId,
        id = breakpointProps.id,
        secondaryLabelId = breakpointProps.secondaryLabelId,
        icon = _this.props.icon;
      return React.createElement(Checkbox, _extends({
        icon: icon
      }, breakpointProps, _this.baseProps, {
        testId: testId || id,
        secondaryLabelId: secondaryLabelId
      }));
    }), _defineProperty(_assertThisInitialized(_this), "renderControlReadOnly", function (breakpointProps) {
      return React.createElement(Checkbox, _extends({}, breakpointProps, _this.baseProps, {
        disabled: !0
      }));
    }), _defineProperty(_assertThisInitialized(_this), "handleClick", function (evt) {
      null == evt || evt.stopPropagation(), null == evt || evt.preventDefault();
      var _this$props2 = _this.props,
        value = _this$props2.value,
        disabled = _this$props2.disabled,
        readOnly = _this$props2.readOnly;
      disabled || readOnly || _this.notifyChange(!isChecked(value));
    }), _defineProperty(_assertThisInitialized(_this), "handleCheckChange", function (evt) {
      var value = evt.target.checked;
      _this.notifyChange(value);
    }), _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (evt) {
      if ("Enter" === evt.key || " " === evt.key) {
        var checked = isChecked(_this.props.value);
        _this.notifyChange(!checked), evt.preventDefault();
      }
    }), _this;
  }
  return _createClass(CheckboxField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(CheckboxField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderLabel",
    value: function (props) {
      return this.isInlineLabelVisible() ? null : _get(_getPrototypeOf(CheckboxField.prototype), "renderLabel", this).call(this, props);
    }
  }, {
    key: "baseProps",
    get: function () {
      return {
        id: this.fieldUniqueId,
        onClick: this.handleClick,
        checked: isChecked(this.props.value),
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onKeyDown: this.handleKeyDown,
        onChange: this.handleCheckChange,
        accessibilityProps: this.generateAccessibilityProperties(),
        renderInlineLabel: this.renderInlineLabel
      };
    }
  }], [{
    key: "isContentVisible",
    value: function (value) {
      return isChecked(value);
    }
  }]), CheckboxField;
}();
_defineProperty(CheckboxField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showInlineLabel: PropTypes.bool,
  detailElement: PropTypes.node,
  icon: PropTypes.string,
  children: PropTypes.node,
  checkboxRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf("undefined" != typeof window ? Element : Object)
  })]),
  secondaryLabelId: PropTypes.string
})), _defineProperty(CheckboxField, "defaultProps", {
  icon: "gw-check"
}), CheckboxField.__docgenInfo = {
  description: "Displays a label, clickable box, and message.\n\n@typedef {typeof CheckboxField.propTypes} CheckboxFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<CheckboxFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "isContentVisible",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "value",
      type: null
    }],
    returns: null
  }, {
    name: "renderInlineLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the inline label",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the inline label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "isInlineLabelVisible",
    docblock: "Check is inline label is visible\n@returns {boolean} whether the inline label is visible",
    modifiers: [],
    params: [],
    returns: {
      description: "whether the inline label is visible",
      type: {
        name: "boolean"
      }
    },
    description: "Check is inline label is visible"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Renders Checkbox that is read only\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders Checkbox that is read only"
  }, {
    name: "baseProps",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      type: null
    }],
    returns: null
  }, {
    name: "handleCheckChange",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleKeyDown",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }],
  displayName: "CheckboxField",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-check'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "String with the name of the icon"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Default value"
    },
    showInlineLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays label inline"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to render when checkbox is selected, as processed by the `MetadataContent` component (metadata 3.0)"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children to render when the checkbox is selected (metadata 3.0)"
    },
    checkboxRef: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "typeof window !== 'undefined' ? Element : Object",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Reference to the checkbox element"
    },
    secondaryLabelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Secondary label id, uses for accessibility, to link secondary label with input element"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, CheckboxField.__docgenInfo = {
  componentName: "CheckboxField",
  packageName: "@jutro/components",
  description: "Displays a label, clickable box, and message.",
  displayName: "CheckboxField",
  methods: [{
    name: "isContentVisible",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "value",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderInlineLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the inline label",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the inline label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "isInlineLabelVisible",
    docblock: "Check is inline label is visible\n@returns {boolean} whether the inline label is visible",
    modifiers: [],
    params: [],
    returns: {
      description: "whether the inline label is visible",
      type: {
        name: "boolean"
      }
    },
    description: "Check is inline label is visible"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Renders Checkbox that is read only\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders Checkbox that is read only"
  }, {
    name: "baseProps",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleCheckChange",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleKeyDown",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }],
  actualName: "CheckboxField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "Default value"
    },
    showInlineLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays label inline"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to render when checkbox is selected, as processed by the `MetadataContent` component (metadata 3.0)"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "String with the name of the icon",
      defaultValue: {
        value: "'gw-check'",
        computed: !1
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children to render when the checkbox is selected (metadata 3.0)"
    },
    checkboxRef: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "typeof window !== 'undefined' ? Element : Object",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Reference to the checkbox element"
    },
    secondaryLabelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Secondary label id, uses for accessibility, to link secondary label with input element"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};