import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SELECT, TOGGLE, SET_ALL, CLEAR_ALL } from "./types";
import { toggleValue } from "./helper";
export function reducer(state, _ref) {
  var type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case SELECT:
      return areEqual([payload], state.rows) ? _objectSpread(_objectSpread({}, state), {}, {
        all: !1
      }) : _objectSpread(_objectSpread({}, state), {}, {
        all: !1,
        rows: [payload]
      });
    case TOGGLE:
      var rows = state.rows,
        newRows = toggleValue(rows, payload);
      return _objectSpread(_objectSpread({}, state), {}, {
        all: !1,
        rows: newRows
      });
    case SET_ALL:
      return areEqual(payload, state.rows) ? _objectSpread(_objectSpread({}, state), {}, {
        all: !0
      }) : _objectSpread(_objectSpread({}, state), {}, {
        all: !0,
        rows: payload
      });
    case CLEAR_ALL:
      return state.rows.length ? _objectSpread(_objectSpread({}, state), {}, {
        all: !1,
        rows: []
      }) : _objectSpread(_objectSpread({}, state), {}, {
        all: !1
      });
    default:
      return state;
  }
}
function areEqual(array1, array2) {
  return array1.length === array2.length && array1.every(function (item) {
    return _includesInstanceProperty(array2).call(array2, item);
  });
}