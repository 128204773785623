import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
export var GenericSelectControlControl = function (_ref) {
  var _cx,
    children = _ref.children,
    isFocused = _ref.isFocused,
    innerRef = _ref.innerRef,
    innerProps = _ref.innerProps,
    className = _ref.className,
    _ref$selectProps = _ref.selectProps,
    genericSelectStyles = _ref$selectProps.genericSelectStyles,
    invalid = _ref$selectProps.invalid,
    errorMessage = _ref$selectProps.errorMessage,
    registerValidation = _ref$selectProps.registerValidation,
    warningMessageCheck = errorMessage && errorMessage instanceof Object && !isEmpty(errorMessage) && "warning" === (null == errorMessage ? void 0 : errorMessage.type),
    messageStyle = warningMessageCheck ? "warning" : "invalid",
    focusedStyle = warningMessageCheck ? "focusedWarning" : "focusedInvalid",
    controlClasses = cx(genericSelectStyles.control, (_defineProperty(_cx = {}, genericSelectStyles.controlFocused, isFocused && !invalid), _defineProperty(_cx, messageStyle, invalid), _defineProperty(_cx, focusedStyle, isFocused && invalid), _cx), className, !isUndefined(registerValidation) && invalid && genericSelectStyles.labpreviewBorder);
  return React.createElement("div", _extends({
    ref: innerRef,
    className: controlClasses
  }, innerProps), children);
};
GenericSelectControlControl.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlControl"
}, GenericSelectControlControl.__docgenInfo = {
  componentName: "GenericSelectControlControl",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlControl",
  methods: [],
  actualName: "GenericSelectControlControl"
};