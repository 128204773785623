import { defineMessages } from "react-intl";
export var messages = defineMessages({
  startOfRange: {
    id: "jutro-components.widgets.inputs.Slider.StartOfRange",
    defaultMessage: "Start of Range"
  },
  endOfRange: {
    id: "jutro-components.widgets.inputs.Slider.EndOfRange",
    defaultMessage: "End of Range"
  }
});