import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useCallback } from "react";
import cx from "classnames";
import { LookupField } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import styles from "./SearchField.module.css";
export var SearchField = function (props) {
  var _cx,
    isDarkTheme = props.isDarkTheme,
    onSearchValueChange = props.onSearchValueChange,
    onLoadValues = props.onLoadValues,
    searchAvailableValues = props.searchAvailableValues,
    searchFieldPlaceholder = props.searchFieldPlaceholder,
    optionTypes = props.optionTypes,
    translator = useTranslator(),
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    searchValue = _useState2[0],
    setSearchValue = _useState2[1],
    onValueChange = useCallback(function (val) {
      setSearchValue(val), onSearchValueChange && onSearchValueChange(val);
    }, [onSearchValueChange]);
  return onSearchValueChange && React.createElement(LookupField, {
    id: "search",
    label: "search",
    labelId: "search_63202513",
    value: searchValue,
    onValueChange: onValueChange,
    onLoadValues: onLoadValues,
    layout: "full-width",
    className: cx((_cx = {}, _defineProperty(_cx, styles.darkSearchContainer, isDarkTheme), _defineProperty(_cx, styles.searchContainer, !isDarkTheme), _cx)),
    availableValues: searchAvailableValues,
    optionTypes: optionTypes,
    placeholder: translator(searchFieldPlaceholder) || null,
    hideLabel: !0,
    internalClassNames: styles
  });
};
SearchField.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SearchField"
}, SearchField.__docgenInfo = {
  componentName: "SearchField",
  packageName: "@jutro/router",
  description: "",
  displayName: "SearchField",
  methods: [],
  actualName: "SearchField"
};