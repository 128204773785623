import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { availableValuesCodeNameObject } from "@jutro/prop-types";
import { TypeaheadMultiSelectField } from "../../TypeaheadMultiSelectField/TypeaheadMultiSelectField";
export var timeZoneFieldPropTypes = _objectSpread(_objectSpread({}, TypeaheadMultiSelectField.propTypes), {}, {
  availableValues: PropTypes.arrayOf(PropTypes.shape(availableValuesCodeNameObject).isRequired)
});
export var TimeZoneField = function (props) {
  return React.createElement(TypeaheadMultiSelectField, _extends({}, props, {
    singleSelect: !0
  }));
};
TimeZoneField.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TimeZoneField"
}, TimeZoneField.__docgenInfo = {
  componentName: "TimeZoneField",
  packageName: "@jutro/components",
  description: "",
  displayName: "TimeZoneField",
  methods: [],
  actualName: "TimeZoneField"
};