import PropTypes from "prop-types";
export var vshortDateFormat = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};
export var shortDateFormat = {
  year: "numeric",
  month: "short",
  day: "numeric"
};
export var longDateFormat = {
  year: "numeric",
  month: "long",
  day: "numeric"
};
export var abbreviatedDateFormat = {
  year: "numeric",
  month: "short",
  day: "numeric",
  weekday: "short"
};
export var fullDateFormat = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long"
};
export var dateFormatMap = {
  vshort: vshortDateFormat,
  short: shortDateFormat,
  long: longDateFormat,
  abbreviated: abbreviatedDateFormat,
  full: fullDateFormat
};
export var timeFormat = {
  hour: "numeric",
  minute: "numeric"
};
export var dateFormats = ["vshort", "short", "long", "abbreviated", "full"];
export var dateFormatShape = PropTypes.oneOf(dateFormats);