import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useRef } from "react";
import cx from "classnames";
import { LargeUploadArea } from "../CommonComponents/LargeUploadArea";
import { EmptyThinArea } from "../CommonComponents/EmptyThinArea";
import { UploadedArea } from "../CommonComponents/UploadedArea";
import { DragAndDropWrapper } from "../CommonComponents/DragAndDropWrapper";
import { TYPE_THIN, TYPE_LARGE } from "../utils";
import styles from "../FileUploadField.module.css";
import { isAccessibleDisabled } from "../../../../accessibleDisabled";
export var SingleFileUploadField = function (_ref) {
  var value = _ref.value,
    maxLength = _ref.maxLength,
    disabled = _ref.disabled,
    readonly = _ref.readonly,
    className = _ref.className,
    controlClassName = _ref.controlClassName,
    required = _ref.required,
    accept = _ref.accept,
    messageProps = _ref.messageProps,
    type = _ref.type,
    imageSource = _ref.imageSource,
    buttonType = _ref.buttonType,
    buttonSize = _ref.buttonSize,
    buttonIcon = _ref.buttonIcon,
    id = _ref.id,
    _onChange = _ref.onChange,
    testId = _ref.testId,
    fieldUniqueId = _ref.fieldUniqueId,
    uploadState = _ref.uploadState,
    onCancel = _ref.onCancel,
    completed = _ref.completed,
    total = _ref.total,
    onFileClear = _ref.onFileClear,
    onInputFocus = _ref.onInputFocus,
    onInputBlur = _ref.onInputBlur,
    dataPath = _ref.dataPath,
    inputA11yProps = _ref.inputA11yProps,
    renderValidationMessages = _ref.renderValidationMessages,
    disableDragAndDrop = _ref.disableDragAndDrop,
    wrapperClassName = _ref.wrapperClassName,
    inputRef = useRef(null),
    showExplorerDialog = function () {
      var _inputRef$current;
      return null === (_inputRef$current = inputRef.current) || void 0 === _inputRef$current ? void 0 : _inputRef$current.click();
    },
    accessibleDisabled = Boolean(disabled && isAccessibleDisabled("accessibleDisabled.fields"));
  return React.createElement(DragAndDropWrapper, {
    className: className,
    onDrop: _onChange,
    dragAndDropDisabled: disabled || readonly || disableDragAndDrop
  }, React.createElement("div", {
    className: wrapperClassName
  }, !value && type === TYPE_THIN && React.createElement(EmptyThinArea, {
    disabled: disabled,
    messageProps: messageProps,
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    disableDragAndDropMessage: disableDragAndDrop,
    onAction: showExplorerDialog
  }), !value && type === TYPE_LARGE && React.createElement(LargeUploadArea, {
    imageSource: imageSource,
    disabled: disabled,
    messageProps: messageProps,
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    disableDragAndDropMessage: disableDragAndDrop,
    onAction: showExplorerDialog,
    variant: "empty"
  }), value && React.createElement(UploadedArea, {
    value: value,
    messageProps: messageProps,
    type: type,
    imageSource: imageSource,
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    id: id,
    uploadState: uploadState,
    onCancel: onCancel,
    completed: completed,
    total: total,
    onFileClear: onFileClear,
    renderValidationMessages: renderValidationMessages,
    showExplorerDialog: showExplorerDialog,
    disableFileRemoveButton: accessibleDisabled
  }), React.createElement("input", _extends({
    id: fieldUniqueId,
    type: "file",
    "data-testid": testId || id,
    ref: inputRef,
    accept: accept,
    className: cx(controlClassName, styles.input),
    maxLength: maxLength,
    onChange: function (event) {
      return _onChange(event);
    },
    disabled: disabled,
    required: required,
    onFocus: onInputFocus,
    onBlur: onInputBlur,
    "data-path": dataPath
  }, inputA11yProps))));
};
SingleFileUploadField.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SingleFileUploadField"
}, SingleFileUploadField.__docgenInfo = {
  componentName: "SingleFileUploadField",
  packageName: "@jutro/components",
  description: "",
  displayName: "SingleFileUploadField",
  methods: [],
  actualName: "SingleFileUploadField"
};