import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "path", "completed", "value", "total", "className", "progressBarClassName", "stepClassName", "label", "hideLabel", "labelPosition", "showProgressLabel", "showPercentageProgress"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { useBreakpoint } from "@jutro/layout";
import { FormattedNumber } from "../../values/FormattedNumber";
import styles from "./SimpleProgressBar.module.css";
export var SimpleProgressBar = function (props) {
  var _context,
    _ref = useBreakpoint(props).breakpointProps,
    id = _ref.id,
    path = _ref.path,
    completed = _ref.completed,
    value = _ref.value,
    total = _ref.total,
    className = _ref.className,
    progressBarClassName = _ref.progressBarClassName,
    stepClassName = _ref.stepClassName,
    label = _ref.label,
    hideLabel = _ref.hideLabel,
    labelPosition = _ref.labelPosition,
    showProgressLabel = _ref.showProgressLabel,
    showPercentageProgress = _ref.showPercentageProgress,
    rest = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    componentClassNames = cx(styles.simpleProgressBar, className),
    progressBarClassNames = cx(styles.progressBar, progressBarClassName),
    progressBarStepClassNames = cx(styles.progressBarSteps, stepClassName),
    effectiveCompleted = path ? Number(value) : completed,
    progress = Math.round(effectiveCompleted / total * 100);
  progress = progress < 100 ? progress : 100;
  var labelPositioningStyles = label && showProgressLabel && "top" === labelPosition && !hideLabel ? styles.labelContainer : styles.labelContainerCenter,
    progressLabel = showPercentageProgress ? React.createElement(FormattedNumber, {
      format: "percent",
      value: progress / 100
    }) : _concatInstanceProperty(_context = "".concat(effectiveCompleted, "/")).call(_context, total),
    labelId = "progressbar-label-".concat(id),
    renderLabel = function () {
      return React.createElement("div", {
        className: styles.label,
        id: labelId
      }, translator(label));
    };
  return React.createElement("div", _extends({
    id: id,
    className: componentClassNames
  }, rest, {
    role: "progressbar",
    "aria-valuenow": progress,
    "aria-valuemin": 0,
    "aria-valuemax": total,
    "aria-label": translator(label)
  }), React.createElement("div", {
    className: labelPositioningStyles
  }, !hideLabel && label && "top" === labelPosition ? renderLabel() : null, showProgressLabel && React.createElement("div", {
    className: styles.progressLabel
  }, progressLabel)), React.createElement("div", {
    className: progressBarClassNames
  }, React.createElement("div", {
    className: progressBarStepClassNames,
    style: {
      transform: "translateX(".concat(progress - 100, "%)")
    }
  })), React.createElement("div", {
    className: labelPositioningStyles
  }, !hideLabel && label && "bottom" === labelPosition ? renderLabel() : null));
};
var simpleProgressBarPropTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  path: PropTypes.string,
  value: PropTypes.number,
  id: PropTypes.string,
  className: PropTypes.string,
  progressBarClassName: PropTypes.string,
  stepClassName: PropTypes.string,
  label: intlMessageShape,
  hideLabel: PropTypes.bool,
  labelPosition: PropTypes.oneOf(["top", "bottom"]),
  showProgressLabel: PropTypes.bool,
  showPercentageProgress: PropTypes.bool
};
SimpleProgressBar.defaultProps = {
  labelPosition: "bottom",
  showProgressLabel: !0,
  showPercentageProgress: !1,
  hideLabel: !1
}, SimpleProgressBar.propTypes = simpleProgressBarPropTypes, SimpleProgressBar.displayName = "SimpleProgressBar", SimpleProgressBar.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SimpleProgressBar",
  props: {
    labelPosition: {
      defaultValue: {
        value: "'bottom'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }]
      },
      required: !1,
      description: "Allows you to select position of the progress bar label"
    },
    showProgressLabel: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows progress label"
    },
    showPercentageProgress: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, shows percentage progress instead of fractions"
    },
    hideLabel: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides label. If you need to make component without label and accessible, provide label prop and set hideLabel to true -> aria-label attribute will be set to label."
    },
    completed: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Number of steps that were completed"
    },
    total: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Total number of steps"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint path to value"
    },
    value: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of steps that were completed when used with path"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "A unique ID for this element"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override whole component styles"
    },
    progressBarClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar styles"
    },
    stepClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar step styles"
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Label of the progress bar"
    }
  }
}, SimpleProgressBar.__docgenInfo = {
  componentName: "SimpleProgressBar",
  packageName: "@jutro/components",
  description: "",
  displayName: "SimpleProgressBar",
  methods: [],
  actualName: "SimpleProgressBar",
  props: {
    completed: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Number of steps that were completed"
    },
    total: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Total number of steps"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint path to value"
    },
    value: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Number of steps that were completed when used with path"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "A unique ID for this element"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override whole component styles"
    },
    progressBarClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar styles"
    },
    stepClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar step styles"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Label of the progress bar"
    },
    hideLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides label. If you need to make component without label and accessible, provide label prop and set hideLabel to true -> aria-label attribute will be set to label.",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }]
      },
      required: !1,
      description: "Allows you to select position of the progress bar label",
      defaultValue: {
        value: "'bottom'",
        computed: !1
      }
    },
    showProgressLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows progress label",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    showPercentageProgress: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, shows percentage progress instead of fractions",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  }
};