import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
export var PageTitle = function (props) {
  var title = props.title;
  return React.createElement(Helmet, null, React.createElement("title", null, title));
};
PageTitle.propTypes = {
  title: PropTypes.string.isRequired
}, PageTitle.__docgenInfo = {
  description: "PageHead component provides an api to configure and manipulate\nthe head tag title property on the page\n\n@returns {React.ReactElement} - rendered component",
  methods: [],
  displayName: "PageTitle",
  props: {
    title: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Page title text"
    }
  }
}, PageTitle.__docgenInfo = {
  componentName: "PageTitle",
  packageName: "@jutro/router",
  description: "PageHead component provides an api to configure and manipulate\nthe head tag title property on the page",
  displayName: "PageTitle",
  methods: [],
  actualName: "PageTitle",
  props: {
    title: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Page title text"
    }
  }
};