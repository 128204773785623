import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import cx from "classnames";
import { IconButton } from "../../../IconButton/IconButton_DEPRECATED";
import { getKeyPressHandler } from "../../../../accessibility/getKeyPressHandler";
import { messages } from "../GenericSelectControl.messages";
export var GenericSelectControlClearIndicator = function (_ref) {
  var className = _ref.className,
    _ref$selectProps = _ref.selectProps,
    genericSelectStyles = _ref$selectProps.genericSelectStyles,
    ariaDisabled = _ref$selectProps["aria-disabled"],
    innerProps = _ref.innerProps;
  return ariaDisabled ? null : React.createElement(IconButton, _extends({}, innerProps, {
    className: cx(className, genericSelectStyles.selectIcon, genericSelectStyles.cancel),
    icon: "gw-cancel",
    "aria-hidden": "false",
    ariaLabel: messages.clearButton,
    onKeyDown: getKeyPressHandler(innerProps.onMouseDown, void 0, !0),
    noDeprecationWarnings: ["IconButton"]
  }));
};
GenericSelectControlClearIndicator.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlClearIndicator"
}, GenericSelectControlClearIndicator.__docgenInfo = {
  componentName: "GenericSelectControlClearIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlClearIndicator",
  methods: [],
  actualName: "GenericSelectControlClearIndicator"
};