import { defineMessages } from "react-intl";
export var messages = defineMessages({
  skipNavMessage: {
    id: "jutro-components.accessibility.SkipNav.navigation",
    defaultMessage: "Skip to content"
  },
  skipNavMessageWithAppName: {
    id: "jutro-components.accessibility.SkipNav.navigationWithAppName",
    defaultMessage: "Skip to {appName} content"
  }
});