import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
export var SliderHandle = function (_Component) {
  _inherits(SliderHandle, Component);
  var _super = _createSuper(SliderHandle);
  function SliderHandle() {
    var _context, _this;
    _classCallCheck(this, SliderHandle);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "setHandle", function (handle) {
      _this.handle = handle;
    }), _defineProperty(_assertThisInitialized(_this), "handleMouseDown", function () {
      _this.focus();
    }), _this;
  }
  return _createClass(SliderHandle, [{
    key: "focus",
    value: function () {
      this.handle.focus();
    }
  }, {
    key: "render",
    value: function () {
      var _this$props = this.props,
        id = _this$props.id,
        min = _this$props.min,
        max = _this$props.max,
        value = _this$props.value,
        offset = _this$props.offset,
        disabled = _this$props.disabled,
        className = _this$props.className,
        tooltipClassName = _this$props.tooltipClassName,
        labelId = _this$props.labelId,
        testId = _this$props.testId,
        onFocus = _this$props.onFocus,
        onBlur = _this$props.onBlur,
        accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields"),
        tabIndex = disabled && !accessibleDisabled ? void 0 : "0",
        style = {
          left: "".concat(offset, "%")
        },
        accessibleDisabledProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
          onFocus: onFocus,
          onBlur: onBlur
        })), {}, {
          "aria-disabled": !0,
          "aria-readonly": !0,
          readOnly: !0
        }) : {};
      return React.createElement("div", _extends({
        id: id,
        tabIndex: tabIndex,
        className: className,
        style: style,
        role: "slider",
        "aria-valuemin": min,
        "aria-valuemax": max,
        "aria-valuenow": value,
        "aria-disabled": disabled,
        "aria-labelledby": labelId,
        "aria-orientation": "horizontal",
        name: "slider",
        ref: this.setHandle,
        onMouseDown: this.handleMouseDown,
        onFocus: onFocus,
        onBlur: onBlur,
        "data-testid": testId
      }, accessibleDisabledProps), React.createElement("output", {
        className: tooltipClassName
      }, React.createElement(FormattedNumber, {
        value: value
      })));
    }
  }]), SliderHandle;
}();
_defineProperty(SliderHandle, "propTypes", {
  id: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  labelId: PropTypes.string,
  testId: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
}), _defineProperty(SliderHandle, "defaultProps", {
  disabled: !1,
  step: 1
}), SliderHandle.__docgenInfo = {
  description: "",
  methods: [{
    name: "setHandle",
    docblock: null,
    modifiers: [],
    params: [{
      name: "handle",
      type: null
    }],
    returns: null
  }, {
    name: "handleMouseDown",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "focus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  displayName: "SliderHandle",
  props: {
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is disabled"
    },
    step: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Step between consecutive values"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    min: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Current slider value (to create a fully controlled component)"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    tooltipClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component's tooltip"
    },
    labelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the element that labels the slider handle component."
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id."
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the blur event is fired"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the focus event is fired"
    }
  }
}, SliderHandle.__docgenInfo = {
  componentName: "SliderHandle",
  packageName: "@jutro/components",
  description: "",
  displayName: "SliderHandle",
  methods: [{
    name: "setHandle",
    docblock: null,
    modifiers: [],
    params: [{
      name: "handle",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleMouseDown",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "focus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "SliderHandle",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    min: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Current slider value (to create a fully controlled component)"
    },
    step: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Step between consecutive values",
      defaultValue: {
        value: "1",
        computed: !1
      }
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, this field is disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    tooltipClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component's tooltip"
    },
    labelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the element that labels the slider handle component."
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id."
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the blur event is fired"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the focus event is fired"
    }
  }
};