import React from "react";
import { useLanguage } from "@jutro/locale";
import { MessagesLoader } from "./MessagesLoader";
export var GlobalizationMessages = function (_ref) {
  var loaders = _ref.loaders,
    onLoad = _ref.onLoad,
    language = useLanguage().language;
  return React.createElement(MessagesLoader, {
    loaders: loaders,
    onLoad: onLoad,
    language: language
  });
};
GlobalizationMessages.__docgenInfo = {
  description: "A version of MessagesLoader that is connected to the LanguageContext in the React tree.\n\n@param defaultLanguage - the language to use in case no language is returned from the context\n@param loaders - the language loader functions array\n@param onLoad - callback to call after messages have been loaded",
  methods: [],
  displayName: "GlobalizationMessages"
}, GlobalizationMessages.__docgenInfo = {
  componentName: "GlobalizationMessages",
  packageName: "@jutro/app",
  description: "A version of MessagesLoader that is connected to the LanguageContext in the React tree.",
  displayName: "GlobalizationMessages",
  methods: [],
  actualName: "GlobalizationMessages"
};