import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { FieldValue } from "@jutro/components";
import { simpleDatatypeComponentMap } from "@jutro/uiconfig";
import get from "lodash/get";
import { warning } from "@jutro/logger";
import { TableColumn } from "../table/TableColumn";
import { FieldColumn } from "./FieldColumn";
import styles from "../DataTable.module.css";
export var DisplayColumn = function () {
  return "development" === process.env.NODE_ENV && warning("Component <DisplayColumn /> should never render"), React.createElement(React.Fragment, null);
};
DisplayColumn.propTypes = _objectSpread(_objectSpread({}, TableColumn.propTypes), {}, {
  fieldDatatype: PropTypes.oneOf(_Object$keys(simpleDatatypeComponentMap)),
  onAccessor: PropTypes.func,
  valueProps: PropTypes.object
}), DisplayColumn.defaultCell = function (row, rowId, _ref) {
  var _context,
    fieldDatatype = _ref.fieldDatatype,
    path = _ref.path,
    visible = _ref.visible,
    id = _ref.id,
    valueProps = _ref.valueProps;
  return !1 === visible ? null : React.createElement(FieldValue, _extends({
    id: _concatInstanceProperty(_context = "".concat(id, "_")).call(_context, rowId),
    datatype: fieldDatatype,
    value: get(row, path)
  }, valueProps));
}, DisplayColumn.editCell = function (props) {
  return FieldColumn.editCell(props);
}, DisplayColumn.editCellClass = styles.editedCell, DisplayColumn.displayName = "DisplayColumn", DisplayColumn.defaultProps = {
  renderCell: DisplayColumn.defaultCell,
  renderEditCell: DisplayColumn.editCell,
  editCellClass: DisplayColumn.editCellClass,
  columnProportion: 1,
  fieldDatatype: "string"
}, DisplayColumn.__docgenInfo = {
  description: "DisplayColumn\n@returns {null} - The DisplayColumn component does not render anything\n\n@metadataType container",
  methods: [{
    name: "defaultCell",
    docblock: "Render the default cell with the button and action defined\n@param {object} row - Each row in the data table\n@param {number | string} rowId - the id of the row\n@param {object} props - the properties used by DisplayColumn\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "rowId",
      description: "the id of the row",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "{ fieldDatatype, path, visible, id, valueProps }"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the default cell with the button and action defined"
  }, {
    name: "editCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "props",
      optional: !1,
      type: null
    }],
    returns: null
  }],
  displayName: "DisplayColumn",
  props: {
    renderCell: {
      defaultValue: {
        value: "DisplayColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    renderEditCell: {
      defaultValue: {
        value: "DisplayColumn.editCell",
        computed: !0
      },
      required: !1
    },
    editCellClass: {
      defaultValue: {
        value: "DisplayColumn.editCellClass",
        computed: !0
      },
      required: !1
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    },
    fieldDatatype: {
      defaultValue: {
        value: "'string'",
        computed: !1
      },
      type: {
        name: "enum",
        computed: !0,
        value: "Object.keys(simpleDatatypeComponentMap)"
      },
      required: !1,
      description: ""
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Returns a value that is used by the column to access the correct data of each row"
    },
    valueProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Props passed to the underlying FieldValue. Useful for passing e.g., `showFractions: true`"
    }
  },
  composes: ["../table/TableColumn"]
}, DisplayColumn.__docgenInfo = {
  componentName: "DisplayColumn",
  packageName: "@jutro/datatable",
  description: "DisplayColumn",
  displayName: "DisplayColumn",
  methods: [{
    name: "defaultCell",
    docblock: "Render the default cell with the button and action defined\n@param {object} row - Each row in the data table\n@param {number | string} rowId - the id of the row\n@param {object} props - the properties used by DisplayColumn\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "rowId",
      description: "the id of the row",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "{ fieldDatatype, path, visible, id, valueProps }"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the default cell with the button and action defined"
  }, {
    name: "editCell",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "props",
      optional: !1,
      type: null
    }],
    returns: null
  }],
  actualName: "DisplayColumn",
  metadataType: "container",
  props: {
    fieldDatatype: {
      type: {
        name: "enum",
        computed: !0,
        value: "Object.keys(simpleDatatypeComponentMap)"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Returns a value that is used by the column to access the correct data of each row"
    },
    valueProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Props passed to the underlying FieldValue. Useful for passing e.g., `showFractions: true`"
    },
    renderCell: {
      defaultValue: {
        value: "DisplayColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    renderEditCell: {
      defaultValue: {
        value: "DisplayColumn.editCell",
        computed: !0
      },
      required: !1
    },
    editCellClass: {
      defaultValue: {
        value: "DisplayColumn.editCellClass",
        computed: !0
      },
      required: !1
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../table/TableColumn"]
};