import { useEffect, useRef } from "react";
export var useCombinedRefs = function () {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) refs[_key] = arguments[_key];
  var targetRef = useRef(null);
  return useEffect(function () {
    for (var _i = 0, _refs = refs; _i < _refs.length; _i++) {
      var ref = _refs[_i];
      if (!ref) return;
      "function" == typeof ref ? ref(targetRef.current) : ref.current = targetRef.current;
    }
  }, [refs]), targetRef;
};