import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
var _excluded = ["navLink"],
  _excluded2 = ["id", "menuId", "isOpen"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useEffect, useCallback, useRef, useMemo } from "react";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { InlineLabel, Icon, DropdownMenu } from "@jutro/components";
import { useTranslator } from "@jutro/locale";
import { usePathname } from "@jutro/platform";
import { NavigationContent } from "../NavBar/NavigationContent";
import { removeNavLinkIcon } from "../NavBar/navBarHelper";
import { NavBarAccordion } from "../NavBar/SideNavigation/NavBarAccordion/NavBarAccordion";
import { messages } from "./ApplicationHeader.messages";
import styles from "./MoreButton.module.css";
import navBarItemStyles from "../NavBar/NavBarItem.module.css";
import appHeaderStyles from "./ApplicationHeader.module.css";
var MoreButtonContent = function (_ref) {
    var toggle = _ref.toggle,
      routes = _ref.routes,
      isOpen = _ref.isOpen,
      accessibilityProps = _ref.accessibilityProps,
      id = _ref.id,
      pathname = usePathname(),
      translator = useTranslator();
    !function (callback) {
      var deps = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
        location = useLocation(),
        oldLocation = useRef(location),
        cb = useCallback(callback, deps);
      useEffect(function () {
        location !== oldLocation.current && cb(), oldLocation.current = location;
      }, [cb, location]);
    }(function () {
      return toggle(!1);
    }, [isOpen]);
    var isActivePath = function (_ref2) {
        return _ref2.path === pathname;
      },
      hasActiveRoute = routes.some(function (_ref3) {
        var path = _ref3.path,
          subRoutes = _ref3.routes;
        return isActivePath({
          path: path
        }) || (null == subRoutes ? void 0 : subRoutes.some(isActivePath));
      }),
      classes = cx(appHeaderStyles.navItem, navBarItemStyles.topNavBarItem, navBarItemStyles.navBarItem, hasActiveRoute && appHeaderStyles.activeNavItem);
    return React.createElement("button", _extends({
      id: id,
      onClick: function () {
        toggle(!isOpen);
      },
      className: classes,
      tabIndex: 0,
      role: "menuitem"
    }, accessibilityProps), React.createElement(InlineLabel, {
      iconPosition: "right",
      icon: React.createElement(Icon, {
        icon: "gw-more-vertical",
        style: {
          fontSize: "1rem"
        }
      })
    }, translator(messages.moreButtonText)));
  },
  NestedRoutesComponent = function (_ref4) {
    var routes = _ref4.routes,
      id = _ref4.id,
      nestedRoutes = useMemo(function () {
        var _context;
        return _mapInstanceProperty(_context = _mapInstanceProperty(routes).call(routes, removeNavLinkIcon)).call(_context, function (_ref5) {
          var navLink = _ref5.navLink;
          return _objectSpread(_objectSpread({}, _objectWithoutProperties(_ref5, _excluded)), {}, {
            navLink: _objectSpread(_objectSpread({}, navLink), {}, {
              className: cx(null == navLink ? void 0 : navLink.className, appHeaderStyles.moreMenuItem)
            })
          });
        });
      }, [routes]),
      renderTriggerWrapFN = useCallback(function (_ref6, toggle) {
        var triggerId = _ref6.id,
          isOpen = (_ref6.menuId, _ref6.isOpen),
          accessibilityProps = _objectWithoutProperties(_ref6, _excluded2);
        return React.createElement(MoreButtonContent, {
          toggle: toggle,
          routes: nestedRoutes,
          isOpen: isOpen,
          id: triggerId,
          accessibilityProps: accessibilityProps
        });
      }, [nestedRoutes, appHeaderStyles]);
    return React.createElement(DropdownMenu, {
      id: id,
      renderTrigger: renderTriggerWrapFN,
      className: cx(appHeaderStyles.moreButtonDropdown, _defineProperty({}, styles.hideMoreButton, !routes.length))
    }, React.createElement(NavigationContent, {
      routes: nestedRoutes,
      nestedRoutesComponent: NavBarAccordion,
      alignment: "top",
      className: navBarItemStyles.moreMenuContent
    }));
  };
export var createMoreButton = function (_ref7) {
  var routes = _ref7.routes;
  return {
    nestedRoutesComponent: function (_ref8) {
      var focused = _ref8.focused,
        id = _ref8.id;
      return React.createElement(NestedRoutesComponent, {
        routes: routes,
        focused: focused,
        id: id
      });
    },
    routes: [{
      path: "/"
    }]
  };
};
MoreButtonContent.__docgenInfo = {
  componentName: "MoreButtonContent",
  packageName: "@jutro/router",
  description: "",
  displayName: "MoreButtonContent",
  methods: [],
  actualName: "MoreButtonContent"
}, NestedRoutesComponent.__docgenInfo = {
  componentName: "NestedRoutesComponent",
  packageName: "@jutro/router",
  description: "",
  displayName: "NestedRoutesComponent",
  methods: [],
  actualName: "NestedRoutesComponent"
};