import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import { dataTypeShapeWithPhone, DATA_TYPE_OBJECT } from "@jutro/prop-types";
import cx from "classnames";
import { ContextConsumer } from "@jutro/platform";
import { BreakpointTrackerContext } from "@jutro/layout";
import { CountryContext } from "@jutro/locale";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { InlineLoader } from "../../loading/inlineLoader/InlineLoader";
import "intl-tel-input/build/css/intlTelInput.css";
import styles from "./IntlPhoneNumberField.module.css";
import { IntlPhoneNumberInputLazyLoaded, IntlPhoneNumberReadOnlyLazyLoaded } from "./IntlPhoneNumberHelpers";
import { IntlPhoneNumberFieldValidationImplementation } from "./IntlPhoneNumberFieldValidationImplementation";
import { OldIntlPhoneNumberFieldValidationImplementation } from "./OldIntlPhoneNumberFieldValidationImplementation";
export var IntlPhoneNumberField = function (_FieldComponent) {
  _inherits(IntlPhoneNumberField, FieldComponent);
  var _super = _createSuper(IntlPhoneNumberField);
  function IntlPhoneNumberField(props) {
    var _this;
    _classCallCheck(this, IntlPhoneNumberField), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "renderIntlPhoneNumberFieldComponent", function (breakpoint, countryContext) {
      return _this.countryContext = countryContext, _this.renderFieldComponent(breakpoint);
    }), _defineProperty(_assertThisInitialized(_this), "handleChange", function (value) {
      _this.notifyChange(value);
    }), _defineProperty(_assertThisInitialized(_this), "state", {
      isValid: !0,
      validationErrorCode: void 0
    }), _defineProperty(_assertThisInitialized(_this), "validateNumber", function (validateNumber, validationErrorCode) {
      _this.setState({
        isValid: validateNumber
      }), _this.setState({
        validationErrorCode: validationErrorCode
      }), _this.validate({
        isValid: validateNumber,
        validationErrorCode: validationErrorCode
      });
    }), _this.inputFieldRef = React.createRef();
    _this.getInstance = function () {
      var _window$intlTelInputG;
      return _this.inputFieldRef.current && (null === (_window$intlTelInputG = window.intlTelInputGlobals) || void 0 === _window$intlTelInputG ? void 0 : _window$intlTelInputG.getInstance(_this.inputFieldRef.current));
    }.bind(_assertThisInitialized(_this));
    var ValidationImplementation = props.registerValidation ? new IntlPhoneNumberFieldValidationImplementation() : new OldIntlPhoneNumberFieldValidationImplementation();
    return _this.validationImplementation = ValidationImplementation.bind(_assertThisInitialized(_this)), _this;
  }
  return _createClass(IntlPhoneNumberField, [{
    key: "render",
    value: function () {
      return React.createElement(ContextConsumer, {
        contexts: [BreakpointTrackerContext, CountryContext]
      }, this.renderIntlPhoneNumberFieldComponent);
    }
  }, {
    key: "getValidationConfig",
    value: function (rulesConfig) {
      var _context,
        validationConfig = _get(_getPrototypeOf(IntlPhoneNumberField.prototype), "getValidationConfig", this).call(this, rulesConfig);
      return _objectSpread(_objectSpread({}, validationConfig), {}, {
        propsList: _concatInstanceProperty(_context = ["required", "value"]).call(_context, _toConsumableArray(get(validationConfig, "propsList", []))),
        options: get(rulesConfig, "options", {
          getInstance: this.getInstance
        })
      });
    }
  }, {
    key: "getValidationMessages",
    value: function (value) {
      return this.validationImplementation.getValidationMessages(value);
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var id = breakpointProps.id,
        disabled = breakpointProps.disabled,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        dataType = breakpointProps.dataType,
        value = breakpointProps.value,
        placeholder = breakpointProps.placeholder,
        defaultCountry = breakpointProps.defaultCountry,
        noDropdown = breakpointProps.noDropdown,
        testId = breakpointProps.testId,
        registerValidation = breakpointProps.registerValidation,
        inputStyle = cx(styles.phoneNumber, {
          disabled: disabled,
          invalid: !options.isValid
        }, !isUndefined(registerValidation) && !options.isValid && styles.labPreviewBorder, controlClassName),
        divStyle = cx(_defineProperty({}, styles.dropdownHidden, noDropdown)),
        initialCountry = defaultCountry || this.countryContext.defaultCountryCode,
        fieldUniqueId = this.fieldUniqueId;
      return React.createElement("div", {
        className: divStyle,
        "aria-disabled": disabled
      }, React.createElement(IntlPhoneNumberInputLazyLoaded, _extends({
        inputFieldRef: this.inputFieldRef,
        getInstance: this.getInstance,
        fallback: React.createElement(InlineLoader, {
          id: "InlineLoader",
          loading: !0,
          loadingMessage: "Loading..."
        }),
        id: fieldUniqueId,
        testId: testId || id,
        className: inputStyle,
        onChange: this.handleChange,
        defaultCountry: initialCountry,
        disabled: disabled,
        required: required
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), {
        dataType: dataType,
        validateNumber: this.validateNumber,
        value: value,
        placeholder: placeholder,
        noDropdown: noDropdown
      })));
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        showCountryCodeForReadOnly = breakpointProps.showCountryCodeForReadOnly,
        dataType = breakpointProps.dataType,
        defaultCountry = breakpointProps.defaultCountry,
        controlClassName = breakpointProps.controlClassName;
      return React.createElement(IntlPhoneNumberReadOnlyLazyLoaded, _extends({
        fallback: React.createElement(InlineLoader, {
          id: "InlineLoader",
          loading: !0,
          loadingMessage: "Loading..."
        }),
        id: id,
        value: value,
        showCountryCodeForReadOnly: showCountryCodeForReadOnly,
        dataType: dataType,
        defaultCountry: defaultCountry,
        className: controlClassName
      }, this.generateReadOnlyAccessibilityProperties()));
    }
  }]), IntlPhoneNumberField;
}();
_defineProperty(IntlPhoneNumberField, "contextType", FieldComponent.contextType);
var PhoneShape = PropTypes.shape({
  countryCode: PropTypes.shape({
    code: PropTypes.string.isRequired
  }),
  phoneNumber: PropTypes.string.isRequired
});
IntlPhoneNumberField.defaultProps = _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  dataType: DATA_TYPE_OBJECT,
  showCountryCodeForReadOnly: !0,
  noDropdown: !1
}), IntlPhoneNumberField.propTypes = _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  dataType: dataTypeShapeWithPhone,
  value: PropTypes.oneOfType([PhoneShape, PropTypes.string]),
  defaultCountry: PropTypes.string,
  showCountryCodeForReadOnly: PropTypes.bool,
  noDropdown: PropTypes.bool
}), IntlPhoneNumberField.displayName = "IntlPhoneNumberField", IntlPhoneNumberField.__docgenInfo = {
  description: "IntlPhoneNumberField is a 'field' component that displays a label, control and message. It can be used to render\n for entering and validating international telephone numbers.\n\n@typedef {typeof IntlPhoneNumberField.propTypes} IntlPhoneNumberFieldPropTypes properties for IntlPhoneNumberField component\n@extends FieldComponent<PropTypes.InferProps<IntlPhoneNumberFieldPropTypes>>\n@returns {React.ReactElement}\n\n@metadataType field",
  methods: [{
    name: "renderIntlPhoneNumberFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      type: null
    }, {
      name: "countryContext",
      type: null
    }],
    returns: null
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} value - new value",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "validateNumber",
    docblock: "Validation helper to retrieve validation boolean from intl-tel-input\n\n@param {boolean} validateNumber - validation state\n@param {number} validationErrorCode - validation error code",
    modifiers: [],
    params: [{
      name: "validateNumber",
      description: "validation state",
      type: {
        name: "boolean"
      },
      optional: !1
    }, {
      name: "validationErrorCode",
      description: "validation error code",
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: null,
    description: "Validation helper to retrieve validation boolean from intl-tel-input"
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "rulesConfig",
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }],
  displayName: "IntlPhoneNumberField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShapeWithPhone"
      },
      required: !1,
      description: "Type of returned value in the `onValeChange` callback"
    },
    showCountryCodeForReadOnly: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sets the country code visibility if rendered in `readOnly` mode"
    },
    noDropdown: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides the country drop-down list, the country number is always displayed"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "PhoneShape"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: 'Phone number value.\nPassed as a string e.g., "+48600500400", or phone shape e.g.,\n`{countryCode: { code: "PL" }, phoneNumber: "600500400"}`.\n`countryCode` has to be an iso2 country code in lower or upper case.'
    },
    defaultCountry: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default country code to be rendered. Must be an iso2 country code string."
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, IntlPhoneNumberField.__docgenInfo = {
  componentName: "IntlPhoneNumberField",
  packageName: "@jutro/components",
  description: "IntlPhoneNumberField is a 'field' component that displays a label, control and message. It can be used to render\n for entering and validating international telephone numbers.",
  displayName: "IntlPhoneNumberField",
  methods: [{
    name: "renderIntlPhoneNumberFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      optional: void 0,
      type: null
    }, {
      name: "countryContext",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} value - new value",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "validateNumber",
    docblock: "Validation helper to retrieve validation boolean from intl-tel-input\n\n@param {boolean} validateNumber - validation state\n@param {number} validationErrorCode - validation error code",
    modifiers: [],
    params: [{
      name: "validateNumber",
      description: "validation state",
      type: {
        name: "boolean"
      },
      optional: !1
    }, {
      name: "validationErrorCode",
      description: "validation error code",
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: null,
    description: "Validation helper to retrieve validation boolean from intl-tel-input"
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "rulesConfig",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }],
  actualName: "IntlPhoneNumberField",
  metadataType: "field",
  props: {
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'string'",
          computed: !1
        }, {
          value: "'object'",
          computed: !1
        }]
      },
      required: !1,
      description: "Type of returned value in the `onValeChange` callback",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "PhoneShape"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: 'Phone number value.\nPassed as a string e.g., "+48600500400", or phone shape e.g.,\n`{countryCode: { code: "PL" }, phoneNumber: "600500400"}`.\n`countryCode` has to be an iso2 country code in lower or upper case.'
    },
    defaultCountry: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default country code to be rendered. Must be an iso2 country code string."
    },
    showCountryCodeForReadOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sets the country code visibility if rendered in `readOnly` mode",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    noDropdown: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides the country drop-down list, the country number is always displayed",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};