import { defineMessages } from "react-intl";
export var messages = defineMessages({
  logout: {
    id: "jutro-app.AuthErrorPage.logout",
    defaultMessage: "Log out"
  },
  accessDenied: {
    id: "jutro-app.AuthErrorPage.accessDenied",
    defaultMessage: "Access Denied"
  },
  accessDeniedMessage: {
    id: "jutro-app.AuthErrorPage.accessDeniedMessage",
    defaultMessage: "Sorry, you are not allowed to access this page. Please log out."
  },
  genericError: {
    id: "jutro-app.AuthErrorPage.genericError",
    defaultMessage: "Error"
  },
  authErrorTitle: {
    id: "jutro-auth.SecureRoute.authErrorTitle",
    defaultMessage: "Authentication Error"
  },
  authErrorMessage: {
    id: "jutro-auth.SecureRoute.authErrorMessage",
    defaultMessage: "This application requires authentication but it is disabled"
  }
});