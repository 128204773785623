import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { useContext } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { BreakpointTrackerContext } from "@jutro/layout";
import styles from "./ProgressStep.module.css";
import { Icon } from "../../Icon/Icon";
import { messages } from "../Progressindicator.messages";
export var ProgressStep = function (_ref) {
  var _cx,
    id = _ref.id,
    step = _ref.step,
    className = _ref.className,
    isVertical = _ref.isVertical,
    index = _ref.index,
    translator = useTranslator(),
    breakpoint = useContext(BreakpointTrackerContext),
    visited = step.visited,
    active = step.active,
    title = step.title,
    onClick = step.onClick,
    _step$disabled = step.disabled,
    disabled = void 0 !== _step$disabled && _step$disabled,
    progressStepClasses = cx(styles.progressStep, className, (_defineProperty(_cx = {}, styles.active, active), _defineProperty(_cx, styles.visited, visited), _defineProperty(_cx, styles.vertical, isVertical), _cx)),
    beforeConnectorClasses = cx(styles.markersConnector, styles.beforeConnector),
    afterConnectorClasses = cx(styles.markersConnector, styles.afterConnector);
  return React.createElement("div", {
    className: progressStepClasses,
    onClick: onClick,
    onKeyPress: function (e) {
      return "Enter" === e.key && (null == onClick ? void 0 : onClick(e));
    },
    tabIndex: 0,
    role: "menuitem",
    id: id,
    "aria-current": active ? "step" : void 0,
    title: "phone" === breakpoint ? translator(title) : void 0,
    "aria-label": translator(messages.ariaLabel, {
      progressTitle: translator(title),
      progressLabel: visited && !active ? translator(messages.visited) : void 0
    })
  }, React.createElement("div", {
    className: styles.markerWrapper
  }, React.createElement("div", {
    className: beforeConnectorClasses
  }), React.createElement("div", {
    className: cx(styles.progressMarker, _defineProperty({}, styles.progressStepDisabled, disabled)),
    "aria-hidden": !0
  }, visited && !active ? React.createElement(Icon, {
    icon: "gw-check"
  }) : null, active ? React.createElement("div", {
    className: styles.circle
  }) : null, visited || active ? null : index + 1), React.createElement("div", {
    className: afterConnectorClasses
  })), React.createElement("div", {
    className: cx(styles.title, _defineProperty({}, styles.titleDisabled, disabled)),
    "aria-disabled": !!disabled || void 0
  }, title ? translator(title) : " "));
};
ProgressStep.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ProgressStep"
}, ProgressStep.__docgenInfo = {
  componentName: "ProgressStep",
  packageName: "@jutro/components",
  description: "",
  displayName: "ProgressStep",
  methods: [],
  actualName: "ProgressStep"
};