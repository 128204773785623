import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _Object$getPrototypeOf from "@babel/runtime-corejs3/core-js-stable/object/get-prototype-of";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$getOwnPropertyNames from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-names";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
function getPrototype(obj) {
  return obj.prototype || _Object$getPrototypeOf(obj);
}
var ImplDescriptor = function () {
  function ImplDescriptor(objOrFunc) {
    _classCallCheck(this, ImplDescriptor), this.instance = objOrFunc, this.allPrototypes = function (object) {
      for (var proto = getPrototype(object), protos = []; null != proto && proto !== Object.prototype;) protos.push(proto), proto = getPrototype(proto);
      return protos;
    }(objOrFunc);
  }
  return _createClass(ImplDescriptor, [{
    key: "findPrototypeMember",
    value: function (name) {
      var _context,
        proto = _findInstanceProperty(_context = this.allPrototypes).call(_context, function (p) {
          return void 0 !== p[name];
        });
      return proto ? proto[name] : void 0;
    }
  }, {
    key: "getMembers",
    value: function () {
      var _context2,
        _context3,
        _context4,
        keys = new _Set(_filterInstanceProperty(_context2 = _concatInstanceProperty(_context3 = []).call(_context3, _toConsumableArray(_Object$getOwnPropertyNames(this.instance)), _toConsumableArray(_mapInstanceProperty(_context4 = this.allPrototypes).call(_context4, function (p) {
          return _Object$getOwnPropertyNames(p);
        })))).call(_context2, function (k) {
          return "prototype" !== k && "constructor" !== k;
        }));
      return _Array$from(keys);
    }
  }, {
    key: "getMember",
    value: function (name) {
      return this.instance[name] || this.findPrototypeMember(name);
    }
  }, {
    key: "hasMember",
    value: function (name) {
      return void 0 !== this.getMember(name);
    }
  }]), ImplDescriptor;
}();
export { ImplDescriptor as default };