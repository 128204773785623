import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
export function usePathMatch(deriveMatch) {
  var history = useHistory(),
    _useState = useState(function () {
      return deriveMatch(history.location.pathname);
    }),
    _useState2 = _slicedToArray(_useState, 2),
    pathMatch = _useState2[0],
    setPathMatch = _useState2[1];
  return useEffect(function () {
    var updateMatch = function (location) {
        var newMatch = deriveMatch(location.pathname);
        setPathMatch(function (oldMatch) {
          return isEqual(oldMatch, newMatch) ? oldMatch : newMatch;
        });
      },
      unregister = history.listen(updateMatch);
    return updateMatch(history.location), unregister;
  }, [deriveMatch, history]), pathMatch;
}
var stringIdentity = function (pathname) {
  return pathname;
};
export var usePathname = function () {
  return usePathMatch(stringIdentity);
};