import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["startDate", "startValue", "separator", "endDate", "endValue"],
  _excluded2 = ["startDate", "endDate", "startValue", "endValue", "separator"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import { dateFormatShape, dateValueShape, intlMessageShape } from "@jutro/prop-types";
import { FormattedDate, formatDate } from "./FormattedDate";
import { FieldComponent } from "../inputs/FieldComponent/FieldComponent";
var formattedDateRangePropTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  startValue: dateValueShape,
  endValue: dateValueShape,
  placeholder: intlMessageShape,
  displayFormat: dateFormatShape,
  separator: PropTypes.string
};
export var FormattedDateRange = function (props) {
  var startDate = props.startDate,
    startValue = props.startValue,
    separator = props.separator,
    endDate = props.endDate,
    endValue = props.endValue,
    other = _objectWithoutProperties(props, _excluded);
  return React.createElement(React.Fragment, null, React.createElement(FormattedDate, _extends({
    date: startDate,
    value: startValue
  }, other)), separator, React.createElement(FormattedDate, _extends({
    date: endDate,
    value: endValue
  }, other)));
};
FormattedDateRange.propTypes = formattedDateRangePropTypes, FormattedDateRange.defaultProps = {
  displayFormat: "long",
  placeholder: FieldComponent.defaultROEmptyValue,
  separator: " - "
};
export function formatDateRange(intl, props, dateRangeObject) {
  var _context,
    _context2,
    _ref = isNil(dateRangeObject) ? {} : dateRangeObject,
    startDate = _ref.startDate,
    endDate = _ref.endDate,
    _ref$separator = _ref.separator,
    separator = void 0 === _ref$separator ? FormattedDateRange.defaultProps.separator : _ref$separator,
    startDateProp = props.startDate,
    endDateProp = props.endDate,
    startValue = props.startValue,
    endValue = props.endValue,
    separatorProp = props.separator,
    otherProps = _objectWithoutProperties(props, _excluded2),
    formattedStartDate = formatDate(intl, _objectSpread(_objectSpread({}, otherProps), {}, {
      date: startDateProp,
      value: startValue
    }), startDate),
    formattedEndDate = formatDate(intl, _objectSpread(_objectSpread({}, otherProps), {}, {
      date: endDateProp,
      value: endValue
    }), endDate);
  return _concatInstanceProperty(_context = _concatInstanceProperty(_context2 = "".concat(formattedStartDate)).call(_context2, separator || separatorProp)).call(_context, formattedEndDate);
}
FormattedDateRange.__docgenInfo = {
  description: "The `FormattedDateRange` component is used to render `Date` values\nlocalized and in specific preset formats\n\n@type {React.FC<PropTypes.InferProps<typeof formattedDateRangePropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FormattedDateRange",
  props: {
    displayFormat: {
      defaultValue: {
        value: "'long'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateFormatShape"
      },
      required: !1,
      description: "The date format: 'short' or 'long'"
    },
    placeholder: {
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The string to display if start or end 'date' is undefined/null"
    },
    separator: {
      defaultValue: {
        value: "' - '",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The range separator (between start and end date)"
    },
    startDate: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: !1,
      description: "The start date to display"
    },
    endDate: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: !1,
      description: "The end date to display"
    },
    startValue: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "Start value to display in the format of string|number|object"
    },
    endValue: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "End value to display in the format of string|number|object"
    }
  }
}, FormattedDateRange.__docgenInfo = {
  componentName: "FormattedDateRange",
  packageName: "@jutro/components",
  description: "The `FormattedDateRange` component is used to render `Date` values\nlocalized and in specific preset formats",
  displayName: "FormattedDateRange",
  methods: [],
  actualName: "FormattedDateRange",
  metadataType: "element",
  props: {
    startDate: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: !1,
      description: "The start date to display"
    },
    endDate: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: !1,
      description: "The end date to display"
    },
    startValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Start value to display in the format of string|number|object"
    },
    endValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "End value to display in the format of string|number|object"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The string to display if start or end 'date' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      }
    },
    displayFormat: {
      type: {
        name: "custom",
        raw: "dateFormatShape"
      },
      required: !1,
      description: "The date format: 'short' or 'long'",
      defaultValue: {
        value: "'long'",
        computed: !1
      }
    },
    separator: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The range separator (between start and end date)",
      defaultValue: {
        value: "' - '",
        computed: !1
      }
    }
  }
};