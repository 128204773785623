import { defineMessages } from "react-intl";
export var messages = defineMessages({
  breadcrumbLabel: {
    id: "jutro-components.widgets.Breadcrumb.ariaLabel",
    defaultMessage: "breadcrumb"
  },
  breadcrumbBackButtonLabel: {
    id: "jutro-components.widgets.Breadcrumb.backButtonLabel",
    defaultMessage: "Back"
  }
});