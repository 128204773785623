import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _context;
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React from "react";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { warning, makeUniqueWarningLog } from "@jutro/logger";
import { Accordion, AccordionCard, Avatar, Badge, Breadcrumb, Button, ToggleField, Card, CheckboxField, CheckboxGroupField, ClickableCard, IconButton, ColorSwatch, CurrencyField, CurrencyValue, DateField, DateValue, ImageRadioButtonField, Image, InfoLabel, InlineNotification, InputField, InputMaskField, InputNumberField, FileUploadField, FieldValue, FormattedCurrency, FormattedDate, FormattedDateRange, FormattedNumber, GlobalizationChooser, Link, Loader, LookupField, MapArea, NumberValue, PhoneNumberField, RadioButtonCardField, RadioButtonField, DropdownSelectField, StepperField, SwitchField, Slider, TabSet, Tab, TextAreaField, IntlElement, TypeaheadMultiSelectField, YearField, Header, HeaderActions, LogoTitle, Footer, FooterText, FooterNavBar, FooterCopyright, FooterNavLink, StickyFooter, InlineLoader, NotificationAction, IFrame, TagCollection, Tag, FieldIcon, Icon, Chevron, HelpHeading, HelpParagraph, HelpLink, SimpleProgressBar, PhaseProgressBar, StepProgressBar, ThemeChooser, ErrorBoundary, IntlPhoneNumberField, FormattedPhoneNumber, PhoneNumberValue, DateTimeField, DateRangeField, DateTimeZoneField, LanguageSelector, DropdownMenu, DropdownMenuSeparator, DropdownMenuButton, DropdownMenuLink, DropdownMenuHeader, DropdownMenuAvatar, RadioField, SimpleDateField, SimpleMonthYearField, MonthYearField, SingleColumnLayout, OneToOneColumnLayout, TwoToOneColumnLayout, SettingsCard, GlobalizationSettingsCard, ThemeSettingsCard } from "@jutro/components";
import { TextHighlight, TreeNode, TreeView } from "@jutro/components/lab-preview";
import { Grid, GridItem, GridLayout, ColumnsLayout, Flex, FlexItem } from "@jutro/layout";
import { AsyncLink, AsyncButtonLink, ButtonLink, TopNavigation, SideNavigation } from "@jutro/router";
export var simpleDatatypeComponentMap = {
  boolean: {
    component: ToggleField
  },
  checkbox: {
    component: CheckboxField
  },
  currency: {
    component: CurrencyField
  },
  number: {
    component: InputNumberField
  },
  integer: {
    component: InputNumberField
  },
  radio: {
    component: RadioButtonField
  },
  select: {
    component: DropdownSelectField
  },
  string: {
    component: InputField
  },
  tel: {
    component: PhoneNumberField
  },
  text: {
    component: InputField
  },
  textarea: {
    component: TextAreaField
  },
  typelist: {
    component: DropdownSelectField
  },
  date: {
    component: DateField
  },
  year: {
    component: YearField
  },
  yesno: {
    component: ToggleField
  }
};
export var simpleNamedComponentMap = {
  Accordion: {
    component: Accordion
  },
  AccordionCard: {
    component: AccordionCard
  },
  AsyncLink: {
    component: AsyncLink
  },
  AsyncButtonLink: {
    component: AsyncButtonLink
  },
  Avatar: {
    component: Avatar
  },
  Badge: {
    component: Badge
  },
  Breadcrumb: {
    component: Breadcrumb
  },
  Button: {
    component: Button
  },
  ButtonLink: {
    component: ButtonLink
  },
  IconButton: {
    component: IconButton
  },
  Toggle: {
    component: ToggleField
  },
  Card: {
    component: Card
  },
  ClickableCard: {
    component: ClickableCard
  },
  Checkbox: {
    component: CheckboxField
  },
  CheckboxGroup: {
    component: CheckboxGroupField
  },
  Chevron: {
    component: Chevron
  },
  ColorSwatch: {
    component: ColorSwatch
  },
  Currency: {
    component: CurrencyField
  },
  CurrencyValue: {
    component: CurrencyValue
  },
  DateValue: {
    component: DateValue
  },
  DropdownMenu: {
    component: DropdownMenu
  },
  DropdownMenuAvatar: {
    component: DropdownMenuAvatar
  },
  DropdownMenuButton: {
    component: DropdownMenuButton
  },
  DropdownMenuHeader: {
    component: DropdownMenuHeader
  },
  DropdownMenuLink: {
    component: DropdownMenuLink
  },
  DropdownMenuSeparator: {
    component: DropdownMenuSeparator
  },
  FieldIcon: {
    component: FieldIcon
  },
  FieldValue: {
    component: FieldValue
  },
  Flex: {
    component: Flex
  },
  FlexItem: {
    component: FlexItem
  },
  Footer: {
    component: Footer
  },
  FooterText: {
    component: FooterText
  },
  FooterNavBar: {
    component: FooterNavBar
  },
  FooterCopyright: {
    component: FooterCopyright
  },
  FooterNavLink: {
    component: FooterNavLink
  },
  FormattedCurrency: {
    component: FormattedCurrency
  },
  FormattedDate: {
    component: FormattedDate
  },
  FormattedDateRange: {
    component: FormattedDateRange
  },
  FormattedNumber: {
    component: FormattedNumber
  },
  Fragment: {
    component: React.Fragment
  },
  Grid: {
    component: Grid
  },
  GridItem: {
    component: GridItem
  },
  GridLayout: {
    component: GridLayout
  },
  ColumnsLayout: {
    component: ColumnsLayout
  },
  SingleColumnLayout: {
    component: SingleColumnLayout
  },
  OneToOneColumnLayout: {
    component: OneToOneColumnLayout
  },
  TwoToOneColumnLayout: {
    component: TwoToOneColumnLayout
  },
  Icon: {
    component: Icon
  },
  Image: {
    component: Image
  },
  ImageRadioButton: {
    component: ImageRadioButtonField
  },
  InfoLabel: {
    component: InfoLabel
  },
  InlineNotification: {
    component: InlineNotification
  },
  Input: {
    component: InputField
  },
  InputMask: {
    component: InputMaskField
  },
  InputNumber: {
    component: InputNumberField
  },
  PhoneNumber: {
    component: PhoneNumberField
  },
  FileUpload: {
    component: FileUploadField
  },
  Link: {
    component: Link
  },
  Loader: {
    component: Loader
  },
  Lookup: {
    component: LookupField
  },
  GlobalizationChooser: {
    component: GlobalizationChooser
  },
  MapArea: {
    component: MapArea
  },
  NotificationAction: {
    component: NotificationAction
  },
  NumberValue: {
    component: NumberValue
  },
  RadioButtonCard: {
    component: RadioButtonCardField
  },
  RadioButton: {
    component: RadioButtonField
  },
  DropdownSelect: {
    component: DropdownSelectField
  },
  Stepper: {
    component: StepperField
  },
  Switch: {
    component: SwitchField
  },
  Slider: {
    component: Slider
  },
  TabSet: {
    component: TabSet
  },
  Tab: {
    component: Tab
  },
  TextArea: {
    component: TextAreaField
  },
  ThemeChooser: {
    component: ThemeChooser
  },
  TypeaheadMultiSelect: {
    component: TypeaheadMultiSelectField
  },
  Date: {
    component: DateField
  },
  DateTime: {
    component: DateTimeField
  },
  DateTimeZone: {
    component: DateTimeZoneField
  },
  Year: {
    component: YearField
  },
  Header: {
    component: Header
  },
  HeaderActions: {
    component: HeaderActions
  },
  LogoTitle: {
    component: LogoTitle
  },
  TopNavigation: {
    component: TopNavigation
  },
  SideNavigation: {
    component: SideNavigation
  },
  StickyFooter: {
    component: StickyFooter
  },
  InlineLoader: {
    component: InlineLoader
  },
  IFrame: {
    component: IFrame
  },
  IntlElement: {
    component: IntlElement
  },
  Tag: {
    component: Tag
  },
  TagCollection: {
    component: TagCollection
  },
  HelpHeading: {
    component: HelpHeading
  },
  HelpParagraph: {
    component: HelpParagraph
  },
  HelpLink: {
    component: HelpLink
  },
  SimpleProgressBar: {
    component: SimpleProgressBar
  },
  PhaseProgressBar: {
    component: PhaseProgressBar
  },
  StepProgressBar: {
    component: StepProgressBar
  },
  IntlPhoneNumber: {
    component: IntlPhoneNumberField
  },
  DateRange: {
    component: DateRangeField
  },
  ErrorBoundary: {
    component: ErrorBoundary
  },
  FormattedPhoneNumber: {
    component: FormattedPhoneNumber
  },
  PhoneNumberValue: {
    component: PhoneNumberValue
  },
  LanguageSelector: {
    component: LanguageSelector
  },
  Radio: {
    component: RadioField
  },
  SimpleDate: {
    component: SimpleDateField
  },
  SimpleMonthYear: {
    component: SimpleMonthYearField
  },
  TextHighlight: {
    component: TextHighlight
  },
  TreeNode: {
    component: TreeNode
  },
  TreeView: {
    component: TreeView
  },
  MonthYear: {
    component: MonthYearField
  },
  SettingsCard: {
    component: SettingsCard
  },
  GlobalizationSettingsCard: {
    component: GlobalizationSettingsCard
  },
  ThemeSettingsCard: {
    component: ThemeSettingsCard
  }
};
export var lowercaseSimpleNamedComponentMap = _reduceInstanceProperty(_context = _Object$entries(simpleNamedComponentMap)).call(_context, function (componentMap, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    key = _ref2[0],
    val = _ref2[1];
  return _objectSpread(_objectSpread({}, componentMap), {}, _defineProperty({}, key.toLowerCase(), val));
}, {});
export function resolveComponentFromDatatype(datatype) {
  var useDefault = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
    componentInfo = simpleDatatypeComponentMap[datatype && datatype.toLowerCase()];
  return componentInfo || (useDefault ? simpleDatatypeComponentMap.string : null);
}
export function resolveComponentFromName(name) {
  if (name) return simpleNamedComponentMap[name] || resolveComponentFromLowercaseName(name);
}
var logDeprecationWarning = makeUniqueWarningLog('using case-insensitive keys for "component" property in metadata has been deprecated. Match the casing used by the component Name'),
  resolveComponentFromLowercaseName = function (name) {
    var component = lowercaseSimpleNamedComponentMap[name.toLowerCase()];
    return component && logDeprecationWarning(), component;
  };
export function setComponentMapOverrides(componentMap, components) {
  var overrideMap = cloneDeep(componentMap);
  _Object$keys(overrideMap).forEach(function (key) {
    var overrideComponent = overrideMap[key].component;
    if (overrideComponent) {
      if (components) {
        var component = components[overrideComponent];
        component && (overrideComponent === overrideComponent.toLowerCase() ? lowercaseSimpleNamedComponentMap[overrideComponent.toLowerCase()] = {
          component: component
        } : simpleNamedComponentMap[overrideComponent] = {
          component: component
        });
      }
      if (resolveComponentFromName(overrideComponent)) {
        var resolvedComponent = resolveComponentFromName(overrideComponent);
        overrideMap[key].component = resolvedComponent.component;
      }
    }
    simpleDatatypeComponentMap[key] && (!0 === overrideMap[key].replace ? simpleDatatypeComponentMap[key] = overrideMap[key] : merge(simpleDatatypeComponentMap[key], overrideMap[key]));
  });
}
export function setSimpleNamedComponentMapOverrides(componentMapOverrides) {
  _Object$keys(componentMapOverrides).forEach(function (key) {
    if (simpleNamedComponentMap[key] && (simpleNamedComponentMap[key] = _objectSpread(_objectSpread({}, simpleNamedComponentMap[key]), componentMapOverrides[key])), lowercaseSimpleNamedComponentMap[key.toLowerCase()]) {
      var lowerCaseKey = key.toLowerCase();
      lowercaseSimpleNamedComponentMap[lowerCaseKey] = _objectSpread(_objectSpread({}, lowercaseSimpleNamedComponentMap[lowerCaseKey]), componentMapOverrides[key]);
    }
  });
}
export function appendSimpleNamedComponentMap(componentsMap) {
  _Object$entries(componentsMap).forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    simpleNamedComponentMap[key] || lowercaseSimpleNamedComponentMap[key.toLowerCase()] ? warning("".concat(key, " already exists in simpleNamedComponentMap")) : (simpleNamedComponentMap[key] = value, lowercaseSimpleNamedComponentMap[key.toLowerCase()] = value);
  });
}
export function setSimpleDatatypeComponentMapOverrides(componentMapOverrides) {
  _Object$keys(componentMapOverrides).forEach(function (key) {
    simpleDatatypeComponentMap[key] && (simpleDatatypeComponentMap[key] = _objectSpread(_objectSpread({}, simpleDatatypeComponentMap[key]), componentMapOverrides[key]));
  });
}