import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "buttonText", "isOpen", "autoFocus", "dropUp", "alignRight", "className", "menuClassName", "children", "icon", "toggleButtonClassName", "fullWidth"],
  _excluded2 = ["id", "menuId", "isOpen", "ref"];
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { intlMessageShape } from "@jutro/prop-types";
import { Button } from "../button/Button";
import { DropdownMenu } from "./DropdownMenu";
import styles from "./DropdownMenuButton.module.css";
export var DropdownMenuButton = function (_ref) {
  var id = _ref.id,
    buttonText = _ref.buttonText,
    isOpenProp = _ref.isOpen,
    autoFocus = _ref.autoFocus,
    dropUp = _ref.dropUp,
    alignRight = _ref.alignRight,
    className = _ref.className,
    menuClassName = _ref.menuClassName,
    children = _ref.children,
    icon = _ref.icon,
    toggleButtonClassName = _ref.toggleButtonClassName,
    fullWidth = _ref.fullWidth,
    buttonProps = _objectWithoutProperties(_ref, _excluded),
    menuProps = {
      isOpen: isOpenProp,
      autoFocus: autoFocus,
      dropUp: dropUp,
      alignRight: alignRight,
      menuClassName: menuClassName
    },
    classes = cx(_defineProperty({}, styles.fullWidth, fullWidth), className);
  return React.createElement(DropdownMenu, _extends({
    id: id,
    className: classes
  }, menuProps, {
    renderTrigger: function (_ref2, toggleMenu) {
      var buttonId = _ref2.id,
        menuId = _ref2.menuId,
        isOpen = _ref2.isOpen,
        ref = _ref2.ref,
        other = _objectWithoutProperties(_ref2, _excluded2);
      return React.createElement(Button, _extends({
        id: buttonId,
        className: toggleButtonClassName
      }, other, buttonProps, {
        onClick: function () {
          return toggleMenu(!isOpen);
        },
        "aria-controls": menuId,
        "aria-haspopup": "true",
        ref: ref,
        icon: icon
      }), buttonText);
    }
  }), children);
};
var propTypes = {
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  dropUp: PropTypes.bool,
  alignRight: PropTypes.bool,
  menuClassName: PropTypes.string,
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  toggleButtonClassName: PropTypes.string,
  fullWidth: PropTypes.bool
};
DropdownMenuButton.propTypes = propTypes, DropdownMenuButton.displayName = "DropdownMenuButton", DropdownMenuButton.__docgenInfo = {
  description: "DropdownMenuButton\n\n@metadataType action",
  methods: [],
  displayName: "DropdownMenuButton",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the menu component"
    },
    buttonText: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Content to be rendered as the 'children' for the 'Button' component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name displayed inside toggle button"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu is currently opened"
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu appears above the trigger component."
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, menu items are aligned to the right edge of the menu."
    },
    menuClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Override class for the inner menu of the dropdown menu"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu button component"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the first menu item gets focused after opening the dropdown menu"
    },
    toggleButtonClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for toggle button"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, expands button to parent's boundaries"
    }
  }
}, DropdownMenuButton.__docgenInfo = {
  componentName: "DropdownMenuButton",
  packageName: "@jutro/components",
  description: "DropdownMenuButton",
  displayName: "DropdownMenuButton",
  methods: [],
  actualName: "DropdownMenuButton",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the menu component"
    },
    buttonText: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Content to be rendered as the 'children' for the 'Button' component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name displayed inside toggle button"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu is currently opened"
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu appears above the trigger component."
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, menu items are aligned to the right edge of the menu."
    },
    menuClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Override class for the inner menu of the dropdown menu"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu button component"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the first menu item gets focused after opening the dropdown menu"
    },
    toggleButtonClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for toggle button"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, expands button to parent's boundaries"
    }
  }
};