import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import { fastOptionsMerge } from "./helper";
import { HttpEventTypes } from "./common";
function defaultOnResponse(value) {
  return _Promise.resolve(value);
}
function defaultOnError(value) {
  return _Promise.reject(value);
}
function defaultOnException(error) {
  return _Promise.reject(error);
}
function defaultOnTrace() {}
function defaultOnFetch(url, options) {
  var fetchCall = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : fetch;
  return fetchCall(url, options);
}
export var HttpTransport = _createClass(function HttpTransport(handlers) {
  var _ref,
    _this = this;
  _classCallCheck(this, HttpTransport), _defineProperty(this, "onAuth", void 0), _defineProperty(this, "onResponse", void 0), _defineProperty(this, "onErrorResponse", void 0), _defineProperty(this, "onException", void 0), _defineProperty(this, "onTrace", void 0), _defineProperty(this, "onFetch", void 0), _defineProperty(this, "fetch", (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(url, method, opts) {
    var options, start, fetchWithTrace, _response;
    return _regeneratorRuntime.wrap(function (_context2) {
      for (;;) switch (_context2.prev = _context2.next) {
        case 0:
          return options = _objectSpread(_objectSpread({}, opts), {}, {
            method: method
          }), start = Date.now(), fetchWithTrace = function () {
            var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
              var fetchOptions, authOptions;
              return _regeneratorRuntime.wrap(function (_context) {
                for (;;) switch (_context.prev = _context.next) {
                  case 0:
                    if (_this.onTrace(HttpEventTypes.request, {
                      url: url,
                      options: options
                    }), fetchOptions = options, !_this.onAuth) {
                      _context.next = 7;
                      break;
                    }
                    return _context.next = 5, _this.onAuth();
                  case 5:
                    authOptions = _context.sent, fetchOptions = fastOptionsMerge(options, authOptions);
                  case 7:
                    return _context.abrupt("return", window.fetch(url, fetchOptions));
                  case 8:
                  case "end":
                    return _context.stop();
                }
              }, _callee);
            }));
            return function () {
              return _ref2.apply(this, arguments);
            };
          }(), _context2.prev = 3, _context2.next = 6, _this.onFetch(url, options, fetchWithTrace);
        case 6:
          if (!(_response = _context2.sent).ok) {
            _context2.next = 10;
            break;
          }
          return _this.onTrace(HttpEventTypes.response, {
            url: url,
            options: options,
            response: _response,
            duration: Date.now() - start
          }), _context2.abrupt("return", _this.onResponse(_response));
        case 10:
          return _this.onTrace(HttpEventTypes.error, {
            url: url,
            options: options,
            error: _response,
            duration: Date.now() - start
          }), _context2.abrupt("return", _this.onErrorResponse(_response));
        case 14:
          return _context2.prev = 14, _context2.t0 = _context2.catch(3), _this.onTrace(HttpEventTypes.exception, {
            url: url,
            options: options,
            exception: _context2.t0.toString(),
            duration: Date.now() - start
          }), _context2.abrupt("return", _this.onException(_context2.t0));
        case 18:
        case "end":
          return _context2.stop();
      }
    }, _callee2, null, [[3, 14]]);
  })), function (_x, _x2, _x3) {
    return _ref.apply(this, arguments);
  }));
  var _ref3 = handlers || {},
    onAuth = _ref3.onAuth,
    onErrorResponse = _ref3.onErrorResponse,
    onException = _ref3.onException,
    onFetch = _ref3.onFetch,
    onResponse = _ref3.onResponse,
    onTrace = _ref3.onTrace;
  this.onAuth = onAuth, this.onFetch = onFetch || defaultOnFetch, this.onResponse = onResponse || defaultOnResponse, this.onErrorResponse = onErrorResponse || defaultOnError, this.onException = onException || defaultOnException, this.onTrace = onTrace || defaultOnTrace;
});