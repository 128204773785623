import React, { useEffect } from "react";
import { Flex } from "@jutro/layout";
import { YearFieldCustomHeader } from "../YearField/YearFieldCustomHeader";
import { messages } from "./MonthYearField.messages";
import styles from "./MonthYearFieldCustomHeader.module.css";
export var MonthYearFieldCustomHeader = function (headerProps) {
  var date = headerProps.date,
    increaseYear = headerProps.increaseYear,
    decreaseYear = headerProps.decreaseYear,
    changeYear = headerProps.changeYear,
    prevYearButtonDisabled = headerProps.prevYearButtonDisabled,
    nextYearButtonDisabled = headerProps.nextYearButtonDisabled,
    PrevButtonComponent = headerProps.PrevButtonComponent,
    NextButtonComponent = headerProps.NextButtonComponent,
    onClick = headerProps.onClick,
    selectedYear = headerProps.selectedYear,
    monthPickerActive = headerProps.monthPickerActive;
  return useEffect(function () {
    void 0 !== selectedYear && changeYear(selectedYear);
  }, [changeYear, selectedYear]), monthPickerActive ? React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "between"
  }, React.createElement(PrevButtonComponent, {
    onClick: decreaseYear,
    disabled: prevYearButtonDisabled,
    label: messages.previousYear
  }), React.createElement("div", {
    role: "button",
    tabIndex: 0,
    onClick: onClick,
    onKeyPress: onClick,
    className: styles.currentYear
  }, date.getFullYear()), React.createElement(NextButtonComponent, {
    onClick: increaseYear,
    disabled: nextYearButtonDisabled,
    label: messages.nextYear
  })) : React.createElement(YearFieldCustomHeader, headerProps);
};
MonthYearFieldCustomHeader.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "MonthYearFieldCustomHeader"
}, MonthYearFieldCustomHeader.__docgenInfo = {
  componentName: "MonthYearFieldCustomHeader",
  packageName: "@jutro/components",
  description: "",
  displayName: "MonthYearFieldCustomHeader",
  methods: [],
  actualName: "MonthYearFieldCustomHeader"
};