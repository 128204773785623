import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { intlMessageShape, validationMessageShape } from "@jutro/prop-types";
import { useId } from "@jutro/platform";
import isEmpty from "lodash/isEmpty";
import { InlineNotificationContent } from "./InlineNotificationContent";
import { InlineNotificationBundle } from "./InlineNotificationBundle";
import { linkPropTypes } from "../Link/Link";
import styles from "./InlineNotification.module.css";
import { inlineSeverityTypes, stylesNotificationByType } from "./InlineNotificationStyleValues";
export var InlineNotification = function (_ref) {
  var _ref$isDismissable = _ref.isDismissable,
    isDismissable = void 0 === _ref$isDismissable || _ref$isDismissable,
    _ref$type = _ref.type,
    type = void 0 === _ref$type ? "info" : _ref$type,
    _ref$isEmbeddedNotifi = _ref.isEmbeddedNotification,
    isEmbeddedNotification = void 0 !== _ref$isEmbeddedNotifi && _ref$isEmbeddedNotifi,
    _ref$isTooltipEnabled = _ref.isTooltipEnabled,
    isTooltipEnabled = void 0 === _ref$isTooltipEnabled || _ref$isTooltipEnabled,
    _ref$isBundleOpenByDe = _ref.isBundleOpenByDefault,
    isBundleOpenByDefault = void 0 !== _ref$isBundleOpenByDe && _ref$isBundleOpenByDe,
    onClosed = _ref.onClosed,
    className = _ref.className,
    message = _ref.message,
    messageProps = _ref.messageProps,
    linkProps = _ref.linkProps,
    idProp = _ref.id,
    id = useId({
      default: idProp
    }),
    _useState = useState(!0),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1],
    handleDismiss = function () {
      null == onClosed || onClosed(), setIsOpen(!1);
    },
    previousTypeRef = useRef(),
    previousMessageRef = useRef();
  useEffect(function () {
    var previousType = previousTypeRef.current,
      previousMessage = previousMessageRef.current;
    void 0 === previousType || void 0 === previousMessage || previousType === type && previousMessage === message || setIsOpen(!0), previousTypeRef.current = type, previousMessageRef.current = message;
  }, [message, type]);
  var ariaProps = isEmbeddedNotification ? {} : {
    role: "alert",
    "aria-hidden": !isOpen
  };
  return isOpen && !isBundledMessagesEmpty(message) ? React.createElement(InlineNotificationBundle, {
    id: id,
    messages: message,
    isEmbeddedNotification: isEmbeddedNotification,
    isDismissable: isDismissable,
    handleDismiss: handleDismiss,
    isBundleOpenByDefault: isBundleOpenByDefault
  }) : React.createElement("div", _extends({
    id: id,
    className: cx(styles.inlineNotification, isOpen && stylesNotificationByType[type], className)
  }, ariaProps), isOpen && (!isMessageEmpty(message) || messageProps) && React.createElement(InlineNotificationContent, {
    type: type,
    messageProps: messageProps,
    message: message,
    isEmbeddedNotification: isEmbeddedNotification,
    isDismissable: isDismissable,
    handleDismiss: handleDismiss,
    linkProps: linkProps,
    id: id,
    isTooltipEnabled: isTooltipEnabled
  }));
};
var bundledMessagesShape = PropTypes.shape({
  error: PropTypes.objectOf(PropTypes.arrayOf(validationMessageShape)),
  warning: PropTypes.objectOf(PropTypes.arrayOf(validationMessageShape)),
  info: PropTypes.objectOf(PropTypes.arrayOf(validationMessageShape)),
  success: PropTypes.objectOf(PropTypes.arrayOf(validationMessageShape))
});
InlineNotification.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.oneOfType([intlMessageShape, bundledMessagesShape]),
  type: PropTypes.oneOf(["info", "success", "warning", "error"]),
  onClosed: PropTypes.func,
  isDismissable: PropTypes.bool,
  isEmbeddedNotification: PropTypes.bool,
  messageProps: PropTypes.shape({
    success: intlMessageShape,
    warning: intlMessageShape,
    info: intlMessageShape,
    error: intlMessageShape,
    dismiss: intlMessageShape
  }),
  linkProps: PropTypes.shape(function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({}, linkPropTypes)),
  isTooltipEnabled: PropTypes.bool,
  isBundleOpenByDefault: PropTypes.bool
};
var isBundledMessagesEmpty = function (message) {
    return isEmpty(message) || _Object$values(inlineSeverityTypes).every(function (severityType) {
      return isEmpty(message[severityType]);
    });
  },
  isMessageEmpty = function (message) {
    return !("string" == typeof message || Array.isArray(message) && message.length || null != message && message.defaultMessage || null != message && message.id);
  };
InlineNotification.__docgenInfo = {
  description: "A notification message.\n\n@metadataType element",
  methods: [],
  displayName: "InlineNotification",
  props: {
    isDismissable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the dismiss button appears with this component"
    },
    type: {
      defaultValue: {
        value: "'info'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set the color and appearance of the notification (success, warning, error, info)"
    },
    isEmbeddedNotification: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, attributes 'aria-hidden' and 'role' will be added"
    },
    isTooltipEnabled: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Enables the tooltip to show a full message"
    },
    isBundleOpenByDefault: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the bundled messages card will be open"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component unique identifier"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "intlMessageShape"
        }, {
          name: "custom",
          raw: "bundledMessagesShape"
        }]
      },
      required: !1,
      description: "Shape of messages to display in bundled notifications"
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          success: {
            name: "custom",
            raw: "intlMessageShape",
            description: "prefix text for a success type message",
            required: !1
          },
          warning: {
            name: "custom",
            raw: "intlMessageShape",
            description: "prefix text for a warning type message",
            required: !1
          },
          info: {
            name: "custom",
            raw: "intlMessageShape",
            description: "prefix text for an info type message",
            required: !1
          },
          error: {
            name: "custom",
            raw: "intlMessageShape",
            description: "prefix text for an error type message",
            required: !1
          },
          dismiss: {
            name: "custom",
            raw: "intlMessageShape",
            description: "dismiss button aria-label",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    linkProps: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Link prop used to navigate the user to an internal or external link"
    }
  }
}, InlineNotification.__docgenInfo = {
  componentName: "InlineNotification",
  packageName: "@jutro/components",
  description: "A notification message.",
  displayName: "InlineNotification",
  methods: [],
  actualName: "InlineNotification",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component unique identifier"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }, {
          name: "custom",
          raw: "bundledMessagesShape"
        }]
      },
      required: !1,
      description: "Shape of messages to display in bundled notifications"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set the color and appearance of the notification (success, warning, error, info)",
      defaultValue: {
        value: "'info'",
        computed: !1
      }
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    },
    isDismissable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the dismiss button appears with this component",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    isEmbeddedNotification: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, attributes 'aria-hidden' and 'role' will be added",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          success: {
            name: "union",
            description: "prefix text for a success type message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          warning: {
            name: "union",
            description: "prefix text for a warning type message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          info: {
            name: "union",
            description: "prefix text for an info type message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          error: {
            name: "union",
            description: "prefix text for an error type message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          dismiss: {
            name: "union",
            description: "dismiss button aria-label",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    linkProps: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Link prop used to navigate the user to an internal or external link"
    },
    isTooltipEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Enables the tooltip to show a full message",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    isBundleOpenByDefault: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the bundled messages card will be open",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  }
};