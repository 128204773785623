import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "className", "isMulti", "innerRef", "selectProps", "innerProps"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import find from "lodash/find";
import cx from "classnames";
import { getMenuListId } from "../helpers";
export var GenericSelectControlMenuList = function (_ref) {
  var _find,
    children = _ref.children,
    className = _ref.className,
    isMulti = _ref.isMulti,
    innerRef = _ref.innerRef,
    selectProps = _ref.selectProps,
    innerProps = _ref.innerProps,
    other = _objectWithoutProperties(_ref, _excluded),
    menuIsOpen = selectProps.menuIsOpen,
    genericSelectStyles = selectProps.genericSelectStyles,
    id = selectProps.id,
    maxHeight = other.maxHeight,
    menuListClasses = cx({
      "menu-list": !0,
      "menu-list--is-multi": isMulti
    }, genericSelectStyles.menuList, className),
    activeOptionId = null === (_find = find(children, function (child) {
      var _child$props;
      return null == child || null === (_child$props = child.props) || void 0 === _child$props ? void 0 : _child$props.isFocused;
    })) || void 0 === _find ? void 0 : _find.key;
  return React.createElement("ul", _extends({
    id: getMenuListId(id),
    className: menuListClasses,
    ref: innerRef,
    role: "listbox",
    "aria-labelledby": selectProps["aria-labelledby"],
    "aria-activedescendant": activeOptionId,
    "aria-expanded": menuIsOpen,
    tabIndex: "0"
  }, innerProps, {
    style: _objectSpread(_objectSpread({}, null == innerProps ? void 0 : innerProps.style), {}, {
      maxHeight: maxHeight
    })
  }), children);
};
GenericSelectControlMenuList.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlMenuList"
}, GenericSelectControlMenuList.__docgenInfo = {
  componentName: "GenericSelectControlMenuList",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMenuList",
  methods: [],
  actualName: "GenericSelectControlMenuList"
};