export var timezones = {
  "Africa/Abidjan": ["Africa/Abidjan"],
  "Africa/Accra": ["Africa/Accra"],
  "Africa/Addis_Ababa": ["Africa/Addis_Ababa"],
  "Africa/Algiers": ["Africa/Algiers"],
  "Africa/Asmara": ["Africa/Asmara"],
  "Africa/Bamako": ["Africa/Bamako"],
  "Africa/Bangui": ["Africa/Bangui"],
  "Africa/Banjul": ["Africa/Banjul"],
  "Africa/Bissau": ["Africa/Bissau"],
  "Africa/Blantyre": ["Africa/Blantyre"],
  "Africa/Brazzaville": ["Africa/Brazzaville"],
  "Africa/Bujumbura": ["Africa/Bujumbura"],
  "Africa/Cairo": ["Africa/Cairo"],
  "Africa/Casablanca": ["Africa/Casablanca"],
  "Africa/Conakry": ["Africa/Conakry"],
  "Africa/Dakar": ["Africa/Dakar"],
  "Africa/Dar_es_Salaam": ["Africa/Dar_es_Salaam"],
  "Africa/Djibouti": ["Africa/Djibouti"],
  "Africa/Douala": ["Africa/Douala"],
  "Africa/El_Aaiun": ["Africa/El_Aaiun"],
  "Africa/Freetown": ["Africa/Freetown"],
  "Africa/Gaborone": ["Africa/Gaborone"],
  "Africa/Harare": ["Africa/Harare"],
  "Africa/Johannesburg": ["Africa/Johannesburg"],
  "Africa/Juba": ["Africa/Juba"],
  "Africa/Kampala": ["Africa/Kampala"],
  "Africa/Khartoum": ["Africa/Khartoum"],
  "Africa/Kigali": ["Africa/Kigali"],
  "Africa/Kinshasa": ["Africa/Kinshasa"],
  "Africa/Lagos": ["Africa/Lagos"],
  "Africa/Libreville": ["Africa/Libreville"],
  "Africa/Lome": ["Africa/Lome"],
  "Africa/Luanda": ["Africa/Luanda"],
  "Africa/Lubumbashi": ["Africa/Lubumbashi"],
  "Africa/Lusaka": ["Africa/Lusaka"],
  "Africa/Malabo": ["Africa/Malabo"],
  "Africa/Maputo": ["Africa/Maputo"],
  "Africa/Maseru": ["Africa/Maseru"],
  "Africa/Mbabane": ["Africa/Mbabane"],
  "Africa/Mogadishu": ["Africa/Mogadishu"],
  "Africa/Monrovia": ["Africa/Monrovia"],
  "Africa/Nairobi": ["Africa/Nairobi"],
  "Africa/Ndjamena": ["Africa/Ndjamena"],
  "Africa/Niamey": ["Africa/Niamey"],
  "Africa/Nouakchott": ["Africa/Nouakchott"],
  "Africa/Ouagadougou": ["Africa/Ouagadougou"],
  "Africa/Porto-Novo": ["Africa/Porto-Novo"],
  "Africa/Sao_Tome": ["Africa/Sao_Tome"],
  "Africa/Tripoli": ["Africa/Tripoli"],
  "Africa/Tunis": ["Africa/Tunis"],
  "Africa/Windhoek": ["Africa/Windhoek"],
  "America/Adak": ["America/Adak"],
  "America/Anchorage": ["America/Anchorage", "America/Juneau", "America/Metlakatla", "America/Nome", "America/Sitka", "America/Yakutat"],
  "America/Anguilla": ["America/Anguilla"],
  "America/Antigua": ["America/Antigua"],
  "America/Argentina/Buenos_Aires": ["America/Argentina/Buenos_Aires", "America/Argentina/Catamarca", "America/Argentina/Cordoba", "America/Argentina/Jujuy", "America/Argentina/La_Rioja", "America/Argentina/Mendoza", "America/Argentina/Rio_Gallegos", "America/Argentina/Salta", "America/Argentina/San_Juan", "America/Argentina/San_Luis", "America/Argentina/Tucuman", "America/Argentina/Ushuaia"],
  "America/Aruba": ["America/Aruba"],
  "America/Asuncion": ["America/Asuncion"],
  "America/Atikokan": ["America/Atikokan"],
  "America/Barbados": ["America/Barbados"],
  "America/Belize": ["America/Belize"],
  "America/Blanc-Sablon": ["America/Blanc-Sablon"],
  "America/Bogota": ["America/Bogota"],
  "America/Cancun": ["America/Cancun"],
  "America/Caracas": ["America/Caracas"],
  "America/Cayenne": ["America/Cayenne"],
  "America/Cayman": ["America/Cayman"],
  "America/Chicago": ["America/Chicago", "America/Indiana/Knox", "America/Indiana/Tell_City", "America/Menominee", "America/North_Dakota/Beulah", "America/North_Dakota/Center", "America/North_Dakota/New_Salem"],
  "America/Costa_Rica": ["America/Costa_Rica"],
  "America/Curacao": ["America/Curacao"],
  "America/Danmarkshavn": ["America/Danmarkshavn"],
  "America/Denver": ["America/Boise", "America/Denver"],
  "America/Dominica": ["America/Dominica"],
  "America/Edmonton": ["America/Cambridge_Bay", "America/Edmonton", "America/Inuvik", "America/Yellowknife"],
  "America/El_Salvador": ["America/El_Salvador"],
  "America/Godthab": ["America/Godthab"],
  "America/Grand_Turk": ["America/Grand_Turk"],
  "America/Grenada": ["America/Grenada"],
  "America/Guadeloupe": ["America/Guadeloupe"],
  "America/Guatemala": ["America/Guatemala"],
  "America/Guayaquil": ["America/Guayaquil"],
  "America/Guyana": ["America/Guyana"],
  "America/Halifax": ["America/Glace_Bay", "America/Goose_Bay", "America/Halifax", "America/Moncton"],
  "America/Havana": ["America/Havana"],
  "America/Hermosillo": ["America/Hermosillo"],
  "America/Jamaica": ["America/Jamaica"],
  "America/Kralendijk": ["America/Kralendijk"],
  "America/La_Paz": ["America/La_Paz"],
  "America/Lima": ["America/Lima"],
  "America/Los_Angeles": ["America/Los_Angeles"],
  "America/Lower_Princes": ["America/Lower_Princes"],
  "America/Managua": ["America/Managua"],
  "America/Manaus": ["America/Boa_Vista", "America/Campo_Grande", "America/Cuiaba", "America/Manaus", "America/Porto_Velho"],
  "America/Marigot": ["America/Marigot"],
  "America/Martinique": ["America/Martinique"],
  "America/Mexico_City": ["America/Bahia_Banderas", "America/Matamoros", "America/Merida", "America/Mexico_City", "America/Monterrey"],
  "America/Miquelon": ["America/Miquelon"],
  "America/Montevideo": ["America/Montevideo"],
  "America/Montserrat": ["America/Montserrat"],
  "America/Nassau": ["America/Nassau"],
  "America/New_York": ["America/Detroit", "America/Indiana/Indianapolis", "America/Indiana/Marengo", "America/Indiana/Petersburg", "America/Indiana/Vevay", "America/Indiana/Vincennes", "America/Indiana/Winamac", "America/Kentucky/Louisville", "America/Kentucky/Monticello", "America/New_York"],
  "America/Noronha": ["America/Noronha"],
  "America/Ojinaga": ["America/Chihuahua", "America/Mazatlan", "America/Ojinaga"],
  "America/Panama": ["America/Panama"],
  "America/Paramaribo": ["America/Paramaribo"],
  "America/Phoenix": ["America/Phoenix"],
  "America/Port-au-Prince": ["America/Port-au-Prince"],
  "America/Port_of_Spain": ["America/Port_of_Spain"],
  "America/Puerto_Rico": ["America/Puerto_Rico"],
  "America/Punta_Arenas": ["America/Punta_Arenas"],
  "America/Regina": ["America/Regina", "America/Swift_Current"],
  "America/Rio_Branco": ["America/Eirunepe", "America/Rio_Branco"],
  "America/Santiago": ["America/Santiago"],
  "America/Santo_Domingo": ["America/Santo_Domingo"],
  "America/Sao_Paulo": ["America/Araguaina", "America/Bahia", "America/Belem", "America/Fortaleza", "America/Maceio", "America/Recife", "America/Santarem", "America/Sao_Paulo"],
  "America/Scoresbysund": ["America/Scoresbysund"],
  "America/St_Barthelemy": ["America/St_Barthelemy"],
  "America/St_Johns": ["America/St_Johns"],
  "America/St_Kitts": ["America/St_Kitts"],
  "America/St_Lucia": ["America/St_Lucia"],
  "America/St_Thomas": ["America/St_Thomas"],
  "America/St_Vincent": ["America/St_Vincent"],
  "America/Tegucigalpa": ["America/Tegucigalpa"],
  "America/Thule": ["America/Thule"],
  "America/Tijuana": ["America/Tijuana"],
  "America/Toronto": ["America/Iqaluit", "America/Nipigon", "America/Pangnirtung", "America/Thunder_Bay", "America/Toronto"],
  "America/Tortola": ["America/Tortola"],
  "America/Vancouver": ["America/Vancouver"],
  "America/Whitehorse": ["America/Creston", "America/Dawson", "America/Dawson_Creek", "America/Fort_Nelson", "America/Whitehorse"],
  "America/Winnipeg": ["America/Rainy_River", "America/Rankin_Inlet", "America/Resolute", "America/Winnipeg"],
  "Antarctica/Casey": ["Antarctica/Casey"],
  "Antarctica/Davis": ["Antarctica/Davis"],
  "Antarctica/DumontDUrville": ["Antarctica/DumontDUrville"],
  "Antarctica/Mawson": ["Antarctica/Mawson"],
  "Antarctica/McMurdo": ["Antarctica/McMurdo"],
  "Antarctica/Palmer": ["Antarctica/Palmer", "Antarctica/Rothera"],
  "Antarctica/Syowa": ["Antarctica/Syowa"],
  "Antarctica/Troll": ["Antarctica/Troll"],
  "Antarctica/Vostok": ["Antarctica/Vostok"],
  "Arctic/Longyearbyen": ["Arctic/Longyearbyen"],
  "Asia/Aden": ["Asia/Aden"],
  "Asia/Almaty": ["Asia/Almaty", "Asia/Qostanay"],
  "Asia/Amman": ["Asia/Amman"],
  "Asia/Ashgabat": ["Asia/Ashgabat"],
  "Asia/Baghdad": ["Asia/Baghdad"],
  "Asia/Bahrain": ["Asia/Bahrain"],
  "Asia/Baku": ["Asia/Baku"],
  "Asia/Bangkok": ["Asia/Bangkok"],
  "Asia/Beirut": ["Asia/Beirut"],
  "Asia/Bishkek": ["Asia/Bishkek"],
  "Asia/Brunei": ["Asia/Brunei"],
  "Asia/Chita": ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
  "Asia/Colombo": ["Asia/Colombo"],
  "Asia/Damascus": ["Asia/Damascus"],
  "Asia/Dhaka": ["Asia/Dhaka"],
  "Asia/Dili": ["Asia/Dili"],
  "Asia/Dubai": ["Asia/Dubai"],
  "Asia/Dushanbe": ["Asia/Dushanbe"],
  "Asia/Hebron": ["Asia/Gaza", "Asia/Hebron"],
  "Asia/Ho_Chi_Minh": ["Asia/Ho_Chi_Minh"],
  "Asia/Hong_Kong": ["Asia/Hong_Kong"],
  "Asia/Hovd": ["Asia/Hovd"],
  "Asia/Irkutsk": ["Asia/Irkutsk"],
  "Asia/Jakarta": ["Asia/Jakarta", "Asia/Pontianak"],
  "Asia/Jayapura": ["Asia/Jayapura"],
  "Asia/Jerusalem": ["Asia/Jerusalem"],
  "Asia/Kabul": ["Asia/Kabul"],
  "Asia/Kamchatka": ["Asia/Anadyr", "Asia/Kamchatka"],
  "Asia/Karachi": ["Asia/Karachi"],
  "Asia/Kathmandu": ["Asia/Kathmandu"],
  "Asia/Kolkata": ["Asia/Kolkata"],
  "Asia/Kuala_Lumpur": ["Asia/Kuala_Lumpur", "Asia/Kuching"],
  "Asia/Kuwait": ["Asia/Kuwait"],
  "Asia/Macau": ["Asia/Macau"],
  "Asia/Makassar": ["Asia/Makassar"],
  "Asia/Manila": ["Asia/Manila"],
  "Asia/Muscat": ["Asia/Muscat"],
  "Asia/Nicosia": ["Asia/Famagusta", "Asia/Nicosia"],
  "Asia/Novosibirsk": ["Asia/Barnaul", "Asia/Krasnoyarsk", "Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Tomsk"],
  "Asia/Omsk": ["Asia/Omsk"],
  "Asia/Phnom_Penh": ["Asia/Phnom_Penh"],
  "Asia/Pyongyang": ["Asia/Pyongyang"],
  "Asia/Qatar": ["Asia/Qatar"],
  "Asia/Qyzylorda": ["Asia/Aqtau", "Asia/Aqtobe", "Asia/Atyrau", "Asia/Oral", "Asia/Qyzylorda"],
  "Asia/Riyadh": ["Asia/Riyadh"],
  "Asia/Sakhalin": ["Asia/Magadan", "Asia/Sakhalin", "Asia/Srednekolymsk"],
  "Asia/Seoul": ["Asia/Seoul"],
  "Asia/Shanghai": ["Asia/Shanghai"],
  "Asia/Singapore": ["Asia/Singapore"],
  "Asia/Taipei": ["Asia/Taipei"],
  "Asia/Tashkent": ["Asia/Samarkand", "Asia/Tashkent"],
  "Asia/Tbilisi": ["Asia/Tbilisi"],
  "Asia/Tehran": ["Asia/Tehran"],
  "Asia/Thimphu": ["Asia/Thimphu"],
  "Asia/Tokyo": ["Asia/Tokyo"],
  "Asia/Ulaanbaatar": ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
  "Asia/Urumqi": ["Asia/Urumqi"],
  "Asia/Vientiane": ["Asia/Vientiane"],
  "Asia/Vladivostok": ["Asia/Ust-Nera", "Asia/Vladivostok"],
  "Asia/Yangon": ["Asia/Yangon"],
  "Asia/Yekaterinburg": ["Asia/Yekaterinburg"],
  "Asia/Yerevan": ["Asia/Yerevan"],
  "Atlantic/Azores": ["Atlantic/Azores"],
  "Atlantic/Bermuda": ["Atlantic/Bermuda"],
  "Atlantic/Canary": ["Atlantic/Canary"],
  "Atlantic/Cape_Verde": ["Atlantic/Cape_Verde"],
  "Atlantic/Faroe": ["Atlantic/Faroe"],
  "Atlantic/Reykjavik": ["Atlantic/Reykjavik"],
  "Atlantic/South_Georgia": ["Atlantic/South_Georgia"],
  "Atlantic/St_Helena": ["Atlantic/St_Helena"],
  "Atlantic/Stanley": ["Atlantic/Stanley"],
  "Australia/Adelaide": ["Australia/Adelaide", "Australia/Broken_Hill"],
  "Australia/Brisbane": ["Australia/Brisbane", "Australia/Lindeman"],
  "Australia/Darwin": ["Australia/Darwin"],
  "Australia/Eucla": ["Australia/Eucla"],
  "Australia/Lord_Howe": ["Australia/Lord_Howe"],
  "Australia/Perth": ["Australia/Perth"],
  "Australia/Sydney": ["Antarctica/Macquarie", "Australia/Currie", "Australia/Hobart", "Australia/Melbourne", "Australia/Sydney"],
  "Europe/Amsterdam": ["Europe/Amsterdam"],
  "Europe/Andorra": ["Europe/Andorra"],
  "Europe/Athens": ["Europe/Athens"],
  "Europe/Belgrade": ["Europe/Belgrade"],
  "Europe/Berlin": ["Europe/Berlin", "Europe/Busingen"],
  "Europe/Bratislava": ["Europe/Bratislava"],
  "Europe/Brussels": ["Europe/Brussels"],
  "Europe/Bucharest": ["Europe/Bucharest"],
  "Europe/Budapest": ["Europe/Budapest"],
  "Europe/Chisinau": ["Europe/Chisinau"],
  "Europe/Copenhagen": ["Europe/Copenhagen"],
  "Europe/Dublin": ["Europe/Dublin"],
  "Europe/Gibraltar": ["Europe/Gibraltar"],
  "Europe/Guernsey": ["Europe/Guernsey"],
  "Europe/Helsinki": ["Europe/Helsinki"],
  "Europe/Isle_of_Man": ["Europe/Isle_of_Man"],
  "Europe/Istanbul": ["Europe/Istanbul"],
  "Europe/Jersey": ["Europe/Jersey"],
  "Europe/Kaliningrad": ["Europe/Kaliningrad"],
  "Europe/Kiev": ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
  "Europe/Lisbon": ["Atlantic/Madeira", "Europe/Lisbon"],
  "Europe/Ljubljana": ["Europe/Ljubljana"],
  "Europe/London": ["Europe/London"],
  "Europe/Luxembourg": ["Europe/Luxembourg"],
  "Europe/Madrid": ["Africa/Ceuta", "Europe/Madrid"],
  "Europe/Malta": ["Europe/Malta"],
  "Europe/Mariehamn": ["Europe/Mariehamn"],
  "Europe/Minsk": ["Europe/Minsk"],
  "Europe/Monaco": ["Europe/Monaco"],
  "Europe/Moscow": ["Europe/Kirov", "Europe/Moscow", "Europe/Simferopol", "Europe/Volgograd"],
  "Europe/Oslo": ["Europe/Oslo"],
  "Europe/Paris": ["Europe/Paris"],
  "Europe/Podgorica": ["Europe/Podgorica"],
  "Europe/Prague": ["Europe/Prague"],
  "Europe/Riga": ["Europe/Riga"],
  "Europe/Rome": ["Europe/Rome"],
  "Europe/Samara": ["Europe/Astrakhan", "Europe/Samara", "Europe/Saratov", "Europe/Ulyanovsk"],
  "Europe/San_Marino": ["Europe/San_Marino"],
  "Europe/Sarajevo": ["Europe/Sarajevo"],
  "Europe/Skopje": ["Europe/Skopje"],
  "Europe/Sofia": ["Europe/Sofia"],
  "Europe/Stockholm": ["Europe/Stockholm"],
  "Europe/Tallinn": ["Europe/Tallinn"],
  "Europe/Tirane": ["Europe/Tirane"],
  "Europe/Vaduz": ["Europe/Vaduz"],
  "Europe/Vatican": ["Europe/Vatican"],
  "Europe/Vienna": ["Europe/Vienna"],
  "Europe/Vilnius": ["Europe/Vilnius"],
  "Europe/Warsaw": ["Europe/Warsaw"],
  "Europe/Zagreb": ["Europe/Zagreb"],
  "Europe/Zurich": ["Europe/Zurich"],
  "Indian/Antananarivo": ["Indian/Antananarivo"],
  "Indian/Chagos": ["Indian/Chagos"],
  "Indian/Christmas": ["Indian/Christmas"],
  "Indian/Cocos": ["Indian/Cocos"],
  "Indian/Comoro": ["Indian/Comoro"],
  "Indian/Kerguelen": ["Indian/Kerguelen"],
  "Indian/Mahe": ["Indian/Mahe"],
  "Indian/Maldives": ["Indian/Maldives"],
  "Indian/Mauritius": ["Indian/Mauritius"],
  "Indian/Mayotte": ["Indian/Mayotte"],
  "Indian/Reunion": ["Indian/Reunion"],
  "Pacific/Apia": ["Pacific/Apia"],
  "Pacific/Auckland": ["Pacific/Auckland"],
  "Pacific/Bougainville": ["Pacific/Bougainville"],
  "Pacific/Chatham": ["Pacific/Chatham"],
  "Pacific/Chuuk": ["Pacific/Chuuk"],
  "Pacific/Easter": ["Pacific/Easter"],
  "Pacific/Efate": ["Pacific/Efate"],
  "Pacific/Enderbury": ["Pacific/Enderbury"],
  "Pacific/Fakaofo": ["Pacific/Fakaofo"],
  "Pacific/Fiji": ["Pacific/Fiji"],
  "Pacific/Funafuti": ["Pacific/Funafuti"],
  "Pacific/Galapagos": ["Pacific/Galapagos"],
  "Pacific/Gambier": ["Pacific/Gambier"],
  "Pacific/Guadalcanal": ["Pacific/Guadalcanal"],
  "Pacific/Guam": ["Pacific/Guam"],
  "Pacific/Honolulu": ["Pacific/Honolulu"],
  "Pacific/Kiritimati": ["Pacific/Kiritimati"],
  "Pacific/Kosrae": ["Pacific/Kosrae", "Pacific/Pohnpei"],
  "Pacific/Majuro": ["Pacific/Kwajalein", "Pacific/Majuro"],
  "Pacific/Marquesas": ["Pacific/Marquesas"],
  "Pacific/Midway": ["Pacific/Midway"],
  "Pacific/Nauru": ["Pacific/Nauru"],
  "Pacific/Niue": ["Pacific/Niue"],
  "Pacific/Norfolk": ["Pacific/Norfolk"],
  "Pacific/Noumea": ["Pacific/Noumea"],
  "Pacific/Pago_Pago": ["Pacific/Pago_Pago"],
  "Pacific/Palau": ["Pacific/Palau"],
  "Pacific/Pitcairn": ["Pacific/Pitcairn"],
  "Pacific/Port_Moresby": ["Pacific/Port_Moresby"],
  "Pacific/Rarotonga": ["Pacific/Rarotonga"],
  "Pacific/Saipan": ["Pacific/Saipan"],
  "Pacific/Tahiti": ["Pacific/Tahiti"],
  "Pacific/Tarawa": ["Pacific/Tarawa"],
  "Pacific/Tongatapu": ["Pacific/Tongatapu"],
  "Pacific/Wake": ["Pacific/Wake"],
  "Pacific/Wallis": ["Pacific/Wallis"]
};