import { defineMessages } from "react-intl";
export var messages = defineMessages({
  success: {
    id: "jutro-components.widgets.InlineNotification.success",
    defaultMessage: "Success:"
  },
  info: {
    id: "jutro-components.widgets.InlineNotification.info",
    defaultMessage: "Info:"
  },
  warning: {
    id: "jutro-components.widgets.InlineNotification.warning",
    defaultMessage: "Warning:"
  },
  error: {
    id: "jutro-components.widgets.InlineNotification.error",
    defaultMessage: "Error:"
  },
  dismiss: {
    id: "jutro-components.widgets.InlineNotification.dismiss",
    defaultMessage: "Dismiss"
  },
  messagesCountError: {
    id: "jutro-components.widgets.InlineNotification.Errors found",
    defaultMessage: "Error notifications found: {count}"
  },
  messagesCountWarning: {
    id: "jutro-components.widgets.InlineNotification.Warnings found",
    defaultMessage: "Warning notifications found: {count}"
  },
  messagesCountInfo: {
    id: "jutro-components.widgets.InlineNotification.Info found",
    defaultMessage: "Informational notifications found: {count}"
  },
  messagesCountSuccess: {
    id: "jutro-components.widgets.InlineNotification.Success found",
    defaultMessage: "Successful notifications found: {count}"
  },
  all: {
    id: "jutro-components.widgets.InlineNotification.all",
    defaultMessage: "All"
  },
  allAriaLabel: {
    id: "jutro-components.widgets.InlineNotification.ariaLabel.all",
    defaultMessage: "All notifications"
  },
  notificationSeverity: {
    id: "jutro-components.widgets.InlineNotification.ariaLabel.severity",
    defaultMessage: "Notification severity"
  }
});