import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DATA_TYPE_STRING, DATA_TYPE_OBJECT } from "@jutro/prop-types";
import { formatPhoneNumberAndCountryCode, CountryContext } from "@jutro/locale";
var phoneDataTypeShape = PropTypes.oneOf([DATA_TYPE_STRING, DATA_TYPE_OBJECT]);
export var formattedPhoneNumberPropTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    countryCode: PropTypes.shape({
      code: PropTypes.string
    }),
    phoneNumber: PropTypes.string
  })]),
  showCountryCodeForReadOnly: PropTypes.bool,
  dataType: phoneDataTypeShape,
  defaultCountry: PropTypes.string
};
export var FormattedPhoneNumber = function (props) {
  var _context,
    resolvedValue,
    _value$countryCode,
    defaultCountryCode = useContext(CountryContext).defaultCountryCode,
    value = props.value,
    showCountryCodeForReadOnly = props.showCountryCodeForReadOnly,
    dataType = props.dataType,
    initialCountry = props.defaultCountry || defaultCountryCode;
  dataType === DATA_TYPE_OBJECT && null != value && value.phoneNumber && (resolvedValue = formatPhoneNumberAndCountryCode(value.phoneNumber, (null == value || null === (_value$countryCode = value.countryCode) || void 0 === _value$countryCode ? void 0 : _value$countryCode.code) || initialCountry));
  dataType === DATA_TYPE_STRING && value && (resolvedValue = formatPhoneNumberAndCountryCode(value, initialCountry));
  var _ref2 = _slicedToArray(resolvedValue || [], 2),
    countryCode = _ref2[0],
    phoneNumber = _ref2[1],
    readOnlyPhoneNumber = showCountryCodeForReadOnly ? _concatInstanceProperty(_context = "+".concat(countryCode, " ")).call(_context, phoneNumber) : phoneNumber;
  return React.createElement(React.Fragment, null, phoneNumber ? readOnlyPhoneNumber : "-");
};
FormattedPhoneNumber.defaultProps = {
  dataType: DATA_TYPE_OBJECT,
  showCountryCodeForReadOnly: !0
}, FormattedPhoneNumber.propTypes = formattedPhoneNumberPropTypes, FormattedPhoneNumber.__docgenInfo = {
  description: "The `FormattedPhoneNumber` component is used to render international phone number value.\n\n@type {React.FC<PropTypes.InferProps<typeof formattedPhoneNumberPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FormattedPhoneNumber",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "phoneDataTypeShape"
      },
      required: !1,
      description: "Type of returned value"
    },
    showCountryCodeForReadOnly: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sets country code visibility if rendered in readOnly mode"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            countryCode: {
              name: "shape",
              value: {
                code: {
                  name: "string",
                  required: !1
                }
              },
              required: !1
            },
            phoneNumber: {
              name: "string",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Value to display"
    },
    defaultCountry: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default country code to render, must be an iso2 country code string"
    }
  }
}, FormattedPhoneNumber.__docgenInfo = {
  componentName: "FormattedPhoneNumber",
  packageName: "@jutro/components",
  description: "The `FormattedPhoneNumber` component is used to render international phone number value.",
  displayName: "FormattedPhoneNumber",
  methods: [],
  actualName: "FormattedPhoneNumber",
  metadataType: "element",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            countryCode: {
              name: "shape",
              value: {
                code: {
                  name: "string",
                  required: !1
                }
              },
              required: !1
            },
            phoneNumber: {
              name: "string",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Value to display"
    },
    showCountryCodeForReadOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sets country code visibility if rendered in readOnly mode",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    dataType: {
      type: {
        name: "custom",
        raw: "phoneDataTypeShape"
      },
      required: !1,
      description: "Type of returned value",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    },
    defaultCountry: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default country code to render, must be an iso2 country code string"
    }
  }
};