import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["allCollapsed"];
import React from "react";
import { useTranslator } from "@jutro/locale";
import { Button } from "../button/Button";
import { messages } from "./TreeView.messages";
import styles from "./TreeNodeCollapseAll.module.css";
export var TreeViewCollapseAll = function (_ref) {
  var allCollapsed = _ref.allCollapsed,
    props = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator();
  return React.createElement(Button, _extends({
    className: styles.button,
    type: "text"
  }, props), translator(allCollapsed ? messages.expandAll : messages.collapseAll));
};
TreeViewCollapseAll.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TreeViewCollapseAll"
}, TreeViewCollapseAll.__docgenInfo = {
  componentName: "TreeViewCollapseAll",
  packageName: "@jutro/components",
  description: "",
  displayName: "TreeViewCollapseAll",
  methods: [],
  actualName: "TreeViewCollapseAll"
};