import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["tablet", "phone", "phoneWide"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import units from "units-css";
var identity = function (x) {
  return x;
};
export var breakpointExtensions = {
  phone: function (props) {
    return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, props), props.tablet), props.phoneWide), props.phone);
  },
  phoneWide: function (props) {
    return _objectSpread(_objectSpread(_objectSpread({}, props), props.tablet), props.phoneWide);
  },
  tablet: function (props) {
    return _objectSpread(_objectSpread({}, props), props.tablet);
  },
  desktop: identity
};
export var extendWithBreakpointValues = function (props, breakpoint) {
  var _ref,
    breakpointProps = (breakpointExtensions[breakpoint] || identity)(props);
  return (_ref = breakpointProps).tablet, _ref.phone, _ref.phoneWide, _objectWithoutProperties(_ref, _excluded);
};
export var getBreakpointPxValue = function (device, rootElement) {
  var element = function (rootElement) {
      var _document,
        _root$querySelector,
        root = rootElement || (null === (_document = document) || void 0 === _document ? void 0 : _document.body);
      return null !== (_root$querySelector = root.querySelector(".themeRoot")) && void 0 !== _root$querySelector ? _root$querySelector : root;
    }(rootElement),
    breakpointValue = "function" == typeof getComputedStyle && getComputedStyle(element).getPropertyValue("--GW-BREAKPOINT-".concat(device.toUpperCase()));
  return breakpointValue && units.convert("px", breakpointValue, element);
};
export var getBreakpoints = function (rootElement) {
  return {
    phone: getBreakpointPxValue("phone", rootElement) || 580,
    phoneWide: getBreakpointPxValue("phoneWide", rootElement) || 768,
    tablet: getBreakpointPxValue("tablet", rootElement) || 1024
  };
};
export var breakpoints = ["phone", "phoneWide", "tablet"];