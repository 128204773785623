import _URLSearchParams from "@babel/runtime-corejs3/core-js-stable/url-search-params";
import { getConfigValue } from "@jutro/config";
var shouldDisplayStringIdCache;
export function shouldDisplayStringId() {
  var _window;
  void 0 === shouldDisplayStringIdCache && (shouldDisplayStringIdCache = new _URLSearchParams(null === (_window = window) || void 0 === _window ? void 0 : _window.location.search).has("displayStringId") || getConfigValue("DEV_DISPLAY_STRING_ID", !1));
  return shouldDisplayStringIdCache;
}
export function stringIdTranslator(key) {
  return "string" != typeof key ? key.id : key;
}