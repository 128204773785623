import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocaleContext, useTranslator, useLanguage } from "@jutro/locale";
import { Grid } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./GlobalizationChooser.module.css";
import { messages } from "./GlobalizationChooser.messages.js";
import { useI18nDropdown, getAvailableLanguages } from "./useI18nDropdown";
import { DropdownSelectField } from "../inputs/DropdownSelectField/DropdownSelectField";
var defaultLocaleLabel = messages.localeLabel,
  defaultLanguageLabel = messages.languageLabel,
  globalizationChooserPropTypes = {
    className: PropTypes.string,
    containerStyle: PropTypes.string,
    localeId: PropTypes.string,
    languageId: PropTypes.string,
    localeValue: PropTypes.string,
    languageValue: PropTypes.string,
    languageLabelText: intlMessageShape,
    localeLabelText: intlMessageShape,
    availableLanguageValues: PropTypes.arrayOf(PropTypes.string),
    availableLocaleValues: PropTypes.arrayOf(PropTypes.string),
    onLocaleValueChange: PropTypes.func,
    onLanguageValueChange: PropTypes.func,
    renderLocaleLabel: PropTypes.func,
    renderLanguageLabel: PropTypes.func,
    showLocaleLabel: PropTypes.bool,
    showLanguageLabel: PropTypes.bool,
    showLocaleSelect: PropTypes.bool,
    showLanguageSelect: PropTypes.bool,
    readOnly: PropTypes.bool,
    skipPropagation: PropTypes.bool
  };
export var GlobalizationChooser = function (_ref) {
  var onLocaleValueChange = _ref.onLocaleValueChange,
    onLanguageValueChange = _ref.onLanguageValueChange,
    localeId = _ref.localeId,
    languageId = _ref.languageId,
    localeLabelText = _ref.localeLabelText,
    languageLabelText = _ref.languageLabelText,
    localeValueProp = _ref.localeValue,
    languageValueProp = _ref.languageValue,
    availableLanguageValues = _ref.availableLanguageValues,
    availableLocaleValues = _ref.availableLocaleValues,
    renderLocaleLabel = _ref.renderLocaleLabel,
    renderLanguageLabel = _ref.renderLanguageLabel,
    showLocaleLabel = _ref.showLocaleLabel,
    showLanguageLabel = _ref.showLanguageLabel,
    showLocaleSelect = _ref.showLocaleSelect,
    showLanguageSelect = _ref.showLanguageSelect,
    className = _ref.className,
    containerStyle = _ref.containerStyle,
    readOnly = _ref.readOnly,
    skipPropagation = _ref.skipPropagation,
    translator = useTranslator(),
    _useLanguage = useLanguage(),
    language = _useLanguage.language,
    availableLanguages = _useLanguage.availableLanguages,
    languageOnChangeCallback = _useLanguage.languageOnChangeCallback,
    _useContext = useContext(LocaleContext),
    locale = _useContext.locale,
    availableLocales = _useContext.availableLocales,
    localeOnChangeCallback = _useContext.localeOnChangeCallback,
    languageDropdownProps = useI18nDropdown({
      value: languageValueProp || language,
      availableValues: getAvailableLanguages(availableLanguageValues, availableLanguages),
      renderValueLabel: renderLanguageLabel,
      skipPropagation: skipPropagation,
      onValueChange: onLanguageValueChange,
      serviceCallback: languageOnChangeCallback
    }),
    localeDropdownProps = useI18nDropdown({
      value: localeValueProp || locale,
      availableValues: availableLocaleValues || availableLocales,
      renderValueLabel: renderLocaleLabel,
      skipPropagation: skipPropagation,
      onValueChange: onLocaleValueChange,
      serviceCallback: localeOnChangeCallback
    }),
    localeLabel = translator(localeLabelText),
    languageLabel = translator(languageLabelText),
    languageDropdown = showLanguageSelect ? React.createElement(DropdownSelectField, _extends({}, languageDropdownProps, {
      label: languageLabel,
      hideLabel: !showLanguageLabel,
      className: className,
      controlClassName: styles.globalizationChooser,
      id: languageId,
      readOnly: readOnly
    })) : null,
    localeDropdown = showLocaleSelect ? React.createElement(DropdownSelectField, _extends({}, localeDropdownProps, {
      label: localeLabel,
      hideLabel: !showLocaleLabel,
      className: className,
      controlClassName: styles.globalizationChooser,
      id: localeId,
      readOnly: readOnly
    })) : null,
    dropdownCount = languageDropdown && localeDropdown ? 2 : 1;
  return React.createElement(Grid, {
    gap: "large",
    className: containerStyle,
    columns: ["1fr"],
    repeat: dropdownCount
  }, languageDropdown, localeDropdown);
};
GlobalizationChooser.propTypes = globalizationChooserPropTypes, GlobalizationChooser.defaultProps = {
  showLocaleSelect: !0,
  showLanguageSelect: !0,
  languageId: "languageSelect",
  localeId: "localeSelect",
  localeLabelText: defaultLocaleLabel,
  languageLabelText: defaultLanguageLabel,
  showLocaleLabel: !0,
  showLanguageLabel: !0,
  readOnly: !1,
  skipPropagation: !1
}, GlobalizationChooser.__docgenInfo = {
  description: "Component that allows the user to select the preferred application language and locale.\n\n@type {React.FC<PropTypes.InferProps<typeof globalizationChooserPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "GlobalizationChooser",
  props: {
    showLocaleSelect: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing/hiding the locale select"
    },
    showLanguageSelect: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing/hiding the language select"
    },
    languageId: {
      defaultValue: {
        value: "'languageSelect'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the language select element"
    },
    localeId: {
      defaultValue: {
        value: "'localeSelect'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the locale select element"
    },
    localeLabelText: {
      defaultValue: {
        value: "messages.localeLabel",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Message key for the locale label"
    },
    languageLabelText: {
      defaultValue: {
        value: "messages.languageLabel",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Message key for the language label"
    },
    showLocaleLabel: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing or hiding the locale label"
    },
    showLanguageLabel: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing or hiding the language label"
    },
    readOnly: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, the drop-down lists are readonly, ignored in storybook mode"
    },
    skipPropagation: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, the config is not updated on value change and GlobalizationChooser becomes a controlled component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    containerStyle: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for component container"
    },
    localeValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Selected locale"
    },
    languageValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Selected language"
    },
    availableLanguageValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Languages available for selection"
    },
    availableLocaleValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Locales available for selection"
    },
    onLocaleValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback invoked on locale change"
    },
    onLanguageValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback invoked on language change"
    },
    renderLocaleLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render prop to display locale in options"
    },
    renderLanguageLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render prop to display language in options"
    }
  }
}, GlobalizationChooser.__docgenInfo = {
  componentName: "GlobalizationChooser",
  packageName: "@jutro/components",
  description: "Component that allows the user to select the preferred application language and locale.",
  displayName: "GlobalizationChooser",
  methods: [],
  actualName: "GlobalizationChooser",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    containerStyle: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for component container"
    },
    localeId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the locale select element",
      defaultValue: {
        value: "'localeSelect'",
        computed: !1
      }
    },
    languageId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the language select element",
      defaultValue: {
        value: "'languageSelect'",
        computed: !1
      }
    },
    localeValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Selected locale"
    },
    languageValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Selected language"
    },
    languageLabelText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Message key for the language label",
      defaultValue: {
        value: "messages.languageLabel",
        computed: !0
      }
    },
    localeLabelText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Message key for the locale label",
      defaultValue: {
        value: "messages.localeLabel",
        computed: !0
      }
    },
    availableLanguageValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Languages available for selection"
    },
    availableLocaleValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Locales available for selection"
    },
    onLocaleValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback invoked on locale change"
    },
    onLanguageValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback invoked on language change"
    },
    renderLocaleLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render prop to display locale in options"
    },
    renderLanguageLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render prop to display language in options"
    },
    showLocaleLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing or hiding the locale label",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    showLanguageLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing or hiding the language label",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    showLocaleSelect: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing/hiding the locale select",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    showLanguageSelect: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for showing/hiding the language select",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, the drop-down lists are readonly, ignored in storybook mode",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    skipPropagation: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, the config is not updated on value change and GlobalizationChooser becomes a controlled component",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  }
};