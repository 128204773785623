import { PhoneNumberUtil } from "google-libphonenumber";
export var getSamplePhoneNumber = function (countryCode, phoneNumberType) {
  var phoneUtils = PhoneNumberUtil.getInstance(),
    phoneNumber = phoneUtils.getExampleNumberForType(countryCode, phoneNumberType);
  return phoneNumber ? phoneUtils.format(phoneNumber) : null;
};
export var getPrefixForCountry = function (countryCode) {
  return PhoneNumberUtil.getInstance().getCountryCodeForRegion(countryCode);
};
export var isValidatePhoneNumber = function (phoneNumber, countryCode) {
  var phoneUtils = PhoneNumberUtil.getInstance();
  return phoneUtils.isValidNumber(phoneUtils.parse(phoneNumber, countryCode));
};