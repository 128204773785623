import { metadataTypes } from "./types";
export var REF_PROPERTY = "@ref";
export var REF_PROPERTY_SHORTCUT = "".concat(REF_PROPERTY, ":");
export var refTypeMap = {
  actions: metadataTypes.ACTION,
  elements: metadataTypes.ELEMENT,
  containers: metadataTypes.CONTAINER,
  fields: metadataTypes.FIELD,
  iterables: metadataTypes.ITERABLE,
  layouts: metadataTypes.LAYOUT,
  component: metadataTypes.COMPONENT
};