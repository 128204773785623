import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["src", "alt", "className", "figcaption", "onError", "onLoad", "showLoader", "dangerouslySetInnerHTML", "containerClassName"];
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { usePrevious } from "@jutro/platform";
import { intlMessageShape } from "@jutro/prop-types";
import { Loader } from "../loading/loader/Loader";
import styles from "./Image.module.css";
var imagePropTypes = {
  src: PropTypes.string.isRequired,
  alt: intlMessageShape,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  figcaption: intlMessageShape,
  width: PropTypes.number,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  showLoader: PropTypes.bool
};
export var Image = function (_ref) {
  var src = _ref.src,
    alt = _ref.alt,
    className = _ref.className,
    figcaption = _ref.figcaption,
    onError = _ref.onError,
    onLoad = _ref.onLoad,
    showLoader = _ref.showLoader,
    containerClassName = (_ref.dangerouslySetInnerHTML, _ref.containerClassName),
    rest = _objectWithoutProperties(_ref, _excluded),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1],
    translator = useTranslator(),
    previousSrc = usePrevious(src);
  useEffect(function () {
    src !== previousSrc && setIsLoading(!0);
  }, [previousSrc, src]);
  var figureClassNames = cx(styles.imageFigure, containerClassName);
  return React.createElement("figure", {
    className: figureClassNames
  }, showLoader && isLoading && React.createElement(Loader, null), React.createElement("img", _extends({
    src: src,
    alt: translator(alt),
    className: showLoader && isLoading ? styles.imageFigureLoading : className,
    onError: function (e) {
      setIsLoading(!1), null == onError || onError(e);
    },
    onLoad: function (e) {
      setIsLoading(!1), null == onLoad || onLoad(e);
    }
  }, rest)), figcaption && React.createElement("figcaption", {
    className: styles.imageFigureCaption
  }, figcaption));
};
Image.propTypes = imagePropTypes, Image.defaultProps = {
  showLoader: !0
}, Image.__docgenInfo = {
  description: "Image\n@type {React.FC<PropTypes.InferProps<typeof imagePropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "Image",
  props: {
    showLoader: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show loader when the Image is still loading",
      tsType: {
        name: "boolean"
      }
    },
    src: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Specifies the URL of the Image"
    },
    alt: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Specifies an alternate text for the Image",
      tsType: {
        name: "IntlMessageShape"
      }
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the containing figure node",
      tsType: {
        name: "string"
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the img node"
    },
    figcaption: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The caption for the figure",
      tsType: {
        name: "IntlMessageShape"
      }
    },
    width: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Specifies the width of the image"
    },
    onError: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Script to be run when an error occurs when the Image is being loaded"
    },
    onLoad: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Script to be run when the Image is loaded successfully"
    }
  }
}, Image.__docgenInfo = {
  componentName: "Image",
  packageName: "@jutro/components",
  description: "Image",
  displayName: "Image",
  methods: [],
  actualName: "Image",
  metadataType: "element",
  props: {
    src: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Specifies the URL of the Image"
    },
    alt: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Specifies an alternate text for the Image"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the containing figure node"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the img node"
    },
    figcaption: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The caption for the figure"
    },
    width: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Specifies the width of the image"
    },
    onError: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Script to be run when an error occurs when the Image is being loaded"
    },
    onLoad: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Script to be run when the Image is loaded successfully"
    },
    showLoader: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show loader when the Image is still loading",
      defaultValue: {
        value: "true",
        computed: !1
      }
    }
  }
};