import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _Array$from2 from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import isPlainObject from "lodash/isPlainObject";
import isDate from "lodash/isDate";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import pick from "lodash/pick";
import { DATA_TYPE_DATE_TIME, DATA_TYPE_OBJECT, dateFormatMap, timeFormat } from "@jutro/prop-types";
import { warning } from "@jutro/logger";
var DATE_FORMAT_STYLES_MAP = {
    y: ["year"],
    M: ["month"],
    d: ["day", "weekday"],
    h: ["hour"],
    m: ["minute"]
  },
  DEFAULT_ISO_STRING = [DATE_FORMAT_STYLES_MAP.y[0], DATE_FORMAT_STYLES_MAP.M[0], DATE_FORMAT_STYLES_MAP.d[0]];
function getDateObject(date, includeTime) {
  var offset = date.getTimezoneOffset();
  return includeTime ? {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth(),
    day: date.getUTCDate(),
    hour: date.getUTCHours(),
    minute: date.getUTCMinutes()
  } : {
    year: offset < 0 ? date.getFullYear() : date.getUTCFullYear(),
    month: offset < 0 ? date.getMonth() : date.getUTCMonth(),
    day: offset < 0 ? date.getDate() : date.getUTCDate()
  };
}
export function formatDateToDataType(date, dataType) {
  var includeTime = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
    _getDateObject = getDateObject(date, includeTime),
    year = _getDateObject.year,
    month = _getDateObject.month,
    day = _getDateObject.day,
    hour = _getDateObject.hour,
    minute = _getDateObject.minute;
  if (dataType === DATA_TYPE_OBJECT) return includeTime ? {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute
  } : {
    year: year,
    month: month,
    day: day
  };
  if (includeTime) return new Date(Date.UTC(year, month, day, hour, minute)).toISOString();
  var dateString = new Date(Date.UTC(year, month, day)).toISOString();
  return dataType === DATA_TYPE_DATE_TIME ? dateString : dateString.substr(0, 10);
}
export function parseDateFromDataType(value) {
  var includeTime = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
    dateValue = Array.isArray(value) ? value[0] : value;
  if (isString(dateValue) || isNumber(dateValue) || isDate(dateValue)) {
    var date = new Date(dateValue);
    return includeTime ? date : new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }
  if (includeTime) {
    var _year = dateValue.year,
      _dateValue$month = dateValue.month,
      _month = void 0 === _dateValue$month ? 5 : _dateValue$month,
      _dateValue$day = dateValue.day,
      _day = void 0 === _dateValue$day ? 15 : _dateValue$day,
      _dateValue$hour = dateValue.hour,
      hour = void 0 === _dateValue$hour ? 0 : _dateValue$hour,
      _dateValue$minute = dateValue.minute,
      minute = void 0 === _dateValue$minute ? 0 : _dateValue$minute;
    return new Date(Date.UTC(_year, _month, _day, hour, minute));
  }
  var year = dateValue.year,
    _dateValue$month2 = dateValue.month,
    month = void 0 === _dateValue$month2 ? 5 : _dateValue$month2,
    _dateValue$day2 = dateValue.day,
    day = void 0 === _dateValue$day2 ? 15 : _dateValue$day2;
  return new Date(year, month, day);
}
export function formatDateToLocalePattern(date, formatDate) {
  var displayFormat = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "long",
    inputFormat = arguments.length > 3 ? arguments[3] : void 0,
    timeZone = arguments.length > 4 ? arguments[4] : void 0;
  if (date) return formatDate || warning("[formatDateToLocalePattern] Second argument is falsy. Will default to creating new Intl.DateTimeFormat instance with default locale. Pass formatDate function from intl object taken from context to take advantage of caching"), formatDate ? formatDate(date, _objectSpread(_objectSpread({}, getDateIntlFormat(displayFormat, inputFormat)), {}, {
    timeZone: timeZone
  })) : new Intl.DateTimeFormat(formatDate, _objectSpread(_objectSpread({}, getDateIntlFormat(displayFormat, inputFormat)), {}, {
    timeZone: timeZone
  })).format(date);
}
export function trimToInputFormat(value, inputFormat) {
  var _context,
    valueParts = inputFormatToValueParts(inputFormat);
  return isPlainObject(value) ? pick(value, valueParts) : isString(value) ? _filterInstanceProperty(_context = value.split("-")).call(_context, function (_, index) {
    return _includesInstanceProperty(valueParts).call(valueParts, DEFAULT_ISO_STRING[index]);
  }).join("-") : value;
}
export function inputFormatToValueParts(inputFormat) {
  if (!inputFormat) return DEFAULT_ISO_STRING;
  for (var valueParts = [], _i = 0, _Array$from = _Array$from2(new _Set(inputFormat)); _i < _Array$from.length; _i++) {
    var _DATE_FORMAT_STYLES_M,
      char = _Array$from[_i],
      _ref = null !== (_DATE_FORMAT_STYLES_M = DATE_FORMAT_STYLES_MAP[char]) && void 0 !== _DATE_FORMAT_STYLES_M ? _DATE_FORMAT_STYLES_M : [],
      valuePart = _slicedToArray(_ref, 1)[0];
    valuePart && valueParts.push(valuePart);
  }
  return valueParts;
}
function getDateIntlFormat(format, inputFormat) {
  var _dateFormatMap$format,
    _inputFormat$match,
    _context2,
    dateFormat = null !== (_dateFormatMap$format = dateFormatMap[format]) && void 0 !== _dateFormatMap$format ? _dateFormatMap$format : dateFormatMap.long;
  if (!inputFormat) return dateFormat;
  var intlFormat = _objectSpread(_objectSpread({}, dateFormat), timeFormat);
  return 2 === (null === (_inputFormat$match = inputFormat.match(/y/g)) || void 0 === _inputFormat$match ? void 0 : _inputFormat$match.length) && (intlFormat.year = "2-digit"), _reduceInstanceProperty(_context2 = _Array$from2(new _Set(inputFormat))).call(_context2, function (acc, char) {
    var formatKeys = DATE_FORMAT_STYLES_MAP[char];
    return null == formatKeys || formatKeys.forEach(function (formatKey) {
      formatKey in intlFormat && (acc[formatKey] = intlFormat[formatKey]);
    }), acc;
  }, {});
}