import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "children", "showOnInit", "flipBehavior"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import Tippy from "@tippyjs/react";
import { followCursor, sticky } from "tippy.js";
import { placementOptions } from "@jutro/prop-types";
import { useId } from "@jutro/platform";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import "tippy.js/dist/tippy.css";
import styles from "./Tooltip.module.css";
var propTypes = {
    id: PropTypes.string,
    followCursor: PropTypes.bool,
    placement: PropTypes.oneOf(placementOptions),
    trigger: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    hideOnClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["toggle"])]),
    animation: PropTypes.string,
    delay: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    sticky: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["reference", "popper"])]),
    flipBehavior: PropTypes.arrayOf(PropTypes.oneOf(["right", "bottom", "top", "left"])),
    showOnInit: PropTypes.bool
  },
  defaultProps = {
    followCursor: !1,
    placement: "right",
    trigger: "mouseenter focus",
    duration: [300, 300],
    hideOnClick: !0,
    animation: "fade",
    delay: [0, 20],
    sticky: !0,
    flipBehavior: ["right", "bottom", "top", "left", "right"],
    showOnInit: !1
  };
export var Tooltip = function (props) {
  var _context,
    _tippyProps$popperOpt,
    _tippyProps$popperOpt2,
    _context2,
    _tippyProps$plugins,
    _defaultProps$props = _objectSpread(_objectSpread({}, defaultProps), props),
    idProp = _defaultProps$props.id,
    children = _defaultProps$props.children,
    showOnInit = _defaultProps$props.showOnInit,
    flipBehavior = _defaultProps$props.flipBehavior,
    tippyProps = _objectWithoutProperties(_defaultProps$props, _excluded),
    translator = (useId({
      default: idProp
    }), useTranslator());
  return tippyProps.content ? (tippyProps.content = translator(tippyProps.content), React.createElement(Tippy, _extends({}, tippyProps, {
    animation: "fade",
    arrow: !0,
    inertia: !1,
    theme: "dark",
    appendTo: "parent"
  }, {
    interactive: !0,
    className: styles.tooltip,
    onMount: function (instance) {
      instance.reference.setAttribute("aria-controls", "tippy-".concat(instance.id)), instance.reference.setAttribute("aria-owns", "tippy-".concat(instance.id)), instance.reference.setAttribute("aria-expanded", "true");
    },
    onHide: function (instance) {
      instance.reference.removeAttribute("aria-controls"), instance.reference.removeAttribute("aria-owns"), instance.reference.setAttribute("aria-expanded", "false");
    },
    aria: {
      content: "describedby"
    },
    showOnCreate: showOnInit,
    popperOptions: _objectSpread(_objectSpread({}, tippyProps.popperOptions), {}, {
      modifiers: _concatInstanceProperty(_context = [{
        name: "flip",
        options: {
          fallbackPlacements: flipBehavior
        }
      }]).call(_context, _toConsumableArray(null !== (_tippyProps$popperOpt = null === (_tippyProps$popperOpt2 = tippyProps.popperOptions) || void 0 === _tippyProps$popperOpt2 ? void 0 : _tippyProps$popperOpt2.modifiers) && void 0 !== _tippyProps$popperOpt ? _tippyProps$popperOpt : []))
    }),
    plugins: _concatInstanceProperty(_context2 = [sticky, followCursor]).call(_context2, _toConsumableArray(null !== (_tippyProps$plugins = tippyProps.plugins) && void 0 !== _tippyProps$plugins ? _tippyProps$plugins : []))
  }), children)) : null;
};
Tooltip.propTypes = propTypes, Tooltip.defaultProps = defaultProps, Tooltip.displayName = "Tooltip", Tooltip.__docgenInfo = {
  description: "Adds a tooltip to the parent component.",
  methods: [],
  displayName: "Tooltip",
  props: {
    followCursor: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the tooltip follows the user's mouse cursor"
    },
    placement: {
      defaultValue: {
        value: "'right'",
        computed: !1
      },
      type: {
        name: "enum",
        computed: !0,
        value: "placementOptions"
      },
      required: !1,
      description: "Positions of the tooltip relative to its reference element"
    },
    trigger: {
      defaultValue: {
        value: "'mouseenter focus'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The events (each separated by a space) which cause the tooltip to appear"
    },
    duration: {
      defaultValue: {
        value: "[300, 300]",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "array"
        }]
      },
      required: !1,
      description: "Duration of the CSS transition animation in ms"
    },
    hideOnClick: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: "'toggle'",
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "If true, the tooltip disappears if a mousedown event is fired outside of it"
    },
    animation: {
      defaultValue: {
        value: "'fade'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The type of the transition animation"
    },
    delay: {
      defaultValue: {
        value: "[0, 20]",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "array"
        }]
      },
      required: !1,
      description: "Delay in ms between the trigger event and the tooltip appearing or disappearing"
    },
    sticky: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: "'reference'",
            computed: !1
          }, {
            value: "'popper'",
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "Ensures the tooltip stays stuck to its reference element if it moves around or changes size"
    },
    flipBehavior: {
      defaultValue: {
        value: "['right', 'bottom', 'top', 'left', 'right']",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "enum",
          value: [{
            value: "'right'",
            computed: !1
          }, {
            value: "'bottom'",
            computed: !1
          }, {
            value: "'top'",
            computed: !1
          }, {
            value: "'left'",
            computed: !1
          }]
        }
      },
      required: !1,
      description: "Determines the order of flipping, i.e., which placements to prefer if a certain placement cannot be used"
    },
    showOnInit: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Displays the tooltip when the page is loaded"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component, referenced to find it in the document tree"
    }
  }
}, Tooltip.__docgenInfo = {
  componentName: "Tooltip",
  packageName: "@jutro/components",
  description: "Adds a tooltip to the parent component.",
  displayName: "Tooltip",
  methods: [],
  actualName: "Tooltip",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component, referenced to find it in the document tree"
    },
    followCursor: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the tooltip follows the user's mouse cursor",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    placement: {
      type: {
        name: "enum",
        computed: !0,
        value: "placementOptions"
      },
      required: !1,
      description: "Positions of the tooltip relative to its reference element",
      defaultValue: {
        value: "'right'",
        computed: !1
      }
    },
    trigger: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The events (each separated by a space) which cause the tooltip to appear",
      defaultValue: {
        value: "'mouseenter focus'",
        computed: !1
      }
    },
    duration: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "array"
        }]
      },
      required: !1,
      description: "Duration of the CSS transition animation in ms",
      defaultValue: {
        value: "[300, 300]",
        computed: !1
      }
    },
    hideOnClick: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: "'toggle'",
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "If true, the tooltip disappears if a mousedown event is fired outside of it",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    animation: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The type of the transition animation",
      defaultValue: {
        value: "'fade'",
        computed: !1
      }
    },
    delay: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "array"
        }]
      },
      required: !1,
      description: "Delay in ms between the trigger event and the tooltip appearing or disappearing",
      defaultValue: {
        value: "[0, 20]",
        computed: !1
      }
    },
    sticky: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: "'reference'",
            computed: !1
          }, {
            value: "'popper'",
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "Ensures the tooltip stays stuck to its reference element if it moves around or changes size",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    flipBehavior: {
      type: {
        name: "arrayOf",
        value: {
          name: "enum",
          value: [{
            value: "'right'",
            computed: !1
          }, {
            value: "'bottom'",
            computed: !1
          }, {
            value: "'top'",
            computed: !1
          }, {
            value: "'left'",
            computed: !1
          }]
        }
      },
      required: !1,
      description: "Determines the order of flipping, i.e., which placements to prefer if a certain placement cannot be used",
      defaultValue: {
        value: "['right', 'bottom', 'top', 'left', 'right']",
        computed: !1
      }
    },
    showOnInit: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Displays the tooltip when the page is loaded",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  }
};