import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _context;
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import events from "events";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import every from "lodash/every";
import values from "lodash/values";
import mapValues from "lodash/mapValues";
import mapKeys from "lodash/mapKeys";
import isUndefined from "lodash/isUndefined";
import isFunction from "lodash/isFunction";
import { log } from "@jutro/logger";
function mapTopics(topics, callback) {
  return isString(topics) ? callback(topics) : isArray(topics) && every(topics, isString) ? _mapInstanceProperty(topics).call(topics, callback) : topics instanceof Object && every(values(topics), isString) ? mapValues(mapKeys(topics, function (val) {
    return val;
  }), callback) : void log.error("'subscribe'/'unsubscribeAll' supports only topics defined as:\n    - single string 'topic'\n    - array of strings ['topic1', 'topic2']\n    - object which values are strings {topic1: 'topic1', topic2: 'topic2'}");
}
export var EventProvider = _createClass(function EventProvider() {
  var _this = this;
  _classCallCheck(this, EventProvider), _defineProperty(this, "publish", function (topic, event) {
    _this.emitter.emit(topic, event);
  }), _defineProperty(this, "subscribe", function (topics, handler) {
    return mapTopics(topics, function (topic) {
      var listener = function (event) {
        return handler(event, topic);
      };
      return _this.emitter.on(topic, listener), function () {
        return {
          topic: topic,
          listener: listener
        };
      };
    });
  }), _defineProperty(this, "unsubscribe", function (handle) {
    if (isFunction(handle)) {
      var _handle = handle(),
        listener = _handle.listener,
        topic = _handle.topic;
      _this.emitter.removeListener(topic, listener);
    } else log.error("'unsubscribe' supports handles returned by 'subscribe' function.");
  }), _defineProperty(this, "unsubscribeAll", function (topics) {
    isUndefined(topics) ? _this.emitter.removeAllListeners() : mapTopics(topics, function (topic) {
      _this.emitter.removeAllListeners(topic);
    });
  }), this.emitter = new events.EventEmitter(), this.emitter.setMaxListeners(1 / 0);
});
var EVENT_PROVIDER_KEY = _Symbol("jutro.eventProvider");
-1 === _indexOfInstanceProperty(_context = _Object$getOwnPropertySymbols(global)).call(_context, EVENT_PROVIDER_KEY) && (global[EVENT_PROVIDER_KEY] = new EventProvider());
var eventProvider = global[EVENT_PROVIDER_KEY],
  subscribe = eventProvider.subscribe;
export { subscribe };
var unsubscribe = eventProvider.unsubscribe;
export { unsubscribe };
var unsubscribeAll = eventProvider.unsubscribeAll;
export { unsubscribeAll };
var publish = eventProvider.publish;
export { publish };