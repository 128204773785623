import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["className", "icon"];
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../../Icon/Icon";
export var LoadingIcon = function (props) {
  var _ref = props,
    className = _ref.className,
    icon = _ref.icon,
    other = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Icon, _extends({
    icon: icon,
    className: cx(className, "linear-spin")
  }, other));
};
var loadingIconPropTypes = {
  className: PropTypes.string,
  icon: PropTypes.string
};
LoadingIcon.propTypes = loadingIconPropTypes, LoadingIcon.defaultProps = {
  icon: "gw-renewals"
}, LoadingIcon.displayName = "LoadingIcon", LoadingIcon.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LoadingIcon",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-renewals'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "icon name to be used"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    }
  }
}, LoadingIcon.__docgenInfo = {
  componentName: "LoadingIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "LoadingIcon",
  methods: [],
  actualName: "LoadingIcon",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "icon name to be used",
      defaultValue: {
        value: "'gw-renewals'",
        computed: !1
      }
    }
  }
};