import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _Object$getPrototypeOf from "@babel/runtime-corejs3/core-js-stable/object/get-prototype-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import { Children, Component, Fragment, isValidElement } from "react";
import { error } from "@jutro/logger";
import isNil from "lodash/isNil";
import { getDisplayName } from "./getDisplayName";
function childIsOfType(child, componentType) {
  var childDisplayName = getDisplayName(child.type),
    componentTypeDisplayName = getDisplayName(componentType);
  if (child.type === componentType || childDisplayName === componentTypeDisplayName) return !0;
  if ("string" != typeof child.type && Object.prototype.isPrototypeOf.call(Component, child.type)) for (var prototype = _Object$getPrototypeOf(child.type); prototype !== Component;) {
    if (prototype === componentType) return !0;
    prototype = _Object$getPrototypeOf(prototype);
  }
  return !1;
}
export var wrapWithIsRequired = function (propType) {
  var wrappedPropType = propType.bind(null);
  return wrappedPropType.isRequired = function (props, propName, componentName) {
    var _context8,
      _context6,
      _context7,
      value = props[propName];
    if (isNil(value)) return new Error(_concatInstanceProperty(_context6 = _concatInstanceProperty(_context7 = "Failed value type: The value `".concat(propName, "` is marked as required in `")).call(_context7, componentName, "`, but its value is `")).call(_context6, value, "`."));
    for (var _len = arguments.length, rest = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) rest[_key - 3] = arguments[_key];
    return propType.apply(void 0, _concatInstanceProperty(_context8 = [props, propName, componentName]).call(_context8, rest));
  }, wrappedPropType;
};
export var ComponentPropTypes = {
  childOfComponentType: function (componentType) {
    var componentDisplayName = getDisplayName(componentType);
    return wrapWithIsRequired(function (props, propName, componentName, location, propFullName) {
      var _context,
        _context2,
        _context3,
        componentNameSafe = componentName || "<<anonymous>>",
        propFullNameSafe = propFullName || propName,
        children = props[propName];
      return "object" === _typeof(children) && childIsOfType(children, Fragment) && (children = children.props[propName]), Children.toArray(children).every(function (child) {
        return childIsOfType(child, componentType);
      }) ? null : new Error(_concatInstanceProperty(_context = _concatInstanceProperty(_context2 = "Invalid ".concat(location, " `")).call(_context2, propFullNameSafe, "` supplied to `")).call(_context, componentNameSafe, "`. ") + _concatInstanceProperty(_context3 = "".concat(componentNameSafe, " accepts only ")).call(_context3, componentDisplayName, " components as children."));
    });
  },
  oneOfChildOfComponentTypes: function (componentTypes) {
    if (!Array.isArray(componentTypes)) throw new TypeError("Expected an array for the 'componentTypes' parameter, got ".concat(_typeof(componentTypes), " instead"));
    var displayNames = _mapInstanceProperty(componentTypes).call(componentTypes, function (componentType) {
      return getDisplayName(componentType);
    });
    return function (props, propName, componentName) {
      var prop = props[propName];
      Children.forEach(prop, function (child) {
        var _context4, _context5;
        child && _findIndexInstanceProperty(componentTypes).call(componentTypes, function (componentType) {
          return childIsOfType(child, componentType);
        }) < 0 && error(_concatInstanceProperty(_context4 = _concatInstanceProperty(_context5 = "".concat(componentName, " child is a '")).call(_context5, getDisplayName(child.type), "', but should be one of: ")).call(_context4, displayNames, "."));
      });
    };
  },
  altTextDefinedWithImage: function () {
    return function (props, propName) {
      if (props.image && !props.title && !props[propName]) return new Error("imageAltText or title must be defined with image");
    };
  },
  all: function () {
    for (var _len2 = arguments.length, propTypes = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) propTypes[_key2] = arguments[_key2];
    return wrapWithIsRequired(function () {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) args[_key3] = arguments[_key3];
      var foundError = null;
      return propTypes.every(function (propType) {
        return !(foundError = propType.apply(void 0, args));
      }), foundError;
    });
  },
  enabledOn: function (propType, anotherProp, anotherPropValue) {
    return wrapWithIsRequired(function (props) {
      for (var _context9, _len4 = arguments.length, rest = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) rest[_key4 - 1] = arguments[_key4];
      return props[anotherProp] === anotherPropValue ? propType.apply(void 0, _concatInstanceProperty(_context9 = [props]).call(_context9, rest)) : null;
    });
  },
  withLength: function (expectedLengthExpression) {
    return wrapWithIsRequired(function (props, propName, componentName) {
      var _context11,
        _context12,
        _context13,
        _context10,
        value = props[propName];
      if (isNil(value)) return null;
      if (void 0 === value.length) return new Error(_concatInstanceProperty(_context10 = "Invalid prop '".concat(propName, "' with no length field supplied to '")).call(_context10, componentName, "', expected value with length field"));
      var length = value.length,
        expectedLength = "number" == typeof expectedLengthExpression ? expectedLengthExpression : expectedLengthExpression(props);
      return length === expectedLength ? null : new Error(_concatInstanceProperty(_context11 = _concatInstanceProperty(_context12 = _concatInstanceProperty(_context13 = "Invalid prop '".concat(propName, "' with length '")).call(_context13, length, "' supplied to '")).call(_context12, componentName, "', expected value with length '")).call(_context11, expectedLength, "'"));
    });
  },
  elementsWithId: wrapWithIsRequired(function (props, propName, componentName, location, propFullName) {
    var _context14,
      _context15,
      prop = props[propName],
      componentNameSafe = componentName || "<<anonymous>>",
      propFullNameSafe = propFullName || propName;
    return Children.toArray(prop).every(function (child) {
      return isValidElement(child) && child.props.id;
    }) ? null : new Error(_concatInstanceProperty(_context14 = _concatInstanceProperty(_context15 = "Invalid ".concat(location, " `")).call(_context15, propFullNameSafe, "` supplied to `")).call(_context14, componentNameSafe, "`. ") + "Provide an array/children of React elements with `id` prop on each.");
  })
};