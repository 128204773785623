import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { parse, parseISO, isValid, isEqual, isAfter, isBefore } from "date-fns";
import { parseDateFromDataType } from "./dateFormat";
export function isDateInRange(date, minDateShape, maxDateShape) {
  var includeTime = arguments.length > 3 && void 0 !== arguments[3] && arguments[3],
    minDateObject = minDateShape ? parseDateFromDataType(minDateShape, includeTime) : null,
    maxDateObject = maxDateShape ? parseDateFromDataType(maxDateShape, includeTime) : null,
    fulfillMinRequirement = !minDateObject || isEqual(date, minDateObject) || isAfter(date, minDateObject),
    fulfillMaxRequirement = !maxDateObject || isEqual(date, maxDateObject) || isBefore(date, maxDateObject);
  return {
    fulfillMinRequirement: fulfillMinRequirement,
    fulfillMaxRequirement: fulfillMaxRequirement
  };
}
export function validateDateUserInput(input, patterns) {
  var _context;
  return !input || !!isValid(parseISO(input)) || _filterInstanceProperty(_context = _mapInstanceProperty(patterns).call(patterns, function (pattern) {
    return parse(input, pattern, new Date());
  })).call(_context, function (date) {
    return isAfter(date, new Date("01/01/1000"));
  }).some(function (date) {
    return isValid(date);
  });
}