import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { ModalNext, ModalBody, ModalFooter, ModalHeader, Button } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import isNil from "lodash/isNil";
import cloneDeep from "lodash/cloneDeep";
import { messages } from "./ColumnsConfigModal.messages";
import { ColumnEntry } from "./ColumnEntry";
export function ColumnsConfigModal(_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    onReject = _ref.onReject,
    parentSelector = _ref.parentSelector,
    initialColumns = _ref.initialColumns,
    translator = useTranslator(),
    _useState = useState(initialColumns),
    _useState2 = _slicedToArray(_useState, 2),
    columns = _useState2[0],
    setColumns = _useState2[1],
    handleConfirm = useCallback(function () {
      return onResolve(columns);
    }, [onResolve, columns]),
    columnEntries = useMemo(function () {
      var onColumnToggle = function (index) {
        return function (enabled) {
          var _context,
            newColumns = _mapInstanceProperty(_context = cloneDeep(columns)).call(_context, function (column, columnIndex) {
              var currentlyEnabled;
              return columnIndex === index && (column.enabled = isNil(enabled) ? !currentlyEnabled : enabled), column;
            });
          setColumns(newColumns);
        };
      };
      return _mapInstanceProperty(columns).call(columns, function (column, index) {
        return React.createElement(ColumnEntry, _extends({
          key: column.id
        }, column, {
          onToggle: onColumnToggle(index)
        }));
      });
    }, [columns]),
    titleId = uniqueInnerId("columnsConfigModalTitle", "titleId").titleId,
    title = translator(messages.title);
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    parentSelector: parentSelector,
    ariaLabelledby: titleId,
    shouldCloseOnEsc: !1
  }, React.createElement(ModalHeader, {
    title: title,
    titleId: titleId,
    subtitle: translator(messages.subtitle),
    ariaLabel: translator(messages.closeModal),
    onClose: onReject
  }), React.createElement(ModalBody, {
    contentLayout: {
      component: "Grid",
      componentProps: {
        columns: ["1fr"],
        gap: "none",
        role: "group",
        "aria-label": title
      }
    }
  }, columnEntries), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: onReject
  }, translator(messages.cancel)), React.createElement(Button, {
    onClick: handleConfirm
  }, translator(messages.applyChanges))));
}
var columnPropTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  enabled: PropTypes.bool
};
ColumnsConfigModal.propTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
  parentSelector: PropTypes.func,
  initialColumns: PropTypes.arrayOf(PropTypes.shape(columnPropTypes))
}, ColumnsConfigModal.__docgenInfo = {
  description: "ColumnsConfigModal\n@param {object} param0",
  methods: [],
  displayName: "ColumnsConfigModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The flag to indicate if the Modal is currently open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when the Modal has been requested to be resolved"
    },
    onReject: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when the Modal has been requested to be closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to find the Modal's parent selector"
    },
    initialColumns: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Used to identify the component",
              required: !0
            },
            header: {
              name: "string",
              description: "Label text to be displayed beside the checkbox",
              required: !1
            },
            enabled: {
              name: "bool",
              description: "If true, the checkbox is marked as selected",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Array of columns"
    }
  }
}, ColumnsConfigModal.__docgenInfo = {
  componentName: "ColumnsConfigModal",
  packageName: "@jutro/datatable",
  description: "ColumnsConfigModal",
  displayName: "ColumnsConfigModal",
  methods: [],
  actualName: "ColumnsConfigModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The flag to indicate if the Modal is currently open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when the Modal has been requested to be resolved"
    },
    onReject: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called when the Modal has been requested to be closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to find the Modal's parent selector"
    },
    initialColumns: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Used to identify the component",
              required: !0
            },
            header: {
              name: "string",
              description: "Label text to be displayed beside the checkbox",
              required: !1
            },
            enabled: {
              name: "bool",
              description: "If true, the checkbox is marked as selected",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Array of columns"
    }
  }
};