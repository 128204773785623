import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "className", "fluid", "dangerouslySetInnerHTML", "noDeprecationWarnings"];
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { ComponentPropTypes } from "@jutro/prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import styles from "./Footer.module.css";
import { FooterNavBar } from "./FooterNavBar_DEPRECATED";
import { FooterText } from "./FooterText_DEPRECATED";
import { FooterCopyright } from "./FooterCopyright_DEPRECATED";
import { FooterContext, footerLegalTextType, footerBasicType } from "./FooterContext_DEPRECATED";
import { FooterDeprecationContext } from "./FooterDeprecationContext_DEPRECATED";
var footerPropTypes = {
  className: PropTypes.string,
  children: ComponentPropTypes.oneOfChildOfComponentTypes([FooterNavBar, FooterText, FooterCopyright]),
  fluid: PropTypes.bool
};
export var Footer = function (_ref) {
  var _cx,
    children = _ref.children,
    className = _ref.className,
    fluid = _ref.fluid,
    noDeprecationWarnings = (_ref.dangerouslySetInnerHTML, _ref.noDeprecationWarnings),
    rest = _objectWithoutProperties(_ref, _excluded);
  useDeprecationWarning({
    componentName: "Footer",
    noDeprecationWarnings: noDeprecationWarnings
  });
  var footerType = children && function (children) {
      var _context, _context2;
      return _includesInstanceProperty(_context = _mapInstanceProperty(_context2 = React.Children).call(_context2, children, function (child) {
        return null == child ? void 0 : child.type;
      })).call(_context, FooterText) ? footerLegalTextType : footerBasicType;
    }(children),
    classes = cx(styles.footer, className),
    containerClasses = cx(styles.footerContainer, styles.footerContent, (_defineProperty(_cx = {}, styles.footerContentBasic, footerType === footerBasicType), _defineProperty(_cx, styles.fluid, fluid), _cx));
  return React.createElement("footer", _extends({
    className: classes
  }, rest), React.createElement(FooterContext.Provider, {
    value: footerType
  }, React.createElement(FooterDeprecationContext.Provider, {
    value: noDeprecationWarnings
  }, React.createElement("div", {
    className: containerClasses
  }, children))));
};
Footer.propTypes = footerPropTypes, Footer.__docgenInfo = {
  description: "The `Footer` component is designed as the container for a set of components that should be displayed on the footer of\napplication using `Jutro`. Dedicated components that should be used as children are `FooterNavBar` along with\n`FooterNavLink`s, `FooterText` and `FooterCopyright`.\n\n@type {React.FC<PropTypes.InferProps<typeof footerPropTypes>>}\n\n@metadataType container\n@deprecated since v8.9.0",
  methods: [],
  displayName: "Footer",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.oneOfChildOfComponentTypes([\n    FooterNavBar,\n    FooterText,\n    FooterCopyright,\n])"
      },
      required: !1,
      description: "Children of footer (navbar, text, copyright, etc.)"
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Span the entire width"
    }
  }
}, Footer.__docgenInfo = {
  componentName: "Footer",
  packageName: "@jutro/components",
  description: "The `Footer` component is designed as the container for a set of components that should be displayed on the footer of\napplication using `Jutro`. Dedicated components that should be used as children are `FooterNavBar` along with\n`FooterNavLink`s, `FooterText` and `FooterCopyright`.",
  displayName: "Footer",
  methods: [],
  actualName: "Footer",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.oneOfChildOfComponentTypes([\n    FooterNavBar,\n    FooterText,\n    FooterCopyright,\n])"
      },
      required: !1,
      description: "Children of footer (navbar, text, copyright, etc.)"
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Span the entire width"
    }
  }
};