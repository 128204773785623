import { defineMessages } from "react-intl";
export var messages = defineMessages({
  notifications: {
    id: "jutro-router.ApplicationHeader.Notifications",
    defaultMessage: "Notifications"
  },
  logoAlt: {
    id: "jutro-router.ApplicationHeader.logo alt",
    defaultMessage: "Application Logo"
  },
  notificationButton: {
    id: "jutro-router.ApplicationHeader.Notification Button",
    defaultMessage: "Notification Button"
  },
  searchFieldPlaceholder: {
    id: "jutro-router.ApplicationHeader.SearchField Placeholder",
    defaultMessage: "Search..."
  },
  moreButtonText: {
    id: "jutro-router.ApplicationHeader.Subheader More",
    defaultMessage: "More"
  },
  help: {
    id: "jutro-router.ApplicationHeader.Help",
    defaultMessage: "Help"
  },
  switchApplication: {
    id: "jutro-router.ApplicationHeader.SwitchApplication",
    defaultMessage: "Switch Application"
  },
  subHeaderNavTitle: {
    id: "jutro-router.ApplicationHeader.SubHeaderNavTitle",
    defaultMessage: "Header Navigation"
  }
});