import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { format, isAfter, isBefore } from "date-fns";
var PATTERN_MAP_yyyy = "yyyy",
  PATTERN_MAP_M = "M",
  PATTERN_MAP_MM = "MM",
  PATTERN_MAP_dd = "dd",
  PATTERN_MAP_d = "d",
  SEPARATORS = ["/", "-", "."];
export function getDatePatternFromLocale(locale) {
  var sampleDate = new Date("1970-01-02T00:00");
  return format(sampleDate, "P", {
    locale: locale
  }).replace(/(19)?70/i, PATTERN_MAP_yyyy).replace(/01/i, PATTERN_MAP_MM).replace(/1/i, PATTERN_MAP_M).replace(/02/i, PATTERN_MAP_dd).replace(/2/i, PATTERN_MAP_d);
}
export function getDateInputFormats(localPattern) {
  var _context,
    _context2,
    permutationFormats = _filterInstanceProperty(_context = _mapInstanceProperty(SEPARATORS).call(SEPARATORS, function (separator) {
      return localPattern.replace(/[/\-.]/g, separator);
    })).call(_context, function (pattern) {
      return pattern !== localPattern;
    });
  return _concatInstanceProperty(_context2 = [localPattern]).call(_context2, _toConsumableArray(permutationFormats), ["P"]);
}
export function isTodayOutOfRange(_ref) {
  var minDateObject = _ref.minDateObject,
    maxDateObject = _ref.maxDateObject,
    nowWithoutTime = new Date();
  nowWithoutTime.setHours(0, 0, 0, 0);
  var isTodayBeforeMin = !!minDateObject && isBefore(nowWithoutTime, minDateObject),
    isTodayAfterMax = !!maxDateObject && isAfter(nowWithoutTime, maxDateObject);
  return isTodayBeforeMin || isTodayAfterMax;
}