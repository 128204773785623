import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["onClick", "target", "replace", "to", "href", "innerRef", "className", "allowNoLeadingSlash", "dangerouslySetInnerHTML"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { log } from "@jutro/logger";
import cx from "classnames";
import { Button } from "@jutro/components";
import { useHistory } from "react-router-dom";
import { intlMessageShape, intlToShape, deprecated } from "@jutro/prop-types";
import { useBreakpoint } from "@jutro/layout";
import { getUrlTranslatorAndSanitizer, useTranslator } from "@jutro/locale";
import styles from "./ButtonLink.module.css";
var defaultProps = _objectSpread(_objectSpread({}, Button.defaultProps), {}, {
  replace: !1,
  allowNoLeadingSlash: !1
});
export var ButtonLink = forwardRef(function (props, ref) {
  var _ref = useBreakpoint(props).breakpointProps,
    onClick = _ref.onClick,
    target = _ref.target,
    replace = _ref.replace,
    to = _ref.to,
    href = _ref.href,
    innerRef = _ref.innerRef,
    className = _ref.className,
    allowNoLeadingSlash = _ref.allowNoLeadingSlash,
    other = (_ref.dangerouslySetInnerHTML, _objectWithoutProperties(_ref, _excluded)),
    history = useHistory(),
    translator = useTranslator(),
    urlTranslatorAndSanitizer = getUrlTranslatorAndSanitizer(translator),
    newRef = ref || innerRef;
  to || href || log.error('"href" or "to". One of these properties is required for the component ButtonLink. Use "to" for internal routing and "href" for external routing');
  var classes = cx(styles.buttonLink, className);
  return React.createElement(Button, _extends({
    href: href,
    onClick: function (event) {
      if (onClick && onClick(event), !href && !(event.defaultPrevented || void 0 !== event.button && 0 !== event.button || target)) {
        if (event.preventDefault(), !history) return;
        var safeTo = urlTranslatorAndSanitizer(to, allowNoLeadingSlash);
        safeTo && (replace ? history.replace(safeTo) : history.push(safeTo));
      }
    }
  }, other, {
    ref: newRef,
    className: classes
  }));
});
var buttonLinkPropTypes = {
  replace: PropTypes.bool,
  to: intlToShape,
  href: intlMessageShape,
  innerRef: deprecated(PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf("undefined" != typeof window ? Element : Object)
  })]), void 0, "Please use ref instead (See more: https://reactjs.org/docs/refs-and-the-dom.html)"),
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  allowNoLeadingSlash: PropTypes.bool,
  target: PropTypes.string
};
ButtonLink.propTypes = buttonLinkPropTypes, ButtonLink.defaultProps = defaultProps, ButtonLink.displayName = "ButtonLink", ButtonLink.__docgenInfo = {
  description: "@metadataType action",
  methods: [],
  displayName: "ButtonLink",
  props: {
    replace: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "The replace prop will replace the current entry in the history stack"
    },
    allowNoLeadingSlash: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if relative routes without leading slash are allowed"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is resolved; can be an object like `<Link to>`.\nUse this for paths internal to the application."
    },
    href: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The destination path when promise is resolved.\nUse this for paths external to the application."
    },
    innerRef: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.oneOfType([\n        PropTypes.func,\n        PropTypes.shape({\n            current: PropTypes.instanceOf(\n                typeof window !== 'undefined' ? Element : Object\n            ),\n        }),\n    ]),\n    undefined,\n    'Please use ref instead (See more: https://reactjs.org/docs/refs-and-the-dom.html)'\n)"
      },
      required: !1,
      description: "Get ref to the inner rendered `<a>` or `<button>`\n@deprecated"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The child element wrapped in the component"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the button is clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    target: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Where to display the linked URL. Used only for external paths."
    }
  }
}, ButtonLink.__docgenInfo = {
  componentName: "ButtonLink",
  packageName: "@jutro/router",
  description: "",
  displayName: "ButtonLink",
  methods: [],
  actualName: "ButtonLink",
  metadataType: "action",
  props: {
    replace: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The replace prop will replace the current entry in the history stack",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is resolved; can be an object like `<Link to>`.\nUse this for paths internal to the application."
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The destination path when promise is resolved.\nUse this for paths external to the application."
    },
    innerRef: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "instanceOf",
              value: "typeof window !== 'undefined' ? Element : Object",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Get ref to the inner rendered `<a>` or `<button>`\n@deprecated",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The child element wrapped in the component"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the button is clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    allowNoLeadingSlash: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Defines if relative routes without leading slash are allowed",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    target: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Where to display the linked URL. Used only for external paths."
    }
  }
};