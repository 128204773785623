import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["children"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React, { createContext, useMemo } from "react";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import { resolveComponent as defaultResolveComponent } from "../render/renderMetadataHelper";
import { simpleNamedComponentMap as globalComponentMap } from "../render/componentMap";
export var ComponentMapContext = createContext(defaultResolveComponent);
export var extendResolver = function (componentMapExtensions, resolveComponent) {
  var componentMapExtensionsOnly = omit(componentMapExtensions, _Object$keys(globalComponentMap));
  return function (component, datatype, componentMap, type, metadataPath, componentProps) {
    return resolveComponent(component, datatype, _objectSpread(_objectSpread({}, componentMapExtensionsOnly), componentMap), type, metadataPath, componentProps);
  };
};
var componentMapProviderDefaultProps = {
    resolveComponent: defaultResolveComponent
  },
  componentMapProviderPropTypes = {
    resolveComponent: PropTypes.func,
    componentMapExtensions: PropTypes.objectOf(PropTypes.elementType.isRequired)
  };
export var ComponentMapProvider = function (_ref) {
  var children = _ref.children,
    _ref2 = _objectWithoutProperties(_ref, _excluded),
    componentMapExtensions = _ref2.componentMapExtensions,
    resolveComponent = _ref2.resolveComponent,
    customResolveComponent = useMemo(function () {
      return componentMapExtensions ? extendResolver(componentMapExtensions, resolveComponent) : resolveComponent;
    }, [resolveComponent, componentMapExtensions]);
  return React.createElement(ComponentMapContext.Provider, {
    value: customResolveComponent
  }, children);
};
ComponentMapProvider.propTypes = componentMapProviderPropTypes, ComponentMapProvider.defaultProps = componentMapProviderDefaultProps, ComponentMapProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ComponentMapProvider",
  props: {
    resolveComponent: {
      defaultValue: {
        value: "defaultResolveComponent",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "An optional callback function to resolve components from string and/or datatype"
    },
    componentMapExtensions: {
      type: {
        name: "objectOf",
        value: {
          name: "elementType"
        }
      },
      required: !1,
      description: "Extensions to the default component map which will be used when resolving metadata"
    }
  }
}, ComponentMapProvider.__docgenInfo = {
  componentName: "ComponentMapProvider",
  packageName: "@jutro/uiconfig",
  description: "",
  displayName: "ComponentMapProvider",
  methods: [],
  actualName: "ComponentMapProvider",
  props: {
    resolveComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "An optional callback function to resolve components from string and/or datatype",
      defaultValue: {
        value: "defaultResolveComponent",
        computed: !0
      }
    },
    componentMapExtensions: {
      type: {
        name: "objectOf",
        value: {
          name: "elementType"
        }
      },
      required: !1,
      description: "Extensions to the default component map which will be used when resolving metadata"
    }
  }
};