import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { IconButton } from "../../IconButton/IconButton_DEPRECATED";
import { Icon } from "../../Icon/Icon";
import { layoutShape } from "../../../layouts/Layouts.commons";
import { getLayoutComponent } from "../helpers/modalHelpers";
import styles from "./ModalHeader.module.css";
export var modalClassMap = {
  warning: {
    status: "modalWarningStatus",
    icon: "iconWarning"
  },
  error: {
    status: "modalErrorStatus",
    icon: "iconError"
  },
  info: {
    status: "modalInfoStatus",
    icon: "iconInfo"
  },
  success: {
    status: "modalSuccessStatus",
    icon: "iconSuccess"
  }
};
var modalHeaderPropTypes = {
  contentLayout: PropTypes.oneOfType([PropTypes.shape({
    component: PropTypes.string.isRequired,
    componentProps: PropTypes.object
  }), layoutShape]),
  titleLayout: PropTypes.oneOfType([PropTypes.shape({
    component: PropTypes.string.isRequired,
    componentProps: PropTypes.object
  }), layoutShape]),
  status: PropTypes.oneOf(["success", "info", "warning", "error"]),
  icon: PropTypes.string,
  title: intlMessageShape,
  subtitle: intlMessageShape,
  onClose: PropTypes.func,
  titleId: PropTypes.string,
  ariaLabel: intlMessageShape
};
export var ModalHeader = function (_ref) {
  var contentLayout = _ref.contentLayout,
    titleLayout = _ref.titleLayout,
    status = _ref.status,
    icon = _ref.icon,
    title = _ref.title,
    subtitle = _ref.subtitle,
    onClose = _ref.onClose,
    titleId = _ref.titleId,
    ariaLabel = _ref.ariaLabel,
    Layout = getLayoutComponent(contentLayout.component),
    TitleLayout = getLayoutComponent(titleLayout.component),
    translator = useTranslator(),
    modalClass = modalClassMap[status] || {},
    modalStatusClasses = cx(styles.modalStatus, styles[modalClass.status]),
    iconClasses = cx(styles.icon, styles[modalClass.icon]);
  return React.createElement("div", {
    className: styles.modalHeader
  }, React.createElement("div", {
    className: modalStatusClasses
  }), React.createElement(Layout, _extends({
    className: styles.modalHeaderLayout
  }, contentLayout.componentProps), icon && React.createElement(Icon, {
    icon: icon,
    className: iconClasses
  }), React.createElement(TitleLayout, _extends({
    id: titleId
  }, titleLayout.componentProps), title && React.createElement("span", {
    className: styles.title
  }, translator(title)), subtitle && React.createElement("span", {
    className: styles.subtitle
  }, translator(subtitle))), onClose && React.createElement(IconButton, {
    size: "small",
    icon: "gw-close",
    onClick: onClose,
    ariaLabel: ariaLabel,
    className: styles.closeButton,
    noDeprecationWarnings: ["IconButton"]
  })));
};
ModalHeader.propTypes = modalHeaderPropTypes, ModalHeader.defaultProps = {
  contentLayout: {
    component: "flex",
    componentProps: {
      alignItems: "top",
      wrap: !1,
      gap: "small"
    }
  },
  titleLayout: {
    component: "flex",
    componentProps: {
      direction: "column",
      gap: "none"
    }
  }
}, ModalHeader.__docgenInfo = {
  description: "ModalHeader\n@type {React.FC<PropTypes.InferProps<typeof modalHeaderPropTypes>>}",
  methods: [],
  displayName: "ModalHeader",
  props: {
    contentLayout: {
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        alignItems: 'top',\n        wrap: false,\n        gap: 'small',\n    },\n}",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: !0
            },
            componentProps: {
              name: "object",
              required: !1
            }
          }
        }, {
          name: "custom",
          raw: "layoutShape"
        }]
      },
      required: !1,
      description: "defines the content layout to be used with a 'component' property set to either Flex or Grid and\n'componentProps' to set properties for that layout component"
    },
    titleLayout: {
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        direction: 'column',\n        gap: 'none',\n    },\n}",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: !0
            },
            componentProps: {
              name: "object",
              required: !1
            }
          }
        }, {
          name: "custom",
          raw: "layoutShape"
        }]
      },
      required: !1,
      description: "defines the main title layout to be used with a 'component' property set to either Flex or Grid and\n'componentProps' to set properties for that layout component"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: !1
        }, {
          value: "'info'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }]
      },
      required: !1,
      description: "The status of this modal.  Either a 'success', 'info', 'warning', error'.  Default to no status"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "icon name"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text to display for the title"
    },
    subtitle: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Text to display for the subtitle"
    },
    onClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The function to be closed when the close button is clicked"
    },
    titleId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify modal header title content."
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label text for close icon"
    }
  }
}, ModalHeader.__docgenInfo = {
  componentName: "ModalHeader",
  packageName: "@jutro/components",
  description: "ModalHeader",
  displayName: "ModalHeader",
  methods: [],
  actualName: "ModalHeader",
  props: {
    contentLayout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: !0
            },
            componentProps: {
              name: "object",
              required: !1
            }
          }
        }, {
          name: "shape",
          value: {
            repeat: {
              name: "union",
              value: [{
                name: "enum",
                value: [{
                  value: "'auto-fit'",
                  computed: !1
                }, {
                  value: "'auto-fill'",
                  computed: !1
                }]
              }, {
                name: "number"
              }, {
                name: "string"
              }],
              description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
              required: !1
            },
            gap: {
              name: "enum",
              computed: !0,
              value: "Object.keys(gaps)",
              description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
              required: !1
            },
            columns: {
              name: "array",
              description: "define explicit columns",
              required: !1
            },
            colSpan: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              description: "specifies how many columns an element should span across",
              required: !1
            },
            colStart: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              description: "define column to start",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "defines the content layout to be used with a 'component' property set to either Flex or Grid and\n'componentProps' to set properties for that layout component",
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        alignItems: 'top',\n        wrap: false,\n        gap: 'small',\n    },\n}",
        computed: !1
      }
    },
    titleLayout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: !0
            },
            componentProps: {
              name: "object",
              required: !1
            }
          }
        }, {
          name: "shape",
          value: {
            repeat: {
              name: "union",
              value: [{
                name: "enum",
                value: [{
                  value: "'auto-fit'",
                  computed: !1
                }, {
                  value: "'auto-fill'",
                  computed: !1
                }]
              }, {
                name: "number"
              }, {
                name: "string"
              }],
              description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
              required: !1
            },
            gap: {
              name: "enum",
              computed: !0,
              value: "Object.keys(gaps)",
              description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
              required: !1
            },
            columns: {
              name: "array",
              description: "define explicit columns",
              required: !1
            },
            colSpan: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              description: "specifies how many columns an element should span across",
              required: !1
            },
            colStart: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              description: "define column to start",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "defines the main title layout to be used with a 'component' property set to either Flex or Grid and\n'componentProps' to set properties for that layout component",
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        direction: 'column',\n        gap: 'none',\n    },\n}",
        computed: !1
      }
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: !1
        }, {
          value: "'info'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }]
      },
      required: !1,
      description: "The status of this modal.  Either a 'success', 'info', 'warning', error'.  Default to no status"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "icon name"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text to display for the title"
    },
    subtitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Text to display for the subtitle"
    },
    onClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The function to be closed when the close button is clicked"
    },
    titleId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify modal header title content."
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text for close icon"
    }
  }
};