import React from "react";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./DropdownMenu.module.css";
export var DropdownMenuHeader = function (props) {
  var id = props.id,
    title = props.title,
    children = props.children,
    translator = useTranslator();
  return React.createElement("div", {
    id: id,
    className: styles.dropdownHeader
  }, React.createElement("div", {
    className: styles.dropdownHeaderTitle
  }, translator(title)), children);
};
DropdownMenuHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  children: PropTypes.node
}, DropdownMenuHeader.__docgenInfo = {
  description: "DropdownMenuHeader\n\n@metadataType container\n@param props",
  methods: [],
  displayName: "DropdownMenuHeader",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the dropdown menu header component"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The element node representing the header title, in most cases it will be a plain string."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu header component."
    }
  }
}, DropdownMenuHeader.__docgenInfo = {
  componentName: "DropdownMenuHeader",
  packageName: "@jutro/components",
  description: "DropdownMenuHeader",
  displayName: "DropdownMenuHeader",
  methods: [],
  actualName: "DropdownMenuHeader",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the dropdown menu header component"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The element node representing the header title, in most cases it will be a plain string."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu header component."
    }
  }
};