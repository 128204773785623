import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useBreakpoint } from "@jutro/layout";
import styles from "./ActionTitleBar.module.css";
import { TitleElement } from "./TitleElement";
export var ActionTitleBar = function (props) {
  var breakpointProps = useBreakpoint(props).breakpointProps,
    className = breakpointProps.className,
    childrenArray = React.Children.toArray(breakpointProps.children),
    isTitleElement = function (child) {
      return child.type === TitleElement;
    },
    titleElements = _filterInstanceProperty(childrenArray).call(childrenArray, isTitleElement),
    actions = _filterInstanceProperty(childrenArray).call(childrenArray, function (child) {
      return !isTitleElement(child);
    });
  return React.createElement("div", {
    role: "group",
    className: cx(styles.actionTitleBar, styles.actionTitleBarContainer, className)
  }, React.createElement("div", {
    "data-testid": "title-container",
    className: cx(styles.titleContainer)
  }, titleElements), React.createElement("div", {
    role: "toolbar",
    className: cx(styles.toolbar)
  }, actions));
};
export var actionTitleBarBasePropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
ActionTitleBar.propTypes = _objectSpread(_objectSpread({}, actionTitleBarBasePropTypes), {}, {
  phone: PropTypes.shape(actionTitleBarBasePropTypes),
  phoneWide: PropTypes.shape(actionTitleBarBasePropTypes),
  tablet: PropTypes.shape(actionTitleBarBasePropTypes)
}), ActionTitleBar.displayName = "ActionTitleBar", ActionTitleBar.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "ActionTitleBar",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "arrayOf",
          value: {
            name: "node"
          }
        }, {
          name: "node"
        }]
      },
      required: !0,
      description: "Children to be rendered inside this component"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: !0
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: !0
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: !0
          }
        }
      },
      required: !1,
      description: ""
    }
  }
}, ActionTitleBar.__docgenInfo = {
  componentName: "ActionTitleBar",
  packageName: "@jutro/components",
  description: "",
  displayName: "ActionTitleBar",
  methods: [],
  actualName: "ActionTitleBar",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "arrayOf",
          value: {
            name: "node"
          }
        }, {
          name: "node"
        }]
      },
      required: !0,
      description: "Children to be rendered inside this component"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: !0
          }
        }
      },
      required: !1,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: !0
          }
        }
      },
      required: !1,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: !1
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: !0
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};