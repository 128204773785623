import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import { warning } from "@jutro/logger";
var loggedMessages = new _Set();
export var logDeprecationMessage = function (deprecated, alternative) {
  var extraMessage = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "",
    dropTargetVersion = arguments.length > 3 ? arguments[3] : void 0,
    message = "".concat(deprecated, " has been deprecated.");
  alternative && (message += " Please use ".concat(alternative, " instead.")), extraMessage && (message += " ".concat(extraMessage)), message += dropTargetVersion ? ' It will be removed in the "'.concat(dropTargetVersion, '" Jutro release.') : " It will remain in the Jutro library until no longer in use which will be determined by the usage analytics.", loggedMessages.has(message) || (loggedMessages.add(message), warning(message, {
    bold: !0
  }));
};