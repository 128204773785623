import React from "react";
import { useTranslator } from "@jutro/locale";
import { DropdownMenuLink } from "@jutro/components";
import styles from "./AppSwitcher.module.css";
export var AppSwitcherElement = function (_ref) {
  var _ref$item = _ref.item,
    title = _ref$item.title,
    imageUrl = _ref$item.imageUrl,
    imageAlt = _ref$item.imageAlt,
    to = _ref$item.to,
    href = _ref$item.href,
    onClick = _ref$item.onClick,
    onRouteClick = _ref.onRouteClick,
    closePopover = _ref.closePopover,
    resetSearch = _ref.resetSearch,
    translatedTitle = useTranslator()(title);
  return React.createElement(DropdownMenuLink, {
    className: styles.item,
    to: to,
    href: href,
    onClick: function (event) {
      onClick && onClick(event), onRouteClick && onRouteClick(), closePopover && closePopover(), resetSearch();
    }
  }, React.createElement("img", {
    src: imageUrl,
    className: styles.itemIcon,
    alt: imageAlt
  }), React.createElement("div", {
    className: styles.itemLabel
  }, translatedTitle));
};
AppSwitcherElement.displayName = "AppSwitcherElement", AppSwitcherElement.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AppSwitcherElement"
}, AppSwitcherElement.__docgenInfo = {
  componentName: "AppSwitcherElement",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcherElement",
  methods: [],
  actualName: "AppSwitcherElement"
};