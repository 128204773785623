import PropTypes from "prop-types";
import { intlMessageShape } from "./intlMessageShape";
import { dateValueShape } from "./dateValueShape";
import { andValidator, shapeValidator } from "./airbnbPropTypes";
export var validationMessageShapeType = {
  message: intlMessageShape,
  details: intlMessageShape,
  type: PropTypes.oneOf(["error", "warning"]).isRequired
};
export var validationMessageShape = PropTypes.oneOfType([PropTypes.string, PropTypes.shape(validationMessageShapeType)]);
export var getValidationRuleShape = function (ruleProptype) {
  return PropTypes.oneOfType([ruleProptype, andValidator([PropTypes.array, shapeValidator({
    0: ruleProptype.isRequired,
    1: intlMessageShape,
    length: PropTypes.oneOf([1, 2]).isRequired
  })])]);
};
export var dateValidationRuleShape = getValidationRuleShape(dateValueShape);
export var booleanValidationRuleShape = getValidationRuleShape(PropTypes.bool);
export var stringValidationRuleShape = getValidationRuleShape(PropTypes.string);