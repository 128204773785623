import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import { useContext, useState, useRef, useCallback, useEffect, createContext } from "react";
import { useTranslator } from "@jutro/locale";
import { usePrevious } from "@jutro/platform";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { triggerValidation } from "./triggerValidation";
import { fieldRulesFactory } from "../fieldRulesFactory/fieldRulesFactory";
import { getMessagesCount, excludeMultiMessageWarningsFromMessages, groupCurrentValidationMessages } from "./messagesFilterUtils";
import messages from "./validationMechanism.messages";
var getPath = function (path, formPath) {
    var _context;
    return formPath ? _concatInstanceProperty(_context = "".concat(formPath, ".")).call(_context, path) : path;
  },
  emptyContext = createContext({});
export var useValidation = function (_ref) {
  var _usePrevious,
    formData = _ref.formData,
    formPath = _ref.formPath,
    formRules = _ref.formRules,
    formInvalidMessage = _ref.formInvalidMessage,
    formWarningMessage = _ref.formWarningMessage,
    _ref$formMode = _ref.formMode,
    formMode = void 0 === _ref$formMode ? "opaque" : _ref$formMode,
    onValidationChange = _ref.onValidationChange,
    isValidationIgnored = _ref.isValidationIgnored,
    FormContext = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : emptyContext,
    translator = useTranslator(),
    validationPassThru = "transparent" === formMode,
    nestedContext = useContext(FormContext),
    fieldRules = useRef({}),
    messagesGroupedByType = useRef({}),
    onIsValidCallbacks = useRef({}),
    multipleMessagesMap = useRef({}),
    _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    formMessages = _useState2[0],
    setFormMessages = _useState2[1],
    prevFormData = null !== (_usePrevious = usePrevious(formData)) && void 0 !== _usePrevious ? _usePrevious : {},
    _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    nestedFormMessages = _useState4[0],
    setNestedFormMessages = _useState4[1],
    registerValidation = useCallback(function (path, rulesConfig, options, onIsValid) {
      if (!isValidationIgnored) if (validationPassThru && null != nestedContext && nestedContext.registerValidation) {
        var _fullPath = getPath(path, formPath);
        nestedContext.registerValidation(_fullPath, rulesConfig, options, onIsValid);
      } else {
        var fullPath = path;
        if (null !== fieldRules.current) {
          if (isEmpty(rulesConfig)) delete fieldRules.current[fullPath];else {
            var _context2,
              _context3,
              rules = _mapInstanceProperty(_context2 = _filterInstanceProperty(_context3 = _Object$entries(rulesConfig)).call(_context3, function (_ref2) {
                return !!_slicedToArray(_ref2, 2)[1];
              })).call(_context2, function (_ref4) {
                var _ref5 = _slicedToArray(_ref4, 2),
                  key = _ref5[0],
                  value = _ref5[1],
                  _ref6 = Array.isArray(value) ? value : [value],
                  _ref7 = _slicedToArray(_ref6, 2),
                  ruleConfig = _ref7[0],
                  messageOverwrite = _ref7[1];
                return fieldRulesFactory[key]([ruleConfig, messageOverwrite], options);
              });
            fieldRules.current[fullPath] = rules;
          }
          onIsValid && (onIsValidCallbacks.current[fullPath] = onIsValid), null != options && options.enableMultipleValidation && (multipleMessagesMap.current[fullPath] = !!options && options.enableMultipleValidation);
        }
      }
    }, [fieldRules.current, validationPassThru, null == nestedContext ? void 0 : nestedContext.registerValidation]),
    notifyParentForm = useCallback(function (path, message) {
      setNestedFormMessages(function (prevMessages) {
        var newMessages = _objectSpread({}, isEmpty(prevMessages) ? {} : prevMessages);
        return message ? newMessages[path] = message : delete newMessages[path], newMessages;
      });
    }, [setNestedFormMessages]),
    resolveFormValidation = function (path, value, id) {
      if (validationPassThru && null != nestedContext && nestedContext.resolveFormValidation) {
        var fullPath = getPath(path, formPath);
        return nestedContext.resolveFormValidation(fullPath, value, id);
      }
      var _context4, _context5;
      if (!isEmpty(formMessages)) return formMessages[path] ? formMessages[path] : _reduceInstanceProperty(_context4 = _filterInstanceProperty(_context5 = _Object$entries(formMessages)).call(_context5, function (_ref8) {
        var fieldPath = _slicedToArray(_ref8, 1)[0];
        return _startsWithInstanceProperty(fieldPath).call(fieldPath, path);
      })).call(_context4, function (acc, _ref10) {
        var _ref11 = _slicedToArray(_ref10, 2),
          key = _ref11[0],
          val = _ref11[1];
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key.replace("".concat(path, "."), ""), val));
      }, {});
    };
  return useEffect(function () {
    if (!(isValidationIgnored || validationPassThru && nestedContext)) {
      var rulesForField = null === fieldRules.current ? {} : fieldRules.current,
        validationMessages = triggerValidation({
          fieldRules: rulesForField,
          formRules: formRules,
          formData: formData,
          nestedFormMessages: nestedFormMessages,
          translator: translator,
          multipleMessagesMap: multipleMessagesMap.current
        });
      formData && _Object$entries(onIsValidCallbacks.current).forEach(function (_ref12) {
        var _ref13 = _slicedToArray(_ref12, 2),
          path = _ref13[0],
          onIsValidCallback = _ref13[1];
        !validationMessages[path] && formData[path] && prevFormData[path] !== formData[path] && onIsValidCallback(formData[path]);
      });
      var formMessageObject = excludeMultiMessageWarningsFromMessages(validationMessages);
      setFormMessages(function (prevMessages) {
        return isEqual(prevMessages, formMessageObject) ? prevMessages : formMessageObject;
      });
    }
  }, [formData, fieldRules.current, formRules, setFormMessages, nestedFormMessages, validationPassThru, !!nestedContext]), useEffect(function () {
    var _messagesGroupedByTyp, _messagesGroupedByTyp2;
    if (!(isValidationIgnored || validationPassThru && nestedContext) && formMessages) {
      messagesGroupedByType.current = groupCurrentValidationMessages(formMessages);
      var errorMessages = null === (_messagesGroupedByTyp = messagesGroupedByType.current) || void 0 === _messagesGroupedByTyp ? void 0 : _messagesGroupedByTyp.error,
        warningMessages = null === (_messagesGroupedByTyp2 = messagesGroupedByType.current) || void 0 === _messagesGroupedByTyp2 ? void 0 : _messagesGroupedByTyp2.warning,
        formErrorsCount = getMessagesCount(errorMessages),
        formWarningsCount = getMessagesCount(warningMessages),
        hasWarnings = formWarningsCount > 0,
        hasErrors = formErrorsCount > 0;
      if (onValidationChange && onValidationChange(!hasErrors, formMessages, hasWarnings), !validationPassThru && nestedContext && formPath) {
        var errorMsg = null != formInvalidMessage ? formInvalidMessage : messages.formErrorMessage,
          errorMessage = {
            message: translator(errorMsg, {
              value: formErrorsCount.toString()
            }),
            type: "error"
          },
          warningMsg = null != formWarningMessage ? formWarningMessage : messages.formWarningMessage,
          warningMessage = {
            message: translator(warningMsg, {
              value: formWarningsCount.toString()
            }),
            type: "warning"
          },
          message = hasErrors && errorMessage || hasWarnings && warningMessage || void 0;
        nestedContext.notifyParentForm && "string" == typeof formPath && nestedContext.notifyParentForm(formPath, message);
      }
    }
  }, [formMessages, validationPassThru, !!nestedContext, null == nestedContext ? void 0 : nestedContext.notifyParentForm, formInvalidMessage]), useEffect(function () {
    isValidationIgnored || nestedContext && formPath && nestedContext.notifyParentForm && "string" == typeof formPath && "function" == typeof nestedContext.notifyParentForm && nestedContext.notifyParentForm(formPath, void 0);
  }, [!!nestedContext, formPath]), isValidationIgnored ? {} : {
    registerValidation: registerValidation,
    notifyParentForm: notifyParentForm,
    resolveFormValidation: resolveFormValidation,
    validationMessages: messagesGroupedByType.current
  };
};