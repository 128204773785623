import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext, useEffect } from "react";
import { ModalNextProvider, SkipNav, ToastProvider } from "@jutro/components";
import { BreakpointTracker } from "@jutro/layout";
import { isTSMEnabled } from "./internationalization/tsm/tsmHelpers";
import { MessagesContext } from "./internationalization/MessagesContext";
var ToastProviderToggle = function (_ref) {
    var disabled = _ref.disabled,
      children = _ref.children,
      trigger = _ref.trigger;
    return useEffect(function () {
      var originalTrigger = null;
      return trigger && (originalTrigger = ToastProvider.toast, ToastProvider.setToastTrigger(trigger)), function () {
        ToastProvider.toast === trigger && originalTrigger && ToastProvider.setToastTrigger(originalTrigger);
      };
    }, [trigger]), disabled ? React.createElement(React.Fragment, null, children) : React.createElement(ToastProvider, null, children);
  },
  ModalProviderToggle = function (_ref2) {
    var disabled = _ref2.disabled,
      emitter = _ref2.emitter,
      modalFunctions = _ref2.modalFunctions,
      children = _ref2.children;
    return disabled ? React.createElement(React.Fragment, null, children) : React.createElement(ModalNextProvider, {
      emitter: emitter,
      modalFunctions: modalFunctions
    }, children);
  },
  ApplicationContentInternal = function (_ref3) {
    var Main = _ref3.mainComponent,
      mainComponentProps = _ref3.mainComponentProps,
      disableToastProvider = _ref3.disableToastProvider,
      toastTrigger = _ref3.toastTrigger,
      disableModalProvider = _ref3.disableModalProvider,
      modalEmitter = _ref3.modalEmitter,
      modalFunctions = _ref3.modalFunctions,
      LoaderComponent = _ref3.loaderComponent,
      isAuthenticated = _ref3.isAuthenticated,
      hideSkipNavigation = _ref3.hideSkipNavigation,
      appName = _ref3.appName,
      _useContext$messagesS = useContext(MessagesContext).messagesState,
      isMessagesLoaded = _useContext$messagesS.isMessagesLoaded,
      languagesLoaded = _useContext$messagesS.languagesLoaded,
      isMessagesLoadedTSM = _useContext$messagesS.isMessagesLoadedTSM,
      globalizationLoaded = isMessagesLoaded && languagesLoaded && (!isTSMEnabled() || isMessagesLoadedTSM);
    return React.createElement(LoaderComponent, {
      loaded: isAuthenticated && globalizationLoaded
    }, !hideSkipNavigation && React.createElement(SkipNav, {
      appName: appName
    }), React.createElement(BreakpointTracker, null, React.createElement(ToastProviderToggle, {
      disabled: disableToastProvider,
      trigger: toastTrigger
    }, React.createElement(ModalProviderToggle, {
      disabled: disableModalProvider,
      emitter: modalEmitter,
      modalFunctions: modalFunctions
    }, React.createElement(Main, _extends({
      hasLoaded: !0
    }, mainComponentProps))))));
  };
ApplicationContentInternal.displayName = "ApplicationContent";
export var ApplicationContent = React.memo(ApplicationContentInternal);
ApplicationContentInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ApplicationContent"
}, ToastProviderToggle.__docgenInfo = {
  componentName: "ToastProviderToggle",
  packageName: "@jutro/app",
  description: "",
  displayName: "ToastProviderToggle",
  methods: [],
  actualName: "ToastProviderToggle"
}, ModalProviderToggle.__docgenInfo = {
  componentName: "ModalProviderToggle",
  packageName: "@jutro/app",
  description: "",
  displayName: "ModalProviderToggle",
  methods: [],
  actualName: "ModalProviderToggle"
}, ApplicationContentInternal.__docgenInfo = {
  componentName: "ApplicationContent",
  packageName: "@jutro/app",
  description: "",
  displayName: "ApplicationContent",
  methods: [],
  actualName: "ApplicationContentInternal"
};