import { defineMessages } from "react-intl";
export var messages = defineMessages({
  incorrectInput: {
    id: "jutro-components.widgets.inputs.TimeField.incorrectInput",
    defaultMessage: "Incorrect input"
  },
  inputAriaLabel12: {
    id: "jutro-components.widgets.inputs.TimeField.inputAriaLabel12",
    defaultMessage: "{fieldLabel} - please enter time in 12-hour format"
  },
  inputAriaLabel24: {
    id: "jutro-components.widgets.inputs.TimeField.inputAriaLabel24",
    defaultMessage: "{fieldLabel} - please enter time in 24-hour format"
  }
});