import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { useMemo } from "react";
import { generateComponentId } from "../utils/uniqueId";
var getId = function () {
  return generateComponentId("id");
};
export var useId = function () {
  var args = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
  return useMemo(function () {
    var _args$prefix, _args$default, _context;
    return null !== (_args$prefix = args.prefix) && void 0 !== _args$prefix && _args$prefix.length ? _concatInstanceProperty(_context = "".concat(args.prefix, "_")).call(_context, getId()) : null !== (_args$default = args.default) && void 0 !== _args$default && _args$default.length ? args.default : getId();
  }, [args.prefix, args.default]);
};