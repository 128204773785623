import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import { useIsDraggedOver } from "../utils";
import styles from "../FileUploadField.module.css";
export var DragAndDropWrapper = function (_ref) {
  var className = _ref.className,
    dragAndDropDisabled = _ref.dragAndDropDisabled,
    _onDrop = _ref.onDrop,
    children = _ref.children,
    _useIsDraggedOver = useIsDraggedOver(),
    isDraggedOver = _useIsDraggedOver.isDraggedOver,
    resetIsDraggedOver = _useIsDraggedOver.resetIsDraggedOver,
    popDrag = _useIsDraggedOver.popDrag,
    pushDrag = _useIsDraggedOver.pushDrag,
    classes = cx(_defineProperty({}, styles.dragOver, !dragAndDropDisabled && isDraggedOver), className),
    dragAndDropProps = !dragAndDropDisabled && {
      onDragOver: function (e) {
        e.preventDefault(), e.stopPropagation();
      },
      onDrop: function (e) {
        resetIsDraggedOver(), e.preventDefault(), _onDrop(e);
      },
      onDragEnter: pushDrag,
      onDragLeave: popDrag
    };
  return React.createElement("div", _extends({
    className: classes
  }, dragAndDropProps), children);
};
DragAndDropWrapper.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DragAndDropWrapper"
}, DragAndDropWrapper.__docgenInfo = {
  componentName: "DragAndDropWrapper",
  packageName: "@jutro/components",
  description: "",
  displayName: "DragAndDropWrapper",
  methods: [],
  actualName: "DragAndDropWrapper"
};