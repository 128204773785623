import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useEffect, useState } from "react";
export var useDebounce = function (value, delay) {
  var _useState = useState(value),
    _useState2 = _slicedToArray(_useState, 2),
    debouncedValue = _useState2[0],
    setDebouncedValue = _useState2[1];
  return useEffect(function () {
    var handler = setTimeout(function () {
      setDebouncedValue(value);
    }, delay);
    return function () {
      clearTimeout(handler);
    };
  }, [value, delay]), debouncedValue;
};