import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { warning } from "@jutro/logger";
import { uniqueInnerId } from "@jutro/platform";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./Accordion.module.css";
import { Collapse } from "../collapse/Collapse";
import { AccordionContext } from "./AccordionContext";
import { AccordionCardHeader } from "./AccordionCardHeader";
export var AccordionCard = function (props) {
  var _cx,
    _cx2,
    id = props.id,
    className = props.className,
    collapseClassName = props.collapseClassName,
    cardBodyClassName = props.cardBodyClassName,
    cardHeadingClassName = props.cardHeadingClassName,
    cardHeadingProps = props.cardHeadingProps,
    cardTitleClassName = props.cardTitleClassName,
    errorState = props.errorState,
    isCollapsible = props.isCollapsible,
    renderHeader = props.renderHeader,
    chevron = props.chevron,
    chevronAlignment = props.chevronAlignment,
    title = props.title,
    allowOverflowContent = props.allowOverflowContent,
    children = props.children,
    onCardHeadingClicked = props.onCardHeadingClicked,
    _useContext = useContext(AccordionContext),
    isAccordionOpen = _useContext.isAccordionOpen,
    toggleAccordionOpen = _useContext.toggleAccordionOpen,
    showFrame = _useContext.showFrame,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    focused = _useState2[0],
    setFocused = _useState2[1],
    _uniqueInnerId = uniqueInnerId(id, "headingId", "contentId"),
    headingId = _uniqueInnerId.headingId,
    contentId = _uniqueInnerId.contentId,
    isOpen = isAccordionOpen(id),
    cardHeadingClass = cx(styles.cardHeading, cardHeadingClassName),
    cardBodyClass = cx(styles.cardBody, cardBodyClassName),
    accordionCardClass = cx((_defineProperty(_cx = {}, styles.accordionFrame, showFrame), _defineProperty(_cx, "isOpen", isOpen), _cx), className),
    accordionTitleClass = cx(styles.cardTitle, cardTitleClassName, (_defineProperty(_cx2 = {
      isOpen: isOpen
    }, styles.headerFocused, focused), _defineProperty(_cx2, styles.headerError, errorState), _defineProperty(_cx2, styles.accordionToggle, isCollapsible), _cx2));
  return React.createElement("div", {
    id: id,
    className: accordionCardClass
  }, React.createElement("button", _extends({
    id: headingId,
    className: cardHeadingClass,
    onFocus: function () {
      setFocused(!0);
    },
    onBlur: function () {
      setFocused(!1);
    },
    disabled: !isCollapsible,
    onClick: isCollapsible ? function (evt) {
      toggleAccordionOpen ? (toggleAccordionOpen(id), null == onCardHeadingClicked || onCardHeadingClicked(), evt.preventDefault()) : warning("The Card with id='".concat(id, "' was clicked but does not have an 'onToggleOpen' function"));
    } : void 0,
    "aria-controls": contentId,
    "aria-expanded": isOpen
  }, cardHeadingProps), React.createElement("span", {
    className: accordionTitleClass
  }, renderHeader ? renderHeader(isOpen, id, styles.header) : React.createElement(AccordionCardHeader, {
    isOpen: isOpen,
    chevron: chevron,
    chevronAlignment: chevronAlignment,
    errorState: errorState,
    title: title,
    isCollapsible: isCollapsible
  }))), React.createElement(Collapse, {
    id: contentId,
    isOpen: isOpen,
    "aria-hidden": !isOpen,
    className: collapseClassName,
    allowOverflowContent: allowOverflowContent,
    "aria-live": "assertive"
  }, React.createElement("div", {
    className: cardBodyClass
  }, children)));
};
var propTypes = {
  id: PropTypes.string.isRequired,
  chevron: PropTypes.bool,
  chevronAlignment: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
  collapseClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  cardTitleClassName: PropTypes.string,
  cardHeadingClassName: PropTypes.string,
  cardHeadingProps: PropTypes.shape({}),
  errorState: PropTypes.bool,
  title: intlMessageShape,
  renderHeader: PropTypes.func,
  isCollapsible: PropTypes.bool,
  allowOverflowContent: PropTypes.bool,
  onCardHeadingClicked: PropTypes.func
};
AccordionCard.propTypes = propTypes, AccordionCard.defaultProps = {
  chevron: !1,
  chevronAlignment: "left",
  errorState: !1,
  isCollapsible: !0
}, AccordionCard.displayName = "AccordionCard", AccordionCard.__docgenInfo = {
  description: "The `AccordionCard` component offers Card-like behavior inside an `Accordion`. It accepts\nmost of the same properties as Card (except 'isOpen' and 'onToggleOpen' which is managed\nby the containing Accordion).\n\n@example\n<Accordion>\n    <AccordionCard id=\"card1\">Card 1 Content</AccordionCard>\n    <AccordionCard id=\"card2\">Card 2 Content</AccordionCard>\n</Accordion>\n\n@metadataType container",
  methods: [],
  displayName: "AccordionCard",
  props: {
    chevron: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows a chevron in the header"
    },
    chevronAlignment: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Position of the chevron"
    },
    errorState: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Indicates if there is an error within the card contents. Changes visual appearience if set to true."
    },
    isCollapsible: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Indicates if the card should be collapsible"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the header"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for the whole card"
    },
    collapseClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for Collapse"
    },
    cardBodyClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for card body inside Collapse"
    },
    cardTitleClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for card header title"
    },
    cardHeadingClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for the card header"
    },
    cardHeadingProps: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Additional props passed to card header"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Message rendered as the title for the header"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a custom header to be displayed in the Accordion"
    },
    allowOverflowContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Allow content to overflow container size"
    },
    onCardHeadingClicked: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when card heading is clicked"
    }
  }
}, AccordionCard.__docgenInfo = {
  componentName: "AccordionCard",
  packageName: "@jutro/components",
  description: "The `AccordionCard` component offers Card-like behavior inside an `Accordion`. It accepts\nmost of the same properties as Card (except 'isOpen' and 'onToggleOpen' which is managed\nby the containing Accordion).",
  displayName: "AccordionCard",
  methods: [],
  actualName: "AccordionCard",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the header"
    },
    chevron: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows a chevron in the header",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    chevronAlignment: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Position of the chevron",
      defaultValue: {
        value: "'left'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for the whole card"
    },
    collapseClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for Collapse"
    },
    cardBodyClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for card body inside Collapse"
    },
    cardTitleClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for card header title"
    },
    cardHeadingClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional styles for the card header"
    },
    cardHeadingProps: {
      type: {
        name: "shape",
        value: {}
      },
      required: !1,
      description: "Additional props passed to card header"
    },
    errorState: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Indicates if there is an error within the card contents. Changes visual appearience if set to true.",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Message rendered as the title for the header"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render a custom header to be displayed in the Accordion"
    },
    isCollapsible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Indicates if the card should be collapsible",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    allowOverflowContent: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Allow content to overflow container size"
    },
    onCardHeadingClicked: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when card heading is clicked"
    }
  }
};