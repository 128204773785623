import { defineMessages } from "react-intl";
export var multipleFileUploadMessages = defineMessages({
  fileLimitMessage: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.fileLimitMessage",
    defaultMessage: "Number of files that can be uploaded: {fileLimit}"
  },
  fileLimitMessagePrefix: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.fileLimitMessagePrefix",
    defaultMessage: "File upload limit exceeded."
  },
  duplicatedFileError: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.duplicatedFileError",
    defaultMessage: "You cannot upload the same file more than once"
  },
  emptyLimitedUploadAreaMessage: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.limited.emptyUploadAreaMessage",
    defaultMessage: "Drag and drop files here. Limit: {fileLimit}"
  },
  emptyUnlimitedUploadAreaMessage: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.unlimited.emptyUploadAreaMessage",
    defaultMessage: "Drag and drop files here"
  },
  uploadFilesMessage: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.uploadFilesMessage",
    defaultMessage: "Upload files"
  }
});