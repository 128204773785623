import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["className", "contentContainerClassName", "labelContainerClassName", "showTime", "tooltipTime", "placeholderTime", "labelTime", "hideLabelTime", "secondaryLabelTime", "showTimeZone", "labelTimeZone", "secondaryLabelTimeZone", "tooltipTimeZone", "hideLabelTimeZone", "timeZones", "defaultTimeZone", "id", "dataType", "value", "defaultValue", "path", "model", "label", "hideLabel", "dataPath", "onValueChange"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { publish, JUTRO_TOPICS } from "@jutro/events";
import { GridLayout } from "@jutro/layout";
import { LocaleContext } from "@jutro/locale";
import { DATA_TYPE_OBJECT, dateTimeZoneValueShape, intlMessageShape, nestedTooltipShape } from "@jutro/prop-types";
import { FieldComponent } from "../../FieldComponent/FieldComponent";
import { DateField } from "../DateField/DateField";
import styles from "./DateTimeZoneField.module.css";
import { DateTimeField } from "../DateTimeField/DateTimeField";
import { TimeZoneField } from "./TimeZoneField";
import { useTimeZoneDropdownValues } from "./useTimeZoneDropdownValues";
import { parseDateTimeZoneFromDataType, formatDateTimeZoneToDataType } from "./helpers";
import { timezones } from "./timezones";
var timezonesList = _Object$keys(timezones),
  dateTimeZoneFieldPropTypes = _objectSpread(_objectSpread({}, DateTimeField.propTypes), {}, {
    value: dateTimeZoneValueShape,
    defaultValue: dateTimeZoneValueShape,
    showTimeZone: PropTypes.bool,
    labelTimeZone: intlMessageShape,
    secondaryLabelTimeZone: intlMessageShape,
    tooltipTimeZone: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
    hideLabelTimeZone: PropTypes.bool,
    timeZones: PropTypes.arrayOf(PropTypes.oneOf(timezonesList).isRequired),
    defaultTimeZone: PropTypes.oneOf(timezonesList)
  });
export var DateTimeZoneField = function (props) {
  var disabled,
    className = props.className,
    contentContainerClassName = props.contentContainerClassName,
    labelContainerClassName = props.labelContainerClassName,
    showTime = props.showTime,
    tooltipTime = props.tooltipTime,
    placeholderTime = props.placeholderTime,
    labelTime = props.labelTime,
    hideLabelTime = props.hideLabelTime,
    secondaryLabelTime = props.secondaryLabelTime,
    showTimeZone = props.showTimeZone,
    labelTimeZone = props.labelTimeZone,
    secondaryLabelTimeZone = props.secondaryLabelTimeZone,
    tooltipTimeZone = props.tooltipTimeZone,
    hideLabelTimeZone = props.hideLabelTimeZone,
    timeZones = props.timeZones,
    defaultTimeZone = props.defaultTimeZone,
    id = props.id,
    _props$dataType = props.dataType,
    dataType = void 0 === _props$dataType ? DATA_TYPE_OBJECT : _props$dataType,
    _props$value = props.value,
    valueProp = void 0 === _props$value ? {} : _props$value,
    _props$defaultValue = props.defaultValue,
    defaultValue = void 0 === _props$defaultValue ? {} : _props$defaultValue,
    path = props.path,
    model = props.model,
    label = props.label,
    dataPath = (props.hideLabel, props.dataPath),
    onValueChange = props.onValueChange,
    commonFieldProps = _objectWithoutProperties(props, _excluded),
    _useTimeZoneDropdownV = useTimeZoneDropdownValues(timeZones),
    _useTimeZoneDropdownV2 = _slicedToArray(_useTimeZoneDropdownV, 2),
    currentTimeZone = _useTimeZoneDropdownV2[0],
    timeZoneValues = _useTimeZoneDropdownV2[1],
    appDefaultTimezone = useContext(LocaleContext).defaultTimeZone,
    parsedValue = parseDateTimeZoneFromDataType(isEmpty(valueProp) ? defaultValue : valueProp, defaultTimeZone || appDefaultTimezone || currentTimeZone, showTime),
    labelOnTop = "top" === commonFieldProps.labelPosition,
    handleChange = function (value) {
      var newValue = _objectSpread(_objectSpread({}, parsedValue), value),
        formattedValue = formatDateTimeZoneToDataType(newValue, dataType, showTime);
      onValueChange && (onValueChange(formattedValue, model || path, {
        id: id,
        valueProp: valueProp,
        dataPath: dataPath
      }), publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread(_objectSpread({}, props), {}, {
        value: formattedValue
      }))));
    };
  return React.createElement(GridLayout, {
    id: id,
    className: className,
    columns: labelOnTop ? ["2fr", "auto"] : [],
    hgap: "small",
    vgap: labelOnTop ? "none" : void 0,
    phone: {
      columns: [],
      vgap: void 0
    },
    style: {
      gridTemplateRows: "min-content"
    },
    defaultGridItem: {
      className: cx(_defineProperty({}, styles.fieldContainer, labelOnTop)),
      phone: {
        className: void 0
      }
    }
  }, React.createElement(DateTimeField, _extends({}, commonFieldProps, {
    id: "".concat(id, "-datetime"),
    label: label,
    showTime: showTime,
    dataType: DATA_TYPE_OBJECT,
    onValueChange: function (datetime) {
      return handleChange(_objectSpread(_objectSpread({}, parsedValue), {}, {
        datetime: datetime
      }));
    },
    value: parsedValue.datetime,
    tooltipTime: tooltipTime,
    placeholderTime: placeholderTime,
    labelTime: labelTime,
    hideLabelTime: hideLabelTime,
    secondaryLabelTime: secondaryLabelTime,
    contentContainerClassName: contentContainerClassName,
    labelContainerClassName: labelContainerClassName,
    preserveTimeZone: !0
  })), showTimeZone && (disabled = props.disabled, React.createElement(TimeZoneField, _extends({}, commonFieldProps, {
    id: "".concat(id, "-timezone"),
    availableValues: timeZoneValues,
    value: parsedValue.timezone,
    disabled: disabled,
    label: labelTimeZone,
    secondaryLabel: secondaryLabelTimeZone,
    hideLabel: hideLabelTimeZone,
    tooltip: tooltipTimeZone,
    onValueChange: function (timezone) {
      return handleChange(_objectSpread(_objectSpread({}, parsedValue), {}, {
        timezone: timezone
      }));
    },
    contentContainerClassName: cx(_defineProperty({}, styles.timeZoneField, labelOnTop), _defineProperty({}, styles.disabled, disabled), contentContainerClassName),
    labelContainerClassName: cx(_defineProperty({}, styles.timeZoneLabel, labelOnTop), labelContainerClassName)
  }))));
};
DateTimeZoneField.displayName = "DateTimeZoneField", DateTimeZoneField.propTypes = dateTimeZoneFieldPropTypes, DateTimeZoneField.defaultProps = _objectSpread(_objectSpread({}, DateField.defaultProps), {}, {
  dataType: DATA_TYPE_OBJECT,
  showTime: !0,
  showTimeZone: !0
}), DateTimeZoneField.__docgenInfo = {
  description: "@metadataType field",
  methods: [],
  displayName: "DateTimeZoneField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      required: !1
    },
    showTime: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    showTimeZone: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show timezone picker"
    },
    value: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: !1,
      description: "Value to display"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: !1,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    labelTimeZone: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for time zone field label"
    },
    secondaryLabelTimeZone: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for time zone field secondary label (same as secondaryLabel prop by default)"
    },
    tooltipTimeZone: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip for time zone field (same as tooltip prop by default)"
    },
    hideLabelTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide time zone label (same as hideLabel by default)"
    },
    timeZones: {
      type: {
        name: "arrayOf",
        value: {
          name: "enum",
          computed: !0,
          value: "timezonesList"
        }
      },
      required: !1,
      description: "Time zones to show in the dropdown"
    },
    defaultTimeZone: {
      type: {
        name: "enum",
        computed: !0,
        value: "timezonesList"
      },
      required: !1,
      description: "Default time zone to be used (local by default)"
    }
  },
  composes: ["../DateTimeField/DateTimeField"]
}, DateTimeZoneField.__docgenInfo = {
  componentName: "DateTimeZoneField",
  packageName: "@jutro/components",
  description: "",
  displayName: "DateTimeZoneField",
  methods: [],
  actualName: "DateTimeZoneField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: !1,
      description: "Value to display"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: !1,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    showTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show timezone picker",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    labelTimeZone: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for time zone field label"
    },
    secondaryLabelTimeZone: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for time zone field secondary label (same as secondaryLabel prop by default)"
    },
    tooltipTimeZone: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip for time zone field (same as tooltip prop by default)"
    },
    hideLabelTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide time zone label (same as hideLabel by default)"
    },
    timeZones: {
      type: {
        name: "arrayOf",
        value: {
          name: "enum",
          computed: !0,
          value: "timezonesList"
        }
      },
      required: !1,
      description: "Time zones to show in the dropdown"
    },
    defaultTimeZone: {
      type: {
        name: "enum",
        computed: !0,
        value: "timezonesList"
      },
      required: !1,
      description: "Default time zone to be used (local by default)"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      required: !1
    },
    showTime: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../DateTimeField/DateTimeField"]
};