import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import styles from "./HeaderActions.module.css";
export var HeaderActions = function (_ref) {
  var children = _ref.children,
    className = _ref.className,
    noDeprecationWarnings = _ref.noDeprecationWarnings,
    classes = cx(styles.headerActions, className);
  return useDeprecationWarning({
    componentName: "HeaderActions",
    noDeprecationWarnings: noDeprecationWarnings
  }), React.createElement("div", {
    className: classes
  }, children);
};
var headerActionsPropTypes = {
  className: PropTypes.string
};
HeaderActions.propTypes = headerActionsPropTypes, HeaderActions.__docgenInfo = {
  description: "@metadataType container\n@deprecated since v8.9.0",
  methods: [],
  displayName: "HeaderActions",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    }
  }
}, HeaderActions.__docgenInfo = {
  componentName: "HeaderActions",
  packageName: "@jutro/components",
  description: "",
  displayName: "HeaderActions",
  methods: [],
  actualName: "HeaderActions",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    }
  }
};