import React from "react";
import loadable from "@loadable/component";
var IntlPhoneNumberInputLazy = loadable(function () {
    return import("./IntlPhoneNumberInput").then(function (el) {
      return el.IntlPhoneNumberInput;
    });
  }),
  IntlPhoneNumberReadOnlyLazy = loadable(function () {
    return import("./values/PhoneNumberValue").then(function (el) {
      return el.PhoneNumberValue;
    });
  });
export var IntlPhoneNumberInputLazyLoaded = function (props) {
  return React.createElement(IntlPhoneNumberInputLazy, props);
};
export var IntlPhoneNumberReadOnlyLazyLoaded = function (props) {
  return React.createElement(IntlPhoneNumberReadOnlyLazy, props);
};
IntlPhoneNumberInputLazyLoaded.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "IntlPhoneNumberInputLazyLoaded"
}, IntlPhoneNumberReadOnlyLazyLoaded.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "IntlPhoneNumberReadOnlyLazyLoaded"
}, IntlPhoneNumberInputLazyLoaded.__docgenInfo = {
  componentName: "IntlPhoneNumberInputLazyLoaded",
  packageName: "@jutro/components",
  description: "",
  displayName: "IntlPhoneNumberInputLazyLoaded",
  methods: [],
  actualName: "IntlPhoneNumberInputLazyLoaded"
}, IntlPhoneNumberReadOnlyLazyLoaded.__docgenInfo = {
  componentName: "IntlPhoneNumberReadOnlyLazyLoaded",
  packageName: "@jutro/components",
  description: "",
  displayName: "IntlPhoneNumberReadOnlyLazyLoaded",
  methods: [],
  actualName: "IntlPhoneNumberReadOnlyLazyLoaded"
};