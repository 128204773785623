import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { getConfigValue } from "@jutro/config";
import { warning, info } from "@jutro/logger";
import { fetchTsmMessages } from "./fetchTsmMessages";
export var tsmMessageLoader = (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(language) {
  var _context, _context2, worksetUid, stringVersion, stringGroup, jutroVersion;
  return _regeneratorRuntime.wrap(function (_context3) {
    for (;;) switch (_context3.prev = _context3.next) {
      case 0:
        return worksetUid = getConfigValue("JUTRO_TSM_WORKSET_UID", "active"), stringVersion = getConfigValue("JUTRO_TSM_STRING_VERSION"), stringGroup = getConfigValue("JUTRO_TSM_STRING_GROUP"), jutroVersion = getConfigValue("JUTRO_CORE_STRING_VERSION"), info(_concatInstanceProperty(_context = _concatInstanceProperty(_context2 = 'TSM enabled: Loading messages for "'.concat(language, '" language, worksetUid: "')).call(_context2, worksetUid, '", string group: "')).call(_context, stringGroup, ":.")), _context3.prev = 5, _context3.next = 8, fetchTsmMessages(language, worksetUid, stringGroup, void 0, stringVersion, jutroVersion);
      case 8:
        return _context3.abrupt("return", _context3.sent);
      case 11:
        return _context3.prev = 11, _context3.t0 = _context3.catch(5), warning("TSM: Error loading translations. Details: ".concat(_context3.t0)), _context3.abrupt("return", {});
      case 15:
      case "end":
        return _context3.stop();
    }
  }, _callee, null, [[5, 11]]);
})), function (_x) {
  return _ref.apply(this, arguments);
});
var _ref;