import { defineMessages } from "react-intl";
export var messages = defineMessages({
  confirmButtonText: {
    id: "jutro-components.modal.ConfirmationModal.confirmButtonText",
    defaultMessage: "OK"
  },
  cancelButtonText: {
    id: "jutro-components.modal.ConfirmationModal.cancelButtonText",
    defaultMessage: "Cancel"
  },
  closeIconButtonAriaLabel: {
    id: "jutro-components.modal.ConfirmationModal.closeIconButtonAriaLabel",
    defaultMessage: "Close Modal"
  }
});