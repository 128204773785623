import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import { TYPE_THIN, TYPE_LARGE } from "./utils";
import styles from "./FileUploadField.module.css";
import { MultipleUploadField } from "./MultipleFilesUploadField/MultipleFilesUploadField";
import { SingleFileUploadField } from "./SingleFileUploadField/SingleFileUploadField";
export var FileUploadFieldInternal = function (_ref) {
  var _cx,
    value = _ref.value,
    maxLength = _ref.maxLength,
    disabled = _ref.disabled,
    controlClassName = _ref.controlClassName,
    required = _ref.required,
    accept = _ref.accept,
    messageProps = _ref.messageProps,
    type = _ref.type,
    imageSource = _ref.imageSource,
    buttonType = _ref.buttonType,
    buttonSize = _ref.buttonSize,
    buttonIcon = _ref.buttonIcon,
    id = _ref.id,
    onChange = _ref.onChange,
    testId = _ref.testId,
    fieldUniqueId = _ref.fieldUniqueId,
    breakpoint = _ref.breakpoint,
    disableDragAndDropProp = _ref.disableDragAndDrop,
    uploadState = _ref.uploadState,
    onCancel = _ref.onCancel,
    completed = _ref.completed,
    total = _ref.total,
    onFileClear = _ref.onFileClear,
    onInputFocus = _ref.onInputFocus,
    onInputBlur = _ref.onInputBlur,
    dataPath = _ref.dataPath,
    inputA11yProps = _ref.inputA11yProps,
    renderValidationMessages = _ref.renderValidationMessages,
    maxNumberOfFiles = _ref.maxNumberOfFiles,
    maxFileSizeKB = _ref.maxFileSizeKB,
    onUpload = _ref.onUpload,
    uploadErrors = _ref.uploadErrors,
    isMultipleFileUpload = 1 !== maxNumberOfFiles,
    disableDragAndDrop = null != disableDragAndDropProp ? disableDragAndDropProp : !("desktop" === breakpoint),
    wrapperClasses = cx(styles.wrapper, (_defineProperty(_cx = {}, styles.wrapperLarge, type === TYPE_LARGE), _defineProperty(_cx, styles.wrapperThin, type === TYPE_THIN), _defineProperty(_cx, styles.wrapperThinWithUpload, type === TYPE_THIN && value), _defineProperty(_cx, styles.wrapperWithDragAndDropDisabled, disableDragAndDrop && (type === TYPE_THIN && !value || type === TYPE_LARGE)), _cx), controlClassName),
    multipleFileUploadWrapperClasses = cx(styles.wrapper, styles.wrapperLarge, controlClassName);
  return isMultipleFileUpload ? React.createElement(MultipleUploadField, {
    id: id,
    dataPath: dataPath,
    onChange: onChange,
    fieldUniqueId: fieldUniqueId,
    breakpoint: breakpoint,
    onFileClear: onFileClear,
    onInputFocus: onInputFocus,
    completed: completed,
    total: total,
    uploadState: uploadState,
    onCancel: onCancel,
    disabled: disabled,
    messageProps: messageProps,
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    imageSource: imageSource,
    className: styles.fileUploadField,
    wrapperClassName: multipleFileUploadWrapperClasses,
    onInputBlur: onInputBlur,
    inputA11yProps: inputA11yProps,
    renderValidationMessages: renderValidationMessages,
    maxNumberOfFiles: maxNumberOfFiles,
    maxLength: maxLength,
    maxFileSizeKB: maxFileSizeKB,
    accept: accept,
    onUpload: onUpload,
    value: value,
    uploadErrors: uploadErrors
  }) : React.createElement(SingleFileUploadField, {
    id: id,
    onChange: onChange,
    fieldUniqueId: fieldUniqueId,
    breakpoint: breakpoint,
    onFileClear: onFileClear,
    onInputFocus: onInputFocus,
    completed: completed,
    total: total,
    uploadState: uploadState,
    onCancel: onCancel,
    disabled: disabled,
    messageProps: messageProps,
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    disableDragAndDrop: disableDragAndDrop,
    imageSource: imageSource,
    className: styles.fileUploadField,
    wrapperClassName: wrapperClasses,
    onInputBlur: onInputBlur,
    inputA11yProps: inputA11yProps,
    renderValidationMessages: renderValidationMessages,
    type: type,
    dataPath: dataPath,
    testId: testId,
    accept: accept,
    required: required,
    maxLength: maxLength,
    value: value
  });
};
FileUploadFieldInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FileUploadFieldInternal"
}, FileUploadFieldInternal.__docgenInfo = {
  componentName: "FileUploadFieldInternal",
  packageName: "@jutro/components",
  description: "",
  displayName: "FileUploadFieldInternal",
  methods: [],
  actualName: "FileUploadFieldInternal"
};