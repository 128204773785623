import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import FocusLock from "react-focus-lock";
import { useTranslator } from "@jutro/locale";
import { IconButton } from "@jutro/components";
import { usePathname } from "@jutro/platform";
import noop from "lodash/noop";
import styles from "./BurgerMenu.module.css";
import { messages } from "./BurgerMenu.messages";
import { BurgerMenuItem } from "./BurgerMenuItem";
import { BurgerMenuContext } from "./BurgerMenuContext";
export var BurgerSidebar = function (_ref) {
  var isOpen = _ref.isOpen,
    burgerContent = _ref.burgerContent,
    separatorClassName = _ref.separatorClassName,
    translator = useTranslator(),
    visibilityStyles = _defineProperty({}, styles.isClosed, !isOpen),
    sidebarClassNames = cx(styles.burgerSidebar, visibilityStyles, separatorClassName),
    backdropClassNames = cx(styles.backdrop, visibilityStyles),
    _useContext$closeMenu = useContext(BurgerMenuContext).closeMenu,
    closeMenu = void 0 === _useContext$closeMenu ? noop : _useContext$closeMenu,
    pathname = usePathname();
  useEffect(closeMenu, [pathname]);
  var burgerSidebarHeader = React.createElement(BurgerMenuItem, {
    id: "burger-menu-side-bar-header",
    className: styles.sidebarHeader,
    hideSeparator: !0,
    isContainerOnly: !0
  }, React.createElement("span", {
    className: styles.menu
  }, translator(messages.menuHeaderTitle)), React.createElement(IconButton, {
    icon: "gw-close",
    iconColor: "dark",
    onClick: closeMenu,
    "aria-label": translator(messages.closeNavigationLabel),
    noDeprecationWarnings: ["IconButton"]
  }));
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: sidebarClassNames,
    "aria-hidden": !isOpen
  }, React.createElement(FocusLock, {
    noFocusGuards: !0,
    whiteList: function (node) {
      var _document$getElementB, _document$getElementB2;
      return null !== (_document$getElementB = null === (_document$getElementB2 = document.getElementById("appSwitcherModal")) || void 0 === _document$getElementB2 ? void 0 : _document$getElementB2.contains(node)) && void 0 !== _document$getElementB && _document$getElementB;
    }
  }, burgerSidebarHeader, burgerContent)), React.createElement("div", {
    className: backdropClassNames,
    onClick: closeMenu,
    role: "presentation"
  }));
};
BurgerSidebar.propTypes = {
  isOpen: PropTypes.bool,
  burgerContent: PropTypes.arrayOf(PropTypes.node),
  separatorClassName: PropTypes.string
}, BurgerSidebar.__docgenInfo = {
  description: "BurgerSidebar renders content of burger menu in a sidebar\n\n@metadataType container",
  methods: [],
  displayName: "BurgerSidebar",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sidebar state"
    },
    burgerContent: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: !1,
      description: "Sidebar content"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger sidebar separator"
    }
  }
}, BurgerSidebar.__docgenInfo = {
  componentName: "BurgerSidebar",
  packageName: "@jutro/router",
  description: "BurgerSidebar renders content of burger menu in a sidebar",
  displayName: "BurgerSidebar",
  methods: [],
  actualName: "BurgerSidebar",
  metadataType: "container",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sidebar state"
    },
    burgerContent: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: !1,
      description: "Sidebar content"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger sidebar separator"
    }
  }
};