import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _cardFactoryMap,
  _excluded = ["id", "code", "name", "iconName", "secondaryLabel", "moneyAmount", "currency", "disabled", "checked", "onValueSelect", "layout", "styles", "iconSrc", "iconClassName", "tabIndex", "iconContainerClassName", "hideValue", "noDeprecationWarnings"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import cx from "classnames";
import { uniqueInnerId } from "@jutro/platform";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { getKeyPressHandler } from "../../../accessibility/getKeyPressHandler";
import { Icon } from "../../Icon/Icon";
import { CurrencyValue } from "../../values/CurrencyValue";
export var PORTRAIT_LAYOUT = "portrait";
export var LANDSCAPE_LAYOUT = "landscape";
export var LAYOUT_PROP_TYPE = PropTypes.oneOf([PORTRAIT_LAYOUT, LANDSCAPE_LAYOUT]);
var renderCardIcon = function (props) {
    var styles = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      iconName = props.iconName,
      iconSrc = props.iconSrc,
      iconContainerClasses = props.iconContainerClasses,
      iconContentClasses = props.iconContentClasses;
    return React.createElement("div", {
      className: iconContainerClasses
    }, iconSrc ? React.createElement("img", {
      src: iconSrc,
      alt: iconName,
      className: cx(styles.imageIcon, iconContentClasses)
    }) : React.createElement(Icon, {
      icon: iconName,
      className: iconContentClasses,
      size: "large"
    }));
  },
  cardFactoryMap = (_defineProperty(_cardFactoryMap = {}, PORTRAIT_LAYOUT, {
    renderContent: function (radioButton, _ref, _ref2, _ref3) {
      var name = _ref.name,
        moneyAmount = _ref.moneyAmount,
        secondaryLabel = _ref.secondaryLabel,
        iconName = _ref.iconName,
        currency = _ref.currency,
        iconSrc = _ref.iconSrc,
        hideValue = _ref.hideValue,
        styles = _ref2.styles,
        iconContentClasses = _ref2.iconContentClasses,
        iconContainerClasses = _ref2.iconContainerClasses,
        nameId = _ref3.nameId,
        secondaryLabelId = _ref3.secondaryLabelId,
        moneyId = _ref3.moneyId;
      return React.createElement("div", {
        className: styles.cardWrapperPortrait
      }, renderCardIcon({
        iconName: iconName,
        iconSrc: iconSrc,
        iconContainerClasses: iconContainerClasses,
        iconContentClasses: iconContentClasses
      }, styles), !hideValue && React.createElement(CurrencyValue, {
        id: moneyId,
        amount: moneyAmount,
        currency: currency,
        showFractions: !0,
        className: styles.moneyAmount,
        hideValue: hideValue
      }), React.createElement("div", {
        id: nameId,
        className: styles.namePortrait
      }, name), React.createElement("div", {
        id: secondaryLabelId,
        className: styles.secondaryLabelPortrait
      }, secondaryLabel), radioButton);
    },
    getIconContentClasses: function (styles) {
      return cx(styles.iconContentPortrait);
    },
    getIconContainerClasses: function (styles) {
      return cx(styles.iconContainerPortrait);
    },
    getIconWrapperClasses: function (styles, checked) {
      return cx(styles.radioWrapper, styles.radioWrapperPortrait, _defineProperty({}, styles.radioWrapperChecked, checked));
    },
    getCardClasses: function (styles, checked, disabled, _ref4) {
      var focused = _ref4.focused,
        active = _ref4.active;
      return cx(styles.cardPortrait, _defineProperty({}, styles.cardChecked, checked), _defineProperty({}, styles.disabled, disabled), _defineProperty({}, styles.cardFocused, focused), _defineProperty({}, styles.cardActive, active));
    }
  }), _defineProperty(_cardFactoryMap, LANDSCAPE_LAYOUT, {
    renderContent: function (radioButton, _ref5, _ref6, _ref7) {
      var name = _ref5.name,
        moneyAmount = _ref5.moneyAmount,
        secondaryLabel = _ref5.secondaryLabel,
        iconName = _ref5.iconName,
        currency = _ref5.currency,
        iconSrc = _ref5.iconSrc,
        hideValue = _ref5.hideValue,
        styles = _ref6.styles,
        iconContentClasses = _ref6.iconContentClasses,
        iconContainerClasses = _ref6.iconContainerClasses,
        nameId = _ref7.nameId,
        secondaryLabelId = _ref7.secondaryLabelId,
        moneyId = _ref7.moneyId;
      return React.createElement("div", {
        className: styles.cardWrapperLandscape
      }, radioButton, renderCardIcon({
        iconName: iconName,
        iconSrc: iconSrc,
        iconContainerClasses: iconContainerClasses,
        iconContentClasses: iconContentClasses
      }, styles), React.createElement("div", {
        className: styles.contentWrapperLandscape
      }, !hideValue && React.createElement(CurrencyValue, {
        id: moneyId,
        amount: moneyAmount,
        currency: currency,
        showFractions: !0,
        className: styles.moneyAmount,
        hideValue: hideValue
      }), React.createElement("div", {
        id: nameId,
        className: styles.nameLandscape
      }, name), React.createElement("div", {
        id: secondaryLabelId,
        className: styles.secondaryLabelLandscape
      }, secondaryLabel)));
    },
    getIconContentClasses: function (styles) {
      return cx(styles.iconContentLandscape);
    },
    getIconContainerClasses: function (styles) {
      return cx(styles.iconContainerLandscape);
    },
    getIconWrapperClasses: function (styles, checked) {
      return cx(styles.radioWrapper, styles.radioWrapperLandscape, _defineProperty({}, styles.radioWrapperChecked, checked));
    },
    getCardClasses: function (styles, checked, disabled, _ref8) {
      var focused = _ref8.focused,
        active = _ref8.active;
      return cx(styles.cardLandscape, _defineProperty({}, styles.cardChecked, checked), _defineProperty({}, styles.disabled, disabled), _defineProperty({}, styles.cardFocused, focused), _defineProperty({}, styles.cardActive, active));
    }
  }), _cardFactoryMap);
export var RadioButtonCard = function (_Component) {
  _inherits(RadioButtonCard, Component);
  var _super = _createSuper(RadioButtonCard);
  function RadioButtonCard() {
    var _context, _this;
    _classCallCheck(this, RadioButtonCard);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "state", {
      focused: !1
    }), _defineProperty(_assertThisInitialized(_this), "handleClick", function () {
      var _this$props = _this.props,
        disabled = _this$props.disabled,
        code = _this$props.code,
        model = _this$props.model,
        path = _this$props.path,
        onValueSelect = _this$props.onValueSelect;
      disabled || onValueSelect(code, model || path);
    }), _defineProperty(_assertThisInitialized(_this), "handleKeyPress", getKeyPressHandler(_this.handleClick)), _defineProperty(_assertThisInitialized(_this), "handleBlur", function () {
      _this.setState({
        focused: !1
      });
    }), _defineProperty(_assertThisInitialized(_this), "activateCard", function (e) {
      " " === e.key && _this.setActive(!0);
    }), _defineProperty(_assertThisInitialized(_this), "deactivateCard", function (e) {
      " " === e.key && _this.setActive(!1);
    }), _this;
  }
  return _createClass(RadioButtonCard, [{
    key: "setActive",
    value: function (active) {
      this.setState({
        active: active
      });
    }
  }, {
    key: "render",
    value: function () {
      var _context2,
        _context3,
        _this$props2 = this.props,
        id = _this$props2.id,
        code = _this$props2.code,
        name = _this$props2.name,
        iconName = _this$props2.iconName,
        secondaryLabel = _this$props2.secondaryLabel,
        moneyAmount = _this$props2.moneyAmount,
        currency = _this$props2.currency,
        disabled = _this$props2.disabled,
        checked = _this$props2.checked,
        layout = (_this$props2.onValueSelect, _this$props2.layout),
        styles = _this$props2.styles,
        iconSrc = _this$props2.iconSrc,
        iconClassName = _this$props2.iconClassName,
        tabIndex = _this$props2.tabIndex,
        iconContainerClassName = _this$props2.iconContainerClassName,
        hideValue = _this$props2.hideValue,
        other = (_this$props2.noDeprecationWarnings, _objectWithoutProperties(_this$props2, _excluded)),
        _ref9 = cardFactoryMap[layout] || cardFactoryMap[PORTRAIT_LAYOUT],
        getIconWrapperClasses = _ref9.getIconWrapperClasses,
        getCardClasses = _ref9.getCardClasses,
        renderContent = _ref9.renderContent,
        getIconContentClasses = _ref9.getIconContentClasses,
        getIconContainerClasses = _ref9.getIconContainerClasses,
        currentTabIndex = disabled ? void 0 : tabIndex,
        iconWrapperClasses = getIconWrapperClasses(styles, checked),
        cardClasses = getCardClasses(styles, checked, disabled, this.state),
        iconContentClasses = cx(getIconContentClasses(styles), iconClassName),
        iconContainerClasses = cx(getIconContainerClasses(styles), iconContainerClassName),
        _uniqueInnerId = uniqueInnerId(id, "valueId", "nameId", "secondaryLabelId", "moneyId"),
        valueId = _uniqueInnerId.valueId,
        nameId = _uniqueInnerId.nameId,
        secondaryLabelId = _uniqueInnerId.secondaryLabelId,
        moneyId = _uniqueInnerId.moneyId,
        radioButton = React.createElement(React.Fragment, null, React.createElement("input", _extends({
          "aria-hidden": !0,
          type: "radio",
          id: valueId,
          className: styles.radioInput,
          value: code,
          checked: checked,
          disabled: disabled
        }, other, {
          tabIndex: -1,
          onChange: this.handleClick
        })), React.createElement("div", {
          className: iconWrapperClasses
        }));
      return React.createElement("div", {
        key: valueId,
        role: "radio",
        "aria-disabled": disabled,
        "aria-checked": checked,
        "aria-labelledby": _concatInstanceProperty(_context2 = _concatInstanceProperty(_context3 = "".concat(nameId, " ")).call(_context3, secondaryLabelId, " ")).call(_context2, moneyId),
        className: cardClasses,
        "data-value": code,
        onClick: this.handleClick,
        onKeyPress: this.handleKeyPress,
        onKeyDown: this.activateCard,
        onKeyUp: this.deactivateCard,
        onBlur: this.handleBlur,
        tabIndex: currentTabIndex
      }, renderContent(radioButton, {
        name: name,
        secondaryLabel: secondaryLabel,
        moneyAmount: moneyAmount,
        iconName: iconName,
        currency: currency,
        iconSrc: iconSrc,
        hideValue: hideValue
      }, {
        styles: styles,
        iconContentClasses: iconContentClasses,
        iconContainerClasses: iconContainerClasses
      }, {
        nameId: nameId,
        secondaryLabelId: secondaryLabelId,
        moneyId: moneyId
      }));
    }
  }]), RadioButtonCard;
}();
_defineProperty(RadioButtonCard, "propTypes", {
  code: intlMessageShape.isRequired,
  name: intlMessageShape.isRequired,
  secondaryLabel: intlMessageShape.isRequired,
  moneyAmount: PropTypes.number,
  tabIndex: PropTypes.number,
  currency: PropTypes.string,
  layout: LAYOUT_PROP_TYPE,
  hideValue: PropTypes.bool
}), _defineProperty(RadioButtonCard, "defaultProps", {
  layout: PORTRAIT_LAYOUT
}), RadioButtonCard.__docgenInfo = {
  description: "Renders a radio button displayed as a card.\n@typedef {typeof RadioButtonCard.propTypes} RadioButtonCardPropTypes\n@extends Component<PropTypes.InferProps<RadioButtonCardPropTypes>>",
  methods: [{
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleBlur",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "setActive",
    docblock: null,
    modifiers: [],
    params: [{
      name: "active",
      type: null
    }],
    returns: null
  }, {
    name: "activateCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      type: null
    }],
    returns: null
  }, {
    name: "deactivateCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      type: null
    }],
    returns: null
  }],
  displayName: "RadioButtonCard",
  props: {
    layout: {
      defaultValue: {
        value: "'portrait'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "LAYOUT_PROP_TYPE"
      },
      required: !1,
      description: "Card layout"
    },
    code: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Radio button code"
    },
    name: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Name displayed below the radio button"
    },
    secondaryLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Secondary label displayed below the name"
    },
    moneyAmount: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Money amount"
    },
    tabIndex: {
      type: {
        name: "number"
      },
      required: !1,
      description: "TabIndex of the Radio Button"
    },
    currency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Currency code"
    },
    hideValue: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide value if set to true"
    }
  }
}, renderCardIcon.__docgenInfo = {
  componentName: "renderCardIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderCardIcon",
  methods: [],
  actualName: "renderCardIcon"
}, RadioButtonCard.__docgenInfo = {
  componentName: "RadioButtonCard",
  packageName: "@jutro/components",
  description: "Renders a radio button displayed as a card.",
  displayName: "RadioButtonCard",
  methods: [{
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleBlur",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "setActive",
    docblock: null,
    modifiers: [],
    params: [{
      name: "active",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "activateCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "deactivateCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      optional: !1,
      type: null
    }],
    returns: null
  }],
  actualName: "RadioButtonCard",
  props: {
    code: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Radio button code"
    },
    name: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Name displayed below the radio button"
    },
    secondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Secondary label displayed below the name"
    },
    moneyAmount: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Money amount"
    },
    tabIndex: {
      type: {
        name: "number"
      },
      required: !1,
      description: "TabIndex of the Radio Button"
    },
    currency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Currency code"
    },
    layout: {
      type: {
        name: "custom",
        raw: "LAYOUT_PROP_TYPE"
      },
      required: !1,
      description: "Card layout",
      defaultValue: {
        value: "'portrait'",
        computed: !1
      }
    },
    hideValue: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide value if set to true"
    }
  }
};