import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["rows"];
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { useMemo } from "react";
import identity from "lodash/identity";
import { useControlledReducer, bindActionCreators } from "../helper";
import { reducer } from "./reducer";
import * as actions from "./actions";
export function useSelection(initialState, selectedRows, selectionType, onSelectionChange) {
  var updateState = selectedRows && function (oldState) {
      return _objectSpread(_objectSpread({}, oldState), {}, {
        rows: selectedRows
      });
    },
    _useControlledReducer = useControlledReducer({
      reducer: reducer,
      initialState: initialState,
      controlledState: withNormalizedRows(updateState, selectionType),
      onStateChange: function (_ref) {
        var rows = _ref.rows;
        onSelectionChange && rows !== state.rows && onSelectionChange(rows);
      },
      updateInControlledMode: !updateState
    }),
    _useControlledReducer2 = _slicedToArray(_useControlledReducer, 2),
    state = _useControlledReducer2[0],
    dispatch = _useControlledReducer2[1],
    boundActions = useMemo(function () {
      return bindActionCreators(dispatch, actions);
    }, []);
  return [state, boundActions];
}
function withNormalizedRows() {
  var updateState = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : identity,
    selectionType = arguments.length > 1 ? arguments[1] : void 0;
  return function (state) {
    var _updateState = updateState(state),
      rows = _updateState.rows;
    return _objectSpread(_objectSpread({}, _objectWithoutProperties(_updateState, _excluded)), {}, {
      rows: normalizeSelectedRows(rows, selectionType)
    });
  };
}
function normalizeSelectedRows(selectedRows, selectionType) {
  return "none" === selectionType ? [] : "multi" === selectionType ? selectedRows : _sliceInstanceProperty(selectedRows).call(selectedRows, 0, 1);
}