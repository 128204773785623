import React from "react";
import { Tag } from "../../../Tag/Tag";
var clickEvent = function (evt) {
  return evt.stopPropagation();
};
export var GenericSelectControlMultiValue = function (_ref) {
  var id = _ref.id,
    removeProps = _ref.removeProps,
    children = _ref.children,
    className = _ref.className,
    _ref$selectProps = _ref.selectProps,
    isDisabled = _ref$selectProps.isDisabled,
    ariaDisabled = _ref$selectProps["aria-disabled"];
  return React.createElement(Tag, {
    id: id,
    label: children,
    className: className,
    disabled: isDisabled || ariaDisabled,
    renderIcon: function (icon) {
      return React.createElement("div", null, React.cloneElement(icon, removeProps));
    },
    onClick: clickEvent
  });
};
GenericSelectControlMultiValue.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlMultiValue"
}, GenericSelectControlMultiValue.__docgenInfo = {
  componentName: "GenericSelectControlMultiValue",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMultiValue",
  methods: [],
  actualName: "GenericSelectControlMultiValue"
};