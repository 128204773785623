import { SET_FILTER_VALUE, SET_PAGE, SET_PAGE_SIZE, SET_SORTED, SET_COLUMNS, SET_EDITED_ROW } from "./types";
export var setFilterValue = function (filterValue) {
  var resetPage = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
  return {
    type: SET_FILTER_VALUE,
    filterValue: filterValue,
    resetPage: resetPage
  };
};
export var setPage = function (page) {
  return {
    type: SET_PAGE,
    page: page
  };
};
export var setPageSize = function (pageSize) {
  var resetPage = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
  return {
    type: SET_PAGE_SIZE,
    pageSize: pageSize,
    resetPage: resetPage
  };
};
export var setSorted = function (sorted) {
  var resetPage = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
  return {
    type: SET_SORTED,
    sorted: sorted,
    resetPage: resetPage
  };
};
export var setColumns = function (columns) {
  return {
    type: SET_COLUMNS,
    columns: columns
  };
};
export var setEditedRow = function (rowId) {
  return {
    type: SET_EDITED_ROW,
    rowId: rowId
  };
};