import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { getConfigValue } from "@jutro/config";
import { warning, info } from "@jutro/logger";
import { fetchTsmLanguages } from "./fetchTsmLanguages";
export var tsmLanguagesLoader = (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
  var _context, worksetUid, stringVersion, stringGroup, config, jutroVersion, languages;
  return _regeneratorRuntime.wrap(function (_context2) {
    for (;;) switch (_context2.prev = _context2.next) {
      case 0:
        return worksetUid = getConfigValue("JUTRO_TSM_WORKSET_UID", "active"), stringVersion = getConfigValue("JUTRO_TSM_STRING_VERSION"), stringGroup = getConfigValue("JUTRO_TSM_STRING_GROUP"), config = getConfigValue("localeSettings"), jutroVersion = getConfigValue("JUTRO_CORE_STRING_VERSION"), info(_concatInstanceProperty(_context = 'TSM enabled: Loading available languages, worksetUid: "'.concat(worksetUid, '", string group: "')).call(_context, stringGroup, ":.")), _context2.prev = 6, _context2.next = 9, fetchTsmLanguages(worksetUid, stringGroup, void 0, stringVersion, jutroVersion);
      case 9:
        languages = _context2.sent, config.availableLanguages = languages.languageCodes, config.preferredLanguage = languages.defaultLanguageCode, _context2.next = 17;
        break;
      case 14:
        _context2.prev = 14, _context2.t0 = _context2.catch(6), warning("TSM: Error loading languages. Details: ".concat(_context2.t0));
      case 17:
      case "end":
        return _context2.stop();
    }
  }, _callee, null, [[6, 14]]);
})), function () {
  return _ref.apply(this, arguments);
});
var _ref;