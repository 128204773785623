import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["phases", "className"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useBreakpoint } from "@jutro/layout";
import styles from "./PhaseProgressBar.module.css";
import { PhaseProgress, phaseProgressPropTypes } from "./PhaseProgress";
import { PhaseProgressLabel } from "./PhaseProgressLabel";
export var PhaseProgressBar = function (props) {
  var phases = props.phases,
    className = props.className,
    rest = _objectWithoutProperties(props, _excluded),
    isPhone = "phone" === useBreakpoint(props).breakpoint,
    classes = cx(styles.phaseProgressBar, className);
  return React.createElement("nav", _extends({
    className: classes
  }, rest), React.createElement("div", {
    className: styles.phases,
    role: "menu"
  }, _mapInstanceProperty(phases).call(phases, function (phaseProps, index) {
    var _context2,
      id = _concatInstanceProperty(_context2 = "".concat(phaseProps.id)).call(_context2, index);
    return React.createElement(PhaseProgress, _extends({}, phaseProps, {
      id: id,
      key: id,
      showLabel: !isPhone
    }));
  })), function () {
    var _context;
    if (!isPhone) return null;
    var activePhase = _findInstanceProperty(phases).call(phases, function (phase) {
      return phase.steps.some(function (step) {
        return step.active;
      });
    });
    if (!activePhase) return null;
    var activeStep = _findInstanceProperty(_context = activePhase.steps).call(_context, function (step) {
        return step.active;
      }),
      title = activePhase.title,
      icon = activePhase.icon,
      isError = activePhase.isError,
      stepTitle = null == activeStep ? void 0 : activeStep.title;
    return React.createElement(PhaseProgressLabel, {
      title: title,
      stepTitle: stepTitle,
      icon: icon,
      isError: isError,
      active: !0
    });
  }());
};
var phaseProgressBarPropTypes = {
  phases: PropTypes.arrayOf(PropTypes.shape(function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({}, phaseProgressPropTypes)).isRequired).isRequired,
  className: PropTypes.string
};
PhaseProgressBar.propTypes = phaseProgressBarPropTypes, PhaseProgressBar.__docgenInfo = {
  description: "PhaseProgressBar\n@type {React.FC<PropTypes.InferProps<typeof phaseProgressBarPropTypes>>}",
  methods: [],
  displayName: "PhaseProgressBar",
  props: {
    phases: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {}
        }
      },
      required: !0,
      description: "Phases in the progress indicator"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar styles"
    }
  }
}, PhaseProgressBar.__docgenInfo = {
  componentName: "PhaseProgressBar",
  packageName: "@jutro/components",
  description: "PhaseProgressBar",
  displayName: "PhaseProgressBar",
  methods: [],
  actualName: "PhaseProgressBar",
  props: {
    phases: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {}
        }
      },
      required: !0,
      description: "Phases in the progress indicator"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to override progress bar styles"
    }
  }
};