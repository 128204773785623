import { useCallback, useLayoutEffect, useRef } from "react";
export var useEvent = function (handler) {
  var handlerRef = useRef(null);
  return useLayoutEffect(function () {
    handlerRef.current = handler;
  }), useCallback(function () {
    var fn = handlerRef.current;
    if (!fn) throw new Error("You run this handler during the rendering. useEvent is supposed to be used with event handlers, if you need a stable identity during the rendering consider `useCallback` instead");
    return fn.apply(void 0, arguments);
  }, []);
};