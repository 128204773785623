import { defineMessages } from "react-intl";
export var messages = defineMessages({
  collapsedButtonLabel: {
    id: "jutro-router.SideNavigation.collapsedButtonLabel",
    defaultMessage: "Expand menu"
  },
  expandedButtonLabel: {
    id: "jutro-router.SideNavigation.expandedButtonLabel",
    defaultMessage: "Collapse menu"
  }
});