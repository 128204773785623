import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from "react";
import cx from "classnames";
export var withClassName = function (Component) {
  for (var _len = arguments.length, classNames = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) classNames[_key - 1] = arguments[_key];
  var WrappedComponent = function (props) {
    return React.createElement(Component, _extends({}, props, {
      className: cx.apply(void 0, _toConsumableArray(_mapInstanceProperty(classNames).call(classNames, function (className) {
        return props.selectProps.customStyles[className];
      })))
    }));
  };
  return WrappedComponent.displayName = Component.displayName || Component.name, WrappedComponent;
};