import { SET_ASYNC_DATA, SET_LOADING } from "./types";
export var setAsyncData = function (asyncData, numberOfRows) {
  return {
    type: SET_ASYNC_DATA,
    asyncData: asyncData,
    numberOfRows: numberOfRows
  };
};
export var setLoading = function (loading) {
  return {
    type: SET_LOADING,
    loading: loading
  };
};