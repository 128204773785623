import React, { useRef, useCallback, useEffect } from "react";
import { useKeyPress } from "./useKeyPress";
export function useAccessibleRef(focused) {
  var ref = useRef(),
    handleKey = useCallback(function (e, keyPressed) {
      var _ref$current, _ref$current$click;
      (e.preventDefault(), e.stopPropagation(), (focused || isFocused(ref)) && keyPressed) && (null === (_ref$current = ref.current) || void 0 === _ref$current || null === (_ref$current$click = _ref$current.click) || void 0 === _ref$current$click || _ref$current$click.call(_ref$current));
    }, [focused, ref]);
  return useEffect(function () {
    var _ref$current2, _ref$current2$focus;
    focused && !isFocused(ref) && (null === (_ref$current2 = ref.current) || void 0 === _ref$current2 || null === (_ref$current2$focus = _ref$current2.focus) || void 0 === _ref$current2$focus || _ref$current2$focus.call(_ref$current2));
  }, [focused, ref]), useKeyPress(ref, ["Space", "Enter"], handleKey), ref;
}
function isFocused(_ref) {
  var current = _ref.current;
  return document.activeElement === current;
}