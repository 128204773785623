import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { createContext, useContext, useMemo } from "react";
import flow from "lodash/flow";
import kebabCase from "lodash/kebabCase";
import deburr from "lodash/deburr";
import { createIntl } from "react-intl";
import { createContextConsumerHOC } from "@jutro/platform";
import { IntlContext } from "./IntlProvider";
import { shouldDisplayStringId, stringIdTranslator } from "./stringIdTranslator";
var fallbackIntl = createIntl({
  locale: "en-US",
  messages: {},
  onError: function () {}
});
function createIdFromMessage(message) {
  return flow(deburr, kebabCase)(message) || "non-existing-abcdef";
}
var createTranslator = function (intl) {
  return function (key, args) {
    if (!key) return key;
    var message,
      formatter = intl || fallbackIntl;
    if ("string" == typeof key) return formatter.formatMessage({
      id: createIdFromMessage(message = key),
      defaultMessage: message
    }, args);
    if (function (key) {
      return void 0 !== key.id || void 0 !== key.defaultMessage || void 0 !== key.args;
    }(key)) {
      if (shouldDisplayStringId()) return stringIdTranslator(key);
      var mergedArgs = _objectSpread(_objectSpread({}, args), key.args),
        _resolveFromIntlMessa = function (_ref) {
          var id = _ref.id,
            defaultMessage = _ref.defaultMessage;
          return {
            id: id || createIdFromMessage(defaultMessage),
            defaultMessage: defaultMessage
          };
        }(key),
        id = _resolveFromIntlMessa.id,
        defaultMessage = _resolveFromIntlMessa.defaultMessage,
        output = formatter.formatMessage({
          id: id,
          defaultMessage: defaultMessage
        }, mergedArgs);
      return output === id ? defaultMessage || "" : output;
    }
    return key;
  };
};
export var TranslatorContext = createContext(createTranslator());
export var TranslatorProvider = function (_ref2) {
  var children = _ref2.children,
    intl = useContext(IntlContext),
    context = useMemo(function () {
      return createTranslator(intl);
    }, [intl]);
  return React.createElement(TranslatorContext.Provider, {
    value: context
  }, children);
};
export var useTranslator = function () {
  var contextValue = useContext(TranslatorContext);
  if (!contextValue) throw new Error("Please check that your app is wrapped in TranslatorProvider or GlobalizationProvider");
  return contextValue;
};
export var withTranslator = function (ComponentToWrap) {
  return createContextConsumerHOC({
    component: ComponentToWrap,
    context: TranslatorContext,
    displayName: "WithTranslator".concat(ComponentToWrap.displayName || ComponentToWrap.name),
    mapContextToProps: function (props, translator) {
      return {
        translator: translator
      };
    }
  });
};
TranslatorProvider.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TranslatorProvider"
}, TranslatorProvider.__docgenInfo = {
  componentName: "TranslatorProvider",
  packageName: "@jutro/locale",
  description: "",
  displayName: "TranslatorProvider",
  methods: [],
  actualName: "TranslatorProvider"
};