import { defineMessages } from "react-intl";
export var messages = defineMessages({
  logout: {
    id: "jutro-router.DropdownMenu.Logout",
    defaultMessage: "Logout"
  },
  profileMenu: {
    id: "jutro-router.DropdownMenu.ProfileMenu",
    defaultMessage: "Profile menu"
  }
});