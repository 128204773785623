import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _GridLayout$propTypes,
  _excluded = ["id", "children", "columns", "gap", "maxWidth", "title", "useCard"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import PropTypes from "prop-types";
import { generateComponentId } from "@jutro/platform";
import { useBreakpoint, Flex, GridLayout } from "@jutro/layout";
import { Card } from "../widgets/card/Card";
import { layoutSizes } from "./Layouts.commons";
import styles from "./Layout.module.css";
export var LayoutContainer = function (props) {
  var _context,
    breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    children = breakpointProps.children,
    columns = breakpointProps.columns,
    gap = breakpointProps.gap,
    maxWidth = breakpointProps.maxWidth,
    title = breakpointProps.title,
    useCard = breakpointProps.useCard,
    other = _objectWithoutProperties(breakpointProps, _excluded),
    childArray = React.Children.toArray(children);
  if (childArray.length !== columns.length) throw Error(_concatInstanceProperty(_context = "Layout component expect exact ".concat(columns.length, " direct descedants, got ")).call(_context, childArray.length, "."));
  var width;
  return React.createElement(Flex, _extends({
    id: id,
    className: styles.wrapper
  }, other, {
    direction: "column",
    style: {
      maxWidth: (width = maxWidth, width && width in layoutSizes ? layoutSizes[maxWidth] : maxWidth)
    }
  }), title && React.createElement("h1", null, title), React.createElement(GridLayout, {
    columns: columns
  }, _mapInstanceProperty(childArray).call(childArray, function (descendat, index) {
    return function (descendants, index) {
      if (useCard) {
        var _context2,
          cardId = id ? _concatInstanceProperty(_context2 = "".concat(id, "-layout-card-")).call(_context2, index) : generateComponentId("layout-card-".concat(index));
        return React.createElement(Card, {
          key: index,
          id: cardId,
          className: styles.container,
          isPanel: !0,
          fullWidth: !0
        }, React.createElement(GridLayout, {
          gap: gap
        }, [descendants]));
      }
      return React.createElement(GridLayout, {
        key: index,
        gap: gap
      }, [descendants]);
    }(descendat, index);
  })));
};
export var layoutComponentPropTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  gap: null === (_GridLayout$propTypes = GridLayout.propTypes) || void 0 === _GridLayout$propTypes ? void 0 : _GridLayout$propTypes.gap,
  maxWidth: PropTypes.oneOfType([PropTypes.oneOf(["default", "expanded", "narrow"]), PropTypes.string]),
  title: PropTypes.string,
  useCard: PropTypes.bool
};
export var layoutComponentDefaultProps = {
  gap: "small",
  useCard: !0,
  maxWidth: "default"
};
LayoutContainer.propTypes = _objectSpread(_objectSpread({}, layoutComponentPropTypes), {}, {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired)
}), LayoutContainer.defaultProps = _objectSpread(_objectSpread({}, layoutComponentDefaultProps), {}, {
  columns: [1]
}), LayoutContainer.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LayoutContainer",
  props: {
    gap: {
      defaultValue: {
        value: "'small'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "GridLayout.propTypes?.gap"
      },
      required: !1,
      description: "Gap between elements inside of the container"
    },
    useCard: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If if true, content will be wrapped by Card component"
    },
    maxWidth: {
      defaultValue: {
        value: "'default'",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'default'",
            computed: !1
          }, {
            value: "'expanded'",
            computed: !1
          }, {
            value: "'narrow'",
            computed: !1
          }]
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Set max width which can take container"
    },
    columns: {
      defaultValue: {
        value: "[1]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Define number of columns"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "What should be rendered in the container"
    },
    title: {
      type: {
        name: "string"
      },
      required: !1,
      description: "If title prop is provided it will be displayed at the top of the page"
    }
  }
}, LayoutContainer.__docgenInfo = {
  componentName: "LayoutContainer",
  packageName: "@jutro/components",
  description: "",
  displayName: "LayoutContainer",
  methods: [],
  actualName: "LayoutContainer",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "What should be rendered in the container"
    },
    gap: {
      type: {
        name: "custom",
        raw: "GridLayout.propTypes?.gap"
      },
      required: !1,
      description: "Gap between elements inside of the container",
      defaultValue: {
        value: "'small'",
        computed: !1
      }
    },
    maxWidth: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'default'",
            computed: !1
          }, {
            value: "'expanded'",
            computed: !1
          }, {
            value: "'narrow'",
            computed: !1
          }]
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Set max width which can take container",
      defaultValue: {
        value: "'default'",
        computed: !1
      }
    },
    title: {
      type: {
        name: "string"
      },
      required: !1,
      description: "If title prop is provided it will be displayed at the top of the page"
    },
    useCard: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If if true, content will be wrapped by Card component",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    columns: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: !1,
      description: "Define number of columns",
      defaultValue: {
        value: "[1]",
        computed: !1
      }
    }
  }
};