import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { IconButton } from "@jutro/components";
import styles from "./BurgerMenu.module.css";
import { BurgerSidebar } from "./BurgerSidebar";
import { messages } from "./BurgerMenu.messages";
import { BurgerMenuContext } from "./BurgerMenuContext";
export var BurgerMenu = function (_ref) {
  var className = _ref.className,
    burgerContent = _ref.burgerContent,
    separatorClassName = _ref.separatorClassName,
    buttonClassName = _ref.buttonClassName,
    closeOnItemClick = _ref.closeOnItemClick,
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1],
    _useState3 = useState(document.body.style.overflow),
    initialBodyOverflow = _slicedToArray(_useState3, 1)[0],
    translator = useTranslator(),
    toggleSidebar = useCallback(function () {
      return setIsOpen(function (prevIsOpen) {
        return !prevIsOpen;
      });
    }, []);
  useEffect(function () {
    document.body.style.overflow = isOpen ? "hidden" : initialBodyOverflow;
  }, [isOpen]);
  var burgerMenuStyles = cx(styles.burgerMenu, className),
    adjustSidebarMenuToViewportHeight = useCallback(function () {
      var viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty("--burger-menu-sidebar-height", "".concat(viewportHeight, "px"));
    }, []);
  useEffect(function () {
    return window.addEventListener("resize", adjustSidebarMenuToViewportHeight), function () {
      window.removeEventListener("resize", adjustSidebarMenuToViewportHeight);
    };
  }, [adjustSidebarMenuToViewportHeight]);
  var burgerMenuContextValue = useMemo(function () {
    return {
      closeMenu: function () {
        return setIsOpen(!1);
      },
      closeOnItemClick: closeOnItemClick
    };
  }, [setIsOpen, closeOnItemClick]);
  return React.createElement(BurgerMenuContext.Provider, {
    value: burgerMenuContextValue
  }, React.createElement("div", {
    className: burgerMenuStyles,
    role: "navigation"
  }, React.createElement(IconButton, {
    icon: "gw-menu",
    iconColor: "light",
    onClick: toggleSidebar,
    "aria-label": translator(messages.openBurgerMenu),
    "aria-expanded": isOpen,
    className: cx(styles.burgerButton, buttonClassName),
    iconClassName: styles.burgerButtonIcon,
    noDeprecationWarnings: ["IconButton"]
  }), React.createElement(BurgerSidebar, {
    isOpen: isOpen,
    burgerContent: null == burgerContent ? void 0 : _filterInstanceProperty(burgerContent).call(burgerContent, Boolean),
    separatorClassName: separatorClassName
  })));
};
var burgerMenuPropTypes = {
  className: PropTypes.string,
  burgerContent: PropTypes.arrayOf(PropTypes.node),
  separatorClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  closeOnItemClick: PropTypes.bool
};
BurgerMenu.propTypes = burgerMenuPropTypes, BurgerMenu.__docgenInfo = {
  description: "BurgerMenu component renders burger menu",
  methods: [],
  displayName: "BurgerMenu",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "And optional CSS class for this component"
    },
    burgerContent: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: !1,
      description: "Sidebar content"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger sidebar separator"
    },
    buttonClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger menu button"
    },
    closeOnItemClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, clicking items (BurgerMenuItem) which does not change browser location, will cause menu to be closed.\nThis can be overwritten per item by setting closeOnClick prop on it.\nNote that if item changes browser location, menu will always be closed."
    }
  }
}, BurgerMenu.__docgenInfo = {
  componentName: "BurgerMenu",
  packageName: "@jutro/router",
  description: "BurgerMenu component renders burger menu",
  displayName: "BurgerMenu",
  methods: [],
  actualName: "BurgerMenu",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "And optional CSS class for this component"
    },
    burgerContent: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: !1,
      description: "Sidebar content"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger sidebar separator"
    },
    buttonClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional CSS class for the burger menu button"
    },
    closeOnItemClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, clicking items (BurgerMenuItem) which does not change browser location, will cause menu to be closed.\nThis can be overwritten per item by setting closeOnClick prop on it.\nNote that if item changes browser location, menu will always be closed."
    }
  }
};