import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "option", "value", "disabled", "required", "onValueChange", "textAlign", "className", "labelClassName", "dangerouslySetInnerHTML", "tabIndex", "testId", "secondaryLabel", "aria-label", "aria-labelledby"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { ContextConsumer, generateComponentId, deprecateComponent } from "@jutro/platform";
import { TranslatorContext } from "@jutro/locale";
import { isEmptyValue, isNilValue } from "@jutro/data";
import styles from "./RadioButton.module.css";
import { getOptionCode, getOptionName } from "../availableValues";
var contexts = [TranslatorContext];
export var RadioButtonInternal = function (_Component) {
  _inherits(RadioButtonInternal, Component);
  var _super = _createSuper(RadioButtonInternal);
  function RadioButtonInternal(props, context) {
    var _this;
    _classCallCheck(this, RadioButtonInternal), _this = _super.call(this, props, context), _defineProperty(_assertThisInitialized(_this), "inputRef", React.createRef()), _defineProperty(_assertThisInitialized(_this), "handleValueChange", function (evt) {
      var _this$props = _this.props,
        onValueChange = _this$props.onValueChange,
        model = _this$props.model,
        path = _this$props.path;
      _this$props.disabled || onValueChange && onValueChange(evt.target.value, model || path);
    }), _defineProperty(_assertThisInitialized(_this), "handleClick", function (evt) {
      var _this$props2 = _this.props,
        onValueChange = _this$props2.onValueChange,
        model = _this$props2.model,
        path = _this$props2.path;
      _this$props2.disabled || (onValueChange && _this.inputRef.current.value !== _this.props.value && onValueChange(_this.inputRef.current.value, model || path), evt.preventDefault());
    }), _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (evt) {
      var _this$props3 = _this.props,
        onValueChange = _this$props3.onValueChange,
        model = _this$props3.model,
        path = _this$props3.path;
      _this$props3.disabled || " " !== evt.key && "Enter" !== evt.key || (onValueChange && onValueChange(_this.inputRef.current.value, model || path), evt.preventDefault());
    }), _defineProperty(_assertThisInitialized(_this), "renderComponent", function (translator) {
      var _code,
        _context,
        _context2,
        _context3,
        _this$props4 = _this.props,
        id = _this$props4.id,
        option = _this$props4.option,
        value = _this$props4.value,
        disabled = _this$props4.disabled,
        required = _this$props4.required,
        className = (_this$props4.onValueChange, _this$props4.textAlign, _this$props4.className),
        labelClassName = _this$props4.labelClassName,
        tabIndex = (_this$props4.dangerouslySetInnerHTML, _this$props4.tabIndex),
        testId = _this$props4.testId,
        secondaryLabel = _this$props4.secondaryLabel,
        ariaLabel = _this$props4["aria-label"],
        ariaLabeledBy = _this$props4["aria-labelledby"],
        other = _objectWithoutProperties(_this$props4, _excluded),
        classes = cx(styles.radioButton, className),
        labelClasses = cx(styles.label, labelClassName),
        code = getOptionCode(option),
        checked = (null == value ? void 0 : value.toString()) === (null === (_code = code) || void 0 === _code ? void 0 : _code.toString());
      isNilValue(code) && (code = "empty", checked = isEmptyValue(value));
      var controlUniqueId = _assertThisInitialized(_this).controlUniqueId,
        valueId = _concatInstanceProperty(_context = "".concat(controlUniqueId, "_")).call(_context, code),
        labelText = _this.renderPrimaryText(translator),
        labelId = "".concat(valueId, "-label"),
        secondaryLabelId = "".concat(valueId, "-secondary-label");
      return React.createElement("div", {
        className: classes,
        "aria-checked": checked,
        "aria-disabled": disabled,
        "data-testid": "radio-button-control",
        role: "radio",
        onClick: _this.handleClick,
        onKeyDown: _this.handleKeyDown,
        tabIndex: tabIndex,
        "data-value": code,
        "aria-labelledby": null != ariaLabeledBy ? ariaLabeledBy : labelId,
        "aria-label": ariaLabel,
        "aria-describedby": secondaryLabel ? secondaryLabelId : void 0,
        title: labelText
      }, React.createElement("input", _extends({
        type: "radio",
        "aria-hidden": !0,
        id: valueId,
        value: code || "",
        onChange: _this.handleValueChange,
        checked: checked,
        disabled: disabled,
        required: required,
        ref: _this.inputRef
      }, other, {
        "data-testid": testId ? _concatInstanceProperty(_context2 = "".concat(testId, "_")).call(_context2, code) : _concatInstanceProperty(_context3 = "".concat(id, "_")).call(_context3, code),
        tabIndex: -1
      })), React.createElement("span", {
        className: labelClasses,
        "data-testid": "radio-button-label",
        id: labelId
      }, React.createElement("span", {
        className: styles.primaryText
      }, labelText), secondaryLabel && _this.renderSecondaryText(translator, secondaryLabelId)));
    });
    var _id = props.id;
    return _this.controlUniqueId = generateComponentId(_id), _this;
  }
  return _createClass(RadioButtonInternal, [{
    key: "renderPrimaryText",
    value: function (translator) {
      var option = this.props.option,
        name = getOptionName(option);
      return name ? translator(name) : null;
    }
  }, {
    key: "renderSecondaryText",
    value: function (translator, id) {
      return this.props.secondaryLabel ? React.createElement("span", {
        className: styles.secondaryText,
        "data-testid": "radio-button-secondary-label",
        id: id
      }, translator(this.props.secondaryLabel)) : null;
    }
  }, {
    key: "render",
    value: function () {
      return React.createElement(ContextConsumer, {
        contexts: contexts
      }, this.renderComponent);
    }
  }]), RadioButtonInternal;
}();
_defineProperty(RadioButtonInternal, "propTypes", {
  option: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  onValueChange: PropTypes.func,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  labelClassName: PropTypes.string
}), _defineProperty(RadioButtonInternal, "displayName", "RadioButton");
export var RadioButton = deprecateComponent(RadioButtonInternal, {
  deprecated: "RadioButton",
  alternative: "RadioField",
  extraMessage: "",
  dropTargetVersion: ""
});
RadioButtonInternal.__docgenInfo = {
  description: "",
  methods: [{
    name: "handleValueChange",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      type: null
    }],
    returns: null
  }, {
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      type: null
    }],
    returns: null
  }, {
    name: "handleKeyDown",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      type: null
    }],
    returns: null
  }, {
    name: "renderPrimaryText",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      type: null
    }],
    returns: null
  }, {
    name: "renderSecondaryText",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      type: null
    }, {
      name: "id",
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      type: null
    }],
    returns: null
  }],
  displayName: "RadioButton",
  props: {
    option: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Radio Button options"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines whether the field is disabled"
    },
    value: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Value to determine whether the button is selected or not"
    },
    required: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines whether the field is required"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the radio button"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the value is changed"
    },
    tabIndex: {
      type: {
        name: "number"
      },
      required: !1,
      description: "TabIndex of the Radio Button"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "className passed to the top element of RadioButton"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An additional className passed to the label"
    }
  }
}, RadioButtonInternal.__docgenInfo = {
  componentName: "RadioButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "RadioButton",
  methods: [{
    name: "handleValueChange",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleKeyDown",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderPrimaryText",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderSecondaryText",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: void 0,
      type: null
    }, {
      name: "id",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "RadioButtonInternal",
  props: {
    option: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Radio Button options"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines whether the field is disabled"
    },
    value: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Value to determine whether the button is selected or not"
    },
    required: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines whether the field is required"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id of the radio button"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the value is changed"
    },
    tabIndex: {
      type: {
        name: "number"
      },
      required: !1,
      description: "TabIndex of the Radio Button"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "className passed to the top element of RadioButton"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An additional className passed to the label"
    }
  }
};