import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import cx from "classnames";
import styles from "./SliderIndicatorsContainer.module.css";
import { sliderValuePropType } from "./sliderUtils";
export var SliderIndicatorsContainer = function (_ref) {
  var min = _ref.min,
    max = _ref.max,
    containerClassName = _ref.containerClassName,
    indicatorClassName = _ref.indicatorClassName,
    children = _ref.children;
  return React.createElement("div", {
    className: containerClassName
  }, React.createElement("div", {
    className: cx(indicatorClassName, styles.min)
  }, React.createElement(FormattedNumber, {
    value: min
  })), children, React.createElement("div", {
    className: cx(indicatorClassName, styles.max)
  }, React.createElement(FormattedNumber, {
    value: max
  })));
};
SliderIndicatorsContainer.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: sliderValuePropType.isRequired,
  range: PropTypes.bool,
  containerClassName: PropTypes.string,
  indicatorClassName: PropTypes.string
}, SliderIndicatorsContainer.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "SliderIndicatorsContainer",
  props: {
    min: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "custom",
        raw: "sliderValuePropType.isRequired"
      },
      required: !1,
      description: "Current slider value (to create a fully controlled component)"
    },
    range: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set, displays a range selector"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    indicatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the indicator"
    }
  }
}, SliderIndicatorsContainer.__docgenInfo = {
  componentName: "SliderIndicatorsContainer",
  packageName: "@jutro/components",
  description: "",
  displayName: "SliderIndicatorsContainer",
  methods: [],
  actualName: "SliderIndicatorsContainer",
  props: {
    min: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: !0,
      description: "Current slider value (to create a fully controlled component)"
    },
    range: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set, displays a range selector"
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the component"
    },
    indicatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional style to apply to the indicator"
    }
  }
};