import { defineMessages } from "@jutro/locale";
export default defineMessages({
  formErrorMessage: {
    id: "jutro-validation.ValidationMechanism.formErrorMessage",
    defaultMessage: "{value} field(s) are invalid"
  },
  formWarningMessage: {
    id: "jutro-validation.ValidationMechanism.formWarningMessage",
    defaultMessage: "{value} field(s) have warnings"
  }
});