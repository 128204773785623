import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["header", "renderHeader", "cell", "renderCell", "className", "visible", "option", "onOption", "value", "translator", "onValueChange", "columnProportion", "onFilter", "basePath"],
  _excluded2 = ["id", "rowId"];
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import { RadioButton } from "@jutro/components";
import { warning } from "@jutro/logger";
import { TableColumn } from "../table/TableColumn";
import { createTextFilter } from "../helper";
import styles from "./RadioColumn.module.css";
export var RadioColumn = function () {
  return "development" === process.env.NODE_ENV && warning("Component <RadioColumn /> should never render"), React.createElement(React.Fragment, null);
};
RadioColumn.propTypes = _objectSpread(_objectSpread({}, TableColumn.propTypes), {}, {
  option: PropTypes.oneOfType([PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string
  }), PropTypes.func]),
  onOption: PropTypes.func,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  onAccessor: PropTypes.func
}), RadioColumn.getRowActions = function (_ref) {
  if (_ref.selectOnRowClick) return function (event) {
    return RadioColumn.onRowClick(event);
  };
}, RadioColumn.onRowClick = function (evt) {
  var currentRadioInput = evt.currentTarget.querySelector('input[type="radio"]');
  currentRadioInput && (evt.preventDefault(), currentRadioInput.click());
}, RadioColumn.getTabIndex = function (index) {
  return 0 === index ? 0 : -1;
}, RadioColumn.onKeyDown = function (evt) {
  var handleMoveDown = function (selectedElementIndex, availableValues) {
      return (selectedElementIndex + 1) % availableValues.length;
    },
    handleMoveUp = function (selectedElementIndex, availableValues) {
      return 0 === selectedElementIndex ? availableValues.length - 1 : (selectedElementIndex - 1) % availableValues.length;
    },
    handleMove = {
      ArrowDown: handleMoveDown,
      ArrowRight: handleMoveDown,
      ArrowUp: handleMoveUp,
      ArrowLeft: handleMoveUp
    }[evt.key];
  if (handleMove) {
    evt.preventDefault();
    var nextRow = function (currentRow) {
      if (!currentRow.querySelector("input").checked) return currentRow;
      var siblingRows = currentRow.parentNode.childNodes,
        currentRowIndex = _indexOfInstanceProperty(Array.prototype).call(siblingRows, currentRow),
        nextIndex = handleMove(currentRowIndex, siblingRows);
      return siblingRows.item(nextIndex);
    }(evt.currentTarget);
    nextRow.focus(), nextRow.click();
  }
}, RadioColumn.defaultCell = function (row, rowId, props) {
  props.header, props.renderHeader, props.cell, props.renderCell, props.className;
  var visible = props.visible,
    option = props.option,
    onOption = props.onOption,
    value = props.value,
    onValueChange = (props.translator, props.onValueChange),
    other = (props.columnProportion, props.onFilter, props.basePath, _objectWithoutProperties(props, _excluded));
  if (!1 === visible) return null;
  var rowOption = option || onOption;
  return isFunction(rowOption) ? rowOption = rowOption(row, rowId, props) : !rowOption && row && (rowOption = {
    code: row.code,
    name: row.name || row.code
  }), rowOption ? renderRadioButton({
    rowOption: rowOption,
    value: value,
    onValueChange: onValueChange,
    otherProps: _objectSpread(_objectSpread({}, other), {}, {
      rowId: rowId
    })
  }) : null;
};
export function renderRadioButton(props) {
  var _context,
    rowOption = props.rowOption,
    value = props.value,
    onValueChange = props.onValueChange,
    otherProps = props.otherProps,
    id = otherProps.id,
    rowId = otherProps.rowId,
    rest = _objectWithoutProperties(otherProps, _excluded2);
  return React.createElement(RadioButton, _extends({
    id: _concatInstanceProperty(_context = "".concat(id, "_")).call(_context, rowId),
    labelClassName: styles.radio,
    key: rowOption.code,
    option: rowOption,
    value: value,
    onValueChange: onValueChange
  }, rest));
}
RadioColumn.displayName = "RadioColumn", RadioColumn.defaultProps = {
  renderCell: RadioColumn.defaultCell,
  getRowActions: RadioColumn.getRowActions,
  onFilter: function (filterValue) {
    var textFilter = createTextFilter(filterValue);
    return function (_ref2) {
      var name = _ref2.name;
      return textFilter(name);
    };
  },
  columnProportion: 1
}, RadioColumn.__docgenInfo = {
  description: "RadioColumn\n@returns {null} - The RadioColumn component does not render anything\n\n@metadataType container",
  methods: [{
    name: "getRowActions",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ selectOnRowClick }",
      type: null
    }],
    returns: null
  }, {
    name: "onRowClick",
    docblock: "Handle row click and trigger radio selection and focus\n@param {object} evt - Click event object",
    modifiers: ["static"],
    params: [{
      name: "evt",
      description: "Click event object",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle row click and trigger radio selection and focus"
  }, {
    name: "getTabIndex",
    docblock: "Computes the tabindex value for a given row\n@param {number} index",
    modifiers: ["static"],
    params: [{
      name: "index",
      description: null,
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: null,
    description: "Computes the tabindex value for a given row"
  }, {
    name: "onKeyDown",
    docblock: "Handle keydown event for row to move between tabs\n@param {object} evt - keydown event object",
    modifiers: ["static"],
    params: [{
      name: "evt",
      description: "keydown event object",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle keydown event for row to move between tabs"
  }, {
    name: "defaultCell",
    docblock: "Render the default cell with the radio button\n@param {object} row - Each row in the data table\n@param {number | string} rowId - the id of the row\n@param {object} props - the properties used by RadioColumn\n@param {object} styles - styles for RadioButton\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "rowId",
      description: "the id of the row",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "props",
      description: "the properties used by RadioColumn",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the default cell with the radio button"
  }],
  displayName: "RadioColumn",
  props: {
    renderCell: {
      defaultValue: {
        value: "RadioColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    getRowActions: {
      defaultValue: {
        value: "RadioColumn.getRowActions",
        computed: !0
      },
      required: !1
    },
    onFilter: {
      defaultValue: {
        value: "filterValue => {\n    const textFilter = createTextFilter(filterValue);\n\n    return ({ name }) => textFilter(name);\n}",
        computed: !1
      },
      required: !1
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    },
    option: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            name: {
              name: "string",
              required: !1
            },
            code: {
              name: "string",
              required: !1
            }
          }
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "The option to be displayed in cell"
    },
    onOption: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The option to be displayed in cell"
    },
    value: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Value of selected row"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On radio button click"
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    }
  },
  composes: ["../table/TableColumn"]
}, renderRadioButton.__docgenInfo = {
  description: "Render the button with action defined on onclick listener\n@param {object} props - Radio Button props\n@param {object} option - Option for the current row\n@param {string} value - Value for the current row\n@param {Function} onValueChange - Callback for value change\n@param {object} otherProps - Other props to pass to radio button\n@returns {React.ReactElement}",
  methods: [],
  displayName: "renderRadioButton"
}, RadioColumn.__docgenInfo = {
  componentName: "RadioColumn",
  packageName: "@jutro/datatable",
  description: "RadioColumn",
  displayName: "RadioColumn",
  methods: [{
    name: "getRowActions",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ selectOnRowClick }",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "onRowClick",
    docblock: "Handle row click and trigger radio selection and focus\n@param {object} evt - Click event object",
    modifiers: ["static"],
    params: [{
      name: "evt",
      description: "Click event object",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle row click and trigger radio selection and focus"
  }, {
    name: "getTabIndex",
    docblock: "Computes the tabindex value for a given row\n@param {number} index",
    modifiers: ["static"],
    params: [{
      name: "index",
      description: null,
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: null,
    description: "Computes the tabindex value for a given row"
  }, {
    name: "onKeyDown",
    docblock: "Handle keydown event for row to move between tabs\n@param {object} evt - keydown event object",
    modifiers: ["static"],
    params: [{
      name: "evt",
      description: "keydown event object",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handle keydown event for row to move between tabs"
  }, {
    name: "defaultCell",
    docblock: "Render the default cell with the radio button\n@param {object} row - Each row in the data table\n@param {number | string} rowId - the id of the row\n@param {object} props - the properties used by RadioColumn\n@param {object} styles - styles for RadioButton\n@returns {React.ReactElement}",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "rowId",
      description: "the id of the row",
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "props",
      description: "the properties used by RadioColumn",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the default cell with the radio button"
  }],
  actualName: "RadioColumn",
  metadataType: "container",
  props: {
    option: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            name: {
              name: "string",
              required: !1
            },
            code: {
              name: "string",
              required: !1
            }
          }
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "The option to be displayed in cell"
    },
    onOption: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The option to be displayed in cell"
    },
    value: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Value of selected row"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On radio button click"
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "RadioColumn.defaultCell",
        computed: !0
      },
      required: !1
    },
    getRowActions: {
      defaultValue: {
        value: "RadioColumn.getRowActions",
        computed: !0
      },
      required: !1
    },
    onFilter: {
      defaultValue: {
        value: "filterValue => {\n    const textFilter = createTextFilter(filterValue);\n\n    return ({ name }) => textFilter(name);\n}",
        computed: !1
      },
      required: !1
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["../table/TableColumn"]
}, renderRadioButton.__docgenInfo = {
  componentName: "renderRadioButton",
  packageName: "@jutro/datatable",
  description: "Render the button with action defined on onclick listener",
  displayName: "renderRadioButton",
  methods: [],
  actualName: "renderRadioButton"
};