import { defineMessages } from "react-intl";
export var messages = defineMessages({
  placeholder: {
    id: "jutro-components.widgets.inputs.YearField.placeholder",
    defaultMessage: "YYYY"
  },
  todayButton: {
    id: "jutro-components.widgets.inputs.YearField.todayButton",
    defaultMessage: "Current year"
  },
  previousYearRange: {
    id: "jutro-components.widgets.inputs.YearField.previousYearRange",
    defaultMessage: "Previous years"
  },
  nextYearRange: {
    id: "jutro-components.widgets.inputs.YearField.nextYearRange",
    defaultMessage: "Next years"
  },
  validationMinYear: {
    id: "jutro-components.widgets.inputs.YearField.The minimum allowed year is",
    defaultMessage: "The minimum allowed year is {value}"
  },
  validationMaxYear: {
    id: "jutro-components.widgets.inputs.YearField.The maximum allowed year is",
    defaultMessage: "The maximum allowed year is {value}"
  }
});