import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Symbol$toPrimitive from "@babel/runtime-corejs3/core-js-stable/symbol/to-primitive";
function _toPropertyKey(arg) {
  var key = function (input, hint) {
    if ("object" !== _typeof(input) || null === input) return input;
    var prim = input[_Symbol$toPrimitive];
    if (void 0 !== prim) {
      var res = prim.call(input, hint || "default");
      if ("object" !== _typeof(res)) return res;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return ("string" === hint ? String : Number)(input);
  }(arg, "string");
  return "symbol" === _typeof(key) ? key : String(key);
}
import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { Button, Badge, InputField, Collapse } from "@jutro/components";
import cx from "classnames";
import internalStyles from "./FilterBar.module.css";
import { messages } from "./FilterBar.messages";
import { FilterBarContent } from "./FilterBarContent";
import { DEFAULT_SEARCH_KEY } from "./utils";
var filterBarPropTypes = {
  uiProps: PropTypes.object,
  classNameMap: PropTypes.object,
  componentMap: PropTypes.object,
  callbackMap: PropTypes.object,
  onFiltersChange: PropTypes.func.isRequired,
  initialFilters: PropTypes.object,
  searchKey: PropTypes.string
};
export var FilterBar = function (_ref) {
  var _context,
    callbackMap = _ref.callbackMap,
    componentMap = _ref.componentMap,
    initialFiltersProp = _ref.initialFilters,
    onFiltersChangeProp = _ref.onFiltersChange,
    metadata = _ref.uiProps,
    searchKey = _ref.searchKey,
    styles = _ref.styles,
    initialSearch = initialFiltersProp[searchKey],
    initialFilters = _objectWithoutProperties(initialFiltersProp, _mapInstanceProperty(_context = [searchKey]).call(_context, _toPropertyKey)),
    _useState = useState(initialSearch),
    _useState2 = _slicedToArray(_useState, 2),
    search = _useState2[0],
    setSearch = _useState2[1],
    searchDebounced = useDebounce(search, 300),
    _useState3 = useState(initialFilters),
    _useState4 = _slicedToArray(_useState3, 2),
    filters = _useState4[0],
    setFilters = _useState4[1],
    onFiltersChangeRef = useRef(null),
    searchKeyRef = useRef(searchKey),
    _useState5 = useState(!1),
    _useState6 = _slicedToArray(_useState5, 2),
    isShown = _useState6[0],
    setShown = _useState6[1],
    translator = useTranslator(),
    numberFiltersApplied = _Object$keys(filters).length,
    isFiltersEmpty = 0 === numberFiltersApplied,
    toggleFilterBar = useCallback(function () {
      setShown(!isShown);
    }, [isShown]);
  useEffect(function () {
    if (onFiltersChangeRef.current) {
      var newFilters = function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = null != arguments[i] ? arguments[i] : {};
          i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
          }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
          });
        }
        return target;
      }({}, filters);
      searchDebounced && (newFilters[searchKeyRef.current] = searchDebounced), onFiltersChangeRef.current(newFilters);
    }
  }, [searchDebounced, filters]), useEffect(function () {
    onFiltersChangeRef.current = onFiltersChangeProp;
  }, [onFiltersChangeProp]);
  var filterBarContentProps = {
      styles: styles,
      metadata: metadata,
      onFiltersChange: setFilters,
      componentMap: componentMap,
      callbackMap: callbackMap,
      isFiltersEmpty: isFiltersEmpty,
      initialFilters: filters
    },
    manageFiltersButtonClassNames = cx(internalStyles.manageFiltersButton);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: internalStyles.searchBar
  }, React.createElement(InputField, {
    id: "searchFilterBar",
    icon: "gw-search",
    iconPosition: "right",
    value: search,
    onValueChange: setSearch,
    placeholder: translator(messages.searchInput),
    controlClassName: internalStyles.searchField
  }), metadata && React.createElement(Button, {
    id: "manageFilters",
    icon: "gw-tune",
    type: "outlined",
    onClick: toggleFilterBar,
    className: manageFiltersButtonClassNames
  }, translator(messages.manageFilters), !isFiltersEmpty && React.createElement(Badge, {
    id: "filtersCount",
    type: "info",
    value: numberFiltersApplied,
    className: internalStyles.manageFiltersBadge
  }))), metadata && React.createElement(Collapse, {
    isOpen: isShown
  }, React.createElement(FilterBarContent, filterBarContentProps)));
};
FilterBar.propTypes = filterBarPropTypes, FilterBar.defaultProps = {
  initialFilters: {},
  searchKey: DEFAULT_SEARCH_KEY
}, FilterBar.__docgenInfo = {
  description: "Component for filters rendering, supplied through metadata\n\n@type {React.FC<PropTypes.InferProps<typeof filterBarPropTypes>>}\n\n@metadataType container",
  methods: [],
  displayName: "FilterBar",
  props: {
    initialFilters: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      type: {
        name: "object"
      },
      required: !1,
      description: "Object with initial filters to be applied"
    },
    searchKey: {
      defaultValue: {
        value: "DEFAULT_SEARCH_KEY",
        computed: !0
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of search filter key in filter data. Can not change during component lifetime."
    },
    uiProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Content metadata or an array of metadata"
    },
    classNameMap: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Resolve class name to hashed class name"
    },
    componentMap: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Resolve component map"
    },
    callbackMap: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Resolve callback name to a callback function"
    },
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback for when filters are changed"
    }
  }
}, FilterBar.__docgenInfo = {
  componentName: "FilterBar",
  packageName: "@jutro/datatable",
  description: "Component for filters rendering, supplied through metadata",
  displayName: "FilterBar",
  methods: [],
  actualName: "FilterBar",
  metadataType: "container",
  props: {
    uiProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Content metadata or an array of metadata"
    },
    classNameMap: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Resolve class name to hashed class name"
    },
    componentMap: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Resolve component map"
    },
    callbackMap: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Resolve callback name to a callback function"
    },
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback for when filters are changed"
    },
    initialFilters: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Object with initial filters to be applied",
      defaultValue: {
        value: "{}",
        computed: !1
      }
    },
    searchKey: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of search filter key in filter data. Can not change during component lifetime.",
      defaultValue: {
        value: "'search'",
        computed: !1
      }
    }
  }
};