import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import styles from "./ColorSwatch.module.css";
import { hexColorElements } from "./hexColorElements";
import { getKeyPressHandler } from "../../../accessibility/getKeyPressHandler";
import { isBright } from "./utils";
var solidColorPropTypes = PropTypes.shape({
    hexColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  }),
  gradientColorPropTypes = PropTypes.shape({
    startingColor: PropTypes.string.isRequired,
    startingHexColor: PropTypes.string.isRequired,
    finishingColor: PropTypes.string.isRequired,
    finishingHexColor: PropTypes.string.isRequired
  });
var boxStyleByType = {
    solid: function (_ref) {
      var color = _ref.color,
        hexColor = _ref.hexColor;
      return {
        backgroundColor: color,
        borderColor: isBright(hexColor) ? "var(--GW-BORDER-COLOR)" : color
      };
    },
    gradient: function (_ref2) {
      var _context,
        startingColor = _ref2.startingColor,
        startingHexColor = _ref2.startingHexColor,
        finishingColor = _ref2.finishingColor,
        finishingHexColor = _ref2.finishingHexColor,
        shouldHaveBorder = isBright(startingHexColor) || isBright(finishingHexColor),
        gradientColorValue = _concatInstanceProperty(_context = "linear-gradient(to right, ".concat(startingColor, ", ")).call(_context, finishingColor, ")");
      return function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = null != arguments[i] ? arguments[i] : {};
          i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
          }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
          });
        }
        return target;
      }({
        backgroundImage: gradientColorValue
      }, shouldHaveBorder ? {
        borderColor: "var(--GW-BORDER-COLOR)"
      } : {
        borderImage: gradientColorValue,
        borderImageSlice: 1
      });
    }
  },
  colorSwatchPropTypes = {
    title: intlMessageShape.isRequired,
    colorProperties: PropTypes.oneOfType([solidColorPropTypes, gradientColorPropTypes]).isRequired,
    type: PropTypes.oneOf(["gradient", "solid"]).isRequired,
    onClick: PropTypes.func
  };
export var ColorSwatch = function (props) {
  var translator = useTranslator(),
    title = props.title,
    colorProperties = props.colorProperties,
    type = props.type,
    className = props.className,
    onClick = props.onClick,
    noDeprecationWarnings = props.noDeprecationWarnings;
  function handleClick(event) {
    onClick && onClick(event);
  }
  useDeprecationWarning({
    componentName: "ColorSwatch",
    noDeprecationWarnings: noDeprecationWarnings
  });
  var swatchClasses = cx(styles.colorSwatch, className),
    colorElements = hexColorElements(type, colorProperties, styles.color);
  return React.createElement("div", {
    tabIndex: "0",
    role: "button",
    className: swatchClasses,
    onClick: handleClick,
    onKeyPress: getKeyPressHandler(handleClick)
  }, React.createElement("div", {
    className: styles.box,
    style: boxStyleByType[type](colorProperties)
  }), React.createElement("div", {
    className: styles.title
  }, translator(title)), colorElements);
};
ColorSwatch.propTypes = colorSwatchPropTypes, ColorSwatch.defaultProps = {
  type: "solid"
}, ColorSwatch.__docgenInfo = {
  description: "ColorSwatch\n@type {React.FC<PropTypes.InferProps<typeof colorSwatchPropTypes>>}\n\n@metadataType action\n@deprecated since v8.9.0",
  methods: [],
  displayName: "ColorSwatch",
  props: {
    type: {
      defaultValue: {
        value: "'solid'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'gradient'",
          computed: !1
        }, {
          value: "'solid'",
          computed: !1
        }]
      },
      required: !1,
      description: "Type of color, either gradient or solid"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Title of the color"
    },
    colorProperties: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "solidColorPropTypes"
        }, {
          name: "custom",
          raw: "gradientColorPropTypes"
        }]
      },
      required: !0,
      description: "Color properties containing either color and hex color for solid colors or starting and finishing colors and hexColors of a gradient"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom `onClick` handler"
    }
  }
}, ColorSwatch.__docgenInfo = {
  componentName: "ColorSwatch",
  packageName: "@jutro/components",
  description: "ColorSwatch",
  displayName: "ColorSwatch",
  methods: [],
  actualName: "ColorSwatch",
  metadataType: "action",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Title of the color"
    },
    colorProperties: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "solidColorPropTypes"
        }, {
          name: "custom",
          raw: "gradientColorPropTypes"
        }]
      },
      required: !0,
      description: "Color properties containing either color and hex color for solid colors or starting and finishing colors and hexColors of a gradient"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'gradient'",
          computed: !1
        }, {
          value: "'solid'",
          computed: !1
        }]
      },
      required: !1,
      description: "Type of color, either gradient or solid",
      defaultValue: {
        value: "'solid'",
        computed: !1
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom `onClick` handler"
    }
  }
};