import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
var _class;
import { service, ServiceManager } from "@jutro/services";
import { log } from "@jutro/logger";
import { logDeprecationMessage } from "@jutro/platform";
export var PROMPT_SERVICE_ID = "PROMPT_SERVICE";
var prompts = {};
service(PROMPT_SERVICE_ID)(_class = function () {
  function PromptServiceInternal() {
    _classCallCheck(this, PromptServiceInternal);
  }
  return _createClass(PromptServiceInternal, [{
    key: "pushPrompt",
    value: function (name, props) {
      logDeprecationMessage("PromptService"), "development" === process.env.NODE_ENV && prompts[name] ? log.warning("The prompt name ".concat(name, " has been used")) : prompts[name] = props;
    }
  }, {
    key: "popPrompt",
    value: function (name) {
      logDeprecationMessage("PromptService");
      var prompt = prompts[name];
      return delete prompts[name], prompt;
    }
  }]), PromptServiceInternal;
}());
export var PromptService = ServiceManager.getService(PROMPT_SERVICE_ID);