import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import { getConfigValue } from "@jutro/config";
import { logDeprecationMessage } from "@jutro/platform";
import loadGtag from "../gtag";
import { subscribe } from "../EventProvider";
import JUTRO_TOPICS from "../jutroTopics";
import WhitelistProvider from "./WhitelistProvider";
import DictionaryProvider from "./DictionaryProvider";
export default function () {
  var trackingConfig = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {
      whitelistConfig: {},
      dictionaryConfig: {}
    },
    ga4TrackingId = getConfigValue("GA4_TRACKING_ID"),
    deprecatedGaTrackingId = getConfigValue("GA_TRACKING_ID"),
    additionalId = getConfigValue("GA_ADDITIONAL_TRACKING_ID"),
    trackingId = null != ga4TrackingId ? ga4TrackingId : deprecatedGaTrackingId,
    gtag = loadGtag(trackingId);
  deprecatedGaTrackingId && logDeprecationMessage("REACT_APP_GA_TRACKING_ID environment variable", "REACT_APP_GA4_TRACKING_ID environment variable"), additionalId && (gtag("config", additionalId), logDeprecationMessage("REACT_APP_GA_ADDITIONAL_TRACKING_ID environment variable")), subscribe(JUTRO_TOPICS.UNKNOWN_ERROR, function (event) {
    return gtag("event", JUTRO_TOPICS.UNKNOWN_ERROR, {
      event_category: event.error,
      event_label: _JSON$stringify(event)
    });
  }), subscribe([JUTRO_TOPICS.VALUE_CHANGED, JUTRO_TOPICS.FIELD_BLUR], function (event, topic) {
    var _trackingConfig$dicti, _trackingConfig$dicti2;
    return gtag("event", topic, {
      event_category: null === (_trackingConfig$dicti = trackingConfig.dictionaryConfig) || void 0 === _trackingConfig$dicti || null === (_trackingConfig$dicti2 = _trackingConfig$dicti.eventKeys) || void 0 === _trackingConfig$dicti2 ? void 0 : _trackingConfig$dicti2.path,
      event_label: event.path
    });
  }), subscribe([JUTRO_TOPICS.BUTTON_CLICKED, JUTRO_TOPICS.LINK_CLICKED], function (event, topic) {
    return gtag("event", topic, {
      event_category: event.label,
      event_label: _JSON$stringify(event)
    });
  }), subscribe([JUTRO_TOPICS.ROUTE_CHANGED], function (event, topic) {
    gtag("event", topic, {
      event_category: event.event_category,
      event_label: event.pathname
    });
  }), subscribe([JUTRO_TOPICS.FETCH_REQUEST, JUTRO_TOPICS.FETCH_RESPONSE, JUTRO_TOPICS.FETCH_ERROR], function (event, topic) {
    gtag("event", topic, {
      event_category: event.event_category,
      event_label: event.url
    });
  });
  var whitelist = WhitelistProvider.create(trackingConfig.whitelistConfig),
    translate = DictionaryProvider.create(trackingConfig.dictionaryConfig);
  subscribe(JUTRO_TOPICS.COMPONENT_LOADED, function (event, topic) {
    var transformedEvent = translate(whitelist(event));
    gtag("event", topic, {
      event_category: "Props",
      event_label: _JSON$stringify(transformedEvent)
    });
  });
}