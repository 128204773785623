import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _eventTopicMap;
import { JUTRO_TOPICS } from "@jutro/events";
export var HttpEventTypes;
!function (HttpEventTypes) {
  HttpEventTypes.request = "request", HttpEventTypes.response = "response", HttpEventTypes.error = "error", HttpEventTypes.exception = "exception";
}(HttpEventTypes || (HttpEventTypes = {}));
export var eventTopicMap = (_eventTopicMap = {}, _defineProperty(_eventTopicMap, HttpEventTypes.request, JUTRO_TOPICS.FETCH_REQUEST), _defineProperty(_eventTopicMap, HttpEventTypes.response, JUTRO_TOPICS.FETCH_RESPONSE), _defineProperty(_eventTopicMap, HttpEventTypes.error, JUTRO_TOPICS.FETCH_ERROR), _defineProperty(_eventTopicMap, HttpEventTypes.exception, JUTRO_TOPICS.FETCH_ERROR), _eventTopicMap);
export var JSON_CONTENT_TYPE = "application/json";
export var PROBLEM_JSON_CONTENT_TYPE = "application/problem+json";
export var BLOB_CONTENT_TYPE = "application/octet-stream";
export var CONTENT_TYPE = "Content-Type";
export var JSON_CONTENT_TYPES = [JSON_CONTENT_TYPE, PROBLEM_JSON_CONTENT_TYPE];