import { defineMessages } from "react-intl";
export var messages = defineMessages({
  closeTagLabel: {
    id: "jutro-components.widgets.Tag.close",
    defaultMessage: "Delete tag: {label}"
  },
  expandLabel: {
    id: "jutro-components.widgets.Tag.expand",
    defaultMessage: "Collapsed tag collection. Number of available items: {items}. Select to expand."
  }
});