import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children"];
import React from "react";
import { Button, Icon } from "@jutro/components";
import styles from "./ContextSwitcher.module.css";
export var ContextSwitcherButton = React.forwardRef(function (_ref, ref) {
  var children = _ref.children,
    others = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Button, _extends({
    className: styles.contextSwitcherButton,
    renderContent: function () {
      return React.createElement(React.Fragment, null, children, React.createElement(Icon, {
        icon: "gw-expand-more"
      }));
    }
  }, others, {
    ref: ref
  }));
});
ContextSwitcherButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "ContextSwitcherButton"
};