import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import cx from "classnames";
import { Icon } from "../../../Icon/Icon";
export var LookupAddLink = function (_ref) {
  var data = _ref.data,
    className = _ref.className,
    _ref$selectProps = _ref.selectProps,
    customStyles = _ref$selectProps.customStyles,
    onAddNew = _ref$selectProps.onAddNew,
    innerProps = _ref.innerProps;
  return onAddNew ? React.createElement(React.Fragment, null, React.createElement("button", _extends({
    className: cx(customStyles.option, customStyles.addLink, className)
  }, innerProps, {
    onClick: function () {
      return onAddNew(data.value);
    }
  }), React.createElement(Icon, {
    icon: "gw-add"
  }), data.label)) : null;
};
LookupAddLink.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupAddLink"
}, LookupAddLink.__docgenInfo = {
  componentName: "LookupAddLink",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupAddLink",
  methods: [],
  actualName: "LookupAddLink"
};