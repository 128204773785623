import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import range from "lodash/range";
export var SHOW_ALL_PAGES = "All";
export function getVisiblePages(page, totalPages) {
  var _context3,
    _context,
    _context2,
    currentPage = page + 1;
  return totalPages <= 7 ? range(1, totalPages + 1) : currentPage < 5 ? _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(range(1, 6)), [totalPages]) : currentPage + 5 - 1 > totalPages ? _concatInstanceProperty(_context2 = [1]).call(_context2, _toConsumableArray(range(totalPages - 5 + 2, totalPages + 1))) : _concatInstanceProperty(_context3 = [1]).call(_context3, _toConsumableArray(range(currentPage - 2, currentPage + 5 - 2)), [totalPages]);
}