import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
export var animationDelayDefault = 400;
var getHeight = function (node) {
    return node.scrollHeight;
  },
  setHeight = function (node, height) {
    node.style.height = "".concat(height, "px");
  },
  collapse = function (node) {
    return setHeight(node, 0);
  },
  flushHeight = function (node) {
    return setHeight(node, getHeight(node));
  },
  simpleAnimationMap = {
    none: {},
    highlight: {
      classNames: "highlight",
      timeout: {
        enter: animationDelayDefault
      },
      exit: !1
    },
    insert: {
      classNames: "insert",
      timeout: {
        enter: animationDelayDefault,
        exit: animationDelayDefault / 2
      }
    },
    expand: function () {
      var height;
      return {
        classNames: "expand",
        onEnter: function (node) {
          height = getHeight(node), collapse(node);
        },
        onEntering: function (node) {
          setHeight(node, height);
        },
        onExit: flushHeight,
        onExiting: collapse,
        timeout: {
          enter: animationDelayDefault,
          exit: animationDelayDefault / 2
        }
      };
    },
    reveal: {
      classNames: "reveal",
      transitionWrapper: "reveal_base",
      timeout: animationDelayDefault
    }
  };
export function resolveAnimationFromName(name) {
  return void 0 === name ? simpleAnimationMap.none : isPlainObject(name) || isFunction(name) ? name : simpleAnimationMap[name] || simpleAnimationMap.none;
}