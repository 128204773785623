import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./FooterNavBar.module.css";
import { useFooterDeprecationMessage } from "./FooterDeprecationContext_DEPRECATED";
var footerNavLinkPropTypes = {
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  url: intlMessageShape
};
export var FooterNavLink = function (props) {
  useFooterDeprecationMessage("FooterNavLink");
  var header = props.header,
    url = props.url,
    className = props.className,
    translator = useTranslator(),
    urlTranslatorAndSanitizer = useSafeTranslatedUrls(),
    classes = cx(styles.navItem, className);
  return React.createElement("div", {
    className: classes
  }, React.createElement("a", {
    tabIndex: "0",
    className: styles.navLink,
    role: "link",
    href: urlTranslatorAndSanitizer(url)
  }, translator(header)));
};
FooterNavLink.propTypes = footerNavLinkPropTypes, FooterNavLink.__docgenInfo = {
  description: "The `FooterNavLink` component is designed as a simple component for presenting a navigation link inside the `FooterNavBar` container.\n\n@metadataType action\n\n@type {React.FC<PropTypes.InferProps<typeof footerNavLinkPropTypes>>}\n@deprecated since v8.9.0",
  methods: [],
  displayName: "FooterNavLink",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Link header"
    },
    url: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "External link"
    }
  }
}, FooterNavLink.__docgenInfo = {
  componentName: "FooterNavLink",
  packageName: "@jutro/components",
  description: "The `FooterNavLink` component is designed as a simple component for presenting a navigation link inside the `FooterNavBar` container.",
  displayName: "FooterNavLink",
  methods: [],
  actualName: "FooterNavLink",
  metadataType: "action",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Link header"
    },
    url: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "External link"
    }
  }
};