import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _get from "@babel/runtime-corejs3/helpers/get";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["inputFormat"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import isDate from "lodash/isDate";
import { isExists as doesDateExist } from "date-fns";
import { dateFormatShape, dateValueShape, dataTypeShapeWithDateTime, dateValidationRuleShape, stringValidationRuleShape, DATA_TYPE_OBJECT } from "@jutro/prop-types";
import { IntlContext, LocaleContext, formatDateToDataType, parseDateFromDataType, trimToInputFormat, inputFormatToValueParts } from "@jutro/locale";
import { Flex } from "@jutro/layout";
import { getValidationMessageStyle } from "@jutro/validation/internal";
import { isAccessibleDisabled, muteCallbacks } from "../../../../accessibleDisabled";
import { OldSimpleDateFieldValidationImplementation } from "./OldSimpleDateFieldValidationImplementation";
import { DateValue } from "../../../values/DateValue";
import { FieldComponent } from "../../FieldComponent/FieldComponent";
import { messages } from "./SimpleDateField.messages";
import { isNumberKey, extractFieldFormat, toInt, toLocaleFormat, toZeroIndexedMonth, toOneIndexedMonth, toFourDigitYear, toTwoDigitYear, applyFormatters, isValidDateObject, extendDateWithDefaults, getInputFormat, DEFAULT_INPUT_FORMAT } from "./helpers";
import { moveFocusToNextField, moveFocusToPrevField } from "./keyboardNavigation";
import inputFieldStyles from "../../InputField/InputField.module.css";
import styles from "./SimpleDateField.module.css";
var inputLabels = {
    day: messages.dayInputLabel,
    month: messages.monthInputLabel,
    year: messages.yearInputLabel
  },
  SimpleDateFieldInternal = function (_FieldComponent) {
    _inherits(SimpleDateFieldInternal, FieldComponent);
    var _super = _createSuper(SimpleDateFieldInternal);
    function SimpleDateFieldInternal(props) {
      var _this$props$value, _this$props$value2, _this$props$value3, _thisSuper, _thisSuper2, _this;
      return _classCallCheck(this, SimpleDateFieldInternal), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "isPristine", {
        day: !0,
        month: !0,
        year: !0
      }), _defineProperty(_assertThisInitialized(_this), "toUnformattedValueMappers", {
        day: [toInt],
        month: [toInt, toZeroIndexedMonth],
        year: [toFourDigitYear, toInt]
      }), _defineProperty(_assertThisInitialized(_this), "toDisplayedValueMappers", {
        day: [toLocaleFormat],
        month: [toOneIndexedMonth, toLocaleFormat],
        year: [toTwoDigitYear]
      }), _defineProperty(_assertThisInitialized(_this), "state", {
        day: null === (_this$props$value = _this.props.value) || void 0 === _this$props$value ? void 0 : _this$props$value.day,
        month: null === (_this$props$value2 = _this.props.value) || void 0 === _this$props$value2 ? void 0 : _this$props$value2.month,
        year: null === (_this$props$value3 = _this.props.value) || void 0 === _this$props$value3 ? void 0 : _this$props$value3.year,
        focusedFieldValue: "",
        dayFocused: !1,
        monthFocused: !1,
        yearFocused: !1,
        isFocused: !1
      }), _defineProperty(_assertThisInitialized(_this), "inputRefs", []), _defineProperty(_assertThisInitialized(_this), "handleInputChange", function (inputValue, fieldType) {
        _this.isPristine[fieldType] = !1;
        var _this$props = _this.props,
          yearFormat = _this$props.yearFormat,
          minDate = _this$props.minDate,
          maxDate = _this$props.maxDate,
          fieldValue = applyFormatters(_this.toUnformattedValueMappers[fieldType], inputValue, {
            yearFormat: yearFormat,
            minDate: minDate,
            maxDate: maxDate
          });
        _this.setState(_defineProperty({
          focusedFieldValue: inputValue
        }, fieldType, fieldValue), _this.updateValue);
      }), _defineProperty(_assertThisInitialized(_this), "handleInputFocus", function (event, fieldType) {
        var _this$setState2,
          yearFormat = _this.props.yearFormat,
          inputValue = applyFormatters(_this.toDisplayedValueMappers[fieldType], _this.getSingleFieldValue(fieldType), {
            localeFormat: extractFieldFormat(fieldType, _this.getInputFormat()),
            yearFormat: yearFormat
          });
        _this.setState((_defineProperty(_this$setState2 = {}, "".concat(fieldType, "Focused"), !0), _defineProperty(_this$setState2, "focusedFieldValue", inputValue || ""), _this$setState2)), _get((_thisSuper = _assertThisInitialized(_this), _getPrototypeOf(SimpleDateFieldInternal.prototype)), "handleFocus", _thisSuper).call(_thisSuper, event);
      }), _defineProperty(_assertThisInitialized(_this), "handleInputBlur", function (event, fieldType) {
        _this.setState(_defineProperty({}, "".concat(fieldType, "Focused"), !1)), _get((_thisSuper2 = _assertThisInitialized(_this), _getPrototypeOf(SimpleDateFieldInternal.prototype)), "handleBlur", _thisSuper2).call(_thisSuper2, event);
      }), props.registerValidation || (_this.validationImplementation = new OldSimpleDateFieldValidationImplementation().bind(_assertThisInitialized(_this))), _this;
    }
    return _createClass(SimpleDateFieldInternal, [{
      key: "isFieldPristine",
      value: function () {
        var value = this.props.value,
          _this$isPristine = this.isPristine,
          day = _this$isPristine.day,
          month = _this$isPristine.month,
          year = _this$isPristine.year;
        return !value && (day || month || year);
      }
    }, {
      key: "getSingleFieldValue",
      value: function (fieldType) {
        var value = this.props.value;
        return isNil(null == value ? void 0 : value[fieldType]) ? this.state[fieldType] : value[fieldType];
      }
    }, {
      key: "getValueToDisplay",
      value: function (fieldType) {
        if (this.state["".concat(fieldType, "Focused")]) return this.state.focusedFieldValue;
        var fieldValue = this.getSingleFieldValue(fieldType);
        if (isNil(fieldValue)) return "";
        var yearFormat = this.props.yearFormat;
        return applyFormatters(this.toDisplayedValueMappers[fieldType], fieldValue, {
          localeFormat: extractFieldFormat(fieldType, this.getInputFormat()),
          yearFormat: yearFormat
        });
      }
    }, {
      key: "getValidationConfig",
      value: function () {
        for (var _get2, _context, _context2, _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        var validationConfig = (_get2 = _get(_getPrototypeOf(SimpleDateFieldInternal.prototype), "getValidationConfig", this)).call.apply(_get2, _concatInstanceProperty(_context = [this]).call(_context, args)),
          _this$props2 = this.props,
          intl = _this$props2.intl,
          locale = _this$props2.locale,
          displayFormat = _this$props2.displayFormat;
        return _objectSpread(_objectSpread({}, validationConfig), {}, {
          propsList: _concatInstanceProperty(_context2 = ["inputFormat", "minDate", "maxDate"]).call(_context2, _toConsumableArray((null == validationConfig ? void 0 : validationConfig.propsList) || [])),
          options: {
            intl: intl,
            locale: locale,
            format: displayFormat,
            localePattern: this.getInputFormat()
          }
        });
      }
    }, {
      key: "getInputFormat",
      value: function () {
        var inputFormat = this.props.inputFormat;
        return Array.isArray(inputFormat) ? _slicedToArray(inputFormat, 1)[0] || DEFAULT_INPUT_FORMAT : inputFormat || DEFAULT_INPUT_FORMAT;
      }
    }, {
      key: "getFieldMaxLength",
      value: function (fieldType) {
        var yearFormat = this.props.yearFormat;
        return {
          day: 2,
          month: 2,
          year: (null == yearFormat ? void 0 : yearFormat.length) || 4
        }[fieldType];
      }
    }, {
      key: "updateValue",
      value: function () {
        var dataType = this.props.dataType;
        if (!isValidDateObject(this.state, this.getInputFormat())) return this.notifyChange(void 0, !this.isFieldPristine());
        var _extendDateWithDefaul = extendDateWithDefaults(this.state),
          year = _extendDateWithDefaul.year,
          month = _extendDateWithDefaul.month,
          day = _extendDateWithDefaul.day;
        if (doesDateExist(year, month, day)) {
          var formattedDate = formatDateToDataType(new Date(year, month, day), dataType);
          return this.notifyChange(trimToInputFormat(formattedDate, this.getInputFormat()), !this.isFieldPristine());
        }
        return this.notifyChange({
          year: year,
          month: month,
          day: day
        }, !this.isFieldPristine());
      }
    }, {
      key: "selectInputValue",
      value: function (input) {
        input && input.value.length > 0 && input.select();
      }
    }, {
      key: "renderControlReadOnly",
      value: function (breakpointProps) {
        var id = breakpointProps.id,
          value = breakpointProps.value,
          displayFormat = breakpointProps.displayFormat;
        return React.createElement(DateValue, _extends({
          id: id,
          value: value,
          displayFormat: displayFormat,
          inputFormat: this.getInputFormat(),
          skipTimeZone: !0
        }, this.generateReadOnlyAccessibilityProperties()));
      }
    }, {
      key: "getLabelledControlId",
      value: function () {
        var _context3,
          _inputFormatToValuePa = inputFormatToValueParts(this.getInputFormat()),
          firstFieldType = _slicedToArray(_inputFormatToValuePa, 1)[0];
        return _concatInstanceProperty(_context3 = "".concat(this.fieldUniqueId, "-")).call(_context3, firstFieldType);
      }
    }, {
      key: "renderControl",
      value: function (breakpointProps, options) {
        var _this2 = this,
          uniqId = this.fieldUniqueId,
          id = breakpointProps.id,
          yearFormat = breakpointProps.yearFormat,
          required = breakpointProps.required,
          disabled = breakpointProps.disabled,
          autoFocus = breakpointProps.autoFocus,
          controlClassName = breakpointProps.controlClassName,
          validationMessages = breakpointProps.validationMessages,
          registerValidation = breakpointProps.registerValidation,
          _breakpointProps$test = breakpointProps.testId,
          testId = void 0 === _breakpointProps$test ? id : _breakpointProps$test,
          isValid = options.isValid,
          labelId = options.labelId,
          fieldTypes = inputFormatToValueParts(this.getInputFormat());
        this.inputRefs = _mapInstanceProperty(fieldTypes).call(fieldTypes, function () {
          return React.createRef();
        });
        var messageStyle = getValidationMessageStyle(validationMessages),
          onInputChange = function (event, fieldType) {
            return _this2.handleInputChange(event.target.value, fieldType);
          },
          onInputFocus = function (event, fieldType) {
            var _this2$inputRefs$inde;
            if (_this2.handleInputFocus(event, fieldType), autoFocus) {
              var index = _indexOfInstanceProperty(fieldTypes).call(fieldTypes, fieldType);
              _this2.selectInputValue(null === (_this2$inputRefs$inde = _this2.inputRefs[index]) || void 0 === _this2$inputRefs$inde ? void 0 : _this2$inputRefs$inde.current);
            }
          },
          onInputBlur = function (event, fieldType) {
            return _this2.handleInputBlur(event, fieldType);
          },
          onInputKeyUp = function (event, fieldType) {
            var _this2$inputRefs$inde2, _this2$inputRefs;
            if (autoFocus) {
              var index = _indexOfInstanceProperty(fieldTypes).call(fieldTypes, fieldType);
              moveFocusToNextField(event, {
                current: null === (_this2$inputRefs$inde2 = _this2.inputRefs[index]) || void 0 === _this2$inputRefs$inde2 ? void 0 : _this2$inputRefs$inde2.current,
                next: null === (_this2$inputRefs = _this2.inputRefs[index + 1]) || void 0 === _this2$inputRefs ? void 0 : _this2$inputRefs.current
              });
            }
          },
          onInputKeyDown = function (event, fieldType) {
            var _this2$inputRefs$inde3, _this2$inputRefs2;
            if (autoFocus) {
              var index = _indexOfInstanceProperty(fieldTypes).call(fieldTypes, fieldType);
              moveFocusToPrevField(event, {
                current: null === (_this2$inputRefs$inde3 = _this2.inputRefs[index]) || void 0 === _this2$inputRefs$inde3 ? void 0 : _this2$inputRefs$inde3.current,
                prev: null === (_this2$inputRefs2 = _this2.inputRefs[index - 1]) || void 0 === _this2$inputRefs2 ? void 0 : _this2$inputRefs2.current
              });
            }
          },
          accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields");
        return React.createElement(Flex, {
          id: uniqId,
          "data-testid": testId,
          className: styles.container
        }, _mapInstanceProperty(fieldTypes).call(fieldTypes, function (fieldType, index) {
          var _context4,
            _cx,
            _context5,
            _context6,
            inputId = _concatInstanceProperty(_context4 = "".concat(uniqId, "-")).call(_context4, fieldType),
            fieldLabelId = "".concat(inputId, "-label"),
            inputStyles = cx(inputFieldStyles.input, controlClassName, (_defineProperty(_cx = {
              disabled: disabled
            }, styles.twoDigits, 2 === _this2.getFieldMaxLength(fieldType)), _defineProperty(_cx, styles.fourDigits, 4 === _this2.getFieldMaxLength(fieldType)), _defineProperty(_cx, inputFieldStyles.labpreviewBorder, !isUndefined(registerValidation) && !isValid), _defineProperty(_cx, messageStyle, !isValid), _cx)),
            accessibleDisabledProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
              onChange: onInputChange,
              onFocus: onInputFocus,
              onBlur: onInputBlur,
              onKeyUp: onInputKeyUp,
              onKeyDown: onInputKeyDown,
              onKeyPress: isNumberKey
            })), {}, {
              "aria-disabled": !0,
              "aria-readonly": !0,
              readOnly: !0,
              disabled: void 0
            }) : {};
          return React.createElement("div", {
            key: inputId
          }, React.createElement("label", {
            id: fieldLabelId,
            htmlFor: inputId,
            className: styles.hidden
          }, _this2.translator(inputLabels[fieldType])), React.createElement("input", _extends({
            id: inputId,
            type: "text",
            ref: _this2.inputRefs[index],
            className: inputStyles,
            value: _this2.getValueToDisplay(fieldType),
            onChange: function (event) {
              return onInputChange(event, fieldType);
            },
            onFocus: function (event) {
              return onInputFocus(event, fieldType);
            },
            onBlur: function (event) {
              return onInputBlur(event, fieldType);
            },
            onKeyUp: function (event) {
              return onInputKeyUp(event, fieldType);
            },
            onKeyDown: function (event) {
              return onInputKeyDown(event, fieldType);
            },
            onKeyPress: isNumberKey,
            required: Boolean(required),
            disabled: Boolean(disabled),
            placeholder: extractFieldFormat(fieldType, _this2.getInputFormat(), yearFormat),
            "aria-labelledby": _concatInstanceProperty(_context5 = "".concat(labelId, " ")).call(_context5, fieldLabelId),
            maxLength: _this2.getFieldMaxLength(fieldType),
            pattern: "[0-9]*",
            "data-testid": _concatInstanceProperty(_context6 = "".concat(testId, "-")).call(_context6, fieldType)
          }, accessibleDisabledProps)));
        }));
      }
    }]), SimpleDateFieldInternal;
  }();
export var SimpleDateField = function (_ref) {
  var userInputFormat = _ref.inputFormat,
    props = _objectWithoutProperties(_ref, _excluded),
    intl = useContext(IntlContext),
    dateLocale = useContext(LocaleContext).dateLocale,
    value = props.value,
    inputFormat = getInputFormat(["day", "month", "year"], dateLocale, userInputFormat),
    selectedValue = useMemo(function () {
      if (value) {
        var dateToObject = function (date) {
          return {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate()
          };
        };
        if (isString(value) || isNumber(value)) return dateToObject(parseDateFromDataType(value));
        if (value instanceof Object && !isDate(value)) {
          var _extendDateWithDefaul2 = extendDateWithDefaults(value),
            year = _extendDateWithDefaul2.year,
            month = _extendDateWithDefaul2.month,
            day = _extendDateWithDefaul2.day,
            inputFormatProp = Array.isArray(inputFormat) ? inputFormat[0] : inputFormat;
          if (!isValidDateObject({
            year: year,
            month: month,
            day: day
          }, inputFormatProp)) return;
          return doesDateExist(year, month, day) ? dateToObject(parseDateFromDataType(value)) : value;
        }
      }
    }, [value, inputFormat]);
  return React.createElement(SimpleDateFieldInternal, _extends({}, props, {
    inputFormat: inputFormat,
    value: selectedValue,
    intl: intl,
    locale: dateLocale
  }));
};
SimpleDateField.displayName = "SimpleDateField", SimpleDateField.propTypes = _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: dateValueShape,
  dataType: dataTypeShapeWithDateTime,
  inputFormat: stringValidationRuleShape,
  yearFormat: PropTypes.oneOf(["yyyy", "yy"]),
  displayFormat: dateFormatShape,
  maxDate: dateValidationRuleShape,
  minDate: dateValidationRuleShape,
  autoFocus: PropTypes.bool,
  testId: PropTypes.string
}), SimpleDateField.defaultProps = _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  dataType: DATA_TYPE_OBJECT,
  displayFormat: "short",
  maxDate: "2100-01-01",
  minDate: "1900-01-01",
  autoFocus: !1
}), SimpleDateField.__docgenInfo = {
  description: "@metadataType field",
  methods: [],
  displayName: "SimpleDateField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShapeWithDateTime"
      },
      required: !1,
      description: "Type of the returned value"
    },
    displayFormat: {
      defaultValue: {
        value: "'short'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateFormatShape"
      },
      required: !1,
      description: "The readonly date format: 'vshort', 'short', 'long', 'abbreviated' or 'full'"
    },
    maxDate: {
      defaultValue: {
        value: "'2100-01-01'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets maxDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message.\nFor example: maxDate: [SomeDate, "a custom message"].'
    },
    minDate: {
      defaultValue: {
        value: "'1900-01-01'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets minDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message.\nFor example: minDate: [SomeDate, "a custom message"].'
    },
    autoFocus: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true focus will be automatically switched between fields when typing"
    },
    value: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "Date to display"
    },
    inputFormat: {
      type: {
        name: "custom",
        raw: "stringValidationRuleShape"
      },
      required: !1,
      description: "Format of date input used for field order. e.g. 'd/M/yyyy'\nd - day\nM - month\ny - year\nBy default it is generated based on user's locale, and\nit is recommended to not set it manually\n\nProp can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as undefined or\ndesired format and the second value as the custom message.\nFor example: [undefined, \"a custom message\"]."
    },
    yearFormat: {
      type: {
        name: "enum",
        value: [{
          value: "'yyyy'",
          computed: !1
        }, {
          value: "'yy'",
          computed: !1
        }]
      },
      required: !1,
      description: "Format of the year displayed in the field. Determine whether year\nshould be two of four digits."
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id.\nEach of the inputs has data-testid with a suffix, either '-month', '-day' or '-year'.\nElement wrapping the inputs has data-testid without any suffix."
    }
  },
  composes: ["../../FieldComponent/FieldComponent"]
}, SimpleDateField.__docgenInfo = {
  componentName: "SimpleDateField",
  packageName: "@jutro/components",
  description: "",
  displayName: "SimpleDateField",
  methods: [],
  actualName: "SimpleDateField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Date to display"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }, {
          value: "'date-time'",
          computed: !1
        }]
      },
      required: !1,
      description: "Type of the returned value",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    },
    inputFormat: {
      type: {
        name: "custom",
        raw: "stringValidationRuleShape"
      },
      required: !1,
      description: "Format of date input used for field order. e.g. 'd/M/yyyy'\nd - day\nM - month\ny - year\nBy default it is generated based on user's locale, and\nit is recommended to not set it manually\n\nProp can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as undefined or\ndesired format and the second value as the custom message.\nFor example: [undefined, \"a custom message\"]."
    },
    yearFormat: {
      type: {
        name: "enum",
        value: [{
          value: "'yyyy'",
          computed: !1
        }, {
          value: "'yy'",
          computed: !1
        }]
      },
      required: !1,
      description: "Format of the year displayed in the field. Determine whether year\nshould be two of four digits."
    },
    displayFormat: {
      type: {
        name: "custom",
        raw: "dateFormatShape"
      },
      required: !1,
      description: "The readonly date format: 'vshort', 'short', 'long', 'abbreviated' or 'full'",
      defaultValue: {
        value: "'short'",
        computed: !1
      }
    },
    maxDate: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets maxDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message.\nFor example: maxDate: [SomeDate, "a custom message"].',
      defaultValue: {
        value: "'2100-01-01'",
        computed: !1
      }
    },
    minDate: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets minDate validation on DateField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message.\nFor example: minDate: [SomeDate, "a custom message"].',
      defaultValue: {
        value: "'1900-01-01'",
        computed: !1
      }
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true focus will be automatically switched between fields when typing",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id.\nEach of the inputs has data-testid with a suffix, either '-month', '-day' or '-year'.\nElement wrapping the inputs has data-testid without any suffix."
    }
  },
  composes: ["../../FieldComponent/FieldComponent"]
};