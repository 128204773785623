import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import cx from "classnames";
import { useDeprecationWarning } from "@jutro/platform";
import styles from "./StickyFooter.module.css";
var stickyFooterPropTypes = {
  children: PropTypes.node.isRequired,
  hiddenOnLoad: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  stickyClassName: PropTypes.string,
  containerClassName: PropTypes.string
};
export var StickyFooter = function (_ref) {
  var children = _ref.children,
    hiddenOnLoad = _ref.hiddenOnLoad,
    fullWidth = _ref.fullWidth,
    className = _ref.className,
    stickyClassName = _ref.stickyClassName,
    containerClassName = _ref.containerClassName,
    noDeprecationWarnings = _ref.noDeprecationWarnings;
  useDeprecationWarning({
    componentName: "StickyFooter",
    noDeprecationWarnings: noDeprecationWarnings
  });
  var _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isInitialView = _useState2[0],
    setInitialView = _useState2[1],
    _useInView = useInView({
      threshold: 1
    }),
    _useInView2 = _slicedToArray(_useInView, 3),
    ref = _useInView2[0],
    inView = _useInView2[1],
    entry = _useInView2[2],
    elementDissapeardOnBottom = !entry || entry.boundingClientRect.top > 0 && !entry.isIntersecting,
    shouldDisplaySticky = !inView && elementDissapeardOnBottom && (!hiddenOnLoad || isInitialView),
    stickyClasses = cx(styles.stickyFooter, _defineProperty({}, styles.fullWidth, fullWidth), className, stickyClassName);
  !isInitialView && inView && setInitialView(!0);
  var childrenContainer = React.createElement("div", {
    className: containerClassName
  }, children);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: className,
    ref: ref
  }, childrenContainer), shouldDisplaySticky && React.createElement("div", {
    className: stickyClasses
  }, childrenContainer));
};
StickyFooter.propTypes = stickyFooterPropTypes, StickyFooter.defaultProps = {
  hiddenOnLoad: !0,
  fullWidth: !0
}, StickyFooter.__docgenInfo = {
  description: "StickyFooter\n@type {React.FC<PropTypes.InferProps<typeof stickyFooterPropTypes>>}\n\n@metadataType container\n\n@deprecated since v8.9.0",
  methods: [],
  displayName: "StickyFooter",
  props: {
    hiddenOnLoad: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sticky version is displayed only after initial view of children"
    },
    fullWidth: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `fullWidth` is set then sticky version expands to full width of the screen using `position: fixed`,\notherwise it fills the width of its parent using `position: sticky`"
    },
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "Footer's content"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for component."
    },
    stickyClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for component applied only on sticky version."
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component which wraps the children of this component."
    }
  }
}, StickyFooter.__docgenInfo = {
  componentName: "StickyFooter",
  packageName: "@jutro/components",
  description: "StickyFooter",
  displayName: "StickyFooter",
  methods: [],
  actualName: "StickyFooter",
  metadataType: "container",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: !0,
      description: "Footer's content"
    },
    hiddenOnLoad: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Sticky version is displayed only after initial view of children",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `fullWidth` is set then sticky version expands to full width of the screen using `position: fixed`,\notherwise it fills the width of its parent using `position: sticky`",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for component."
    },
    stickyClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for component applied only on sticky version."
    },
    containerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component which wraps the children of this component."
    }
  }
};