import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { getMessageProp } from "@jutro/platform";
import styles from "./Footer.module.css";
import { FooterContext, footerBasicType } from "./FooterContext_DEPRECATED";
import { FooterText } from "./FooterText_DEPRECATED";
import { messages } from "./FooterCopyright_DEPRECATED.messages";
import { useFooterDeprecationMessage } from "./FooterDeprecationContext_DEPRECATED";
var footerCopyrightPropTypes = {
  className: PropTypes.string,
  messageProps: PropTypes.shape({
    copyrightMessage: intlMessageShape
  })
};
export var FooterCopyright = function (_ref) {
  var className = _ref.className,
    messageProps = _ref.messageProps;
  useFooterDeprecationMessage("FooterCopyright");
  var translator = useTranslator(),
    footerType = useContext(FooterContext),
    classes = cx(styles.footerCopyright, _defineProperty({}, styles.footerCopyrightBasic, footerType === footerBasicType), className),
    copyrights = translator(getMessageProp("copyrightMessage", messageProps, messages), {
      year: new Date().getFullYear()
    });
  return React.createElement(FooterText, {
    noDeprecationWarnings: ["FooterText"],
    className: classes,
    text: copyrights
  });
};
FooterCopyright.propTypes = footerCopyrightPropTypes, FooterCopyright.__docgenInfo = {
  description: "The `FooterCopyright` component is designed as a simple component presenting copyright inside the `Footer` container.\n\n@type {React.FC<PropTypes.InferProps<typeof footerCopyrightPropTypes>>}\n\n@metadataType element\n@deprecated since v8.9.0",
  methods: [],
  displayName: "FooterCopyright",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          copyrightMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Location aware text of the copyright",
            required: !1
          }
        }
      },
      required: !1,
      description: "Copyright message props"
    }
  }
}, FooterCopyright.__docgenInfo = {
  componentName: "FooterCopyright",
  packageName: "@jutro/components",
  description: "The `FooterCopyright` component is designed as a simple component presenting copyright inside the `Footer` container.",
  displayName: "FooterCopyright",
  methods: [],
  actualName: "FooterCopyright",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          copyrightMessage: {
            name: "union",
            description: "Location aware text of the copyright",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Copyright message props"
    }
  }
};