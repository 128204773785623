import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
export default {
  create: function (config) {
    return function (event) {
      var _context;
      return _reduceInstanceProperty(_context = _Object$keys(event)).call(_context, function (result, key) {
        var _context2;
        return null != config && config.allowedProperties && -1 !== _indexOfInstanceProperty(_context2 = config.allowedProperties).call(_context2, key) ? (result[key] = event[key], result) : (result[key] = config.obfuscationString, result);
      }, {});
    };
  }
};