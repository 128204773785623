import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
function replaceAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}
export function applyInsertMode(newState, oldState, userInput, maskOptions, handleValueChangeCallback) {
  if (newState.value.split("").every(function (char, index) {
    var _context;
    return char === maskOptions.maskChar || _includesInstanceProperty(_context = maskOptions.permanents).call(_context, index);
  }) || null === oldState.selection || null === newState.selection || !maskOptions.maskChar) return newState.value !== oldState.value && handleValueChangeCallback({
    target: {
      value: newState.value
    }
  }), newState;
  var newValue = newState.value;
  if (null !== userInput) {
    newValue = newState.value;
    for (var startIndex = oldState.selection.start, endIndex = newState.selection.end; endIndex < newValue.length;) {
      var _context2, _context3;
      _includesInstanceProperty(_context2 = maskOptions.permanents).call(_context2, endIndex) ? endIndex += 1 : (_includesInstanceProperty(_context3 = maskOptions.permanents).call(_context3, startIndex) || (newValue = replaceAt(newValue, endIndex, oldState.value.charAt(startIndex)), endIndex += 1), startIndex += 1);
    }
  } else if (newState.value !== oldState.value && oldState.selection.end < newValue.length) {
    newValue = oldState.value;
    var _startIndex = newState.selection.start,
      _endIndex = oldState.selection.end;
    for (_endIndex === _startIndex && (_endIndex += 1); _endIndex < newValue.length;) {
      var _context4, _context5;
      _includesInstanceProperty(_context4 = maskOptions.permanents).call(_context4, _endIndex) ? _endIndex += 1 : (_includesInstanceProperty(_context5 = maskOptions.permanents).call(_context5, _startIndex) || (newValue = replaceAt(newValue, _startIndex, newValue.charAt(_endIndex)), _endIndex += 1), _startIndex += 1);
    }
    for (; _startIndex < newValue.length;) {
      var _context6;
      _includesInstanceProperty(_context6 = maskOptions.permanents).call(_context6, _startIndex) || (newValue = replaceAt(newValue, _startIndex, maskOptions.maskChar)), _startIndex += 1;
    }
  }
  return {
    value: newValue,
    selection: newState.selection
  };
}