import _parseInt from "@babel/runtime-corejs3/core-js-stable/parse-int";
export function moveFocusToNextField(event, inputs) {
  if (inputs.current && !Number.isNaN(_parseInt(event.key, 10))) {
    var valueLength = inputs.current.value.length,
      maxValueLength = _parseInt(inputs.current.getAttribute("maxlength") || "0", 10),
      hasNotSelectedValue = inputs.current.selectionStart === inputs.current.selectionEnd;
    inputs.next && hasNotSelectedValue && valueLength >= maxValueLength && inputs.next.focus();
  }
}
export function moveFocusToPrevField(event, inputs) {
  if (inputs.current) {
    var valueLength = inputs.current.value.length;
    inputs.prev && "Backspace" === event.code && 0 === valueLength && (inputs.prev.focus(), event.preventDefault());
  }
}