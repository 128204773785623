import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["inputFormat"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useContext } from "react";
import { LocaleContext } from "@jutro/locale";
import { SimpleDateField } from "../SimpleDateField/SimpleDateField";
import { getInputFormat } from "../SimpleDateField/helpers";
export var SimpleMonthYearField = function (_ref) {
  var userInputFormat = _ref.inputFormat,
    props = _objectWithoutProperties(_ref, _excluded),
    dateLocale = useContext(LocaleContext).dateLocale;
  return React.createElement(SimpleDateField, _extends({}, props, {
    inputFormat: getInputFormat(["month", "year"], dateLocale, userInputFormat)
  }));
};
SimpleMonthYearField.displayName = "SimpleMonthYearField", SimpleMonthYearField.propTypes = _objectSpread({}, SimpleDateField.propTypes), SimpleMonthYearField.defaultProps = _objectSpread({}, SimpleDateField.defaultProps), SimpleMonthYearField.__docgenInfo = {
  description: "@metadataType field",
  methods: [],
  displayName: "SimpleMonthYearField",
  composes: ["../SimpleDateField/SimpleDateField"]
}, SimpleMonthYearField.__docgenInfo = {
  componentName: "SimpleMonthYearField",
  packageName: "@jutro/components",
  description: "",
  displayName: "SimpleMonthYearField",
  methods: [],
  actualName: "SimpleMonthYearField",
  metadataType: "field",
  composes: ["../SimpleDateField/SimpleDateField"]
};