import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["navLink"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import get from "lodash/get";
import cx from "classnames";
import { Accordion, AccordionCard, Chevron, Icon } from "@jutro/components";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { intlMessageShape, IntlMessageShape, routesShape } from "@jutro/prop-types";
import { usePathname } from "@jutro/platform";
import { getIdFromPath } from "../../NavBarLink";
import { NavigationContext } from "../../NavigationContext";
import { NavigationContent } from "../../NavigationContent";
import { renderBadge } from "../renderBadge";
import styles from "../SideNavigation.module.css";
import navBarStyles from "../../NavBarItem.module.css";
export var getNotifications = function (routes) {
  return _reduceInstanceProperty(routes).call(routes, function (notifications, route) {
    return notifications + get(route, "navLink.notifications", 0);
  }, 0);
};
export var NavBarAccordionInternal = function (_ref) {
  var toProp = _ref.to,
    header = _ref.header,
    icon = _ref.icon,
    disabled = _ref.disabled,
    className = _ref.className,
    routes = _ref.routes,
    notifications = _ref.notifications,
    _useContext = useContext(NavigationContext),
    setActiveRoute = _useContext.setActiveRoute,
    isNavigationCollapsed = _useContext.isCollapsed,
    expandCallback = _useContext.expand,
    pathname = usePathname(),
    translator = useTranslator(),
    urlTranslatorAndSanitizer = useSafeTranslatedUrls(),
    _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    accordionStates = _useState2[0],
    setAccordionStates = _useState2[1],
    to = urlTranslatorAndSanitizer(toProp),
    hasActiveRoute = routes.some(function (_ref2) {
      var path = _ref2.path;
      return matchPath(pathname, path);
    }),
    cardID = getIdFromPath(to) || "unknownId",
    classes = cx(styles.subItem),
    styledRoutes = _mapInstanceProperty(routes).call(routes, function (_ref3) {
      var navLink = _ref3.navLink;
      return _objectSpread(_objectSpread({}, _objectWithoutProperties(_ref3, _excluded)), {}, {
        navLink: _objectSpread(_objectSpread({}, navLink), {}, {
          className: cx(navLink && navLink.className, classes)
        })
      });
    }),
    shouldBeCollapsed = !hasActiveRoute || isNavigationCollapsed;
  shouldBeCollapsed && accordionStates.length > 0 && setAccordionStates([]), !shouldBeCollapsed && hasActiveRoute && -1 === _indexOfInstanceProperty(accordionStates).call(accordionStates, cardID) && setAccordionStates(function (prev) {
    return _concatInstanceProperty(prev).call(prev, [cardID]);
  });
  var onUpdateAccordionStates = useCallback(function (openedAccordions) {
    setAccordionStates(openedAccordions), accordionStates.length < openedAccordions.length && (null == expandCallback || expandCallback());
  }, [expandCallback, accordionStates.length]);
  return React.createElement("div", {
    onClick: function (e) {
      return e.stopPropagation();
    },
    role: "presentation"
  }, React.createElement(Accordion, {
    disabled: disabled,
    showFrame: !1,
    closeOthers: !1,
    className: styles.sideNavAccordion,
    accordionStates: accordionStates,
    onUpdateAccordionStates: onUpdateAccordionStates
  }, React.createElement(AccordionCard, {
    id: cardID,
    cardBodyClassName: styles.cardBody,
    cardTitleClassName: styles.cardTitle,
    cardHeadingClassName: styles.cardHeading,
    cardHeadingProps: {
      role: "menuitem"
    },
    collapseClassName: styles.accordionCollapse,
    onCardHeadingClicked: function () {
      return setActiveRoute(to);
    },
    renderHeader: function (isOpen) {
      var _cx,
        _cx2,
        iconComponent = icon && React.createElement(Icon, {
          icon: icon,
          className: navBarStyles.navBarItemIcon
        }),
        subNavsNotifications = getNotifications(routes),
        classes = cx(navBarStyles.navBarItem, navBarStyles.leftNavBarItem, (_defineProperty(_cx = {}, styles.activeAccordion, hasActiveRoute), _defineProperty(_cx, styles.expandedAccordion, isOpen), _cx), className),
        chevronClasses = cx(styles.chevron, (_defineProperty(_cx2 = {}, styles.rightAlignChevron, !notifications), _defineProperty(_cx2, styles.openedAccordion, isOpen), _cx2));
      return React.createElement("div", {
        className: classes,
        "data-testid": "accordion-".concat(cardID, "-header-root")
      }, React.createElement("div", {
        className: navBarStyles.titleWrapper
      }, iconComponent, React.createElement("div", {
        className: navBarStyles.titleContentWrapper
      }, React.createElement("span", {
        className: navBarStyles.title
      }, translator(header)), renderBadge(notifications, "badge__".concat(cardID), notifications || subNavsNotifications), React.createElement(Chevron, {
        className: chevronClasses,
        chevronIcon: "gw-expand-more",
        isOpen: isOpen,
        messageProps: {
          label: translator(header)
        },
        noDeprecationWarnings: ["Chevron"]
      }))));
    }
  }, React.createElement(NavigationContent, {
    routes: styledRoutes,
    alignment: "left"
  }))));
};
NavBarAccordionInternal.propTypes = {
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  to: intlMessageShape.isRequired,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  routes: routesShape
}, NavBarAccordionInternal.displayName = "NavBarAccordion";
export var NavBarAccordion = NavBarAccordionInternal;
NavBarAccordionInternal.__docgenInfo = {
  description: "The `NavBarAccordion` component is designed as a container for sub nav links of side navigation\n\n@param {object} props properties for SideNavigation component\n\n@returns {React.ReactElement}",
  methods: [],
  displayName: "NavBarAccordion",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Link header"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Internal path"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to make the item disabled"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the active component"
    },
    exact: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, will only match if the path matches the location.pathname exactly"
    },
    routes: {
      type: {
        name: "custom",
        raw: "routesShape"
      },
      required: !1,
      description: "Sub navigation items metadata"
    }
  }
}, NavBarAccordionInternal.__docgenInfo = {
  componentName: "NavBarAccordion",
  packageName: "@jutro/router",
  description: "The `NavBarAccordion` component is designed as a container for sub nav links of side navigation",
  displayName: "NavBarAccordion",
  methods: [],
  actualName: "NavBarAccordionInternal",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Link header"
    },
    to: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Internal path"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to make the item disabled"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the active component"
    },
    exact: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, will only match if the path matches the location.pathname exactly"
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: !1,
      description: "Sub navigation items metadata"
    }
  }
};