import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { getConfigValue } from "@jutro/config";
import { createTSMRequest } from "./tsmHelpers";
export var fetchTsmMessages = (_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(language, worksetUid, stringGroup) {
  var _context,
    _context2,
    serverUrl,
    stringVersion,
    jutroVersion,
    url,
    httpRequest,
    parameters,
    _args = arguments;
  return _regeneratorRuntime.wrap(function (_context3) {
    for (;;) switch (_context3.prev = _context3.next) {
      case 0:
        return serverUrl = _args.length > 3 && void 0 !== _args[3] ? _args[3] : getConfigValue("JUTRO_TSM_SERVER"), stringVersion = _args.length > 4 ? _args[4] : void 0, jutroVersion = _args.length > 5 ? _args[5] : void 0, url = _concatInstanceProperty(_context = _concatInstanceProperty(_context2 = "/api/v2/worksets/".concat(worksetUid, "/string-groups/")).call(_context2, stringGroup, "/strings-react/")).call(_context, language), httpRequest = createTSMRequest(serverUrl).build(), parameters = {
          jutroVersion: jutroVersion || "8.10.0"
        }, stringVersion && (parameters.version = stringVersion), _context3.next = 9, httpRequest.get(url, parameters);
      case 9:
        return _context3.abrupt("return", _context3.sent.data);
      case 10:
      case "end":
        return _context3.stop();
    }
  }, _callee);
})), function (_x, _x2, _x3) {
  return _ref.apply(this, arguments);
});
var _ref;