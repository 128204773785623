import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import last from "lodash/last";
import isEqual from "lodash/isEqual";
import cx from "classnames";
import { usePrevious } from "@jutro/platform";
import { ComponentPropTypes } from "@jutro/prop-types";
import { AccordionCard } from "./AccordionCard";
import styles from "./Accordion.module.css";
import { AccordionContext } from "./AccordionContext";
import { computeOpenedAccordions, getInitiallyOpenedAccordions } from "./helpers";
export var Accordion = function (_ref) {
  var _cx,
    id = _ref.id,
    expandedAccordions = _ref.accordionStates,
    _ref$boldFont = _ref.boldFont,
    boldFont = void 0 === _ref$boldFont || _ref$boldFont,
    className = _ref.className,
    children = _ref.children,
    _ref$closeOthers = _ref.closeOthers,
    closeOthers = void 0 === _ref$closeOthers || _ref$closeOthers,
    defaultOpenedId = _ref.defaultOpenedId,
    _ref$disabled = _ref.disabled,
    disabled = void 0 !== _ref$disabled && _ref$disabled,
    _ref$showFrame = _ref.showFrame,
    showFrame = void 0 === _ref$showFrame || _ref$showFrame,
    _ref$onUpdateAccordio = _ref.onUpdateAccordionStates,
    onExpandAccordion = void 0 === _ref$onUpdateAccordio ? noop : _ref$onUpdateAccordio,
    initiallyOpenedAccordions = getInitiallyOpenedAccordions(expandedAccordions, defaultOpenedId),
    _useState = useState(initiallyOpenedAccordions),
    _useState2 = _slicedToArray(_useState, 2),
    openedAccordions = _useState2[0],
    setOpenedAccordions = _useState2[1],
    previousExpandedAccordions = usePrevious(expandedAccordions),
    previousCloseOthersProp = usePrevious(closeOthers);
  useEffect(function () {
    if (!expandedAccordions || isEqual(previousExpandedAccordions, expandedAccordions)) {
      if (!previousCloseOthersProp && closeOthers) {
        var recentlyOpened = last(openedAccordions);
        recentlyOpened && setOpenedAccordions([recentlyOpened]);
      }
    } else setOpenedAccordions(expandedAccordions);
  }, [expandedAccordions, closeOthers, openedAccordions, setOpenedAccordions, previousExpandedAccordions, previousCloseOthersProp]);
  var context = {
    isAccordionOpen: function (accordionId) {
      return !!accordionId && _includesInstanceProperty(openedAccordions).call(openedAccordions, accordionId);
    },
    toggleAccordionOpen: function (accordionId) {
      disabled || setOpenedAccordions(function (previouslyOpened) {
        var computedOpenedAccordions = computeOpenedAccordions(previouslyOpened, accordionId, closeOthers);
        return onExpandAccordion(computedOpenedAccordions), computedOpenedAccordions;
      });
    },
    stateCallBack: onExpandAccordion,
    showFrame: showFrame
  };
  return React.createElement("div", _extends({
    className: cx(styles.accordion, (_cx = {}, _defineProperty(_cx, styles.normalFont, !boldFont), _defineProperty(_cx, styles.accordionFrame, showFrame), _defineProperty(_cx, styles.disabled, disabled), _cx), className),
    "aria-disabled": disabled,
    "aria-haspopup": "true"
  }, id ? {
    "data-testid": id,
    id: id
  } : {}), React.createElement(AccordionContext.Provider, {
    value: context
  }, children));
};
var propTypes = {
  id: PropTypes.string,
  accordionStates: PropTypes.array,
  boldFont: PropTypes.bool,
  className: PropTypes.string,
  children: ComponentPropTypes.childOfComponentType(AccordionCard),
  closeOthers: PropTypes.bool,
  defaultOpenedId: PropTypes.string,
  disabled: PropTypes.bool,
  showFrame: PropTypes.bool,
  onUpdateAccordionStates: PropTypes.func
};
Accordion.propTypes = propTypes, Accordion.__docgenInfo = {
  description: "The `Accordion` component is used to surround a set of `Card` components so that the user can open or close them.\n\n@typedef {typeof Accordion.propTypes} AccordionPropTypes\n\n@metadataType container",
  methods: [],
  displayName: "Accordion",
  props: {
    boldFont: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Changes the font weight of the accordion header to bold, set to `true` by default"
    },
    closeOthers: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Optional flag indicating whether other accordion cards should be collapsed when one is expanded"
    },
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Disables the accordion"
    },
    showFrame: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows the accordion borders when true, set to `true` by default"
    },
    onUpdateAccordionStates: {
      defaultValue: {
        value: "noop",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "A callback function for querying the list of expanded accordions"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the accordion"
    },
    accordionStates: {
      type: {
        name: "array"
      },
      required: !1,
      description: "An array containing the ids of accordion cards which should be expanded"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType<\n    AccordionCardProps,\n    typeof AccordionCard\n>(AccordionCard)"
      },
      required: !1,
      description: "The children elements to render inside this component"
    },
    defaultOpenedId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The optional id of the accordion card to be opened by default"
    }
  }
}, Accordion.__docgenInfo = {
  componentName: "Accordion",
  packageName: "@jutro/components",
  description: "The `Accordion` component is used to surround a set of `Card` components so that the user can open or close them.",
  displayName: "Accordion",
  methods: [],
  actualName: "Accordion",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the accordion"
    },
    accordionStates: {
      type: {
        name: "array"
      },
      required: !1,
      description: "An array containing the ids of accordion cards which should be expanded"
    },
    boldFont: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Changes the font weight of the accordion header to bold, set to `true` by default",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(AccordionCard)"
      },
      required: !1,
      description: "The children elements to render inside this component"
    },
    closeOthers: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Optional flag indicating whether other accordion cards should be collapsed when one is expanded",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    defaultOpenedId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The optional id of the accordion card to be opened by default"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Disables the accordion",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    showFrame: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows the accordion borders when true, set to `true` by default",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    onUpdateAccordionStates: {
      type: {
        name: "func"
      },
      required: !1,
      description: "A callback function for querying the list of expanded accordions",
      defaultValue: {
        value: "noop",
        computed: !0
      }
    }
  }
};