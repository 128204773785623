import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$fromEntries from "@babel/runtime-corejs3/core-js-stable/object/from-entries";
import isFunction from "lodash/isFunction";
import { getConfigValue } from "@jutro/config";
var muteHandler = function (evt) {
  var _evt$nativeEvent, _evt$nativeEvent$stop, _evt$nativeEvent2, _evt$nativeEvent2$pre;
  evt.nativeEvent instanceof KeyboardEvent && "Enter" !== evt.nativeEvent.code && "Space" !== evt.nativeEvent.code || (null == evt || null === (_evt$nativeEvent = evt.nativeEvent) || void 0 === _evt$nativeEvent || null === (_evt$nativeEvent$stop = _evt$nativeEvent.stopPropagation) || void 0 === _evt$nativeEvent$stop || _evt$nativeEvent$stop.call(_evt$nativeEvent), null == evt || null === (_evt$nativeEvent2 = evt.nativeEvent) || void 0 === _evt$nativeEvent2 || null === (_evt$nativeEvent2$pre = _evt$nativeEvent2.preventDefault) || void 0 === _evt$nativeEvent2$pre || _evt$nativeEvent2$pre.call(_evt$nativeEvent2));
};
export var muteCallbacks = function (props) {
  var _context,
    callbacks = _filterInstanceProperty(_context = _Object$entries(props)).call(_context, function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      return _startsWithInstanceProperty(key).call(key, "on") && isFunction(value);
    }),
    mutedCallbacks = _mapInstanceProperty(callbacks).call(callbacks, function (_ref3) {
      return [_slicedToArray(_ref3, 1)[0], muteHandler];
    });
  return _Object$fromEntries(mutedCallbacks);
};
export var isAccessibleDisabled = function (configPath) {
  return Boolean(getConfigValue(configPath) || !1 !== getConfigValue(configPath) && getConfigValue("accessibleDisabled.all"));
};