import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _context,
  _excluded = ["id", "title", "onLanguageChange", "onLocaleChange", "onSave", "languageValue", "localeValue", "noDeprecationWarnings"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useRef, useState, useContext } from "react";
import set from "lodash/fp/set";
import omit from "lodash/fp/omit";
import pick from "lodash/fp/pick";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { LocaleContext, useLanguage } from "@jutro/locale";
import { useEvent, useDeprecationWarning } from "@jutro/platform";
import { GlobalizationChooser } from "../GlobalizationChooser/GlobalizationChooser";
import { SettingsCard } from "./SettingsCard_DEPRECATED";
import { messages } from "./GlobalizationSettingsCard.messages";
var pickedValuesProps = ["languageValue", "localeValue"];
_concatInstanceProperty(_context = []).call(_context, pickedValuesProps, ["availableLanguageValues", "availableLocaleValues"]);
export var GlobalizationSettingsCard = function (_ref) {
  var id = _ref.id,
    title = _ref.title,
    onLanguageChange = _ref.onLanguageChange,
    onLocaleChange = _ref.onLocaleChange,
    onSave = _ref.onSave,
    languageValueProp = _ref.languageValue,
    localeValueProp = _ref.localeValue,
    noDeprecationWarnings = _ref.noDeprecationWarnings,
    gcProps = _objectWithoutProperties(_ref, _excluded);
  useDeprecationWarning({
    componentName: "GlobalizationSettingsCard",
    noDeprecationWarnings: noDeprecationWarnings
  });
  var localeContext = useContext(LocaleContext),
    languageContext = useLanguage(),
    currentLanguage = languageContext.language,
    currentLocale = localeContext.locale,
    _useState = useState({
      languageValue: languageValueProp || currentLanguage,
      localeValue: localeValueProp || currentLocale
    }),
    _useState2 = _slicedToArray(_useState, 2),
    data = _useState2[0],
    setData = _useState2[1],
    ref = useRef(_objectSpread({}, data)),
    updateLocale = useEvent(function (newLocale) {
      var _localeContext$locale;
      null == localeContext || null === (_localeContext$locale = localeContext.localeOnChangeCallback) || void 0 === _localeContext$locale || _localeContext$locale.call(localeContext, newLocale);
    }),
    updateLanguage = useEvent(function (newLanguage) {
      var _languageContext$lang;
      null == languageContext || null === (_languageContext$lang = languageContext.languageOnChangeCallback) || void 0 === _languageContext$lang || _languageContext$lang.call(languageContext, newLanguage);
    }),
    onSaveCallback = useEvent(function () {
      currentLocale !== data.localeValue && updateLocale(data.localeValue), currentLanguage !== data.languageValue && updateLanguage(data.languageValue), null == onSave || onSave(data), ref.current = _objectSpread({}, data);
    }),
    onCancelCallback = useEvent(function () {
      setData(_objectSpread(_objectSpread({}, data), ref.current));
    }),
    onLanguageValueChange = useEvent(function (lang) {
      setData(set("languageValue", lang, data)), null == onLanguageChange || onLanguageChange(lang);
    }),
    onLocaleValueChange = useEvent(function (locale) {
      setData(set("localeValue", locale, data)), null == onLocaleChange || onLocaleChange(locale);
    });
  return React.createElement(SettingsCard, {
    id: id,
    title: title || messages.globSettingsTitle,
    renderContent: function (isEditMode) {
      return React.createElement(GlobalizationChooser, _extends({
        availableLocaleValues: ["en-US", "fr-FR", "fr-CA", "EN", "PL"],
        availableLanguageValues: ["en", "fr-FR", "de-DE"]
      }, gcProps, {
        readOnly: !isEditMode,
        languageValue: data.languageValue,
        localeValue: data.localeValue,
        onLanguageValueChange: onLanguageValueChange,
        onLocaleValueChange: onLocaleValueChange,
        containerStyle: "inlineWithIndent",
        skipPropagation: !0
      }));
    },
    onSaveClick: onSaveCallback,
    onCancelClick: onCancelCallback
  });
};
export var globalizationSettingsProps = _objectSpread(_objectSpread(_objectSpread({}, omit(["onLocaleValueChange", "onLanguageValueChange"], GlobalizationChooser.propTypes)), pick(pickedValuesProps, GlobalizationChooser.propTypes)), {}, {
  id: PropTypes.string,
  title: intlMessageShape,
  onLocaleChange: PropTypes.func,
  onLanguageChange: PropTypes.func,
  onSave: PropTypes.func
});
GlobalizationSettingsCard.propTypes = globalizationSettingsProps, GlobalizationSettingsCard.displayName = "GlobalizationSettingsCard", GlobalizationSettingsCard.__docgenInfo = {
  description: "@metadataType container\n@deprecated since v8.10",
  methods: [],
  displayName: "GlobalizationSettingsCard",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: ""
    },
    onLocaleChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    onLanguageChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    onSave: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash/fp"]
}, GlobalizationSettingsCard.__docgenInfo = {
  componentName: "GlobalizationSettingsCard",
  packageName: "@jutro/components",
  description: "",
  displayName: "GlobalizationSettingsCard",
  methods: [],
  actualName: "GlobalizationSettingsCard",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: ""
    },
    onLocaleChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    onLanguageChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    onSave: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash/fp"]
};