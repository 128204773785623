import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { injectIntl } from "react-intl";
import React, { useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { warning } from "@jutro/logger";
import { getConfigValue } from "@jutro/config";
import { usePrevious } from "@jutro/platform";
import { IntlProvider } from "./IntlProvider";
import { TranslatorProvider } from "./TranslatorProvider";
import { LocaleContextProvider } from "./localeContext";
import { LanguageContextProvider } from "./languageContext";
import { CurrencyContextProvider } from "./currencyContext";
import { CountryContextProvider } from "./countryContext";
import { getDateLocale } from "./getDateLocale";
import { getTimeZoneFallback } from "./LocaleService";
export function withIntl(ComponentToWrap) {
  var wrapper = injectIntl(ComponentToWrap);
  return ComponentToWrap.propTypes && (wrapper.propTypes = ComponentToWrap.propTypes), ComponentToWrap.defaultProps && (wrapper.defaultProps = ComponentToWrap.defaultProps), wrapper;
}
var globalizationReducer = function (state, action) {
  switch (action.type) {
    case "defaultLocaleChanged":
      return state.usingDefaultLocale && state.locale !== action.payload ? _objectSpread(_objectSpread({}, state), {}, {
        locale: action.payload
      }) : state;
    case "localeChanged":
      return _objectSpread(_objectSpread({}, state), {}, {
        locale: action.payload,
        usingDefaultLocale: !1
      });
    case "defaultLanguageChanged":
      return state.usingDefaultLanguage && state.language !== action.payload ? _objectSpread(_objectSpread({}, state), {}, {
        language: action.payload
      }) : state;
    case "languageChanged":
      return _objectSpread(_objectSpread({}, state), {}, {
        language: action.payload,
        usingDefaultLanguage: !1
      });
    default:
      return state;
  }
};
export var GlobalizationProvider = function (props) {
  var _ref = props,
    availableLanguages = _ref.availableLanguages,
    availableLocales = _ref.availableLocales,
    defaultCountryCode = _ref.defaultCountryCode,
    defaultCurrency = _ref.defaultCurrency,
    defaultLocale = _ref.defaultLocale,
    defaultLanguage = _ref.defaultLanguage,
    defaultTimeZone = _ref.defaultTimeZone,
    messages = _ref.messages,
    children = _ref.children,
    onLanguageChange = _ref.onLanguageChange,
    onLocaleChange = _ref.onLocaleChange,
    onLanguageOrLocaleChange = _ref.onLanguageOrLocaleChange,
    intlTextComponent = _ref.intlTextComponent,
    _ref$translatorProvid = _ref.translatorProvider,
    TranslatorProviderComp = void 0 === _ref$translatorProvid ? TranslatorProvider : _ref$translatorProvid,
    _useReducer = useReducer(globalizationReducer, {
      locale: defaultLocale,
      usingDefaultLocale: !0,
      language: defaultLanguage,
      usingDefaultLanguage: !0
    }),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    currentState = _useReducer2[0],
    dispatch = _useReducer2[1];
  useEffect(function () {
    dispatch({
      type: "defaultLocaleChanged",
      payload: defaultLocale
    });
  }, [defaultLocale, dispatch]), useEffect(function () {
    dispatch({
      type: "defaultLanguageChanged",
      payload: defaultLanguage
    });
  }, [defaultLanguage, dispatch]);
  var locale = currentState.locale,
    language = currentState.language;
  !function (previousState, currentState, onLocaleChange, onLanguageChange, onLanguageOrLocaleChange) {
    useEffect(function () {
      if (previousState) {
        var locale = currentState.locale,
          language = currentState.language,
          localeChanged = previousState.locale !== locale,
          languageChanged = previousState.language !== language;
        (localeChanged || languageChanged) && (onLanguageOrLocaleChange ? onLanguageOrLocaleChange({
          language: language,
          languageChanged: languageChanged,
          locale: locale,
          localeChanged: localeChanged
        }) : (localeChanged && (onLocaleChange ? onLocaleChange(locale) : warning("GlobalizationProvider: The locale has been changed but no onLocaleChange callback provided.")), languageChanged && (onLanguageChange ? onLanguageChange(language) : warning("GlobalizationProvider: The language has been changed but no onLanguageChange callback provided."))));
      }
    });
  }(usePrevious(currentState), currentState, onLocaleChange, onLanguageChange, onLanguageOrLocaleChange);
  var dateLocale = useMemo(function () {
      return getDateLocale(locale);
    }, [locale]),
    timeZoneFallback = getConfigValue("localeSettings.defaultTimeZone", getTimeZoneFallback()),
    localeContext = {
      availableLocales: availableLocales,
      locale: locale,
      dateLocale: dateLocale,
      defaultTimeZone: defaultTimeZone || timeZoneFallback,
      localeOnChangeCallback: function (newLocale) {
        return dispatch({
          type: "localeChanged",
          payload: newLocale
        });
      }
    },
    languageContext = {
      availableLanguages: availableLanguages,
      language: language,
      languageOnChangeCallback: function (newLanguage) {
        return dispatch({
          type: "languageChanged",
          payload: newLanguage
        });
      }
    },
    currencyContext = useMemo(function () {
      return {
        defaultCurrency: defaultCurrency
      };
    }, [defaultCurrency]),
    countryContext = useMemo(function () {
      return {
        defaultCountryCode: defaultCountryCode
      };
    }, [defaultCountryCode]);
  return React.createElement(CountryContextProvider, {
    value: countryContext
  }, React.createElement(CurrencyContextProvider, {
    value: currencyContext
  }, React.createElement(LanguageContextProvider, {
    value: languageContext
  }, React.createElement(LocaleContextProvider, {
    value: localeContext
  }, React.createElement(IntlProvider, {
    defaultLocale: defaultLocale,
    locale: locale,
    messages: messages,
    textComponent: intlTextComponent
  }, React.createElement(TranslatorProviderComp, null, children))))));
};
var globalizationProviderPropTypes = {
  defaultLocale: PropTypes.string,
  defaultLanguage: PropTypes.string,
  availableLocales: PropTypes.arrayOf(PropTypes.string.isRequired),
  availableLanguages: PropTypes.arrayOf(PropTypes.string.isRequired),
  defaultCountryCode: PropTypes.string,
  defaultCurrency: PropTypes.string,
  defaultTimeZone: PropTypes.string,
  messages: PropTypes.objectOf(PropTypes.string.isRequired),
  onLanguageChange: PropTypes.func,
  onLocaleChange: PropTypes.func,
  onLanguageOrLocaleChange: PropTypes.func,
  intlTextComponent: PropTypes.elementType
};
GlobalizationProvider.defaultProps = {
  defaultCountryCode: "US",
  defaultCurrency: "USD",
  defaultLanguage: "en",
  defaultLocale: "en-US",
  availableLocales: [],
  availableLanguages: [],
  messages: {}
}, GlobalizationProvider.displayName = "GlobalizationProvider", GlobalizationProvider.propTypes = globalizationProviderPropTypes, GlobalizationProvider.__docgenInfo = {
  description: "A GlobalizationProvider that can be placed in the app component hierarchy.",
  methods: [],
  displayName: "GlobalizationProvider",
  props: {
    defaultCountryCode: {
      defaultValue: {
        value: "'US'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Default country code. Changes to this property will affect components inside this provider.",
      tsType: {
        name: "string"
      }
    },
    defaultCurrency: {
      defaultValue: {
        value: "'USD'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Default currency. Changes to this property will affect components inside this provider.",
      tsType: {
        name: "string"
      }
    },
    defaultLanguage: {
      defaultValue: {
        value: "'en'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Default language. Changes to this property will affect components inside this provider.",
      tsType: {
        name: "string"
      }
    },
    defaultLocale: {
      defaultValue: {
        value: "'en-US'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Default locale. Changes to this property will affect components inside this provider.",
      tsType: {
        name: "string"
      }
    },
    availableLocales: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Array of available locales. Changes to this property will affect components inside this provider.",
      tsType: {
        name: "Array",
        elements: [{
          name: "string"
        }],
        raw: "Array<string>"
      }
    },
    availableLanguages: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Array of available languages. Changes to this property will affect components inside this provider.",
      tsType: {
        name: "Array",
        elements: [{
          name: "string"
        }],
        raw: "Array<string>"
      }
    },
    messages: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      type: {
        name: "objectOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Messages used by IntlProvider. You need to handle loading and passing new messages when language changes.\nSee MessagesLoader.",
      tsType: {
        name: "Record",
        elements: [{
          name: "string"
        }, {
          name: "string"
        }],
        raw: "Record<string, string>"
      }
    },
    defaultTimeZone: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default time zone. Changes to this property will affet components inside the locale provider.",
      tsType: {
        name: "string"
      }
    },
    onLanguageChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Triggered when language is changed\n(language: string) => void",
      tsType: {
        name: "signature",
        type: "function",
        raw: "(val: string) => void",
        signature: {
          arguments: [{
            name: "val",
            type: {
              name: "string"
            }
          }],
          return: {
            name: "void"
          }
        }
      }
    },
    onLocaleChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Triggered when locale is changed\n(locale: string) => void",
      tsType: {
        name: "signature",
        type: "function",
        raw: "(val: string) => void",
        signature: {
          arguments: [{
            name: "val",
            type: {
              name: "string"
            }
          }],
          return: {
            name: "void"
          }
        }
      }
    },
    onLanguageOrLocaleChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Triggered when language or locale is changed\n({ language: string; languageChanged: boolean; locale: string; localeChanged: boolean; }) => void",
      tsType: {
        name: "signature",
        type: "function",
        raw: "(updates: {\n    language: string;\n    languageChanged: boolean;\n    locale: string;\n    localeChanged: boolean;\n}) => void",
        signature: {
          arguments: [{
            name: "updates",
            type: {
              name: "signature",
              type: "object",
              raw: "{\n    language: string;\n    languageChanged: boolean;\n    locale: string;\n    localeChanged: boolean;\n}",
              signature: {
                properties: [{
                  key: "language",
                  value: {
                    name: "string",
                    required: !0
                  }
                }, {
                  key: "languageChanged",
                  value: {
                    name: "boolean",
                    required: !0
                  }
                }, {
                  key: "locale",
                  value: {
                    name: "string",
                    required: !0
                  }
                }, {
                  key: "localeChanged",
                  value: {
                    name: "boolean",
                    required: !0
                  }
                }]
              }
            }
          }],
          return: {
            name: "void"
          }
        }
      }
    },
    intlTextComponent: {
      type: {
        name: "custom",
        raw: "PropTypes.elementType as Requireable<\n    GlobalizationProviderProps['intlTextComponent']\n>"
      },
      required: !1,
      description: "The tag to render text inside of react-intl <Formatted*> components; defaults to React.Fragment (https://formatjs.io/docs/react-intl/upgrade-guide-3x)",
      tsType: {
        name: "IntlProviderProps['textComponent']",
        raw: "IntlProviderProps['textComponent']"
      }
    },
    id: {
      required: !1,
      tsType: {
        name: "string"
      },
      description: ""
    },
    defaultMessage: {
      required: !1,
      tsType: {
        name: "string"
      },
      description: ""
    },
    translatorProvider: {
      required: !1,
      tsType: {
        name: "ReactComponentType",
        raw: "React.ComponentType"
      },
      description: ""
    },
    children: {
      required: !1,
      tsType: {
        name: "ReactReactNode",
        raw: "React.ReactNode"
      },
      description: ""
    }
  }
}, GlobalizationProvider.__docgenInfo = {
  componentName: "GlobalizationProvider",
  packageName: "@jutro/locale",
  description: "A GlobalizationProvider that can be placed in the app component hierarchy.",
  displayName: "GlobalizationProvider",
  methods: [],
  actualName: "GlobalizationProvider",
  props: {
    defaultLocale: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default locale. Changes to this property will affect components inside this provider.",
      defaultValue: {
        value: "'en-US'",
        computed: !1
      }
    },
    defaultLanguage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default language. Changes to this property will affect components inside this provider.",
      defaultValue: {
        value: "'en'",
        computed: !1
      }
    },
    availableLocales: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Array of available locales. Changes to this property will affect components inside this provider.",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    availableLanguages: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Array of available languages. Changes to this property will affect components inside this provider.",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    defaultCountryCode: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default country code. Changes to this property will affect components inside this provider.",
      defaultValue: {
        value: "'US'",
        computed: !1
      }
    },
    defaultCurrency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default currency. Changes to this property will affect components inside this provider.",
      defaultValue: {
        value: "'USD'",
        computed: !1
      }
    },
    defaultTimeZone: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default time zone. Changes to this property will affet components inside the locale provider."
    },
    messages: {
      type: {
        name: "objectOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "Messages used by IntlProvider. You need to handle loading and passing new messages when language changes.\nSee MessagesLoader.",
      defaultValue: {
        value: "{}",
        computed: !1
      }
    },
    onLanguageChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Triggered when language is changed\n(language: string) => void"
    },
    onLocaleChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Triggered when locale is changed\n(locale: string) => void"
    },
    onLanguageOrLocaleChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Triggered when language or locale is changed\n({ language: string; languageChanged: boolean; locale: string; localeChanged: boolean; }) => void"
    },
    intlTextComponent: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "The tag to render text inside of react-intl <Formatted*> components; defaults to React.Fragment (https://formatjs.io/docs/react-intl/upgrade-guide-3x)"
    }
  }
};