import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["isNodeExpanded", "isNodeVisible", "getMatchedLabelParts", "registerNode", "unregisterNode", "toggleNode", "isFirstNode", "activeNodeId"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import { useContext, useCallback, useEffect, useMemo } from "react";
import { useTranslator } from "@jutro/locale";
import { TreeViewContext } from "./TreeViewContext";
import { TreeViewDescendantContext } from "./TreeViewDescendantContext";
export var useTreeNode = function (nodeId, nodeLabel, disabled) {
  var treeViewContext = useContext(TreeViewContext),
    treeViewDescendantContext = useContext(TreeViewDescendantContext);
  if (!treeViewContext) throw new Error("Please make sure the hook is used in the TreeViewContext and TreeViewDescendantContext scope.");
  var translator = useTranslator(),
    isNodeExpanded = treeViewContext.isNodeExpanded,
    isNodeVisible = treeViewContext.isNodeVisible,
    getMatchedLabelParts = treeViewContext.getMatchedLabelParts,
    registerNode = treeViewContext.registerNode,
    unregisterNode = treeViewContext.unregisterNode,
    toggleNode = treeViewContext.toggleNode,
    isFirstNode = treeViewContext.isFirstNode,
    activeNodeId = treeViewContext.activeNodeId,
    other = _objectWithoutProperties(treeViewContext, _excluded),
    parentPath = treeViewDescendantContext.parentPath,
    path = useMemo(function () {
      var _context;
      return _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(parentPath), [nodeId]);
    }, [parentPath, nodeId]),
    expanded = isNodeExpanded(nodeId),
    visible = isNodeVisible(nodeId, path),
    labelMatches = getMatchedLabelParts(nodeId),
    translatedLabel = translator(nodeLabel);
  useEffect(function () {
    return registerNode(nodeId, translatedLabel, parentPath, disabled), function () {
      unregisterNode(nodeId);
    };
  }, [nodeId, parentPath, translatedLabel, disabled]);
  var toggle = useCallback(function () {
      toggleNode(nodeId);
    }, [toggleNode, nodeId]),
    isActiveNode = activeNodeId === nodeId,
    tabIndex = useMemo(function () {
      return isActiveNode || !activeNodeId && isFirstNode(nodeId) ? 0 : -1;
    }, [nodeId, isFirstNode, activeNodeId]);
  return _objectSpread(_objectSpread({}, other), {}, {
    translatedLabel: translatedLabel,
    expanded: expanded,
    visible: visible,
    path: path,
    toggle: toggle,
    labelMatches: labelMatches,
    activeNodeId: activeNodeId,
    tabIndex: tabIndex,
    isActiveNode: isActiveNode
  });
};