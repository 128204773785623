import { defineMessages } from "react-intl";
export var messages = defineMessages({
  openBurgerMenu: {
    id: "jutro-router.BurgerMenu.Open Menu",
    defaultMessage: "Open Menu"
  },
  menuHeaderTitle: {
    id: "jutro-router.BurgerMenu.Menu header title",
    defaultMessage: "Menu"
  },
  closeNavigationLabel: {
    id: "jutro-router.BurgerMenu.Close navigation",
    defaultMessage: "Close navigation"
  }
});