function resolveFiberType(type) {
  return type.type ? resolveFiberType(type.type) : type.render || type;
}
export function getDisplayName(component, fallback) {
  var _fiberType$constructo;
  if (component) {
    if (component.displayName) return component.displayName;
    var fiberType = resolveFiberType(component);
    return fiberType.displayName || fiberType.name || fallback || (null === (_fiberType$constructo = fiberType.constructor) || void 0 === _fiberType$constructo ? void 0 : _fiberType$constructo.name);
  }
}