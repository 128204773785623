import { Flex, Grid } from "@jutro/layout";
import isString from "lodash/isString";
var layoutComponentMap = {
  flex: Flex,
  grid: Grid,
  div: "div",
  Flex: Flex,
  Grid: Grid
};
export var getLayoutComponent = function (component) {
  return isString(component) ? layoutComponentMap[component] || layoutComponentMap.div : component;
};