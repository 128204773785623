import React from "react";
import PropTypes from "prop-types";
import { useDeprecationWarning } from "@jutro/platform";
import { intlMessageShape } from "@jutro/prop-types";
import { HelpElement } from "./HelpElement_DEPRECATED";
var helpParagraphPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape])
};
export var HelpParagraph = function (_ref) {
  var content = _ref.content,
    className = _ref.className,
    noDeprecationWarnings = _ref.noDeprecationWarnings;
  return useDeprecationWarning({
    componentName: "HelpParagraph",
    noDeprecationWarnings: noDeprecationWarnings
  }), React.createElement(HelpElement, {
    className: className,
    tag: "p",
    content: content
  });
};
HelpParagraph.propTypes = helpParagraphPropTypes, HelpParagraph.__docgenInfo = {
  description: "The `HelpParagraph` component is designed as a simple component which displays\ntext inside the Help drop-down list in the Application Header\n\n@type {React.FC<PropTypes.InferProps<typeof helpParagraphPropTypes>>}\n@metadataType element\n@deprecated since v8.9.0",
  methods: [],
  displayName: "HelpParagraph",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "Content to render"
    }
  }
}, HelpParagraph.__docgenInfo = {
  componentName: "HelpParagraph",
  packageName: "@jutro/components",
  description: "The `HelpParagraph` component is designed as a simple component which displays\ntext inside the Help drop-down list in the Application Header",
  displayName: "HelpParagraph",
  methods: [],
  actualName: "HelpParagraph",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "Content to render"
    }
  }
};