import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useEffect } from "react";
import { log } from "@jutro/logger";
import styles from "./UnresolvedComponent.module.css";
export var UnresolvedComponent = function (_ref) {
  var _context,
    id = _ref.id,
    componentName = _ref.componentName,
    componentNameId = componentName ? _concatInstanceProperty(_context = "<".concat(componentName, ' id="')).call(_context, id, '" />') : id;
  return useEffect(function () {
    log.warning('Unable to resolve component "'.concat(componentNameId, '". It is missing correct "component" or "datatype" properties.'));
  }, [id, componentName]), React.createElement("div", {
    id: id,
    className: styles.unresolvedComponent
  }, React.createElement("b", null, "***"), " Could not resolve component:", " ", React.createElement("code", null, componentNameId), " ", React.createElement("b", null, "***"));
};
UnresolvedComponent.__docgenInfo = {
  description: "Renders a warning that a component could not be resolved via metadata and logs a warning to the developer console.\n\n@param {object} props\n@param {string} props.id - Identity of the component that could not be resolved\n @param {string} props.componentName - component name that could not be resolved\n@returns  {React.ReactElement}",
  methods: [],
  displayName: "UnresolvedComponent"
}, UnresolvedComponent.__docgenInfo = {
  componentName: "UnresolvedComponent",
  packageName: "@jutro/uiconfig",
  description: "Renders a warning that a component could not be resolved via metadata and logs a warning to the developer console.",
  displayName: "UnresolvedComponent",
  methods: [],
  actualName: "UnresolvedComponent"
};