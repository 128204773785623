import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import isEqual from "lodash/isEqual";
export var onFocusHandler = function (focused, setFocused, rtState, rowIndex, columnId) {
  return function () {
    var _context,
      newFocused = {
        row: rowIndex,
        column: _findIndexInstanceProperty(_context = rtState.allVisibleColumns).call(_context, function (c) {
          return c.id === columnId;
        }),
        columnId: columnId
      };
    isEqual(focused, newFocused) || setFocused(newFocused);
  };
};
export var isFocused = function (_ref, rtState, row, columnId) {
  var _rtState$allVisibleCo,
    focusedRow = _ref.row,
    focusedColumn = _ref.column;
  return focusedRow === row && (null === (_rtState$allVisibleCo = rtState.allVisibleColumns[focusedColumn]) || void 0 === _rtState$allVisibleCo ? void 0 : _rtState$allVisibleCo.id) === columnId;
};