import React from "react";
import cx from "classnames";
import { Icon } from "../../../Icon/Icon";
export var LookupOptionIcon = function (_ref) {
  var type = _ref.type,
    customStyles = _ref.customStyles,
    optionTypesMap = _ref.optionTypesMap,
    title = _ref.title,
    _ref2 = optionTypesMap[type] || {},
    _ref2$icon = _ref2.icon,
    icon = void 0 === _ref2$icon ? "gw-help" : _ref2$icon,
    className = _ref2.className;
  return React.createElement(Icon, {
    icon: icon,
    className: cx(customStyles.icon, className),
    title: title
  });
};
LookupOptionIcon.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupOptionIcon"
}, LookupOptionIcon.__docgenInfo = {
  componentName: "LookupOptionIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupOptionIcon",
  methods: [],
  actualName: "LookupOptionIcon"
};