import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import cx from "classnames";
import PropTypes from "prop-types";
import React, { createRef } from "react";
import { uniqueInnerId, deprecateComponent } from "@jutro/platform";
import { Grid } from "@jutro/layout";
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING, intlMessageShape } from "@jutro/prop-types";
import { getOptionCode, getOptionName } from "../availableValues";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { RadioButtonCard, LAYOUT_PROP_TYPE, PORTRAIT_LAYOUT } from "./RadioButtonCard";
import styles from "./RadioButtonCardField.module.css";
import { createHandleRadioButtonKeyDown, getTabIndexForRadioGroup } from "../../../radiobuttonUtils";
export var RadioButtonCardFieldInternal = function (_FieldComponent) {
  _inherits(RadioButtonCardFieldInternal, FieldComponent);
  var _super = _createSuper(RadioButtonCardFieldInternal);
  function RadioButtonCardFieldInternal() {
    var _context, _this;
    _classCallCheck(this, RadioButtonCardFieldInternal);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "ref", createRef(null)), _this;
  }
  return _createClass(RadioButtonCardFieldInternal, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(RadioButtonCardFieldInternal.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _this2 = this,
        availableValues = breakpointProps.availableValues,
        disabled = breakpointProps.disabled,
        label = breakpointProps.label,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        renderName = breakpointProps.renderName,
        renderSecondaryLabel = breakpointProps.renderSecondaryLabel,
        layout = breakpointProps.layout,
        translator = this.translator,
        fieldUniqueId = this.fieldUniqueId,
        selectedValue = this.getDataTypeAwareSelectedValue(),
        radios = _mapInstanceProperty(availableValues).call(availableValues, function (option, index) {
          var secondaryLabel = option.secondaryLabel,
            moneyAmount = option.moneyAmount,
            iconName = option.iconName,
            currency = option.currency,
            iconSrc = option.iconSrc,
            iconClassName = option.iconClassName,
            iconContainerClassName = option.iconContainerClassName,
            hideValue = option.hideValue,
            optionCode = getOptionCode(option),
            optionName = getOptionName(option),
            checked = selectedValue === optionCode,
            cardId = uniqueInnerId(fieldUniqueId, optionCode)[optionCode];
          return React.createElement(RadioButtonCard, _extends({
            iconName: iconName,
            layout: layout,
            key: cardId,
            id: cardId,
            iconSrc: iconSrc,
            iconClassName: iconClassName,
            tabIndex: getTabIndexForRadioGroup(option, selectedValue, index),
            iconContainerClassName: iconContainerClassName,
            code: optionCode,
            name: renderName(optionName, translator),
            secondaryLabel: renderSecondaryLabel(secondaryLabel, translator),
            moneyAmount: moneyAmount,
            currency: currency,
            disabled: disabled,
            checked: checked,
            required: required,
            onValueSelect: _this2.handleAvailableValuesValueChange,
            styles: styles,
            hideValue: hideValue,
            noDeprecationWarnings: _this2.props.noDeprecationWarnings
          }, _this2.generateAccessibilityProperties(), _this2.generateDataPathProperty()));
        }),
        gridProps = "landscape" === layout ? {} : {
          columns: ["1fr"],
          repeat: "3",
          phone: {
            repeat: "2"
          }
        },
        classes = cx(styles.radioButtonCard, controlClassName),
        handleRadioButtonKeyDown = createHandleRadioButtonKeyDown(function (el, value) {
          el.querySelector("div[data-value=".concat(value, "]")).focus();
        }),
        _this$props = this.props,
        onFocus = _this$props.onFocus,
        onBlur = _this$props.onBlur;
      return React.createElement(Grid, _extends({
        ref: this.ref,
        id: fieldUniqueId,
        className: classes,
        "aria-label": translator(label),
        role: "radiogroup",
        onKeyDown: function (event) {
          return handleRadioButtonKeyDown(event, _this2.props, _this2.handleAvailableValuesValueChange);
        },
        gap: "large"
      }, gridProps, {
        onFocusCapture: function (e) {
          var _this2$ref$current;
          return !(null !== (_this2$ref$current = _this2.ref.current) && void 0 !== _this2$ref$current && _this2$ref$current.contains(e.relatedTarget)) && onFocus && onFocus(e);
        },
        onBlurCapture: function (e) {
          var _this2$ref$current2;
          return !(null !== (_this2$ref$current2 = _this2.ref.current) && void 0 !== _this2$ref$current2 && _this2$ref$current2.contains(e.relatedTarget)) && onBlur && onBlur(e);
        }
      }), radios);
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      return this.renderControl(breakpointProps);
    }
  }]), RadioButtonCardFieldInternal;
}();
_defineProperty(RadioButtonCardFieldInternal, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(PropTypes.shape({
    iconName: PropTypes.string,
    iconSrc: PropTypes.string,
    iconClassName: PropTypes.string,
    iconContainerClassName: PropTypes.string,
    id: PropTypes.string.isRequired,
    displayName: intlMessageShape,
    secondaryLabel: intlMessageShape.isRequired,
    moneyAmount: PropTypes.number,
    hideValue: PropTypes.bool
  })),
  renderName: PropTypes.func,
  renderSecondaryLabel: PropTypes.func,
  layout: LAYOUT_PROP_TYPE
})), _defineProperty(RadioButtonCardFieldInternal, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  renderName: function (name, translator) {
    return translator(name);
  },
  renderSecondaryLabel: function (secondaryLabel, translator) {
    return translator(secondaryLabel);
  },
  dataType: DATA_TYPE_STRING,
  layout: PORTRAIT_LAYOUT,
  availableValues: []
})), _defineProperty(RadioButtonCardFieldInternal, "contextType", FieldComponent.contextType), _defineProperty(RadioButtonCardFieldInternal, "displayName", "RadioButtonCardField");
export var RadioButtonCardField = deprecateComponent(RadioButtonCardFieldInternal, {
  deprecated: "RadioButtonCardField",
  alternative: "",
  extraMessage: "",
  dropTargetVersion: ""
});
RadioButtonCardFieldInternal.__docgenInfo = {
  description: "Renders a list of radio button cards. You specify the options using component props.\n@typedef {typeof RadioButtonCardField.propTypes} RadioButtonCardFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<RadioButtonCardFieldPropTypes>>\n\n@metadataType field\n@deprecated since v8.8.0",
  methods: [{
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }],
    returns: null
  }],
  displayName: "RadioButtonCardField",
  props: {
    renderName: {
      defaultValue: {
        value: "(name, translator) => translator(name)",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom render method for name prop. Translated property value by default"
    },
    renderSecondaryLabel: {
      defaultValue: {
        value: "(secondaryLabel, translator) =>\ntranslator(secondaryLabel)",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom render method for secondary label prop. Translated property value by default"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the value"
    },
    layout: {
      defaultValue: {
        value: "PORTRAIT_LAYOUT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "LAYOUT_PROP_TYPE"
      },
      required: !1,
      description: "Cards layout"
    },
    availableValues: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            iconName: {
              name: "string",
              required: !1
            },
            iconSrc: {
              name: "string",
              required: !1
            },
            iconClassName: {
              name: "string",
              required: !1
            },
            iconContainerClassName: {
              name: "string",
              required: !1
            },
            id: {
              name: "string",
              required: !0
            },
            displayName: {
              name: "custom",
              raw: "intlMessageShape",
              required: !1
            },
            secondaryLabel: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: !0
            },
            moneyAmount: {
              name: "number",
              required: !1
            },
            hideValue: {
              name: "bool",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code', 'name' and 'secondaryLabel'"
    },
    value: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "The selected value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "The default value to set if none is provided"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, RadioButtonCardFieldInternal.__docgenInfo = {
  componentName: "RadioButtonCardField",
  packageName: "@jutro/components",
  description: "Renders a list of radio button cards. You specify the options using component props.",
  displayName: "RadioButtonCardField",
  methods: [{
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "RadioButtonCardFieldInternal",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "The selected value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "The default value to set if none is provided"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            iconName: {
              name: "string",
              required: !1
            },
            iconSrc: {
              name: "string",
              required: !1
            },
            iconClassName: {
              name: "string",
              required: !1
            },
            iconContainerClassName: {
              name: "string",
              required: !1
            },
            id: {
              name: "string",
              required: !0
            },
            displayName: {
              name: "union",
              required: !1,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            secondaryLabel: {
              name: "union",
              required: !0,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            moneyAmount: {
              name: "number",
              required: !1
            },
            hideValue: {
              name: "bool",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code', 'name' and 'secondaryLabel'",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    renderName: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom render method for name prop. Translated property value by default",
      defaultValue: {
        value: "(name, translator) => translator(name)",
        computed: !1
      }
    },
    renderSecondaryLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom render method for secondary label prop. Translated property value by default",
      defaultValue: {
        value: "(secondaryLabel, translator) =>\ntranslator(secondaryLabel)",
        computed: !1
      }
    },
    layout: {
      type: {
        name: "enum",
        value: [{
          value: "'portrait'",
          computed: !1
        }, {
          value: "'landscape'",
          computed: !1
        }]
      },
      required: !1,
      description: "Cards layout",
      defaultValue: {
        value: "'portrait'",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};