import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
export function first(nodeMap) {
  return nodeMap[0];
}
export function find(nodeMap, id) {
  return _findInstanceProperty(nodeMap).call(nodeMap, function (node) {
    return node.id === id;
  });
}
export function findParent(nodeMap, id) {
  var node = find(nodeMap, id);
  if (node) return find(nodeMap, node.path[node.path.length - 1]);
}
export function findChildren(nodeMap, id) {
  return _filterInstanceProperty(nodeMap).call(nodeMap, function (node) {
    return node.path[node.path.length - 1] === id;
  });
}
export function getVisibleNodes(_ref) {
  var _context,
    nodeMap = _ref.nodeMap,
    expandedNodes = _ref.expandedNodes,
    matchedNodes = _ref.matchedNodes,
    filterQuery = _ref.filterQuery,
    hasExpandedParent = function (node) {
      return node.path.every(function (ancestorId) {
        return _includesInstanceProperty(expandedNodes).call(expandedNodes, ancestorId);
      });
    };
  return filterQuery ? _filterInstanceProperty(_context = _filterInstanceProperty(nodeMap).call(nodeMap, function (_ref2) {
    var id = _ref2.id,
      path = _ref2.path;
    return matchedNodes.some(function (_ref3) {
      var _context2,
        _context3,
        matchedNodeId = _ref3.id,
        matchedNodePath = _ref3.path;
      return _includesInstanceProperty(_context2 = _concatInstanceProperty(_context3 = []).call(_context3, _toConsumableArray(path), [id])).call(_context2, matchedNodeId) || _includesInstanceProperty(matchedNodePath).call(matchedNodePath, id);
    });
  })).call(_context, hasExpandedParent) : _filterInstanceProperty(nodeMap).call(nodeMap, function (node) {
    return 0 === node.path.length || hasExpandedParent(node);
  });
}
export function getEnabledNodes(nodeMap) {
  return _filterInstanceProperty(nodeMap).call(nodeMap, function (node) {
    return !node.disabled;
  });
}
export function next(_ref4) {
  var nodeMap = _ref4.nodeMap,
    activeNodeId = _ref4.activeNodeId,
    list = getEnabledNodes(getVisibleNodes({
      nodeMap: nodeMap,
      expandedNodes: _ref4.expandedNodes,
      matchedNodes: _ref4.matchedNodes,
      filterQuery: _ref4.filterQuery
    })),
    activeIndex = _findIndexInstanceProperty(list).call(list, function (node) {
      return node.id === activeNodeId;
    }),
    _list$slice = _sliceInstanceProperty(list).call(list, activeIndex + 1);
  return _slicedToArray(_list$slice, 1)[0] || find(nodeMap, activeNodeId) || first(nodeMap);
}
export function prev(_ref5) {
  var nodeMap = _ref5.nodeMap,
    activeNodeId = _ref5.activeNodeId,
    list = getEnabledNodes(getVisibleNodes({
      nodeMap: nodeMap,
      expandedNodes: _ref5.expandedNodes,
      matchedNodes: _ref5.matchedNodes,
      filterQuery: _ref5.filterQuery
    })),
    activeIndex = _findIndexInstanceProperty(list).call(list, function (node) {
      return node.id === activeNodeId;
    }),
    _list$slice$reverse = _sliceInstanceProperty(list).call(list, 0, activeIndex).reverse();
  return _slicedToArray(_list$slice$reverse, 1)[0] || find(nodeMap, activeNodeId) || first(nodeMap);
}