import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["text"],
  _excluded2 = ["className", "href", "to", "text"],
  _excluded3 = ["className", "href", "to"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { intlMessageShape, intlToShape } from "@jutro/prop-types";
import styles from "./Breadcrumb.module.css";
import { messages } from "./Breadcrumb.messages";
import { Link } from "../Link/Link";
export var Breadcrumb = function (props) {
  var breadcrumbClassName = props.className,
    links = props.links,
    renderLinkTrigger = props.renderLinkTrigger,
    lastItemClickable = props.lastItemClickable,
    backLinkWithoutText = props.backLinkWithoutText,
    translator = useTranslator(),
    urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  if (!links || links.length < 2) return null;
  var item,
    className,
    href,
    to,
    restLinkProps,
    label,
    backLinkProps,
    divider = React.createElement("span", {
      "aria-hidden": "true",
      className: styles.divider
    }, "/"),
    renderLinkItem = renderLinkTrigger || function (_ref2) {
      var text = _ref2.text,
        linkProps = _objectWithoutProperties(_ref2, _excluded);
      return React.createElement(Link, linkProps, text);
    },
    breadcrumbClasses = cx(styles.breadcrumb, breadcrumbClassName),
    breadcrumbs = _mapInstanceProperty(links).call(links, function (_ref3, index, linkArray) {
      var className = _ref3.className,
        href = _ref3.href,
        to = _ref3.to,
        text = _ref3.text,
        restLinkProps = _objectWithoutProperties(_ref3, _excluded2),
        isLastItem = linkArray.length - 1 === index,
        isReadOnly = isLastItem && !lastItemClickable,
        linkProps = _objectSpread({
          text: translator(text),
          href: href && urlTranslatorAndSanitizer(href),
          to: to && urlTranslatorAndSanitizer(to),
          className: cx(styles.breadcrumbLink, className)
        }, restLinkProps);
      return isLastItem && lastItemClickable && (linkProps["aria-current"] = "page"), React.createElement("li", {
        key: "breadcrumb_link_".concat(index)
      }, index > 0 && divider, isReadOnly ? function (_ref) {
        var text = _ref.text;
        return React.createElement("span", {
          className: styles.currentLink
        }, text);
      }({
        text: translator(text)
      }) : renderLinkItem(linkProps));
    });
  return React.createElement("div", {
    className: breadcrumbClasses,
    "aria-label": translator(messages.breadcrumbLabel),
    role: "navigation"
  }, links.length > 2 && React.createElement("ul", null, breadcrumbs), 2 === links.length && (item = _slicedToArray(links, 1)[0], className = item.className, href = item.href, to = item.to, restLinkProps = _objectWithoutProperties(item, _excluded3), label = translator(messages.breadcrumbBackButtonLabel), backLinkProps = _objectSpread(_objectSpread({}, restLinkProps), {}, {
    text: backLinkWithoutText ? "" : label,
    href: href && urlTranslatorAndSanitizer(href),
    to: to && urlTranslatorAndSanitizer(to),
    className: cx(styles.breadcrumbLink, className),
    icon: "gw-keyboard-backspace",
    "aria-label": label
  }), React.createElement(React.Fragment, null, renderLinkItem(backLinkProps))));
};
var breadcrumbPropTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: intlMessageShape.isRequired,
    href: intlMessageShape,
    to: intlToShape,
    className: PropTypes.string,
    history: PropTypes.object
  })),
  renderLinkTrigger: PropTypes.func,
  lastItemClickable: PropTypes.bool,
  backLinkWithoutText: PropTypes.bool
};
Breadcrumb.propTypes = breadcrumbPropTypes, Breadcrumb.displayName = "Breadcrumb", Breadcrumb.__docgenInfo = {
  description: 'Displays a "breadcrumb" of links to previous pages with anchor tags or Link components\n\n@metadataType action',
  methods: [],
  displayName: "Breadcrumb",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class to wrap the component"
    },
    links: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            text: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: !0
            },
            href: {
              name: "custom",
              raw: "intlMessageShape",
              required: !1
            },
            to: {
              name: "custom",
              raw: "intlToShape",
              required: !1
            },
            className: {
              name: "string",
              required: !1
            },
            history: {
              name: "object",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Takes an array of objects, each item contains props for the used Link type component"
    },
    renderLinkTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "RenderProp function to generate the component that makes up the breadcrumb"
    },
    lastItemClickable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines if the last item is a link"
    },
    backLinkWithoutText: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Determines if the backLink includes the word "Back" or just an "arrow" icon'
    }
  }
}, Breadcrumb.__docgenInfo = {
  componentName: "Breadcrumb",
  packageName: "@jutro/components",
  description: 'Displays a "breadcrumb" of links to previous pages with anchor tags or Link components',
  displayName: "Breadcrumb",
  methods: [],
  actualName: "Breadcrumb",
  metadataType: "action",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class to wrap the component"
    },
    links: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            text: {
              name: "union",
              required: !0,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            href: {
              name: "union",
              required: !1,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            to: {
              name: "custom",
              raw: "intlToShape",
              required: !1
            },
            className: {
              name: "string",
              required: !1
            },
            history: {
              name: "object",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Takes an array of objects, each item contains props for the used Link type component"
    },
    renderLinkTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "RenderProp function to generate the component that makes up the breadcrumb"
    },
    lastItemClickable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines if the last item is a link"
    },
    backLinkWithoutText: {
      type: {
        name: "bool"
      },
      required: !1,
      description: 'Determines if the backLink includes the word "Back" or just an "arrow" icon'
    }
  }
};