import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "hideSeparator", "separatorClassName", "className", "tag", "isContainerOnly", "closeOnClick", "dangerouslySetInnerHTML"];
import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { DropdownMenuSeparator } from "@jutro/components";
import styles from "./BurgerMenu.module.css";
import { BurgerMenuContext } from "./BurgerMenuContext";
export var BurgerMenuItem = function (_ref) {
  var id = _ref.id,
    hideSeparator = _ref.hideSeparator,
    separatorClassName = _ref.separatorClassName,
    className = _ref.className,
    _ref$tag = _ref.tag,
    Tag = void 0 === _ref$tag ? "div" : _ref$tag,
    isContainerOnly = _ref.isContainerOnly,
    closeOnClick = _ref.closeOnClick,
    props = (_ref.dangerouslySetInnerHTML, _objectWithoutProperties(_ref, _excluded)),
    _useContext = useContext(BurgerMenuContext),
    closeMenu = _useContext.closeMenu,
    closeOnItemClick = _useContext.closeOnItemClick,
    shouldCloseOnClick = null != closeOnClick ? closeOnClick : closeOnItemClick;
  return React.createElement(React.Fragment, null, !hideSeparator && React.createElement(DropdownMenuSeparator, {
    className: cx(styles.sidebarSeparator, separatorClassName)
  }), React.createElement(Tag, _extends({
    id: id,
    className: cx(styles.sidebarItem, className, _defineProperty({}, styles.sidebarItemInteractive, !isContainerOnly))
  }, props, {
    onClick: function (event) {
      var _props$onClick;
      shouldCloseOnClick && closeMenu(), null === (_props$onClick = props.onClick) || void 0 === _props$onClick || _props$onClick.call(props, event);
    }
  })));
};
BurgerMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  hideSeparator: PropTypes.bool,
  isContainerOnly: PropTypes.bool,
  className: PropTypes.string,
  separatorClassName: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.elementType,
  closeOnClick: PropTypes.bool
}, BurgerMenuItem.__docgenInfo = {
  description: "BurgerMenuLink renders single burger menu content element",
  methods: [],
  displayName: "BurgerMenuItem",
  props: {
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "HTML tag which will be used to render item. Defaults to 'div'"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    hideSeparator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, does not render a separator"
    },
    isContainerOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, disable interaction styles"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the menu item"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the burger sidebar item separator"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content of the item"
    },
    closeOnClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, clicking this item will cause menu to be closed if this item does not change browser location.\nThis overwrites closeOnItemClick prop from burger menu.\nNote that if item changes browser location, menu will always be closed."
    }
  }
}, BurgerMenuItem.__docgenInfo = {
  componentName: "BurgerMenuItem",
  packageName: "@jutro/router",
  description: "BurgerMenuLink renders single burger menu content element",
  displayName: "BurgerMenuItem",
  methods: [],
  actualName: "BurgerMenuItem",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    hideSeparator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, does not render a separator"
    },
    isContainerOnly: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, disable interaction styles"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the menu item"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the burger sidebar item separator"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content of the item"
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "HTML tag which will be used to render item. Defaults to 'div'",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    },
    closeOnClick: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, clicking this item will cause menu to be closed if this item does not change browser location.\nThis overwrites closeOnItemClick prop from burger menu.\nNote that if item changes browser location, menu will always be closed."
    }
  }
};