import { SELECT, TOGGLE, SET_ALL, CLEAR_ALL } from "./types";
export var select = function (rowId) {
  return {
    type: SELECT,
    payload: rowId
  };
};
export var toggle = function (rowId) {
  return {
    type: TOGGLE,
    payload: rowId
  };
};
export var setAll = function (newRows) {
  return {
    type: SET_ALL,
    payload: newRows
  };
};
export var clearAll = function () {
  return {
    type: CLEAR_ALL
  };
};