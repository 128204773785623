import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["className", "onClick", "children", "innerRef", "loading", "onTrigger", "failTo", "match", "location", "history", "to", "message"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button } from "@jutro/components";
import { intlMessageShape, intlToShape } from "@jutro/prop-types";
import styles from "./AsyncButtonLink.module.css";
import { withAsyncAction } from "../AsyncAction/AsyncAction";
var AsyncButtonLinkInternal = function (_Component) {
  _inherits(AsyncButtonLinkInternal, Component);
  var _super = _createSuper(AsyncButtonLinkInternal);
  function AsyncButtonLinkInternal() {
    return _classCallCheck(this, AsyncButtonLinkInternal), _super.apply(this, arguments);
  }
  return _createClass(AsyncButtonLinkInternal, [{
    key: "render",
    value: function () {
      var _this$props = this.props,
        className = _this$props.className,
        onClick = _this$props.onClick,
        children = _this$props.children,
        innerRef = _this$props.innerRef,
        loading = _this$props.loading,
        props = (_this$props.onTrigger, _this$props.failTo, _this$props.match, _this$props.location, _this$props.history, _this$props.to, _this$props.message, _objectWithoutProperties(_this$props, _excluded)),
        classes = cx(styles.asyncButtonLink, className);
      return loading && delete props.icon, React.createElement(Button, _extends({}, props, {
        onClick: onClick,
        ref: innerRef,
        className: classes
      }), children);
    }
  }]), AsyncButtonLinkInternal;
}();
_defineProperty(AsyncButtonLinkInternal, "propTypes", _objectSpread(_objectSpread({}, Button.propTypes), {}, {
  failTo: intlToShape,
  failToMessage: PropTypes.string,
  message: intlMessageShape,
  replace: PropTypes.bool,
  to: intlToShape,
  toMessage: PropTypes.string,
  onTrigger: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  className: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.shape({
    current: PropTypes.any
  })])
})), _defineProperty(AsyncButtonLinkInternal, "defaultProps", _objectSpread({}, Button.defaultProps)), _defineProperty(AsyncButtonLinkInternal, "displayName", "AsyncButtonLink");
export var AsyncButtonLink = withAsyncAction(AsyncButtonLinkInternal);
AsyncButtonLinkInternal.__docgenInfo = {
  description: "AsyncButtonLink is a Jutro component,\ndesigned following the principles of Link (React Router 4).\nAsyncButtonLink invokes the trigger and wait for promise to be resolved or rejected.\nMeanwhile the promise is in execution, the component updates its content within the message provided by property {message}\nCase promise has been resolved, AsyncButtonLink attempts to navigate to destination provided by property {to}\nCase promise has been rejected, AsyncButtonLink attempts to navigate to destination provided by property {failTo}\n\n@example\n<AsyncButtonLink\n  to='/dashboard/success'\n  replace\n>\n  Click Me!\n</AsyncButtonLink>\n\n@metadataType action",
  methods: [],
  displayName: "AsyncButtonLink",
  props: {
    failTo: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is rejected; can be an object like `<Link to>`"
    },
    failToMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is rejected; shown if 'failTo' is not provided"
    },
    message: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message shown when executing the trigger/promise"
    },
    replace: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The replace prop will replace the current entry in the history stack"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is resolved; can be an object like `<Link to>`"
    },
    toMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is resolved; shown if 'to' is not provided"
    },
    onTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "The method used to trigger the promise"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The method to be called before (instead in case of event.preventDefault been called) the built-in onClick handler"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The children elements to render inside of the AsyncLink"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    innerRef: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "any",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Get ref to the inner rendered `<a>` or `<button>`"
    }
  },
  composes: ["@jutro/components"]
}, AsyncButtonLinkInternal.__docgenInfo = {
  componentName: "AsyncButtonLink",
  packageName: "@jutro/router",
  description: "AsyncButtonLink is a Jutro component,\ndesigned following the principles of Link (React Router 4).\nAsyncButtonLink invokes the trigger and wait for promise to be resolved or rejected.\nMeanwhile the promise is in execution, the component updates its content within the message provided by property {message}\nCase promise has been resolved, AsyncButtonLink attempts to navigate to destination provided by property {to}\nCase promise has been rejected, AsyncButtonLink attempts to navigate to destination provided by property {failTo}",
  displayName: "AsyncButtonLink",
  methods: [],
  actualName: "AsyncButtonLinkInternal",
  metadataType: "action",
  props: {
    failTo: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is rejected; can be an object like `<Link to>`"
    },
    failToMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is rejected; shown if 'failTo' is not provided"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message shown when executing the trigger/promise"
    },
    replace: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The replace prop will replace the current entry in the history stack"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is resolved; can be an object like `<Link to>`"
    },
    toMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is resolved; shown if 'to' is not provided"
    },
    onTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "The method used to trigger the promise"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The method to be called before (instead in case of event.preventDefault been called) the built-in onClick handler"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The children elements to render inside of the AsyncLink"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    innerRef: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }, {
          name: "shape",
          value: {
            current: {
              name: "any",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Get ref to the inner rendered `<a>` or `<button>`"
    }
  },
  composes: ["@jutro/components"]
};