import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "className", "disabled", "icon", "iconPosition", "inline", "onClick", "onKeyPress", "to", "href", "target", "rel", "textClassName", "dangerouslySetInnerHTML", "exact", "activeClassName", "history"];
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import isString from "lodash/isString";
import { JUTRO_TOPICS } from "@jutro/events";
import { historyPropTypes, intlMessageShape, intlToShape } from "@jutro/prop-types";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { useBreakpoint } from "@jutro/layout";
import { Router } from "react-router";
import { trackMethod } from "../../helpers";
import { Icon } from "../Icon/Icon";
import { InlineLabel } from "../InlineLabel/InlineLabel";
import styles from "./Link.module.css";
export var Link = forwardRef(function (props, ref) {
  var _cx,
    _cx2,
    _ref = useBreakpoint(props).breakpointProps,
    children = _ref.children,
    className = _ref.className,
    disabled = _ref.disabled,
    icon = _ref.icon,
    iconPosition = _ref.iconPosition,
    inline = _ref.inline,
    onClick = _ref.onClick,
    onKeyPress = _ref.onKeyPress,
    to = _ref.to,
    href = _ref.href,
    target = _ref.target,
    relProp = _ref.rel,
    textClassName = _ref.textClassName,
    exact = (_ref.dangerouslySetInnerHTML, _ref.exact),
    activeClassName = _ref.activeClassName,
    history = _ref.history,
    rest = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    classes = cx(styles.link, (_defineProperty(_cx = {}, styles.disabled, disabled), _defineProperty(_cx, styles.inline, inline), _cx), className),
    textClasses = cx(styles.text, textClassName),
    iconClasses = cx(styles.icon, (_defineProperty(_cx2 = {}, styles.left, "left" === iconPosition), _defineProperty(_cx2, styles.right, "right" === iconPosition), _cx2)),
    iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: iconClasses
    }),
    trackingProps = _objectSpread({}, props);
  isString(children) && (trackingProps.label = children);
  var trackedOnClick = trackMethod(onClick, JUTRO_TOPICS.LINK_CLICKED, trackingProps),
    content = React.createElement("span", {
      className: textClasses
    }, translator(children)),
    urlTranslatorAndSanitizer = useSafeTranslatedUrls(),
    linkProps = to ? {
      tag: NavLink,
      to: disabled ? "" : urlTranslatorAndSanitizer(to),
      exact: exact,
      activeClassName: activeClassName
    } : {
      tag: "a",
      href: disabled ? void 0 : urlTranslatorAndSanitizer(href),
      target: target,
      rel: "_blank" === target ? "noreferrer" : relProp
    },
    labelProps = _objectSpread(_objectSpread({
      className: classes,
      onClick: disabled ? void 0 : trackedOnClick,
      onKeyPress: disabled ? void 0 : function (evt) {
        var KEYS = ["Enter", " "];
        _includesInstanceProperty(KEYS).call(KEYS, evt.key) && (null == onClick || onClick(evt)), null == onKeyPress || onKeyPress(evt);
      },
      ref: ref,
      icon: iconComponent,
      iconPosition: iconPosition,
      tabIndex: 0,
      role: onClick || disabled ? "button" : void 0,
      "aria-disabled": disabled
    }, linkProps), rest),
    LinkComponent = React.createElement(InlineLabel, labelProps, content);
  return history ? React.createElement(Router, {
    history: history
  }, LinkComponent) : LinkComponent;
});
export var linkPropTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  inline: PropTypes.bool,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  to: intlToShape,
  href: intlMessageShape,
  target: PropTypes.string,
  rel: PropTypes.string,
  textClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  history: historyPropTypes
};
Link.propTypes = linkPropTypes, Link.defaultProps = {
  disabled: !1,
  iconPosition: "left",
  inline: !1
}, Link.displayName = "Link", Link.__docgenInfo = {
  description: "Link component\n\n@metadataType action",
  methods: [],
  displayName: "Link",
  props: {
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, this link is disabled"
    },
    iconPosition: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text"
    },
    inline: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the link is displayed inline and not as a block element"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The id for the link element"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The children elements to render inside the Link"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the link is clicked"
    },
    onKeyPress: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the link is focused and keyboard key pressed"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the link is clicked.\nUse this for paths internal to the application."
    },
    href: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The destination path when the link is clicked.\nUse this for paths external to the application."
    },
    target: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Where to display the linked URL. Used only for external paths."
    },
    rel: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The relationship (`rel`) attribute of the linked URL. Used only for external paths."
    },
    textClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for the link text"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for element when it is active"
    },
    exact: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When true, the `activeClassName` will only be applied if location is matched exactly"
    },
    history: {
      type: {
        name: "custom",
        raw: "historyPropTypes"
      },
      required: !1,
      description: "Specific router history to use for navigation"
    }
  }
}, Link.__docgenInfo = {
  componentName: "Link",
  packageName: "@jutro/components",
  description: "Link component",
  displayName: "Link",
  methods: [],
  actualName: "Link",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The id for the link element"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The children elements to render inside the Link"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, this link is disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    inline: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the link is displayed inline and not as a block element",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text",
      defaultValue: {
        value: "'left'",
        computed: !1
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the link is clicked"
    },
    onKeyPress: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when the link is focused and keyboard key pressed"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the link is clicked.\nUse this for paths internal to the application."
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The destination path when the link is clicked.\nUse this for paths external to the application."
    },
    target: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Where to display the linked URL. Used only for external paths."
    },
    rel: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The relationship (`rel`) attribute of the linked URL. Used only for external paths."
    },
    textClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for the link text"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for element when it is active"
    },
    exact: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When true, the `activeClassName` will only be applied if location is matched exactly"
    },
    history: {
      type: {
        name: "custom",
        raw: "historyPropTypes"
      },
      required: !1,
      description: "Specific router history to use for navigation"
    }
  }
};