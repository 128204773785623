import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { onKeyDownHandler, onKeyUpHandler } from "./onKeyHandlers";
import { onFocusHandler, isFocused } from "./onFocusHandler";
import { messages } from "../DataTable.messages";
var getColumnSortingProps = function (translator, sorted, columnId) {
  var ariaSort = function (columnId, sorted) {
      var sortedHeader = null == sorted ? void 0 : _findInstanceProperty(sorted).call(sorted, function (_ref) {
        return _ref.id === columnId;
      });
      return sortedHeader ? sortedHeader.desc ? "descending" : "ascending" : "none";
    }(columnId, sorted),
    isSorted = "none" !== ariaSort;
  return _objectSpread({
    ariaSort: ariaSort,
    isSorted: isSorted
  }, isSorted && {
    sortingDirectionLabel: translator(messages[ariaSort])
  });
};
export var getA11yTrGroupProps = function () {
  return {
    role: "rowgroup"
  };
};
export var getA11yTrProps = function () {
  return {
    role: "row"
  };
};
export var getA11yTableProps = function (translator, sorted, tableLabel) {
  return function (_ref2) {
    var _context,
      _context2,
      _context3,
      _context4,
      _context6,
      _context7,
      columns = _ref2.columns,
      sortedColumnsLabels = _filterInstanceProperty(_context = _mapInstanceProperty(_context2 = _filterInstanceProperty(_context3 = _mapInstanceProperty(_context4 = columns || []).call(_context4, function (_ref3) {
        var columnId = _ref3.id,
          headerAriaLabel = _ref3.headerAriaLabel,
          header = _ref3.header;
        return _objectSpread(_objectSpread({}, getColumnSortingProps(translator, sorted, columnId)), {}, {
          headerAriaLabel: headerAriaLabel,
          header: header
        });
      })).call(_context3, function (_ref4) {
        return _ref4.isSorted;
      })).call(_context2, function (_ref5) {
        var _context5,
          headerAriaLabel = _ref5.headerAriaLabel,
          header = _ref5.header,
          sortingDirectionLabel = _ref5.sortingDirectionLabel,
          columnAriaLabel = translator(headerAriaLabel) || translator(header);
        return _filterInstanceProperty(_context5 = [null == columnAriaLabel ? void 0 : columnAriaLabel.toLowerCase(), sortingDirectionLabel]).call(_context5, Boolean).join(" ");
      })).call(_context, Boolean),
      tableAriaLabel = sortedColumnsLabels.length > 0 ? _concatInstanceProperty(_context6 = _concatInstanceProperty(_context7 = "".concat(tableLabel, ", ")).call(_context7, translator(messages.sortedBy), " ")).call(_context6, sortedColumnsLabels.join(", ")) : tableLabel;
    return _objectSpread({
      role: "table"
    }, tableAriaLabel && {
      "aria-label": tableAriaLabel
    });
  };
};
var getA11yTheadThProps = function (tableId, focused, setFocused, state, columnId) {
  var _context8;
  return {
    id: _concatInstanceProperty(_context8 = "".concat(tableId, "_")).call(_context8, columnId),
    tabIndex: "selection" === columnId ? -1 : 0,
    onFocus: onFocusHandler(focused, setFocused, state, 0, columnId),
    onKeyUp: onKeyUpHandler(focused, tableId, state),
    onKeyDown: onKeyDownHandler(focused, tableId, state),
    role: "columnheader",
    "data-row": 0,
    "data-col": columnId,
    "data-parent": tableId
  };
};
export var getA11yTheadThPropsSortableHeader = function (tableId, sorted, focused, setFocused, translator) {
  return function (state, columnId, type, headerAriaLabel) {
    var _context9,
      _context10,
      _context11,
      _getColumnSortingProp = getColumnSortingProps(translator, sorted, columnId),
      isSorted = _getColumnSortingProp.isSorted,
      ariaSort = _getColumnSortingProp.ariaSort,
      sortingDirectionLabel = _getColumnSortingProp.sortingDirectionLabel,
      ariaSortLabel = _concatInstanceProperty(_context9 = "".concat(translator(messages.sortableColumnHeader))).call(_context9, isSorted ? _concatInstanceProperty(_context10 = ", ".concat(translator(messages.sorted), " ")).call(_context10, sortingDirectionLabel) : ""),
      resolvedHeaderLabel = translator(headerAriaLabel) || void 0,
      ariaLabel = _filterInstanceProperty(_context11 = [resolvedHeaderLabel, ariaSortLabel]).call(_context11, Boolean).join(" ");
    return _objectSpread(_objectSpread({
      "aria-sort": ariaSort
    }, getA11yTheadThProps(tableId, focused, setFocused, state, columnId)), ariaLabel && {
      "aria-label": ariaLabel
    });
  };
};
export var getA11yTheadThPropsHeader = function (tableId, focused, setFocused, translator) {
  return function (state, columnId, type) {
    var emptyHeaderAriaLabel = function (type, translator) {
      return "expander" === type ? translator(messages.expanderColumn) : "actions" === type ? translator(messages.actionsColumn) : "single_selection" === type ? translator(messages.singleSelectionColumn) : void 0;
    }(type, translator);
    return emptyHeaderAriaLabel ? _objectSpread({
      "aria-label": emptyHeaderAriaLabel
    }, getA11yTheadThProps(tableId, focused, setFocused, state, columnId)) : getA11yTheadThProps(tableId, focused, setFocused, state, columnId);
  };
};
export var getA11yTdProps = function (tableId, focused, setFocused) {
  return function (state, rowInfo, columnId, shouldBeFocusable) {
    return {
      role: "cell",
      tabIndex: !shouldBeFocusable && isFocused(focused, state, rowInfo.viewIndex + 1, columnId) ? 0 : -1,
      "data-row": rowInfo.viewIndex + 1,
      "data-col": columnId,
      "data-parent": tableId,
      onFocus: onFocusHandler(focused, setFocused, state, rowInfo.viewIndex + 1, columnId),
      onKeyUp: onKeyUpHandler(focused, tableId, state),
      onKeyDown: onKeyDownHandler(focused, tableId, state)
    };
  };
};