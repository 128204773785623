import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { components } from "react-select";
import cx from "classnames";
export var GenericSelectControlMenu = function (props) {
  var _props$selectProps = props.selectProps,
    genericSelectStyles = _props$selectProps.genericSelectStyles,
    isLoading = _props$selectProps.isLoading,
    ariaDisabled = _props$selectProps["aria-disabled"];
  return isLoading || ariaDisabled ? null : React.createElement(components.Menu, _extends({}, props, {
    className: cx(genericSelectStyles.genericSelect, genericSelectStyles.menu)
  }));
};
GenericSelectControlMenu.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlMenu"
}, GenericSelectControlMenu.__docgenInfo = {
  componentName: "GenericSelectControlMenu",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlMenu",
  methods: [],
  actualName: "GenericSelectControlMenu"
};