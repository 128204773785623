import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "value", "defaultValue", "maxDate", "minDate", "onValueChange", "label", "path", "startPath", "endPath", "dataPath", "startDataPath", "endDataPath", "startPathValueProp", "endPathValueProp", "readOnly", "labelPosition", "className", "endLabel", "endSecondaryLabel", "endTooltip", "endPlaceholder", "endContentContainerClassName", "endControlClassName", "endLabelContainerClassName", "endLabelClassName", "highlightRange", "errorMessage"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { dateRangeValueShape, intlMessageShape, nestedTooltipShape } from "@jutro/prop-types";
import { GridLayout } from "@jutro/layout";
import get from "lodash/get";
import { DateField } from "../DateField/DateField";
import { messages } from "./DateRangeField.messages";
import styles from "./DateRangeField.module.css";
var dateRangeFieldDefaultProps = _objectSpread(_objectSpread({}, DateField.defaultProps), {}, {
    defaultValue: {},
    startPathValueProp: "startDate",
    endPathValueProp: "endDate",
    highlightRange: !1,
    errorMessage: {}
  }),
  dateRangePropTypes = _objectSpread(_objectSpread({}, DateField.propTypes), {}, {
    value: dateRangeValueShape,
    defaultValue: dateRangeValueShape,
    startPath: PropTypes.string,
    endPath: PropTypes.string,
    startDataPath: PropTypes.string,
    endDataPath: PropTypes.string,
    startPathValueProp: PropTypes.string,
    endPathValueProp: PropTypes.string,
    endLabel: intlMessageShape,
    endSecondaryLabel: intlMessageShape,
    endTooltip: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
    endPlaceholder: intlMessageShape,
    endContentContainerClassName: PropTypes.string,
    endControlClassName: PropTypes.string,
    endLabelContainerClassName: PropTypes.string,
    endLabelClassName: PropTypes.string,
    highlightRange: PropTypes.bool,
    errorMessage: PropTypes.any
  });
export var DateRangeField = function (_ref) {
  var _context,
    _context2,
    _context3,
    _context4,
    _context5,
    _context6,
    id = _ref.id,
    value = _ref.value,
    _ref$defaultValue = _ref.defaultValue,
    defaultValue = void 0 === _ref$defaultValue ? {} : _ref$defaultValue,
    maxDate = _ref.maxDate,
    minDate = _ref.minDate,
    onValueChange = _ref.onValueChange,
    label = _ref.label,
    path = _ref.path,
    startPath = _ref.startPath,
    endPath = _ref.endPath,
    dataPath = _ref.dataPath,
    startDataPath = _ref.startDataPath,
    endDataPath = _ref.endDataPath,
    startPathValueProp = _ref.startPathValueProp,
    endPathValueProp = _ref.endPathValueProp,
    readOnly = _ref.readOnly,
    labelPosition = _ref.labelPosition,
    className = _ref.className,
    endLabel = _ref.endLabel,
    endSecondaryLabel = _ref.endSecondaryLabel,
    endTooltip = _ref.endTooltip,
    endPlaceholder = _ref.endPlaceholder,
    endContentContainerClassName = _ref.endContentContainerClassName,
    endControlClassName = _ref.endControlClassName,
    endLabelContainerClassName = _ref.endLabelContainerClassName,
    endLabelClassName = _ref.endLabelClassName,
    highlightRange = _ref.highlightRange,
    errorMessage = _ref.errorMessage,
    commonDateProps = _objectWithoutProperties(_ref, _excluded),
    _ref2 = function (value, startPathValueProp, endPathValueProp) {
      if (value) return {
        startDate: startPathValueProp ? value[startPathValueProp] : value.startDate,
        endDate: endPathValueProp ? value[endPathValueProp] : value.endDate
      };
    }(value, startPathValueProp, endPathValueProp) || defaultValue,
    startDate = _ref2.startDate,
    endDate = _ref2.endDate,
    startDatePath = null != startPath ? startPath : _concatInstanceProperty(_context = "".concat(path, ".")).call(_context, startPathValueProp),
    endDatePath = null != endPath ? endPath : _concatInstanceProperty(_context2 = "".concat(path, ".")).call(_context2, endPathValueProp),
    startDateDataPath = null != startDataPath ? startDataPath : _concatInstanceProperty(_context3 = "".concat(dataPath, ".")).call(_context3, startPathValueProp),
    endDateDataPath = null != endDataPath ? endDataPath : _concatInstanceProperty(_context4 = "".concat(dataPath, ".")).call(_context4, endPathValueProp),
    setDateRange = useCallback(function (startDate, endDate, model, props) {
      var endDateChanged = props.endDateChanged;
      onValueChange && onValueChange(endDateChanged ? endDate : startDate, model, props);
    }, [onValueChange]),
    setMinDate = useCallback(function (newStartDate, model, props) {
      return setDateRange(newStartDate, endDate, model, _objectSpread(_objectSpread({}, props), {}, {
        startDateChanged: !0
      }));
    }, [endDate, setDateRange]),
    setMaxDate = useCallback(function (newEndDate, model, props) {
      return setDateRange(startDate, newEndDate, model, _objectSpread(_objectSpread({}, props), {}, {
        endDateChanged: !0
      }));
    }, [startDate, setDateRange]),
    labelOnTop = "top" === labelPosition,
    gridColumns = labelOnTop ? ["minmax(0, 1fr)"] : [];
  return React.createElement(GridLayout, {
    id: id,
    className: className,
    repeat: 2,
    columns: gridColumns,
    hgap: "small",
    vgap: labelOnTop ? "none" : void 0,
    phone: {
      columns: [],
      repeat: void 0,
      vgap: void 0
    },
    style: {
      gridTemplateRows: "min-content"
    },
    defaultGridItem: {
      className: cx(_defineProperty({}, styles.fieldContainer, labelOnTop)),
      phone: {
        className: void 0
      }
    }
  }, React.createElement(DateField, _extends({}, commonDateProps, {
    readOnly: readOnly,
    labelPosition: labelPosition,
    label: label || messages.startLabel,
    contentContainerClassName: cx(_defineProperty({}, styles.startDateField, labelOnTop), commonDateProps.contentContainerClassName),
    labelContainerClassName: cx(_defineProperty({}, styles.startDateLabel, labelOnTop), commonDateProps.labelContainerClassName),
    id: _concatInstanceProperty(_context5 = "".concat(id, "_")).call(_context5, startPathValueProp),
    path: startDatePath,
    dataPath: startDateDataPath,
    value: startDate,
    minDate: minDate,
    maxDate: endDate || maxDate,
    onValueChange: setMinDate,
    startDate: highlightRange ? startDate || (null == value ? void 0 : value.startDate) : void 0,
    endDate: highlightRange ? endDate || (null == value ? void 0 : value.endDate) : void 0,
    errorMessage: get(errorMessage, startPathValueProp),
    selectsStart: highlightRange
  })), React.createElement(DateField, _extends({}, commonDateProps, {
    readOnly: readOnly,
    labelPosition: labelPosition,
    label: endLabel || messages.endLabel,
    secondaryLabel: endSecondaryLabel || commonDateProps.secondaryLabel,
    placeholder: endPlaceholder || commonDateProps.placeholder,
    tooltip: endTooltip || commonDateProps.tooltip,
    contentContainerClassName: cx(_defineProperty({}, styles.endDateField, labelOnTop), endContentContainerClassName || commonDateProps.contentContainerClassName),
    controlClassName: endControlClassName || commonDateProps.controlClassName,
    labelContainerClassName: cx(_defineProperty({}, styles.endDateLabel, labelOnTop), endLabelContainerClassName || commonDateProps.labelContainerClassName),
    labelClassName: endLabelClassName || commonDateProps.labelClassName,
    id: _concatInstanceProperty(_context6 = "".concat(id, "_")).call(_context6, endPathValueProp),
    path: endDatePath,
    dataPath: endDateDataPath,
    value: endDate,
    minDate: startDate || minDate,
    maxDate: maxDate,
    onValueChange: setMaxDate,
    startDate: highlightRange ? startDate || (null == value ? void 0 : value.startDate) : void 0,
    endDate: highlightRange ? endDate || (null == value ? void 0 : value.endDate) : void 0,
    errorMessage: get(errorMessage, endPathValueProp),
    selectsEnd: highlightRange
  })));
};
DateRangeField.displayName = "DateRangeField", DateRangeField.propTypes = dateRangePropTypes, DateRangeField.defaultProps = dateRangeFieldDefaultProps, DateRangeField.__docgenInfo = {
  description: "@extends {FieldComponent<DateRangeFieldPropTypes>}\n\n@metadataType field",
  methods: [],
  displayName: "DateRangeField",
  props: {
    defaultValue: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateRangeValueShape"
      },
      required: !1,
      description: "Default date range value"
    },
    startPathValueProp: {
      defaultValue: {
        value: "'startDate'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of prop in value object for startPath. This is needed to work with @jutro/validation mechanism"
    },
    endPathValueProp: {
      defaultValue: {
        value: "'endDate'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of prop in value object for endPath. This is needed to work with @jutro/validation mechanism"
    },
    highlightRange: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "if true, selected range will be highlighted"
    },
    errorMessage: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      type: {
        name: "any"
      },
      required: !1,
      description: "Error message to display"
    },
    value: {
      type: {
        name: "custom",
        raw: "dateRangeValueShape"
      },
      required: !1,
      description: "Date range value to display if used as controlled component"
    },
    startPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path in model to start date"
    },
    endPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path in model to end date"
    },
    startDataPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Start date dataPath"
    },
    endDataPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "End date dataPath"
    },
    endLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for end date label"
    },
    endSecondaryLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for end date label (by default same as secondaryLabel prop)"
    },
    endTooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip for end date (by default same as tooltip prop for start date)"
    },
    endPlaceholder: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for end date placeholder (by default same as placeholder prop)"
    },
    endContentContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end content container (by default takes value from contentContainerClassName prop if specified)"
    },
    endControlClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end date control input (by default takes value from controlClassName prop if specified)"
    },
    endLabelContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end label container (by default takes value from labelContainerClassName prop if specified)"
    },
    endLabelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end date label (by default takes value from labelClassName prop if specified)"
    }
  },
  composes: ["../DateField/DateField"]
}, DateRangeField.__docgenInfo = {
  componentName: "DateRangeField",
  packageName: "@jutro/components",
  description: "",
  displayName: "DateRangeField",
  methods: [],
  actualName: "DateRangeField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "dateRangeValueShape"
      },
      required: !1,
      description: "Date range value to display if used as controlled component"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateRangeValueShape"
      },
      required: !1,
      description: "Default date range value",
      defaultValue: {
        value: "{}",
        computed: !1
      }
    },
    startPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path in model to start date"
    },
    endPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path in model to end date"
    },
    startDataPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Start date dataPath"
    },
    endDataPath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "End date dataPath"
    },
    startPathValueProp: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of prop in value object for startPath. This is needed to work with @jutro/validation mechanism",
      defaultValue: {
        value: "'startDate'",
        computed: !1
      }
    },
    endPathValueProp: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of prop in value object for endPath. This is needed to work with @jutro/validation mechanism",
      defaultValue: {
        value: "'endDate'",
        computed: !1
      }
    },
    endLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for end date label"
    },
    endSecondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for end date label (by default same as secondaryLabel prop)"
    },
    endTooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip for end date (by default same as tooltip prop for start date)"
    },
    endPlaceholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for end date placeholder (by default same as placeholder prop)"
    },
    endContentContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end content container (by default takes value from contentContainerClassName prop if specified)"
    },
    endControlClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end date control input (by default takes value from controlClassName prop if specified)"
    },
    endLabelContainerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end label container (by default takes value from labelContainerClassName prop if specified)"
    },
    endLabelClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "class for end date label (by default takes value from labelClassName prop if specified)"
    },
    highlightRange: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "if true, selected range will be highlighted",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    errorMessage: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Error message to display",
      defaultValue: {
        value: "{}",
        computed: !1
      }
    }
  },
  composes: ["../DateField/DateField"]
};