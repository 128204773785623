import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { createHOC } from "@jutro/platform";
import { intlMessageShape } from "@jutro/prop-types";
import { ValueComponentWrapper } from "../../../values/ValueComponentWrapper";
import { FormattedPhoneNumber, formattedPhoneNumberPropTypes } from "./FormattedPhoneNumber";
var PhoneValueInternal = createHOC({
    component: FormattedPhoneNumber,
    wrapper: ValueComponentWrapper,
    displayName: "PhoneValue"
  }),
  phoneValuePropTypes = _objectSpread(_objectSpread({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    path: PropTypes.string,
    tag: PropTypes.string,
    prefix: intlMessageShape,
    suffix: intlMessageShape
  }, formattedPhoneNumberPropTypes), {}, {
    _rest: PropTypes.any
  });
export var PhoneNumberValue = function (props) {
  return React.createElement(PhoneValueInternal, props);
};
PhoneNumberValue.propTypes = phoneValuePropTypes, PhoneNumberValue.defaultProps = _objectSpread({
  tag: "div"
}, FormattedPhoneNumber.defaultProps), PhoneNumberValue.__docgenInfo = {
  description: "Renders a formatted international phone number using the `tag` property to wrap\nthe value.\n\n@type {React.FC<PropTypes.InferProps<typeof phoneValuePropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "PhoneNumberValue",
  props: {
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The html tag to use when rendering the outermost element of this component"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint path to value"
    },
    prefix: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Optional suffix message to be attached at the end the value"
    },
    _rest: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Do not set this field directly - this is a placeholder for the rest od properties, same as you can pass to FormattedPhoneNumber component"
    }
  },
  composes: ["./FormattedPhoneNumber"]
}, PhoneNumberValue.__docgenInfo = {
  componentName: "PhoneNumberValue",
  packageName: "@jutro/components",
  description: "Renders a formatted international phone number using the `tag` property to wrap\nthe value.",
  displayName: "PhoneNumberValue",
  methods: [],
  actualName: "PhoneNumberValue",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint path to value"
    },
    tag: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    },
    prefix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Optional suffix message to be attached at the end the value"
    },
    _rest: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Do not set this field directly - this is a placeholder for the rest od properties, same as you can pass to FormattedPhoneNumber component"
    }
  },
  composes: ["./FormattedPhoneNumber"]
};