import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import isString from "lodash/isString";
import get from "lodash/get";
import { log } from "@jutro/logger";
import { refTypeMap, REF_PROPERTY, REF_PROPERTY_SHORTCUT } from "../common/refs";
export function resolveContentReference(item, rootMetadata) {
  return isString(item) && _startsWithInstanceProperty(item).call(item, REF_PROPERTY_SHORTCUT) ? extendMetadata(void 0, item.substr(REF_PROPERTY_SHORTCUT.length), rootMetadata) : item[REF_PROPERTY] ? extendMetadata(item, item[REF_PROPERTY], rootMetadata) : item;
}
export function extendMetadata(targetItem, refKey, rootMetadata) {
  if (!refKey) return targetItem;
  var parts = refKey.split("/"),
    baseItem = get(rootMetadata, parts);
  if (!baseItem) return log.warning('unable to resolve @ref: "'.concat(refKey, '"')), targetItem;
  var extendedItem = baseItem;
  return targetItem && (delete (extendedItem = _objectSpread(_objectSpread({}, baseItem), targetItem))[REF_PROPERTY], baseItem.componentProps && targetItem.componentProps && (extendedItem.componentProps = _objectSpread(_objectSpread({}, baseItem.componentProps), targetItem.componentProps))), function (extendedItem, parts, refKey) {
    if (!extendedItem.id || !extendedItem.type) {
      var _parts = _slicedToArray(parts, 2),
        idFromRefKey = _parts[0],
        typeFromRefKey = _parts[1];
      extendedItem.id || (idFromRefKey ? extendedItem.id = idFromRefKey : log.warning("unable to resolve 'id' from: \"".concat(refKey, '"'))), extendedItem.type || (typeFromRefKey ? extendedItem.type = refTypeMap[typeFromRefKey] : log.warning("unable to resolve 'type' from: \"".concat(refKey, '"')));
    }
  }(extendedItem, parts.reverse(), refKey), extendedItem;
}