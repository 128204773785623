import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { layoutShape } from "../../../layouts/Layouts.commons";
import styles from "./ModalFooter.module.css";
import { getLayoutComponent } from "../helpers/modalHelpers";
var defaultLayout = {
    component: "flex",
    componentProps: {
      alignItems: "middle",
      justifyContent: "right",
      wrap: "reverse",
      gap: "medium"
    }
  },
  modalFooterPropTypes = {
    contentLayout: PropTypes.oneOfType([PropTypes.shape({
      component: PropTypes.string.isRequired,
      componentProps: PropTypes.object
    }), layoutShape]),
    children: PropTypes.node
  };
export var ModalFooter = function (_ref) {
  var contentLayout = _ref.contentLayout,
    children = _ref.children,
    Layout = getLayoutComponent(contentLayout.component),
    footerClasses = styles.modalFooter,
    footerLayoutClasses = cx(_defineProperty({}, styles.modalFooterFlexLayout, contentLayout === defaultLayout && "flex" === defaultLayout.component));
  return React.createElement("div", {
    className: footerClasses
  }, React.createElement(Layout, _extends({
    className: footerLayoutClasses
  }, contentLayout.componentProps), children));
};
ModalFooter.propTypes = modalFooterPropTypes, ModalFooter.defaultProps = {
  contentLayout: defaultLayout
}, ModalFooter.__docgenInfo = {
  description: "ModalFooter\n@type {React.FC<PropTypes.InferProps<typeof modalFooterPropTypes>>}",
  methods: [],
  displayName: "ModalFooter",
  props: {
    contentLayout: {
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        alignItems: 'middle',\n        justifyContent: 'right',\n        wrap: 'reverse',\n        gap: 'medium',\n    },\n}",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: !0
            },
            componentProps: {
              name: "object",
              required: !1
            }
          }
        }, {
          name: "custom",
          raw: "layoutShape"
        }]
      },
      required: !1,
      description: "defines the layout to be used with a 'component' property set to either Flex or Grid\n(otherwise, will default to a div) and 'componentProperties' to set properties for that layout component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content of the ModalFooter. Typically Buttons."
    }
  }
}, ModalFooter.__docgenInfo = {
  componentName: "ModalFooter",
  packageName: "@jutro/components",
  description: "ModalFooter",
  displayName: "ModalFooter",
  methods: [],
  actualName: "ModalFooter",
  props: {
    contentLayout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: !0
            },
            componentProps: {
              name: "object",
              required: !1
            }
          }
        }, {
          name: "shape",
          value: {
            repeat: {
              name: "union",
              value: [{
                name: "enum",
                value: [{
                  value: "'auto-fit'",
                  computed: !1
                }, {
                  value: "'auto-fill'",
                  computed: !1
                }]
              }, {
                name: "number"
              }, {
                name: "string"
              }],
              description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
              required: !1
            },
            gap: {
              name: "enum",
              computed: !0,
              value: "Object.keys(gaps)",
              description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
              required: !1
            },
            columns: {
              name: "array",
              description: "define explicit columns",
              required: !1
            },
            colSpan: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              description: "specifies how many columns an element should span across",
              required: !1
            },
            colStart: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              description: "define column to start",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "defines the layout to be used with a 'component' property set to either Flex or Grid\n(otherwise, will default to a div) and 'componentProperties' to set properties for that layout component",
      defaultValue: {
        value: "{\n    component: 'flex',\n    componentProps: {\n        alignItems: 'middle',\n        justifyContent: 'right',\n        wrap: 'reverse',\n        gap: 'medium',\n    },\n}",
        computed: !1
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content of the ModalFooter. Typically Buttons."
    }
  }
};