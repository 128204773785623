import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { IntlContext, LocaleContext, useTranslator, parseDateFromDataType, formatDateToLocalePattern } from "@jutro/locale";
import { dateValueShape, intlMessageShape, dateFormatShape } from "@jutro/prop-types";
import { getConfigValue } from "@jutro/config";
import { FieldComponent } from "../inputs/FieldComponent/FieldComponent";
function getFormattedDateProps(props, date) {
  var _FormattedDate$defaul = _objectSpread(_objectSpread({}, FormattedDate.defaultProps), props),
    dateProp = _FormattedDate$defaul.date,
    value = _FormattedDate$defaul.value,
    placeholder = _FormattedDate$defaul.placeholder,
    displayFormat = _FormattedDate$defaul.displayFormat,
    inputFormat = _FormattedDate$defaul.inputFormat,
    dateValue = date || dateProp || value;
  if (!dateValue) return {
    placeholder: null != placeholder ? placeholder : FieldComponent.defaultROEmptyValue
  };
  var includeTime = (null == inputFormat ? void 0 : _includesInstanceProperty(inputFormat).call(inputFormat, "h")) || (null == inputFormat ? void 0 : _includesInstanceProperty(inputFormat).call(inputFormat, "m"));
  return {
    dateObj: parseDateFromDataType(dateValue, includeTime),
    displayFormat: displayFormat,
    inputFormat: inputFormat
  };
}
export var formattedDatePropTypes = {
  date: PropTypes.instanceOf(Date),
  value: dateValueShape,
  placeholder: intlMessageShape,
  displayFormat: dateFormatShape,
  inputFormat: PropTypes.string,
  skipTimeZone: PropTypes.bool
};
export var FormattedDate = function (props) {
  var _getFormattedDateProp = getFormattedDateProps(props),
    placeholder = _getFormattedDateProp.placeholder,
    dateObj = _getFormattedDateProp.dateObj,
    displayFormat = _getFormattedDateProp.displayFormat,
    inputFormat = _getFormattedDateProp.inputFormat,
    skipTimeZone = props.skipTimeZone,
    translator = useTranslator(),
    defaultTimeZone = useContext(LocaleContext).defaultTimeZone,
    intl = useContext(IntlContext);
  return placeholder ? React.createElement(Fragment, null, translator(placeholder)) : React.createElement(Fragment, null, formatDateToLocalePattern(dateObj, null == intl ? void 0 : intl.formatDate, displayFormat, inputFormat, skipTimeZone ? void 0 : defaultTimeZone));
};
FormattedDate.propTypes = formattedDatePropTypes, FormattedDate.defaultProps = {
  displayFormat: "long",
  placeholder: FieldComponent.defaultROEmptyValue
};
export function formatDate(intl, props, date) {
  var _getFormattedDateProp2 = getFormattedDateProps(props, date),
    placeholder = _getFormattedDateProp2.placeholder,
    dateObj = _getFormattedDateProp2.dateObj,
    displayFormat = _getFormattedDateProp2.displayFormat,
    inputFormat = _getFormattedDateProp2.inputFormat,
    skipTimeZone = props.skipTimeZone;
  if (placeholder) return placeholder;
  var defaultTimeZone = getConfigValue("localeSettings.defaultTimeZone");
  return formatDateToLocalePattern(dateObj, null == intl ? void 0 : intl.formatDate, displayFormat, inputFormat, skipTimeZone ? void 0 : defaultTimeZone);
}
FormattedDate.__docgenInfo = {
  description: "The `FormattedDate` component is used to render `Date` values\nlocalized and in specific preset formats, e.g. short or long.\n\n@metadataType element",
  methods: [],
  displayName: "FormattedDate",
  props: {
    displayFormat: {
      defaultValue: {
        value: "'long'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "dateFormatShape"
      },
      required: !1,
      description: "The date format: 'vshort', 'short', 'long', 'abbreviated', 'full'"
    },
    placeholder: {
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The string to display if 'date' is undefined/null"
    },
    date: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: !1,
      description: "The date to display"
    },
    value: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "The value to display in the format of string|number|object"
    },
    inputFormat: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Format of the date displayed when the value is edited.\nUsed to retrieve tokens that should be displayed."
    },
    skipTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, date won't be converted to defaultTimezone.\nUse if date is already converted to desired time zone."
    }
  }
}, FormattedDate.__docgenInfo = {
  componentName: "FormattedDate",
  packageName: "@jutro/components",
  description: "The `FormattedDate` component is used to render `Date` values\nlocalized and in specific preset formats, e.g. short or long.",
  displayName: "FormattedDate",
  methods: [],
  actualName: "FormattedDate",
  metadataType: "element",
  props: {
    date: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: !1,
      description: "The date to display"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The value to display in the format of string|number|object"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The string to display if 'date' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      }
    },
    displayFormat: {
      type: {
        name: "custom",
        raw: "dateFormatShape"
      },
      required: !1,
      description: "The date format: 'vshort', 'short', 'long', 'abbreviated', 'full'",
      defaultValue: {
        value: "'long'",
        computed: !1
      }
    },
    inputFormat: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Format of the date displayed when the value is edited.\nUsed to retrieve tokens that should be displayed."
    },
    skipTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, date won't be converted to defaultTimezone.\nUse if date is already converted to desired time zone."
    }
  }
};