export var consumerComponents = {
  "--GW-TAB-BAR-HEIGHT": "2.5rem",
  "--GW-DATA-TABLE-ROW-MIN-HEIGHT": "2.5rem",
  "--GW-DATA-TABLE-BUTTON-SIZE": "1.5rem",
  "--GW-COLUMN-ENTRY-HEIGHT": "2rem",
  "--GW-FIELD-COMPONENT-HEIGHT": "2.5rem",
  "--GW-LOOKUP-FIELD-ICON-BACKGROUND-SIZE": "1.5rem",
  "--GW-CHECKBOX-SIZE": "1.25rem",
  "--GW-DATE-FIELD-DAY-NAME-SIZE": "2rem",
  "--GW-GENERIC-SELECT-MAX-HEIGHT": "15rem",
  "--GW-FILE-UPLOAD-HEIGHT-THIN": "3.25rem",
  "--GW-RADIO-BUTTON-SIZE": "1.5rem",
  "--GW-RADIO-BUTTON-CARD-RADIO-BUTTON-SIZE": "1.5rem",
  "--GW-SWITCH-FIELD-HEIGHT": "1.5rem",
  "--GW-SWITCH-FIELD-WIDTH": "3rem",
  "--GW-STEPPER-FIELD-WIDTH": "2.5rem",
  "--GW-PHONE-NUMBER-FLAG-CONTAINER-WIDTH": "7.25rem",
  "--GW-MODAL-HEADER-STATUS-LINE-HEIGHT": "0.25rem",
  "--GW-MODAL-HEADER-PADDING-HORIZONTAL": "var(--GW-LAYOUT-6)",
  "--GW-MODAL-HEADER-PADDING-TOP": "var(--GW-LAYOUT-8)",
  "--GW-MODAL-HEADER-PADDING-BOTTOM": "var(--GW-LAYOUT-2)",
  "--GW-MODAL-BODY-PADDING": "var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8)",
  "--GW-MODAL-FOOTER-PADDING": "var(--GW-LAYOUT-5)",
  "--GW-ACCORDION-MIN-HEIGHT": "2.5rem",
  "--GW-CHEVRON-SIZE": "1.5rem",
  "--GW-INFO-LABEL-SIZE": "1.5rem",
  "--GW-INLINE-NOTIFICATION-MIN-HEIGHT": "3rem",
  "--GW-NOTIFICATION-DISMISS-BUTTON-SIZE": "1.5rem",
  "--GW-GLOBALIZATION-CHOOSER-HEIGHT": "2.5rem",
  "--GW-GLOBALIZATION-CHOOSER-MIN-WIDTH": "10rem",
  "--GW-LINK-HEIGHT": "1.5rem",
  "--GW-PHASE-BAR-WIDTH": "0.5rem",
  "--GW-CARD-PADDING": "var(--GW-LAYOUT-6)",
  "--GW-POPOVER-FOOTER-ICON-SIZE": "0.75rem",
  "--GW-TAG-MIN-HEIGHT": "2rem",
  "--GW-TAG-BUTTON-SIZE": "1rem",
  "--GW-TAG-LABEL-MAX-WIDTH": "9rem",
  "--GW-TEXTAREA-FIELD-MIN-HEIGHT": "5rem",
  "--GW-SLIDER-FIELD-HEIGHT": "2.75rem",
  "--GW-SLIDER-FIELD-RAIL-HEIGHT": "0.25rem",
  "--GW-SLIDER-FIELD-HANDLE-DIAMETER": "1.5rem",
  "--GW-BUTTON-HEIGHT": "2.5rem",
  "--GW-BUTTON-HEIGHT-SMALL": "2rem",
  "--GW-ICON-BUTTON-HEIGHT": "2.5rem",
  "--GW-ICON-BUTTON-WIDTH": "2.5rem",
  "--GW-MODAL-CLOSE-BUTTON-MARGIN-TOP": "var(--GW-SPACING-3)",
  "--GW-SIMPLE-PROGRESS-BAR-MIN-HEIGHT": "0.5rem",
  "--GW-HEADER-HEIGHT": "4.25rem",
  "--GW-AVATAR-SIZE": "2.5rem"
};