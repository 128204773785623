import React from "react";
import cx from "classnames";
import { getMessageProp } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { messages } from "../FileUploadField.messages";
import { multipleFileUploadMessages } from "../MultipleFilesUploadField/MultipleFilesUploadField.messages";
import { FileUploadActionButton } from "./FileUploadActionButton";
import styles from "../FileUploadField.module.css";
export var LargeUploadArea = function (_ref) {
  var messageProps = _ref.messageProps,
    imageSource = _ref.imageSource,
    disabled = _ref.disabled,
    buttonType = _ref.buttonType,
    buttonSize = _ref.buttonSize,
    buttonIcon = _ref.buttonIcon,
    disableDragAndDropMessage = _ref.disableDragAndDropMessage,
    onAction = _ref.onAction,
    variant = _ref.variant,
    fileName = _ref.fileName,
    genericMessage = _ref.genericMessage,
    fileLimit = _ref.fileLimit,
    imageClassName = _ref.imageClassName,
    dragAndDropMessageClassName = _ref.dragAndDropMessageClassName,
    translator = useTranslator(),
    emptyUploadAreaMessage = translator(getMessageProp("emptyUploadAreaMessage", messageProps, messages)),
    fileAreaMessage = translator(getMessageProp("empty" === variant ? "emptyUploadAreaMessage" : "uploadedFilesMessage", messageProps, messages), genericMessage),
    multipeFilesAreaMessage = fileLimit && 1 !== fileLimit && translator(getMessageProp("unlimited" === fileLimit ? "emptyUnlimitedUploadAreaMessage" : "emptyLimitedUploadAreaMessage", messageProps, multipleFileUploadMessages), genericMessage);
  return React.createElement(React.Fragment, null, imageSource && React.createElement("img", {
    src: imageSource,
    alt: emptyUploadAreaMessage,
    className: cx(styles.areaImage, imageClassName),
    "aria-hidden": !0
  }), !disableDragAndDropMessage && React.createElement("div", {
    className: cx(styles.valueText, dragAndDropMessageClassName)
  }, multipeFilesAreaMessage || fileAreaMessage, fileName && " ".concat(fileName)), React.createElement(FileUploadActionButton, {
    size: buttonSize,
    type: buttonType,
    icon: buttonIcon,
    messageProps: messageProps,
    disabled: disabled,
    onClick: onAction
  }));
};
LargeUploadArea.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LargeUploadArea"
}, LargeUploadArea.__docgenInfo = {
  componentName: "LargeUploadArea",
  packageName: "@jutro/components",
  description: "",
  displayName: "LargeUploadArea",
  methods: [],
  actualName: "LargeUploadArea"
};