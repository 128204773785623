import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["className", "values", "defaultLabel"],
  _excluded2 = ["isOpen", "menuId"];
import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { contextShape, intlMessageShape } from "@jutro/prop-types";
import { DropdownMenu, DropdownMenuLink } from "@jutro/components";
import { usePathname } from "@jutro/platform";
import { ContextSwitcherButton } from "./ContextSwitcherButton";
import styles from "./ContextSwitcher.module.css";
export var isContextActive = function (location, _ref) {
  var path = _ref.path,
    exact = _ref.exact,
    match = _ref.match,
    pathMatches = matchPath(location, {
      path: path,
      exact: exact
    }),
    regexMatches = !!match && location.match(match);
  return !!pathMatches || !!regexMatches;
};
export var ContextSwitcher = function (_ref2) {
  var className = _ref2.className,
    values = _valuesInstanceProperty(_ref2),
    defaultLabel = _ref2.defaultLabel,
    others = _objectWithoutProperties(_ref2, _excluded),
    pathname = usePathname(),
    translator = useTranslator(),
    classes = cx(className),
    activeContext = useMemo(function () {
      return _findInstanceProperty(values).call(values, function (context) {
        return isContextActive(pathname, context);
      });
    }, [pathname, values]),
    renderTrigger = useCallback(function (_ref3, toggleMenu) {
      _ref3.isOpen;
      var menuId = _ref3.menuId,
        other = _objectWithoutProperties(_ref3, _excluded2);
      return React.createElement(ContextSwitcherButton, _extends({
        "aria-controls": menuId,
        onClick: toggleMenu
      }, other), React.createElement("span", null, (null == activeContext ? void 0 : activeContext.title) || translator(defaultLabel)));
    }, [null == activeContext ? void 0 : activeContext.title, defaultLabel, translator]),
    links = useMemo(function () {
      return _mapInstanceProperty(values).call(values, function (context, index) {
        var path = context.path,
          title = context.title;
        return React.createElement(DropdownMenuLink, {
          key: index.toString(),
          to: path,
          activeClassName: styles.activeContext,
          isActive: function () {
            return isContextActive(pathname, context);
          }
        }, title);
      });
    }, [pathname, values]);
  return React.createElement(DropdownMenu, _extends({
    id: "context-switcher",
    className: classes,
    menuClassName: styles.contextSwitcherMenu,
    renderTrigger: renderTrigger
  }, others), links);
};
ContextSwitcher.propTypes = {
  className: PropTypes.string,
  defaultLabel: intlMessageShape,
  values: PropTypes.arrayOf(contextShape).isRequired
}, ContextSwitcher.__docgenInfo = {
  description: "Context switcher is a route dropdown. It can be used to\nnavigate through high level routes (contexts) in an application.\n\n@metadataType container\n@param {object} props Props for the component",
  methods: [],
  displayName: "ContextSwitcher",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component"
    },
    defaultLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Default button label when no context is active"
    },
    values: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "contextShape"
        }
      },
      required: !0,
      description: "Array of values for the contexts"
    }
  }
}, ContextSwitcher.__docgenInfo = {
  componentName: "ContextSwitcher",
  packageName: "@jutro/router",
  description: "Context switcher is a route dropdown. It can be used to\nnavigate through high level routes (contexts) in an application.",
  displayName: "ContextSwitcher",
  methods: [],
  actualName: "ContextSwitcher",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component"
    },
    defaultLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Default button label when no context is active"
    },
    values: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            path: {
              name: "string",
              description: "The context path",
              required: !1
            },
            title: {
              name: "custom",
              raw: "intlMessageShape",
              description: "Context title",
              required: !1
            },
            exact: {
              name: "bool",
              description: "If true, will only match if the path matches the location.pathname exactly\n(unless match regular expression matches)",
              required: !1
            },
            match: {
              name: "union",
              value: [{
                name: "string"
              }, {
                name: "instanceOf",
                value: "RegExp"
              }],
              description: "The context will be considered active if the path matches this regular\nexpression, even if the path does not match the active route",
              required: !1
            }
          }
        }
      },
      required: !0,
      description: "Array of values for the contexts"
    }
  }
};