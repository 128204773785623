import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { uniqueInnerId } from "@jutro/platform";
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING, DATA_TYPE_OBJECT, availableValueObjectShape, intlMessageShape } from "@jutro/prop-types";
import { isEmptyValue, isNilValue } from "@jutro/data";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { tryToCastToBoolean, findAvailableValue } from "../FieldComponent/utils";
import { AnimationGroup } from "../../AnimationGroup/AnimationGroup";
import styles from "./ToggleField.module.css";
import buttonStyles from "../../button/Button.module.css";
import { messages } from "./ToggleField.messages";
import { animationDelayDefault } from "../../config/animationMap";
import { ToggleButton } from "./ToggleButton";
var defaultAvailableValues = [{
  code: "true",
  name: messages.yes
}, {
  code: "false",
  name: messages.no
}];
export var ToggleField = function (_FieldComponent) {
  _inherits(ToggleField, FieldComponent);
  var _super = _createSuper(ToggleField);
  function ToggleField() {
    var _context, _this;
    _classCallCheck(this, ToggleField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "handleButtonClick", function (evt) {
      evt.preventDefault();
      var targetValue = evt.target.dataset.value;
      _this.handleAvailableValuesValueChange(targetValue);
    }), _defineProperty(_assertThisInitialized(_this), "translateObjectValue", function (targetValue) {
      var availableValues = _this.props.availableValues,
        availableValue = _findInstanceProperty(availableValues).call(availableValues, findAvailableValue(targetValue));
      return isNilValue(availableValue) ? availableValue : isNilValue(availableValue.name) ? {
        id: availableValue.id,
        displayName: _this.translator(availableValue.displayName)
      } : {
        code: availableValue.code,
        name: _this.translator(availableValue.name)
      };
    }), _defineProperty(_assertThisInitialized(_this), "handleAvailableValuesValueChange", function (targetValue) {
      if (_this.props.dataType === DATA_TYPE_OBJECT) _this.notifyChange(_this.translateObjectValue(targetValue));else _this.notifyChange(tryToCastToBoolean(targetValue));
    }), _defineProperty(_assertThisInitialized(_this), "getDataTypeAwareSelectedValue", function () {
      var _this$props = _this.props,
        dataType = _this$props.dataType,
        value = _this$props.value;
      if (dataType === DATA_TYPE_OBJECT && !isEmptyValue(value)) {
        var selectedValue = isNilValue(value.code) ? value.id : value.code;
        return !isNilValue(selectedValue) && selectedValue.toString() || void 0;
      }
      return value;
    }), _this;
  }
  return _createClass(ToggleField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(ToggleField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderButton",
    value: function (option, styleClasses, activeStyle, activeKeypress, uniqueId, ariaLabel) {
      var _option$name,
        selectedValue = this.getDataTypeAwareSelectedValue(),
        callIfUnrelated = function (func) {
          return function (e) {
            document.getElementById(uniqueId).contains(e.relatedTarget) || null == func || func(e);
          };
        };
      return React.createElement(ToggleButton, _extends({
        key: (null == option ? void 0 : option.id) || (null == option || null === (_option$name = option.name) || void 0 === _option$name ? void 0 : _option$name.id) || (null == option ? void 0 : option.name) || (null == option ? void 0 : option.code),
        uniqueId: uniqueId,
        active: activeStyle,
        activeKeypress: activeKeypress,
        option: option,
        styleClasses: styleClasses,
        handleButtonClick: this.handleButtonClick,
        icon: option.icon,
        iconClassName: option.iconClassName,
        iconPosition: option.iconPosition,
        iconSize: option.iconSize
      }, this.props, {
        onFocus: callIfUnrelated(this.props.onFocus),
        onBlur: callIfUnrelated(this.props.onBlur),
        value: selectedValue,
        ariaLabel: ariaLabel
      }));
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function () {
      var accessibilityProperties = _get(_getPrototypeOf(ToggleField.prototype), "generateAccessibilityProperties", this).call(this),
        required = accessibilityProperties["aria-required"];
      return accessibilityProperties.required = required, delete accessibilityProperties["aria-required"], accessibilityProperties;
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _context2,
        _this2 = this,
        availableValues = breakpointProps.availableValues,
        label = breakpointProps.label,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        disabled = breakpointProps.disabled,
        children = breakpointProps.children,
        animation = breakpointProps.animation,
        detailElement = breakpointProps.detailElement,
        buttonAriaLabels = breakpointProps.buttonAriaLabels,
        fieldUniqueId = this.fieldUniqueId,
        uniqueId = uniqueInnerId(fieldUniqueId, "uniqueId").uniqueId,
        value = this.getDataTypeAwareSelectedValue(),
        detailVisible = value && "true" === value.toString() && (detailElement || children),
        translator = this.translator,
        values = availableValues,
        buttons = [],
        optionCount = null == values ? void 0 : values.length;
      values.forEach(function (option, index) {
        var _cx,
          isFirstElement = 0 === index,
          isLastElement = index === optionCount - 1,
          isNotFirstOrLast = !isFirstElement && !isLastElement,
          buttonClasses = cx(buttonStyles.button, styles.toggleButton, (_defineProperty(_cx = {
            disabled: disabled
          }, styles.left, isFirstElement), _defineProperty(_cx, styles.right, isLastElement), _defineProperty(_cx, styles.middle, isNotFirstOrLast), _cx)),
          button = _this2.renderButton(option, buttonClasses, styles.active, styles.activeKeypress, uniqueId, null == buttonAriaLabels ? void 0 : buttonAriaLabels[index]);
        buttons.push(button);
      });
      var outerClasses = cx(styles.toggle, controlClassName),
        groupLabel = required ? _concatInstanceProperty(_context2 = "".concat(translator(label), ", ")).call(_context2, translator(messages.required)) : translator(label),
        control = React.createElement("div", _extends({
          className: outerClasses,
          role: "group"
        }, this.generateAccessibilityProperties(), {
          "aria-label": groupLabel
        }), React.createElement("div", {
          id: uniqueId,
          className: styles.content
        }, buttons)),
        otherContent = React.createElement("div", {
          role: "region",
          "aria-live": "assertive",
          "aria-hidden": !detailVisible
        }, detailVisible && React.createElement(AnimationGroup, {
          animation: animation || "insert",
          appear: !0,
          timeout: {
            appear: animationDelayDefault
          }
        }, detailElement || children));
      return React.createElement(React.Fragment, null, control, otherContent);
    }
  }]), ToggleField;
}();
_defineProperty(ToggleField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  detailElement: PropTypes.node,
  animation: PropTypes.string,
  children: PropTypes.node,
  buttonAriaLabels: PropTypes.arrayOf(intlMessageShape)
})), _defineProperty(ToggleField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  availableValues: defaultAvailableValues,
  dataType: DATA_TYPE_STRING
})), _defineProperty(ToggleField, "contextType", FieldComponent.contextType), ToggleField.__docgenInfo = {
  description: "Renders buttons that allow you to toggle between the available values.\n@typedef {typeof ToggleField.propTypes} ToggleFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<ToggleFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "handleButtonClick",
    docblock: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "renderButton",
    docblock: "Creates a button element\n\n@param {object} option - object that contains the value for the button\n@param {object} styleClasses - class name object of button styles\n@param {object} activeStyle - class name object of button active styles\n@returns {Element} - a button element",
    modifiers: [],
    params: [{
      name: "option",
      description: "object that contains the value for the button",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "styleClasses",
      description: "class name object of button styles",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "activeStyle",
      description: "class name object of button active styles",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "activeKeypress"
    }, {
      name: "uniqueId"
    }, {
      name: "ariaLabel"
    }],
    returns: {
      description: "a button element",
      type: {
        name: "Element"
      }
    },
    description: "Creates a button element"
  }, {
    name: "translateObjectValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "targetValue",
      type: null
    }],
    returns: null
  }, {
    name: "handleAvailableValuesValueChange",
    docblock: "Change handler\n\n@param {any} targetValue - The new value to set",
    modifiers: [],
    params: [{
      name: "targetValue",
      description: "The new value to set",
      type: {
        name: "any"
      },
      optional: !1
    }],
    returns: null,
    description: "Change handler"
  }, {
    name: "getDataTypeAwareSelectedValue",
    docblock: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT or DATA_TYPE_GW_REST_OBJECT, return the `code` from the value.\nOtherwise return the value itself\n\n@returns {any} - The selected value",
    modifiers: [],
    params: [],
    returns: {
      description: "The selected value",
      type: {
        name: "any"
      }
    },
    description: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT or DATA_TYPE_GW_REST_OBJECT, return the `code` from the value.\nOtherwise return the value itself"
  }, {
    name: "generateAccessibilityProperties",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "ToggleField",
  props: {
    availableValues: {
      defaultValue: {
        value: "[\n    {\n        code: 'true',\n        name: messages.yes,\n    },\n    {\n        code: 'false',\n        name: messages.no,\n    },\n]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "availableValueObjectShape"
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain `code` and `name`; if not provided, default 'Yes'/'No' is used"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "Format of the value"
    },
    value: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "Boolean, object, or string value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "Default value"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: !1,
      description: 'Node to render when the "other" value is selected'
    },
    animation: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Animation passed to AnimationGroup"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Wrapped children to be rendered when the value prop is set to true"
    },
    buttonAriaLabels: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "intlMessageShape"
        }
      },
      required: !1,
      description: "Aria labels for buttons"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, ToggleField.__docgenInfo = {
  componentName: "ToggleField",
  packageName: "@jutro/components",
  description: "Renders buttons that allow you to toggle between the available values.",
  displayName: "ToggleField",
  methods: [{
    name: "handleButtonClick",
    docblock: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "renderButton",
    docblock: "Creates a button element\n\n@param {object} option - object that contains the value for the button\n@param {object} styleClasses - class name object of button styles\n@param {object} activeStyle - class name object of button active styles\n@returns {Element} - a button element",
    modifiers: [],
    params: [{
      name: "option",
      description: "object that contains the value for the button",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "styleClasses",
      description: "class name object of button styles",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "activeStyle",
      description: "class name object of button active styles",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "activeKeypress"
    }, {
      name: "uniqueId"
    }, {
      name: "ariaLabel"
    }],
    returns: {
      description: "a button element",
      type: {
        name: "Element"
      }
    },
    description: "Creates a button element"
  }, {
    name: "translateObjectValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "targetValue",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleAvailableValuesValueChange",
    docblock: "Change handler\n\n@param {any} targetValue - The new value to set",
    modifiers: [],
    params: [{
      name: "targetValue",
      description: "The new value to set",
      type: {
        name: "any"
      },
      optional: !1
    }],
    returns: null,
    description: "Change handler"
  }, {
    name: "getDataTypeAwareSelectedValue",
    docblock: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT or DATA_TYPE_GW_REST_OBJECT, return the `code` from the value.\nOtherwise return the value itself\n\n@returns {any} - The selected value",
    modifiers: [],
    params: [],
    returns: {
      description: "The selected value",
      type: {
        name: "any"
      }
    },
    description: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT or DATA_TYPE_GW_REST_OBJECT, return the `code` from the value.\nOtherwise return the value itself"
  }, {
    name: "generateAccessibilityProperties",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "ToggleField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "Boolean, object, or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "Default value"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "Format of the value",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: !1
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: !1
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: !1
              }
            }
          }]
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain `code` and `name`; if not provided, default 'Yes'/'No' is used",
      defaultValue: {
        value: "[\n    {\n        code: 'true',\n        name: messages.yes,\n    },\n    {\n        code: 'false',\n        name: messages.no,\n    },\n]",
        computed: !1
      }
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: !1,
      description: 'Node to render when the "other" value is selected'
    },
    animation: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Animation passed to AnimationGroup"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Wrapped children to be rendered when the value prop is set to true"
    },
    buttonAriaLabels: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }
      },
      required: !1,
      description: "Aria labels for buttons"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};