import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _styleIconWrapperByTy, _stylesNotificationBy;
import styles from "./InlineNotification.module.css";
export var inlineSeverityTypes = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error"
};
export var styleIconWrapperByType = (_styleIconWrapperByTy = {}, _defineProperty(_styleIconWrapperByTy, inlineSeverityTypes.INFO, styles.iconWrapperInfo), _defineProperty(_styleIconWrapperByTy, inlineSeverityTypes.SUCCESS, styles.iconWrapperSuccess), _defineProperty(_styleIconWrapperByTy, inlineSeverityTypes.WARNING, styles.iconWrapperWarning), _defineProperty(_styleIconWrapperByTy, inlineSeverityTypes.ERROR, styles.iconWrapperError), _styleIconWrapperByTy);
export var stylesNotificationByType = (_stylesNotificationBy = {}, _defineProperty(_stylesNotificationBy, inlineSeverityTypes.INFO, styles.inlineNotificationInfo), _defineProperty(_stylesNotificationBy, inlineSeverityTypes.SUCCESS, styles.inlineNotificationSuccess), _defineProperty(_stylesNotificationBy, inlineSeverityTypes.WARNING, styles.inlineNotificationWarning), _defineProperty(_stylesNotificationBy, inlineSeverityTypes.ERROR, styles.inlineNotificationError), _stylesNotificationBy);