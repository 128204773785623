import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import { isEmptyValue } from "@jutro/data";
import { getOptionCode } from "./widgets/inputs/availableValues";
export var SPACE_BAR = " ";
export var createHandleRadioButtonKeyDown = function (focusTrigger) {
  return function (evt, props, handleAvailableValuesValueChange) {
    var model = props.model,
      path = props.path,
      value = props.value,
      availableValues = props.availableValues,
      selectedElementIndex = _findIndexInstanceProperty(availableValues).call(availableValues, function (el) {
        var selectedValue = value ? getOptionCode(value) || value.toString() : void 0;
        return !isEmptyValue(value) && getOptionCode(el) === selectedValue;
      }),
      handleMove = eventMap[evt.key];
    handleMove && evt.preventDefault();
    var targetIndex = handleMove ? handleMove(selectedElementIndex, availableValues) : -1;
    if (targetIndex >= 0) {
      var optionCode = getOptionCode(availableValues[targetIndex]);
      handleAvailableValuesValueChange(optionCode, model || path), null == focusTrigger || focusTrigger(evt.currentTarget, optionCode);
    }
  };
};
var handleMoveDown = function (selectedElementIndex, availableValues) {
    return (selectedElementIndex + 1) % availableValues.length;
  },
  handleMoveUp = function (selectedElementIndex, availableValues) {
    return 0 === selectedElementIndex ? availableValues.length - 1 : (selectedElementIndex - 1) % availableValues.length;
  },
  eventMap = {
    SPACE_BAR: function () {
      return -1;
    },
    ArrowDown: handleMoveDown,
    ArrowRight: handleMoveDown,
    ArrowUp: handleMoveUp,
    ArrowLeft: handleMoveUp
  };
export var getTabIndex = function (index) {
  return 0 === index ? 0 : -1;
};
export var getTabIndexForRadioGroup = function (option, selectedValue, index) {
  var _getOptionCode,
    code = null !== (_getOptionCode = getOptionCode(option)) && void 0 !== _getOptionCode ? _getOptionCode : "empty",
    checked = "empty" === code ? isEmptyValue(selectedValue) : (null == selectedValue ? void 0 : selectedValue.toString()) === (null == code ? void 0 : code.toString());
  return isEmptyValue(selectedValue) && 0 === index || checked ? 0 : -1;
};