import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
var _context;
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import { log } from "@jutro/logger";
import ServiceRegistry from "./ServiceRegistry";
var ServiceManager = function () {
    function ServiceManager() {
      _classCallCheck(this, ServiceManager), this.registry = new ServiceRegistry();
    }
    return _createClass(ServiceManager, [{
      key: "register",
      value: function (id, Service, options) {
        this.registry.register(id, Service, options);
      }
    }, {
      key: "getService",
      value: function (id) {
        if (this.registry.hasRegistration(id)) return this.registry.getRegistration(id).getService();
        log.warning("No service available for id '".concat(id, "'"));
      }
    }, {
      key: "getServiceByContract",
      value: function (contract, resolver) {
        var implementors = this.registry.getImplementorsOf(contract);
        if (0 !== implementors.length) {
          if (!resolver) return implementors.length > 1 && log.warning('Contract "'.concat(contract, '" has multiple implementations, but no resolver provided')), this.getService(implementors[0].id);
          var metadata = _mapInstanceProperty(implementors).call(implementors, function (reg) {
              return reg.getMetadata();
            }),
            selected = _findInstanceProperty(metadata).call(metadata, resolver);
          if (void 0 !== selected) return this.getService(selected.id);
          log.warning("Resolver callback did not find any implementations");
        }
      }
    }, {
      key: "listRegistrations",
      value: function () {
        log.info("Currently registered services: ".concat(this.registry.getRegistrationIds().join(", ")));
      }
    }]), ServiceManager;
  }(),
  SERVICE_MANAGER_KEY = _Symbol("jutro.services.serviceManager");
-1 === _indexOfInstanceProperty(_context = _Object$getOwnPropertySymbols(global)).call(_context, SERVICE_MANAGER_KEY) && (global[SERVICE_MANAGER_KEY] = new ServiceManager());
export default global[SERVICE_MANAGER_KEY];