import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["aria-label"];
import _parseFloat from "@babel/runtime-corejs3/core-js-stable/parse-float";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import { intlMessageShape, DATA_TYPE_STRING, DATA_TYPE_NUMBER, validationMessageShape } from "@jutro/prop-types";
import { isEmptyValue, isNumber } from "@jutro/data";
import cx from "classnames";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { NumberValue } from "../../values/NumberValue";
import { NumberInput } from "./NumberInput";
import { InputNumberFieldValidationImplementation } from "./InputNumberFieldValidationImplementation";
import { OldInputNumberFieldValidationImplementation } from "./OldInputNumberFieldValidationImplementation";
import inputFieldStyles from "../InputField/InputField.module.css";
import { FieldIcon } from "../FieldComponent/FieldIcon";
var numberDataTypeShape = PropTypes.oneOf([DATA_TYPE_STRING, DATA_TYPE_NUMBER]),
  inputNumberFieldPropTypes = _objectSpread(_objectSpread({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataType: numberDataTypeShape,
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, validationMessageShape]))]),
    maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, validationMessageShape]))]),
    decimalPlaces: PropTypes.number,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }, FieldIcon.propTypes), {}, {
    messageProps: PropTypes.shape({
      validationMinValue: intlMessageShape,
      validationMaxValue: intlMessageShape,
      validationInvalidNumber: intlMessageShape
    }),
    name: PropTypes.string,
    showGrouping: PropTypes.bool
  });
export var InputNumberField = function (_FieldComponent) {
  _inherits(InputNumberField, FieldComponent);
  var _super = _createSuper(InputNumberField);
  function InputNumberField(props) {
    var _this;
    _classCallCheck(this, InputNumberField), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "handleChange", function (value) {
      var dataType = _this.props.dataType;
      isEmptyValue(value) && _this.notifyChange(""), isNumber(value) && (dataType === DATA_TYPE_NUMBER ? _this.notifyChange(_parseFloat(value)) : _this.notifyChange(value));
    });
    var ValidationImplementation = props.registerValidation ? new InputNumberFieldValidationImplementation() : new OldInputNumberFieldValidationImplementation();
    return _this.validationImplementation = ValidationImplementation.bind(_assertThisInitialized(_this)), _this;
  }
  return _createClass(InputNumberField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(InputNumberField.prototype), "render", this).call(this);
    }
  }, {
    key: "getValidationConfig",
    value: function () {
      for (var _get2, _context, _context2, _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      var validationConfig = (_get2 = _get(_getPrototypeOf(InputNumberField.prototype), "getValidationConfig", this)).call.apply(_get2, _concatInstanceProperty(_context = [this]).call(_context, args));
      return _objectSpread(_objectSpread({}, validationConfig), {}, {
        propsList: _concatInstanceProperty(_context2 = ["required", "minValue", "maxValue"]).call(_context2, _toConsumableArray(get(validationConfig, "propsList", []))),
        options: get(validationConfig, "options", [])
      });
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      return this.validationImplementation.getValidationMessages();
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var styles = inputFieldStyles,
        value = breakpointProps.value,
        maxLength = breakpointProps.maxLength,
        decimalPlaces = breakpointProps.decimalPlaces,
        disabled = breakpointProps.disabled,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        minValue = breakpointProps.minValue,
        maxValue = breakpointProps.maxValue,
        step = breakpointProps.step,
        testId = breakpointProps.testId,
        id = breakpointProps.id,
        name = breakpointProps.name,
        registerValidation = breakpointProps.registerValidation,
        showGrouping = breakpointProps.showGrouping,
        label = breakpointProps.label,
        fieldUniqueId = this.fieldUniqueId,
        messageStyle = this.getValidationMessageStyle(this.getValidationMessages()),
        inputStyle = cx(styles.input, _defineProperty({
          disabled: disabled
        }, messageStyle, !options.isValid), controlClassName, !isUndefined(registerValidation) && !options.isValid && styles.labpreviewBorder),
        icon = breakpointProps.icon,
        iconPosition = breakpointProps.iconPosition,
        inputAutoComplete = name ? "on" : "off",
        _this$generateAccessi = this.generateAccessibilityProperties(),
        fieldComponentAriaLabel = _this$generateAccessi["aria-label"],
        restAccessibilityProperties = _objectWithoutProperties(_this$generateAccessi, _excluded);
      return React.createElement(FieldIcon, {
        icon: icon,
        iconPosition: iconPosition,
        disabled: disabled
      }, React.createElement(NumberInput, _extends({
        id: fieldUniqueId,
        testId: testId || id,
        className: inputStyle,
        maxLength: maxLength,
        value: value,
        onChange: this.handleChange,
        disabled: disabled,
        required: required,
        min: minValue,
        max: maxValue,
        step: step,
        decimalPlaces: decimalPlaces,
        autoComplete: inputAutoComplete,
        name: name,
        showGrouping: showGrouping,
        label: label,
        fieldComponentAriaLabel: fieldComponentAriaLabel
      }, this.generateDataPathProperty(), restAccessibilityProperties, this.getInputFocusHandlers())));
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        decimalPlaces = breakpointProps.decimalPlaces,
        showGrouping = breakpointProps.showGrouping,
        controlClassName = breakpointProps.controlClassName;
      if (!value && 0 !== value) {
        return React.createElement("div", null, "-");
      }
      return React.createElement(NumberValue, _extends({
        id: id,
        value: +value,
        showFractions: !0,
        maximumFractionDigits: decimalPlaces,
        showGrouping: showGrouping,
        className: controlClassName
      }, this.generateReadOnlyAccessibilityProperties()));
    }
  }]), InputNumberField;
}();
_defineProperty(InputNumberField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), inputNumberFieldPropTypes)), _defineProperty(InputNumberField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  dataType: DATA_TYPE_NUMBER,
  decimalPlaces: 2,
  step: 1,
  showGrouping: !0
})), _defineProperty(InputNumberField, "contextType", FieldComponent.contextType), InputNumberField.__docgenInfo = {
  description: "Renders an input element for number fields.\n@typedef {typeof inputNumberFieldPropTypes} InputNumberFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<InputNumberFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\nIf field is 'number' and have max and min value and no validation message is provided, it is added",
    modifiers: [],
    params: [],
    returns: null,
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\nIf field is 'number' and have max and min value and no validation message is provided, it is added"
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} value - new value",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactNode} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }],
  displayName: "InputNumberField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_NUMBER",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "numberDataTypeShape"
      },
      required: !1,
      description: "Type of returned value in the `onValueChange` callback"
    },
    decimalPlaces: {
      defaultValue: {
        value: "2",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "The number of decimal places to display in the value"
    },
    step: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Step for increment/decrement, such as `0.05`"
    },
    showGrouping: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to `true`, displays the thousands separator"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Number passed as value"
    },
    minValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Minimum value for the number"
    },
    maxValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Maximum value for the number"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          validationMinValue: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Validation message for min value",
            required: !1
          },
          validationMaxValue: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Validation message for max value",
            required: !1
          },
          validationInvalidNumber: {
            name: "custom",
            raw: "intlMessageShape",
            description: 'Validation message for "not a number"',
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "This name will be passed to the input name attribute and switched on autocomplete"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, InputNumberField.__docgenInfo = {
  componentName: "InputNumberField",
  packageName: "@jutro/components",
  description: "Renders an input element for number fields.",
  displayName: "InputNumberField",
  methods: [{
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\nIf field is 'number' and have max and min value and no validation message is provided, it is added",
    modifiers: [],
    params: [],
    returns: null,
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\nIf field is 'number' and have max and min value and no validation message is provided, it is added"
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} value - new value",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactNode} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }],
  actualName: "InputNumberField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Number passed as value"
    },
    dataType: {
      type: {
        name: "custom",
        raw: "numberDataTypeShape"
      },
      required: !1,
      description: "Type of returned value in the `onValueChange` callback",
      defaultValue: {
        value: "'number'",
        computed: !1
      }
    },
    minValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Minimum value for the number"
    },
    maxValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Maximum value for the number"
    },
    decimalPlaces: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The number of decimal places to display in the value",
      defaultValue: {
        value: "2",
        computed: !1
      }
    },
    step: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Step for increment/decrement, such as `0.05`",
      defaultValue: {
        value: "1",
        computed: !1
      }
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          validationMinValue: {
            name: "union",
            description: "Validation message for min value",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          validationMaxValue: {
            name: "union",
            description: "Validation message for max value",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          validationInvalidNumber: {
            name: "union",
            description: 'Validation message for "not a number"',
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "This name will be passed to the input name attribute and switched on autocomplete"
    },
    showGrouping: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to `true`, displays the thousands separator",
      defaultValue: {
        value: "true",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};