import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["animation", "children", "onEntered", "dangerouslySetInnerHTML"],
  _excluded2 = ["transitionWrapper"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { log } from "@jutro/logger";
import { resolveAnimationFromName, animationDelayDefault } from "../config/animationMap";
import styles from "./AnimationGroup.module.css";
var animationGroupPropTypes = {
  animation: PropTypes.oneOf(["expand", "insert", "highlight", "reveal"]),
  className: PropTypes.string,
  children: PropTypes.node
};
export var AnimationGroup = function (_ref) {
  var animation = _ref.animation,
    children = _ref.children,
    onEntered = _ref.onEntered,
    other = (_ref.dangerouslySetInnerHTML, _objectWithoutProperties(_ref, _excluded)),
    animationProps = useMemo(function () {
      var animationConfig = resolveAnimationFromName(animation);
      return isFunction(animationConfig) ? animationConfig() : animationConfig;
    }, [animation]),
    onEnteredCallback = useCallback(function (node, isAppearing) {
      node.style = _objectSpread(_objectSpread({}, node.style), {}, {
        height: "auto"
      }), null == onEntered || onEntered(node, isAppearing);
    }, [onEntered]);
  animation && !animationProps && (log.warning("Unable to find animation alias for '".concat(animation, "'. Using default properties.")), animationProps = {
    classNames: animation,
    timeout: animationDelayDefault
  });
  var appearClasses,
    combinedProps = _objectSpread(_objectSpread(_objectSpread({}, animationProps), other), {}, {
      onEntered: onEnteredCallback
    }),
    classNames = combinedProps.classNames,
    enterClasses = {
      enter: styles["".concat(classNames, "_enter")],
      enterActive: styles["".concat(classNames, "_enter_active")]
    },
    exitClasses = {
      exit: styles["".concat(classNames, "_exit")],
      exitActive: styles["".concat(classNames, "_exit_active")]
    };
  combinedProps.appear && (appearClasses = {
    appear: styles["".concat(classNames, "_enter")],
    appearActive: styles["".concat(classNames, "_enter_active")]
  }), combinedProps.classNames = _objectSpread(_objectSpread(_objectSpread({}, enterClasses), exitClasses), appearClasses);
  var transitionGroup,
    transitionWrapper = combinedProps.transitionWrapper,
    groupProps = _objectWithoutProperties(combinedProps, _excluded2);
  if (React.Children.count(children) > 1) {
    var _context,
      childTransitions = _mapInstanceProperty(_context = React.Children).call(_context, children, function (child) {
        return React.createElement(CSSTransition, groupProps, child);
      });
    transitionGroup = React.createElement(TransitionGroup, null, childTransitions);
  } else transitionGroup = React.createElement(CSSTransition, groupProps, React.createElement("span", null, children));
  if (transitionWrapper) {
    var wrapperClass = styles[transitionWrapper];
    return React.createElement("div", {
      className: wrapperClass
    }, transitionGroup);
  }
  return transitionGroup;
};
AnimationGroup.propTypes = animationGroupPropTypes, AnimationGroup.__docgenInfo = {
  description: "Defines an Animation Group.\nThis wraps TransitionGroup/CSSTransition and provides support for animation aliases to define transition properties.\nThe animations are triggered when elements are added to or removed from the children of the animation group.\nFor the 'swap' type animations where you are replacing one element with another, each element requires a unique\n'key' to trigger the animation.\n\nProperties: {@link AnimationGroup.propTypes}\n\n@see https://github.com/reactjs/react-transition-group\n\n@example <AnimationGroup animation=\"reveal\">{ children to animate }</AnimationGroup>",
  methods: [],
  displayName: "AnimationGroup",
  props: {
    animation: {
      type: {
        name: "enum",
        value: [{
          value: "'expand'",
          computed: !1
        }, {
          value: "'insert'",
          computed: !1
        }, {
          value: "'highlight'",
          computed: !1
        }, {
          value: "'reveal'",
          computed: !1
        }]
      },
      required: !1,
      description: "Optional animation name/alias"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid element"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children, preferably 'Row'"
    }
  }
}, AnimationGroup.__docgenInfo = {
  componentName: "AnimationGroup",
  packageName: "@jutro/components",
  description: "Defines an Animation Group.\nThis wraps TransitionGroup/CSSTransition and provides support for animation aliases to define transition properties.\nThe animations are triggered when elements are added to or removed from the children of the animation group.\nFor the 'swap' type animations where you are replacing one element with another, each element requires a unique\n'key' to trigger the animation.\n\nProperties: {@link AnimationGroup.propTypes}",
  displayName: "AnimationGroup",
  methods: [],
  actualName: "AnimationGroup",
  props: {
    animation: {
      type: {
        name: "enum",
        value: [{
          value: "'expand'",
          computed: !1
        }, {
          value: "'insert'",
          computed: !1
        }, {
          value: "'highlight'",
          computed: !1
        }, {
          value: "'reveal'",
          computed: !1
        }]
      },
      required: !1,
      description: "Optional animation name/alias"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid element"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children, preferably 'Row'"
    }
  }
};