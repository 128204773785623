import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "icon", "size", "color", "className", "tag", "noDeprecationWarnings", "dangerouslySetInnerHTML"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { forwardRef, useRef, useLayoutEffect, useState, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { deprecated } from "@jutro/prop-types";
import { logDeprecationMessage } from "@jutro/platform";
import { warning } from "@jutro/logger";
import { useBreakpoint } from "@jutro/layout";
import styles from "./Icon.module.css";
import { getIconClasses, gwCustomIconsIconPrefix, gwIconsIconPrefix, isCustomIcon, isHexColor } from "../../iconUtils";
import { gwIconsSet } from "./gwIcons";
import { IconSVG } from "./IconSVG";
export var iconSizes = ["small", "medium", "large"];
var iconPropTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.string,
  tag: deprecated(PropTypes.string),
  _rest: PropTypes.any
};
export var Icon = forwardRef(function (props, ref) {
  var _cx2,
    breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    icon = breakpointProps.icon,
    size = breakpointProps.size,
    color = breakpointProps.color,
    className = breakpointProps.className,
    tag = breakpointProps.tag,
    noDeprecationWarnings = breakpointProps.noDeprecationWarnings,
    rest = (breakpointProps.dangerouslySetInnerHTML, _objectWithoutProperties(breakpointProps, _excluded)),
    Tag = tag || "i",
    iconRef = useRef(null),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    emptyIcon = _useState2[0],
    setEmptyIcon = _useState2[1],
    gwIconCheck = gwIconsSet.has(icon) || isCustomIcon(icon),
    isValidHexColor = !isHexColor(color);
  if (useImperativeHandle(ref, function () {
    return null == iconRef ? void 0 : iconRef.current;
  }), useLayoutEffect(function () {
    var isEmptyIcon = !iconRef.current || !gwIconCheck && "none" === window.getComputedStyle(iconRef.current, ":before").content;
    setEmptyIcon(isEmptyIcon), color && isValidHexColor && warning("Incorrect HEX string: ".concat(color, " provided in color prop for Icon."));
  }, [icon, iconRef, color, gwIconCheck, isValidHexColor]), useEffect(function () {
    var _context,
      notIgnored = !noDeprecationWarnings || !noDeprecationWarnings.some(function (ignoreStr) {
        return "mi-icon" === ignoreStr;
      });
    !gwIconCheck && notIgnored && logDeprecationMessage("".concat(icon, " icon"), _concatInstanceProperty(_context = "".concat(gwIconsIconPrefix, " or ")).call(_context, gwCustomIconsIconPrefix, " icons."));
  }, [icon]), !icon) return null;
  var classes = cx(getIconClasses(icon), _defineProperty({}, styles.empty, emptyIcon), (_defineProperty(_cx2 = {}, styles.iconSize1, "small" === size), _defineProperty(_cx2, styles.iconSize2, "medium" === size), _defineProperty(_cx2, styles.iconSize3, "large" === size), _cx2), className);
  return gwIconCheck ? React.createElement(IconSVG, {
    id: id,
    ref: iconRef,
    icon: icon,
    color: isValidHexColor ? void 0 : color,
    className: classes,
    _rest: rest
  }) : React.createElement(Tag, _extends({
    id: id,
    ref: iconRef,
    style: color && (isValidHexColor ? void 0 : {
      color: color
    }),
    className: classes
  }, rest));
});
Icon.propTypes = iconPropTypes, Icon.displayName = "Icon", Icon.__docgenInfo = {
  description: "@metadataType element",
  methods: [],
  displayName: "Icon",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !0,
      description: "icon name to be used"
    },
    size: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }, {
          value: "'large'",
          computed: !1
        }]
      },
      required: !1,
      description: "Determines the size of icon"
    },
    color: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Determines the color (HEX code) of icon"
    },
    tag: {
      type: {
        name: "custom",
        raw: "deprecated(PropTypes.string)"
      },
      required: !1,
      description: "Tag used to render icon\n@deprecated tag will be selected internally based on icon type/a11y etc. it's not safe to pass it from outside"
    },
    _rest: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Set of custom props, specific to a particular tag being used"
    }
  }
}, Icon.__docgenInfo = {
  componentName: "Icon",
  packageName: "@jutro/components",
  description: "",
  displayName: "Icon",
  methods: [],
  actualName: "Icon",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !0,
      description: "icon name to be used"
    },
    size: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }, {
          value: "'large'",
          computed: !1
        }]
      },
      required: !1,
      description: "Determines the size of icon"
    },
    color: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Determines the color (HEX code) of icon"
    },
    tag: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Tag used to render icon\n@deprecated tag will be selected internally based on icon type/a11y etc. it's not safe to pass it from outside",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    _rest: {
      type: {
        name: "any"
      },
      required: !1,
      description: "Set of custom props, specific to a particular tag being used"
    }
  }
};