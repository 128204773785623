import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import React from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { messages } from "../LookupField.messages";
import { LookupOptionIcon } from "./LookupOptionIcon";
import { LookupAddLink } from "./LookupAddLink";
import { getOptionName } from "../../availableValues";
export var LookupOption = function (props) {
  var _cx,
    data = props.data,
    innerProps = props.innerProps,
    isFocused = props.isFocused,
    isSelected = props.isSelected,
    isDisabled = props.isDisabled,
    _props$selectProps = props.selectProps,
    customStyles = _props$selectProps.customStyles,
    inputValue = _props$selectProps.inputValue,
    components = _props$selectProps.components,
    optionTypesMap = _props$selectProps.optionTypesMap,
    translator = useTranslator(),
    stateClassName = cx((_defineProperty(_cx = {}, customStyles.focused, isFocused), _defineProperty(_cx, customStyles.selected, isSelected), _defineProperty(_cx, customStyles.optionDisabled, isDisabled), _cx)),
    type = data.type,
    value = data.value;
  if (data.__isNew__) {
    var AddLink = components.AddLink || LookupAddLink;
    return React.createElement(AddLink, _extends({}, props, {
      value: value,
      className: stateClassName,
      innerProps: innerProps
    }));
  }
  var translatedTypeDisplayName = translator((optionTypesMap[type] || {}).displayName || messages.unknownType);
  return React.createElement("div", _extends({}, innerProps, {
    className: cx(customStyles.option, stateClassName)
  }), React.createElement(LookupOptionIcon, {
    type: type,
    customStyles: customStyles,
    optionTypesMap: optionTypesMap,
    title: translatedTypeDisplayName
  }), React.createElement("div", {
    className: customStyles.item
  }, React.createElement("div", {
    className: customStyles.title
  }, renderTitle(translator(getOptionName(data)), inputValue)), React.createElement("div", {
    className: customStyles.subtitle
  }, translatedTypeDisplayName)));
};
function renderTitle(displayName, searchString) {
  var _context;
  if (!displayName || !searchString) return displayName;
  var index = _indexOfInstanceProperty(_context = displayName.toLowerCase()).call(_context, searchString.toLowerCase());
  return -1 === index ? displayName : React.createElement(React.Fragment, null, displayName.substr(0, index), React.createElement("strong", null, displayName.substr(index, searchString.length)), displayName.substr(index + searchString.length));
}
LookupOption.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupOption"
}, LookupOption.__docgenInfo = {
  componentName: "LookupOption",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupOption",
  methods: [],
  actualName: "LookupOption"
}, renderTitle.__docgenInfo = {
  componentName: "renderTitle",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderTitle",
  methods: [],
  actualName: "renderTitle"
};