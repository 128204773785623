import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context3;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context3 = Object.prototype.toString.call(o)).call(_context3, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _entriesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/entries";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useState, useLayoutEffect, useMemo, useRef } from "react";
import cx from "classnames";
import merge from "lodash/merge";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { routesShape, contextSwitcherShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { useBreakpoint } from "@jutro/layout";
import { TopNavigation } from "../NavBar/TopNavigation/TopNavigation";
import styles from "./ApplicationHeader.module.css";
import navBarStyles from "../NavBar/NavBarItem.module.css";
import { createMoreButton } from "./MoreButton";
import { messages } from "./ApplicationHeader.messages";
import { useObserveThemeFontsLoading } from "../hooks/useObserveThemeFontsLoading";
var useRoutesForNavigation = function (navigationRoutes, containerRef) {
  var _useState = useState("recalculate"),
    _useState2 = _slicedToArray(_useState, 2),
    status = _useState2[0],
    setStatus = _useState2[1],
    _useState3 = useState(navigationRoutes.length),
    _useState4 = _slicedToArray(_useState3, 2),
    sliceIndex = _useState4[0],
    setSliceIndex = _useState4[1],
    visibleNavigationRoutes = useMemo(function () {
      return _filterInstanceProperty(navigationRoutes).call(navigationRoutes, function (route) {
        return !1 !== route.showOnNavBar;
      });
    }, [navigationRoutes]),
    routesForSubHeader = useMemo(function () {
      return _sliceInstanceProperty(visibleNavigationRoutes).call(visibleNavigationRoutes, 0, sliceIndex);
    }, [visibleNavigationRoutes, sliceIndex]),
    routesForMoreButton = useMemo(function () {
      var _context,
        stylesForMoreButtonRoute = {
          className: cx(navBarStyles.leftNavBarItem, navBarStyles.moreMenuContentItem),
          activeClassName: cx(navBarStyles.activeLeftNavBarItem)
        };
      return _mapInstanceProperty(_context = _sliceInstanceProperty(visibleNavigationRoutes).call(visibleNavigationRoutes, sliceIndex)).call(_context, function (route) {
        var _route$routes;
        return _objectSpread(_objectSpread(_objectSpread({}, route), stylesForMoreButtonRoute), {}, {
          routes: null === (_route$routes = route.routes) || void 0 === _route$routes ? void 0 : _mapInstanceProperty(_route$routes).call(_route$routes, function (subRoute) {
            return _objectSpread(_objectSpread({}, subRoute), stylesForMoreButtonRoute);
          })
        });
      });
    }, [visibleNavigationRoutes, sliceIndex]),
    latestFontLoadUpdate = useObserveThemeFontsLoading(containerRef);
  return useLayoutEffect(function () {
    var changeStatus = debounce(function () {
      setSliceIndex(navigationRoutes.length), setStatus("recalculate");
    }, 200);
    return window.addEventListener("resize", changeStatus), function () {
      changeStatus.cancel(), window.removeEventListener("resize", changeStatus);
    };
  }, [navigationRoutes, visibleNavigationRoutes, containerRef, latestFontLoadUpdate]), useLayoutEffect(function () {
    var wrapper = containerRef.current,
      allNodes = _toConsumableArray(wrapper.childNodes),
      menuNodes = _sliceInstanceProperty(allNodes).call(allNodes, 0, allNodes.length - 1),
      _allNodes$slice = _sliceInstanceProperty(allNodes).call(allNodes, -1),
      moreNode = _slicedToArray(_allNodes$slice, 1)[0],
      spaceAvailable = wrapper.offsetWidth,
      shouldTrim = moreNode.offsetLeft > spaceAvailable,
      moreNodeWidth = moreNode.offsetWidth;
    "recalculate" === status && shouldTrim && setSliceIndex(function (spaceAvailable, nodes) {
      var _step,
        sliceAt = -1,
        sum = 0,
        _iterator = _createForOfIteratorHelper(_entriesInstanceProperty(nodes).call(nodes));
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _ref = _step.value,
            _ref2 = _slicedToArray(_ref, 2),
            index = _ref2[0],
            node = _ref2[1];
          if (sum + node.offsetWidth >= spaceAvailable) break;
          sum += node.offsetWidth, sliceAt = index;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return sliceAt + 1;
    }(spaceAvailable - moreNodeWidth, menuNodes)), setStatus("ready");
  }, [visibleNavigationRoutes, containerRef, latestFontLoadUpdate, status]), [routesForSubHeader, routesForMoreButton];
};
var subApplicationHeaderPropTypes = {
  className: PropTypes.string,
  navigationRoutes: routesShape,
  contextSwitcher: contextSwitcherShape,
  renderContextSwitcher: PropTypes.func,
  renderCustomComponent: PropTypes.func
};
export var SubApplicationHeader = function (_ref3) {
  var _context2,
    routes,
    className = _ref3.className,
    navigationRoutes = _ref3.navigationRoutes,
    contextSwitcher = _ref3.contextSwitcher,
    CustomComponent = _ref3.renderCustomComponent,
    renderContextSwitcher = _ref3.renderContextSwitcher,
    classes = cx(styles.subApplicationHeader, className),
    translator = useTranslator(),
    breakpoint = useBreakpoint().breakpoint,
    subHeaderRef = useRef(),
    _useRoutesForNavigati = useRoutesForNavigation(navigationRoutes, subHeaderRef),
    _useRoutesForNavigati2 = _slicedToArray(_useRoutesForNavigati, 2),
    routesForSubHeader = _useRoutesForNavigati2[0],
    routesForMoreButton = _useRoutesForNavigati2[1],
    navRoutes = _mapInstanceProperty(routes = routesForSubHeader || navigationRoutes).call(routes, function (route) {
      var _route$routes2, _route$navLink;
      return merge({}, route, {
        navLink: {
          className: cx(styles.navItem, _defineProperty({}, styles.navigationDropDown, (null === (_route$routes2 = route.routes) || void 0 === _route$routes2 ? void 0 : _route$routes2.length) > 0), null === (_route$navLink = route.navLink) || void 0 === _route$navLink ? void 0 : _route$navLink.className),
          activeClassName: styles.activeNavItem
        }
      });
    }),
    customComponent = CustomComponent && React.createElement("div", {
      className: styles.customComponentWrapper
    }, React.createElement(CustomComponent, null)),
    isDesktop = "desktop" === breakpoint;
  return React.createElement("nav", {
    "aria-label": translator(messages.subHeaderNavTitle),
    className: classes
  }, React.createElement(TopNavigation, {
    wrap: !0,
    contextSwitcher: contextSwitcher,
    renderContextSwitcher: renderContextSwitcher,
    navBarRef: subHeaderRef,
    className: styles.subApplicationNavigation,
    routes: _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(navRoutes), [createMoreButton({
      routes: routesForMoreButton
    })])
  }), isDesktop && customComponent);
};
SubApplicationHeader.defaultProps = {
  navigationRoutes: []
}, SubApplicationHeader.propTypes = subApplicationHeaderPropTypes, SubApplicationHeader.__docgenInfo = {
  description: "SubApplicationHeader is subheader for the default header for all Guidewire applications.\nIt contains navigation.\n\n@type {React.FC<PropTypes.InferProps<typeof subApplicationHeaderPropTypes>>}\n@metadataType container\n@param props",
  methods: [],
  displayName: "SubApplicationHeader",
  props: {
    navigationRoutes: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "routesShape"
      },
      required: !1,
      description: "Display a nav bar defined by the routing metadata object.\nThe className and activeClassName in the NavLink in each element are overwritten."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    contextSwitcher: {
      type: {
        name: "custom",
        raw: "contextSwitcherShape"
      },
      required: !1,
      description: "The context switcher object"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    },
    renderCustomComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom component or function to render custom component, placed right after navigation menu bar"
    }
  }
}, SubApplicationHeader.__docgenInfo = {
  componentName: "SubApplicationHeader",
  packageName: "@jutro/router",
  description: "SubApplicationHeader is subheader for the default header for all Guidewire applications.\nIt contains navigation.",
  displayName: "SubApplicationHeader",
  methods: [],
  actualName: "SubApplicationHeader",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    navigationRoutes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: !1,
      description: "Display a nav bar defined by the routing metadata object.\nThe className and activeClassName in the NavLink in each element are overwritten.",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: !1
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: !0
          }
        }
      },
      required: !1,
      description: "The context switcher object"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    },
    renderCustomComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom component or function to render custom component, placed right after navigation menu bar"
    }
  }
};