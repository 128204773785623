import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "className", "fileName"];
import React from "react";
export var FileUploadFieldReadonly = function (_ref) {
  var id = _ref.id,
    className = _ref.className,
    fileName = _ref.fileName,
    rest = _objectWithoutProperties(_ref, _excluded);
  return React.createElement("span", _extends({
    id: id,
    className: className
  }, rest), fileName);
};
FileUploadFieldReadonly.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FileUploadFieldReadonly"
}, FileUploadFieldReadonly.__docgenInfo = {
  componentName: "FileUploadFieldReadonly",
  packageName: "@jutro/components",
  description: "",
  displayName: "FileUploadFieldReadonly",
  methods: [],
  actualName: "FileUploadFieldReadonly"
};