import { useCallback, useMemo, useContext } from "react";
import { BreakpointTrackerContext } from "./BreakpointTracker";
import { extendWithBreakpointValues } from "./breakpoint.utils";
export var useBreakpoint = function (props) {
  var breakpoint = useContext(BreakpointTrackerContext),
    applyBreakpoint = useCallback(function (componentProps) {
      return extendWithBreakpointValues(componentProps, breakpoint);
    }, [breakpoint]);
  return useMemo(function () {
    return {
      breakpointProps: props && extendWithBreakpointValues(props, breakpoint),
      applyBreakpoint: applyBreakpoint,
      breakpoint: breakpoint
    };
  }, [props, breakpoint, applyBreakpoint]);
};