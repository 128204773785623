import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { getSamplePhoneNumber, getPrefixForCountry } from "@jutro/locale";
import { intlMessageShape, validationMessageShape } from "@jutro/prop-types";
import { mappingDelta } from "@jutro/platform";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { InputMaskField } from "../InputMaskField/InputMaskField";
import { FieldIcon } from "../FieldComponent/FieldIcon";
import styles from "./PhoneNumberField.module.css";
import { PhoneNumberFieldValidationImplementation } from "./PhoneNumberFieldValidationImplementation";
import { OldPhoneNumberFieldValidationImplementation } from "./OldPhoneNumberFieldValidationImplementation";
var phoneMaskFormatChars = {
  9: "[0-9]"
};
export var PhoneNumberField = function (_PureComponent) {
  _inherits(PhoneNumberField, PureComponent);
  var _super = _createSuper(PhoneNumberField);
  function PhoneNumberField(props) {
    var _this;
    _classCallCheck(this, PhoneNumberField), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "state", {
      phoneMask: ""
    }), _defineProperty(_assertThisInitialized(_this), "getValidationMessages", function () {
      return _this.validationImplementation.getValidationMessages();
    });
    var ValidationImplementation = props.registerValidation ? new PhoneNumberFieldValidationImplementation() : new OldPhoneNumberFieldValidationImplementation();
    return _this.validationImplementation = ValidationImplementation.bind(_assertThisInitialized(_this)), _this;
  }
  return _createClass(PhoneNumberField, [{
    key: "renderInput",
    value: function () {
      return React.createElement(InputMaskField, _extends({}, this.props, {
        mask: this.state.phoneMask,
        formatChars: phoneMaskFormatChars,
        inputType: "tel",
        className: styles.phoneNumber,
        onGetValidationMessages: this.getValidationMessages,
        insertMode: !0
      }));
    }
  }, {
    key: "render",
    value: function () {
      return this.renderInput();
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function (nextProps, prevState) {
      return mappingDelta({
        phoneMask: PhoneNumberField.getMask(nextProps)
      }, prevState);
    }
  }, {
    key: "getMask",
    value: function (_ref) {
      var countryCode = _ref.countryCode,
        phoneNumberType = _ref.phoneNumberType,
        withCountryPrefix = _ref.withCountryPrefix,
        phoneMask = "",
        samplePhoneNumber = getSamplePhoneNumber(countryCode, phoneNumberType);
      if (samplePhoneNumber && (phoneMask = samplePhoneNumber.replace(/\d/g, "9"), withCountryPrefix)) {
        var prefix = getPrefixForCountry(countryCode);
        if (0 !== prefix) {
          var _context,
            escapedPrefix = prefix.toString().replace(/9/g, "\\9");
          phoneMask = _concatInstanceProperty(_context = "+".concat(escapedPrefix, " ")).call(_context, phoneMask);
        }
      }
      return phoneMask;
    }
  }]), PhoneNumberField;
}();
_defineProperty(PhoneNumberField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  maskChar: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  countryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, validationMessageShape]))]),
  phoneNumberType: PropTypes.number,
  withCountryPrefix: PropTypes.bool,
  messageProps: PropTypes.shape({
    invalidPhone: intlMessageShape
  }),
  value: PropTypes.string
}, FieldIcon.propTypes)), _defineProperty(PhoneNumberField, "defaultProps", {
  countryCode: "US",
  maskChar: "_",
  phoneNumberType: 0,
  value: ""
}), PhoneNumberField.__docgenInfo = {
  description: "PhoneNumberField is a 'field' component that displays a label, control and message. It can be used to render\na phone number mask on a HTML5 <input> element with type 'tel'.\n@typedef {typeof PhoneNumberField.propTypes} PhoneNumberFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<PhoneNumberFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "getMask",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ countryCode, phoneNumberType, withCountryPrefix }",
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderInput",
    docblock: "Render control for this component.\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "PhoneNumberField",
  props: {
    countryCode: {
      defaultValue: {
        value: "'US'",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "A country code to automatically set the input mask. The codes are based on the two letter ISO 3166-1 codes.\nExample codes: US, PL, GB, FR, IE"
    },
    maskChar: {
      defaultValue: {
        value: "'_'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The character used in the mask, '_' is used by default.\nNOTE: The mask char should not be the same as one of possible input characters because this can generate unintended incorrect values."
    },
    phoneNumberType: {
      defaultValue: {
        value: "0",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "A phone number type used when generating the countryCode based mask. The following are possible values:\n        - FIXED_LINE = 0,\n        - MOBILE = 1,\n        - FIXED_LINE_OR_MOBILE = 2,\n        - TOLL_FREE = 3,\n        - PREMIUM_RATE = 4,\n        - SHARED_COST = 5,\n        - VOIP = 6,\n        - PERSONAL_NUMBER = 7,\n        - PAGER = 8,\n        - UAN = 9,\n        - VOICEMAIL = 10,"
    },
    value: {
      defaultValue: {
        value: "''",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Value to display in the component"
    },
    alwaysShowMask: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the mask is always visible"
    },
    withCountryPrefix: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, the mask will be prefixed with the country code. Works only\nin combination with `countryCode`"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          invalidPhone: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Validation message for invalid phone number",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
}, PhoneNumberField.__docgenInfo = {
  componentName: "PhoneNumberField",
  packageName: "@jutro/components",
  description: "PhoneNumberField is a 'field' component that displays a label, control and message. It can be used to render\na phone number mask on a HTML5 <input> element with type 'tel'.",
  displayName: "PhoneNumberField",
  methods: [{
    name: "getMask",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ countryCode, phoneNumberType, withCountryPrefix }",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderInput",
    docblock: "Render control for this component.\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "PhoneNumberField",
  metadataType: "field",
  props: {
    maskChar: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The character used in the mask, '_' is used by default.\nNOTE: The mask char should not be the same as one of possible input characters because this can generate unintended incorrect values.",
      defaultValue: {
        value: "'_'",
        computed: !1
      }
    },
    alwaysShowMask: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the mask is always visible"
    },
    countryCode: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "A country code to automatically set the input mask. The codes are based on the two letter ISO 3166-1 codes.\nExample codes: US, PL, GB, FR, IE",
      defaultValue: {
        value: "'US'",
        computed: !1
      }
    },
    phoneNumberType: {
      type: {
        name: "number"
      },
      required: !1,
      description: "A phone number type used when generating the countryCode based mask. The following are possible values:\n        - FIXED_LINE = 0,\n        - MOBILE = 1,\n        - FIXED_LINE_OR_MOBILE = 2,\n        - TOLL_FREE = 3,\n        - PREMIUM_RATE = 4,\n        - SHARED_COST = 5,\n        - VOIP = 6,\n        - PERSONAL_NUMBER = 7,\n        - PAGER = 8,\n        - UAN = 9,\n        - VOICEMAIL = 10,",
      defaultValue: {
        value: "0",
        computed: !1
      }
    },
    withCountryPrefix: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to true, the mask will be prefixed with the country code. Works only\nin combination with `countryCode`"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          invalidPhone: {
            name: "union",
            description: "Validation message for invalid phone number",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    value: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Value to display in the component",
      defaultValue: {
        value: "''",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
};