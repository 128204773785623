import { defineMessages } from "react-intl";
export var messages = defineMessages({
  emptyUploadAreaMessage: {
    id: "jutro-components.widgets.input.FileUploadField.emptyUploadAreaMessage",
    defaultMessage: "Drag and drop a file here"
  },
  uploadFilesMessage: {
    id: "jutro-components.widgets.input.FileUploadField.uploadFilesMessage",
    defaultMessage: "Upload file"
  },
  uploadedFilesMessage: {
    id: "jutro-components.widgets.input.FileUploadField.uploadedFilesMessage",
    defaultMessage: "Uploaded file: "
  },
  removeFileMessage: {
    id: "jutro-components.widgets.input.FileUploadField.removeFileMessage",
    defaultMessage: "Remove file"
  },
  maxLengthMessage: {
    id: "jutro-components.widgets.inputs.FileUploadField.maxLength",
    defaultMessage: "Filename cannot exceed {nameLength} characters"
  },
  placeholder: {
    id: "jutro-components.widgets.inputs.FileUploadField.placeholder",
    defaultMessage: "No file chosen"
  },
  maxFileSizeKBMessage: {
    id: "jutro-components.widgets.inputs.FileUploadField.maxFileSizeKB",
    defaultMessage: "File cannot exceed {fileSizeKB}KB in size"
  },
  incorrectFileTypeMessage: {
    id: "jutro-components.widgets.inputs.FileUploadField.incorrectFileType",
    defaultMessage: "File type not supported"
  }
});