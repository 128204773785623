import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useTranslator } from "@jutro/locale";
import { useAccessibility } from "../state/accessibility/useAccessibility";
import { getA11yTrGroupProps, getA11yTrProps, getA11yTableProps, getA11yTheadThPropsSortableHeader, getA11yTheadThPropsHeader, getA11yTdProps } from "./accessibilityProps.js";
var getInitialColumn = function (selectionType, isExpandable) {
  var initialColumn = 0;
  return "none" === selectionType && isExpandable || "single" === selectionType && isExpandable ? initialColumn = 1 : "single" === selectionType && isExpandable && (initialColumn = 2), initialColumn;
};
export var useAccessibilityProps = function (_ref) {
  var selectionType = _ref.selectionType,
    isExpandable = _ref.isExpandable,
    tableId = _ref.tableId,
    sorted = _ref.sorted,
    tableLabel = _ref.tableLabel,
    translator = useTranslator(),
    _useAccessibility = useAccessibility({
      focused: {
        row: 0,
        column: getInitialColumn(selectionType, isExpandable),
        columnId: void 0
      }
    }),
    _useAccessibility2 = _slicedToArray(_useAccessibility, 2),
    focused = _useAccessibility2[0].focused,
    setFocused = _useAccessibility2[1].setFocused;
  return {
    getA11yTrGroupProps: getA11yTrGroupProps,
    getA11yTrProps: getA11yTrProps,
    getA11yTableProps: getA11yTableProps(translator, sorted, tableLabel),
    getA11yTheadThPropsSortableHeader: getA11yTheadThPropsSortableHeader(tableId, sorted, focused, setFocused, translator),
    getA11yTheadThPropsHeader: getA11yTheadThPropsHeader(tableId, focused, setFocused, translator),
    getA11yTdProps: getA11yTdProps(tableId, focused, setFocused)
  };
};