import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isFunction from "lodash/isFunction";
import styles from "./FieldIcon.module.css";
import { Icon } from "../../Icon/Icon";
var defaultIconRender = function (icon, positionStyles, displayStyles) {
  return React.createElement(Icon, {
    icon: icon,
    className: cx(positionStyles, displayStyles),
    size: "large"
  });
};
export var fieldIconPropTypes = {
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};
export var FieldIcon = function (props) {
  var field = props.children,
    icon = props.icon,
    iconPosition = props.iconPosition,
    disabled = props.disabled,
    className = props.className,
    renderIcon = isFunction(props.renderIcon) ? props.renderIcon : defaultIconRender;
  if (!icon) return field;
  var isRight = "right" === iconPosition,
    isLeft = !isRight,
    wrapperClasses = cx(styles.fieldWithIcon, className),
    iconClasses = cx({
      disabled: disabled
    }, styles.icon, _defineProperty({}, styles.iconWrapper, !renderIcon)),
    iconElement = renderIcon(icon, styles.iconWrapper, iconClasses);
  return React.createElement("div", {
    className: wrapperClasses
  }, isLeft && iconElement, field, isRight && iconElement);
};
FieldIcon.propTypes = fieldIconPropTypes, FieldIcon.__docgenInfo = {
  description: "FieldIcon component definition\n\n@type {React.FC<PropTypes.InferProps<typeof fieldIconPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FieldIcon",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon definition"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase that indicates where the icon should be placed relative to the text"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Indicates if the icon should have the disabled class"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Child elements of the icon component"
    }
  }
}, defaultIconRender.__docgenInfo = {
  componentName: "defaultIconRender",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultIconRender",
  methods: [],
  actualName: "defaultIconRender"
}, FieldIcon.__docgenInfo = {
  componentName: "FieldIcon",
  packageName: "@jutro/components",
  description: "FieldIcon component definition",
  displayName: "FieldIcon",
  methods: [],
  actualName: "FieldIcon",
  metadataType: "element",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon definition"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase that indicates where the icon should be placed relative to the text"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Indicates if the icon should have the disabled class"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Child elements of the icon component"
    }
  }
};