import { defineMessages } from "react-intl";
export var messages = defineMessages({
  title: {
    id: "jutro-components.widgets.PopoverContainer.title",
    defaultMessage: "Popover Container"
  },
  ariaTitle: {
    id: "jutro-components.widgets.PopoverContainer.ariaTitle",
    defaultMessage: "{title} popover"
  }
});