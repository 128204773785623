import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { useId } from "@jutro/platform";
import styles from "./ProgressPhase.module.css";
import { getKeyPressHandler } from "../../../accessibility/getKeyPressHandler";
import { PhaseProgressLabel } from "./PhaseProgressLabel";
import { messages } from "../Progressindicator.messages";
export var PhaseProgress = function (_ref) {
  var _cx,
    title = _ref.title,
    idProp = _ref.id,
    _ref$steps = _ref.steps,
    steps = void 0 === _ref$steps ? [] : _ref$steps,
    icon = _ref.icon,
    onClick = _ref.onClick,
    className = _ref.className,
    showLabel = _ref.showLabel,
    isError = _ref.isError,
    translator = useTranslator(),
    id = useId({
      default: idProp
    }),
    active = steps.some(function (step) {
      return step.active;
    }),
    activeStepIndex = _findIndexInstanceProperty(steps).call(steps, function (step) {
      return step.active;
    }),
    finalStep = activeStepIndex === steps.length - 1,
    complete = steps.every(function (step) {
      return step.visited;
    }),
    disabled = !(active || complete),
    progress = finalStep || complete ? 100 : 100 / steps.length * (activeStepIndex + 1),
    progressPercent = "".concat(progress, "%"),
    handleClick = function (e) {
      e.preventDefault(), disabled || onClick && onClick(e);
    },
    classes = cx(styles.progressPhase, (_defineProperty(_cx = {}, styles.active, active), _defineProperty(_cx, styles.complete, complete), _defineProperty(_cx, styles.disabled, disabled), _defineProperty(_cx, styles.error, isError), _cx), className),
    labelId = "".concat(id, "-label"),
    progressLabel = 100 === progress ? translator(messages.completed) : translator(messages.inProgress, {
      progress: progress
    });
  return React.createElement("div", {
    id: id,
    role: "menuitem",
    className: classes,
    onClick: handleClick,
    onKeyPress: getKeyPressHandler(function (e) {
      disabled || handleClick(e);
    }),
    "aria-label": translator(messages.ariaLabel, {
      progressTitle: translator(title),
      progressLabel: progressLabel
    }),
    tabIndex: disabled ? -1 : 0,
    "aria-current": active ? "step" : void 0
  }, React.createElement("div", {
    role: "progressbar",
    "aria-valuetext": translator(title),
    "aria-valuenow": progress,
    "aria-valuemin": 0,
    "aria-valuemax": 100,
    className: styles.bar,
    "aria-labelledby": showLabel ? labelId : void 0,
    title: showLabel ? void 0 : translator(title)
  }, React.createElement("div", {
    className: styles.indicator,
    style: {
      width: progressPercent
    }
  })), showLabel && React.createElement(PhaseProgressLabel, {
    id: labelId,
    title: title,
    stepTitle: active ? steps[activeStepIndex].title : void 0,
    icon: icon,
    complete: complete,
    active: active,
    isError: isError
  }));
};
export var phaseProgressPropTypes = {
  id: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: intlMessageShape.isRequired,
    visited: PropTypes.bool,
    active: PropTypes.bool
  }).isRequired).isRequired,
  title: intlMessageShape.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isError: PropTypes.bool,
  showLabel: PropTypes.bool
};
PhaseProgress.propTypes = phaseProgressPropTypes, PhaseProgress.__docgenInfo = {
  description: "PhaseProgress\n@type {React.FC<PropTypes.InferProps<typeof phaseProgressPropTypes>>}",
  methods: [],
  displayName: "PhaseProgress",
  props: {
    steps: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            title: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: !0
            },
            visited: {
              name: "bool",
              required: !1
            },
            active: {
              name: "bool",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Steps"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component's identifier. Should be unique on a per-page basis."
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Text part for labeling the phase"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of the icon to display as part of the label"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback function triggered when the phase is clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component"
    },
    isError: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag determining if phase should be shown as an error"
    },
    showLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag determining if phase label should be displayed"
    }
  }
}, PhaseProgress.__docgenInfo = {
  componentName: "PhaseProgress",
  packageName: "@jutro/components",
  description: "PhaseProgress",
  displayName: "PhaseProgress",
  methods: [],
  actualName: "PhaseProgress",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component's identifier. Should be unique on a per-page basis."
    },
    steps: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            title: {
              name: "union",
              required: !0,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            visited: {
              name: "bool",
              required: !1
            },
            active: {
              name: "bool",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Steps",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Text part for labeling the phase"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of the icon to display as part of the label"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback function triggered when the phase is clicked"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component"
    },
    isError: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag determining if phase should be shown as an error"
    },
    showLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag determining if phase label should be displayed"
    }
  }
};