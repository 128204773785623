import React from "react";
import { info } from "@jutro/logger";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { getMessageProp } from "@jutro/platform";
import PropTypes from "prop-types";
import styles from "./SkipNav.module.css";
import { getKeyPressHandler } from "../getKeyPressHandler";
import { messages } from "./SkipNav.messages";
var messagePropsPropType = {
    skipNavMessage: intlMessageShape
  },
  skipNavPropTypes = {
    messageProps: PropTypes.shape(messagePropsPropType),
    appName: intlMessageShape
  },
  skipNav = function () {
    info("Skipping the nav");
    var header = document.querySelector("header"),
      headerPosition = header ? getComputedStyle(header).getPropertyValue("position") : null,
      mainContent = document.querySelector("main");
    mainContent && ("sticky" !== headerPosition && mainContent.scrollIntoView(), mainContent.focus({
      preventScroll: !0
    }));
  },
  handleKeyboardSelection = getKeyPressHandler(skipNav);
export var SkipNav = function (_ref) {
  var messageProps = _ref.messageProps,
    appName = _ref.appName,
    translator = useTranslator(),
    skipNavMessage = getMessageProp("skipNavMessage", messageProps, messages),
    skipNavMessageWithAppName = getMessageProp("skipNavMessageWithAppName", messageProps, messages),
    ariaLabel = appName ? translator(skipNavMessageWithAppName, {
      appName: translator(appName)
    }) : translator(skipNavMessage);
  return React.createElement("section", {
    "aria-label": ariaLabel
  }, React.createElement("span", {
    className: styles.skipNav,
    tabIndex: 0,
    role: "link",
    onClick: skipNav,
    onKeyPress: handleKeyboardSelection
  }, ariaLabel));
};
SkipNav.propTypes = skipNavPropTypes, SkipNav.__docgenInfo = {
  description: "Component providing a link to skip the navigation menu when focused.\nAssumes that the application uses jutro header/main/footer layout.",
  methods: [],
  displayName: "SkipNav",
  props: {
    messageProps: {
      type: {
        name: "shape",
        value: {
          skipNavMessage: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message for skip navigation"
    },
    appName: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "the name of the app whose navigation to skip"
    }
  }
}, SkipNav.__docgenInfo = {
  componentName: "SkipNav",
  packageName: "@jutro/components",
  description: "Component providing a link to skip the navigation menu when focused.\nAssumes that the application uses jutro header/main/footer layout.",
  displayName: "SkipNav",
  methods: [],
  actualName: "SkipNav",
  props: {
    messageProps: {
      type: {
        name: "shape",
        value: {
          skipNavMessage: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message for skip navigation"
    },
    appName: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "the name of the app whose navigation to skip"
    }
  }
};