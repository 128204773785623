import React from "react";
import { ModalNext, ModalHeader } from "@jutro/components";
import styles from "./AppSwitcher.module.css";
import { messages } from "./AppSwitcher.messages";
export var AppSwitcherModal = function (_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    title = _ref.title,
    renderAppSwitcher = _ref.renderAppSwitcher;
  return React.createElement("div", {
    id: "appSwitcherModal"
  }, React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onResolve,
    className: styles.appSwitcherModal
  }, React.createElement(ModalHeader, {
    title: title,
    onClose: onResolve,
    ariaLabel: messages.closeModalAriaLabel
  }), renderAppSwitcher && renderAppSwitcher(onResolve)));
};
AppSwitcherModal.__docgenInfo = {
  description: "Modal wrapper for AppSwitcher",
  methods: [],
  displayName: "AppSwitcherModal"
}, AppSwitcherModal.__docgenInfo = {
  componentName: "AppSwitcherModal",
  packageName: "@jutro/router",
  description: "Modal wrapper for AppSwitcher",
  displayName: "AppSwitcherModal",
  methods: [],
  actualName: "AppSwitcherModal"
};