import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["onChange", "className", "label"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import cx from "classnames";
import { generateComponentId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { InputField } from "../inputs/InputField/InputField";
import { IconButton } from "../IconButton/IconButton_DEPRECATED";
import { messages } from "./TreeView.messages";
import styles from "./TreeViewFilter.module.css";
export var TreeViewFilter = function (_ref) {
  var onChange = _ref.onChange,
    className = _ref.className,
    label = _ref.label,
    props = _objectWithoutProperties(_ref, _excluded),
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    query = _useState2[0],
    setQuery = _useState2[1],
    translator = useTranslator(),
    inputRef = useRef(null),
    filterClasses = cx(_filterInstanceProperty(styles), _defineProperty({}, styles.hideSearchIcon, Boolean(query)), className),
    onValueChange = useCallback(function (value) {
      setQuery(value), onChange && onChange(value);
    }, [setQuery, onChange]),
    clearFilter = useCallback(function () {
      var _inputRef$current, _inputRef$current$foc;
      onValueChange(""), null === (_inputRef$current = inputRef.current) || void 0 === _inputRef$current || null === (_inputRef$current$foc = _inputRef$current.focus) || void 0 === _inputRef$current$foc || _inputRef$current$foc.call(_inputRef$current);
    }, [onValueChange, inputRef]),
    clearButton = React.createElement(IconButton, {
      className: styles.clearButton,
      icon: "gw-cancel",
      onClick: clearFilter,
      ariaLabel: translator(messages.clearFilter),
      noDeprecationWarnings: ["IconButton"]
    });
  return React.createElement("div", {
    className: styles.wrapper
  }, React.createElement(InputField, _extends({
    ref: inputRef,
    id: generateComponentId("TreeViewFilter"),
    label: label || messages.filterLabel,
    hideLabel: !0,
    placeholder: messages.filterPlaceholder,
    icon: "gw-search",
    iconPosition: "right",
    className: filterClasses
  }, props, {
    value: query,
    onValueChange: onValueChange
  })), query && clearButton);
};
export var treeViewFilterProps = omit(InputField.propTypes, ["id"]);
TreeViewFilter.propTypes = _objectSpread(_objectSpread({}, treeViewFilterProps), {}, {
  onChange: PropTypes.func
}), TreeViewFilter.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TreeViewFilter",
  props: {
    onChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash"]
}, TreeViewFilter.__docgenInfo = {
  componentName: "TreeViewFilter",
  packageName: "@jutro/components",
  description: "",
  displayName: "TreeViewFilter",
  methods: [],
  actualName: "TreeViewFilter",
  props: {
    onChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    }
  },
  composes: ["lodash"]
};