import { warning } from "@jutro/logger";
export default function loadGtag(measurementId) {
  if (!measurementId) return warning("To enable gtag.js provide measurement id"), null;
  var scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript", scriptElement.async = !0, scriptElement.src = "https://www.googletagmanager.com/gtag/js?id=".concat(measurementId);
  var headElement = document.getElementsByTagName("head")[0];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  return headElement.insertBefore(scriptElement, headElement.firstChild), window.dataLayer = window.dataLayer || [], gtag("js", new Date()), gtag("config", measurementId), gtag;
}