import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
var Options = function () {
  function Options(options) {
    _classCallCheck(this, Options), this.options = options;
  }
  return _createClass(Options, [{
    key: "onOption",
    value: function (optionName, callback) {
      var option = this.options[optionName];
      void 0 !== option && callback(option);
    }
  }]), Options;
}();
export { Options as default };