export var tryToCastToBoolean = function (value) {
  return "true" === value || "false" !== value && value;
};
export var isAvailableValuesCodeNameObject = function (option) {
  return "code" in option;
};
export var findAvailableValue = function (targetValue) {
  return function (option) {
    var _option$code, _option$id;
    return isAvailableValuesCodeNameObject(option) ? (null === (_option$code = option.code) || void 0 === _option$code ? void 0 : _option$code.toString()) === targetValue : (null === (_option$id = option.id) || void 0 === _option$id ? void 0 : _option$id.toString()) === targetValue;
  };
};