import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useRef, useEffect, useCallback } from "react";
export function useKeyPress(ref, targetKeys, callback) {
  var memoizedKeys = function (array) {
      var ref = useRef(array);
      array1 = array, array2 = ref.current, array1.length === array2.length && array1.every(function (item, index) {
        return array2[index] === item;
      }) || (ref.current = array);
      var array1, array2;
      return ref.current;
    }(targetKeys),
    memoizedCallback = useCallback(function (event) {
      memoizedKeys.some(function (key) {
        var _context;
        return _includesInstanceProperty(_context = [event.key, event.code]).call(_context, key);
      }) && callback(event, "keydown" === event.type);
    }, [memoizedKeys, callback]);
  useEffect(function () {
    var node = ref.current;
    if (node) return node.addEventListener("keydown", memoizedCallback), node.addEventListener("keyup", memoizedCallback), function () {
      node.removeEventListener("keydown", memoizedCallback), node.removeEventListener("keyup", memoizedCallback);
    };
  }, [ref, memoizedCallback]);
}