import { defineMessages } from "react-intl";
export var messages = defineMessages({
  previousYear: {
    id: "jutro-components.MonthYearField.previousYear",
    defaultMessage: "Previous year"
  },
  nextYear: {
    id: "jutro-components.MonthYearField.nextYear",
    defaultMessage: "Next year"
  },
  currentYear: {
    id: "jutro-components.MonthYearField.currentYear",
    defaultMessage: "Current year"
  }
});