import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import hoistStatics from "hoist-non-react-statics";
export var createHOC = function (config) {
  var _context,
    ComponentToWrap = config.component,
    Wrapper = config.wrapper,
    displayName = config.displayName,
    WrappedComponent = Wrapper(ComponentToWrap, function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({}, config.wrapperParams));
  return WrappedComponent.displayName = displayName || _concatInstanceProperty(_context = "".concat(Wrapper.name || "Wrapper", "(")).call(_context, ComponentToWrap.displayName || ComponentToWrap.name, ")"), WrappedComponent.WrappedComponent = ComponentToWrap, ComponentToWrap.propTypes && (WrappedComponent.propTypes = ComponentToWrap.propTypes), ComponentToWrap.defaultProps && (WrappedComponent.defaultProps = ComponentToWrap.defaultProps), hoistStatics(WrappedComponent, ComponentToWrap);
};