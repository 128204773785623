import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
var _excluded = ["id", "renderTrigger", "isOpen", "dropUp", "alignRight", "className", "menuClassName", "children", "focused", "openMenuId", "onMenuOpen", "onMenuClose", "keepContext", "popperOptions", "triggerAriaLabel", "autoFocus"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isArray from "lodash/isArray";
import { uniqueInnerId, generateComponentId } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { Popper } from "../Popper/Popper";
import styles from "./DropdownMenu.module.css";
import { useDropdownMenuKeyboardNavigation } from "./hooks/useDropdownMenuKeyboardNavigation";
var getNodeText = function getNodeText(node) {
    return "string" == typeof node || "number" == typeof node ? node : node instanceof Array ? _mapInstanceProperty(node).call(node, getNodeText).join("") : "object" === _typeof(node) && node && React.isValidElement(node) ? getNodeText(null === (_node$props = node.props) || void 0 === _node$props ? void 0 : _node$props.children) : void 0;
    var _node$props;
  },
  DropDownMenuPropTypes = {
    id: PropTypes.string.isRequired,
    renderTrigger: PropTypes.func,
    isOpen: PropTypes.bool,
    dropUp: PropTypes.bool,
    alignRight: PropTypes.bool,
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    children: PropTypes.node,
    focused: PropTypes.bool,
    openMenuId: PropTypes.string,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    keepContext: PropTypes.bool,
    popperOptions: PropTypes.object,
    triggerAriaLabel: intlMessageShape,
    autoFocus: PropTypes.bool
  };
export var DropdownMenu = function (_ref) {
  var isRight,
    _context,
    id = _ref.id,
    renderTrigger = _ref.renderTrigger,
    isOpen = _ref.isOpen,
    dropUp = _ref.dropUp,
    alignRight = _ref.alignRight,
    className = _ref.className,
    menuClassName = _ref.menuClassName,
    children = _ref.children,
    focused = _ref.focused,
    onMenuOpen = (_ref.openMenuId, _ref.onMenuOpen),
    onMenuClose = _ref.onMenuClose,
    _ref$keepContext = _ref.keepContext,
    keepContext = void 0 !== _ref$keepContext && _ref$keepContext,
    popperOptions = _ref.popperOptions,
    triggerAriaLabel = _ref.triggerAriaLabel,
    _ref$autoFocus = _ref.autoFocus,
    autoFocus = void 0 === _ref$autoFocus || _ref$autoFocus,
    rest = _objectWithoutProperties(_ref, _excluded),
    isInitialMount = useRef(!0),
    _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    triggerElement = _useState2[0],
    setTriggerElement = _useState2[1],
    _useState3 = useState(null != isOpen && isOpen),
    _useState4 = _slicedToArray(_useState3, 2),
    isMenuOpen = _useState4[0],
    setIsMenuOpen = _useState4[1],
    _useState5 = useState(),
    _useState6 = _slicedToArray(_useState5, 2),
    selectedItemText = _useState6[0],
    setSelectedItemText = _useState6[1],
    _useDropdownMenuKeybo = useDropdownMenuKeyboardNavigation(isMenuOpen, autoFocus),
    dropdownRef = _useDropdownMenuKeybo.dropdownRef,
    onKeyDown = _useDropdownMenuKeybo.onKeyDown,
    onHover = _useDropdownMenuKeybo.onHover,
    translator = useTranslator(),
    placement = (isRight = !!alignRight, _concatInstanceProperty(_context = "".concat(!!dropUp ? "top" : "bottom", "-")).call(_context, isRight ? "end" : "start")),
    componentClasses = cx(styles.dropdownMenuPosition, className, {
      opened: isMenuOpen
    }),
    menuClasses = cx(styles.menu, menuClassName);
  useEffect(function () {
    var _triggerElement$focus;
    focused && (null == triggerElement || null === (_triggerElement$focus = triggerElement.focus) || void 0 === _triggerElement$focus || _triggerElement$focus.call(triggerElement));
  }, [triggerElement, focused]), useEffect(function () {
    isInitialMount.current ? isInitialMount.current = !1 : isMenuOpen ? null == onMenuOpen || onMenuOpen() : null == onMenuClose || onMenuClose();
  }, [isMenuOpen, onMenuClose, onMenuOpen]);
  var _uniqueInnerId = uniqueInnerId(id, "triggerId", "menuId"),
    triggerId = _uniqueInnerId.triggerId,
    menuId = _uniqueInnerId.menuId,
    toggleMenu = useCallback(function (open) {
      setIsMenuOpen("boolean" == typeof open ? open : !isMenuOpen);
    }, [isMenuOpen]),
    onDropdownTriggerKeyDown = useCallback(function (event) {
      isMenuOpen && onKeyDown(event);
    }, [isMenuOpen, onKeyDown]),
    dropdownTrigger = useMemo(function () {
      return renderTrigger && renderTrigger(_objectSpread(_objectSpread({
        id: triggerId,
        menuId: menuId,
        isOpen: isMenuOpen,
        ref: setTriggerElement,
        "aria-haspopup": !0,
        "aria-expanded": isMenuOpen,
        "aria-label": translator(triggerAriaLabel)
      }, keepContext && selectedItemText ? {
        title: String(selectedItemText)
      } : {}), {}, {
        onKeyDown: onDropdownTriggerKeyDown
      }), function (value) {
        return toggleMenu(value);
      });
    }, [renderTrigger, triggerId, menuId, isMenuOpen, translator, triggerAriaLabel, keepContext, selectedItemText, onDropdownTriggerKeyDown, toggleMenu]),
    onClose = useCallback(function () {
      setIsMenuOpen(!1);
    }, []),
    addChildId = useCallback(function (child) {
      var childItem = child,
        item = React.cloneElement(childItem, {
          id: generateComponentId(childItem.props.id || "item")
        });
      if (React.isValidElement(item.props.children) || isArray(item.props.children)) {
        var _context2,
          itemChildren = _mapInstanceProperty(_context2 = React.Children.toArray(item.props.children)).call(_context2, addChildId);
        item = React.cloneElement(childItem, {
          children: itemChildren
        });
      }
      return item;
    }, []),
    childrenWithId = useMemo(function () {
      var _context3;
      return _mapInstanceProperty(_context3 = React.Children.toArray(children)).call(_context3, addChildId);
    }, [children, addChildId]),
    dropdown = useMemo(function () {
      var _context5,
        mappedChildren,
        handleOnMenuItemClick = function (child) {
          var _triggerElement$focus2,
            childText = getNodeText(child);
          setSelectedItemText(childText), setIsMenuOpen(!1), null == triggerElement || null === (_triggerElement$focus2 = triggerElement.focus) || void 0 === _triggerElement$focus2 || _triggerElement$focus2.call(triggerElement);
        },
        mapChildRender = function mapChildRender(child) {
          var childItem = child,
            item = function (child) {
              return React.cloneElement(child, {
                onClick: function (event) {
                  var _child$props$onClick, _child$props;
                  handleOnMenuItemClick(child), null === (_child$props$onClick = (_child$props = child.props).onClick) || void 0 === _child$props$onClick || _child$props$onClick.call(_child$props, event);
                },
                onMouseOver: function () {
                  var _child$props$onMouseO, _child$props2;
                  onHover(child.props.id), null === (_child$props$onMouseO = (_child$props2 = child.props).onMouseOver) || void 0 === _child$props$onMouseO || _child$props$onMouseO.call(_child$props2);
                },
                onMouseMove: function () {
                  var _child$props$onMouseM, _child$props3;
                  onHover(child.props.id), null === (_child$props$onMouseM = (_child$props3 = child.props).onMouseMove) || void 0 === _child$props$onMouseM || _child$props$onMouseM.call(_child$props3);
                },
                tabIndex: -1
              });
            }(childItem);
          if ((React.isValidElement(item.props.children) || isArray(item.props.children)) && !childItem.props.onClick) {
            var _context4,
              itemChildren = _mapInstanceProperty(_context4 = React.Children.toArray(item.props.children)).call(_context4, function (subChild) {
                var subChildItem = subChild;
                return subChildItem.props.disabled ? subChild : mapChildRender(subChildItem);
              });
            item = React.cloneElement(childItem, {
              children: itemChildren
            });
          }
          return item;
        };
      return mappedChildren = _mapInstanceProperty(_context5 = React.Children.toArray(childrenWithId)).call(_context5, mapChildRender), React.createElement("div", {
        id: menuId,
        className: menuClasses,
        "aria-labelledby": triggerId,
        "aria-hidden": !isMenuOpen,
        "data-testid": "menu-panel",
        ref: dropdownRef,
        onKeyDown: onKeyDown,
        role: "menu",
        tabIndex: -1
      }, mappedChildren);
    }, [childrenWithId, dropdownRef, isMenuOpen, menuClasses, menuId, onKeyDown, onHover, triggerId, triggerElement]);
  return React.createElement("div", {
    className: componentClasses,
    id: id
  }, dropdownTrigger, React.createElement(Popper, _extends({
    isOpen: isMenuOpen,
    placement: placement,
    triggerElement: triggerElement || void 0,
    popperOptions: popperOptions,
    onClose: onClose
  }, rest), dropdown));
};
DropdownMenu.propTypes = DropDownMenuPropTypes, DropdownMenu.__docgenInfo = {
  description: "A dropdown menu where you can add your actions to each item.\n\n@metadataType container",
  methods: [],
  displayName: "DropdownMenu",
  props: {
    keepContext: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "(Optional) If true, sets the title of the selected value to be the same as the selected item text"
    },
    autoFocus: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the first menu item gets focused after opening the dropdown menu"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the menu component"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu is currently visible"
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu appears above the trigger component"
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, items are aligned to the right edge of the menu"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Override class for the dropdown menu"
    },
    menuClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Override class for the inner menu of the dropdown menu"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children wrapped by the dropdown menu component"
    },
    focused: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the root element is considered as the currently active one"
    },
    openMenuId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional id of the component instance that's supposed be to currently opened.\nMostly useful when rendering multiple instances next to each other (e.g., within a Header)"
    },
    onMenuOpen: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Optional callback that's supposed to be triggered when menu gets opened"
    },
    onMenuClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Optional callback that's supposed to be triggered when menu gets closed"
    },
    popperOptions: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Optional extra popper configuration to overwrite popper defaults"
    },
    triggerAriaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label for the trigger button"
    }
  }
}, DropdownMenu.__docgenInfo = {
  componentName: "DropdownMenu",
  packageName: "@jutro/components",
  description: "A dropdown menu where you can add your actions to each item.",
  displayName: "DropdownMenu",
  methods: [],
  actualName: "DropdownMenu",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the menu component"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu is currently visible"
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the menu appears above the trigger component"
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, items are aligned to the right edge of the menu"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Override class for the dropdown menu"
    },
    menuClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Override class for the inner menu of the dropdown menu"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children wrapped by the dropdown menu component"
    },
    focused: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the root element is considered as the currently active one"
    },
    openMenuId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional id of the component instance that's supposed be to currently opened.\nMostly useful when rendering multiple instances next to each other (e.g., within a Header)"
    },
    onMenuOpen: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Optional callback that's supposed to be triggered when menu gets opened"
    },
    onMenuClose: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Optional callback that's supposed to be triggered when menu gets closed"
    },
    keepContext: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "(Optional) If true, sets the title of the selected value to be the same as the selected item text",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    popperOptions: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Optional extra popper configuration to overwrite popper defaults"
    },
    triggerAriaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label for the trigger button"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the first menu item gets focused after opening the dropdown menu",
      defaultValue: {
        value: "true",
        computed: !1
      }
    }
  }
};