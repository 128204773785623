import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import { isEmptyValue, isNilValue } from "@jutro/data";
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING } from "@jutro/prop-types";
import { deprecateComponent } from "@jutro/platform";
import cx from "classnames";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { getOptionCode, getOptionName } from "../availableValues";
import styles from "./ImageRadioButtonField.module.css";
import { Icon } from "../../Icon/Icon";
import { createHandleRadioButtonKeyDown } from "../../../radiobuttonUtils";
export var ImageRadioButtonFieldInternal = function (_FieldComponent) {
  _inherits(ImageRadioButtonFieldInternal, FieldComponent);
  var _super = _createSuper(ImageRadioButtonFieldInternal);
  function ImageRadioButtonFieldInternal() {
    var _context, _this;
    _classCallCheck(this, ImageRadioButtonFieldInternal);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "handleRadioChange", function (evt) {
      var targetValue = evt.target.value;
      _this.handleAvailableValuesValueChange(targetValue);
    }), _defineProperty(_assertThisInitialized(_this), "getOtherOptionImageName", function (breakpointProps) {
      var _context2,
        otherOptionImage = breakpointProps.otherOptionImage,
        imageFormat = breakpointProps.imageFormat;
      return /.*\.([a-z]{3})/.test(otherOptionImage) ? otherOptionImage : _concatInstanceProperty(_context2 = "".concat(otherOptionImage, ".")).call(_context2, imageFormat);
    }), _this;
  }
  return _createClass(ImageRadioButtonFieldInternal, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(ImageRadioButtonFieldInternal.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _context3,
        _this2 = this,
        id = breakpointProps.id,
        availableValues = breakpointProps.availableValues,
        imageBasePath = breakpointProps.imageBasePath,
        imageClassName = breakpointProps.imageClassName,
        detailElement = breakpointProps.detailElement,
        disabled = breakpointProps.disabled,
        label = breakpointProps.label,
        required = breakpointProps.required,
        controlClassName = breakpointProps.controlClassName,
        imageFormat = breakpointProps.imageFormat,
        testId = breakpointProps.testId,
        translator = this.translator,
        fieldUniqueId = this.fieldUniqueId,
        values = availableValues,
        publicUrl = process.env.PUBLIC_URL,
        sourceRoot = publicUrl ? _concatInstanceProperty(_context3 = "".concat(publicUrl, "/")).call(_context3, imageBasePath) : imageBasePath,
        componentClassName = cx(styles.imageRadioButton, imageClassName, controlClassName),
        selectedValue = this.getDataTypeAwareSelectedValue(),
        handleRadioButtonKeyDown = createHandleRadioButtonKeyDown(function (el, value) {
          var _context4;
          el.querySelector(_concatInstanceProperty(_context4 = "label[for=".concat(fieldUniqueId, "_")).call(_context4, value, "]")).focus();
        }),
        radios = _mapInstanceProperty(values).call(values, function (option) {
          var _context5,
            _context6,
            _context7,
            _context8,
            code = getOptionCode(option),
            checked = !isNilValue(selectedValue) && selectedValue.toString() === code;
          isNilValue(code) && (code = "empty", checked = isEmptyValue(selectedValue));
          var valueId = _concatInstanceProperty(_context5 = "".concat(fieldUniqueId, "_")).call(_context5, code),
            valueTestId = _concatInstanceProperty(_context6 = "".concat(testId || id, "_")).call(_context6, code),
            imageFile = "other" === code ? _this2.getOtherOptionImageName(breakpointProps) : _concatInstanceProperty(_context7 = "".concat(code, ".")).call(_context7, imageFormat),
            imageSource = _concatInstanceProperty(_context8 = "".concat(sourceRoot, "/")).call(_context8, imageFile),
            name = getOptionName(option),
            optionName = translator(name),
            contentWrapperStyles = cx(styles.contentWrapper, _defineProperty({}, styles.disabled, disabled)),
            labelStyles = cx(styles.label, _defineProperty({}, styles.disabled, disabled));
          return React.createElement("label", {
            "aria-label": optionName,
            key: valueId,
            htmlFor: valueId,
            className: labelStyles
          }, React.createElement("input", _extends({
            type: "radio",
            id: valueId,
            "data-testid": valueTestId,
            value: code || "",
            onChange: _this2.handleRadioChange,
            "aria-checked": checked,
            checked: checked,
            disabled: disabled,
            required: required
          }, _this2.generateDataPathProperty(), _this2.generateAccessibilityProperties())), React.createElement("div", {
            className: contentWrapperStyles
          }, React.createElement("div", {
            className: styles.iconImageContainer
          }, React.createElement("div", {
            className: styles.imageWrapper
          }, React.createElement("img", {
            alt: translator(option.imageAltText) || optionName,
            className: styles.image,
            src: imageSource
          })), React.createElement(Icon, {
            icon: "gw-check",
            className: styles.icon,
            size: "medium"
          })), React.createElement("div", {
            className: styles.text
          }, optionName)));
        });
      if ("other" === selectedValue && detailElement) {
        var otherId = "".concat(fieldUniqueId, "_other_element");
        radios.push(React.createElement("label", {
          key: otherId,
          htmlFor: otherId,
          className: cx(styles.label, styles.otherOption)
        }, detailElement));
      }
      return React.createElement("div", {
        id: id,
        className: componentClassName,
        "aria-label": translator(label),
        onKeyDown: function (event) {
          return handleRadioButtonKeyDown(event, breakpointProps, _this2.handleAvailableValuesValueChange);
        },
        role: "radiogroup"
      }, radios);
    }
  }]), ImageRadioButtonFieldInternal;
}();
_defineProperty(ImageRadioButtonFieldInternal, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.array,
  imageBasePath: PropTypes.string,
  imageFormat: PropTypes.string,
  imageClassName: PropTypes.string,
  otherOptionImage: PropTypes.string
})), _defineProperty(ImageRadioButtonFieldInternal, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  availableValues: [],
  dataType: DATA_TYPE_STRING,
  imageFormat: "jpg",
  otherOptionImage: "other.svg"
})), _defineProperty(ImageRadioButtonFieldInternal, "contextType", FieldComponent.contextType), _defineProperty(ImageRadioButtonFieldInternal, "displayName", "ImageRadioButtonField");
export var ImageRadioButtonField = deprecateComponent(ImageRadioButtonFieldInternal, {
  deprecated: "ImageRadioButtonField",
  alternative: "RadioField",
  extraMessage: "",
  dropTargetVersion: ""
});
ImageRadioButtonFieldInternal.__docgenInfo = {
  description: "Renders a list of radio buttons where each button is an image. You map images onto options\nas shown in the following example.\n@typedef {typeof ImageRadioButtonField.propTypes} ImageRadioButtonFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<ImageRadioButtonFieldPropTypes>>\n\n@metadataType field\n@deprecated since v8.8.0",
  methods: [{
    name: "handleRadioChange",
    docblock: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "getOtherOptionImageName",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "ImageRadioButtonField",
  props: {
    availableValues: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "array"
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code', 'name' and 'imageAltText'"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the value"
    },
    imageFormat: {
      defaultValue: {
        value: "'jpg'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional file format to reference images for options"
    },
    otherOptionImage: {
      defaultValue: {
        value: "'other.svg'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional name of a custom image to be used for the 'other' option"
    },
    value: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "The selected value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "defaultAvailableValuePropType"
      },
      required: !1,
      description: "Default value to set if none is provided"
    },
    imageBasePath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Base path for image sources"
    },
    imageClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional class for the div surrounding each image"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, ImageRadioButtonFieldInternal.__docgenInfo = {
  componentName: "ImageRadioButtonField",
  packageName: "@jutro/components",
  description: "Renders a list of radio buttons where each button is an image. You map images onto options\nas shown in the following example.",
  displayName: "ImageRadioButtonField",
  methods: [{
    name: "handleRadioChange",
    docblock: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "getOtherOptionImageName",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "ImageRadioButtonFieldInternal",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "The selected value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: !1,
      description: "Default value to set if none is provided"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    availableValues: {
      type: {
        name: "array"
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code', 'name' and 'imageAltText'",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    imageBasePath: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Base path for image sources"
    },
    imageFormat: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional file format to reference images for options",
      defaultValue: {
        value: "'jpg'",
        computed: !1
      }
    },
    imageClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "An optional class for the div surrounding each image"
    },
    otherOptionImage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional name of a custom image to be used for the 'other' option",
      defaultValue: {
        value: "'other.svg'",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};