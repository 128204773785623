import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./DropdownMenu.module.css";
export var DropdownMenuSeparator = function (props) {
  var id = props.id,
    className = props.className,
    separatorClasses = cx(styles.dropdownSeparator, className);
  return React.createElement("div", {
    id: id,
    className: separatorClasses,
    role: "separator"
  });
};
DropdownMenuSeparator.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string
}, DropdownMenuSeparator.__docgenInfo = {
  description: "DropdownMenuSeparator\n@metadataType element",
  methods: [],
  displayName: "DropdownMenuSeparator",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the dropdown menu separator component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class for the separator"
    }
  }
}, DropdownMenuSeparator.__docgenInfo = {
  componentName: "DropdownMenuSeparator",
  packageName: "@jutro/components",
  description: "DropdownMenuSeparator",
  displayName: "DropdownMenuSeparator",
  methods: [],
  actualName: "DropdownMenuSeparator",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the dropdown menu separator component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class for the separator"
    }
  }
};