import _extends from "@babel/runtime-corejs3/helpers/extends";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useAuth } from "@jutro/auth";
import { intlMessageShape } from "@jutro/prop-types";
import { DropdownMenuSeparator } from "./DropdownMenuSeparator";
import { DropdownMenuAvatarHeader } from "./DropdownMenuAvatarHeader";
import { DropdownMenuLink } from "./DropdownMenuLink";
import { messages } from "./DropdownMenuAvatar.messages";
export var DropdownMenuAvatarContent = function (_ref) {
  var children = _ref.children,
    title = _ref.title,
    subtitle = _ref.subtitle,
    useAuthInfo = _ref.useAuthInfo,
    username = _ref.username,
    icon = _ref.icon,
    className = _ref.className,
    imageSource = _ref.imageSource,
    messageProps = _ref.messageProps,
    showImageBorder = _ref.showImageBorder,
    headerClassName = _ref.headerClassName,
    authLinksClassName = _ref.authLinksClassName,
    separatorClassName = _ref.separatorClassName,
    onBeforeLogout = _ref.onBeforeLogout,
    auth = useAuth(),
    logoutCallback = useCallback(_asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            if (!onBeforeLogout) {
              _context.next = 3;
              break;
            }
            return _context.next = 3, onBeforeLogout();
          case 3:
            if (!auth || !auth.logout) {
              _context.next = 6;
              break;
            }
            return _context.next = 6, auth.logout();
          case 6:
          case "end":
            return _context.stop();
        }
      }, _callee);
    })), [auth, onBeforeLogout]),
    authLinks = useAuthInfo && auth && React.createElement(React.Fragment, null, React.createElement(DropdownMenuSeparator, {
      className: separatorClassName
    }), React.createElement(DropdownMenuLink, {
      "data-gw-test-auth-logout": !0,
      onClick: logoutCallback,
      className: authLinksClassName
    }, messages.logout)),
    headerProps = {
      title: title,
      subtitle: subtitle,
      username: username,
      icon: icon,
      imageSource: imageSource,
      messageProps: messageProps,
      useAuthInfo: useAuthInfo,
      showImageBorder: showImageBorder
    },
    childrenArray = React.Children.toArray(children),
    classNames = cx(className);
  return React.createElement("div", {
    className: classNames
  }, React.createElement(DropdownMenuAvatarHeader, _extends({
    className: headerClassName
  }, headerProps)), childrenArray.length > 0 && React.createElement(React.Fragment, null, React.createElement(DropdownMenuSeparator, {
    className: separatorClassName
  }), children), authLinks);
};
DropdownMenuAvatarContent.propTypes = {
  title: intlMessageShape,
  subtitle: intlMessageShape,
  username: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: intlMessageShape
  }),
  useAuthInfo: PropTypes.bool,
  children: PropTypes.node,
  showImageBorder: PropTypes.bool,
  headerClassName: PropTypes.string,
  authLinksClassName: PropTypes.string,
  separatorClassName: PropTypes.string,
  onBeforeLogout: PropTypes.func
}, DropdownMenuAvatarContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "DropdownMenuAvatarContent",
  props: {
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Menu header title text"
    },
    subtitle: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "User's first and last name, used as component alt text and to display initials if no image is selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to the user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "custom",
            raw: "intlMessageShape",
            description: "userAvatar message",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display header information from Okta (if available) and the Logout menu link"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu avatar component"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays an image border"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar header"
    },
    authLinksClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar auth links"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the dropdown menu separator"
    },
    onBeforeLogout: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called before Okta logout"
    }
  }
}, DropdownMenuAvatarContent.__docgenInfo = {
  componentName: "DropdownMenuAvatarContent",
  packageName: "@jutro/components",
  description: "",
  displayName: "DropdownMenuAvatarContent",
  methods: [],
  actualName: "DropdownMenuAvatarContent",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Menu header title text"
    },
    subtitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: !1,
      description: "User's first and last name, used as component alt text and to display initials if no image is selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to the user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "union",
            description: "userAvatar message",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, display header information from Okta (if available) and the Logout menu link"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children, wrapped by the dropdown menu avatar component"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays an image border"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar header"
    },
    authLinksClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS class for the dropdown menu avatar auth links"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class for the dropdown menu separator"
    },
    onBeforeLogout: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be called before Okta logout"
    }
  }
};