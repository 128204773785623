import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["onClick", "onKeyPress", "onKeyDown", "onMouseDown", "className", "iconClassName", "ariaLabel", "disabled", "icon", "iconColor", "dangerouslySetInnerHTML", "tooltip", "tabIndex", "testId", "id", "noDeprecationWarnings"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape, tooltipShape } from "@jutro/prop-types";
import { uniqueInnerId, useKeyActive, useDeprecationWarning } from "@jutro/platform";
import { useBreakpoint } from "@jutro/layout";
import { isAccessibleDisabled, muteCallbacks } from "../../accessibleDisabled";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";
import { TooltipContent } from "../Tooltip/TooltipContent";
import { messages } from "./IconButton.messages";
import styles from "./IconButton.module.css";
export var availableIconColors = ["light", "neutral", "dark"];
export var IconButton = forwardRef(function (props, externalRef) {
  var _ref = useBreakpoint(props).breakpointProps,
    onClick = _ref.onClick,
    onKeyPress = _ref.onKeyPress,
    onKeyDown = _ref.onKeyDown,
    onMouseDown = _ref.onMouseDown,
    className = _ref.className,
    iconClassName = _ref.iconClassName,
    ariaLabel = _ref.ariaLabel,
    disabled = _ref.disabled,
    icon = _ref.icon,
    iconColor = _ref.iconColor,
    tooltip = (_ref.dangerouslySetInnerHTML, _ref.tooltip),
    tabIndex = _ref.tabIndex,
    testId = _ref.testId,
    id = _ref.id,
    noDeprecationWarnings = _ref.noDeprecationWarnings,
    rest = _objectWithoutProperties(_ref, _excluded);
  useDeprecationWarning({
    componentName: "IconButton",
    noDeprecationWarnings: noDeprecationWarnings
  });
  var translator = useTranslator(),
    internalRef = useRef(null),
    isActiveKeyPressed = useKeyActive(externalRef || internalRef),
    iconColorClass = styles[iconColor],
    classes = cx(_defineProperty({}, styles.activeKeypress, isActiveKeyPressed), _defineProperty({}, styles.padding, !_startsWithInstanceProperty(icon).call(icon, "gw-") && !_startsWithInstanceProperty(icon).call(icon, "cust-")), styles.iconButton, iconColorClass, className),
    iconClasses = cx(styles.icon, iconColorClass, iconClassName),
    accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.iconButton"),
    accessibleDisabledProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks(_objectSpread({
      onClick: onClick,
      onKeyPress: onKeyPress,
      onKeyDown: onKeyDown
    }, rest))), {}, {
      onMouseDown: void 0,
      "aria-disabled": !0,
      disabled: void 0
    }) : {},
    buttonElement = React.createElement("button", _extends({
      type: "button",
      className: classes,
      onClick: onClick,
      onKeyPress: onKeyPress,
      onKeyDown: onKeyDown,
      onMouseDown: onMouseDown,
      "aria-label": translator(ariaLabel || messages.iconButtonText),
      tabIndex: null != tabIndex ? tabIndex : disabled ? void 0 : 0,
      disabled: disabled,
      ref: externalRef || internalRef,
      "data-testid": testId || id,
      id: id
    }, rest, accessibleDisabledProps), React.createElement(Icon, {
      noDeprecationWarnings: noDeprecationWarnings,
      className: iconClasses,
      icon: icon
    }));
  if (!accessibleDisabled && (null != tooltip && tooltip.title || null != tooltip && tooltip.text || null != tooltip && tooltip.renderContent)) {
    var text = tooltip.text,
      title = tooltip.title,
      link = tooltip.link,
      href = tooltip.href,
      renderContent = tooltip.renderContent,
      placement = tooltip.placement,
      content = renderContent ? renderContent() : React.createElement(TooltipContent, {
        title: title,
        text: text,
        href: href,
        link: link
      }),
      tooltipProps = {
        id: uniqueInnerId("icon-button-tooltip", "tooltipId").tooltipId,
        content: content,
        placement: placement,
        trigger: "mouseenter focus"
      };
    return React.createElement(Tooltip, tooltipProps, buttonElement);
  }
  return buttonElement;
});
var iconButtonPropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  ariaLabel: intlMessageShape,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.oneOf(availableIconColors),
  disabled: PropTypes.bool,
  tooltip: tooltipShape,
  testId: PropTypes.string
};
IconButton.propTypes = iconButtonPropTypes, IconButton.defaultProps = {
  disabled: !1,
  iconColor: "dark"
}, IconButton.displayName = "IconButton", IconButton.__docgenInfo = {
  description: "@metadataType action\n@deprecated since v8.9.0",
  methods: [],
  displayName: "IconButton",
  props: {
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, this button is disabled"
    },
    iconColor: {
      defaultValue: {
        value: "'dark'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'light'",
          computed: !1
        }, {
          value: "'neutral'",
          computed: !1
        }, {
          value: "'dark'",
          computed: !1
        }]
      },
      required: !1,
      description: "The color of the icon"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify this component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for icon"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On click handler"
    },
    onMouseDown: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On mouse down handler"
    },
    onKeyDown: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On key down handler"
    },
    onKeyPress: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On key press handler"
    },
    ariaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Aria label text"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Icon name"
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "tooltipShape"
      },
      required: !1,
      description: "IconButton tooltip"
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id."
    }
  }
}, IconButton.__docgenInfo = {
  componentName: "IconButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "IconButton",
  methods: [],
  actualName: "IconButton",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify this component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for icon"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On click handler"
    },
    onMouseDown: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On mouse down handler"
    },
    onKeyDown: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On key down handler"
    },
    onKeyPress: {
      type: {
        name: "func"
      },
      required: !1,
      description: "On key press handler"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Aria label text"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Icon name"
    },
    iconColor: {
      type: {
        name: "enum",
        value: [{
          value: "'light'",
          computed: !1
        }, {
          value: "'neutral'",
          computed: !1
        }, {
          value: "'dark'",
          computed: !1
        }]
      },
      required: !1,
      description: "The color of the icon",
      defaultValue: {
        value: "'dark'",
        computed: !1
      }
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, this button is disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "tooltipShape"
      },
      required: !1,
      description: "IconButton tooltip"
    },
    testId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Data attribute that specifies the data-testid used in testing. If not provided, the data attribute is set to id."
    }
  }
};