import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import isString from "lodash/isString";
import { isSupportedHTMLElement } from "./checkSupportedHTMLElements";
import { isSupportedHTMLContainer } from "./checkSupportedHTMLContainers";
export var metadataTypes = {
  ACTION: "action",
  ELEMENT: "element",
  CONTAINER: "container",
  FIELD: "field",
  ITERABLE: "iterable",
  LAYOUT: "layout",
  COMPONENT: "component"
};
var metadataTypePattern = /^@metadataType\s([a-z]+)$/;
export var getComponentMetadataType = function (component, content) {
  var _component$__docgenIn,
    _context,
    _component$__docgenIn2,
    _component$__docgenIn3,
    metadataTypeFilteredFromDescription = null == component || null === (_component$__docgenIn = component.__docgenInfo) || void 0 === _component$__docgenIn ? void 0 : _filterInstanceProperty(_context = _component$__docgenIn.description.split("\n")).call(_context, function (line) {
      return line.match(metadataTypePattern);
    });
  return null != metadataTypeFilteredFromDescription && metadataTypeFilteredFromDescription.length ? metadataTypeFilteredFromDescription[0].replace(metadataTypePattern, "$1") : isSupportedHTMLContainer(component) || isSupportedHTMLElement(component) ? !content || isString(content) ? metadataTypes.ELEMENT : metadataTypes.CONTAINER : (null == component ? void 0 : component.metadataType) || (null == component || null === (_component$__docgenIn2 = component.__docgenInfo) || void 0 === _component$__docgenIn2 ? void 0 : _component$__docgenIn2.metadataType) || (null == component || null === (_component$__docgenIn3 = component.__docgenInfo) || void 0 === _component$__docgenIn3 ? void 0 : _component$__docgenIn3.jutroMetadataType);
};