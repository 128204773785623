import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useCallback } from "react";
import { useKeyPress } from "./useKeyPress.js";
export function useKeyActive(ref) {
  var targetKeys = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : ["Enter", " "],
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isKeyActive = _useState2[0],
    setIsKeyActive = _useState2[1],
    handleKeyActive = useCallback(function (event, isKeyDown) {
      setIsKeyActive(!!isKeyDown);
    }, []);
  return useKeyPress(ref, targetKeys, handleKeyActive), isKeyActive;
}