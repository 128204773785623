import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import React, { useCallback, useState } from "react";
import { Button } from "@jutro/components";
import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import { Flex } from "@jutro/layout";
import { MetadataForm } from "@jutro/uiconfig";
import { useTranslator } from "@jutro/locale";
import { messages } from "./FilterBar.messages";
import internalStyles from "./FilterBar.module.css";
var FilterBarContentInternal = function (_ref) {
  var styles = _ref.styles,
    metadata = _ref.metadata,
    onFiltersChange = _ref.onFiltersChange,
    componentMap = _ref.componentMap,
    callbackMap = _ref.callbackMap,
    isFiltersEmpty = _ref.isFiltersEmpty,
    _ref$initialFilters = _ref.initialFilters,
    initialFilters = void 0 === _ref$initialFilters ? {} : _ref$initialFilters,
    translator = useTranslator(),
    _useState = useState(initialFilters),
    _useState2 = _slicedToArray(_useState, 2),
    formData = _useState2[0],
    setFormData = _useState2[1],
    isFormEmpty = _Object$values(formData).every(function (value) {
      return !isNumber(value) && isEmpty(value);
    }),
    overrideProps = {
      "@field": {
        className: internalStyles.filterItem
      }
    },
    applyFilters = useCallback(function () {
      var _context;
      onFiltersChange(_reduceInstanceProperty(_context = _Object$entries(formData)).call(_context, function (newFilters, _ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          path = _ref3[0],
          value = _ref3[1];
        return null != value && 0 !== value.length && (newFilters[path] = value), newFilters;
      }, {}));
    }, [formData, onFiltersChange]),
    handleClearFilter = useCallback(function () {
      setFormData({}), onFiltersChange({});
    }, [onFiltersChange, setFormData]),
    handleFormEnter = useCallback(function (event) {
      "Enter" !== event.key || event.defaultPrevented || applyFilters();
    }, [applyFilters]);
  return React.createElement("div", {
    className: internalStyles.filterBar
  }, React.createElement("div", {
    className: internalStyles.titleBar
  }, React.createElement("div", {
    className: internalStyles.filterTitle
  }, translator(messages.title)), React.createElement("div", null, React.createElement(Button, {
    id: "clearFilter",
    type: "text",
    size: "small",
    disabled: isFiltersEmpty,
    onClick: handleClearFilter
  }, translator(messages.clearFilters)), React.createElement(Button, {
    id: "applyFilter",
    type: "filled",
    size: "small",
    disabled: isFormEmpty && isFiltersEmpty,
    onClick: applyFilters
  }, translator(messages.applyFilters)))), React.createElement("hr", {
    className: internalStyles.titleDivider
  }), React.createElement(Flex, {
    gap: "small",
    onKeyDown: handleFormEnter,
    role: "presentation"
  }, React.createElement(MetadataForm, {
    uiProps: metadata,
    onDataChange: function (value, path) {
      setFormData(function (prevState) {
        var newState = function (target) {
          for (var i = 1; i < arguments.length; i++) {
            var source = null != arguments[i] ? arguments[i] : {};
            i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
              _defineProperty(target, key, source[key]);
            }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
              _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
            });
          }
          return target;
        }({}, prevState);
        return set(newState, path, value), newState;
      });
    },
    classNameMap: styles,
    resolveValue: function (id, path) {
      return get(formData, path);
    },
    callbackMap: callbackMap,
    componentMap: componentMap,
    overrideProps: overrideProps,
    data: formData
  })));
};
FilterBarContentInternal.displayName = "FilterBarContent";
export var FilterBarContent = React.memo(FilterBarContentInternal);
FilterBarContentInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FilterBarContent",
  props: {
    initialFilters: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    }
  }
}, FilterBarContentInternal.__docgenInfo = {
  componentName: "FilterBarContent",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "FilterBarContent",
  methods: [],
  actualName: "FilterBarContentInternal",
  props: {
    initialFilters: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    }
  }
};