import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { useContext } from "react";
export default function (_ref) {
  var _context,
    contexts = _ref.contexts,
    renderProp = _ref.children,
    _ref$args = _ref.args,
    args = void 0 === _ref$args ? [] : _ref$args,
    contextHook = useContext;
  return renderProp.apply(void 0, _concatInstanceProperty(_context = _toConsumableArray(_mapInstanceProperty(contexts).call(contexts, function (context) {
    return contextHook(context);
  }))).call(_context, _toConsumableArray(args)));
}