import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React, { memo } from "react";
import cx from "classnames";
import { InlineLabel } from "../InlineLabel/InlineLabel";
import styles from "./TreeNodeHeader.module.css";
import { TextHighlight } from "../TextHighlight-lab-preview/TextHighlight";
export var TreeNodeHeader = memo(function (_ref) {
  var id = _ref.id,
    nodeId = _ref.nodeId,
    label = _ref.label,
    icon = _ref.icon,
    hideIcon = _ref.hideIcon,
    RenderIcon = _ref.renderIcon,
    labelMatches = _ref.labelMatches;
  return React.createElement(InlineLabel, {
    id: id,
    icon: !hideIcon && React.createElement(RenderIcon, {
      icon: icon,
      nodeId: nodeId
    }),
    className: cx(styles.header),
    style: {}
  }, React.createElement("span", {
    className: cx(styles.labelWrapper, _defineProperty({}, styles.iconMargin, icon)),
    title: label
  }, labelMatches ? React.createElement(TextHighlight, {
    label: label,
    highlightParts: labelMatches,
    highlightClassName: styles.highlight
  }) : label));
});