import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["colSpan", "colStart", "clone", "fullWidth", "rowSpan", "rowStart", "visible", "align", "valign", "textAlign", "tag", "children", "className", "style", "dangerouslySetInnerHTML"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import { warning } from "@jutro/logger";
import styles from "./Grid.module.css";
import { useBreakpoint } from "../breakpoint/useBreakpoint";
var valignMapping = {
    top: styles.alignSelfStart,
    middle: styles.alignSelfCenter,
    bottom: styles.alignSelfEnd,
    baseline: styles.alignSelfBaseline,
    stretch: styles.alignSelfStretch
  },
  alignMapping = {
    start: styles.justifySelfStart,
    center: styles.justifySelfCenter,
    end: styles.justifySelfEnd,
    stretch: styles.justifySelfStretch
  },
  textAlignMapping = {
    left: styles.textLeft,
    center: styles.textCenter,
    right: styles.textRight
  };
export var GridItem = function (props) {
  var breakpointProps = useBreakpoint(props).breakpointProps,
    colSpan = breakpointProps.colSpan,
    colStart = breakpointProps.colStart,
    clone = breakpointProps.clone,
    fullWidth = breakpointProps.fullWidth,
    rowSpan = breakpointProps.rowSpan,
    rowStart = breakpointProps.rowStart,
    visible = breakpointProps.visible,
    align = breakpointProps.align,
    valign = breakpointProps.valign,
    textAlign = breakpointProps.textAlign,
    tag = breakpointProps.tag,
    children = breakpointProps.children,
    className = breakpointProps.className,
    style = breakpointProps.style,
    other = (breakpointProps.dangerouslySetInnerHTML, _objectWithoutProperties(breakpointProps, _excluded)),
    Tag = tag;
  if (!1 === visible) return null;
  var classes = cx(textAlign && textAlignMapping[textAlign], valign && valignMapping[valign], align && alignMapping[align], className),
    inlineStyles = _objectSpread({}, style);
  colStart || colSpan ? (colStart && (inlineStyles.gridColumnStart = colStart), colSpan && (inlineStyles.gridColumnEnd = "span ".concat(colSpan)), fullWidth && warning("GridItem: fullWidth property is ignored because colStart or colSpan is provided.")) : fullWidth && (inlineStyles.gridColumnStart = 1, inlineStyles.gridColumnEnd = -1), rowStart && (inlineStyles.gridRowStart = rowStart), rowSpan && (inlineStyles.gridRowEnd = "span ".concat(rowSpan));
  var _context,
    renderWithWrapper = function (wrappedChildren) {
      return React.createElement(Tag, _extends({
        className: classes,
        style: inlineStyles
      }, other), wrappedChildren);
    };
  return clone ? React.createElement(React.Fragment, null, _mapInstanceProperty(_context = React.Children).call(_context, children, function (child) {
    return React.isValidElement(child) ? React.cloneElement(child, _objectSpread(_objectSpread({}, other), {}, {
      className: cx(child.props.className, classes),
      style: _objectSpread(_objectSpread({}, child.props.style), inlineStyles)
    })) : renderWithWrapper(child);
  })) : renderWithWrapper(children);
};
var gridItemPropTypes = {
  clone: PropTypes.bool,
  fullWidth: PropTypes.bool,
  visible: PropTypes.bool,
  rowSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.oneOf(_Object$keys(textAlignMapping)),
  valign: PropTypes.oneOf(_Object$keys(valignMapping)),
  align: PropTypes.oneOf(_Object$keys(alignMapping)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
};
GridItem.propTypes = gridItemPropTypes, GridItem.defaultProps = {
  tag: "div"
}, GridItem.displayName = "GridItem", GridItem.__docgenInfo = {
  description: "Defines a css grid 'GridItem'. This is used in conjunction with 'Grid' parent.\n\n@param {object} [props] - props for this component\n@returns {React.ReactElement}\n\n@example\n<GridItem\n  textAlign=\"left\"\n  valign=\"middle\"\n  align=\"center\"\n  tablet={{ textAlign: 'center' }}\n  phoneWide={{ textAlign: 'center' }}\n  phone={{ visible: false }}\n>\n  custom content\n</GridItem>\n\n@metadataType layout",
  methods: [],
  displayName: "GridItem",
  props: {
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "DOM tag to use"
    },
    clone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Render the child without a tag wrapper. It passes classNames and styles directly to the child element if possible.\nIf not possible, it falls back to wrapping with a tag (for React elements which are not valid)."
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, item takes full width of the parent grid.\nIgnored if colStart or colSpan is specified."
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Specify whether the GridItem is visible"
    },
    rowSpan: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "How many rows it takes"
    },
    rowStart: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Row number to start"
    },
    colSpan: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "How many columns it takes"
    },
    colStart: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Column number to start"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }]
      },
      required: !1,
      description: "Horizontally align the contents of the column"
    },
    valign: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Vertically align the column (relative to other columns in the same row). By default the column will be stretched to equal height."
    },
    align: {
      type: {
        name: "enum",
        value: [{
          value: '"start"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"end"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align the column. By default the column will be stretched horizontally."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class(es) to append to tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children for this item"
    },
    style: {
      type: {
        name: "object"
      },
      required: !1,
      description: "DOM element styles"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridItem property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridItem property for use at the 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridItem property for use at the 'tablet' breakpoint"
    }
  }
}, GridItem.__docgenInfo = {
  componentName: "GridItem",
  packageName: "@jutro/layout",
  description: "Defines a css grid 'GridItem'. This is used in conjunction with 'Grid' parent.",
  displayName: "GridItem",
  methods: [],
  actualName: "GridItem",
  metadataType: "layout",
  props: {
    clone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Render the child without a tag wrapper. It passes classNames and styles directly to the child element if possible.\nIf not possible, it falls back to wrapping with a tag (for React elements which are not valid)."
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, item takes full width of the parent grid.\nIgnored if colStart or colSpan is specified."
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Specify whether the GridItem is visible"
    },
    rowSpan: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "How many rows it takes"
    },
    rowStart: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Row number to start"
    },
    colSpan: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "How many columns it takes"
    },
    colStart: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Column number to start"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }]
      },
      required: !1,
      description: "Horizontally align the contents of the column"
    },
    valign: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Vertically align the column (relative to other columns in the same row). By default the column will be stretched to equal height."
    },
    align: {
      type: {
        name: "enum",
        value: [{
          value: '"start"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"end"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align the column. By default the column will be stretched horizontally."
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "DOM tag to use",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class(es) to append to tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children for this item"
    },
    style: {
      type: {
        name: "object"
      },
      required: !1,
      description: "DOM element styles"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridItem property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridItem property for use at the 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any GridItem property for use at the 'tablet' breakpoint"
    }
  }
};