import { Contract, types } from "@jutro/contract";
import { ServiceManager } from "@jutro/services";
import { logDeprecationMessage } from "../utils/logDeprecationMessage";
export var MessageInterface = Contract.specify({
  getMessage: types.func
});
export function getMessageService() {
  var messageService = ServiceManager.getServiceByContract(MessageInterface);
  if (!messageService) throw new Error("No implementation of MessageInterface provided.");
  return logDeprecationMessage("getMessageService", "Please use useTranslator or TranslatorProvider from @jutro/locale package instead of translator and message service from @jutro/platform"), messageService;
}
export function getMessageProp(messageKey, messageProps, messages) {
  return (null == messageProps ? void 0 : messageProps[messageKey]) || messages[messageKey];
}