import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["component", "componentProps", "navLink", "title", "showOnNavBar"],
  _excluded2 = ["redirect", "title", "showOnNavBar"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useMemo } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { warning } from "@jutro/logger";
import { useTranslator } from "@jutro/locale";
import { sanitizeUrl, PathnameSwitch } from "@jutro/platform";
import { PageTitle } from "../pageHead/PageTitle";
import { resolveComponentFromMap } from "../resolveComponentFromMap";
var ComponentRoute = function (_ref) {
    var title = _ref.title,
      component = _ref.component,
      componentProps = _ref.componentProps,
      resolveComponentMap = _ref.resolveComponentMap,
      resolveComponent = _ref.resolveComponent,
      translator = useTranslator(),
      ResolvedComponent = resolveComponent(component, resolveComponentMap);
    return React.createElement(React.Fragment, null, React.createElement(PageTitle, {
      title: translator(title)
    }), React.createElement(ResolvedComponent, componentProps));
  },
  RedirectRoute = function (_ref2) {
    var _context,
      redirect = _ref2.redirect,
      search = useLocation().search;
    return React.createElement(Redirect, {
      to: sanitizeUrl(_concatInstanceProperty(_context = "".concat(redirect)).call(_context, search))
    });
  };
function renderComponentRoute(routeProps, resolveComponentMap, resolveComponent) {
  var component = routeProps.component,
    componentProps = routeProps.componentProps,
    title = (routeProps.navLink, routeProps.title),
    other = (routeProps.showOnNavBar, _objectWithoutProperties(routeProps, _excluded));
  return React.createElement(Route, _extends({
    key: other.path || title
  }, other), React.createElement(ComponentRoute, {
    title: title,
    component: component,
    componentProps: componentProps,
    resolveComponentMap: resolveComponentMap,
    resolveComponent: resolveComponent
  }));
}
function renderRedirectRoute(_ref3) {
  var redirect = _ref3.redirect,
    title = _ref3.title,
    other = (_ref3.showOnNavBar, _objectWithoutProperties(_ref3, _excluded2));
  return React.createElement(Route, _extends({
    key: "redirect to ".concat(redirect || title)
  }, other, {
    render: function () {
      return React.createElement(RedirectRoute, {
        redirect: redirect
      });
    }
  }));
}
function renderNavRoute(routeProps, resolveComponentMap, resolveComponent) {
  var _context2;
  return routeProps.routes ? _mapInstanceProperty(_context2 = routeProps.routes).call(_context2, function (props) {
    return renderNavRoute(props, resolveComponentMap, resolveComponent);
  }) : routeProps.redirect ? renderRedirectRoute(routeProps) : routeProps.component ? renderComponentRoute(routeProps, resolveComponentMap, resolveComponent) : void (routeProps.href || warning("Either redirect, component or href (for external links) must be provided for every route definition"));
}
export var NavRoutes = function (_ref4) {
  var routes = _ref4.routes,
    resolveComponentMap = _ref4.resolveComponentMap,
    resolveComponent = _ref4.resolveComponent,
    routesArray = Array.isArray(routes) ? routes : routes.routes,
    resolvedRoutes = useMemo(function () {
      return null == routesArray ? void 0 : _mapInstanceProperty(routesArray).call(routesArray, function (routeProps) {
        return renderNavRoute(routeProps, resolveComponentMap, resolveComponent);
      });
    }, [resolveComponent, resolveComponentMap, routesArray]);
  return React.createElement(PathnameSwitch, null, resolvedRoutes);
};
NavRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  resolveComponentMap: PropTypes.object.isRequired,
  resolveComponent: PropTypes.func
}, NavRoutes.defaultProps = {
  resolveComponent: resolveComponentFromMap
}, NavRoutes.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NavRoutes",
  props: {
    resolveComponent: {
      defaultValue: {
        value: "resolveComponentFromMap",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: "An optional callback function to resolve the component; `const Component = resolveComponent(componentName, componentMap)`"
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "object"
        }
      },
      required: !0,
      description: "The routing metadata object"
    },
    resolveComponentMap: {
      type: {
        name: "object"
      },
      required: !0,
      description: "Map of component name to component classes (required if using route metadata in JSON files)"
    }
  }
}, ComponentRoute.__docgenInfo = {
  componentName: "ComponentRoute",
  packageName: "@jutro/router",
  description: "",
  displayName: "ComponentRoute",
  methods: [],
  actualName: "ComponentRoute"
}, RedirectRoute.__docgenInfo = {
  componentName: "RedirectRoute",
  packageName: "@jutro/router",
  description: "",
  displayName: "RedirectRoute",
  methods: [],
  actualName: "RedirectRoute"
}, renderComponentRoute.__docgenInfo = {
  componentName: "renderComponentRoute",
  packageName: "@jutro/router",
  description: "",
  displayName: "renderComponentRoute",
  methods: [],
  actualName: "renderComponentRoute"
}, renderRedirectRoute.__docgenInfo = {
  componentName: "renderRedirectRoute",
  packageName: "@jutro/router",
  description: "",
  displayName: "renderRedirectRoute",
  methods: [],
  actualName: "renderRedirectRoute"
}, renderNavRoute.__docgenInfo = {
  componentName: "renderNavRoute",
  packageName: "@jutro/router",
  description: "",
  displayName: "renderNavRoute",
  methods: [],
  actualName: "renderNavRoute"
}, NavRoutes.__docgenInfo = {
  componentName: "NavRoutes",
  packageName: "@jutro/router",
  description: "",
  displayName: "NavRoutes",
  methods: [],
  actualName: "NavRoutes",
  props: {
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "object"
        }
      },
      required: !0,
      description: "The routing metadata object"
    },
    resolveComponentMap: {
      type: {
        name: "object"
      },
      required: !0,
      description: "Map of component name to component classes (required if using route metadata in JSON files)"
    },
    resolveComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "An optional callback function to resolve the component; `const Component = resolveComponent(componentName, componentMap)`",
      defaultValue: {
        value: "resolveComponentFromMap",
        computed: !0
      }
    }
  }
};