import { defineMessages } from "react-intl";
export var messages = defineMessages({
  yes: {
    id: "jutro-components.widgets.inputs.RadioField.Yes",
    defaultMessage: "Yes"
  },
  no: {
    id: "jutro-components.widgets.inputs.RadioField.No",
    defaultMessage: "No"
  }
});