import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["columns", "children", "gridItems"],
  _excluded2 = ["columns", "variant", "onColumnsRearrange", "gridItems"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _fillInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/fill";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import PropTypes from "prop-types";
import { ComponentPropTypes } from "@jutro/prop-types";
import { warning } from "@jutro/logger";
import times from "lodash/times";
import { GridLayout } from "./GridLayout";
import { useBreakpoint } from "../breakpoint/useBreakpoint";
import { mergeColumnsToFirst, mergeColumnsToLast, stackColumnsToFirst, stackColumnsToLast } from "./columnsRearrangeFunctions";
var variantsMap = {
  mergeColumnsToFirst: mergeColumnsToFirst,
  mergeColumnsToLast: mergeColumnsToLast,
  stackColumnsToFirst: stackColumnsToFirst,
  stackColumnsToLast: stackColumnsToLast,
  default: null
};
export var columnsLayoutVariants = _Object$keys(variantsMap);
var columnsLayoutChildrenPropType = ComponentPropTypes.childOfComponentType(GridLayout).isRequired,
  columnsLayoutColumnsPropType = ComponentPropTypes.withLength(function (props) {
    return React.Children.count(props.children);
  }),
  columnsLayoutProps = _objectSpread(_objectSpread({}, GridLayout.propTypes), {}, {
    id: PropTypes.string,
    children: columnsLayoutChildrenPropType,
    columns: columnsLayoutColumnsPropType,
    onColumnsRearrange: PropTypes.func,
    variant: PropTypes.oneOf(columnsLayoutVariants)
  });
export var ColumnsLayout = function (_ref) {
  var _context,
    columnsProp = _ref.columns,
    childrenProp = _ref.children,
    desktopGridItems = _ref.gridItems,
    props = _objectWithoutProperties(_ref, _excluded),
    desktopColumns = columnsProp || _fillInstanceProperty(_context = Array(React.Children.count(childrenProp))).call(_context, 1),
    breakpointProps = useBreakpoint(_objectSpread({
      gridItems: desktopGridItems,
      columns: desktopColumns
    }, props)).breakpointProps,
    columns = breakpointProps.columns,
    variant = breakpointProps.variant,
    onColumnsRearrange = breakpointProps.onColumnsRearrange,
    _breakpointProps$grid = breakpointProps.gridItems,
    gridItems = void 0 === _breakpointProps$grid ? [] : _breakpointProps$grid,
    rest = _objectWithoutProperties(breakpointProps, _excluded2),
    rearrangeFunction = onColumnsRearrange || variant && variantsMap[variant],
    children = childrenProp,
    rearrangedGridItems = gridItems;
  if (columns.length > 1 && desktopColumns.length !== columns.length) if (rearrangeFunction) {
    var _context2,
      rearrangedChildren = rearrangeFunction(React.Children.toArray(childrenProp), columns.length);
    if (rearrangedChildren.length !== columns.length) warning("GridLayout: onColumnsRearrange returned incorrect number of columns. " + _concatInstanceProperty(_context2 = "Expected length: ".concat(columns.length, " but received ")).call(_context2, rearrangedChildren.length, "."));
    children = rearrangedChildren;
  } else {
    var _context3;
    rearrangedGridItems = _concatInstanceProperty(_context3 = [gridItems[0]]).call(_context3, _toConsumableArray(times(desktopColumns.length - 2, function (index) {
      return _objectSpread({
        rowSpan: 2
      }, gridItems[index + 1]);
    })), [gridItems[desktopColumns.length - 1]]);
  }
  return React.createElement(GridLayout, _extends({
    valignItems: "top",
    columns: columns,
    gridItems: rearrangedGridItems
  }, rest, {
    tablet: props.tablet,
    phoneWide: props.phoneWide,
    phone: props.phone
  }), children);
};
ColumnsLayout.propTypes = columnsLayoutProps, ColumnsLayout.defaultProps = {
  gap: "large",
  tablet: {
    columns: [1],
    gridItems: []
  },
  phoneWide: {
    columns: [1],
    gridItems: []
  },
  phone: {
    columns: [1],
    gridItems: []
  },
  tag: "section",
  variant: "default"
}, ColumnsLayout.displayName = "ColumnsLayout", ColumnsLayout.__docgenInfo = {
  description: "Renders number of independent columns and manages its order for different breakpoints.\nExtends GridLayout component.\nBy default for desktop, it divides columns to have equal width (e.g 3 columns => [1, 1, 1]),\nfor tablet and mobile renders single column grid.\n\n@metadataType layout",
  methods: [],
  displayName: "ColumnsLayout",
  props: {
    gap: {
      defaultValue: {
        value: "'large'",
        computed: !1
      },
      required: !1
    },
    tablet: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      required: !1
    },
    phoneWide: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      required: !1
    },
    phone: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      required: !1
    },
    tag: {
      defaultValue: {
        value: "'section'",
        computed: !1
      },
      required: !1
    },
    variant: {
      defaultValue: {
        value: "'default'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: '"mergeColumnsToFirst"',
          computed: !1
        }, {
          value: '"mergeColumnsToLast"',
          computed: !1
        }, {
          value: '"stackColumnsToFirst"',
          computed: !1
        }, {
          value: '"stackColumnsToLast"',
          computed: !1
        }, {
          value: '"default"',
          computed: !1
        }]
      },
      required: !1,
      description: "Specifies how the columns will be stacked or merged on the breakpoint.\nIgnored if `onColumnsRearrange` provided"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "custom",
        raw: "columnsLayoutChildrenPropType"
      },
      required: !1,
      description: "Each child is a separate column. Accepts only `GridLayout` components as children."
    },
    columns: {
      type: {
        name: "custom",
        raw: "columnsLayoutColumnsPropType"
      },
      required: !1,
      description: "Grid's columns width/ratio configuration.\nThe columns length for desktop have to be equal to number of children (columns).\nIf not provided, it will be inferred as the number of children with a width of one fraction for each column."
    },
    onColumnsRearrange: {
      type: {
        name: "custom",
        raw: "PropTypes.func as PropTypes.Requireable<ColumnsRearrangeFunction>"
      },
      required: !1,
      description: "Callback to rearrange children (columns) that will be invoked on the breakpoint change.\nIt is invoked with the arguments of an array of the children and breakpoint columns' length, `(children, columnsLength) => newChildren`.\nWhen provided, it overrides the `variant` prop."
    }
  },
  composes: ["./GridLayout"]
}, ColumnsLayout.__docgenInfo = {
  componentName: "ColumnsLayout",
  packageName: "@jutro/layout",
  description: "Renders number of independent columns and manages its order for different breakpoints.\nExtends GridLayout component.\nBy default for desktop, it divides columns to have equal width (e.g 3 columns => [1, 1, 1]),\nfor tablet and mobile renders single column grid.",
  displayName: "ColumnsLayout",
  methods: [],
  actualName: "ColumnsLayout",
  metadataType: "layout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "custom",
        raw: "columnsLayoutChildrenPropType"
      },
      required: !1,
      description: "Each child is a separate column. Accepts only `GridLayout` components as children."
    },
    columns: {
      type: {
        name: "custom",
        raw: "columnsLayoutColumnsPropType"
      },
      required: !1,
      description: "Grid's columns width/ratio configuration.\nThe columns length for desktop have to be equal to number of children (columns).\nIf not provided, it will be inferred as the number of children with a width of one fraction for each column."
    },
    onColumnsRearrange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to rearrange children (columns) that will be invoked on the breakpoint change.\nIt is invoked with the arguments of an array of the children and breakpoint columns' length, `(children, columnsLength) => newChildren`.\nWhen provided, it overrides the `variant` prop."
    },
    variant: {
      type: {
        name: "enum",
        value: [{
          value: '"mergeColumnsToFirst"',
          computed: !1
        }, {
          value: '"mergeColumnsToLast"',
          computed: !1
        }, {
          value: '"stackColumnsToFirst"',
          computed: !1
        }, {
          value: '"stackColumnsToLast"',
          computed: !1
        }, {
          value: '"default"',
          computed: !1
        }]
      },
      required: !1,
      description: "Specifies how the columns will be stacked or merged on the breakpoint.\nIgnored if `onColumnsRearrange` provided",
      defaultValue: {
        value: "'default'",
        computed: !1
      }
    },
    gap: {
      defaultValue: {
        value: "'large'",
        computed: !1
      },
      required: !1
    },
    tablet: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      required: !1
    },
    phoneWide: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      required: !1
    },
    phone: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: !1
      },
      required: !1
    },
    tag: {
      defaultValue: {
        value: "'section'",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["./GridLayout"]
};