import PropTypes from "prop-types";
import { intlMessageShape } from "./intlMessageShape";
export var location = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.shape({})
};
export var intlLocation = {
  pathname: intlMessageShape,
  search: PropTypes.string,
  hash: intlMessageShape,
  state: PropTypes.shape({})
};
export var toShape = PropTypes.oneOfType([PropTypes.string, PropTypes.shape(location)]);
export var intlToShape = PropTypes.oneOfType([intlMessageShape, PropTypes.shape(intlLocation)]);
export var isIntlToShapeLocation = function (to) {
  return void 0 !== to && "string" != typeof to && ["pathname", "search", "hash", "state"].some(function (property) {
    return property in to;
  });
};