import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useMemo, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
import { routesShape, contextSwitcherShape } from "@jutro/prop-types";
import styles from "./TopNavigation.module.css";
import { NavigationContent } from "../NavigationContent";
import { removeNavLinkIcon } from "../navBarHelper";
import { NavBarDropdown } from "./NavBarDropdown/NavBarDropdown";
import { NavigationContext } from "../NavigationContext";
export var TopNavigation = function (props) {
  var className = props.className,
    routes = props.routes,
    wrap = props.wrap,
    contextSwitcher = props.contextSwitcher,
    renderContextSwitcher = props.renderContextSwitcher,
    navBarRef = props.navBarRef,
    breakpoint = useBreakpoint().breakpoint,
    _useState = useState(""),
    _useState2 = _slicedToArray(_useState, 2),
    activeRoute = _useState2[0],
    setActiveRoute = _useState2[1],
    classes = cx(styles.topNavigation, _defineProperty({}, styles.noWrap, !wrap), className),
    navigationContext = useMemo(function () {
      return {
        activeRoute: activeRoute,
        setActiveRoute: setActiveRoute
      };
    }, [activeRoute]),
    cleanedRoutes = useMemo(function () {
      return _mapInstanceProperty(routes).call(routes, removeNavLinkIcon);
    }, [routes]);
  return React.createElement(NavigationContext.Provider, {
    value: navigationContext
  }, React.createElement(NavigationContent, _extends({
    routes: cleanedRoutes,
    nestedRoutesComponent: NavBarDropdown,
    alignment: "top"
  }, "desktop" === breakpoint && {
    contextSwitcher: contextSwitcher
  }, {
    className: classes,
    contextSwitcherClassName: styles.contextSwitcher,
    renderContextSwitcher: renderContextSwitcher,
    navBarRef: navBarRef
  })));
};
TopNavigation.propTypes = {
  className: PropTypes.string,
  routes: routesShape.isRequired,
  contextSwitcher: contextSwitcherShape,
  renderContextSwitcher: PropTypes.func,
  wrap: PropTypes.bool,
  navBarRef: PropTypes.shape({
    current: PropTypes.instanceOf("undefined" != typeof window ? Element : Object)
  })
}, TopNavigation.defaultProps = {
  wrap: !0
}, TopNavigation.__docgenInfo = {
  description: "The `TopNavigation` component is designed as a container for navigation links and dropdowns in Header.\n\n@param {object} props properties for TopNavigation component\n@returns {React.ReactElement}\n\n@metadataType container",
  methods: [],
  displayName: "TopNavigation",
  props: {
    wrap: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, items will wrap when there isn't enough space"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    routes: {
      type: {
        name: "custom",
        raw: "routesShape.isRequired"
      },
      required: !1,
      description: "The routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "custom",
        raw: "contextSwitcherShape"
      },
      required: !1,
      description: "The context switcher object"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    },
    navBarRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "typeof window !== 'undefined' ? Element : Object",
            required: !1
          }
        }
      },
      required: !1,
      description: "Reference to the navigation bar HTML element"
    }
  }
}, TopNavigation.__docgenInfo = {
  componentName: "TopNavigation",
  packageName: "@jutro/router",
  description: "The `TopNavigation` component is designed as a container for navigation links and dropdowns in Header.",
  displayName: "TopNavigation",
  methods: [],
  actualName: "TopNavigation",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: !0,
      description: "The routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: !1
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: !0
          }
        }
      },
      required: !1,
      description: "The context switcher object"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    },
    wrap: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, items will wrap when there isn't enough space",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    navBarRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "typeof window !== 'undefined' ? Element : Object",
            required: !1
          }
        }
      },
      required: !1,
      description: "Reference to the navigation bar HTML element"
    }
  }
};