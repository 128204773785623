import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertyNames from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-names";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import merge from "lodash/merge";
import memoize from "lodash/memoize";
import { sha256 } from "js-sha256";
import { log } from "@jutro/logger";
import ImplDescriptor from "./ImplDescriptor";
var Contract = function () {
  function Contract(specification) {
    var _context,
      _this = this;
    _classCallCheck(this, Contract);
    for (var _len = arguments.length, extended = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) extended[_key - 1] = arguments[_key];
    this.spec = merge.apply(void 0, _concatInstanceProperty(_context = _toConsumableArray(_mapInstanceProperty(extended).call(extended, function (c) {
      return c.spec;
    }))).call(_context, [specification])), this.hashFunc = memoize(function () {
      var _context2,
        keyTar = _sortInstanceProperty(_context2 = _Object$keys(_this.spec)).call(_context2).join("");
      return sha256(keyTar);
    });
  }
  return _createClass(Contract, [{
    key: "implementedBy",
    value: function (implementor) {
      var _this2 = this,
        descriptor = new ImplDescriptor(implementor),
        adheres = !0,
        members = descriptor.getMembers();
      return _Object$getOwnPropertyNames(this.spec).forEach(function (specKey) {
        var _context3,
          _context4,
          member = descriptor.getMember(specKey);
        member && _typeof(member) === _this2.spec[specKey] || (log.warning(_includesInstanceProperty(members).call(members, specKey) ? _concatInstanceProperty(_context3 = _concatInstanceProperty(_context4 = "Invalid type for ".concat(specKey, ": '")).call(_context4, _typeof(member), "' is not of type '")).call(_context3, _this2.spec[specKey], "'") : "Missing member: ".concat(specKey)), adheres = !1);
      }), adheres;
    }
  }, {
    key: "hash",
    value: function () {
      return this.hashFunc();
    }
  }, {
    key: "toString",
    value: function () {
      return "contract {".concat(_Object$keys(this.spec).join(","), "}");
    }
  }], [{
    key: "specify",
    value: function (specification) {
      return new Contract(specification);
    }
  }]), Contract;
}();
export { Contract as default };