import { defineMessages } from "react-intl";
export var messages = defineMessages({
  addItem: {
    id: "jutro-components.widgets.inputs.LookupField.addItem",
    defaultMessage: "Add an Item"
  },
  unknownType: {
    id: "jutro-components.widgets.inputs.LookupField.unknownType",
    defaultMessage: "Unknown"
  },
  recentlyViewed: {
    id: "jutro-components.widgets.inputs.LookupField.recentlyViewed",
    defaultMessage: "Recently Viewed"
  }
});