import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import { useCallback, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { getConfigValue } from "@jutro/config";
import { warning } from "@jutro/logger";
import { getNativeLocaleLanguage } from "@jutro/locale";
export var getAvailableLanguages = function (availableLanguagesProp, availableLanguagesContext) {
  return getConfigValue("JUTRO_AUTH_ENABLED", !1) && getConfigValue("JUTRO_TSM_ENABLED", !1) ? (warning('Reading language list from String Manager service. Ignoring "availableLanguageValues" property in app config'), availableLanguagesContext || []) : availableLanguagesProp || availableLanguagesContext || [];
};
export var useI18nDropdown = function (_ref) {
  var value = _ref.value,
    availableValues = _ref.availableValues,
    renderValueLabel = _ref.renderValueLabel,
    onValueChange = _ref.onValueChange,
    skipPropagation = _ref.skipPropagation,
    serviceCallback = _ref.serviceCallback,
    _useState = useState(value),
    _useState2 = _slicedToArray(_useState, 2),
    activeValue = _useState2[0],
    setActiveValue = _useState2[1],
    resolvedValue = useMemo(function () {
      return skipPropagation ? value : activeValue || value;
    }, [activeValue, value, skipPropagation]),
    values = useMemo(function () {
      return isEmpty(availableValues) ? [value] : availableValues;
    }, [availableValues, value]);
  return {
    availableValues: useMemo(function () {
      var _context, _context2;
      return _mapInstanceProperty(_context = _filterInstanceProperty(_context2 = _mapInstanceProperty(values).call(values, function (val) {
        var label = (renderValueLabel || getNativeLocaleLanguage)(val.toLowerCase());
        return label ? {
          value: val,
          label: label
        } : null;
      })).call(_context2, Boolean)).call(_context, function (option) {
        var _ref2 = option || {},
          optionValue = _ref2.value;
        return {
          displayName: _ref2.label,
          id: optionValue
        };
      });
    }, [renderValueLabel, values]),
    onValueChange: useCallback(function (newValue) {
      onValueChange && onValueChange(newValue), setActiveValue(newValue), skipPropagation || null == serviceCallback || serviceCallback(newValue);
    }, [onValueChange, serviceCallback, skipPropagation]),
    value: resolvedValue
  };
};