import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
var click = function (e, columnId) {
    "selection" !== columnId && e.target.click();
  },
  isEditableFieldTarget = function (e) {
    return "textarea" === e.target.type || "text" === e.target.type || "number" === e.target.type;
  };
export var onKeyDownHandler = function (focused, tableId, rtState) {
  return function (e) {
    var focusedColumn = focused.column,
      focusedRow = focused.row,
      columns = rtState.allVisibleColumns,
      endRow = rtState.endRow;
    switch (e.key) {
      case "ArrowLeft":
        if (isEditableFieldTarget(e)) return;
        focusedColumn > 0 && (focusedColumn -= 1);
        break;
      case "ArrowRight":
        if (isEditableFieldTarget(e)) return;
        focusedColumn < columns.length - 1 && (focusedColumn += 1);
        break;
      case "ArrowUp":
        if (isEditableFieldTarget(e)) return;
        focusedRow > 0 && (focusedRow -= 1);
        break;
      case "ArrowDown":
        if (isEditableFieldTarget(e)) return;
        focusedRow < endRow && (focusedRow += 1);
        break;
      case "Home":
        if (isEditableFieldTarget(e)) return;
        e.ctrlKey && 0 !== focusedRow && (focusedRow = 0), 0 !== focusedColumn && (focusedColumn = 0);
        break;
      case "End":
        if (isEditableFieldTarget(e)) return;
        e.ctrlKey && focusedRow !== endRow && (focusedRow = endRow), focusedColumn !== columns.length - 1 && (focusedColumn = columns.length - 1);
        break;
      case "PageUp":
        0 !== focusedRow && (focusedRow = 0);
        break;
      case "PageDown":
        focusedRow !== endRow && (focusedRow = endRow);
        break;
      case "Enter":
        if (e.altKey) return;
        click(e, columns[focusedColumn].id);
        break;
      case " ":
        return void (e.target === document.body && e.preventDefault());
      default:
        return;
    }
    !function (e, focusedRow, id, tableId) {
      var _context, _context2;
      e.preventDefault();
      var nodes = document.querySelectorAll(_concatInstanceProperty(_context = _concatInstanceProperty(_context2 = '[data-row="'.concat(focusedRow, '"][data-col="')).call(_context2, id, '"][data-parent="')).call(_context, tableId, '"]'));
      if (nodes[0]) {
        var focusableElement = nodes[0].querySelectorAll('[tabIndex]:not([tabIndex="-1"])')[0] || nodes[0].querySelectorAll("label")[0];
        focusableElement ? focusableElement.focus() : nodes[0].focus();
      }
    }(e, focusedRow, columns[focusedColumn].id, tableId);
  };
};
export var onKeyUpHandler = function (focused, tableId, rtState) {
  return function (e) {
    var focusedColumn = focused.column,
      columns = rtState.allVisibleColumns,
      sortable = rtState.sortable;
    if (" " === e.key) sortable && click(e, columns[focusedColumn].id);
  };
};