import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
export function mapGooglePlacesAddressToDTO(addressComponents) {
  var streetNumber,
    streetName,
    DTOAddress = {};
  return addressComponents && addressComponents.forEach(function (addressComponent) {
    var _context;
    switch (addressComponent.types.join()) {
      case "country":
        DTOAddress.country = addressComponent.short_name;
        break;
      case "street_number":
        streetNumber = addressComponent.long_name;
        break;
      case "route":
        streetName = addressComponent.long_name;
        break;
      case "postal_code":
        DTOAddress.postalCode = addressComponent.short_name;
        break;
      case "country,political":
        DTOAddress.country = addressComponent.long_name, DTOAddress.countryCode = addressComponent.short_name;
        break;
      case "administrative_area_level_1,political":
        DTOAddress.state = addressComponent.short_name;
        break;
      case "locality,political":
      case "sublocality_level_1,sublocality,political":
        DTOAddress.city = addressComponent.long_name;
        break;
      case "neighborhood,political":
        DTOAddress.addressLine2 = addressComponent.long_name;
    }
    streetNumber && streetName ? DTOAddress.addressLine1 = _concatInstanceProperty(_context = "".concat(streetNumber, " ")).call(_context, streetName) : streetName ? DTOAddress.addressLine1 = streetName : streetNumber && (DTOAddress.addressLine1 = streetNumber);
  }), DTOAddress;
}