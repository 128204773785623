import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import merge from "lodash/merge";
import { log } from "@jutro/logger";
import Options from "./Options";
var Registration = function () {
    function Registration(id, Service, lazy) {
      _classCallCheck(this, Registration), this.id = id, this.name = Service.name || Service.constructor.name, this.attributes = {}, lazy ? this.init = Service : this.service = new Service();
    }
    return _createClass(Registration, [{
      key: "associate",
      value: function (attributes) {
        this.attributes = merge(this.attributes, attributes);
      }
    }, {
      key: "getMetadata",
      value: function () {
        return merge({
          id: this.id,
          name: this.name
        }, this.attributes);
      }
    }, {
      key: "isLazy",
      value: function () {
        return !!this.init;
      }
    }, {
      key: "initializeService",
      value: function () {
        if (this.init) {
          var Service = this.init;
          this.service = new Service(), this.init = null;
        } else log.warning("Service is already instantiated");
      }
    }, {
      key: "getService",
      value: function () {
        return this.isLazy() && this.initializeService(), this.service;
      }
    }]), Registration;
  }(),
  ServiceRegistry = function () {
    function ServiceRegistry() {
      _classCallCheck(this, ServiceRegistry), this.registrations = {}, this.implementations = {}, this.warnings = new _Set();
    }
    return _createClass(ServiceRegistry, [{
      key: "register",
      value: function (id, Service) {
        var _this = this,
          options = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
        if (this.registrations[id]) log.warning("Service with id '".concat(id, "' has already been registered"));else {
          var registration = new Registration(id, Service, options.lazy),
            opts = new Options(options);
          opts.onOption("implements", function (implemented) {
            (Array.isArray(implemented) ? implemented : [implemented]).forEach(function (c) {
              var _context;
              if (!c.implementedBy(Service)) throw new Error(_concatInstanceProperty(_context = "Service ".concat(Service.name || Service.constructor.name, " does not implement: ")).call(_context, c));
              var key = c.hash();
              _this.implementations[key] || (_this.implementations[key] = []), _this.implementations[key].push(registration);
            });
          }), opts.onOption("attributes", function (attributes) {
            return registration.associate(attributes);
          }), this.registrations[id] = registration;
        }
      }
    }, {
      key: "hasRegistration",
      value: function (id) {
        return !!this.registrations[id];
      }
    }, {
      key: "getRegistration",
      value: function (id) {
        return this.registrations[id];
      }
    }, {
      key: "getRegistrationIds",
      value: function () {
        return _Object$keys(this.registrations);
      }
    }, {
      key: "getImplementorsOf",
      value: function (contract) {
        var implementors = this.implementations[contract.hash()];
        if (!implementors || implementors.length <= 0) {
          var message = "No implementors found for: ".concat(contract);
          this.warnings.has(message) || (this.warnings.add(message), log.warning(message));
        }
        return implementors || [];
      }
    }]), ServiceRegistry;
  }();
export { ServiceRegistry as default };