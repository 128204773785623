import React from "react";
import PropTypes from "prop-types";
import { uniqueInnerId } from "@jutro/platform";
import styles from "./Pagination.module.css";
import { PageSizes } from "./PageSizes";
import { PageNumbers } from "./PageNumbers";
export var Pagination = function (_ref) {
  var id = _ref.id,
    page = _ref.page,
    numberOfRows = _ref.numberOfRows,
    pageSize = _ref.pageSize,
    onPageChange = _ref.onPageChange,
    pageSizeSelectedOption = _ref.pageSizeSelectedOption,
    pageSizeOptions = _ref.pageSizeOptions,
    onSelectPageSizeChange = _ref.onSelectPageSizeChange,
    canNext = _ref.canNext,
    canPrevious = _ref.canPrevious,
    paginationSelectId = uniqueInnerId(id, "paginationSelectId").paginationSelectId,
    paginationId = "".concat(paginationSelectId, "_pageNumberSelect");
  return React.createElement("div", {
    id: id,
    className: styles.pagination,
    role: "navigation",
    "aria-label": "pagination"
  }, React.createElement(PageSizes, {
    id: paginationId,
    numberOfRows: numberOfRows,
    onSelectPageSizeChange: onSelectPageSizeChange,
    pageSize: pageSize,
    pageSizeOptions: pageSizeOptions,
    pageSizeSelectedOption: pageSizeSelectedOption
  }), React.createElement(PageNumbers, {
    id: paginationId,
    numberOfRows: numberOfRows,
    pageSize: pageSize,
    onPageChange: onPageChange,
    page: page,
    canPrevious: canPrevious,
    canNext: canNext
  }));
};
Pagination.propTypes = {
  id: PropTypes.string.isRequired,
  canNext: PropTypes.bool,
  canPrevious: PropTypes.bool,
  pageSizeOptions: PropTypes.array.isRequired,
  page: PropTypes.number,
  numberOfRows: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  pageSizeSelectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelectPageSizeChange: PropTypes.func
}, Pagination.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "Pagination",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    canNext: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the `nextNavButton` button is rendered"
    },
    canPrevious: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the `prevNavButton` button is rendered"
    },
    pageSizeOptions: {
      type: {
        name: "array"
      },
      required: !0,
      description: "List of page size options"
    },
    page: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Current page"
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: !0,
      description: "The total number of rows"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Number of items per page"
    },
    onPageChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle page change"
    },
    pageSizeSelectedOption: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Number of records per page"
    },
    onSelectPageSizeChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle page size change"
    }
  }
}, Pagination.__docgenInfo = {
  componentName: "Pagination",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "Pagination",
  methods: [],
  actualName: "Pagination",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    canNext: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the `nextNavButton` button is rendered"
    },
    canPrevious: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the `prevNavButton` button is rendered"
    },
    pageSizeOptions: {
      type: {
        name: "array"
      },
      required: !0,
      description: "List of page size options"
    },
    page: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Current page"
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: !0,
      description: "The total number of rows"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Number of items per page"
    },
    onPageChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle page change"
    },
    pageSizeSelectedOption: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Number of records per page"
    },
    onSelectPageSizeChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle page size change"
    }
  }
};