import { createContext, useContext } from "react";
import noop from "lodash/noop";
import styles from "./DataTable.module.css";
export var TableContext = createContext({
  styles: styles,
  columns: {
    configurable: !0,
    config: [],
    setConfig: noop
  }
});
export var useTable = function () {
  return useContext(TableContext);
};