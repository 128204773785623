import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["UNKNOWN_ERROR"];
import { getConfigValue } from "@jutro/config";
import { logDeprecationMessage } from "@jutro/platform";
import loadMixpanel from "../mixpanel";
import { subscribe } from "../EventProvider";
import JUTRO_TOPICS from "../jutroTopics";
import WhitelistProvider from "./WhitelistProvider";
import DictionaryProvider from "./DictionaryProvider";
export default function () {
  var trackingConfig = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {
    whitelistConfig: {},
    dictionaryConfig: {}
  };
  logDeprecationMessage("MixPanel");
  var mixpanel = loadMixpanel(getConfigValue("MIXPANEL_TRACKING_ID")),
    whitelist = WhitelistProvider.create(trackingConfig.whitelistConfig),
    translate = DictionaryProvider.create(trackingConfig.dictionaryConfig),
    UNKNOWN_ERROR = JUTRO_TOPICS.UNKNOWN_ERROR,
    REST_JUTRO_TOPICS = _objectWithoutProperties(JUTRO_TOPICS, _excluded);
  subscribe(UNKNOWN_ERROR, function (event) {
    return mixpanel.track(UNKNOWN_ERROR, event.error);
  }), subscribe(REST_JUTRO_TOPICS, function (event, topic) {
    var transformedEvent = translate(whitelist(event));
    mixpanel.track(topic, transformedEvent);
  });
}