import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["onValueChange", "value", "selectYearFirst"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useCallback, useMemo, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { DATA_TYPE_OBJECT, intlMessageShape } from "@jutro/prop-types";
import { LocaleContext, parseDateFromDataType } from "@jutro/locale";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import isUndefined from "lodash/isUndefined";
import defaultLocaleObject from "date-fns/locale/en-US";
import { DateField } from "../DateField/DateField";
import { MonthYearFieldCustomHeader } from "./MonthYearFieldCustomHeader";
import { getMonthDatePatternFromLocale } from "./utils";
import { messages } from "./MonthYearField.messages";
var monthPickerProps = {
    showMonthYearPicker: !0,
    showFourColumnMonthYearPicker: !0
  },
  yearPickerProps = {
    showYearPicker: !0,
    todayButtonText: messages.currentYear
  };
export var MonthYearField = function (props) {
  var onValueChange = props.onValueChange,
    value = props.value,
    selectYearFirst = props.selectYearFirst,
    other = _objectWithoutProperties(props, _excluded),
    _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    monthOrYear = _useState2[0],
    setMonthOrYear = _useState2[1],
    _useState3 = useState(!selectYearFirst),
    _useState4 = _slicedToArray(_useState3, 2),
    monthPickerActive = _useState4[0],
    setMonthPickerActive = _useState4[1],
    dateFieldRef = useRef(null),
    dateLocale = useContext(LocaleContext).dateLocale,
    inputFormat = useMemo(function () {
      return getMonthDatePatternFromLocale(isEmpty(dateLocale) ? defaultLocaleObject : dateLocale);
    }, [dateLocale]),
    valueYear = useMemo(function () {
      return value && parseDateFromDataType(value).getFullYear();
    }, [value]),
    handleValueChange = useCallback(function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      null == onValueChange || onValueChange.apply(void 0, args), setMonthOrYear(void 0);
    }, [onValueChange]),
    handleOnChange = useCallback(function (date) {
      var dateFieldInternal = dateFieldRef.current;
      dateFieldInternal && isUndefined(monthOrYear) && (null == dateFieldInternal || dateFieldInternal.handleDateChange(date));
    }, [monthOrYear]),
    handleOnSelect = useCallback(function (date) {
      var dateFieldInternal = dateFieldRef.current;
      if (dateFieldInternal && date) {
        if (isUndefined(monthOrYear)) return setMonthOrYear(monthPickerActive ? date.getMonth() : date.getFullYear()), void setMonthPickerActive(!monthPickerActive);
        var year = monthPickerActive ? monthOrYear : date.getFullYear(),
          month = monthPickerActive ? date.getMonth() : monthOrYear;
        null == dateFieldInternal || dateFieldInternal.handleDateChange(parseDateFromDataType({
          month: month,
          year: year
        })), setMonthPickerActive(!selectYearFirst);
      }
    }, [monthOrYear, monthPickerActive, selectYearFirst]),
    year = monthPickerActive ? monthOrYear : void 0,
    selectedYear = null != year ? year : valueYear,
    pickerSwitchingProps = _objectSpread(_objectSpread({}, monthPickerActive ? monthPickerProps : yearPickerProps), {}, {
      shouldCloseOnSelect: !isUndefined(monthOrYear)
    }),
    renderCustomHeader = useCallback(function (headerProps) {
      return React.createElement(MonthYearFieldCustomHeader, _extends({}, headerProps, {
        selectedYear: selectedYear,
        monthPickerActive: monthPickerActive,
        onClick: function () {
          monthPickerActive && (setMonthOrYear(void 0), setMonthPickerActive(!1));
        }
      }));
    }, [monthPickerActive, selectedYear]);
  return React.createElement(DateField, _extends({
    ref: dateFieldRef
  }, other, pickerSwitchingProps, {
    value: value,
    inputFormat: inputFormat,
    renderCustomHeader: renderCustomHeader,
    onValueChange: handleValueChange,
    onChange: handleOnChange,
    onSelect: handleOnSelect
  }));
};
MonthYearField.displayName = "MonthYearField", MonthYearField.propTypes = _objectSpread(_objectSpread({}, DateField.propTypes), {}, {
  label: intlMessageShape.isRequired,
  selectYearFirst: PropTypes.bool
}), MonthYearField.defaultProps = _objectSpread(_objectSpread({}, omit(DateField.defaultProps, "label")), {}, {
  dataType: DATA_TYPE_OBJECT
}), MonthYearField.__docgenInfo = {
  description: "@metadataType field",
  methods: [],
  displayName: "MonthYearField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      required: !1
    },
    label: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Label text to display or use for screen reader"
    },
    selectYearFirst: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, user will be first asked to pick year then month"
    }
  },
  composes: ["../DateField/DateField"]
}, MonthYearField.__docgenInfo = {
  componentName: "MonthYearField",
  packageName: "@jutro/components",
  description: "",
  displayName: "MonthYearField",
  methods: [],
  actualName: "MonthYearField",
  metadataType: "field",
  props: {
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Label text to display or use for screen reader"
    },
    selectYearFirst: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, user will be first asked to pick year then month"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["../DateField/DateField"]
};