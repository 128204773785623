import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";
import { isEmptyValue, isNilValue } from "@jutro/data";
import { availableValueObjectShape, dataTypeShape, DATA_TYPE_STRING, availableValuesIdDisplayNameObject, availableValuesCodeNameObject } from "@jutro/prop-types";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { GenericSelectControl, genericSelectInternalClassNamesShape } from "../GenericSelectControl/GenericSelectControl";
import { getOptionCode, getOptionName } from "../availableValues";
import { messages } from "./DropdownSelectField.messages";
var shouldRenderDetails = function (value) {
    return "other" === (null == value ? void 0 : value.toLowerCase());
  },
  valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, availableValueObjectShape]),
  DropdownOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesIdDisplayNameObject), {}, {
    isDisabled: PropTypes.bool
  })),
  DropdownCodeOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesCodeNameObject), {}, {
    isDisabled: PropTypes.bool
  })),
  handleOnClick = function (e) {
    return e.stopPropagation();
  };
export var DropdownSelectField = function (_FieldComponent) {
  _inherits(DropdownSelectField, FieldComponent);
  var _super = _createSuper(DropdownSelectField);
  function DropdownSelectField() {
    var _context, _this;
    _classCallCheck(this, DropdownSelectField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "handleOnChange", function (value) {
      _this.beforeValue = _this.props.value, _this.notifyChange(_this.getNewValue(value));
    }), _this;
  }
  return _createClass(DropdownSelectField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(DropdownSelectField.prototype), "render", this).call(this);
    }
  }, {
    key: "getNewValue",
    value: function (_ref) {
      var id = _ref.id,
        value = _ref.value;
      if ("placeholder" !== id) {
        var _this$props = this.props,
          dataType = _this$props.dataType,
          availableValues = _this$props.availableValues;
        if (dataType === DATA_TYPE_STRING) return value;
        var val = _findInstanceProperty(availableValues).call(availableValues, function (availableValue) {
          return getOptionCode(availableValue).toString() === value;
        });
        return isNilValue(val) ? val : isNilValue(val.name) ? {
          id: val.id,
          displayName: this.translator(val.displayName)
        } : {
          code: val.code,
          name: this.translator(val.name)
        };
      }
    }
  }, {
    key: "renderValue",
    get: function () {
      var value = this.props.value;
      return isEmptyValue(value) ? "" : value instanceof Object ? (value.code || value.id).toString() || "" : value.toString();
    }
  }, {
    key: "placeholderText",
    get: function () {
      var _this$props2 = this.props,
        placeholder = _this$props2.placeholder,
        label = _this$props2.label,
        translator = this.translator;
      return placeholder instanceof Object ? translator(placeholder) : placeholder.replace("{LABEL}", translator(label));
    }
  }, {
    key: "options",
    get: function () {
      var _context2,
        _this$props3 = this.props,
        value = _this$props3.value,
        availableValues = _this$props3.availableValues,
        alwaysShowPlaceholder = _this$props3.alwaysShowPlaceholder,
        translator = this.translator,
        options = _filterInstanceProperty(_context2 = _mapInstanceProperty(availableValues).call(availableValues, function (option) {
          var visible = option.visible,
            optionCode = getOptionCode(option),
            optionName = getOptionName(option);
          if (isBoolean(optionCode)) return !1;
          if (!1 === visible) return !1;
          var optionValue = isNumber(optionCode) ? optionCode.toString() : optionCode;
          return !!optionValue && {
            label: translator(optionName),
            value: optionValue,
            isDisabled: option.isDisabled
          };
        })).call(_context2, Boolean),
        hasPlaceholder = availableValues.some(function (_ref2) {
          var code = _ref2.code;
          return isEmptyValue(code);
        }) && availableValues.some(function (_ref3) {
          var id = _ref3.id;
          return isEmptyValue(id);
        });
      return !isEmptyValue(value) && !alwaysShowPlaceholder || hasPlaceholder || options.unshift({
        label: this.placeholderText,
        id: "placeholder"
      }), options;
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _ref4 = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        isValid = _ref4.isValid,
        labelId = _ref4.labelId,
        disabled = breakpointProps.disabled,
        required = breakpointProps.required,
        children = breakpointProps.children,
        internalClassNames = breakpointProps.internalClassNames,
        defaultValue = breakpointProps.defaultValue,
        searchable = breakpointProps.searchable,
        usePortal = breakpointProps.usePortal,
        isInitiallyOpen = breakpointProps.isInitiallyOpen,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        errorMessage = breakpointProps.errorMessage,
        registerValidation = breakpointProps.registerValidation,
        components = breakpointProps.components,
        getOptionLabel = breakpointProps.getOptionLabel,
        options = this.options,
        renderValue = this.renderValue,
        fieldUniqueId = this.fieldUniqueId,
        defaultValueStringified = defaultValue ? defaultValue.toString() : null,
        selectDefaultValue = defaultValueStringified ? _findInstanceProperty(options).call(options, function (el) {
          return el.value === defaultValueStringified;
        }) : null,
        detail = shouldRenderDetails(renderValue) && children,
        renderValueForComponentValue = renderValue instanceof Object ? renderValue.id || renderValue.code : renderValue,
        componentValue = renderValueForComponentValue ? _filterInstanceProperty(options).call(options, function (el) {
          return el.value === renderValueForComponentValue;
        }) : null;
      return React.createElement("div", {
        onClick: handleOnClick,
        role: "presentation"
      }, React.createElement(GenericSelectControl, {
        id: id,
        fieldUniqueId: fieldUniqueId,
        testId: testId,
        availableValues: options,
        internalClassNames: internalClassNames,
        defaultValue: selectDefaultValue,
        disabled: disabled,
        getOptionLabel: null != getOptionLabel ? getOptionLabel : this.getOptionLabel,
        getOptionValue: this.getOptionValue,
        labelId: labelId,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onValueChange: this.handleOnChange,
        placeholder: this.placeholderText,
        required: Array.isArray(required) ? required[0] : required,
        searchable: searchable,
        value: componentValue,
        valid: isValid,
        usePortal: usePortal,
        isInitiallyOpen: isInitiallyOpen,
        errorMessage: errorMessage,
        registerValidation: registerValidation,
        components: components
      }), detail && React.createElement("div", null, detail));
    }
  }, {
    key: "getOptionLabel",
    value: function (_ref5) {
      return _ref5.label;
    }
  }, {
    key: "getOptionValue",
    value: function (_ref6) {
      return _ref6.value;
    }
  }], [{
    key: "isContentVisible",
    value: function (value) {
      return shouldRenderDetails(value);
    }
  }]), DropdownSelectField;
}();
_defineProperty(DropdownSelectField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: valuePropType,
  defaultValue: valuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(PropTypes.oneOfType([DropdownOptionShape, DropdownCodeOptionShape])),
  children: PropTypes.node,
  internalClassNames: genericSelectInternalClassNamesShape,
  alwaysShowPlaceholder: PropTypes.bool,
  searchable: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  usePortal: PropTypes.bool,
  components: PropTypes.object,
  getOptionLabel: PropTypes.func
})), _defineProperty(DropdownSelectField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  availableValues: [],
  placeholder: messages.defaultPlaceholder,
  dataType: DATA_TYPE_STRING,
  searchable: !1
})), _defineProperty(DropdownSelectField, "contextType", FieldComponent.contextType), DropdownSelectField.__docgenInfo = {
  description: "Renders a dropdown where the user can select one of the values. You specify the allowed values and the\naction using props.\n\n@typedef {typeof DropdownSelectField.propTypes} DropdownSelectFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<DropdownSelectFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "isContentVisible",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "value",
      type: null
    }],
    returns: null
  }, {
    name: "getNewValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ id, value }",
      type: null
    }],
    returns: null
  }, {
    name: "handleOnChange",
    docblock: "Handler function for when the select field value is changed\n@param {object} value - value that is passed when a change occurs",
    modifiers: [],
    params: [{
      name: "value",
      description: "value that is passed when a change occurs",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handler function for when the select field value is changed"
  }, {
    name: "renderValue",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "placeholderText",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "options",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} param1\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "{ isValid, labelId }"
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "getOptionLabel",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ label }",
      type: null
    }],
    returns: null
  }, {
    name: "getOptionValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ value }",
      type: null
    }],
    returns: null
  }],
  displayName: "DropdownSelectField",
  props: {
    availableValues: {
      defaultValue: {
        value: "[]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "DropdownOptionShape"
          }, {
            name: "custom",
            raw: "DropdownCodeOptionShape"
          }]
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code' and 'name'"
    },
    placeholder: {
      defaultValue: {
        value: "messages.defaultPlaceholder",
        computed: !0
      },
      required: !1
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the value"
    },
    searchable: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Allows you to type in the drop-down field to find options."
    },
    value: {
      type: {
        name: "custom",
        raw: "valuePropType"
      },
      required: !1,
      description: "Selected item value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "valuePropType"
      },
      required: !1,
      description: "Default value to set if there is no value present"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to render when the 'other' value is selected, as processed by the `MetadataContent` component\n(won't be rendered when value is not 'other')"
    },
    internalClassNames: {
      type: {
        name: "custom",
        raw: "genericSelectInternalClassNamesShape"
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of this component's styles"
    },
    alwaysShowPlaceholder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When set to `true`, the list of options will include the placeholder text and it will be the first option."
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines whether the drop-down list is initially opened"
    },
    usePortal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent"
    },
    components: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Map of customized components to display parts of dropdown (value, options...)"
    },
    getOptionLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to generate option label based on its value"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, DropdownSelectField.__docgenInfo = {
  componentName: "DropdownSelectField",
  packageName: "@jutro/components",
  description: "Renders a dropdown where the user can select one of the values. You specify the allowed values and the\naction using props.",
  displayName: "DropdownSelectField",
  methods: [{
    name: "isContentVisible",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "value",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getNewValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ id, value }",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleOnChange",
    docblock: "Handler function for when the select field value is changed\n@param {object} value - value that is passed when a change occurs",
    modifiers: [],
    params: [{
      name: "value",
      description: "value that is passed when a change occurs",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Handler function for when the select field value is changed"
  }, {
    name: "renderValue",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "placeholderText",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "options",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} param1\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "{ isValid, labelId }"
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "getOptionLabel",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ label }",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getOptionValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ value }",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "DropdownSelectField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "valuePropType"
      },
      required: !1,
      description: "Selected item value"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "valuePropType"
      },
      required: !1,
      description: "Default value to set if there is no value present"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the value",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "DropdownOptionShape"
          }, {
            name: "custom",
            raw: "DropdownCodeOptionShape"
          }]
        }
      },
      required: !1,
      description: "Array of choice objects to display; choice objects contain 'code' and 'name'",
      defaultValue: {
        value: "[]",
        computed: !1
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Node to render when the 'other' value is selected, as processed by the `MetadataContent` component\n(won't be rendered when value is not 'other')"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          control: {
            name: "string",
            description: "CSS class name for the component",
            required: !1
          },
          controlFocused: {
            name: "string",
            description: "CSS class name for the component when focused",
            required: !1
          },
          menuList: {
            name: "string",
            description: "CSS class name for the list of options",
            required: !1
          },
          option: {
            name: "string",
            description: "CSS class name for each option",
            required: !1
          },
          selectIcon: {
            name: "string",
            description: "CSS class name for icon in the select",
            required: !1
          },
          optionDisabled: {
            name: "string",
            description: "CSS class name for disabled option",
            required: !1
          },
          focusedDisabled: {
            name: "string",
            description: "CSS class name for disabled option when focused",
            required: !1
          }
        }
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of this component's styles"
    },
    alwaysShowPlaceholder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When set to `true`, the list of options will include the placeholder text and it will be the first option."
    },
    searchable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Allows you to type in the drop-down field to find options.",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Determines whether the drop-down list is initially opened"
    },
    usePortal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent"
    },
    components: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Map of customized components to display parts of dropdown (value, options...)"
    },
    getOptionLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to generate option label based on its value"
    },
    placeholder: {
      defaultValue: {
        value: "messages.defaultPlaceholder",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};