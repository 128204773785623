import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import { FieldComponentValidationImplementation } from "../FieldComponent/FieldComponentValidationImplementation";
export var FileUploadFieldValidationImplementation = function (_FieldComponentValida) {
  _inherits(FileUploadFieldValidationImplementation, FieldComponentValidationImplementation);
  var _super = _createSuper(FileUploadFieldValidationImplementation);
  function FileUploadFieldValidationImplementation() {
    return _classCallCheck(this, FileUploadFieldValidationImplementation), _super.apply(this, arguments);
  }
  return _createClass(FileUploadFieldValidationImplementation, [{
    key: "bind",
    value: function (entityToBind) {
      var _this = this,
        superBoundMethods = _get(_getPrototypeOf(FileUploadFieldValidationImplementation.prototype), "bind", this).call(this, entityToBind),
        methods = ["getValidationMessages", "handleChange", "handleClearValue"];
      return _reduceInstanceProperty(methods).call(methods, function (acc, method) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, method, _this[method].bind(entityToBind)));
      }, superBoundMethods);
    }
  }, {
    key: "getValidationMessages",
    value: function (progressErrorMessage, isFileValidationMessage) {
      if (!this.props.value || isFileValidationMessage) return _get(_getPrototypeOf(FileUploadFieldValidationImplementation.prototype), "getValidationMessages", this).call(this);
    }
  }, {
    key: "handleChange",
    value: function (e, isMultipleFileUpload) {
      var _e$dataTransfer$files, _e$dataTransfer;
      if (this.pristine = !1, this.focusPristine = !1, isMultipleFileUpload) this.notifyChange(e);else {
        var file = null !== (_e$dataTransfer$files = null === (_e$dataTransfer = e.dataTransfer) || void 0 === _e$dataTransfer ? void 0 : _e$dataTransfer.files[0]) && void 0 !== _e$dataTransfer$files ? _e$dataTransfer$files : e.target.files[0];
        e.target.value = null, this.notifyChange(file);
      }
    }
  }, {
    key: "handleClearValue",
    value: function () {
      this.notifyChange(void 0);
    }
  }]), FileUploadFieldValidationImplementation;
}();