import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Symbol$toPrimitive from "@babel/runtime-corejs3/core-js-stable/symbol/to-primitive";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
function _toPropertyKey(arg) {
  var key = function (input, hint) {
    if ("object" !== _typeof(input) || null === input) return input;
    var prim = input[_Symbol$toPrimitive];
    if (void 0 !== prim) {
      var res = prim.call(input, hint || "default");
      if ("object" !== _typeof(res)) return res;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return ("string" === hint ? String : Number)(input);
  }(arg, "string");
  return "symbol" === _typeof(key) ? key : String(key);
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { warning, error } from "@jutro/logger";
import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import { wrapWithIsRequired } from "./ComponentPropTypes";
import { getDisplayName } from "./getDisplayName";
export var JUTRO_WARNING_PREFIX = "JUTRO DEPRECATION WARNING";
var warned = {};
export var oneOfWithDeprecations = function (allowedItems, deprecatedItems, version) {
  return wrapWithIsRequired(function (props, propName, componentName) {
    var _context5, _context6;
    if (_indexOfInstanceProperty(deprecatedItems).call(deprecatedItems, props[propName]) > -1) {
      var _context,
        _context2,
        _context3,
        _context4,
        message = _concatInstanceProperty(_context = _concatInstanceProperty(_context2 = _concatInstanceProperty(_context3 = _concatInstanceProperty(_context4 = "Value '".concat(props[propName], "' assigned to ")).call(_context4, propName, " is deprecated for ")).call(_context3, componentName, ". Please consider to use one of allowed values: '")).call(_context2, allowedItems.join(", "), "' since Jutro v")).call(_context, version);
      printDeprecationWarning({
        propName: propName,
        componentName: componentName,
        message: message
      });
    }
    for (var _len = arguments.length, rest = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) rest[_key - 3] = arguments[_key];
    return PropTypes.oneOf(_concatInstanceProperty(_context5 = []).call(_context5, _toConsumableArray(deprecatedItems), _toConsumableArray(allowedItems))).apply(void 0, _concatInstanceProperty(_context6 = [props, propName, componentName]).call(_context6, rest));
  });
};
export function deprecated(validator, droppedTargetVersion, additionalInfo) {
  for (var _len2 = arguments.length, ignoredValues = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) ignoredValues[_key2 - 3] = arguments[_key2];
  return function (props, propName, componentName, location, propFullName) {
    var _props$noDeprecationW,
      _context11,
      componentNameSafe = componentName || "<<anonymous>>",
      propFullNameSafe = propFullName || propName,
      propValue = propName ? props[propName] : void 0,
      dropTargetVersionMessage = droppedTargetVersion ? 'It will be removed in the "'.concat(droppedTargetVersion, '" Jutro release.') : "It will remain in the Jutro library until no longer in use which will be determined by the usage analytics.",
      additionalInfoFormatted = additionalInfo ? " ".concat(additionalInfo) : "";
    if (propValue && (null == ignoredValues || !_includesInstanceProperty(ignoredValues).call(ignoredValues, propValue)) && (null === (_props$noDeprecationW = props.noDeprecationWarnings) || void 0 === _props$noDeprecationW || !_includesInstanceProperty(_props$noDeprecationW).call(_props$noDeprecationW, propName))) {
      var _context7,
        _context8,
        _context9,
        _context10,
        message = _concatInstanceProperty(_context7 = _concatInstanceProperty(_context8 = _concatInstanceProperty(_context9 = _concatInstanceProperty(_context10 = " The ".concat(location, ' "')).call(_context10, propFullNameSafe, '" of "')).call(_context9, componentNameSafe, '" is deprecated.')).call(_context8, additionalInfoFormatted, " ")).call(_context7, dropTargetVersionMessage);
      printDeprecationWarning({
        propName: propName,
        componentName: componentNameSafe,
        message: message
      });
    }
    for (var _len3 = arguments.length, rest = new Array(_len3 > 5 ? _len3 - 5 : 0), _key3 = 5; _key3 < _len3; _key3++) rest[_key3 - 5] = arguments[_key3];
    return validator.apply(void 0, _concatInstanceProperty(_context11 = [props, propName, componentName, location, propFullName]).call(_context11, rest));
  };
}
export function deprecateAll(validators, droppedTargetVersion, additionalInfo) {
  for (var _context12, _len4 = arguments.length, ignoredValues = new Array(_len4 > 3 ? _len4 - 3 : 0), _key4 = 3; _key4 < _len4; _key4++) ignoredValues[_key4 - 3] = arguments[_key4];
  return _reduceInstanceProperty(_context12 = _Object$entries(validators)).call(_context12, function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      propName = _ref2[0],
      validator = _ref2[1];
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, propName, deprecated(validator, droppedTargetVersion, additionalInfo, ignoredValues)));
  }, {});
}
function printDeprecationWarning(_ref3) {
  var _context13,
    propName = _ref3.propName,
    componentName = _ref3.componentName,
    message = _ref3.message,
    messageKey = _concatInstanceProperty(_context13 = "".concat(componentName, ".")).call(_context13, propName);
  warned[messageKey] || (warning("".concat(JUTRO_WARNING_PREFIX, ":").concat(message)), warned[messageKey] = !0);
}
export function resetDeprecatedWarnings(component) {
  var _context14;
  component ? _filterInstanceProperty(_context14 = _Object$keys(warned)).call(_context14, function (key) {
    return _startsWithInstanceProperty(key).call(key, "".concat(component, "."));
  }).forEach(function (key) {
    return delete warned[key];
  }) : warned = {};
}
function flattenPropsInfo(propsInfo, parentKey) {
  var _context15;
  return _reduceInstanceProperty(_context15 = _Object$entries(propsInfo)).call(_context15, function (accumulator, _ref4) {
    var _context16,
      _ref5 = _slicedToArray(_ref4, 2),
      key = _ref5[0],
      value = _ref5[1],
      type = value.type,
      name = value.name,
      nestedProperties = {};
    "shape" !== (null == type ? void 0 : type.name) && "shape" !== name || (nestedProperties = flattenPropsInfo((null == type ? void 0 : type.value) || value, key));
    var propsKey = parentKey ? _concatInstanceProperty(_context16 = "".concat(parentKey, ".")).call(_context16, key) : key;
    return _objectSpread(_objectSpread(_objectSpread({}, accumulator), nestedProperties), {}, _defineProperty({}, propsKey, value));
  }, {});
}
function transformProps(_ref6) {
  var _context17,
    props = _ref6.props,
    propsInfo = _ref6.propsInfo,
    displayName = _ref6.displayName,
    transformMap = _ref6.transformMap,
    parentKey = _ref6.parentKey;
  return _reduceInstanceProperty(_context17 = _Object$entries(props)).call(_context17, function (accumulator, _ref7) {
    var _context18,
      _context19,
      _ref8 = _slicedToArray(_ref7, 2),
      key = _ref8[0],
      value = _ref8[1],
      propKey = parentKey ? _concatInstanceProperty(_context18 = "".concat(parentKey, ".")).call(_context18, key) : key;
    if (!get(propsInfo, propKey)) return warning(_concatInstanceProperty(_context19 = 'The prop "'.concat(propKey, '" is not defined in the PropTypes for ')).call(_context19, displayName)), accumulator;
    var _get = get(propsInfo, propKey),
      _get$deprecationInfo = _get.deprecationInfo,
      deprecationInfo = void 0 === _get$deprecationInfo ? {} : _get$deprecationInfo,
      name = _get.name,
      type = _get.type,
      mapTo = deprecationInfo.mapTo,
      actualName = name || (null == type ? void 0 : type.name),
      nestedProperties = {},
      result = _objectSpread({}, accumulator),
      transform = get(transformMap, propKey),
      newValue = transform ? transform(value) : value;
    if (mapTo) {
      var _context20, _newPropInfo$type;
      accumulator[propKey];
      result = _objectSpread({}, _objectWithoutProperties(accumulator, _mapInstanceProperty(_context20 = [propKey]).call(_context20, _toPropertyKey)));
      var _context21,
        newPropInfo = get(propsInfo, mapTo);
      if (((null == newPropInfo ? void 0 : newPropInfo.name) || (null == newPropInfo || null === (_newPropInfo$type = newPropInfo.type) || void 0 === _newPropInfo$type ? void 0 : _newPropInfo$type.name)) !== actualName) error(_concatInstanceProperty(_context21 = 'The deprecated prop "'.concat(propKey, '" has been mapped to the new prop "')).call(_context21, mapTo, '" but the type does not match'));
      set(result, mapTo, newValue);
    } else "shape" === actualName ? nestedProperties = transformProps({
      props: props[key],
      propsInfo: propsInfo,
      displayName: displayName,
      transformMap: transformMap,
      parentKey: key
    }) : set(result, propKey, newValue);
    return _objectSpread(_objectSpread({}, result), nestedProperties);
  }, {});
}
export function transformDeprecatedProps(props, docgenInfo) {
  var transformMap = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
  if (isEmpty(docgenInfo)) return props;
  var _docgenInfo$props = docgenInfo.props,
    propsInfo = void 0 === _docgenInfo$props ? {} : _docgenInfo$props,
    displayName = docgenInfo.displayName;
  return isEmpty(propsInfo) ? props : transformProps({
    props: props,
    propsInfo: flattenPropsInfo(propsInfo),
    displayName: displayName,
    transformMap: transformMap
  });
}
export var withTransformedProps = function (Component) {
  var transformMap = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
    WithTransformedProps = function (props) {
      var transformedProps = transformDeprecatedProps(props, null == Component ? void 0 : Component.__docgenInfo, transformMap);
      return React.createElement(Component, _extends({}, props, transformedProps));
    };
  return WithTransformedProps.displayName = "withTransformedProps(".concat(getDisplayName(Component), ")"), WithTransformedProps.WrappedComponent = Component, WithTransformedProps.propTypes = Component.propTypes, WithTransformedProps;
};