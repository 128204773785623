import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { routesShape, contextSwitcherShape } from "@jutro/prop-types";
import cx from "classnames";
import { NavBarLink } from "./NavBarLink";
import styles from "./NavigationContent.module.css";
import { ContextSwitcher } from "../ContextSwitcher/ContextSwitcher";
export var NavigationContent = function (_ref) {
  var _contextSwitcher$valu,
    routes = _ref.routes,
    contextSwitcher = _ref.contextSwitcher,
    RenderContextSwitcher = _ref.renderContextSwitcher,
    nestedRoutesComponent = _ref.nestedRoutesComponent,
    alignment = _ref.alignment,
    classNameProp = _ref.className,
    contextSwitcherClassName = _ref.contextSwitcherClassName,
    navBarRef = _ref.navBarRef,
    isTop = "top" === alignment,
    classes = cx(styles.navigationContent, classNameProp),
    items = useMemo(function () {
      var _context,
        _context2,
        index = -1;
      return null == routes ? void 0 : _mapInstanceProperty(_context = _filterInstanceProperty(_context2 = _mapInstanceProperty(routes).call(routes, function (_ref2) {
        var title = _ref2.title,
          _ref2$navLink = _ref2.navLink,
          navLink = void 0 === _ref2$navLink ? {} : _ref2$navLink,
          _ref2$path = _ref2.path,
          path = void 0 === _ref2$path ? "" : _ref2$path,
          href = _ref2.href,
          target = _ref2.target,
          _ref2$showOnNavBar = _ref2.showOnNavBar,
          showOnNavBar = void 0 === _ref2$showOnNavBar || _ref2$showOnNavBar,
          exact = _ref2.exact,
          navRoutes = _ref2.routes,
          nestedRoutesComponentForNavItem = _ref2.nestedRoutesComponent,
          id = _ref2.id,
          className = _ref2.className,
          activeClassName = _ref2.activeClassName;
        if (!showOnNavBar) return null;
        index += 1;
        var navItemProps = function (target) {
            for (var i = 1; i < arguments.length; i++) {
              var source = null != arguments[i] ? arguments[i] : {};
              i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
                _defineProperty(target, key, source[key]);
              }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
                _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
              });
            }
            return target;
          }({
            header: navLink.text || title,
            icon: navLink.icon,
            to: path,
            href: href,
            target: target,
            exact: exact,
            className: cx(navLink.className, className),
            activeClassName: cx(navLink.activeClassName, activeClassName),
            alignment: alignment,
            id: navLink.id || id || "navLink_".concat(index),
            routes: navRoutes,
            role: "menuitem",
            notifications: navLink.notifications
          }, navLink.componentProps),
          routesComponent = get(navRoutes, "length", 0) && (nestedRoutesComponentForNavItem || nestedRoutesComponent),
          NavComponent = navLink.component || routesComponent || NavBarLink;
        return {
          key: path + title + index,
          NavComponent: NavComponent,
          navItemProps: navItemProps
        };
      })).call(_context2, function (item) {
        return item;
      })).call(_context, function (_ref3) {
        var key = _ref3.key,
          NavComponent = _ref3.NavComponent,
          navItemProps = _ref3.navItemProps;
        return React.createElement("li", {
          role: "none",
          key: key
        }, React.createElement(NavComponent, navItemProps));
      });
    }, [routes, alignment, nestedRoutesComponent]),
    shouldRenderContextSwitcher = RenderContextSwitcher || (null == contextSwitcher || null === (_contextSwitcher$valu = _valuesInstanceProperty(contextSwitcher)) || void 0 === _contextSwitcher$valu ? void 0 : _contextSwitcher$valu.length) > 0,
    ContextSwitcherComponent = RenderContextSwitcher || ContextSwitcher,
    contextSwitcherClasses = cx(styles.contextSwitcherContainer, contextSwitcherClassName);
  return React.createElement(React.Fragment, null, shouldRenderContextSwitcher && React.createElement("div", {
    className: contextSwitcherClasses
  }, React.createElement(ContextSwitcherComponent, contextSwitcher)), null != items && items.length ? React.createElement("ul", {
    className: classes,
    role: isTop ? "menubar" : "menu",
    ref: navBarRef
  }, items) : null);
};
NavigationContent.propTypes = {
  routes: routesShape,
  contextSwitcher: contextSwitcherShape,
  alignment: PropTypes.oneOf(["top", "left"]),
  className: PropTypes.string,
  contextSwitcherClassName: PropTypes.string,
  renderContextSwitcher: PropTypes.func,
  navBarRef: PropTypes.shape({
    current: PropTypes.instanceOf("undefined" != typeof window ? Element : Object)
  })
}, NavigationContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "NavigationContent",
  props: {
    routes: {
      type: {
        name: "custom",
        raw: "routesShape"
      },
      required: !1,
      description: "The routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "custom",
        raw: "contextSwitcherShape"
      },
      required: !1,
      description: "The context switcher object"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Navigation alignment"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    contextSwitcherClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the context switcher component"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    },
    navBarRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "typeof window !== 'undefined' ? Element : Object",
            required: !1
          }
        }
      },
      required: !1,
      description: "Reference to the navigation bar HTML element"
    }
  }
}, NavigationContent.__docgenInfo = {
  componentName: "NavigationContent",
  packageName: "@jutro/router",
  description: "",
  displayName: "NavigationContent",
  methods: [],
  actualName: "NavigationContent",
  props: {
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: !1,
      description: "The routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: !1
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: !0
          }
        }
      },
      required: !1,
      description: "The context switcher object"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Navigation alignment"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    contextSwitcherClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the context switcher component"
    },
    renderContextSwitcher: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to render custom ContextSwitcher instead of default one"
    },
    navBarRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "typeof window !== 'undefined' ? Element : Object",
            required: !1
          }
        }
      },
      required: !1,
      description: "Reference to the navigation bar HTML element"
    }
  }
};