import React from "react";
import { useTranslator } from "@jutro/locale";
import styles from "./TreeViewNoResults.module.css";
import { messages } from "./TreeView.messages";
export var TreeViewNoResults = function () {
  var translator = useTranslator();
  return React.createElement("div", {
    className: styles.noResults,
    "aria-live": "polite"
  }, translator(messages.noMatchesFound));
};
TreeViewNoResults.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TreeViewNoResults"
}, TreeViewNoResults.__docgenInfo = {
  componentName: "TreeViewNoResults",
  packageName: "@jutro/components",
  description: "",
  displayName: "TreeViewNoResults",
  methods: [],
  actualName: "TreeViewNoResults"
};