import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import cx from "classnames";
import { availableValuesCodeNameObject, availableValuesIdDisplayNameObject, dataTypeShape, DATA_TYPE_STRING, DATA_TYPE_OBJECT, intlMessageShape, availableValueObjectShape } from "@jutro/prop-types";
import { GenericSelectControl, genericSelectInternalClassNamesShape } from "../GenericSelectControl/GenericSelectControl";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { TagCollection } from "../../Tag/TagCollection";
var TypeaheadSingleValueShape = PropTypes.oneOfType([PropTypes.string.isRequired, availableValueObjectShape]),
  TypeaheadOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesIdDisplayNameObject), {}, {
    isDisabled: PropTypes.bool
  })),
  TypeaheadCodeOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesCodeNameObject), {}, {
    isDisabled: PropTypes.bool
  }));
export var TypeaheadMultiSelectField = function (_FieldComponent) {
  _inherits(TypeaheadMultiSelectField, FieldComponent);
  var _super = _createSuper(TypeaheadMultiSelectField);
  function TypeaheadMultiSelectField() {
    var _context, _this;
    _classCallCheck(this, TypeaheadMultiSelectField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "allowNewValue", function (value) {
      var _this$props = _this.props,
        availableValues = _this$props.availableValues;
      return _this$props.allowNew && value && (!availableValues || availableValues.every(function (entry) {
        return entry.name !== value && entry.code !== value;
      }));
    }), _defineProperty(_assertThisInitialized(_this), "handleOnChangeMulti", function (value) {
      var dataType = _this.props.dataType;
      _this.beforeValue = _this.props.value;
      var newVal = dataType === DATA_TYPE_OBJECT || null === value ? value : null == value ? void 0 : _mapInstanceProperty(value).call(value, _this.getOptionValue);
      _this.notifyChange(newVal);
    }), _defineProperty(_assertThisInitialized(_this), "handleOnChangeSingle", function (value, action) {
      var dataType = _this.props.dataType;
      if (_this.beforeValue = _this.props.value, "clear" !== action.action) {
        var newVal = dataType === DATA_TYPE_OBJECT ? value : _this.getOptionValue(value);
        _this.notifyChange(newVal);
      } else _this.notifyChange(value);
    }), _defineProperty(_assertThisInitialized(_this), "getOptionLabel", function (option) {
      return option.name || option.label;
    }), _defineProperty(_assertThisInitialized(_this), "getOptionValue", function (option) {
      return option.code || option.value;
    }), _defineProperty(_assertThisInitialized(_this), "translateAvailableValues", memoize(function (availableValues) {
      return availableValues && _mapInstanceProperty(availableValues).call(availableValues, function (value) {
        return {
          name: _this.translator(value.name),
          code: value.code,
          isDisabled: value.isDisabled
        };
      });
    })), _defineProperty(_assertThisInitialized(_this), "transformValueProp", memoize(function (value, availableValues, dataType) {
      var singleSelect = _this.props.singleSelect;
      if (!value || !availableValues) return [];
      if (dataType === DATA_TYPE_OBJECT) return value;
      var valuesMap = _reduceInstanceProperty(availableValues).call(availableValues, function (map, item) {
        return _Object$assign(map, _defineProperty({}, item.code, item));
      }, {});
      return singleSelect ? valuesMap[value] || {
        code: value,
        name: value
      } : _mapInstanceProperty(value).call(value, function (code) {
        return valuesMap[code] || {
          code: code,
          name: code
        };
      });
    })), _this;
  }
  return _createClass(TypeaheadMultiSelectField, [{
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(TypeaheadMultiSelectField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        className = breakpointProps.className;
      return React.createElement("div", _extends({
        id: "".concat(id, "-readonly"),
        "data-read-only": !0,
        className: className
      }, this.generateReadOnlyAccessibilityProperties()), this.renderSelect({
        readOnly: !0
      }));
    }
  }, {
    key: "renderSelect",
    value: function (props) {
      var _this$props2 = this.props,
        availableValuesProp = _this$props2.availableValues,
        className = _this$props2.className,
        dataType = _this$props2.dataType,
        readOnlySeparator = _this$props2.readOnlySeparator,
        valueProp = _this$props2.value,
        singleSelect = _this$props2.singleSelect,
        usePortal = _this$props2.usePortal,
        isInitiallyOpen = _this$props2.isInitiallyOpen,
        id = _this$props2.id,
        testId = _this$props2.testId,
        onLoadValues = _this$props2.onLoadValues,
        errorMessage = _this$props2.errorMessage,
        registerValidation = _this$props2.registerValidation,
        controlClassName = _this$props2.controlClassName,
        internalClassNames = _this$props2.internalClassNames,
        fieldUniqueId = this.fieldUniqueId,
        availableValues = this.translateAvailableValues(availableValuesProp),
        value = this.transformValueProp(valueProp, availableValues, dataType),
        components = useMemo(function () {
          return singleSelect || isEmpty(value) ? {} : {
            ValueContainer: function (valueContainerProps) {
              return React.createElement(TagCollection, _extends({
                id: "".concat(id, "-option")
              }, valueContainerProps));
            }
          };
        }, [singleSelect, value, id]);
      return React.createElement(GenericSelectControl, _extends({
        id: id,
        fieldUniqueId: fieldUniqueId,
        testId: testId,
        availableValues: availableValues,
        className: className,
        getOptionLabel: this.getOptionLabel,
        getOptionValue: this.getOptionValue,
        readOnlySeparator: readOnlySeparator,
        components: components,
        value: value,
        usePortal: usePortal
      }, props, {
        isInitiallyOpen: isInitiallyOpen,
        loadValues: onLoadValues,
        errorMessage: errorMessage,
        registerValidation: registerValidation,
        internalClassNames: _objectSpread(_objectSpread({}, internalClassNames), {}, {
          control: cx(controlClassName, null == internalClassNames ? void 0 : internalClassNames.control)
        })
      }));
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _ref = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
        isValid = _ref.isValid,
        labelId = _ref.labelId,
        disabled = breakpointProps.disabled,
        placeholder = breakpointProps.placeholder,
        createNewMessage = breakpointProps.createNewMessage,
        singleSelect = breakpointProps.singleSelect,
        stickyIndicator = breakpointProps.stickyIndicator,
        noOptionsMessage = breakpointProps.noOptionsMessage,
        usePortal = breakpointProps.usePortal,
        className = breakpointProps.className,
        required = breakpointProps.required,
        allowNew = breakpointProps.allowNew,
        selectProps = {
          createNewMessage: createNewMessage,
          disabled: disabled,
          isClearable: !1,
          allowNewValue: this.allowNewValue,
          allowNew: allowNew,
          labelId: labelId,
          noOptionsMessage: noOptionsMessage,
          onBlur: this.handleBlur,
          onFocus: this.handleFocus,
          onValueChange: singleSelect ? this.handleOnChangeSingle : this.handleOnChangeMulti,
          placeholder: placeholder,
          multiSelect: !singleSelect,
          stickyIndicator: stickyIndicator,
          usePortal: usePortal,
          valid: isValid,
          className: className,
          required: required
        };
      return this.renderSelect(selectProps);
    }
  }, {
    key: "isEmpty",
    value: function () {
      var _this$props3 = this.props,
        valueProp = _this$props3.value,
        availableValues = _this$props3.availableValues,
        value = this.transformValueProp(valueProp, availableValues);
      return !value || 0 === value.length;
    }
  }]), TypeaheadMultiSelectField;
}();
_defineProperty(TypeaheadMultiSelectField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  dataType: DATA_TYPE_STRING,
  usePortal: !0
})), _defineProperty(TypeaheadMultiSelectField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  availableValues: PropTypes.arrayOf(PropTypes.oneOfType([TypeaheadOptionShape, TypeaheadCodeOptionShape])),
  value: PropTypes.oneOfType([TypeaheadSingleValueShape, PropTypes.arrayOf(TypeaheadSingleValueShape)]),
  dataType: dataTypeShape,
  allowNew: PropTypes.bool,
  noOptionsMessage: intlMessageShape,
  createNewMessage: intlMessageShape,
  readOnlySeparator: PropTypes.string,
  singleSelect: PropTypes.bool,
  stickyIndicator: PropTypes.bool,
  usePortal: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  onLoadValues: PropTypes.func,
  internalClassNames: genericSelectInternalClassNamesShape
})), _defineProperty(TypeaheadMultiSelectField, "contextType", FieldComponent.contextType), TypeaheadMultiSelectField.__docgenInfo = {
  description: "Renders a multi-select input where the user can type to match\nfrom any of the predefined values. As the user types, the text\nis matched against the available values to display choices.\n\n@typedef {typeof TypeaheadMultiSelectField.propTypes} TypeaheadMultiSelectFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<TypeaheadMultiSelectFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "allowNewValue",
    docblock: "allowNewValue\n@param {object} value - new value being entered\n@returns {boolean} true if new values are allowed and it doesn't match an existing one",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value being entered",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "true if new values are allowed and it doesn't match an existing one",
      type: {
        name: "boolean"
      }
    },
    description: "allowNewValue"
  }, {
    name: "renderSelect",
    docblock: null,
    modifiers: [],
    params: [{
      name: "props",
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Renders the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options\n@returns {React.ReactElement} - rendered component",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "{ isValid, labelId }"
    }],
    returns: {
      description: "rendered component",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders the control"
  }, {
    name: "handleOnChangeMulti",
    docblock: "onChange callback passed to Select\n\n@param {object[]} value - the value of the control\n@param {string} value.name\n@param {string} value.code",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value of the control",
      type: {
        name: "Array",
        elements: [{
          name: "object"
        }]
      },
      optional: !1
    }],
    returns: null,
    description: "onChange callback passed to Select"
  }, {
    name: "handleOnChangeSingle",
    docblock: "onChange callback passed to Select\n\n@param {object[]} value - the value of the control\n@param {string} value.name\n@param {string} value.code\n@param {object} action - the action type (clear, blur, ect.)",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value of the control",
      type: {
        name: "Array",
        elements: [{
          name: "object"
        }]
      },
      optional: !1
    }, {
      name: "action",
      description: "the action type (clear, blur, ect.)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "onChange callback passed to Select"
  }, {
    name: "isEmpty",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "getOptionLabel",
    docblock: "Gets the option display text.\nNeeds to handle the internal option.label format of the Creatable component\nwhen new values are created.\n\n@param {object} option\n\n@returns {string} - the text to display",
    modifiers: [],
    params: [{
      name: "option",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "the text to display",
      type: {
        name: "string"
      }
    },
    description: "Gets the option display text.\nNeeds to handle the internal option.label format of the Creatable component\nwhen new values are created."
  }, {
    name: "getOptionValue",
    docblock: "Gets the option value\nNeeds to handle the internal option.value format of the Creatable component\nwhen new values are created.\n\n@param {object} option\n\n@returns {string} - the value that indicates user choice",
    modifiers: [],
    params: [{
      name: "option",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "the value that indicates user choice",
      type: {
        name: "string"
      }
    },
    description: "Gets the option value\nNeeds to handle the internal option.value format of the Creatable component\nwhen new values are created."
  }],
  displayName: "TypeaheadMultiSelectField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShape"
      },
      required: !1,
      description: "The format of the items in the value array"
    },
    usePortal: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent"
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "TypeaheadOptionShape"
          }, {
            name: "custom",
            raw: "TypeaheadCodeOptionShape"
          }]
        }
      },
      required: !1,
      description: "List of values that the user can select from.\n\n`name` - the text to display, for example 'Married', 'Single'\n`code` - the value that is sent on submit.\n`isDisabled` - if option should be disabled pass the key with boolean true value"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "TypeaheadSingleValueShape"
        }, {
          name: "arrayOf",
          value: {
            name: "custom",
            raw: "TypeaheadSingleValueShape"
          }
        }]
      },
      required: !1,
      description: "The list of codes that are selected. Each code is matched\nagainst the codes in `availableValues` to retrieve a `name` to display"
    },
    allowNew: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the user can create new values"
    },
    noOptionsMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to display when there are no options that match\nthe text the user enters."
    },
    createNewMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to display when a new option is being created by the user.\nPass `createNewMessage` with the {message} placeholder for example: 'New option: {message}'."
    },
    readOnlySeparator: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Separator displayed in the list of values in readonly mode"
    },
    singleSelect: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the user can only select one option"
    },
    stickyIndicator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the dropdown indicator is sticky"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the dropdown is initially open"
    },
    onLoadValues: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for asynchronous data loading"
    },
    internalClassNames: {
      type: {
        name: "custom",
        raw: "genericSelectInternalClassNamesShape"
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of this component's styles"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, TypeaheadMultiSelectField.__docgenInfo = {
  componentName: "TypeaheadMultiSelectField",
  packageName: "@jutro/components",
  description: "Renders a multi-select input where the user can type to match\nfrom any of the predefined values. As the user types, the text\nis matched against the available values to display choices.",
  displayName: "TypeaheadMultiSelectField",
  methods: [{
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "allowNewValue",
    docblock: "allowNewValue\n@param {object} value - new value being entered\n@returns {boolean} true if new values are allowed and it doesn't match an existing one",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value being entered",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "true if new values are allowed and it doesn't match an existing one",
      type: {
        name: "boolean"
      }
    },
    description: "allowNewValue"
  }, {
    name: "renderSelect",
    docblock: null,
    modifiers: [],
    params: [{
      name: "props",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Renders the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options\n@returns {React.ReactElement} - rendered component",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "{ isValid, labelId }"
    }],
    returns: {
      description: "rendered component",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders the control"
  }, {
    name: "handleOnChangeMulti",
    docblock: "onChange callback passed to Select\n\n@param {object[]} value - the value of the control\n@param {string} value.name\n@param {string} value.code",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value of the control",
      type: {
        name: "Array",
        elements: [{
          name: "object"
        }]
      },
      optional: !1
    }],
    returns: null,
    description: "onChange callback passed to Select"
  }, {
    name: "handleOnChangeSingle",
    docblock: "onChange callback passed to Select\n\n@param {object[]} value - the value of the control\n@param {string} value.name\n@param {string} value.code\n@param {object} action - the action type (clear, blur, ect.)",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value of the control",
      type: {
        name: "Array",
        elements: [{
          name: "object"
        }]
      },
      optional: !1
    }, {
      name: "action",
      description: "the action type (clear, blur, ect.)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "onChange callback passed to Select"
  }, {
    name: "isEmpty",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "getOptionLabel",
    docblock: "Gets the option display text.\nNeeds to handle the internal option.label format of the Creatable component\nwhen new values are created.\n\n@param {object} option\n\n@returns {string} - the text to display",
    modifiers: [],
    params: [{
      name: "option",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "the text to display",
      type: {
        name: "string"
      }
    },
    description: "Gets the option display text.\nNeeds to handle the internal option.label format of the Creatable component\nwhen new values are created."
  }, {
    name: "getOptionValue",
    docblock: "Gets the option value\nNeeds to handle the internal option.value format of the Creatable component\nwhen new values are created.\n\n@param {object} option\n\n@returns {string} - the value that indicates user choice",
    modifiers: [],
    params: [{
      name: "option",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "the value that indicates user choice",
      type: {
        name: "string"
      }
    },
    description: "Gets the option value\nNeeds to handle the internal option.value format of the Creatable component\nwhen new values are created."
  }],
  actualName: "TypeaheadMultiSelectField",
  metadataType: "field",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "custom",
            raw: "TypeaheadOptionShape"
          }, {
            name: "custom",
            raw: "TypeaheadCodeOptionShape"
          }]
        }
      },
      required: !1,
      description: "List of values that the user can select from.\n\n`name` - the text to display, for example 'Married', 'Single'\n`code` - the value that is sent on submit.\n`isDisabled` - if option should be disabled pass the key with boolean true value"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "TypeaheadSingleValueShape"
        }, {
          name: "arrayOf",
          value: {
            name: "custom",
            raw: "TypeaheadSingleValueShape"
          }
        }]
      },
      required: !1,
      description: "The list of codes that are selected. Each code is matched\nagainst the codes in `availableValues` to retrieve a `name` to display"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }]
      },
      required: !1,
      description: "The format of the items in the value array",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    allowNew: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the user can create new values"
    },
    noOptionsMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to display when there are no options that match\nthe text the user enters."
    },
    createNewMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to display when a new option is being created by the user.\nPass `createNewMessage` with the {message} placeholder for example: 'New option: {message}'."
    },
    readOnlySeparator: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Separator displayed in the list of values in readonly mode"
    },
    singleSelect: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the user can only select one option"
    },
    stickyIndicator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the dropdown indicator is sticky"
    },
    usePortal: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the dropdown is initially open"
    },
    onLoadValues: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for asynchronous data loading"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          control: {
            name: "string",
            description: "CSS class name for the component",
            required: !1
          },
          controlFocused: {
            name: "string",
            description: "CSS class name for the component when focused",
            required: !1
          },
          menuList: {
            name: "string",
            description: "CSS class name for the list of options",
            required: !1
          },
          option: {
            name: "string",
            description: "CSS class name for each option",
            required: !1
          },
          selectIcon: {
            name: "string",
            description: "CSS class name for icon in the select",
            required: !1
          },
          optionDisabled: {
            name: "string",
            description: "CSS class name for disabled option",
            required: !1
          },
          focusedDisabled: {
            name: "string",
            description: "CSS class name for disabled option when focused",
            required: !1
          }
        }
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of this component's styles"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};