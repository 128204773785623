import { defineMessages } from "react-intl";
export var messages = defineMessages({
  dayInputLabel: {
    id: "jutro-components.SimpleDateField.dayInputLabel",
    defaultMessage: "Day"
  },
  monthInputLabel: {
    id: "jutro-components.SimpleDateField.monthInputLabel",
    defaultMessage: "Month"
  },
  yearInputLabel: {
    id: "jutro-components.SimpleDateField.yearInputLabel",
    defaultMessage: "Year"
  }
});