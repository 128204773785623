import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { tooltipBaseProps } from "@jutro/prop-types";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
var propTypes = _objectSpread(_objectSpread({}, tooltipBaseProps), {}, {
  id: PropTypes.string,
  className: PropTypes.string,
  titleClass: PropTypes.string,
  textClass: PropTypes.string,
  linkClass: PropTypes.string
});
export var TooltipContent = function (_ref) {
  var id = _ref.id,
    text = _ref.text,
    title = _ref.title,
    link = _ref.link,
    href = _ref.href,
    className = _ref.className,
    titleClass = _ref.titleClass,
    textClass = _ref.textClass,
    linkClass = _ref.linkClass,
    safeHref = useSafeTranslatedUrls()(href),
    translator = useTranslator();
  return React.createElement("div", {
    id: id,
    className: className,
    role: "alert"
  }, title && React.createElement("div", {
    className: titleClass
  }, React.createElement("strong", null, translator(title))), text && React.createElement("div", {
    className: textClass
  }, translator(text)), link && safeHref && React.createElement("a", {
    className: linkClass,
    href: safeHref,
    target: "_blank",
    rel: "noopener noreferrer"
  }, translator(link)));
};
TooltipContent.propTypes = propTypes, TooltipContent.displayName = "TooltipContent", TooltipContent.__docgenInfo = {
  description: "Content component that renders inside tooltip",
  methods: [],
  displayName: "TooltipContent",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id for the tooltip content"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the component"
    },
    titleClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the title"
    },
    textClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the text"
    },
    linkClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the link"
    }
  },
  composes: ["@jutro/prop-types"]
}, TooltipContent.__docgenInfo = {
  componentName: "TooltipContent",
  packageName: "@jutro/components",
  description: "Content component that renders inside tooltip",
  displayName: "TooltipContent",
  methods: [],
  actualName: "TooltipContent",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Id for the tooltip content"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the component"
    },
    titleClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the title"
    },
    textClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the text"
    },
    linkClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class to customize the link"
    }
  },
  composes: ["@jutro/prop-types"]
};