import { appendSimpleNamedComponentMap } from "@jutro/uiconfig";
import { ActionColumn } from "./columns/ActionColumn";
import { ActionItem } from "./columns/ActionItem";
import { DisplayColumn } from "./columns/DisplayColumn";
import { FieldColumn } from "./columns/FieldColumn";
import { RadioColumn } from "./columns/RadioColumn";
import { DataTable } from "./DataTable";
import { Table } from "./table/Table";
import { TableColumn } from "./table/TableColumn";
import { FilterBar } from "./filterBar/FilterBar";
export { createTextFilter, createStrictTextFilter, defaultColumnFilter } from "./helper";
export { useDataFilter } from "./hooks/useDataFilter";
export { SHOW_ALL_PAGES } from "./Pagination/utils";
export { ActionColumn, FieldColumn, FilterBar, RadioColumn, DisplayColumn, ActionItem, DataTable, Table, TableColumn };
appendSimpleNamedComponentMap({
  ActionColumn: {
    component: ActionColumn
  },
  ActionItem: {
    component: ActionItem
  },
  DisplayColumn: {
    component: DisplayColumn
  },
  FieldColumn: {
    component: FieldColumn
  },
  FilterBar: {
    component: FilterBar
  },
  RadioColumn: {
    component: RadioColumn
  },
  DataTable: {
    component: DataTable
  },
  Table: {
    component: Table
  },
  TableColumn: {
    component: TableColumn
  }
});