import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import { useCallback, useReducer } from "react";
import { findChildren, getVisibleNodes, getEnabledNodes } from "./traverse";
import { ACTIONS, treeReducer } from "./treeReducer";
export var useTree = function (_ref) {
  var getInitialState = _ref.getInitialState,
    filterCallback = _ref.filterCallback,
    _useReducer = useReducer(treeReducer, void 0, getInitialState),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    state = _useReducer2[0],
    dispatch = _useReducer2[1],
    nodeMap = state.nodeMap,
    expandedNodes = state.expandedNodes,
    matchedNodes = state.matchedNodes,
    filterQuery = state.filterQuery,
    isNodeExpanded = useCallback(function (id) {
      return _includesInstanceProperty(expandedNodes).call(expandedNodes, id);
    }, [expandedNodes]),
    toggleNode = useCallback(function (value) {
      findChildren(nodeMap, value).length && dispatch({
        type: ACTIONS.TOGGLE,
        payload: {
          id: value
        }
      });
    }, [nodeMap]),
    allCollapsed = 0 === expandedNodes.length;
  return {
    state: state,
    toggleNode: toggleNode,
    toggleAllNodes: useCallback(function () {
      return dispatch({
        type: ACTIONS.TOGGLE_ALL
      });
    }, []),
    registerNode: useCallback(function (id, label, path, disabled) {
      dispatch({
        type: ACTIONS.REGISTER,
        payload: {
          newNode: {
            id: id,
            label: label,
            path: path,
            disabled: disabled
          }
        }
      });
    }, []),
    unregisterNode: useCallback(function (id) {
      dispatch({
        type: ACTIONS.UNREGISTER,
        payload: {
          id: id
        }
      });
    }, []),
    onFilterChange: useCallback(function (query) {
      dispatch({
        type: ACTIONS.FILTER_CHANGE,
        payload: {
          query: query,
          filterCallback: filterCallback
        }
      });
    }, [filterCallback]),
    isNodeExpanded: isNodeExpanded,
    handleOnKeyDown: useCallback(function (event) {
      dispatch({
        type: ACTIONS.KEY_DOWN,
        payload: {
          key: event.key
        }
      });
    }, []),
    setActiveNodeId: useCallback(function (id) {
      return dispatch({
        type: ACTIONS.CHANGE_ACTIVE_NODE,
        payload: {
          id: id
        }
      });
    }, []),
    setSelectedNodeId: useCallback(function (id) {
      return dispatch({
        type: ACTIONS.CHANGE_SELECTED_NODE,
        payload: {
          id: id
        }
      });
    }, []),
    isNodeVisible: useCallback(function (id, path) {
      return !filterQuery || matchedNodes.some(function (_ref2) {
        var _context,
          _context2,
          matchedNodeId = _ref2.id,
          matchedNodePath = _ref2.path;
        return _includesInstanceProperty(_context = _concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(path), [id])).call(_context, matchedNodeId) || _includesInstanceProperty(matchedNodePath).call(matchedNodePath, id);
      });
    }, [filterQuery, matchedNodes]),
    isFirstNode: useCallback(function (id) {
      var _getEnabledNodes = getEnabledNodes(getVisibleNodes({
          nodeMap: nodeMap,
          expandedNodes: expandedNodes,
          matchedNodes: matchedNodes,
          filterQuery: filterQuery
        })),
        firstNode = _slicedToArray(_getEnabledNodes, 1)[0];
      return (null == firstNode ? void 0 : firstNode.id) === id;
    }, [nodeMap, expandedNodes, matchedNodes, filterQuery]),
    getMatchedLabelParts: useCallback(function (nodeId) {
      var _matchedNodes$find;
      return null === (_matchedNodes$find = _findInstanceProperty(matchedNodes).call(matchedNodes, function (node) {
        return node.id === nodeId;
      })) || void 0 === _matchedNodes$find ? void 0 : _matchedNodes$find.matches;
    }, [matchedNodes]),
    allCollapsed: allCollapsed
  };
};