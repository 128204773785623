import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../../../Icon/Icon";
export var GenericSelectControlDropdownIndicator = function (_ref) {
  var icon = _ref.icon,
    className = _ref.className,
    hasValue = _ref.hasValue,
    _ref$selectProps = _ref.selectProps,
    genericSelectStyles = _ref$selectProps.genericSelectStyles,
    isClearable = _ref$selectProps.isClearable,
    isLoading = _ref$selectProps.isLoading;
  if (hasValue && isClearable || isLoading) return null;
  var arrowClasses = cx(genericSelectStyles.selectIcon, className);
  return React.createElement(Icon, {
    icon: icon,
    size: "medium",
    className: arrowClasses,
    "aria-hidden": !0,
    focusable: !1
  });
};
GenericSelectControlDropdownIndicator.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  selectProps: PropTypes.shape({
    value: PropTypes.any,
    customStyles: PropTypes.object,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool
  })
}, GenericSelectControlDropdownIndicator.defaultProps = {
  icon: "gw-expand-more"
}, GenericSelectControlDropdownIndicator.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "GenericSelectControlDropdownIndicator",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-expand-more'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to show in the indicator"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS className for this component"
    },
    selectProps: {
      type: {
        name: "shape",
        value: {
          value: {
            name: "any",
            description: "Current selected value",
            required: !1
          },
          customStyles: {
            name: "object",
            description: "Map with custom styles for the generic select",
            required: !1
          },
          isClearable: {
            name: "bool",
            description: "Indicates whether the generic select is clearable",
            required: !1
          },
          isLoading: {
            name: "bool",
            description: 'Indicates whether the generic select is in a "loading" state',
            required: !1
          }
        }
      },
      required: !1,
      description: "Generic select custom props"
    }
  }
}, GenericSelectControlDropdownIndicator.__docgenInfo = {
  componentName: "GenericSelectControlDropdownIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlDropdownIndicator",
  methods: [],
  actualName: "GenericSelectControlDropdownIndicator",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to show in the indicator",
      defaultValue: {
        value: "'gw-expand-more'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional CSS className for this component"
    },
    selectProps: {
      type: {
        name: "shape",
        value: {
          value: {
            name: "any",
            description: "Current selected value",
            required: !1
          },
          customStyles: {
            name: "object",
            description: "Map with custom styles for the generic select",
            required: !1
          },
          isClearable: {
            name: "bool",
            description: "Indicates whether the generic select is clearable",
            required: !1
          },
          isLoading: {
            name: "bool",
            description: 'Indicates whether the generic select is in a "loading" state',
            required: !1
          }
        }
      },
      required: !1,
      description: "Generic select custom props"
    }
  }
};