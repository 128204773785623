import React, { memo } from "react";
import { useTranslator, useLanguage } from "@jutro/locale";
import { PageHead } from "@jutro/router";
var GlobalizedHeadInternal = function (_ref) {
  var appName = _ref.appName,
    translator = useTranslator(),
    language = useLanguage().language;
  return React.createElement(PageHead, {
    currentLanguage: language,
    appName: translator(appName),
    appDescription: translator(appName)
  });
};
GlobalizedHeadInternal.displayName = "GlobalizedHead";
export var GlobalizedHead = memo(GlobalizedHeadInternal);
GlobalizedHeadInternal.__docgenInfo = {
  description: "Renders a react-helmet element that builds a localized titleTemplate and description, and other meta tags.\n\n@returns {React.ReactElement} - The Helmet header element",
  methods: [],
  displayName: "GlobalizedHead"
}, GlobalizedHeadInternal.__docgenInfo = {
  componentName: "GlobalizedHead",
  packageName: "@jutro/app",
  description: "Renders a react-helmet element that builds a localized titleTemplate and description, and other meta tags.",
  displayName: "GlobalizedHead",
  methods: [],
  actualName: "GlobalizedHeadInternal"
};