import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["gap", "justifyContent", "alignContent", "alignItems", "tag", "children", "className", "direction", "wrap", "dangerouslySetInnerHTML"];
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React from "react";
import PropTypes from "prop-types";
import { deprecated } from "@jutro/prop-types";
import cx from "classnames";
import styles from "./Flex.module.css";
import { useBreakpoint } from "../breakpoint/useBreakpoint";
var gapMapping = {
    none: styles.gwGapNone,
    small: styles.gwGapSmall,
    medium: styles.gwGapMedium,
    large: styles.gwGapLarge
  },
  alignItemsMapping = {
    top: styles.gwAlignItemsStart,
    middle: styles.gwAlignItemsCenter,
    bottom: styles.gwAlignItemsEnd,
    left: styles.gwAlignItemsStart,
    center: styles.gwAlignItemsCenter,
    right: styles.gwAlignItemsEnd,
    baseline: styles.gwAlignItemsBaseline,
    stretch: styles.gwAlignItemsStretch
  },
  alignContentMapping = {
    top: styles.gwAlignContentStart,
    middle: styles.gwAlignContentCenter,
    bottom: styles.gwAlignContentEnd,
    left: styles.gwAlignContentStart,
    center: styles.gwAlignContentCenter,
    right: styles.gwAlignContentEnd,
    baseline: styles.gwAlignContentBaseline,
    stretch: styles.gwAlignContentStretch
  },
  justifyContentMapping = {
    top: styles.gwJustifyContentStart,
    middle: styles.gwJustifyContentCenter,
    bottom: styles.gwJustifyContentEnd,
    left: styles.gwJustifyContentStart,
    center: styles.gwJustifyContentCenter,
    right: styles.gwJustifyContentEnd,
    around: styles.gwJustifyContentAround,
    between: styles.gwJustifyContentBetween,
    evenly: styles.gwJustifyContentEvenly
  },
  wrapMapping = {
    true: styles.gwWrap,
    false: styles.gwNoWrap,
    reverse: styles.gwReverseWrap
  },
  directionMapping = {
    row: styles.gwDirectionRow,
    rowReverse: styles.gwDirectionRowReverse,
    column: styles.gwDirectionColumn,
    columnReverse: styles.gwDirectionColumnReverse
  };
export var Flex = function (props) {
  var _cx,
    breakpointProps = useBreakpoint(props).breakpointProps,
    gap = breakpointProps.gap,
    justifyContent = breakpointProps.justifyContent,
    alignContent = breakpointProps.alignContent,
    alignItems = breakpointProps.alignItems,
    tag = breakpointProps.tag,
    children = breakpointProps.children,
    className = breakpointProps.className,
    direction = breakpointProps.direction,
    wrap = breakpointProps.wrap,
    other = (breakpointProps.dangerouslySetInnerHTML, _objectWithoutProperties(breakpointProps, _excluded)),
    classes = cx(styles.gwFlex, (_defineProperty(_cx = {}, gap && gapMapping[gap], gap), _defineProperty(_cx, alignItems && alignItemsMapping[alignItems], alignItems), _defineProperty(_cx, alignContent && alignContentMapping[alignContent], alignContent), _defineProperty(_cx, justifyContent && justifyContentMapping[justifyContent], justifyContent), _cx), wrapMapping["".concat(wrap)], direction && directionMapping[direction], className),
    Tag = tag;
  return React.createElement(Tag, _extends({
    className: classes
  }, other), children);
};
var flexPropTypes = {
  gap: PropTypes.oneOf(_Object$keys(gapMapping)),
  alignItems: PropTypes.oneOf(_Object$keys(alignItemsMapping)),
  alignContent: PropTypes.oneOf(_Object$keys(alignContentMapping)),
  justifyContent: PropTypes.oneOf(_Object$keys(justifyContentMapping)),
  direction: PropTypes.oneOf(_Object$keys(directionMapping)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  theme: deprecated(PropTypes.shape({
    getStyle: PropTypes.func
  }), "9.0.0", "Prop 'theme' is unused in the component and has no effect."),
  wrap: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(_Object$keys(wrapMapping))])
};
Flex.propTypes = flexPropTypes, Flex.defaultProps = {
  gap: "medium",
  tag: "div",
  wrap: !0,
  direction: "row"
}, Flex.displayName = "Flex", Flex.__docgenInfo = {
  description: "Defines a CSS 'Flex'. This is used in conjunction with 'FlexItem' children.\n\n@example\n<Flex\n  gap='medium'\n  tablet={{gap: 'small'}}\n>\n  <FlexItem>\n</Flex>\n\n@metadataType layout",
  methods: [],
  displayName: "Flex",
  props: {
    gap: {
      defaultValue: {
        value: "'medium'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between rows and columns ('none', 'small', 'medium', 'large')"
    },
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Optional dom tag to render"
    },
    wrap: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: '"true"',
            computed: !1
          }, {
            value: '"false"',
            computed: !1
          }, {
            value: '"reverse"',
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "Wrap (true), don't wrap (false), reverse wrap (reverse) flex items in the container"
    },
    direction: {
      defaultValue: {
        value: "'row'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: '"row"',
          computed: !1
        }, {
          value: '"rowReverse"',
          computed: !1
        }, {
          value: '"column"',
          computed: !1
        }, {
          value: '"columnReverse"',
          computed: !1
        }]
      },
      required: !1,
      description: "Set the flex direction (row, rowReverse, column, columnReverse)"
    },
    alignItems: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align all items within the grid in the axis opposite to its direction; default is stretch"
    },
    alignContent: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align the flex within its container in the axis opposite to its direction; default is stretch"
    },
    justifyContent: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"around"',
          computed: !1
        }, {
          value: '"between"',
          computed: !1
        }, {
          value: '"evenly"',
          computed: !1
        }]
      },
      required: !1,
      description: "Justify the content in the flex direction: horizontally if direction is 'row' (default) or vertically if direction is 'column'"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class(es) to add to the flex tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children; preferably 'FlexItem'"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Flex property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Flex property for use at the 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Flex property for use at the 'tablet' breakpoint;"
    },
    theme: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.shape({ getStyle: PropTypes.func }),\n    '9.0.0',\n    `Prop 'theme' is unused in the component and has no effect.`\n)"
      },
      required: !1,
      description: "Theme to apply to the component"
    }
  }
}, Flex.__docgenInfo = {
  componentName: "Flex",
  packageName: "@jutro/layout",
  description: "Defines a CSS 'Flex'. This is used in conjunction with 'FlexItem' children.",
  displayName: "Flex",
  methods: [],
  actualName: "Flex",
  metadataType: "layout",
  props: {
    gap: {
      type: {
        name: "enum",
        value: [{
          value: '"none"',
          computed: !1
        }, {
          value: '"small"',
          computed: !1
        }, {
          value: '"medium"',
          computed: !1
        }, {
          value: '"large"',
          computed: !1
        }]
      },
      required: !1,
      description: "Gap between rows and columns ('none', 'small', 'medium', 'large')",
      defaultValue: {
        value: "'medium'",
        computed: !1
      }
    },
    alignItems: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align all items within the grid in the axis opposite to its direction; default is stretch"
    },
    alignContent: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align the flex within its container in the axis opposite to its direction; default is stretch"
    },
    justifyContent: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"around"',
          computed: !1
        }, {
          value: '"between"',
          computed: !1
        }, {
          value: '"evenly"',
          computed: !1
        }]
      },
      required: !1,
      description: "Justify the content in the flex direction: horizontally if direction is 'row' (default) or vertically if direction is 'column'"
    },
    direction: {
      type: {
        name: "enum",
        value: [{
          value: '"row"',
          computed: !1
        }, {
          value: '"rowReverse"',
          computed: !1
        }, {
          value: '"column"',
          computed: !1
        }, {
          value: '"columnReverse"',
          computed: !1
        }]
      },
      required: !1,
      description: "Set the flex direction (row, rowReverse, column, columnReverse)",
      defaultValue: {
        value: "'row'",
        computed: !1
      }
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Optional dom tag to render",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional CSS class(es) to add to the flex tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children; preferably 'FlexItem'"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Flex property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Flex property for use at the 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Flex property for use at the 'tablet' breakpoint;"
    },
    theme: {
      type: {
        name: "shape",
        value: {
          getStyle: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: "@deprecated Theme to apply to the component",
      deprecationInfo: {
        version: "9.0.0",
        mapTo: null
      }
    },
    wrap: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "enum",
          value: [{
            value: '"true"',
            computed: !1
          }, {
            value: '"false"',
            computed: !1
          }, {
            value: '"reverse"',
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "Wrap (true), don't wrap (false), reverse wrap (reverse) flex items in the container",
      defaultValue: {
        value: "true",
        computed: !1
      }
    }
  }
};