import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { uniqueInnerId } from "@jutro/platform";
import { Button } from "../../button/Button";
import styles from "./ConfirmationModal.module.css";
import { ModalNext } from "../ModalNext";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { ModalBody } from "../ModalBody/ModalBody";
import { ModalFooter } from "../ModalFooter/ModalFooter";
import { messages } from "./ConfirmationModal.messages.js";
var defaultConfirmButtonText = messages.confirmButtonText,
  defaultCancelButtonText = messages.cancelButtonText,
  confirmationModalPropTypes = {
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func.isRequired,
    parentSelector: PropTypes.func,
    icon: PropTypes.string,
    title: intlMessageShape.isRequired,
    message: intlMessageShape,
    status: PropTypes.oneOf(["info", "warning", "error", "success"]),
    confirmButtonText: intlMessageShape,
    cancelButtonText: intlMessageShape
  };
export var ConfirmationModal = function (_ref) {
  var isOpen = _ref.isOpen,
    onResolve = _ref.onResolve,
    parentSelector = _ref.parentSelector,
    title = _ref.title,
    message = _ref.message,
    status = _ref.status,
    icon = _ref.icon,
    confirmButtonText = _ref.confirmButtonText,
    cancelButtonText = _ref.cancelButtonText,
    translator = useTranslator(),
    onClose = useCallback(function () {
      return onResolve(confirmationResult.CLOSE);
    }, [onResolve]),
    onCancel = useCallback(function () {
      return onResolve(confirmationResult.CANCEL);
    }, [onResolve]),
    onConfirm = useCallback(function () {
      return onResolve(confirmationResult.CONFIRM);
    }, [onResolve]),
    _uniqueInnerId = uniqueInnerId("modalNext", "titleId", "descriptionId"),
    titleId = _uniqueInnerId.titleId,
    descriptionId = _uniqueInnerId.descriptionId;
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onClose,
    parentSelector: parentSelector,
    overlayClassName: styles.overlayConfirmation,
    shouldFocusAfterRender: !0,
    ariaLabelledby: titleId,
    ariaDescribedby: descriptionId
  }, React.createElement(ModalHeader, {
    icon: icon,
    title: title,
    onClose: onClose,
    status: status,
    titleId: titleId,
    ariaLabel: translator(messages.closeIconButtonAriaLabel)
  }), React.createElement(ModalBody, {
    id: descriptionId
  }, translator(message)), React.createElement(ModalFooter, null, cancelButtonText && React.createElement(Button, {
    type: "text",
    onClick: onCancel
  }, translator(cancelButtonText)), React.createElement(Button, {
    type: "filled",
    onClick: onConfirm
  }, translator(confirmButtonText))));
};
ConfirmationModal.propTypes = confirmationModalPropTypes, ConfirmationModal.defaultProps = {
  confirmButtonText: {
    id: defaultConfirmButtonText.id,
    defaultMessage: defaultConfirmButtonText.defaultMessage
  },
  cancelButtonText: {
    id: defaultCancelButtonText.id,
    defaultMessage: defaultCancelButtonText.defaultMessage
  }
};
export var confirmationResult = {
  CONFIRM: "confirm",
  CANCEL: "cancel",
  CLOSE: "close"
};
ConfirmationModal.__docgenInfo = {
  description: "ConfirmationModal\n@type {React.FC<PropTypes.InferProps<typeof confirmationModalPropTypes>>}",
  methods: [],
  displayName: "ConfirmationModal",
  props: {
    confirmButtonText: {
      defaultValue: {
        value: "{\n    id: defaultConfirmButtonText.id,\n    defaultMessage: defaultConfirmButtonText.defaultMessage,\n}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The text or message shape of the confirm button"
    },
    cancelButtonText: {
      defaultValue: {
        value: "{\n    id: defaultCancelButtonText.id,\n    defaultMessage: defaultCancelButtonText.defaultMessage,\n}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The text or message shape of the cancel button"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Boolean denoting if the modal is open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback function called when the modal is confirmed, cancelled, or closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to find the modal's parent selector"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Text to display for the title"
    },
    message: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to be displayed in the modal"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }]
      },
      required: !1,
      description: "The status of the warning. One of 'info', 'warning', 'error', 'success', or blank for default."
    }
  }
}, ConfirmationModal.__docgenInfo = {
  componentName: "ConfirmationModal",
  packageName: "@jutro/components",
  description: "ConfirmationModal",
  displayName: "ConfirmationModal",
  methods: [],
  actualName: "ConfirmationModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Boolean denoting if the modal is open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback function called when the modal is confirmed, cancelled, or closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to find the modal's parent selector"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Text to display for the title"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to be displayed in the modal"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }]
      },
      required: !1,
      description: "The status of the warning. One of 'info', 'warning', 'error', 'success', or blank for default."
    },
    confirmButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The text or message shape of the confirm button",
      defaultValue: {
        value: "{\n    id: defaultConfirmButtonText.id,\n    defaultMessage: defaultConfirmButtonText.defaultMessage,\n}",
        computed: !1
      }
    },
    cancelButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The text or message shape of the cancel button",
      defaultValue: {
        value: "{\n    id: defaultCancelButtonText.id,\n    defaultMessage: defaultCancelButtonText.defaultMessage,\n}",
        computed: !1
      }
    }
  }
};