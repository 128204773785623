import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React, { useState, useRef } from "react";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { LargeUploadArea } from "../CommonComponents/LargeUploadArea";
import { DragAndDropWrapper } from "../CommonComponents/DragAndDropWrapper";
import { MultipleFilesUploadAccordion } from "./components/MultipleFilesUploadAccordion";
import { InlineNotification } from "../../../inlineNotification/InlineNotification";
import styles from "../FileUploadField.module.css";
import multipleStyles from "./MultipleFilesUploadField.module.css";
import { messages } from "../FileUploadField.messages";
import { multipleFileUploadMessages } from "./MultipleFilesUploadField.messages";
import { checkFileErrors } from "./helpers/MultipleFilesUploadFieldValidation";
var clearInputValue = function (e) {
  e.target && (e.target.value = "");
};
export var MultipleUploadField = function (_ref) {
  var error,
    maxLength = _ref.maxLength,
    disabled = _ref.disabled,
    className = _ref.className,
    controlClassName = _ref.controlClassName,
    required = _ref.required,
    accept = _ref.accept,
    messageProps = _ref.messageProps,
    imageSource = _ref.imageSource,
    buttonType = _ref.buttonType,
    buttonSize = _ref.buttonSize,
    buttonIcon = _ref.buttonIcon,
    id = _ref.id,
    testId = _ref.testId,
    fieldUniqueId = _ref.fieldUniqueId,
    uploadState = _ref.uploadState,
    completed = _ref.completed,
    total = _ref.total,
    onFileClear = _ref.onFileClear,
    onInputFocus = _ref.onInputFocus,
    onInputBlur = _ref.onInputBlur,
    dataPath = _ref.dataPath,
    inputA11yProps = _ref.inputA11yProps,
    maxNumberOfFiles = _ref.maxNumberOfFiles,
    wrapperClassName = _ref.wrapperClassName,
    maxFileSizeKB = _ref.maxFileSizeKB,
    onUpload = _ref.onUpload,
    onChange = _ref.onChange,
    uploadErrors = _ref.uploadErrors,
    inputRef = useRef(null),
    showExplorerDialog = function () {
      var _inputRef$current;
      return null === (_inputRef$current = inputRef.current) || void 0 === _inputRef$current ? void 0 : _inputRef$current.click();
    },
    translator = useTranslator(),
    _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    uploadedFiles = _useState2[0],
    setUploadedFiles = _useState2[1],
    _useState3 = useState(!1),
    _useState4 = _slicedToArray(_useState3, 2),
    fileUploadInactive = _useState4[0],
    setFileUploadInactive = _useState4[1],
    _useState5 = useState(),
    _useState6 = _slicedToArray(_useState5, 2),
    globalError = _useState6[0],
    setGlobalError = _useState6[1],
    _useState7 = useState({}),
    _useState8 = _slicedToArray(_useState7, 2),
    filesErrors = _useState8[0],
    setFilesErrors = _useState8[1],
    resolveValidation = function (files) {
      var _context,
        fileErrors = checkFileErrors(files, translator, messageProps || messages, accept, maxLength, maxFileSizeKB);
      return setFilesErrors(fileErrors), [_Object$keys(fileErrors), _reduceInstanceProperty(_context = _Object$values(fileErrors)).call(_context, function (acc, arr) {
        return _concatInstanceProperty(acc).call(acc, arr);
      }, [])];
    },
    onFileAdded = function (e) {
      for (var newFilesListPath = e.dataTransfer || e.target, newFiles = null == newFilesListPath ? void 0 : newFilesListPath.files, updatedUploadedFiles = _toConsumableArray(uploadedFiles), filenamesAlreadyUploaded = _mapInstanceProperty(uploadedFiles).call(uploadedFiles, function (file) {
          return file.name;
        }), i = 0; i < newFiles.length; i += 1) {
        if (newFilesListPath.files.length + uploadedFiles.length > maxNumberOfFiles) return setGlobalError("fileLimitError"), setFileUploadInactive(!0), void clearInputValue(e);
        _includesInstanceProperty(filenamesAlreadyUploaded).call(filenamesAlreadyUploaded, newFiles[i].name) ? (setGlobalError("duplicateFileError"), setFileUploadInactive(!0)) : updatedUploadedFiles.push(newFiles[i]);
      }
      "number" == typeof maxNumberOfFiles && updatedUploadedFiles.length >= maxNumberOfFiles && setFileUploadInactive(!0);
      var _resolveValidation = resolveValidation(updatedUploadedFiles),
        _resolveValidation2 = _slicedToArray(_resolveValidation, 2),
        filesWithErrors = _resolveValidation2[0],
        filesErrorsMessages = _resolveValidation2[1];
      updatedUploadedFiles.forEach(function (file) {
        _includesInstanceProperty(filesWithErrors).call(filesWithErrors, file.name) || _includesInstanceProperty(filenamesAlreadyUploaded).call(filenamesAlreadyUploaded, file.name) || null == onUpload || onUpload(file);
      }), onChange(updatedUploadedFiles, !0, filesErrorsMessages), setUploadedFiles(updatedUploadedFiles), clearInputValue(e);
    },
    globalErrorNotificationPropsMap = {
      fileLimitError: {
        message: translator(multipleFileUploadMessages.fileLimitMessage, {
          fileLimit: maxNumberOfFiles
        }),
        messageProps: {
          error: multipleFileUploadMessages.fileLimitMessagePrefix
        },
        onClosed: function () {
          setGlobalError(void 0), uploadedFiles.length < maxNumberOfFiles && setFileUploadInactive(!1);
        }
      },
      duplicateFileError: {
        message: translator(multipleFileUploadMessages.duplicatedFileError),
        onClosed: function () {
          setGlobalError(void 0), setFileUploadInactive(!1);
        }
      }
    };
  return React.createElement(DragAndDropWrapper, {
    className: className,
    onDrop: onFileAdded,
    dragAndDropDisabled: disabled || fileUploadInactive
  }, React.createElement("div", {
    className: cx(wrapperClassName, multipleStyles.mobileViewDragAndDropArea, multipleStyles.bottomSpacing)
  }, React.createElement(LargeUploadArea, {
    disabled: disabled || fileUploadInactive,
    messageProps: messageProps || multipleFileUploadMessages,
    buttonType: buttonType,
    buttonSize: buttonSize,
    buttonIcon: buttonIcon,
    onAction: showExplorerDialog,
    imageSource: imageSource,
    variant: "empty",
    genericMessage: {
      fileLimit: maxNumberOfFiles
    },
    fileLimit: maxNumberOfFiles,
    imageClassName: multipleStyles.mobileViewDragAndDropComponents,
    dragAndDropMessageClassName: multipleStyles.mobileViewDragAndDropComponents
  }), globalError && (error = globalError, React.createElement(InlineNotification, _extends({
    id: "".concat(id, "_inline-notification"),
    type: "error",
    className: multipleStyles.notificationError
  }, globalErrorNotificationPropsMap[error]))), React.createElement("input", _extends({
    id: fieldUniqueId,
    type: "file",
    "data-testid": testId || id,
    ref: inputRef,
    accept: accept,
    className: cx(controlClassName, styles.input),
    maxLength: maxLength,
    onChange: onFileAdded,
    disabled: disabled,
    required: required,
    onFocus: onInputFocus,
    onBlur: onInputBlur,
    "data-path": dataPath,
    multiple: !0
  }, inputA11yProps))), React.createElement(MultipleFilesUploadAccordion, {
    id: id,
    uploadedFiles: uploadedFiles,
    uploadState: uploadState,
    uploadErrors: uploadErrors,
    filesErrors: filesErrors,
    total: total,
    completed: completed,
    messageProps: messageProps,
    onActionClick: function (name) {
      return function () {
        !function (fileName) {
          var newDataTransferAfterFileRemoved = _filterInstanceProperty(uploadedFiles).call(uploadedFiles, function (file) {
            return fileName !== file.name;
          });
          setUploadedFiles(newDataTransferAfterFileRemoved), setFileUploadInactive(!1);
          var _resolveValidation3 = resolveValidation(newDataTransferAfterFileRemoved),
            filesErrorsMessages = _slicedToArray(_resolveValidation3, 2)[1];
          null == onFileClear || onFileClear(fileName), newDataTransferAfterFileRemoved.length && onChange(newDataTransferAfterFileRemoved, !0, filesErrorsMessages);
        }(name), setGlobalError(void 0);
      };
    },
    disableFileRemoveButton: disabled
  }));
};
MultipleUploadField.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "MultipleUploadField"
}, MultipleUploadField.__docgenInfo = {
  componentName: "MultipleUploadField",
  packageName: "@jutro/components",
  description: "",
  displayName: "MultipleUploadField",
  methods: [],
  actualName: "MultipleUploadField"
};