import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import { log, warning } from "@jutro/logger";
import { createContextConsumerHOC, logDeprecationMessage } from "@jutro/platform";
import { defaultThemeConfig, themeList, themeNameList } from "@jutro/theme-styles";
import { DynamicStyleSheet } from "./DynamicStyleSheet";
import { parseThemeConfig } from "./parseThemeHelper";
import { ApplyElementClass } from "./ApplyElementClass";
var customStyleSheet = new DynamicStyleSheet();
export var THEMEPROVIDER_DEFAULT = defaultThemeConfig.name;
function addLinkElement(id, href) {
  var link = document.createElement("link");
  link.setAttribute("rel", "stylesheet"), link.setAttribute("type", "text/css"), link.setAttribute("id", id), link.setAttribute("href", href), document.head.appendChild(link);
}
function removeElement(id) {
  var element = document.getElementById(id);
  element && element.parentElement.removeChild(element);
}
export var defaultTheme = {
  name: THEMEPROVIDER_DEFAULT,
  switchTheme: function () {
    log.warning('You should not use "theme.switchTheme()" outside a <ThemeProvider>');
  }
};
export var ThemeContext = React.createContext(defaultTheme);
export var ThemeConfigContext = React.createContext(defaultThemeConfig);
export function withTheme(ComponentToWrap) {
  return log.warning('You should use ThemeContext.Consumer directly, or alternatively "renderWithTheme(renderProp)" to consume theme without using a HOC'), createContextConsumerHOC({
    component: ComponentToWrap,
    context: ThemeContext,
    displayName: "Themed".concat(ComponentToWrap.displayName || ComponentToWrap.name),
    mapContextToProps: function (existingProps, context) {
      return {
        theme: context
      };
    }
  });
}
export function renderWithTheme(renderProp) {
  return React.createElement(ThemeContext.Consumer, null, function (theme) {
    return renderProp(theme);
  });
}
function removeStyleOverrides() {
  removeElement("jutro-variable-overrides"), removeElement("jutro-style-overrides");
}
export var ThemeProvider = function (_Component) {
  _inherits(ThemeProvider, Component);
  var _super = _createSuper(ThemeProvider);
  function ThemeProvider(props) {
    var _this;
    return _classCallCheck(this, ThemeProvider), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "switchTheme", function (newConfig) {
      if (_this.state.config !== newConfig) {
        var newState = _this.createStateFromConfig(newConfig);
        _this.setState(newState, function () {
          _this.removeDynamicStylesheet(), removeStyleOverrides(), _this.createDynamicStylesheet(), newState.internalJutroThemes || _this.appendStyleOverrides();
        });
      }
    }), _defineProperty(_assertThisInitialized(_this), "createStateFromConfig", function (config) {
      var internalJutroThemes = (null == config ? void 0 : config.internalTheme) && _includesInstanceProperty(themeNameList).call(themeNameList, null == config ? void 0 : config.name),
        isUserThemeInInternalFormat = ((null == config ? void 0 : config.rootStyle) || (null == config ? void 0 : config.componentStyles)) && !internalJutroThemes;
      if (!config || isUserThemeInInternalFormat) return {
        internalJutroThemes: !0,
        theme: {
          name: THEMEPROVIDER_DEFAULT,
          switchTheme: _this.switchTheme
        }
      };
      var combinedConfig = function (target) {
        for (var i = 1; i < arguments.length; i++) {
          var source = null != arguments[i] ? arguments[i] : {};
          i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
          }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
          });
        }
        return target;
      }({}, config);
      if (!internalJutroThemes && config.baseTheme) {
        var baseTheme = _findInstanceProperty(themeList).call(themeList, function (theme) {
          return theme.name === config.baseTheme;
        });
        baseTheme ? combinedConfig.rootStyle = baseTheme.rootStyle : warning("Unrecognized baseTheme ".concat(baseTheme));
      }
      var _ref = parseThemeConfig(combinedConfig) || {},
        classNames = _ref.classNames,
        styles = _ref.styles,
        theme = {
          name: config.name,
          switchTheme: _this.switchTheme
        };
      return {
        classNames: classNames,
        config: config,
        styles: styles,
        theme: theme,
        styleOverrides: null == config ? void 0 : config.styleOverrides,
        variableOverrides: null == config ? void 0 : config.variableOverrides,
        internalJutroThemes: internalJutroThemes
      };
    }), _defineProperty(_assertThisInitialized(_this), "getRootStyle", function () {
      var classNames = _this.state.classNames;
      return classNames ? classNames.root : void 0;
    }), _this.state = _this.createStateFromConfig(props.initialConfig), _this;
  }
  return _createClass(ThemeProvider, [{
    key: "componentDidMount",
    value: function () {
      this.createDynamicStylesheet(), this.state.internalJutroThemes || this.appendStyleOverrides(), !this.props.applyLocally && ThemeProvider.bodyStylesMounted && log.warning("Only a single ThemeProvider's styles set may be applied to document's body. Consider applying locally."), this.useBodyStyles && (ThemeProvider.bodyStylesMounted = !0);
    }
  }, {
    key: "componentDidUpdate",
    value: function (prevProps) {
      var initialConfig = this.props.initialConfig,
        prevConfig = prevProps.initialConfig;
      if (this.updateCreatedStateIfNeeded(initialConfig, prevConfig), initialConfig && initialConfig !== prevConfig) {
        var dropTarget = initialConfig.dropTarget;
        dropTarget && logDeprecationMessage("ThemeProvider: ".concat(initialConfig.name, " Theme"), void 0, void 0, dropTarget);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function () {
      this.useBodyStyles && (ThemeProvider.bodyStylesMounted = !1), this.removeDynamicStylesheet(), removeStyleOverrides();
    }
  }, {
    key: "useBodyStyles",
    get: function () {
      return !(this.props.applyLocally || ThemeProvider.bodyStyleMounted);
    }
  }, {
    key: "themeRootStyles",
    get: function () {
      var _context;
      return _filterInstanceProperty(_context = ["themeRoot", this.props.className]).call(_context, Boolean);
    }
  }, {
    key: "appendStyleOverrides",
    value: function () {
      this.state.variableOverrides && addLinkElement("jutro-variable-overrides", this.state.variableOverrides), this.state.styleOverrides && addLinkElement("jutro-style-overrides", this.state.styleOverrides);
    }
  }, {
    key: "createDynamicStylesheet",
    value: function () {
      var styles = this.state.styles;
      styles && (this.stylesheet = customStyleSheet, this.stylesheet.mount(), this.stylesheet.applyRules(styles));
    }
  }, {
    key: "removeDynamicStylesheet",
    value: function () {
      this.stylesheet && (this.stylesheet.unmount(), this.stylesheet = null);
    }
  }, {
    key: "updateCreatedStateIfNeeded",
    value: function (initialConfig, prevConfig) {
      isEqual(initialConfig, prevConfig) || this.switchTheme(initialConfig);
    }
  }, {
    key: "renderContent",
    value: function () {
      var children = this.props.children,
        className = cx(this.themeRootStyles, this.getRootStyle());
      return this.useBodyStyles ? React.createElement(React.Fragment, null, React.createElement(ApplyElementClass, {
        element: document.body,
        className: className
      }), children) : React.createElement("div", {
        className: className
      }, children);
    }
  }, {
    key: "render",
    value: function () {
      return React.createElement(ThemeContext.Provider, {
        value: this.state.theme
      }, React.createElement(ThemeConfigContext.Provider, {
        value: this.state.config
      }, this.renderContent()));
    }
  }]), ThemeProvider;
}();
_defineProperty(ThemeProvider, "propTypes", {
  initialConfig: PropTypes.shape({
    name: PropTypes.string.isRequired,
    baseTheme: PropTypes.string,
    styleOverrides: PropTypes.string,
    variableOverrides: PropTypes.string
  }),
  className: PropTypes.string,
  children: PropTypes.node,
  applyLocally: PropTypes.bool
}), _defineProperty(ThemeProvider, "bodyStylesMounted", !1), renderWithTheme.__docgenInfo = {
  description: "Render by invoking a render prop with 'theme'.\nThis is an alternative to using ThemeContext.Consumer directly. Useful for consuming without\nusing a HOC.\n\n@param {Function} renderProp - render method to be invoked by the ThemeContext\n@returns {React.ReactNode} returns JSX wrapping the invocation of the 'renderProp'",
  methods: [],
  displayName: "renderWithTheme"
}, ThemeProvider.__docgenInfo = {
  description: "A ThemeProvider that can be placed in the app component hierarchy. It takes a theme configuration object\nand injects a 'theme' object into any consumers.\n@extends Component<{}>",
  methods: [{
    name: "useBodyStyles",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "themeRootStyles",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "switchTheme",
    docblock: "Set the theme for this theme provider by providing another theme configuration.\nThis is to change the theme after the initial load at runtime\n\n@param {object} newConfig - new theme configuration object",
    modifiers: [],
    params: [{
      name: "newConfig",
      description: "new theme configuration object",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Set the theme for this theme provider by providing another theme configuration.\nThis is to change the theme after the initial load at runtime"
  }, {
    name: "createStateFromConfig",
    docblock: "create state from config\n@param {object} config configuration for the ThemeProvider\n@returns {object} ThemeProvider state",
    modifiers: [],
    params: [{
      name: "config",
      description: "configuration for the ThemeProvider",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "ThemeProvider state",
      type: {
        name: "object"
      }
    },
    description: "create state from config"
  }, {
    name: "getRootStyle",
    docblock: "Get the root style from a theme. This should be a single string.\n\n@returns {string} root style from theme or default style",
    modifiers: [],
    params: [],
    returns: {
      description: "root style from theme or default style",
      type: {
        name: "string"
      }
    },
    description: "Get the root style from a theme. This should be a single string."
  }, {
    name: "appendStyleOverrides",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "createDynamicStylesheet",
    docblock: "Create dynamic style sheet for configuration styles",
    modifiers: [],
    params: [],
    returns: null,
    description: "Create dynamic style sheet for configuration styles"
  }, {
    name: "removeDynamicStylesheet",
    docblock: "Remove dynamic style sheet",
    modifiers: [],
    params: [],
    returns: null,
    description: "Remove dynamic style sheet"
  }, {
    name: "updateCreatedStateIfNeeded",
    docblock: null,
    modifiers: [],
    params: [{
      name: "initialConfig",
      optional: !1,
      type: null
    }, {
      name: "prevConfig",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "renderContent",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  displayName: "ThemeProvider",
  props: {
    initialConfig: {
      type: {
        name: "shape",
        value: {
          name: {
            name: "string",
            required: !0
          },
          baseTheme: {
            name: "string",
            required: !1
          },
          styleOverrides: {
            name: "string",
            required: !1
          },
          variableOverrides: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "The initial state configuration object"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component class name"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children wrapped by the theme provider"
    },
    applyLocally: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for applying styles locally"
    }
  }
}, renderWithTheme.__docgenInfo = {
  componentName: "renderWithTheme",
  packageName: "@jutro/theme",
  description: "Render by invoking a render prop with 'theme'.\nThis is an alternative to using ThemeContext.Consumer directly. Useful for consuming without\nusing a HOC.",
  displayName: "renderWithTheme",
  methods: [],
  actualName: "renderWithTheme"
}, ThemeProvider.__docgenInfo = {
  componentName: "ThemeProvider",
  packageName: "@jutro/theme",
  description: "A ThemeProvider that can be placed in the app component hierarchy. It takes a theme configuration object\nand injects a 'theme' object into any consumers.",
  displayName: "ThemeProvider",
  methods: [{
    name: "useBodyStyles",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "themeRootStyles",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "switchTheme",
    docblock: "Set the theme for this theme provider by providing another theme configuration.\nThis is to change the theme after the initial load at runtime\n\n@param {object} newConfig - new theme configuration object",
    modifiers: [],
    params: [{
      name: "newConfig",
      description: "new theme configuration object",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Set the theme for this theme provider by providing another theme configuration.\nThis is to change the theme after the initial load at runtime"
  }, {
    name: "createStateFromConfig",
    docblock: "create state from config\n@param {object} config configuration for the ThemeProvider\n@returns {object} ThemeProvider state",
    modifiers: [],
    params: [{
      name: "config",
      description: "configuration for the ThemeProvider",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "ThemeProvider state",
      type: {
        name: "object"
      }
    },
    description: "create state from config"
  }, {
    name: "getRootStyle",
    docblock: "Get the root style from a theme. This should be a single string.\n\n@returns {string} root style from theme or default style",
    modifiers: [],
    params: [],
    returns: {
      description: "root style from theme or default style",
      type: {
        name: "string"
      }
    },
    description: "Get the root style from a theme. This should be a single string."
  }, {
    name: "appendStyleOverrides",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "createDynamicStylesheet",
    docblock: "Create dynamic style sheet for configuration styles",
    modifiers: [],
    params: [],
    returns: null,
    description: "Create dynamic style sheet for configuration styles"
  }, {
    name: "removeDynamicStylesheet",
    docblock: "Remove dynamic style sheet",
    modifiers: [],
    params: [],
    returns: null,
    description: "Remove dynamic style sheet"
  }, {
    name: "updateCreatedStateIfNeeded",
    docblock: null,
    modifiers: [],
    params: [{
      name: "initialConfig",
      optional: !1,
      type: null
    }, {
      name: "prevConfig",
      optional: !1,
      type: null
    }],
    returns: null
  }, {
    name: "renderContent",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "ThemeProvider",
  props: {
    initialConfig: {
      type: {
        name: "shape",
        value: {
          name: {
            name: "string",
            required: !0
          },
          baseTheme: {
            name: "string",
            required: !1
          },
          styleOverrides: {
            name: "string",
            required: !1
          },
          variableOverrides: {
            name: "string",
            required: !1
          }
        }
      },
      required: !1,
      description: "The initial state configuration object"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional component class name"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The component children wrapped by the theme provider"
    },
    applyLocally: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag for applying styles locally"
    }
  }
};