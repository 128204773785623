import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import isPlainObject from "lodash/isPlainObject";
var wrapValidator = function (validator, typeName) {
  var typeChecker = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null;
  return _Object$assign(validator.bind(), {
    typeName: typeName,
    typeChecker: typeChecker,
    isRequired: _Object$assign(validator.isRequired.bind(), {
      typeName: typeName,
      typeChecker: typeChecker,
      typeRequired: !0
    })
  });
};
export var andValidator = function (validators) {
  var name = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "and";
  if (!Array.isArray(validators)) throw new TypeError("and: 2 or more validators are required");
  if (validators.length <= 1) throw new RangeError("and: 2 or more validators are required");
  var validator = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    var firstError = null;
    return validators.some(function (validatorFn) {
      return null != (firstError = validatorFn.apply(void 0, args));
    }), null == firstError ? null : firstError;
  };
  return validator.isRequired = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) args[_key2] = arguments[_key2];
    var firstError = null;
    return validators.some(function (validatorFn) {
      return null != (firstError = validatorFn.isRequired.apply(validatorFn, args));
    }), null == firstError ? null : firstError;
  }, wrapValidator(validator, name, validators);
};
export var shapeValidator = function (shapeTypes) {
  if (!isPlainObject(shapeTypes)) throw new TypeError("shape must be a normal object");
  function shape(props, propName, componentName, location) {
    var propValue = props[propName];
    if (null == propValue) return null;
    for (var _len3 = arguments.length, rest = new Array(_len3 > 4 ? _len3 - 4 : 0), _key3 = 4; _key3 < _len3; _key3++) rest[_key3 - 4] = arguments[_key3];
    for (var key in shapeTypes) {
      var checker = shapeTypes[key];
      if (checker) {
        var _context,
          error = checker.apply(void 0, _concatInstanceProperty(_context = [propValue, key, componentName, location]).call(_context, rest));
        if (error) return error;
      }
    }
    return null;
  }
  return shape.isRequired = function (props, propName, componentName) {
    var _context3,
      _context2,
      propValue = props[propName];
    if (null == propValue) return new TypeError(_concatInstanceProperty(_context2 = "".concat(componentName, ": ")).call(_context2, propName, " is required."));
    for (var _len4 = arguments.length, rest = new Array(_len4 > 3 ? _len4 - 3 : 0), _key4 = 3; _key4 < _len4; _key4++) rest[_key4 - 3] = arguments[_key4];
    return shape.apply(void 0, _concatInstanceProperty(_context3 = [props, propName, componentName]).call(_context3, rest));
  }, wrapValidator(shape, "shape", shapeTypes);
};