import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _toArray from "@babel/runtime-corejs3/helpers/toArray";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from "react";
import flatten from "lodash/flatten";
import { GridLayout } from "./GridLayout";
import { childrenToArray } from "./childrenToArray";
export var mergeColumnsToFirst = function (children, newColumnsNumber) {
  var _context,
    _context2,
    numberOfColumnsToMerge = children.length - newColumnsNumber,
    _children = _toArray(children),
    firstColumn = _children[0],
    restChildren = _sliceInstanceProperty(_children).call(_children, 1),
    columnsToMerge = _spliceInstanceProperty(restChildren).call(restChildren, 0, numberOfColumnsToMerge),
    newFirstColumn = React.cloneElement(firstColumn, void 0, _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(childrenToArray(firstColumn.props.children)), _toConsumableArray(flatten(_mapInstanceProperty(columnsToMerge).call(columnsToMerge, function (column) {
      return childrenToArray(column.props.children);
    })))));
  return _concatInstanceProperty(_context2 = [newFirstColumn]).call(_context2, _toConsumableArray(restChildren));
};
export var mergeColumnsToLast = function (children, newColumnsNumber) {
  return mergeColumnsToFirst(_toConsumableArray(children).reverse(), newColumnsNumber).reverse();
};
export var stackColumnsToFirst = function (children, newColumnsNumber) {
  var _context3,
    numberOfColumnsToStack = children.length - newColumnsNumber,
    childrenCopy = _toConsumableArray(children),
    columnsToStack = _spliceInstanceProperty(childrenCopy).call(childrenCopy, 0, numberOfColumnsToStack + 1);
  return _concatInstanceProperty(_context3 = [React.createElement(GridLayout, {
    id: _mapInstanceProperty(columnsToStack).call(columnsToStack, function (column) {
      return column.props.id;
    }).join("-")
  }, _mapInstanceProperty(columnsToStack).call(columnsToStack, function (column) {
    return React.cloneElement(column, {
      key: column.props.id
    });
  }))]).call(_context3, _toConsumableArray(childrenCopy));
};
export var stackColumnsToLast = function (children, newColumnsNumber) {
  return stackColumnsToFirst(_toConsumableArray(children).reverse(), newColumnsNumber).reverse();
};