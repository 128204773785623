import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
export var getIconClasses = function (icon) {
  var parts = icon.replace(/_/g, "-").split(" ");
  1 === parts.length && (isGwIcon(parts[0]) || isCustomIcon(parts[0]) ? parts.unshift(gwIconsDefaultType) : parts.unshift(materialIconsDefaultType));
  var iconClasses = _mapInstanceProperty(parts).call(parts, function (part, index) {
    var _context;
    return 0 === index || function (icon) {
      return _startsWithInstanceProperty(icon).call(icon, materialIconsIconPrefix);
    }(part) || isGwIcon(part) || isCustomIcon(part) ? part : _concatInstanceProperty(_context = "".concat(materialIconsIconPrefix)).call(_context, part);
  }).join(" ");
  return iconClasses;
};
export var isGwIcon = function (icon) {
  return !(null == icon || !_startsWithInstanceProperty(icon).call(icon, gwIconsIconPrefix));
};
export var isCustomIcon = function (icon) {
  return !(null == icon || !_startsWithInstanceProperty(icon).call(icon, gwCustomIconsIconPrefix));
};
export var isHexColor = function (color) {
  return null != (null == color ? void 0 : color.match(/^#[a-f0-9]{3,6}$/i));
};
var materialIconsDefaultType = "mir",
  materialIconsIconPrefix = "mi-",
  gwIconsDefaultType = "gw";
export var gwIconsIconPrefix = "gw-";
export var gwCustomIconsIconPrefix = "cust-";