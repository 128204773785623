import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SET_FILTER_VALUE, SET_PAGE, SET_PAGE_SIZE, SET_SORTED, SET_COLUMNS, SET_EDITED_ROW } from "./types";
export var reducer = function (state, action) {
  switch (action.type) {
    case SET_FILTER_VALUE:
      var newState = _objectSpread(_objectSpread({}, state), {}, {
        filterValue: action.filterValue
      });
      return action.resetPage && (newState.page = 0), newState;
    case SET_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        page: action.page
      });
    case SET_PAGE_SIZE:
      var _newState = _objectSpread(_objectSpread({}, state), {}, {
        pageSize: action.pageSize
      });
      return action.resetPage && (_newState.page = 0), _newState;
    case SET_SORTED:
      var sorted = action.sorted,
        _newState2 = _objectSpread(_objectSpread({}, state), {}, {
          sorted: null == sorted ? void 0 : _mapInstanceProperty(sorted).call(sorted, function (method) {
            return _objectSpread({}, method);
          })
        });
      return action.resetPage && (_newState2.page = 0), _newState2;
    case SET_COLUMNS:
      return _objectSpread(_objectSpread({}, state), {}, {
        columns: action.columns
      });
    case SET_EDITED_ROW:
      return _objectSpread(_objectSpread({}, state), {}, {
        editedRow: action.rowId
      });
    default:
      return state;
  }
};