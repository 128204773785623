import { defineMessages } from "react-intl";
export var multipleFileUploadStatusBarMessages = defineMessages({
  filesSelectedStatus: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.filesSelectedStatus",
    defaultMessage: "{numberOfSelectedFiles} selected"
  },
  filesUploadingStatus: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.filesUploadingStatus",
    defaultMessage: "{numberOfFilesUploading} uploading..."
  },
  filesUploadedStatus: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.filesUploadedStatus",
    defaultMessage: "{numberOfFilesUploaded} uploaded"
  },
  filesErrorStatus: {
    id: "jutro-components.widgets.inputs.multipleFileUpload.filesErrorStatus",
    defaultMessage: "{numberOfFilesWithError} with error"
  }
});