import React from "react";
import LocaleService from "./LocaleService";
var defaultLocaleContext = {
    availableLocales: [],
    get locale() {
      return LocaleService.getCurrentLocale() || "";
    },
    localeOnChangeCallback: void 0,
    dateLocale: {}
  },
  LocaleContext = React.createContext(defaultLocaleContext);
export var LocaleContextProvider = LocaleContext.Provider;
export var LocaleContextConsumer = LocaleContext.Consumer;
export default LocaleContext;