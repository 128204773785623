import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import FontFaceObserver from "fontfaceobserver";
export function useObserveThemeFontsLoading(elementRef) {
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    latestUpdate = _useState2[0],
    setLatestUpdate = _useState2[1];
  return useEffect(function () {
    if (elementRef.current) {
      var referenceElement,
        _context,
        _context2,
        fonts = (referenceElement = elementRef.current, _concatInstanceProperty(_context = ["Material Icons Round"]).call(_context, _toConsumableArray(_mapInstanceProperty(_context2 = getComputedStyle(referenceElement).getPropertyValue("--GW-FONT-FAMILY").replace(/"/g, "").split(",")).call(_context2, function (fontName) {
          return _trimInstanceProperty(fontName).call(fontName);
        })))),
        isEffectStale = !1;
      return function (fonts, callback) {
        var _ref;
        fonts.forEach((_ref = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(fontName) {
          return _regeneratorRuntime.wrap(function (_context3) {
            for (;;) switch (_context3.prev = _context3.next) {
              case 0:
                return _context3.prev = 0, _context3.next = 3, new FontFaceObserver(fontName).load();
              case 3:
                _context3.next = 8;
                break;
              case 5:
                return _context3.prev = 5, _context3.t0 = _context3.catch(0), _context3.abrupt("return");
              case 8:
                callback(fontName);
              case 9:
              case "end":
                return _context3.stop();
            }
          }, _callee, null, [[0, 5]]);
        })), function (_x) {
          return _ref.apply(this, arguments);
        }));
      }(fonts, debounce(function () {
        isEffectStale || setLatestUpdate(Date.now());
      }, 200)), function () {
        isEffectStale = !0;
      };
    }
  }, [elementRef]), latestUpdate;
}