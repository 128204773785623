import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import { getMessageProp } from "@jutro/platform";
import { messages } from "./InputMaskField.messages";
import { OldFieldComponentValidationImplementation } from "../FieldComponent/OldFieldComponentValidationImplementation";
export var OldInputMaskFieldValidationImplementation = function (_OldFieldComponentVal) {
  _inherits(OldInputMaskFieldValidationImplementation, OldFieldComponentValidationImplementation);
  var _super = _createSuper(OldInputMaskFieldValidationImplementation);
  function OldInputMaskFieldValidationImplementation() {
    return _classCallCheck(this, OldInputMaskFieldValidationImplementation), _super.apply(this, arguments);
  }
  return _createClass(OldInputMaskFieldValidationImplementation, [{
    key: "bind",
    value: function (entityToBind) {
      var _this = this,
        superBoundMethods = _get(_getPrototypeOf(OldInputMaskFieldValidationImplementation.prototype), "bind", this).call(this, entityToBind),
        methods = ["getValidationMessages", "componentDidUpdate"];
      return _reduceInstanceProperty(methods).call(methods, function (acc, method) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, method, _this[method].bind(entityToBind)));
      }, superBoundMethods);
    }
  }, {
    key: "componentDidUpdate",
    value: function (prevProps) {
      var _get2, _context;
      this.props.maskChar === prevProps.maskChar && this.props.mask === prevProps.mask || (this.notifyChange(null), this.pristine = !0, this.focusPristine = !0);
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) args[_key - 1] = arguments[_key];
      (_get2 = _get(_getPrototypeOf(OldInputMaskFieldValidationImplementation.prototype), "componentDidUpdate", this)).call.apply(_get2, _concatInstanceProperty(_context = [this, prevProps]).call(_context, args));
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      var value = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.props.value,
        _this$props = this.props,
        readOnly = _this$props.readOnly,
        onGetValidationMessages = _this$props.onGetValidationMessages,
        messageProps = _this$props.messageProps,
        validationMessages = _get(_getPrototypeOf(OldInputMaskFieldValidationImplementation.prototype), "getValidationMessages", this).call(this, value) || [];
      if (readOnly || this.isEmpty()) return validationMessages;
      if (!this.isComplete(this.applyMaskOnValue(value))) {
        var incompleteInput = getMessageProp("incompleteInput", messageProps, messages);
        return [this.translator(incompleteInput)];
      }
      var customValidationMessages = null == onGetValidationMessages ? void 0 : onGetValidationMessages(value);
      return customValidationMessages ? [this.translator(customValidationMessages)] : validationMessages;
    }
  }]), OldInputMaskFieldValidationImplementation;
}();