import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { warning } from "@jutro/logger";
import { getLanguageFromLocale } from "./locale";
export var getDateLocale = function (locale) {
  var dateLocale, loadedLocale, _context;
  try {
    dateLocale = require("date-fns/locale/".concat(locale, "/index.js"));
  } catch (_unused) {
    try {
      var fallbackLanguageLocale = getLanguageFromLocale(locale);
      dateLocale = require("date-fns/locale/".concat(fallbackLanguageLocale, "/index.js"));
    } catch (_unused2) {
      dateLocale = require("date-fns/locale/".concat("en-US", "/index.js"));
    }
    loadedLocale = dateLocale, warning(_concatInstanceProperty(_context = "date-fns: Requested locale ".concat(locale, " not found; Falling back to ")).call(_context, loadedLocale.code, "."));
  }
  return dateLocale;
};