import React from "react";
import { useTranslator } from "@jutro/locale";
import { getOptionName } from "../../availableValues";
import { messages } from "../LookupField.messages";
import { LookupOptionIcon } from "./LookupOptionIcon";
export var LookupValue = function (_ref) {
  var data = _ref.data,
    _ref$selectProps = _ref.selectProps,
    customStyles = _ref$selectProps.customStyles,
    optionTypesMap = _ref$selectProps.optionTypesMap,
    translator = useTranslator(),
    type = (data || {}).type,
    translatedTypeDisplayName = translator((optionTypesMap[type] || {}).typeDisplayName || messages.unknownType);
  return React.createElement("div", {
    className: customStyles.value
  }, React.createElement(LookupOptionIcon, {
    type: type,
    customStyles: customStyles,
    optionTypesMap: optionTypesMap,
    title: translatedTypeDisplayName
  }), React.createElement("div", {
    className: customStyles.title
  }, translator(getOptionName(data))));
};
LookupValue.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupValue"
}, LookupValue.__docgenInfo = {
  componentName: "LookupValue",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupValue",
  methods: [],
  actualName: "LookupValue"
};