import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["title", "onClick", "className"],
  _excluded2 = ["navLink"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useCallback, useState, useMemo } from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import isUndefined from "lodash/isUndefined";
import isString from "lodash/isString";
import noop from "lodash/noop";
import { IconButton, Link, NotificationAction, Header, HeaderActions, LogoTitle, HelpPopover, LanguageSelector, DropdownMenuAvatar, DropdownMenuAvatarContent, DropdownMenuSeparator, DropdownMenuLink } from "@jutro/components";
import { useAuth } from "@jutro/auth";
import { useBreakpoint } from "@jutro/layout";
import { isIntlShape, useTranslator } from "@jutro/locale";
import { applicationHeaderPropTypes } from "@jutro/prop-types";
import { uniqueInnerId } from "@jutro/platform";
import { AppSwitcher } from "../AppSwitcher/AppSwitcher";
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";
import { BurgerMenuItem } from "../BurgerMenu/BurgerMenuItem";
import { BurgerMenuLink } from "../BurgerMenu/BurgerMenuLink";
import burgerMenuStyles from "../BurgerMenu/BurgerMenu.module.css";
import styles from "./ApplicationHeader.module.css";
import { messages } from "./ApplicationHeader.messages";
import { SearchField } from "./SearchField";
import { BurgerAppSwitcherButton } from "../BurgerMenu/BurgerAppSwitcherButton";
import { NavigationContent } from "../NavBar/NavigationContent";
import { NavigationContext } from "../NavBar/NavigationContext";
import { NavBarAccordion } from "../NavBar/SideNavigation/NavBarAccordion/NavBarAccordion";
function isLinkProp(link) {
  return !(!link || "string" == typeof link) && "href" in link && isIntlShape(link.href);
}
export var ApplicationHeader = function (props) {
  var element,
    _useState = useState(""),
    _useState2 = _slicedToArray(_useState, 2),
    activeRoute = _useState2[0],
    setActiveRoute = _useState2[1],
    translator = useTranslator(),
    auth = useAuth(),
    _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps,
    breakpoint = _useBreakpoint.breakpoint,
    className = breakpointProps.className,
    logoSrc = breakpointProps.logoSrc,
    logoTitle = breakpointProps.logoTitle,
    logoAlt = breakpointProps.logoAlt,
    _breakpointProps$logo = breakpointProps.logoUrl,
    logoUrl = void 0 === _breakpointProps$logo ? "/" : _breakpointProps$logo,
    onSearchValueChange = breakpointProps.onSearchValueChange,
    onLoadValues = breakpointProps.onLoadValues,
    showNotifications = breakpointProps.showNotifications,
    notificationChildren = breakpointProps.notificationChildren,
    showAvatarProp = breakpointProps.showAvatar,
    avatarChildren = breakpointProps.avatarChildren,
    avatarProps = breakpointProps.avatarProps,
    searchFieldPlaceholder = breakpointProps.searchFieldPlaceholder,
    showAppSwitcher = breakpointProps.showAppSwitcher,
    _breakpointProps$appS = breakpointProps.appSwitcherItems,
    appSwitcherItems = void 0 === _breakpointProps$appS ? [] : _breakpointProps$appS,
    useAuthInfo = breakpointProps.useAuthInfo,
    _breakpointProps$comm = breakpointProps.commonAvatarRoutes,
    commonAvatarRoutes = void 0 === _breakpointProps$comm ? [] : _breakpointProps$comm,
    showHelp = breakpointProps.showHelp,
    helpUrl = breakpointProps.helpUrl,
    _breakpointProps$help = breakpointProps.helpPopoverItems,
    helpPopoverItems = void 0 === _breakpointProps$help ? [] : _breakpointProps$help,
    searchAvailableValues = breakpointProps.searchAvailableValues,
    searchOptionTypes = breakpointProps.searchOptionTypes,
    callbackMap = breakpointProps.callbackMap,
    appSwitcherFooterText = breakpointProps.appSwitcherFooterText,
    appSwitcherFooterUrl = breakpointProps.appSwitcherFooterUrl,
    onAppSwitcherFooterClick = breakpointProps.onAppSwitcherFooterClick,
    appSwitcherSearchEnabled = breakpointProps.appSwitcherSearchEnabled,
    appSwitcherCollapsibleGroupsThreshold = breakpointProps.appSwitcherCollapsibleGroupsThreshold,
    appSwitcherHideFooter = breakpointProps.appSwitcherHideFooter,
    burgerMenuRoutes = breakpointProps.burgerMenuRoutes,
    CustomNavigationItem = breakpointProps.renderBurgerMenuCustomItem,
    _breakpointProps$rend = breakpointProps.renderCustomComponent,
    CustomActionItem = void 0 === _breakpointProps$rend ? React.Fragment : _breakpointProps$rend,
    showLanguageSelector = breakpointProps.showLanguageSelector,
    languageSelectorProps = breakpointProps.languageSelectorProps,
    noDeprecationWarnings = breakpointProps.noDeprecationWarnings,
    classes = cx(styles.applicationHeader, className),
    shouldShowHelp = !(void 0 !== showHelp || !helpUrl) || showHelp,
    showAvatar = showAvatarProp && (auth && auth.authenticated || !useAuthInfo),
    isDesktop = "desktop" === breakpoint,
    headerLogo = (logoSrc || logoTitle) && React.createElement("div", {
      className: cx(styles.logoWrapper, _defineProperty({}, styles.logoWrapperHasSearch, onSearchValueChange || !isDesktop))
    }, React.createElement(NavLink, {
      to: logoUrl,
      className: styles.applicationLogoTitleLink
    }, React.createElement(LogoTitle, {
      noDeprecationWarnings: ["LogoTitle"],
      src: logoSrc,
      logoClassName: styles.applicationLogo,
      titleClassName: styles.logoTitleText,
      alt: translator(logoAlt) || translator(messages.logoAlt),
      title: logoTitle
    }))),
    headerNotifications = showNotifications && React.createElement("span", null, React.createElement(NotificationAction, {
      id: "notificationAction",
      title: messages.notifications,
      isFlipEnabled: !1,
      renderTrigger: function (toggle, ref, hasShownPopover) {
        return React.createElement(IconButton, {
          id: "notificationButton",
          icon: "gw-notifications",
          iconColor: "light",
          ref: ref,
          onClick: toggle,
          className: styles.headerActionButton,
          "aria-label": translator(messages.notificationButton),
          "aria-haspopup": "menu",
          "aria-expanded": hasShownPopover,
          noDeprecationWarnings: ["IconButton"]
        });
      }
    }, notificationChildren)),
    iconButtonProps = {
      id: "helpButton",
      icon: "gw-help",
      iconColor: "light",
      className: styles.headerActionButton,
      "aria-label": translator(messages.help)
    },
    renderTrigger = useCallback(function (toggle, ref, hasShownPopover) {
      return React.createElement(IconButton, _extends({}, iconButtonProps, {
        ref: ref,
        onClick: toggle,
        "aria-haspopup": "menu",
        "aria-expanded": hasShownPopover,
        "data-testid": "help-icon",
        noDeprecationWarnings: ["IconButton"]
      }));
    }, [iconButtonProps]),
    helpPopoverId = uniqueInnerId("ApplicationHeader", "helpPopoverId").helpPopoverId,
    helpPopover = shouldShowHelp && React.createElement("span", null, React.createElement(HelpPopover, {
      id: helpPopoverId,
      renderTrigger: renderTrigger,
      footerLink: helpUrl,
      noDeprecationWarnings: noDeprecationWarnings
    }, helpPopoverItems)),
    helpLink = shouldShowHelp && React.createElement(function (_ref) {
      var children = _ref.children,
        url = isLinkProp(helpUrl) ? helpUrl.href : helpUrl;
      return React.createElement(Link, {
        href: url,
        textClassName: styles.helpIcon,
        target: "_blank"
      }, children);
    }, null, React.createElement(IconButton, _extends({}, iconButtonProps, {
      "data-testid": "help-icon",
      noDeprecationWarnings: ["IconButton"]
    }))),
    helpItem = helpPopoverItems.length > 0 ? helpPopover : helpLink,
    languageSelector = showLanguageSelector && React.createElement(LanguageSelector, _extends({
      id: "languageSwitcher",
      hideDropdownIndicator: !0,
      hideLabel: !isDesktop,
      toggleClassName: styles.languageSelectorButton
    }, languageSelectorProps)),
    renderHeaderSearchField = function (searchColorTheme) {
      return React.createElement(SearchField, {
        isDarkTheme: "dark" === searchColorTheme,
        onSearchValueChange: onSearchValueChange,
        onLoadValues: onLoadValues,
        searchAvailableValues: searchAvailableValues,
        optionTypes: searchOptionTypes,
        searchFieldPlaceholder: searchFieldPlaceholder
      });
    },
    handleCallbackRedirect = function (callback, shouldRedirect) {
      return shouldRedirect ? callback : function (callback) {
        return function (evt) {
          var _context;
          evt.preventDefault();
          for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) args[_key - 1] = arguments[_key];
          callback.apply(void 0, _concatInstanceProperty(_context = [evt]).call(_context, args));
        };
      }(callback);
    },
    commonAvatarContent = _mapInstanceProperty(commonAvatarRoutes).call(commonAvatarRoutes, function (_ref2, index) {
      var title = _ref2.title,
        onClick = _ref2.onClick,
        linkClassName = _ref2.className,
        rest = _objectWithoutProperties(_ref2, _excluded),
        to = rest.to,
        href = rest.href,
        shouldRedirect = !isUndefined(to || href),
        callback = isString(onClick) ? null == callbackMap ? void 0 : callbackMap[onClick] : onClick,
        resolvedCallbacks = callback ? {
          onClick: handleCallbackRedirect(callback, shouldRedirect)
        } : {},
        dropdownProps = _objectSpread(_objectSpread({}, rest), resolvedCallbacks);
      return React.createElement(DropdownMenuLink, _extends({
        key: "".concat(index),
        className: cx(linkClassName, burgerMenuStyles.sidebarItem, burgerMenuStyles.sidebarItemInteractive)
      }, dropdownProps), title);
    }),
    dropdownContentClassNames = {
      headerClassName: cx(burgerMenuStyles.sidebarItem, styles.burgerMenuAvatar),
      authLinksClassName: cx(burgerMenuStyles.sidebarItem, burgerMenuStyles.sidebarItemInteractive),
      separatorClassName: burgerMenuStyles.sidebarSeparator
    },
    getHeaderAvatar = useCallback(function () {
      return showAvatar ? React.createElement(DropdownMenuAvatar, _extends({
        id: "dropdownavatar",
        dropUp: !1,
        alignRight: !0,
        useAuthInfo: useAuthInfo,
        className: "withLightFocusOnApplicationHeader"
      }, dropdownContentClassNames, avatarProps), avatarChildren, commonAvatarContent) : null;
    }, [avatarChildren, avatarProps, commonAvatarContent, useAuthInfo, showAvatar]),
    _renderAppSwitcher = function (isDropdown, onRouteClick) {
      return React.createElement(AppSwitcher, {
        id: "appSwitcher",
        items: appSwitcherItems,
        onRouteClick: onRouteClick || void 0,
        iconColor: "light",
        iconClassName: styles.headerActionButton,
        footerText: appSwitcherFooterText,
        footerUrl: appSwitcherFooterUrl,
        onFooterClick: onAppSwitcherFooterClick,
        isDropdown: isDropdown,
        searchEnabled: appSwitcherSearchEnabled,
        collapsibleGroupsThreshold: appSwitcherCollapsibleGroupsThreshold,
        hideFooter: appSwitcherHideFooter
      });
    },
    addBurgerMenuStyles = useCallback(function (_ref3) {
      var navLink = _ref3.navLink;
      return _objectSpread(_objectSpread({}, _objectWithoutProperties(_ref3, _excluded2)), {}, {
        navLink: _objectSpread(_objectSpread({}, navLink), {}, {
          className: cx(navLink && navLink.className, burgerMenuStyles.sidebarItem)
        })
      });
    }, [styles.burgerMenuLink]),
    navigationContext = useMemo(function () {
      return {
        activeRoute: activeRoute,
        setActiveRoute: setActiveRoute,
        isCollapsed: !1,
        expand: noop,
        collapse: noop
      };
    }, [activeRoute, setActiveRoute]),
    mobileBurgerContent = [onSearchValueChange && React.createElement(BurgerMenuItem, {
      id: "burger-search-menu",
      key: "burger-search-menu",
      isContainerOnly: !0,
      className: styles.burgerMenuSidebarStyledContent
    }, renderHeaderSearchField()), burgerMenuRoutes && React.createElement(React.Fragment, {
      key: "burger-menu-routes"
    }, React.createElement(DropdownMenuSeparator, {
      className: burgerMenuStyles.sidebarSeparator
    }), React.createElement(NavigationContext.Provider, {
      value: navigationContext
    }, React.createElement(NavigationContent, {
      routes: _mapInstanceProperty(burgerMenuRoutes).call(burgerMenuRoutes, addBurgerMenuStyles),
      nestedRoutesComponent: NavBarAccordion
    }))), CustomNavigationItem && React.createElement(React.Fragment, {
      key: "burger-menu-custom-component"
    }, React.createElement(DropdownMenuSeparator, {
      className: burgerMenuStyles.sidebarSeparator
    }), React.createElement("div", {
      className: burgerMenuStyles.sidebarItem
    }, React.createElement(CustomNavigationItem, null))), shouldShowHelp && helpUrl && React.createElement(BurgerMenuLink, {
      id: "burger-menu-help",
      key: "burger-menu-help",
      href: isLinkProp(helpUrl) ? helpUrl.href : helpUrl,
      target: "_blank",
      rel: "noopener noreferrer",
      "data-testid": "help-link"
    }, translator(messages.help)), showAppSwitcher && React.createElement(BurgerAppSwitcherButton, {
      icon: "gw-apps",
      key: "burger-app-switcher",
      title: messages.switchApplication,
      renderAppSwitcher: function (onRouteClick) {
        return _renderAppSwitcher(!1, onRouteClick);
      }
    }), showAvatar && React.createElement(React.Fragment, {
      key: "burger-avatar"
    }, React.createElement(DropdownMenuSeparator, {
      className: burgerMenuStyles.sidebarSeparator
    }), React.createElement(DropdownMenuAvatarContent, _extends({
      useAuthInfo: useAuthInfo
    }, dropdownContentClassNames, avatarProps), (avatarChildren || commonAvatarContent.length > 0) && React.createElement(React.Fragment, null, avatarChildren, commonAvatarContent)))],
    appSwitcher = showAppSwitcher && React.createElement("span", null, _renderAppSwitcher(!0)),
    headerActionsCustomItem = React.createElement(CustomActionItem, null);
  return !breakpoint || isDesktop ? React.createElement(Header, {
    className: classes,
    noDeprecationWarnings: ["Header"]
  }, headerLogo, (element = renderHeaderSearchField("dark"), onSearchValueChange && React.createElement("div", {
    className: styles.centerWrapper
  }, element)), React.createElement(HeaderActions, {
    className: styles.headerActions,
    noDeprecationWarnings: ["HeaderActions"]
  }, headerActionsCustomItem, languageSelector, helpItem, appSwitcher, headerNotifications, getHeaderAvatar())) : React.createElement(Header, {
    className: classes,
    noDeprecationWarnings: ["Header"]
  }, React.createElement(BurgerMenu, {
    className: styles.burgerMenuWrapper,
    buttonClassName: styles.headerActionButton,
    burgerContent: mobileBurgerContent
  }), headerLogo, React.createElement(HeaderActions, {
    className: styles.headerActions,
    noDeprecationWarnings: ["HeaderActions"]
  }, languageSelector, headerNotifications));
};
ApplicationHeader.displayName = "ApplicationHeader", ApplicationHeader.propTypes = applicationHeaderPropTypes, ApplicationHeader.defaultProps = {
  showNotifications: !0,
  showAvatar: !0,
  showAppSwitcher: !0,
  searchFieldPlaceholder: messages.searchFieldPlaceholder,
  useAuthInfo: !0,
  callbackMap: {}
}, ApplicationHeader.__docgenInfo = {
  description: "ApplicationHeader is the default header for all Guidewire applications.\nIt contains all the typical controls for Guidewire apps.\nIf you want to add other controls to the header, use the Header component instead\n\n@metadataType container\n@param props",
  methods: [],
  displayName: "ApplicationHeader",
  props: {
    showNotifications: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    showAvatar: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    showAppSwitcher: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    searchFieldPlaceholder: {
      defaultValue: {
        value: "messages.searchFieldPlaceholder",
        computed: !0
      },
      required: !1
    },
    useAuthInfo: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    callbackMap: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["@jutro/prop-types"]
}, ApplicationHeader.__docgenInfo = {
  componentName: "ApplicationHeader",
  packageName: "@jutro/router",
  description: "ApplicationHeader is the default header for all Guidewire applications.\nIt contains all the typical controls for Guidewire apps.\nIf you want to add other controls to the header, use the Header component instead",
  displayName: "ApplicationHeader",
  methods: [],
  actualName: "ApplicationHeader",
  metadataType: "container",
  props: {
    showNotifications: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    showAvatar: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    showAppSwitcher: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    searchFieldPlaceholder: {
      defaultValue: {
        value: "messages.searchFieldPlaceholder",
        computed: !0
      },
      required: !1
    },
    useAuthInfo: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      required: !1
    },
    callbackMap: {
      defaultValue: {
        value: "{}",
        computed: !1
      },
      required: !1
    }
  },
  composes: ["@jutro/prop-types"]
};