import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import assignWith from "lodash/assignWith";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape, linkShape } from "@jutro/prop-types";
import { Link } from "../Link/Link";
import styles from "./PopoverContainer.module.css";
import { messages } from "./PopoverContainer.messages";
var popoverContainerPropTypes = {
  title: intlMessageShape,
  headerLink: linkShape,
  footerLink: linkShape,
  className: PropTypes.string,
  children: PropTypes.node,
  internalClassNames: PropTypes.shape({
    header: PropTypes.string,
    title: PropTypes.string,
    headerLink: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
    footerLink: PropTypes.string
  }),
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool
};
export var PopoverContainer = function (_ref) {
  var title = _ref.title,
    headerLink = _ref.headerLink,
    footerLink = _ref.footerLink,
    children = _ref.children,
    className = _ref.className,
    internalClassNames = _ref.internalClassNames,
    hideHeader = _ref.hideHeader,
    hideFooter = _ref.hideFooter,
    translator = useTranslator(),
    mergedStyles = useMemo(function () {
      return internalClassNames ? assignWith(_objectSpread({}, styles), null != internalClassNames ? internalClassNames : {}, function (popoverContainerStyle, additionalStyle) {
        return additionalStyle ? cx(popoverContainerStyle, additionalStyle) : popoverContainerStyle;
      }) : styles;
    }, [internalClassNames]);
  return React.createElement("div", {
    className: cx(mergedStyles.popoverContainer, className),
    role: "region",
    "aria-live": "assertive",
    "aria-label": translator(messages.ariaTitle, {
      title: translator(title)
    })
  }, !hideHeader && React.createElement("div", {
    className: mergedStyles.header,
    "data-testid": "popover-container-header"
  }, React.createElement("span", {
    className: mergedStyles.title
  }, translator(title)), renderLink(_objectSpread(_objectSpread({}, headerLink), {}, {
    className: mergedStyles.headerLink,
    textClassName: mergedStyles.headerText
  }), translator)), React.createElement("div", {
    className: mergedStyles.body
  }, children), !hideFooter && React.createElement("div", {
    className: mergedStyles.footer,
    "data-testid": "popover-container-footer"
  }, React.createElement("span", {
    className: mergedStyles.footerLinkContainer
  }, renderLink(_objectSpread(_objectSpread({}, footerLink), {}, {
    className: mergedStyles.footerLink,
    textClassName: mergedStyles.footerText
  }), translator))));
};
function renderLink(_ref2, translator) {
  var label = _ref2.label,
    onClick = _ref2.onClick,
    href = _ref2.href,
    className = _ref2.className,
    textClassName = _ref2.textClassName;
  if (!label || !onClick && !href) return null;
  var props = {
    children: translator(label),
    href: href,
    onClick: onClick,
    className: className,
    textClassName: textClassName
  };
  return React.createElement(Link, props);
}
PopoverContainer.propTypes = popoverContainerPropTypes, PopoverContainer.defaultProps = {
  title: messages.title,
  hideHeader: !1,
  hideFooter: !1
}, PopoverContainer.__docgenInfo = {
  description: "PopoverContainer\n@type {React.FC<PropTypes.InferProps<typeof popoverContainerPropTypes>>}",
  methods: [],
  displayName: "PopoverContainer",
  props: {
    title: {
      defaultValue: {
        value: "messages.title",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Popover Container title"
    },
    hideHeader: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "When set to true, hides the header"
    },
    hideFooter: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "When set to true, hides the footer"
    },
    headerLink: {
      type: {
        name: "custom",
        raw: "linkShape"
      },
      required: !1,
      description: "Description for the header link"
    },
    footerLink: {
      type: {
        name: "custom",
        raw: "linkShape"
      },
      required: !1,
      description: "Description for the footer link"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Popover Container content"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          header: {
            name: "string",
            description: "CSS class name for popover header",
            required: !1
          },
          title: {
            name: "string",
            description: "CSS class name for popover title",
            required: !1
          },
          headerLink: {
            name: "string",
            description: "CSS class name for popover header link",
            required: !1
          },
          body: {
            name: "string",
            description: "CSS class name for popover body",
            required: !1
          },
          footer: {
            name: "string",
            description: "CSS class name for popover footer",
            required: !1
          },
          footerLink: {
            name: "string",
            description: "CSS class name for popover footer link",
            required: !1
          }
        }
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of component's styles"
    }
  }
}, PopoverContainer.__docgenInfo = {
  componentName: "PopoverContainer",
  packageName: "@jutro/components",
  description: "PopoverContainer",
  displayName: "PopoverContainer",
  methods: [],
  actualName: "PopoverContainer",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Popover Container title",
      defaultValue: {
        value: "messages.title",
        computed: !0
      }
    },
    headerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          },
          onClick: {
            name: "func",
            required: !1
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Description for the header link"
    },
    footerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          },
          onClick: {
            name: "func",
            required: !1
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Description for the footer link"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Popover Container content"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          header: {
            name: "string",
            description: "CSS class name for popover header",
            required: !1
          },
          title: {
            name: "string",
            description: "CSS class name for popover title",
            required: !1
          },
          headerLink: {
            name: "string",
            description: "CSS class name for popover header link",
            required: !1
          },
          body: {
            name: "string",
            description: "CSS class name for popover body",
            required: !1
          },
          footer: {
            name: "string",
            description: "CSS class name for popover footer",
            required: !1
          },
          footerLink: {
            name: "string",
            description: "CSS class name for popover footer link",
            required: !1
          }
        }
      },
      required: !1,
      description: "Map of CSS class names for overriding individual parts of component's styles"
    },
    hideHeader: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When set to true, hides the header",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    hideFooter: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "When set to true, hides the footer",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  }
}, renderLink.__docgenInfo = {
  componentName: "renderLink",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderLink",
  methods: [],
  actualName: "renderLink"
};