import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { useState, useCallback, useEffect, useMemo, createContext, useContext } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import noop from "lodash/noop";
import { createContextConsumerHOC } from "@jutro/platform";
import { log } from "@jutro/logger";
import { EventEmitter } from "eventemitter3";
import { useModalPromise } from "./useModalPromise";
import { ModalNext } from "./ModalNext";
import { AlertModal } from "./AlertModal/AlertModal";
import { ConfirmationModal } from "./ConfirmationModal/ConfirmationModal";
var methods = ["showAlert", "showConfirm", "showModal"];
export var ModalNextEmitter = function (_EventEmitter) {
  _inherits(ModalNextEmitter, EventEmitter);
  var _super = _createSuper(ModalNextEmitter);
  function ModalNextEmitter() {
    var _this;
    return _classCallCheck(this, ModalNextEmitter), _this = _super.call(this), methods.forEach(function (method) {
      _this[method] = function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return new _Promise(function (resolve, reject) {
          _this.emit(method, {
            method: method,
            args: args,
            resolve: resolve,
            reject: reject
          });
        });
      };
    }), _this;
  }
  return _createClass(ModalNextEmitter);
}();
var createNoProviderWarning = function (methodName) {
  return function () {
    var message = 'You should not use "'.concat(methodName, '()" outside a <ModalNextProvider>');
    return log.error(message), _Promise.reject(new Error(message));
  };
};
function getModalRoot() {
  var modalRoot = document.getElementById("modalRoot");
  if (modalRoot) return modalRoot;
  var deprecatedModalRoot = document.getElementById("confirmation");
  return deprecatedModalRoot ? (log.warning('ModalNextProvider: Found deprecated <div id="confirmation">. Please use <div id="modalRoot"> instead.'), deprecatedModalRoot) : (log.warning('ModalNextProvider: Unable to find <div id="modalRoot">. Please add at the bottom of the <body> in your index.html.'), document.body);
}
export var ModalNextContext = createContext({
  showAlert: createNoProviderWarning("showAlert"),
  showConfirm: createNoProviderWarning("showConfirm"),
  showModal: createNoProviderWarning("showModal")
});
export var withModalContext = function (Component) {
  return createContextConsumerHOC({
    component: Component,
    context: ModalNextContext,
    displayName: "withModalContext(".concat(Component.displayName || Component.name, ")"),
    mapContextToProps: function (props, modalContext) {
      return {
        modalContext: modalContext
      };
    }
  });
};
export var ModalNextProvider = function (_ref) {
  var emitter = _ref.emitter,
    children = _ref.children,
    appElementId = _ref.appElementId,
    modalFunctions = _ref.modalFunctions,
    _useModalPromise = useModalPromise(),
    _useModalPromise2 = _slicedToArray(_useModalPromise, 4),
    isOpenAlert = _useModalPromise2[0],
    showAlertPromise = _useModalPromise2[1],
    resolveAlert = _useModalPromise2[2],
    rejectAlert = _useModalPromise2[3],
    _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    alertRender = _useState2[0].alertRender,
    setAlertState = _useState2[1],
    _useModalPromise3 = useModalPromise(),
    _useModalPromise4 = _slicedToArray(_useModalPromise3, 4),
    isOpenModal = _useModalPromise4[0],
    showModalPromise = _useModalPromise4[1],
    resolveModal = _useModalPromise4[2],
    rejectModal = _useModalPromise4[3],
    _useState3 = useState({}),
    _useState4 = _slicedToArray(_useState3, 2),
    modalRender = _useState4[0].modalRender,
    setModalState = _useState4[1];
  ModalNext.handleAfterClose = useCallback(function () {
    isOpenAlert || setAlertState({}), isOpenModal || setModalState({});
  }, [isOpenAlert, isOpenModal, setAlertState, setModalState]);
  var context = useMemo(function () {
    return function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
          _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }({
      showAlert: function (_ref2) {
        var status = _ref2.status,
          icon = _ref2.icon,
          title = _ref2.title,
          message = _ref2.message,
          confirmButtonText = _ref2.confirmButtonText;
        return setAlertState({
          alertRender: function (alertProps) {
            return React.createElement(AlertModal, _extends({}, alertProps, {
              parentSelector: getModalRoot,
              title: title,
              message: message,
              status: status,
              icon: icon,
              confirmButtonText: confirmButtonText
            }));
          }
        }), showAlertPromise();
      },
      showConfirm: function (_ref3) {
        var status = _ref3.status,
          icon = _ref3.icon,
          title = _ref3.title,
          message = _ref3.message,
          confirmButtonText = _ref3.confirmButtonText,
          cancelButtonText = _ref3.cancelButtonText;
        return setAlertState({
          alertRender: function (alertProps) {
            return React.createElement(ConfirmationModal, _extends({}, alertProps, {
              parentSelector: getModalRoot,
              title: title,
              message: message,
              status: status,
              icon: icon,
              confirmButtonText: confirmButtonText,
              cancelButtonText: cancelButtonText
            }));
          }
        }), showAlertPromise();
      },
      showModal: function (component) {
        return setModalState({
          modalRender: function (modalProps) {
            return React.cloneElement(component, modalProps);
          }
        }), showModalPromise();
      }
    }, modalFunctions);
  }, [showAlertPromise, setAlertState, showModalPromise, setModalState, modalFunctions]);
  return useEffect(function () {
    document.getElementById(appElementId) && ReactModal.setAppElement("#".concat(appElementId));
  }, [appElementId]), useEffect(function () {
    if (!emitter) return noop;
    var methodListener = function (_ref4) {
      var method = _ref4.method,
        args = _ref4.args,
        resolve = _ref4.resolve,
        reject = _ref4.reject;
      context[method].apply(context, _toConsumableArray(args)).then(resolve).catch(reject);
    };
    return methods.forEach(function (method) {
      return emitter.addListener(method, methodListener, context);
    }), function () {
      methods.forEach(function (method) {
        return emitter.removeListener(method, methodListener, context);
      });
    };
  }, [emitter, context]), React.createElement(ModalNextContext.Provider, {
    value: context
  }, children, alertRender && alertRender({
    isOpen: isOpenAlert,
    onResolve: resolveAlert,
    onReject: rejectAlert
  }), modalRender && modalRender({
    isOpen: isOpenModal,
    onResolve: resolveModal,
    onReject: rejectModal
  }));
};
ModalNextProvider.propTypes = {
  appElementId: PropTypes.string,
  children: PropTypes.node,
  emitter: PropTypes.instanceOf(ModalNextEmitter)
}, ModalNextProvider.defaultProps = {
  appElementId: "root"
};
export var useModal = function () {
  var context = useContext(ModalNextContext);
  if (!context) throw new Error("useModal should be used only in components wrapped in the ModalNextProvider");
  return context;
};
ModalNextProvider.__docgenInfo = {
  description: "@type {React.FC<import('/types').ModalNextProviderProps>}",
  methods: [],
  displayName: "ModalNextProvider",
  props: {
    appElementId: {
      defaultValue: {
        value: "'root'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: 'ID of the root element of your app, used to hide the app from accessibility tools on modal popup.\nThe root element must not be a parent of the modal root (from getModalRoot()), or else the content of the modal will be hidden as well.\nDefaults to `"root"`'
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: ""
    },
    emitter: {
      type: {
        name: "instanceOf",
        value: "ModalNextEmitter"
      },
      required: !1,
      description: "Instance of ModalNextEmitter passed down to ModalNextProvider for accessing modal functions from outside the scope of the ModalNextProvider"
    }
  }
}, ModalNextProvider.__docgenInfo = {
  componentName: "ModalNextProvider",
  packageName: "@jutro/components",
  description: "",
  displayName: "ModalNextProvider",
  methods: [],
  actualName: "ModalNextProvider",
  props: {
    appElementId: {
      type: {
        name: "string"
      },
      required: !1,
      description: 'ID of the root element of your app, used to hide the app from accessibility tools on modal popup.\nThe root element must not be a parent of the modal root (from getModalRoot()), or else the content of the modal will be hidden as well.\nDefaults to `"root"`',
      defaultValue: {
        value: "'root'",
        computed: !1
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: ""
    },
    emitter: {
      type: {
        name: "instanceOf",
        value: "ModalNextEmitter"
      },
      required: !1,
      description: "Instance of ModalNextEmitter passed down to ModalNextProvider for accessing modal functions from outside the scope of the ModalNextProvider"
    }
  }
};