import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import React, { useState, useEffect } from "react";
import range from "lodash/range";
import { getFormattedMonthsForLocale } from "@jutro/locale";
import { addMonths, subMonths, setMonth, setYear } from "date-fns";
import { DropdownSelectField } from "../../DropdownSelectField/DropdownSelectField";
import { NextButton, PrevButton } from "../GenericDatePicker/Header";
import styles from "./CustomHeader.module.css";
import { messages } from "./DateField.messages";
export var CustomHeader = React.memo(function (_ref) {
  var date = _ref.date,
    changeYear = _ref.changeYear,
    changeMonth = _ref.changeMonth,
    decreaseMonth = _ref.decreaseMonth,
    increaseMonth = _ref.increaseMonth,
    locale = _ref.locale,
    prevMonthButtonDisabled = _ref.prevMonthButtonDisabled,
    nextMonthButtonDisabled = _ref.nextMonthButtonDisabled,
    minYear = _ref.minYear,
    maxYear = _ref.maxYear,
    _useState = useState(date),
    _useState2 = _slicedToArray(_useState, 2),
    displayedMonth = _useState2[0],
    setDisplayedMonth = _useState2[1];
  useEffect(function () {
    setDisplayedMonth(date);
  }, [date]);
  var listOfValues,
    listOfMonths = getFormattedMonthsForLocale(locale),
    dropdownClassNames = {
      control: styles.control,
      controlFocused: styles.controlFocused,
      menuList: styles.menu,
      option: styles.option,
      selectIcon: styles.selectIcon
    };
  return React.createElement("div", {
    className: styles.dateHeader
  }, React.createElement(PrevButton, {
    onClick: function () {
      decreaseMonth(), setDisplayedMonth(subMonths(displayedMonth, 1));
    },
    disabled: prevMonthButtonDisabled,
    label: messages.previous
  }), React.createElement("div", {
    className: styles.pickersWrapper
  }, React.createElement(DropdownSelectField, {
    className: styles.monthDropdownSelect,
    id: "month-dropdown",
    value: displayedMonth.getMonth(),
    availableValues: (listOfValues = listOfMonths, _mapInstanceProperty(listOfValues).call(listOfValues, function (item, index) {
      return {
        name: item,
        code: index
      };
    })),
    internalClassNames: dropdownClassNames,
    showOptional: !1,
    onValueChange: function (value) {
      setDisplayedMonth(setMonth(displayedMonth, value)), changeMonth(value);
    },
    usePortal: !1,
    label: messages.selectMonth,
    hideLabel: !0
  }), React.createElement(DropdownSelectField, {
    className: styles.yearDropdownSelect,
    id: "year-dropdown",
    value: displayedMonth.getFullYear(),
    availableValues: function () {
      return listOfValues = range(minYear, maxYear + 1), values = _mapInstanceProperty(listOfValues).call(listOfValues, function (item) {
        return {
          name: item,
          code: item
        };
      }), selectedYear = date.getFullYear(), _findInstanceProperty(values).call(values, function (_ref2) {
        return _ref2.name === selectedYear;
      }) || values.push({
        name: selectedYear,
        code: selectedYear
      }), values;
      var listOfValues, values, selectedYear;
    }(),
    internalClassNames: dropdownClassNames,
    showOptional: !1,
    onValueChange: function (value) {
      setDisplayedMonth(setYear(displayedMonth, value)), changeYear(value);
    },
    usePortal: !1,
    label: messages.selectYear,
    hideLabel: !0,
    searchable: !0
  })), React.createElement(NextButton, {
    onClick: function () {
      increaseMonth(), setDisplayedMonth(addMonths(displayedMonth, 1));
    },
    disabled: nextMonthButtonDisabled,
    label: messages.next
  }));
});