import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./InlineFieldLayout.module.css";
var inlineFieldLayoutPropTypes = {
  labelContent: PropTypes.node,
  controlContent: PropTypes.node,
  messageContent: PropTypes.node,
  className: PropTypes.string
};
export var InlineFieldLayout = function (props) {
  var labelContent = props.labelContent,
    controlContent = props.controlContent,
    messageContent = props.messageContent,
    className = props.className,
    automationProperty = props.automationProperty;
  return React.createElement("div", _extends({
    className: cx(styles.inlineFieldLayout, className)
  }, automationProperty), labelContent, React.createElement("div", {
    className: styles.controls
  }, controlContent, React.createElement("div", {
    className: styles.messages
  }, messageContent)));
};
InlineFieldLayout.propTypes = inlineFieldLayoutPropTypes, InlineFieldLayout.displayName = "InlineFieldLayout", InlineFieldLayout.labelClass = styles.label, InlineFieldLayout.__docgenInfo = {
  description: "A component that provides layout for a field (label + control + message). Each 'field layout' component can accept React nodes\nfor each part of a field and is free to render as it likes.\n\n@type {React.FC<PropTypes.InferProps<typeof inlineFieldLayoutPropTypes>>}",
  methods: [],
  displayName: "InlineFieldLayout",
  props: {
    labelContent: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to output in the label portion of the layout"
    },
    controlContent: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to output in the control portion of the layout"
    },
    messageContent: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to output in the message portion of the layout"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name, additional style to apply to the layout"
    }
  }
}, InlineFieldLayout.__docgenInfo = {
  componentName: "InlineFieldLayout",
  packageName: "@jutro/components",
  description: "A component that provides layout for a field (label + control + message). Each 'field layout' component can accept React nodes\nfor each part of a field and is free to render as it likes.",
  displayName: "InlineFieldLayout",
  methods: [],
  actualName: "InlineFieldLayout",
  props: {
    labelContent: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to output in the label portion of the layout"
    },
    controlContent: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to output in the control portion of the layout"
    },
    messageContent: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The content to output in the message portion of the layout"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name, additional style to apply to the layout"
    }
  }
};