import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["actionType", "children", "className", "iconClassName", "disabled", "fullWidth", "href", "icon", "iconPosition", "onClick", "renderContent", "size", "type"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import mapKeys from "lodash/mapKeys";
import isString from "lodash/isString";
import omit from "lodash/omit";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { JUTRO_TOPICS } from "@jutro/events";
import { useKeyActive } from "@jutro/platform";
import { trackMethod } from "../../helpers";
import styles from "./Button.module.css";
import { Icon } from "../Icon/Icon";
import { InlineLabel } from "../InlineLabel/InlineLabel";
import { muteCallbacks, isAccessibleDisabled } from "../../accessibleDisabled";
export var buttonTypes = ["filled", "outlined", "text"];
export var buttonActionTypes = ["button", "submit", "reset"];
export var buttonSizes = ["small", "medium"];
export var buttonIconPositions = ["left", "right"];
var typeStyleMap = mapKeys(buttonTypes),
  buttonPropTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(buttonIconPositions),
    onClick: PropTypes.func,
    renderContent: PropTypes.func,
    size: PropTypes.oneOf(buttonSizes),
    type: PropTypes.oneOf(buttonTypes),
    href: intlMessageShape,
    actionType: PropTypes.oneOf(buttonActionTypes)
  };
export var Button = React.forwardRef(function (props, ref) {
  var _cx2,
    _cx3,
    actionType = props.actionType,
    children = props.children,
    className = props.className,
    iconClassName = props.iconClassName,
    disabled = props.disabled,
    fullWidth = props.fullWidth,
    href = props.href,
    icon = props.icon,
    iconPosition = props.iconPosition,
    onClick = props.onClick,
    renderContent = props.renderContent,
    size = props.size,
    type = props.type,
    other = _objectWithoutProperties(props, _excluded),
    accessibleDisabled = isAccessibleDisabled("accessibleDisabled.button"),
    translator = useTranslator(),
    safeHref = useSafeTranslatedUrls()(href),
    typeStyle = type && typeStyleMap[type] || "filled",
    renderAsLink = void 0 !== safeHref,
    internalRef = useRef(null),
    newRef = ref || internalRef,
    isActiveKeyPressed = useKeyActive(newRef),
    classes = cx(_defineProperty({}, styles.activeKeypress, isActiveKeyPressed), styles.button, (_defineProperty(_cx2 = {}, styles.iconButton, icon && !children), _defineProperty(_cx2, styles[typeStyle], typeStyle), _defineProperty(_cx2, styles.small, "small" === size), _defineProperty(_cx2, styles.fullWidth, fullWidth), _defineProperty(_cx2, styles.disabled, disabled), _defineProperty(_cx2, styles.materialIcon, icon && _startsWithInstanceProperty(icon).call(icon, "mi")), _cx2), className),
    isRight = "right" === iconPosition,
    isLeft = !isRight,
    iconClasses = cx(styles.icon, (_defineProperty(_cx3 = {}, styles.leftIcon, isLeft && children), _defineProperty(_cx3, styles.rightIcon, isRight && children), _cx3), iconClassName),
    iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: iconClasses,
      "aria-hidden": !0
    }),
    translatedChildren = translator(children),
    content = React.createElement(InlineLabel, {
      icon: iconComponent,
      iconPosition: iconPosition
    }, React.createElement("span", null, translatedChildren)),
    trackingProps = _objectSpread(_objectSpread({}, props), {}, {
      label: isString(translatedChildren) ? translatedChildren : void 0
    }),
    trackedTopic = renderAsLink ? JUTRO_TOPICS.LINK_CLICKED : JUTRO_TOPICS.BUTTON_CLICKED,
    trackedOnClick = trackMethod(onClick, trackedTopic, trackingProps),
    contentToRender = renderContent ? renderContent(content) : content,
    remainingProps = omit(other, ["translator", "showRequired", "onDataChange", "onValueChange", "dangerouslySetInnerHTML"]),
    defaultRender = React.createElement("button", _extends({
      ref: newRef,
      type: actionType,
      onClick: trackedOnClick,
      className: classes,
      disabled: disabled
    }, remainingProps, accessibleDisabled && disabled && _objectSpread(_objectSpread({
      disabled: void 0,
      "aria-disabled": !0
    }, muteCallbacks(props)), {}, {
      onMouseDown: void 0,
      type: "button"
    })), contentToRender),
    renderLinkClasses = cx(classes, _defineProperty({}, styles.disabled, disabled)),
    renderLink = React.createElement("a", _extends({
      ref: newRef,
      href: disabled ? void 0 : safeHref,
      onClick: disabled ? void 0 : trackedOnClick,
      className: renderLinkClasses
    }, remainingProps, accessibleDisabled && disabled && {
      role: "link",
      "aria-disabled": !0,
      tabIndex: 0,
      onKeyDown: void 0,
      onKeyPress: void 0,
      onKeyUp: void 0
    }), contentToRender);
  return renderAsLink ? renderLink : defaultRender;
});
Button.propTypes = buttonPropTypes, Button.defaultProps = {
  actionType: "button",
  disabled: !1,
  fullWidth: !1,
  iconPosition: "left",
  size: "medium",
  type: "filled"
}, Button.displayName = "Button", Button.__docgenInfo = {
  description: "@type {React.ForwardRefExoticComponent<PropTypes.InferProps<typeof buttonPropTypes> & Omit<React.HTMLProps<HTMLElement>>, 'size'>}\n\n@metadataType action",
  methods: [],
  displayName: "Button",
  props: {
    actionType: {
      defaultValue: {
        value: "'button'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'button'",
          computed: !1
        }, {
          value: "'submit'",
          computed: !1
        }, {
          value: "'reset'",
          computed: !1
        }]
      },
      required: !1,
      description: "The type of action that this button performs"
    },
    disabled: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, this button is disabled"
    },
    fullWidth: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, the button expands to the available width"
    },
    iconPosition: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text"
    },
    size: {
      defaultValue: {
        value: "'medium'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }]
      },
      required: !1,
      description: "Allows you to select the smaller or larger variant"
    },
    type: {
      defaultValue: {
        value: "'filled'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'filled'",
          computed: !1
        }, {
          value: "'outlined'",
          computed: !1
        }, {
          value: "'text'",
          computed: !1
        }]
      },
      required: !1,
      description: 'Sets the "type" of button to display (filled, outlined, text)'
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional string id for button"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The children elements to render inside of the Button"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for icon"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when button is clicked"
    },
    renderContent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to wrap button content"
    },
    href: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "If this prop is used, an `<a>` tag will be used instead of `<button>`\n and this will be the href passed to the `<a>` tag used."
    }
  }
}, Button.__docgenInfo = {
  componentName: "Button",
  packageName: "@jutro/components",
  description: "",
  displayName: "Button",
  methods: [],
  actualName: "Button",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional string id for button"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The children elements to render inside of the Button"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for icon"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, this button is disabled",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If `true`, the button expands to the available width",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional icon name"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text",
      defaultValue: {
        value: "'left'",
        computed: !1
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when button is clicked"
    },
    renderContent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to wrap button content"
    },
    size: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }]
      },
      required: !1,
      description: "Allows you to select the smaller or larger variant",
      defaultValue: {
        value: "'medium'",
        computed: !1
      }
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'filled'",
          computed: !1
        }, {
          value: "'outlined'",
          computed: !1
        }, {
          value: "'text'",
          computed: !1
        }]
      },
      required: !1,
      description: 'Sets the "type" of button to display (filled, outlined, text)',
      defaultValue: {
        value: "'filled'",
        computed: !1
      }
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "If this prop is used, an `<a>` tag will be used instead of `<button>`\n and this will be the href passed to the `<a>` tag used."
    },
    actionType: {
      type: {
        name: "enum",
        value: [{
          value: "'button'",
          computed: !1
        }, {
          value: "'submit'",
          computed: !1
        }, {
          value: "'reset'",
          computed: !1
        }]
      },
      required: !1,
      description: "The type of action that this button performs",
      defaultValue: {
        value: "'button'",
        computed: !1
      }
    }
  }
};