import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["visible", "grow", "shrink", "alignSelf", "textAlign", "tag", "children", "className", "dangerouslySetInnerHTML"];
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import styles from "./Flex.module.css";
import { useBreakpoint } from "../breakpoint/useBreakpoint";
var alignSelfMapping = {
    top: styles.gwAlignSelfStart,
    middle: styles.gwAlignSelfCenter,
    bottom: styles.gwAlignSelfEnd,
    left: styles.gwAlignSelfStart,
    center: styles.gwAlignSelfCenter,
    right: styles.gwAlignSelfEnd,
    baseline: styles.gwAlignSelfBaseline,
    stretch: styles.gwAlignSelfStretch
  },
  textAlignMapping = {
    left: styles.gwTextLeft,
    center: styles.gwTextCenter,
    right: styles.gwTextRight
  };
export var FlexItem = function (props) {
  var componentProps = useBreakpoint(props).breakpointProps,
    visible = componentProps.visible,
    grow = componentProps.grow,
    shrink = componentProps.shrink,
    alignSelf = componentProps.alignSelf,
    textAlign = componentProps.textAlign,
    tag = componentProps.tag,
    children = componentProps.children,
    className = componentProps.className,
    other = (componentProps.dangerouslySetInnerHTML, _objectWithoutProperties(componentProps, _excluded)),
    Tag = tag,
    inlineStyles = useMemo(function () {
      if (grow || shrink) return pickBy({
        flexGrow: grow,
        flexShrink: shrink
      }, identity);
    }, [grow, shrink]);
  if (!1 === visible) return null;
  var textAlignClass = textAlign && textAlignMapping[textAlign],
    alignSelfClass = alignSelf && alignSelfMapping[alignSelf],
    classes = cx(styles.gwFlexItem, textAlignClass, alignSelfClass, className);
  return React.createElement(Tag, _extends({
    className: classes,
    style: inlineStyles
  }, other), children);
};
var flexItemPropTypes = {
  visible: PropTypes.bool,
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.oneOf(_Object$keys(textAlignMapping)),
  alignSelf: PropTypes.oneOf(_Object$keys(alignSelfMapping)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
};
FlexItem.propTypes = flexItemPropTypes, FlexItem.defaultProps = {
  tag: "div"
}, FlexItem.displayName = "FlexItem", FlexItem.__docgenInfo = {
  description: "Defines a css grid 'FlexItem'. This is used in conjunction with 'Grid' parent.\n\n@param {object} [props] - props for this component\n@returns {React.ReactElement}\n\n@example\n<FlexItem\n  textAlign=\"left\"\n  align=\"middle\"\n  tablet={{ textAlign: 'center' }}\n  phoneWide={{ textAlign: 'center' }}\n  phone={{ visible: false }}\n>\n  custom content\n</FlexItem>\n\n@metadataType layout",
  methods: [],
  displayName: "FlexItem",
  props: {
    tag: {
      defaultValue: {
        value: "'div'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Dom tag to use"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the FlexItem is visible"
    },
    grow: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "The flex grow property"
    },
    shrink: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "The flex shrink property"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }]
      },
      required: !1,
      description: "Horizontally align the contents of the column"
    },
    alignSelf: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align the flex item according to the cross axis of the flex direction."
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class(es) to append to tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children for this item"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FlexItem property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FlexItem property for use at the 'phoneWide' breakpoint;"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FlexItem property for use at the 'tablet' breakpoint;"
    }
  }
}, FlexItem.__docgenInfo = {
  componentName: "FlexItem",
  packageName: "@jutro/layout",
  description: "Defines a css grid 'FlexItem'. This is used in conjunction with 'Grid' parent.",
  displayName: "FlexItem",
  methods: [],
  actualName: "FlexItem",
  metadataType: "layout",
  props: {
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the FlexItem is visible"
    },
    grow: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "The flex grow property"
    },
    shrink: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "The flex shrink property"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }]
      },
      required: !1,
      description: "Horizontally align the contents of the column"
    },
    alignSelf: {
      type: {
        name: "enum",
        value: [{
          value: '"top"',
          computed: !1
        }, {
          value: '"middle"',
          computed: !1
        }, {
          value: '"bottom"',
          computed: !1
        }, {
          value: '"left"',
          computed: !1
        }, {
          value: '"center"',
          computed: !1
        }, {
          value: '"right"',
          computed: !1
        }, {
          value: '"baseline"',
          computed: !1
        }, {
          value: '"stretch"',
          computed: !1
        }]
      },
      required: !1,
      description: "Align the flex item according to the cross axis of the flex direction."
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Dom tag to use",
      defaultValue: {
        value: "'div'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class(es) to append to tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children for this item"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FlexItem property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FlexItem property for use at the 'phoneWide' breakpoint;"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any FlexItem property for use at the 'tablet' breakpoint;"
    }
  }
};