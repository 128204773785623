import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["headerClassName"];
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { BreakpointTracker, BreakpointTrackerContext } from "@jutro/layout";
import { log } from "@jutro/logger";
import { useTranslator } from "@jutro/locale";
import { metadataTypes } from "@jutro/uimetadata";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./Table.module.css";
import { TableColumn } from "./TableColumn";
import { TableTitleBar } from "./TableTitleBar";
var tablePropTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  renderTitle: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  renderTitleAction: PropTypes.func,
  columnsProportion: PropTypes.arrayOf(PropTypes.number),
  placeholder: intlMessageShape,
  renderPhoneCardComponent: PropTypes.func,
  titleId: PropTypes.string,
  titlePosition: PropTypes.oneOf(["left", "right", "center"]),
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  }))
};
export var Table = function (props) {
  var translator = useTranslator();
  return React.createElement(BreakpointTrackerContext.Consumer, null, function (breakpoint) {
    return renderTableComponent(props, breakpoint, translator);
  });
};
function renderHeader(headerProps) {
  var componentProps = headerProps.componentProps,
    breakpoint = headerProps.breakpoint,
    translator = headerProps.translator;
  return renderLine({
    componentProps: componentProps,
    breakpoint: breakpoint,
    renderProp: function (_ref) {
      var headerClassName = _ref.headerClassName,
        rest = _objectWithoutProperties(_ref, _excluded),
        columnProps = _objectSpread({
          headerClassName: cx(styles.tableHeader, headerClassName)
        }, rest);
      return TableColumn.renderHeader(columnProps, translator);
    },
    keyMapper: function () {
      return "header";
    }
  });
}
function renderRow(props) {
  var componentProps = props.componentProps,
    row = props.row,
    index = props.index,
    breakpoint = props.breakpoint,
    translator = props.translator;
  return renderLine({
    componentProps: componentProps,
    breakpoint: breakpoint,
    renderProp: function (columnProps) {
      return TableColumn.renderCell(row, index, _objectSpread(_objectSpread({}, columnProps), {}, {
        basePath: componentProps.path
      }), translator);
    },
    keyMapper: function () {
      return row.id || "".concat(index);
    },
    rowIndex: index
  });
}
var ignoreRowClicks = ["INPUT", "BUTTON", "A", "LABEL", "SELECT"];
function handleRowEvent(evt, columnCallback) {
  var currentTarget = evt.target;
  currentTarget && _includesInstanceProperty(ignoreRowClicks).call(ignoreRowClicks, currentTarget.tagName) || columnCallback(evt);
}
function renderLine(props) {
  var _context,
    onRowClick,
    onKeyDown,
    tabIndex,
    componentProps = props.componentProps,
    breakpoint = props.breakpoint,
    renderProp = props.renderProp,
    keyMapper = props.keyMapper,
    rowIndex = props.rowIndex,
    children = componentProps.children,
    columnsProportion = componentProps.columnsProportion,
    columns = _mapInstanceProperty(_context = React.Children.toArray(children)).call(_context, function (column, index) {
      var columnProps = BreakpointTracker.applyBreakpointOverrides(column.props, breakpoint);
      return !1 === columnProps.visible || columnsProportion && !columnsProportion[index] ? null : (column.type.onRowClick && (onRowClick = function (evt) {
        return handleRowEvent(evt, column.type.onRowClick);
      }), column.type.getTabIndex && (tabIndex = column.type.getTabIndex(rowIndex)), column.type.onKeyDown && (onKeyDown = function (evt) {
        return handleRowEvent(evt, column.type.onKeyDown);
      }), renderProp(_objectSpread(_objectSpread({}, columnProps), {}, {
        scope: "col"
      })));
    });
  return React.createElement("tr", {
    className: styles.row,
    key: keyMapper(),
    onClick: onRowClick,
    onKeyDown: onKeyDown,
    tabIndex: tabIndex
  }, columns);
}
function renderTableTitleBar(props) {
  var title = props.title,
    renderTitleAction = props.renderTitleAction,
    titleId = props.titleId,
    titlePosition = props.titlePosition;
  return React.createElement(TableTitleBar, {
    title: title,
    titleAction: renderTitleAction,
    titleId: titleId,
    titlePosition: titlePosition
  });
}
function renderColGroup(columnsProportion, children) {
  children && columnsProportion && (Array.isArray(children) ? _filterInstanceProperty(children).call(children, function (col) {
    return col && !1 !== col.props.visible;
  }).length : 1) !== columnsProportion.length && log.warning("Number of table columns and columns proportion does not match");
  if (columnsProportion && 0 !== columnsProportion.length) {
    columnsProportion = _filterInstanceProperty(columnsProportion).call(columnsProportion, function (x) {
      return !!x;
    });
    var proportionTotal = _reduceInstanceProperty(columnsProportion).call(columnsProportion, function (total, x) {
        return total + x;
      }),
      cols = _mapInstanceProperty(columnsProportion).call(columnsProportion, function (x, index) {
        return React.createElement("col", {
          key: index,
          style: {
            width: "".concat(x / proportionTotal * 100, "%")
          }
        });
      });
    return React.createElement("colgroup", null, cols);
  }
}
function renderPlaceHolder(placeholder, translator) {
  if (!placeholder) return null;
  var placeholderText = translator ? translator(placeholder) : placeholder;
  return React.createElement("div", {
    className: styles.placeholder
  }, placeholderText);
}
var renderDesktopTable = function (componentProps, breakpoint, translator, titleId) {
    var data = componentProps.data,
      children = componentProps.children,
      colGroup = renderColGroup(componentProps.columnsProportion, children),
      header = renderHeader({
        componentProps: componentProps,
        breakpoint: breakpoint,
        translator: translator
      }),
      body = _mapInstanceProperty(data).call(data, function (row, index) {
        return renderRow({
          componentProps: componentProps,
          row: row,
          index: index,
          breakpoint: breakpoint,
          translator: translator
        });
      });
    return React.createElement("table", {
      className: styles.tableElement,
      "aria-labelledby": titleId
    }, colGroup, React.createElement("thead", null, header), React.createElement("tbody", null, body));
  },
  renderPhoneCardRow = function (rowProps, row, index, translator) {
    var isVisible = !(arguments.length > 4 && void 0 !== arguments[4]) || arguments[4],
      classes = cx(styles.tableCardRow, _defineProperty({}, styles.radioRow, null == rowProps ? void 0 : rowProps.option)),
      cardProps = _objectSpread(_objectSpread({}, rowProps), {}, {
        headerClassName: styles.tableCardElementHeader,
        cellClassName: styles.tableCardElementContent,
        scope: "row",
        textAlign: void 0,
        visible: isVisible
      });
    return React.createElement("tr", {
      key: rowProps.id + index,
      className: classes
    }, TableColumn.renderHeader(cardProps, translator), TableColumn.renderCell(row, index, cardProps, translator));
  },
  renderPhoneTable = function (componentProps, breakpoint, translator, titleId) {
    var _context2,
      clickableColumn,
      data = componentProps.data,
      children = componentProps.children,
      renderPhoneCardComponent = componentProps.renderPhoneCardComponent,
      columnsProportion = componentProps.columnsProportion,
      Card = renderPhoneCardComponent || "div";
    _filterInstanceProperty(_context2 = React.Children.toArray(children)).call(_context2, Boolean).forEach(function (child) {
      child.type.onRowClick && (clickableColumn = child);
    });
    var onCardClick = clickableColumn ? function (evt) {
        return handleRowEvent(evt, clickableColumn.type.onRowClick);
      } : void 0,
      cards = _mapInstanceProperty(data).call(data, function (row, index) {
        var _context3,
          key = row.id || "".concat(index),
          card = _mapInstanceProperty(_context3 = React.Children.toArray(children)).call(_context3, function (child, childIndex) {
            return renderPhoneCardRow(child.props, row, index, translator, 0 !== (null == columnsProportion ? void 0 : columnsProportion[childIndex]));
          });
        return React.createElement(Card, {
          key: key,
          className: styles.card,
          onClick: onCardClick,
          id: key
        }, React.createElement("div", {
          onClick: onCardClick
        }, React.createElement("table", {
          className: styles.tableCard,
          "aria-describedby": titleId
        }, React.createElement("tbody", null, card))));
      });
    return React.createElement(React.Fragment, null, cards);
  },
  renderDesktopOrPhoneTable = function (props) {
    var componentProps = props.componentProps,
      breakpoint = props.breakpoint,
      translator = props.translator,
      titleId = props.titleId;
    return !breakpoint || "phone" !== breakpoint && "phoneWide" !== breakpoint ? renderDesktopTable(componentProps, breakpoint, translator, titleId) : renderPhoneTable(componentProps, 0, translator, titleId);
  },
  isClickable = function (child) {
    var _child$type;
    return null == child || null === (_child$type = child.type) || void 0 === _child$type ? void 0 : _child$type.onRowClick;
  },
  renderTableComponent = function (props, breakpoint, translator) {
    var componentProps = BreakpointTracker.applyBreakpointOverrides(props, breakpoint),
      data = componentProps.data,
      className = componentProps.className,
      title = componentProps.title,
      renderTitle = componentProps.renderTitle,
      titleId = componentProps.titleId,
      renderTitleAction = componentProps.renderTitleAction,
      titlePosition = componentProps.titlePosition,
      placeholder = componentProps.placeholder,
      tableClasses = cx(styles.table, _defineProperty({}, styles.hoverable, function (props) {
        var children = props.children;
        return !!children && (Array.isArray(children) || children.type !== React.Fragment || (children = children.props.children), Array.isArray(children) ? children.some(isClickable) : children.type.onRowClick);
      }(props)), className),
      titleBar = renderTableTitleBar({
        title: title || renderTitle,
        renderTitleAction: renderTitleAction,
        titleId: titleId,
        titlePosition: titlePosition
      }),
      hasData = !isEmpty(data),
      placeholderElement = hasData ? null : renderPlaceHolder(placeholder, translator);
    return React.createElement("div", {
      className: tableClasses
    }, titleBar, hasData && renderDesktopOrPhoneTable({
      componentProps: componentProps,
      breakpoint: breakpoint,
      translator: translator,
      titleId: titleId
    }), placeholderElement);
  };
Table.propTypes = tablePropTypes, Table.metadataType = metadataTypes.CONTAINER, Table.__docgenInfo = {
  description: "Renders a table. You can use it to display data from a model.\n\n@type {React.FC<PropTypes.InferProps<typeof tablePropTypes>>}\n\n@metadataType container",
  methods: [],
  displayName: "Table",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "Title which is placed in title bar"
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function that renders custom title"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children; preferably 'GridItem'; works with any child"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Table property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Table property for use at 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Table property for use at 'tablet' and 'phone' breakpoint"
    },
    renderTitleAction: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render prop for customizing action in the title area"
    },
    columnsProportion: {
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "Proportion of columns (e.g. [2, 2, 1])"
    },
    placeholder: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Placeholder to show when data is empty"
    },
    renderPhoneCardComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Component to wrap mobile Radio Table"
    },
    titleId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The id used for the tables title for use in the aria-describedby"
    },
    titlePosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }]
      },
      required: !1,
      description: "The position of the title in the title bar (left, right, center)"
    },
    data: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Optional string for generating keys for rows",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Cells data"
    }
  }
}, Table.__docgenInfo = {
  componentName: "Table",
  packageName: "@jutro/datatable",
  description: "Renders a table. You can use it to display data from a model.",
  displayName: "Table",
  methods: [],
  actualName: "Table",
  metadataType: "container",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: !1,
      description: "Title which is placed in title bar"
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function that renders custom title"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Optional css class(es) to add to the grid tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Children; preferably 'GridItem'; works with any child"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Table property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Table property for use at 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any Table property for use at 'tablet' and 'phone' breakpoint"
    },
    renderTitleAction: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render prop for customizing action in the title area"
    },
    columnsProportion: {
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: !1,
      description: "Proportion of columns (e.g. [2, 2, 1])"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Placeholder to show when data is empty"
    },
    renderPhoneCardComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Component to wrap mobile Radio Table"
    },
    titleId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The id used for the tables title for use in the aria-describedby"
    },
    titlePosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }]
      },
      required: !1,
      description: "The position of the title in the title bar (left, right, center)"
    },
    data: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Optional string for generating keys for rows",
              required: !1
            }
          }
        }
      },
      required: !1,
      description: "Cells data"
    }
  }
}, renderHeader.__docgenInfo = {
  componentName: "renderHeader",
  packageName: "@jutro/datatable",
  description: "Renders header props",
  displayName: "renderHeader",
  methods: [],
  actualName: "renderHeader"
}, renderRow.__docgenInfo = {
  componentName: "renderRow",
  packageName: "@jutro/datatable",
  description: "Renders row",
  displayName: "renderRow",
  methods: [],
  actualName: "renderRow"
}, renderLine.__docgenInfo = {
  componentName: "renderLine",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderLine",
  methods: [],
  actualName: "renderLine"
}, renderTableTitleBar.__docgenInfo = {
  componentName: "renderTableTitleBar",
  packageName: "@jutro/datatable",
  description: "Renders TableTitleBar",
  displayName: "renderTableTitleBar",
  methods: [],
  actualName: "renderTableTitleBar"
}, renderColGroup.__docgenInfo = {
  componentName: "renderColGroup",
  packageName: "@jutro/datatable",
  description: "Renders colgroup",
  displayName: "renderColGroup",
  methods: [],
  actualName: "renderColGroup"
}, renderPlaceHolder.__docgenInfo = {
  componentName: "renderPlaceHolder",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderPlaceHolder",
  methods: [],
  actualName: "renderPlaceHolder"
}, renderDesktopTable.__docgenInfo = {
  componentName: "renderDesktopTable",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderDesktopTable",
  methods: [],
  actualName: "renderDesktopTable"
}, renderPhoneCardRow.__docgenInfo = {
  componentName: "renderPhoneCardRow",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderPhoneCardRow",
  methods: [],
  actualName: "renderPhoneCardRow"
}, renderPhoneTable.__docgenInfo = {
  componentName: "renderPhoneTable",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderPhoneTable",
  methods: [],
  actualName: "renderPhoneTable"
}, renderDesktopOrPhoneTable.__docgenInfo = {
  componentName: "renderDesktopOrPhoneTable",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderDesktopOrPhoneTable",
  methods: [],
  actualName: "renderDesktopOrPhoneTable"
}, renderTableComponent.__docgenInfo = {
  componentName: "renderTableComponent",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTableComponent",
  methods: [],
  actualName: "renderTableComponent"
};