import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["value", "unmaskedValue", "showMask", "mask", "forwardedRef"],
  _excluded2 = ["onClick", "showCalendar", "openWithIconOnly", "focusCalendar", "icon", "value", "mask", "noMask", "showMask", "disabled", "className"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { forwardRef, useRef, useState } from "react";
import defer from "lodash/defer";
import NumberFormat from "react-number-format";
import cx from "classnames";
import { isAccessibleDisabled, muteCallbacks } from "../../../../accessibleDisabled";
import styles from "./GenericDatePicker.module.css";
import inputStyles from "../../InputField/InputField.module.css";
import customInputStyles from "./CustomInput.module.css";
import { IconButton } from "../../../IconButton/IconButton_DEPRECATED";
import { messages } from "../DateField/DateField.messages";
function maskToFormat(mask) {
  return mask.replace(/[mMdDyY]/g, "#");
}
function maskToCharsArray(mask) {
  return mask.replace(/[^mMdDyY]/g, "").split("");
}
var CustomTextInput = function (props) {
  var value = props.value,
    unmaskedValue = props.unmaskedValue,
    showMask = props.showMask,
    forwardedRef = (props.mask, props.forwardedRef),
    other = _objectWithoutProperties(props, _excluded);
  return React.createElement("input", _extends({}, other, {
    ref: forwardedRef,
    value: showMask ? value : unmaskedValue,
    maxLength: null,
    className: customInputStyles.customInput
  }));
};
export var CustomInput = forwardRef(function (props, ref) {
  var onClick = props.onClick,
    showCalendar = props.showCalendar,
    openWithIconOnly = props.openWithIconOnly,
    focusCalendar = props.focusCalendar,
    icon = props.icon,
    value = props.value,
    mask = props.mask,
    noMask = props.noMask,
    showMask = props.showMask,
    disabled = props.disabled,
    className = props.className,
    other = _objectWithoutProperties(props, _excluded2),
    inputRef = useRef(),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isFocused = _useState2[0],
    setIsFocused = _useState2[1];
  "function" == typeof ref && ref(inputRef.current);
  var handleIconClick = function (event) {
      onClick(event), defer(focusCalendar, event);
    },
    accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields"),
    wrapperClasses = cx(inputStyles.input, customInputStyles.inputWrapper, customInputStyles.inputContainer, _defineProperty({}, customInputStyles.controlFocused, isFocused), className),
    handleFocus = function (event) {
      null == other || other.onFocus(event), setIsFocused(!0);
    },
    handleBlur = function (event) {
      null == other || other.onBlur(event), setIsFocused(!1);
    },
    accessibleDisabledInputProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks(_objectSpread(_objectSpread({}, other), {}, {
      onClick: showCalendar && !openWithIconOnly ? onClick : void 0
    }))), {}, {
      "aria-disabled": !0,
      "aria-readonly": !0,
      readOnly: !0,
      disabled: void 0,
      onBlur: function () {
        return setIsFocused(!1);
      },
      onFocus: function () {
        return setIsFocused(!0);
      }
    }) : {},
    accessibleDisabledIconProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
      onClick: handleIconClick
    })), {}, {
      disabled: void 0,
      "aria-disabled": !0
    }) : {};
  return React.createElement("div", {
    className: wrapperClasses
  }, noMask ? React.createElement("input", _extends({}, other, {
    onClick: showCalendar && !openWithIconOnly ? onClick : void 0,
    ref: inputRef,
    value: value,
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled: disabled,
    className: customInputStyles.customInput
  }, accessibleDisabledInputProps)) : React.createElement(NumberFormat, _extends({}, other, {
    forwardedRef: inputRef,
    showMask: showMask,
    format: mask && maskToFormat(mask),
    mask: mask && maskToCharsArray(mask),
    customInput: CustomTextInput,
    value: ensureLeadingZeros(value, showMask),
    unmaskedValue: value,
    disabled: disabled,
    onClick: showCalendar && !openWithIconOnly ? onClick : void 0,
    onFocus: handleFocus,
    onBlur: handleBlur
  }, accessibleDisabledInputProps, {
    allowLeadingZeros: !0,
    isNumericString: !0
  })), showCalendar && React.createElement(IconButton, _extends({
    icon: icon,
    onClick: handleIconClick,
    ariaLabel: messages.openCalendar,
    disabled: disabled,
    className: cx(styles.icon, customInputStyles.icon)
  }, accessibleDisabledIconProps, {
    noDeprecationWarnings: ["IconButton"]
  })));
});
function ensureLeadingZeros(value, showMask) {
  var _context;
  if (!showMask) return value;
  var separatorMatch = value.match(/[.\-/]/);
  if (!separatorMatch) return value;
  var separator = _slicedToArray(separatorMatch, 1)[0];
  return _mapInstanceProperty(_context = value.split(separator)).call(_context, function (datePart) {
    return 1 === datePart.length ? "0".concat(datePart) : datePart;
  }).join("");
}
CustomInput.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "CustomInput"
}, CustomTextInput.__docgenInfo = {
  componentName: "CustomTextInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "CustomTextInput",
  methods: [],
  actualName: "CustomTextInput"
};