import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import { isEmptyValue } from "@jutro/data";
import { isValid, isExists as doesDateExist } from "date-fns";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { formatDateToLocalePattern, parseDateFromDataType, isDateInRange } from "@jutro/locale";
import { getMessageProp } from "@jutro/platform";
import { messages } from "../DateField/DateField.messages";
import { OldFieldComponentValidationImplementation } from "../../FieldComponent/OldFieldComponentValidationImplementation";
export var OldSimpleDateFieldValidationImplementation = function (_OldFieldComponentVal) {
  _inherits(OldSimpleDateFieldValidationImplementation, OldFieldComponentValidationImplementation);
  var _super = _createSuper(OldSimpleDateFieldValidationImplementation);
  function OldSimpleDateFieldValidationImplementation() {
    return _classCallCheck(this, OldSimpleDateFieldValidationImplementation), _super.apply(this, arguments);
  }
  return _createClass(OldSimpleDateFieldValidationImplementation, [{
    key: "bind",
    value: function (entityToBind) {
      var _this = this,
        superBoundMethods = _get(_getPrototypeOf(OldSimpleDateFieldValidationImplementation.prototype), "bind", this).call(this, entityToBind),
        methods = ["getValidationMessages"];
      return _reduceInstanceProperty(methods).call(methods, function (acc, method) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, method, _this[method].bind(entityToBind)));
      }, superBoundMethods);
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      var _this$props = this.props,
        minDate = _this$props.minDate,
        maxDate = _this$props.maxDate,
        messageProps = _this$props.messageProps,
        displayFormat = _this$props.displayFormat,
        intl = _this$props.intl,
        translator = this.translator,
        value = this.getValue(),
        validationMessages = _get(_getPrototypeOf(OldSimpleDateFieldValidationImplementation.prototype), "getValidationMessages", this).call(this, value) || [];
      if (isEmptyValue(value)) return validationMessages;
      if (!isEmpty(validationMessages)) return validationMessages;
      var incorrectInput = getMessageProp("incorrectInput", messageProps, messages),
        validationMinDate = getMessageProp("validationMinDate", messageProps, messages),
        validationMaxDate = getMessageProp("validationMaxDate", messageProps, messages);
      if (value instanceof Object) {
        var day = value.day,
          month = value.month,
          year = value.year;
        if (isNil(day) || isNil(month) || isNil(year) || !doesDateExist(year, month, day)) return [translator(incorrectInput)];
      }
      var date = parseDateFromDataType(value);
      if (!isValid(date)) return [translator(incorrectInput)];
      var _isDateInRange = isDateInRange(date, minDate, maxDate),
        fulfillMinRequirement = _isDateInRange.fulfillMinRequirement,
        fulfillMaxRequirement = _isDateInRange.fulfillMaxRequirement;
      return !fulfillMinRequirement && minDate ? [translator(validationMinDate, {
        minDate: formatDateToLocalePattern(parseDateFromDataType(minDate), null == intl ? void 0 : intl.formatDate, displayFormat, this.getInputFormat())
      })] : !fulfillMaxRequirement && maxDate ? [translator(validationMaxDate, {
        maxDate: formatDateToLocalePattern(parseDateFromDataType(maxDate), null == intl ? void 0 : intl.formatDate, displayFormat, this.getInputFormat())
      })] : validationMessages;
    }
  }]), OldSimpleDateFieldValidationImplementation;
}();