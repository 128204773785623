import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
var _excluded = ["knockoutPath"];
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React from "react";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import { isPromise } from "@jutro/platform";
import { log } from "@jutro/logger";
import { showAlert } from "./alertHelper";
import { navigateTo } from "./navigationHelper";
import { getErrorCategory } from "./errorCategorizer";
export function invokeAction(name, props, context, onSuccess, onFailure) {
  var actionFunction = props.function,
    actionArgs = props.args;
  if (actionFunction && !isString(actionFunction)) {
    var result, resultException;
    actionArgs && (actionArgs = _mapInstanceProperty(actionArgs).call(actionArgs, function (prop) {
      return context[prop];
    }));
    try {
      result = actionArgs ? actionFunction.apply(void 0, _toConsumableArray(actionArgs)) : actionFunction();
    } catch (ex) {
      result = !1, resultException = ex;
    }
    return {
      name: name,
      result: result,
      resultException: resultException,
      progress: props.progress,
      success: props.success,
      failure: props.failure,
      onSuccess: onSuccess,
      onFailure: onFailure
    };
  }
  log.error('Could not resolve action function: "'.concat(actionFunction || name, '"'));
}
export function actionResultWithPaths(result, successPath, failurePath, progressMsg) {
  var actionObject = {
    name: "unnamed",
    result: result
  };
  return void 0 !== progressMsg && (actionObject.progress = {
    message: progressMsg
  }), void 0 !== successPath && (actionObject.success = {
    path: successPath
  }), void 0 !== failurePath && (actionObject.failure = {
    path: failurePath
  }), actionObject;
}
export function handleAction(_x, _x2, _x3, _x4) {
  return _handleAction.apply(this, arguments);
}
function _handleAction() {
  return (_handleAction = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(actionObject, instance, translator, modalEmitter) {
    var name, result, resultException, progress, success, failure, onSuccess, onFailure, message, _ref2, to, toMessage, _ref3, failTo, failToMessage, onAlert, onNavigate, _instance$mounted, data, isInstanceMounted, computedFailTo, errorData, errorCategory, knockoutPath, errorProps;
    return _regeneratorRuntime.wrap(function (_context3) {
      for (;;) switch (_context3.prev = _context3.next) {
        case 0:
          if (!instance) {
            _context3.next = 4;
            break;
          }
          if (instance.setState && instance.props && instance.props.history) {
            _context3.next = 4;
            break;
          }
          return log.error('"Instance" provided to AsyncLink.handleAction does not have "setState" or "history" props'), _context3.abrupt("return");
        case 4:
          if (name = actionObject.name, result = actionObject.result, resultException = actionObject.resultException, progress = actionObject.progress, success = actionObject.success, failure = actionObject.failure, onSuccess = actionObject.onSuccess, onFailure = actionObject.onFailure, message = (progress || {}).message, to = (_ref2 = success || {}).path, toMessage = _ref2.message, failTo = (_ref3 = failure || {}).path, failToMessage = _ref3.message, onAlert = showAlert, onNavigate = navigateTo, isPromise(result)) {
            _context3.next = 20;
            break;
          }
          if (!1 === result) {
            _context3.next = 15;
            break;
          }
          return onSuccess && onSuccess(name, result), to && onNavigate ? onNavigate(instance, to, result) : toMessage && onAlert && onAlert(translator(toMessage), null, null, modalEmitter), _context3.abrupt("return");
        case 15:
          if (void 0 === resultException || !1 === resultException) {
            _context3.next = 19;
            break;
          }
          return onFailure && onFailure(name, resultException), failTo && onNavigate ? onNavigate(instance, failTo, resultException) : failToMessage && onAlert && onAlert(translator(failToMessage), null, null, modalEmitter), _context3.abrupt("return");
        case 19:
          return _context3.abrupt("return");
        case 20:
          return _context3.prev = 20, instance && instance.setState({
            loading: !0,
            message: translator(message)
          }), _context3.next = 24, result;
        case 24:
          data = _context3.sent, isInstanceMounted = null === (_instance$mounted = instance.mounted) || void 0 === _instance$mounted || _instance$mounted, instance && isInstanceMounted && instance.setState({
            loading: !1,
            message: null
          }), !1 !== data && (onSuccess && onSuccess(name, data), to && onNavigate ? onNavigate(instance, to, data) : toMessage && onAlert && onAlert(translator(toMessage), null, null, modalEmitter)), _context3.next = 46;
          break;
        case 30:
          if (_context3.prev = 30, _context3.t0 = _context3.catch(20), computedFailTo = failTo, _context3.t0 instanceof Error && log.error("AsyncLink: ".concat(_context3.t0)), instance && instance.setState({
            loading: !1,
            message: null
          }), !1 !== _context3.t0) {
            _context3.next = 37;
            break;
          }
          return _context3.abrupt("return");
        case 37:
          if (onFailure && onFailure(name, _context3.t0), !1 !== _context3.t0) {
            _context3.next = 40;
            break;
          }
          return _context3.abrupt("return");
        case 40:
          errorData = _context3.t0, errorCategory = getErrorCategory((null === _context3.t0 || void 0 === _context3.t0 ? void 0 : _context3.t0.errorCode) || _context3.t0), knockoutPath = errorCategory.knockoutPath, errorProps = _objectWithoutProperties(errorCategory, _excluded), knockoutPath && (computedFailTo = knockoutPath), isEmpty(errorProps) || (errorData = _objectSpread(_objectSpread({}, errorProps), {}, {
            error: _context3.t0
          })), computedFailTo && onNavigate ? onNavigate(instance, computedFailTo, errorData) : failToMessage && onAlert && onAlert(translator(failToMessage), null, null, modalEmitter);
        case 46:
        case "end":
          return _context3.stop();
      }
    }, _callee, null, [[20, 30]]);
  }))).apply(this, arguments);
}
export function checkActionIntegrity(events, props, context) {
  events && props && context && events.forEach(function (evt) {
    var actionProps = props[evt];
    if (actionProps) {
      if (isString(actionProps.function)) return void log.error('Could not resolve action function: "'.concat(actionProps.function, '". Check return from "getActionFunctions()"'));
      if (actionProps.function && actionProps.args) {
        var _context,
          _context2,
          missing = _reduceInstanceProperty(_context = actionProps.args).call(_context, function (list, prop) {
            return Object.prototype.hasOwnProperty.call(context, prop) || list.push(prop), list;
          }, []);
        if (0 !== missing.length) log.error(_concatInstanceProperty(_context2 = 'Could not resolve action function arguments from action context: "'.concat(actionProps.name, "(")).call(_context2, missing, ')". Check return from "getActionContext()"'));
      }
    }
  });
}