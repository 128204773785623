import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { uniqueInnerId } from "@jutro/platform";
import { FieldMessage } from "./FieldMessage";
export var FieldComponentValidationImplementation = function () {
  function FieldComponentValidationImplementation() {
    _classCallCheck(this, FieldComponentValidationImplementation);
  }
  return _createClass(FieldComponentValidationImplementation, [{
    key: "bind",
    value: function (entityToBind) {
      var _this = this,
        methods = ["componentDidMount", "componentDidUpdate", "componentWillUnmount", "getValidationMessages", "getMessages", "isValid", "notifyChange", "renderMessages", "validate", "getValidationConfig", "getRules", "handleFocus", "handleBlur", "isRequired"];
      return _reduceInstanceProperty(methods).call(methods, function (acc, method) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, method, _this[method].bind(entityToBind)));
      }, {});
    }
  }, {
    key: "componentDidMount",
    value: function () {
      var _this$props = this.props,
        path = _this$props.path,
        registerValidation = _this$props.registerValidation;
      if (registerValidation) {
        if (this.getValidationConfig) {
          var _this$getRules = this.getRules();
          return registerValidation(path, _this$getRules.validationRules, _this$getRules.options, _this$getRules.onIsValid);
        }
        return registerValidation(path, {
          required: this.props.required
        });
      }
    }
  }, {
    key: "isRequired",
    value: function () {
      var _this$props2 = this.props,
        required = _this$props2.required,
        schemaRequired = _this$props2.schemaRequired;
      return (Array.isArray(required) ? required[0] : required) || schemaRequired;
    }
  }, {
    key: "getValidationConfig",
    value: function (rulesConfig) {
      var _context,
        translator = this.translator;
      return {
        propsList: _concatInstanceProperty(_context = ["required"]).call(_context, _toConsumableArray(get(rulesConfig, "propsList", []))),
        options: get(rulesConfig, "options", {
          label: translator(this.props.label),
          hideLabel: this.props.hideLabel
        })
      };
    }
  }, {
    key: "componentDidUpdate",
    value: function (prevProps) {
      var differenceFoundInProps = function (prevProps, newProps, configList) {
        return configList.some(function (propName) {
          return !isEqual(prevProps[propName], newProps[propName]);
        });
      }(prevProps, this.props, this.getValidationConfig().propsList);
      if (differenceFoundInProps) {
        var _this$props3 = this.props,
          path = _this$props3.path,
          registerValidation = _this$props3.registerValidation;
        if (registerValidation) {
          var _this$getRules2 = this.getRules();
          return registerValidation(path, _this$getRules2.validationRules, _this$getRules2.options);
        }
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function () {
      this.updateTimeout && clearTimeout(this.updateTimeout);
      var _this$props4 = this.props,
        registerValidation = _this$props4.registerValidation,
        path = _this$props4.path;
      "function" == typeof registerValidation && registerValidation(path, {});
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      return this.props.errorMessage;
    }
  }, {
    key: "validate",
    value: function () {}
  }, {
    key: "notifyChange",
    value: function () {}
  }, {
    key: "getMessages",
    value: function () {}
  }, {
    key: "handleFocus",
    value: function () {
      this.getValidationMessages() instanceof Object && this.setState({
        isFocused: !0
      });
    }
  }, {
    key: "handleBlur",
    value: function () {
      this.setState({
        isFocused: !1
      });
    }
  }, {
    key: "renderMessages",
    value: function (errorMessage, successMessage, isValid) {
      var translator = this.translator,
        messageId = uniqueInnerId(this.fieldUniqueId, "messageId").messageId;
      return React.createElement(FieldMessage, {
        id: messageId,
        label: translator(this.props.label),
        errorMessage: isValid ? void 0 : errorMessage,
        translator: translator,
        isFocused: this.state.isFocused
      });
    }
  }, {
    key: "isValid",
    value: function (validationMessages) {
      return !this.showErrors() || isEmpty(validationMessages);
    }
  }, {
    key: "getRules",
    value: function () {
      var _this2 = this,
        _this$getValidationCo = this.getValidationConfig(),
        propsList = _this$getValidationCo.propsList,
        options = _this$getValidationCo.options,
        onIsValid = _this$getValidationCo.onIsValid;
      return {
        validationRules: _reduceInstanceProperty(propsList).call(propsList, function (acc, key) {
          return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, _this2.props[key]));
        }, {}),
        options: _objectSpread(_objectSpread({}, options), {}, {
          enableMultipleValidation: this.props.enableMultipleValidation
        }),
        onIsValid: onIsValid
      };
    }
  }]), FieldComponentValidationImplementation;
}();