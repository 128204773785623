import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _objectDestructuringEmpty from "@babel/runtime-corejs3/helpers/objectDestructuringEmpty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
var _excluded = ["cancelButtonText"];
import React from "react";
import PropTypes from "prop-types";
import { intlMessageShape } from "@jutro/prop-types";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import { messages } from "./AlertModal.messages";
var defaultConfirmButtonText = messages.confirmButtonText,
  alertModalPropTypes = {
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func.isRequired,
    parentSelector: PropTypes.func,
    icon: PropTypes.string,
    title: intlMessageShape.isRequired,
    message: intlMessageShape,
    status: PropTypes.oneOf(["info", "warning", "error", "success"]),
    confirmButtonText: intlMessageShape
  };
export var AlertModal = function (_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref)),
    alertProps = (props.cancelButtonText, _objectWithoutProperties(props, _excluded));
  return React.createElement(ConfirmationModal, _extends({}, alertProps, {
    cancelButtonText: null
  }));
};
AlertModal.propTypes = alertModalPropTypes, AlertModal.defaultProps = {
  confirmButtonText: {
    id: defaultConfirmButtonText.id,
    defaultMessage: defaultConfirmButtonText.defaultMessage
  }
}, AlertModal.__docgenInfo = {
  description: "AlertModal\n@type {React.FC<PropTypes.InferProps<typeof alertModalPropTypes>>}",
  methods: [],
  displayName: "AlertModal",
  props: {
    confirmButtonText: {
      defaultValue: {
        value: "{\n    id: defaultConfirmButtonText.id,\n    defaultMessage: defaultConfirmButtonText.defaultMessage,\n}",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The text or message shape of the confirm button"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Boolean denoting if the modal is open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback function called when the modal is confirmed, cancelled, or closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to find the modal's parent selector"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Text to display for the title"
    },
    message: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message to be displayed in the modal"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }]
      },
      required: !1,
      description: "The status of the warning. One of 'info', 'warning', 'error', 'success' or blank for default."
    }
  }
}, AlertModal.__docgenInfo = {
  componentName: "AlertModal",
  packageName: "@jutro/components",
  description: "AlertModal",
  displayName: "AlertModal",
  methods: [],
  actualName: "AlertModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Boolean denoting if the modal is open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Callback function called when the modal is confirmed, cancelled, or closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function to find the modal's parent selector"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon name"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Text to display for the title"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message to be displayed in the modal"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'success'",
          computed: !1
        }]
      },
      required: !1,
      description: "The status of the warning. One of 'info', 'warning', 'error', 'success' or blank for default."
    },
    confirmButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The text or message shape of the confirm button",
      defaultValue: {
        value: "{\n    id: defaultConfirmButtonText.id,\n    defaultMessage: defaultConfirmButtonText.defaultMessage,\n}",
        computed: !1
      }
    }
  }
};