import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React from "react";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./ErrorNotice.module.css";
import { messages } from "./ErrorNotice.messages";
import { Button } from "../../button/Button";
import { Icon } from "../../Icon/Icon";
var errorNoticeDefaultProps = {
  icon: "gw-error-outline",
  mainMessage: messages.mainMessage,
  detailedMessage: messages.detailedMessage,
  showErrorMessage: !1,
  actions: [{
    label: messages.actionLabel,
    callback: function () {
      return window.location.reload();
    }
  }],
  noticeStyle: "plain"
};
export var errorNoticePropTypes = {
  icon: PropTypes.string,
  mainMessage: intlMessageShape,
  detailedMessage: intlMessageShape,
  error: PropTypes.instanceOf(Error),
  showErrorMessage: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: intlMessageShape.isRequired,
    callback: PropTypes.func.isRequired
  }).isRequired),
  noticeStyle: PropTypes.oneOf(["card", "plain", "fullHeight"])
};
export var ErrorNotice = function (_ref) {
  var _context,
    _context2,
    _context3,
    icon = _ref.icon,
    mainMessage = _ref.mainMessage,
    detailedMessage = _ref.detailedMessage,
    actions = _ref.actions,
    error = _ref.error,
    showErrorMessage = _ref.showErrorMessage,
    noticeStyle = _ref.noticeStyle,
    translator = useTranslator(),
    contClass = styles[noticeStyle] || noticeStyle;
  return React.createElement("div", {
    className: _concatInstanceProperty(_context = "".concat(styles.errorNoticeContainer, " ")).call(_context, contClass)
  }, React.createElement("div", {
    className: styles.errorNotice
  }, React.createElement(Icon, {
    icon: icon,
    className: styles.icon
  }), React.createElement("h2", {
    className: styles.mainMessage
  }, translator(mainMessage)), React.createElement("p", {
    className: styles.detailedMessage
  }, translator(detailedMessage)), showErrorMessage && error && error.message && React.createElement("pre", {
    className: styles.technicalMessage
  }, error.message), React.createElement("div", {
    className: styles.buttonRow
  }, _mapInstanceProperty(_context2 = _filterInstanceProperty(_context3 = actions).call(_context3, function (action) {
    return Boolean(action.label);
  })).call(_context2, function (action) {
    return React.createElement(Button, {
      key: action.label.toString(),
      className: styles.resetButton,
      onClick: action.callback,
      actionType: "reset",
      fullWidth: !1
    }, translator(action.label));
  }))));
};
ErrorNotice.displayName = "ErrorNotice", ErrorNotice.propTypes = errorNoticePropTypes, ErrorNotice.defaultProps = errorNoticeDefaultProps, ErrorNotice.__docgenInfo = {
  description: "A customizable presentational component capable of rendering acceptable error\nUIs in production\n\n@metadataType element",
  methods: [],
  displayName: "ErrorNotice",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-error-outline'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The icon to be displayed on the boundary error UI, above the main message"
    },
    mainMessage: {
      defaultValue: {
        value: "messages.mainMessage",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The main message to be displayed to the user on the error UI"
    },
    detailedMessage: {
      defaultValue: {
        value: "messages.detailedMessage",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "A more detailed message to be displayed below the main message on the error UI"
    },
    showErrorMessage: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Whether or not the error boundary should render the error message"
    },
    actions: {
      defaultValue: {
        value: "[\n    {\n        label: messages.actionLabel,\n        callback: () => window.location.reload(),\n    },\n]",
        computed: !1
      },
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            label: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: !0
            },
            callback: {
              name: "func",
              required: !0
            }
          }
        }
      },
      required: !1,
      description: "An optional array of label-callback objects to be displayed as recovery action buttons"
    },
    noticeStyle: {
      defaultValue: {
        value: "'plain'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'card'",
          computed: !1
        }, {
          value: "'plain'",
          computed: !1
        }, {
          value: "'fullHeight'",
          computed: !1
        }]
      },
      required: !1,
      description: "The notice container visual style, one of 'card', 'plain' or 'fullHeight'"
    },
    error: {
      type: {
        name: "instanceOf",
        value: "Error"
      },
      required: !1,
      description: "The error object with technical information"
    }
  }
}, ErrorNotice.__docgenInfo = {
  componentName: "ErrorNotice",
  packageName: "@jutro/components",
  description: "A customizable presentational component capable of rendering acceptable error\nUIs in production",
  displayName: "ErrorNotice",
  methods: [],
  actualName: "ErrorNotice",
  metadataType: "element",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The icon to be displayed on the boundary error UI, above the main message",
      defaultValue: {
        value: "'gw-error-outline'",
        computed: !1
      }
    },
    mainMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The main message to be displayed to the user on the error UI",
      defaultValue: {
        value: "messages.mainMessage",
        computed: !0
      }
    },
    detailedMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "A more detailed message to be displayed below the main message on the error UI",
      defaultValue: {
        value: "messages.detailedMessage",
        computed: !0
      }
    },
    error: {
      type: {
        name: "instanceOf",
        value: "Error"
      },
      required: !1,
      description: "The error object with technical information"
    },
    showErrorMessage: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Whether or not the error boundary should render the error message",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    actions: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            label: {
              name: "union",
              required: !0,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: !1
                  },
                  defaultMessage: {
                    name: "string",
                    required: !1
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: !1
                  }
                }
              }]
            },
            callback: {
              name: "func",
              required: !0
            }
          }
        }
      },
      required: !1,
      description: "An optional array of label-callback objects to be displayed as recovery action buttons",
      defaultValue: {
        value: "[\n    {\n        label: messages.actionLabel,\n        callback: () => window.location.reload(),\n    },\n]",
        computed: !1
      }
    },
    noticeStyle: {
      type: {
        name: "enum",
        value: [{
          value: "'card'",
          computed: !1
        }, {
          value: "'plain'",
          computed: !1
        }, {
          value: "'fullHeight'",
          computed: !1
        }]
      },
      required: !1,
      description: "The notice container visual style, one of 'card', 'plain' or 'fullHeight'",
      defaultValue: {
        value: "'plain'",
        computed: !1
      }
    }
  }
};