import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import { getMessageProp } from "@jutro/platform";
import isBoolean from "lodash/isBoolean";
import { OldFieldComponentValidationImplementation } from "../FieldComponent/OldFieldComponentValidationImplementation";
import { messages } from "./IntlPhoneNumberField.messages";
export var OldIntlPhoneNumberFieldValidationImplementation = function (_OldFieldComponentVal) {
  _inherits(OldIntlPhoneNumberFieldValidationImplementation, OldFieldComponentValidationImplementation);
  var _super = _createSuper(OldIntlPhoneNumberFieldValidationImplementation);
  function OldIntlPhoneNumberFieldValidationImplementation() {
    return _classCallCheck(this, OldIntlPhoneNumberFieldValidationImplementation), _super.apply(this, arguments);
  }
  return _createClass(OldIntlPhoneNumberFieldValidationImplementation, [{
    key: "bind",
    value: function (entityToBind) {
      var _this = this,
        superBoundMethods = _get(_getPrototypeOf(OldIntlPhoneNumberFieldValidationImplementation.prototype), "bind", this).call(this, entityToBind),
        methods = ["getValidationMessages"];
      return _reduceInstanceProperty(methods).call(methods, function (acc, method) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, method, _this[method].bind(entityToBind)));
      }, superBoundMethods);
    }
  }, {
    key: "validate",
    value: function (value, noNotification) {
      var _this$getInstance, _this$getInstance2;
      this.getInstance && this.getInstance() ? _get(_getPrototypeOf(OldIntlPhoneNumberFieldValidationImplementation.prototype), "validate", this).call(this, {
        isValid: null === (_this$getInstance = this.getInstance()) || void 0 === _this$getInstance ? void 0 : _this$getInstance.isValidNumber(),
        validationErrorCode: null === (_this$getInstance2 = this.getInstance()) || void 0 === _this$getInstance2 ? void 0 : _this$getInstance2.getValidationError()
      }, noNotification) : _get(_getPrototypeOf(OldIntlPhoneNumberFieldValidationImplementation.prototype), "validate", this).call(this, value, noNotification);
    }
  }, {
    key: "getValidationMessages",
    value: function (value) {
      var _this$getInstance3,
        _context,
        _ref = isBoolean(null == value ? void 0 : value.isValid) ? value : this.state,
        isValid = _ref.isValid,
        validationErrorCode = _ref.validationErrorCode,
        superValidationMessages = _get(_getPrototypeOf(OldIntlPhoneNumberFieldValidationImplementation.prototype), "getValidationMessages", this).call(this) || [],
        validationMessages = _toConsumableArray(superValidationMessages);
      !isValid && 0 === superValidationMessages.length && null !== (_this$getInstance3 = this.getInstance()) && void 0 !== _this$getInstance3 && _this$getInstance3.getNumber() && validationMessages.push(_concatInstanceProperty(_context = "".concat(this.translator(getMessageProp("validatePhone", messages)), " ")).call(_context, this.translator(getMessageProp("validatePhoneError".concat(validationErrorCode), messages))));
      return validationMessages;
    }
  }]), OldIntlPhoneNumberFieldValidationImplementation;
}();