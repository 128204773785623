import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useCallback } from "react";
import { Accordion, AccordionCard, DropdownMenuSeparator } from "@jutro/components";
import { AppSwitcherElement } from "./AppSwitcherElement";
import { isGroup, isSeparator } from "./helpers";
import styles from "./AppSwitcher.module.css";
export var AppSwitcherContent = function (_ref) {
  var items = _ref.items,
    displaySearch = _ref.displaySearch,
    renderSearch = _ref.renderSearch,
    resetSearch = _ref.resetSearch,
    onRouteClick = _ref.onRouteClick,
    closePopover = _ref.closePopover,
    isCollapsible = _ref.isCollapsible,
    renderItem = useCallback(function (item) {
      return isSeparator(item) ? React.createElement(DropdownMenuSeparator, {
        key: item.id,
        className: styles.separator
      }) : React.createElement(AppSwitcherElement, {
        key: item.id,
        item: item,
        onRouteClick: onRouteClick,
        closePopover: closePopover,
        resetSearch: resetSearch
      });
    }, [closePopover, onRouteClick, resetSearch]),
    renderItemArray = useCallback(function (itemArray) {
      return null == itemArray ? void 0 : _mapInstanceProperty(itemArray).call(itemArray, function (item) {
        return renderItem(item);
      });
    }, [renderItem]),
    renderGroupOfItems = useCallback(function (group, showSeparator) {
      var defaultOpenedId = group.isInitiallyCollapsed && isCollapsible ? void 0 : "appSwitcherGroup_".concat(group.id);
      return React.createElement(React.Fragment, {
        key: group.id
      }, showSeparator && React.createElement(DropdownMenuSeparator, {
        className: styles.separator
      }), React.createElement(Accordion, {
        id: "accordion_".concat(group.id),
        boldFont: !0,
        showFrame: !1,
        closeOthers: !1,
        defaultOpenedId: defaultOpenedId
      }, React.createElement(AccordionCard, {
        id: "appSwitcherGroup_".concat(group.id),
        title: group.title,
        cardBodyClassName: styles.accordionCard,
        cardHeadingClassName: styles.groupHeading,
        chevron: !0,
        chevronAlignment: "right",
        isCollapsible: isCollapsible
      }, renderItemArray(group.items))));
    }, [isCollapsible, renderItemArray]);
  return React.createElement("div", {
    className: styles.appSwitcherContent
  }, displaySearch && renderSearch && renderSearch(), React.createElement("div", {
    className: styles.appSwitcherItems
  }, _mapInstanceProperty(items).call(items, function (item, index) {
    return isGroup(item) ? renderGroupOfItems(item, !!index || displaySearch) : renderItem(item);
  })));
};
AppSwitcherContent.displayName = "AppSwitcherContent", AppSwitcherContent.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AppSwitcherContent"
}, AppSwitcherContent.__docgenInfo = {
  componentName: "AppSwitcherContent",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcherContent",
  methods: [],
  actualName: "AppSwitcherContent"
};