import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape, tooltipBaseProps, placementOptions } from "@jutro/prop-types";
import { getMessageProp, useId } from "@jutro/platform";
import { TooltipContent } from "./TooltipContent";
import styles from "./TooltipIcon.module.css";
import { Tooltip } from "./Tooltip";
import { Icon } from "../Icon/Icon";
import { messages } from "./TooltipIcon.messages";
var propTypes = _objectSpread(_objectSpread({
    id: PropTypes.string,
    icon: PropTypes.string
  }, tooltipBaseProps), {}, {
    placement: PropTypes.oneOf(placementOptions),
    className: PropTypes.string,
    messageProps: PropTypes.shape({
      showTooltip: intlMessageShape
    }),
    flipBehavior: PropTypes.arrayOf(PropTypes.oneOf(["right", "bottom", "top", "left"])),
    labelPosition: PropTypes.oneOf(["top", "left"]),
    showInlineLabel: PropTypes.bool,
    fieldLabelId: PropTypes.string,
    showOnInit: PropTypes.bool
  }),
  defaultProps = {
    icon: "gw-help",
    showOnInit: !1
  };
export var TooltipIcon = function (props) {
  var _defaultProps$props = _objectSpread(_objectSpread({}, defaultProps), props),
    idProp = _defaultProps$props.id,
    icon = _defaultProps$props.icon,
    text = _defaultProps$props.text,
    title = _defaultProps$props.title,
    link = _defaultProps$props.link,
    href = _defaultProps$props.href,
    renderContent = _defaultProps$props.renderContent,
    className = _defaultProps$props.className,
    placement = _defaultProps$props.placement,
    flipBehavior = _defaultProps$props.flipBehavior,
    fieldLabelId = _defaultProps$props.fieldLabelId,
    labelPosition = _defaultProps$props.labelPosition,
    messageProps = _defaultProps$props.messageProps,
    showInlineLabel = _defaultProps$props.showInlineLabel,
    showOnInit = _defaultProps$props.showOnInit,
    id = useId({
      default: idProp
    }),
    closedIcon = "".concat(icon, "-outline"),
    openIcon = "".concat(icon),
    _useState = useState(closedIcon),
    _useState2 = _slicedToArray(_useState, 2),
    currentIcon = _useState2[0],
    setCurrentIcon = _useState2[1],
    _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    tooltip = _useState4[0],
    setTooltip = _useState4[1],
    iconRef = useRef(null),
    translator = useTranslator();
  useEffect(function () {
    var handleClickOutside = function (event) {
      iconRef && tooltip && !tooltip.popper.contains(event.target) && iconRef.current && !iconRef.current.contains(event.target) && null != tooltip && tooltip.state.isVisible && (setCurrentIcon(closedIcon), tooltip.hide());
    };
    return document.addEventListener("mousedown", handleClickOutside), function () {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closedIcon, iconRef, tooltip]);
  var showTooltipLabel,
    tooltipProps = {
      id: id,
      content: renderContent ? renderContent() : React.createElement(TooltipContent, {
        title: title,
        text: text,
        href: href,
        link: link
      }),
      placement: placement,
      trigger: "manual",
      hideOnClick: !1,
      flipBehavior: flipBehavior,
      showOnInit: showOnInit
    },
    classes = cx(styles.tooltipIcon, labelPosition && _defineProperty({}, styles[labelPosition], !showInlineLabel), className);
  return React.createElement(Tooltip, _extends({}, tooltipProps, {
    onCreate: function (instance) {
      setTooltip(instance);
    }
  }), React.createElement("button", _extends({
    ref: iconRef,
    type: "button",
    onClick: function (evt) {
      evt.preventDefault(), tooltip && (tooltip.state.isVisible ? (setCurrentIcon(closedIcon), tooltip.hide()) : (setCurrentIcon(openIcon), tooltip.show()));
    },
    className: classes
  }, (showTooltipLabel = getMessageProp("showTooltip", messageProps, messages), {
    "aria-label": translator(showTooltipLabel),
    "aria-haspopup": "true",
    "aria-describedby": fieldLabelId
  })), React.createElement(Icon, {
    icon: currentIcon,
    size: "large"
  })));
};
TooltipIcon.propTypes = propTypes, TooltipIcon.defaultProps = defaultProps, TooltipIcon.displayName = "TooltipIcon", TooltipIcon.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TooltipIcon",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-help'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to be displayed"
    },
    showOnInit: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Displays the tooltip when the page is loaded"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component, referenced to find it in the document tree"
    },
    placement: {
      type: {
        name: "enum",
        computed: !0,
        value: "placementOptions"
      },
      required: !1,
      description: "Positions of the tooltip relative to its reference element (icon)"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          showTooltip: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    flipBehavior: {
      type: {
        name: "arrayOf",
        value: {
          name: "enum",
          value: [{
            value: "'right'",
            computed: !1
          }, {
            value: "'bottom'",
            computed: !1
          }, {
            value: "'top'",
            computed: !1
          }, {
            value: "'left'",
            computed: !1
          }]
        }
      },
      required: !1,
      description: "Determines the order of flipping, i.e., which placements to prefer if a certain placement cannot be used"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Information from Field Component to the Tooltip Icon about the position of the label"
    },
    showInlineLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Information from Field Component. If true, the label is displayed inline."
    },
    fieldLabelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Information from Field Component to link tooltip with related field."
    }
  },
  composes: ["@jutro/prop-types"]
}, TooltipIcon.__docgenInfo = {
  componentName: "TooltipIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "TooltipIcon",
  methods: [],
  actualName: "TooltipIcon",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component, referenced to find it in the document tree"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to be displayed",
      defaultValue: {
        value: "'gw-help'",
        computed: !1
      }
    },
    placement: {
      type: {
        name: "enum",
        computed: !0,
        value: "placementOptions"
      },
      required: !1,
      description: "Positions of the tooltip relative to its reference element (icon)"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for the component"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          showTooltip: {
            name: "union",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    flipBehavior: {
      type: {
        name: "arrayOf",
        value: {
          name: "enum",
          value: [{
            value: "'right'",
            computed: !1
          }, {
            value: "'bottom'",
            computed: !1
          }, {
            value: "'top'",
            computed: !1
          }, {
            value: "'left'",
            computed: !1
          }]
        }
      },
      required: !1,
      description: "Determines the order of flipping, i.e., which placements to prefer if a certain placement cannot be used"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Information from Field Component to the Tooltip Icon about the position of the label"
    },
    showInlineLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Information from Field Component. If true, the label is displayed inline."
    },
    fieldLabelId: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Information from Field Component to link tooltip with related field."
    },
    showOnInit: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Displays the tooltip when the page is loaded",
      defaultValue: {
        value: "false",
        computed: !1
      }
    }
  },
  composes: ["@jutro/prop-types"]
};