import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import cx from "classnames";
import { intlMessageShape } from "@jutro/prop-types";
import { Icon } from "../../Icon/Icon";
import styles from "./ProgressPhase.module.css";
export var PhaseProgressLabel = function (_ref) {
  var iconName,
    id = _ref.id,
    title = _ref.title,
    stepTitle = _ref.stepTitle,
    icon = _ref.icon,
    complete = _ref.complete,
    active = _ref.active,
    isError = _ref.isError,
    translator = useTranslator(),
    iconComponent = icon && React.createElement(Icon, {
      icon: (iconName = icon, isError ? "gw-error-outline" : complete ? "gw-check" : iconName),
      className: styles.icon,
      size: complete ? "small" : "medium"
    }),
    labelStyles = cx(styles.label, _defineProperty({}, styles.error, isError));
  return React.createElement("div", {
    className: labelStyles,
    role: isError ? "alert" : void 0
  }, iconComponent, React.createElement("div", {
    className: styles.labelText,
    id: id
  }, React.createElement("span", {
    className: styles.title
  }, translator(title)), active && React.createElement("div", {
    className: styles.activeStep
  }, translator(stepTitle))));
};
var phaseProgressLabelPropTypes = {
  id: PropTypes.string,
  title: intlMessageShape,
  stepTitle: intlMessageShape,
  icon: PropTypes.string,
  complete: PropTypes.bool,
  active: PropTypes.bool,
  isError: PropTypes.bool
};
PhaseProgressLabel.propTypes = phaseProgressLabelPropTypes, PhaseProgressLabel.__docgenInfo = {
  description: "PhaseProgressLabel\n@type {React.FC<PropTypes.InferProps<typeof phaseProgressLabelPropTypes>>}",
  methods: [],
  displayName: "PhaseProgressLabel",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Phase id"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Phase title"
    },
    stepTitle: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Current step title"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of the displayed icon"
    },
    complete: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if a phase is complete"
    },
    active: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if a phase is active"
    },
    isError: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if a phase is in error state"
    }
  }
}, PhaseProgressLabel.__docgenInfo = {
  componentName: "PhaseProgressLabel",
  packageName: "@jutro/components",
  description: "PhaseProgressLabel",
  displayName: "PhaseProgressLabel",
  methods: [],
  actualName: "PhaseProgressLabel",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Phase id"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Phase title"
    },
    stepTitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Current step title"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Name of the displayed icon"
    },
    complete: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if a phase is complete"
    },
    active: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if a phase is active"
    },
    isError: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag indicating if a phase is in error state"
    }
  }
};