import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["id", "onValueChange", "onValidationChange", "showErrors", "dataType", "value", "defaultValue", "minDate", "maxDate", "model", "path", "showTime", "dataPath", "preserveTimeZone", "disabled", "readOnly", "className", "visible", "contentContainerClassName", "controlClassName", "labelContainerClassName", "labelClassName", "secondaryLabelClassName", "labelPosition", "isInitiallyOpen", "timeIntervals", "timeFormat"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { DATA_TYPE_OBJECT, dateValueShape, dataTypeShapeWithDateTime, intlMessageShape, nestedTooltipShape } from "@jutro/prop-types";
import { publish, JUTRO_TOPICS } from "@jutro/events";
import { GridLayout, useBreakpoint } from "@jutro/layout";
import { LocaleContext } from "@jutro/locale";
import { FieldComponent } from "../../FieldComponent/FieldComponent";
import { DateField } from "../DateField/DateField";
import { TimeField } from "../TimeField/TimeField";
import { formatValueFromDataType, formatValueToDataType, parseBoundaryDate, getMinTime, getMaxTime, isValidDate, isValidTime } from "./helpers";
import styles from "./DateTimeField.module.css";
export var DateTimeField = function DateTimeField(props) {
  var breakpointProps = useBreakpoint(props).breakpointProps,
    phone = props.phone,
    phoneWide = props.phoneWide,
    tablet = props.tablet,
    id = breakpointProps.id,
    onValueChange = breakpointProps.onValueChange,
    onValidationChange = breakpointProps.onValidationChange,
    showErrors = breakpointProps.showErrors,
    _breakpointProps$data = breakpointProps.dataType,
    dataType = void 0 === _breakpointProps$data ? DATA_TYPE_OBJECT : _breakpointProps$data,
    value = breakpointProps.value,
    defaultValue = breakpointProps.defaultValue,
    minDate = breakpointProps.minDate,
    maxDate = breakpointProps.maxDate,
    model = breakpointProps.model,
    path = breakpointProps.path,
    showTime = breakpointProps.showTime,
    dataPath = breakpointProps.dataPath,
    _breakpointProps$pres = breakpointProps.preserveTimeZone,
    preserveTimeZone = void 0 !== _breakpointProps$pres && _breakpointProps$pres,
    disabled = breakpointProps.disabled,
    readOnly = breakpointProps.readOnly,
    className = breakpointProps.className,
    visible = breakpointProps.visible,
    contentContainerClassName = breakpointProps.contentContainerClassName,
    controlClassName = breakpointProps.controlClassName,
    labelContainerClassName = breakpointProps.labelContainerClassName,
    labelClassName = breakpointProps.labelClassName,
    secondaryLabelClassName = breakpointProps.secondaryLabelClassName,
    labelPosition = breakpointProps.labelPosition,
    isInitiallyOpen = breakpointProps.isInitiallyOpen,
    timeIntervals = breakpointProps.timeIntervals,
    timeFormat = breakpointProps.timeFormat,
    other = _objectWithoutProperties(breakpointProps, _excluded),
    defaultTimeZone = useContext(LocaleContext).defaultTimeZone,
    _splitProps = function (props) {
      var _context;
      return _reduceInstanceProperty(_context = _Object$entries(props)).call(_context, function (_ref, _ref2) {
        var dateProps = _ref.dateProps,
          timeProps = _ref.timeProps,
          _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          value = _ref3[1],
          newDateProps = dateProps,
          newTimeProps = timeProps;
        return key.match(/Time/) ? newTimeProps = _objectSpread(_defineProperty({}, key.replace("Time", ""), value), timeProps) : newDateProps = _objectSpread(_defineProperty({}, key, value), dateProps), {
          dateProps: newDateProps,
          timeProps: newTimeProps
        };
      }, {
        timeProps: {},
        dateProps: {}
      });
    }(other),
    dateProps = _splitProps.dateProps,
    timeProps = _splitProps.timeProps,
    compositeValue = formatValueFromDataType(value, {
      includeTime: showTime,
      timezone: defaultTimeZone,
      preserveTimeZone: preserveTimeZone
    });
  useEffect(function () {
    if (!value && defaultValue) {
      var newValue = formatValueFromDataType(defaultValue, {
        includeTime: showTime,
        timezone: defaultTimeZone,
        preserveTimeZone: preserveTimeZone
      });
      handleChange(newValue);
    }
    publish(JUTRO_TOPICS.COMPONENT_LOADED, FieldComponent.fieldEventFormatter(_objectSpread({
      componentName: DateTimeField.displayName
    }, props)));
  }, []);
  var labelOnTop = "top" === labelPosition,
    handleChange = function (val) {
      var today = new Date(),
        newValue = _objectSpread(_objectSpread(_objectSpread({}, {
          year: today.getFullYear(),
          month: today.getMonth(),
          day: today.getDate(),
          hour: 0,
          minute: 0
        }), compositeValue), val);
      val || (newValue = void 0);
      var formattedValue = formatValueToDataType(newValue, {
        dataType: dataType,
        timezone: defaultTimeZone,
        includeTime: showTime,
        preserveTimeZone: preserveTimeZone
      });
      onValueChange && (onValueChange(formattedValue, model || path, {
        id: id,
        dataPath: dataPath
      }), publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread(_objectSpread({}, props), {}, {
        value: formattedValue
      }))));
    },
    gridColumns = labelOnTop && showTime ? [2, 1] : [];
  return React.createElement(GridLayout, {
    id: id,
    className: className,
    columns: gridColumns,
    hgap: "small",
    vgap: labelOnTop ? "none" : void 0,
    phone: {
      columns: [],
      vgap: void 0
    },
    style: {
      gridTemplateRows: "min-content"
    },
    defaultGridItem: {
      className: cx(_defineProperty({}, styles.fieldContainer, labelOnTop)),
      phone: {
        className: void 0
      }
    }
  }, function () {
    var selectedValue;
    isValidDate(compositeValue) && (selectedValue = {
      year: compositeValue.year,
      month: compositeValue.month,
      day: compositeValue.day
    });
    var dateFieldProps = _objectSpread(_objectSpread({
      onValidationChange: onValidationChange,
      showErrors: showErrors,
      disabled: disabled,
      readOnly: readOnly,
      visible: visible,
      controlClassName: controlClassName,
      labelClassName: labelClassName,
      secondaryLabelClassName: secondaryLabelClassName,
      labelPosition: labelPosition,
      phone: phone,
      phoneWide: phoneWide,
      tablet: tablet,
      isInitiallyOpen: isInitiallyOpen
    }, dateProps), {}, {
      id: "".concat(id, "-dateField"),
      dataType: DATA_TYPE_OBJECT,
      value: selectedValue,
      minDate: parseBoundaryDate(minDate),
      maxDate: parseBoundaryDate(maxDate),
      onValueChange: function (newValue) {
        if (newValue) {
          var year = newValue.year,
            month = newValue.month,
            day = newValue.day;
          handleChange({
            year: year,
            month: month,
            day: day
          });
        } else handleChange(void 0);
      },
      contentContainerClassName: cx(_defineProperty({}, styles.dateField, labelOnTop), contentContainerClassName),
      labelContainerClassName: cx(_defineProperty({}, styles.dateLabel, labelOnTop), labelContainerClassName)
    });
    return React.createElement(DateField, dateFieldProps);
  }(), showTime && function () {
    var selectedValue;
    isValidTime(compositeValue) && (selectedValue = {
      hour: compositeValue.hour,
      minute: compositeValue.minute
    });
    var timeFieldProps = _objectSpread(_objectSpread({
      onValidationChange: onValidationChange,
      showErrors: showErrors,
      disabled: disabled,
      readOnly: readOnly,
      visible: visible,
      controlClassName: controlClassName,
      labelClassName: labelClassName,
      secondaryLabelClassName: secondaryLabelClassName,
      labelPosition: labelPosition,
      phone: phone,
      phoneWide: phoneWide,
      tablet: tablet
    }, timeProps), {}, {
      timeIntervals: timeIntervals,
      timeFormat: timeFormat,
      isInitiallyOpen: isInitiallyOpen,
      id: "".concat(id, "-timeField"),
      value: selectedValue,
      min: getMinTime(minDate, value),
      max: getMaxTime(maxDate, value),
      onValueChange: function (newValue) {
        if (newValue) {
          var hour = newValue.hour,
            minute = newValue.minute;
          handleChange({
            hour: hour,
            minute: minute
          });
        } else handleChange(void 0);
      },
      contentContainerClassName: cx(_defineProperty({}, styles.timeField, labelOnTop), contentContainerClassName),
      labelContainerClassName: cx(_defineProperty({}, styles.timeLabel, labelOnTop), labelContainerClassName)
    });
    return React.createElement(TimeField, timeFieldProps);
  }());
};
DateTimeField.displayName = "DateTimeField", DateTimeField.propTypes = _objectSpread(_objectSpread(_objectSpread({}, TimeField.propTypes), DateField.propTypes), {}, {
  dataType: dataTypeShapeWithDateTime.isRequired,
  value: dateValueShape,
  defaultValue: dateValueShape,
  showTime: PropTypes.bool,
  onBlurTime: PropTypes.func,
  onFocusTime: PropTypes.func,
  requiredTime: PropTypes.bool,
  showErrorsTime: PropTypes.bool,
  showRequiredTime: PropTypes.bool,
  showOptionalTime: PropTypes.bool,
  labelTime: intlMessageShape,
  secondaryLabelTime: intlMessageShape,
  hideLabelTime: PropTypes.bool,
  tooltipTime: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  placeholderTime: intlMessageShape,
  preserveTimeZone: PropTypes.bool
}), DateTimeField.defaultProps = _objectSpread(_objectSpread(_objectSpread({}, TimeField.defaultProps), DateField.defaultProps), {}, {
  dataType: DATA_TYPE_OBJECT,
  showTime: !0
}), DateTimeField.__docgenInfo = {
  description: "@metadataType field",
  methods: [],
  displayName: "DateTimeField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShapeWithDateTime.isRequired"
      },
      required: !1,
      description: "Returned data type"
    },
    showTime: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows the time picker"
    },
    value: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "Date and time to display"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateValueShape"
      },
      required: !1,
      description: "Sets the default field value on render if there is a default value; needs `onValueChange` to work"
    },
    onBlurTime: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when blur event is fired in the time picker"
    },
    onFocusTime: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when focus event is fired in the time picker"
    },
    requiredTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, time picker is required"
    },
    showErrorsTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows errors for the time picker"
    },
    showRequiredTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows required indicator for the time picker"
    },
    showOptionalTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows optional indicator for the time picker"
    },
    labelTime: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for the time picker label"
    },
    secondaryLabelTime: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for the secondary time picker label"
    },
    hideLabelTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides the label on any layout for the time picker"
    },
    tooltipTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip for the time picker"
    },
    placeholderTime: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Intl message for the time picker placeholder"
    },
    preserveTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component will not convert selected date to UTC"
    }
  },
  composes: ["../TimeField/TimeField", "../DateField/DateField"]
}, DateTimeField.__docgenInfo = {
  componentName: "DateTimeField",
  packageName: "@jutro/components",
  description: "",
  displayName: "DateTimeField",
  methods: [],
  actualName: "DateTimeField",
  metadataType: "field",
  props: {
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }, {
          value: "'date-time'",
          computed: !1
        }]
      },
      required: !1,
      description: "Returned data type",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Date and time to display"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !0
            },
            month: {
              name: "number",
              required: !1
            },
            day: {
              name: "number",
              required: !1
            },
            hour: {
              name: "number",
              required: !1
            },
            minute: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Sets the default field value on render if there is a default value; needs `onValueChange` to work"
    },
    showTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows the time picker",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    onBlurTime: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when blur event is fired in the time picker"
    },
    onFocusTime: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when focus event is fired in the time picker"
    },
    requiredTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, time picker is required"
    },
    showErrorsTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows errors for the time picker"
    },
    showRequiredTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows required indicator for the time picker"
    },
    showOptionalTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Shows optional indicator for the time picker"
    },
    labelTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for the time picker label"
    },
    secondaryLabelTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for the secondary time picker label"
    },
    hideLabelTime: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides the label on any layout for the time picker"
    },
    tooltipTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: !1,
      description: "Tooltip for the time picker"
    },
    placeholderTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Intl message for the time picker placeholder"
    },
    preserveTimeZone: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, component will not convert selected date to UTC"
    }
  },
  composes: ["../TimeField/TimeField", "../DateField/DateField"]
};