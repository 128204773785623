import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import isNil from "lodash/isNil";
import { DATA_TYPE_OBJECT } from "@jutro/prop-types";
import { formatDateToDataType, parseDateFromDataType } from "@jutro/locale";
export var parseDateTimeZoneFromDataType = function (value, defaultTimeZone, showTime) {
  var date,
    dateShape,
    datetime = value.datetime,
    timeZone = value.timezone || defaultTimeZone;
  if (isNil(datetime)) return {
    timezone: timeZone
  };
  if (!showTime) {
    var parsedDate = parseDateFromDataType(datetime);
    return {
      datetime: {
        year: parsedDate.getFullYear(),
        month: parsedDate.getMonth(),
        day: parsedDate.getDate()
      },
      timezone: timeZone
    };
  }
  if (isNil(dateShape = datetime) || isNil(dateShape.year) || isNil(dateShape.month) || isNil(dateShape.day)) date = datetime;else {
    var year = datetime.year,
      _datetime$month = datetime.month,
      month = void 0 === _datetime$month ? 0 : _datetime$month,
      _datetime$day = datetime.day,
      day = void 0 === _datetime$day ? 1 : _datetime$day,
      _datetime$hour = datetime.hour,
      hour = void 0 === _datetime$hour ? 0 : _datetime$hour,
      _datetime$minute = datetime.minute,
      minute = void 0 === _datetime$minute ? 0 : _datetime$minute;
    date = new Date(Date.UTC(year, month, day, hour, minute));
  }
  var zonedDate = utcToZonedTime(date, timeZone);
  return {
    datetime: {
      year: zonedDate.getFullYear(),
      month: zonedDate.getMonth(),
      day: zonedDate.getDate(),
      hour: zonedDate.getHours(),
      minute: zonedDate.getMinutes()
    },
    timezone: timeZone
  };
};
export var formatDateTimeZoneToDataType = function (value, dataType, showTime) {
  var datetime = value.datetime,
    timezone = value.timezone;
  if (!datetime) return value;
  if (!showTime) {
    var date = new Date(datetime.year, datetime.month || 0, datetime.day || 1);
    return {
      datetime: formatDateToDataType(date, dataType),
      timezone: timezone
    };
  }
  var year = datetime.year,
    _datetime$month2 = datetime.month,
    month = void 0 === _datetime$month2 ? 0 : _datetime$month2,
    _datetime$day2 = datetime.day,
    day = void 0 === _datetime$day2 ? 1 : _datetime$day2,
    _datetime$hour2 = datetime.hour,
    hour = void 0 === _datetime$hour2 ? 0 : _datetime$hour2,
    _datetime$minute2 = datetime.minute,
    utcDate = zonedTimeToUtc(new Date(year, month, day, hour, void 0 === _datetime$minute2 ? 0 : _datetime$minute2), timezone);
  return dataType === DATA_TYPE_OBJECT ? {
    datetime: {
      year: utcDate.getUTCFullYear(),
      month: utcDate.getUTCMonth(),
      day: utcDate.getUTCDate(),
      hour: utcDate.getUTCHours(),
      minute: utcDate.getUTCMinutes()
    },
    timezone: timezone
  } : {
    datetime: utcDate.toISOString(),
    timezone: timezone
  };
};