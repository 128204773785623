import { defineMessages } from "react-intl";
export var messages = defineMessages({
  mainMessage: {
    id: "jutro-components.widgets.ErrorNotice.mainMessage",
    defaultMessage: "Something went wrong with a component"
  },
  detailedMessage: {
    id: "jutro-components.widgets.ErrorNotice.detailedMessage",
    defaultMessage: "We were not able to recover from the situation automatically. Please reload the page."
  },
  actionLabel: {
    id: "jutro-components.widgets.ErrorNotice.actionLabel",
    defaultMessage: "Reload Page"
  }
});