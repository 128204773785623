import { defineMessages } from "react-intl";
export var messages = defineMessages({
  noOptionsMessage: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.noOptionsMessage",
    defaultMessage: "No options"
  },
  createNewMessage: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.createNewMessage",
    defaultMessage: "Add: {message}"
  },
  clearButton: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.clearButton",
    defaultMessage: "Clear"
  },
  useUpAndDownToChooseOptions: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.useUpAndDownToChooseOptions",
    defaultMessage: "Use Up and Down to choose options."
  },
  pressEnterToSelectFocused: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.pressEnterToSelectFocused",
    defaultMessage: "Press Enter to select the currently focused option."
  },
  pressEscapeToExitTheMenu: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.pressEscapeToExitTheMenu",
    defaultMessage: "Press Escape to exit the menu."
  },
  pressTabToSelectAndExit: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.pressTabToSelectAndExit",
    defaultMessage: "Press Tab to select the option and exit the menu."
  },
  typeToRefineList: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.typeToRefineList",
    defaultMessage: "Type to refine list. Press Escape to remove the search term."
  },
  addNewValue: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.addNewValue",
    defaultMessage: "Type to add a new value."
  },
  pressDownToOpenTheMenu: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.pressDownToOpenTheMenu",
    defaultMessage: "Press Down to open the menu."
  },
  pressLeftToFocusSelected: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.pressLeftToFocusSelected",
    defaultMessage: "Press left to focus selected values."
  },
  guidanceValue: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.guidanceValue",
    defaultMessage: "Use left and right to toggle between focused values, press Backspace to remove the currently focused value."
  },
  optionSelected: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.optionSelected",
    defaultMessage: "option {label}, selected."
  },
  optionSelectedDisabled: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.optionSelectedDisabled",
    defaultMessage: "option {label} is disabled. Select another option."
  },
  optionDeselected: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.optionDeselected",
    defaultMessage: "Option {label}, deselected."
  },
  optionsCleared: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.optionsCleared",
    defaultMessage: "All selected options have been cleared."
  },
  initialInputFocus: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.initialInputFocus",
    defaultMessage: "Selected options: {options}."
  },
  valueFocused: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.valueFocused",
    defaultMessage: "Value {label} focused."
  },
  menuFocused: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.menuFocused",
    defaultMessage: "Option {label} {status} {disabled}, {arrayIndex}."
  },
  arrayIndex: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.arrayIndex",
    defaultMessage: "{index} of {count}"
  },
  disabled: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.disabled",
    defaultMessage: "disabled"
  },
  selected: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.selected",
    defaultMessage: "selected"
  },
  focused: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.focused",
    defaultMessage: "focused"
  },
  numberOfAvailableResults: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.numberOfAvailableResults",
    defaultMessage: "Number of available results: {count}."
  },
  listOfAvailableOptions: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.listOfAvailableOptions",
    defaultMessage: "List of available options: {count}."
  },
  searchForTerm: {
    id: "jutro-components.widgets.inputs.GenericSelectControl.ariaLive.searchForTerm",
    defaultMessage: "Search for term: {term}. Press Escape to remove the search term."
  }
});