import React, { memo, useCallback, useMemo } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory, createMemoryHistory } from "history";
import { routeConfirmationModal, RouteTracker } from "@jutro/router";
import { isTest } from "../env";
var RoutingContentInternal = function (_ref) {
  var children = _ref.children,
    RouterProp = _ref.routerComponent,
    routerHistoryProp = _ref.routerHistory,
    routerBasename = _ref.routerBasename,
    modalEmitter = _ref.modalEmitter,
    trackingCallback = _ref.trackingCallback,
    getUserConfirmation = useCallback(function (message, callback) {
      return routeConfirmationModal(message, callback, modalEmitter);
    }, [modalEmitter]),
    routerHistory = useMemo(function () {
      return routerHistoryProp || (isTest() ? createMemoryHistory({
        getUserConfirmation: getUserConfirmation
      }) : createBrowserHistory({
        basename: routerBasename
      }));
    }, [getUserConfirmation, routerBasename, routerHistoryProp]);
  return RouterProp ? React.createElement(RouterProp, {
    basename: routerBasename,
    getUserConfirmation: getUserConfirmation
  }, React.createElement(RouteTracker, {
    track: trackingCallback
  }), children) : React.createElement(Router, {
    history: routerHistory,
    basename: routerBasename,
    getUserConfirmation: getUserConfirmation
  }, React.createElement(RouteTracker, {
    track: trackingCallback
  }), children);
};
RoutingContentInternal.displayName = "RoutingContent";
export var RoutingContent = memo(RoutingContentInternal);
RoutingContentInternal.__docgenInfo = {
  description: "Render the children with the correct router. If a custom Router prop is present\nuse it as the router. Otherwise use the base React Router with a custom history.",
  methods: [],
  displayName: "RoutingContent"
}, RoutingContentInternal.__docgenInfo = {
  componentName: "RoutingContent",
  packageName: "@jutro/app",
  description: "Render the children with the correct router. If a custom Router prop is present\nuse it as the router. Otherwise use the base React Router with a custom history.",
  displayName: "RoutingContent",
  methods: [],
  actualName: "RoutingContentInternal"
};