import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import PropTypes from "prop-types";
import { intlMessageShape } from "./intlMessageShape";
export var placementOptions = ["top", "top-start", "top-end", "right", "right-start", "right-end", "bottom", "bottom-start", "bottom-end", "left", "left-start", "left-end", "auto", "auto-start", "auto-end"];
export var tooltipBaseProps = {
  text: intlMessageShape,
  title: intlMessageShape,
  link: intlMessageShape,
  href: intlMessageShape,
  titleClass: PropTypes.string,
  textClass: PropTypes.string,
  linkClass: PropTypes.string,
  renderContent: PropTypes.func
};
export var tooltipBaseShape = PropTypes.shape(tooltipBaseProps);
var tooltipShapeProps = _objectSpread(_objectSpread({}, tooltipBaseProps), {}, {
  placement: PropTypes.oneOf(placementOptions)
});
export var tooltipShape = PropTypes.shape(tooltipShapeProps);
export var nestedTooltipShape = PropTypes.shape(_objectSpread(_objectSpread({}, tooltipShapeProps), {}, {
  id: PropTypes.string,
  icon: PropTypes.string
}));