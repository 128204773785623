import React from "react";
import cx from "classnames";
import { getMessageProp } from "@jutro/platform";
import { useTranslator } from "@jutro/locale";
import { messages } from "../FileUploadField.messages";
import { FileUploadActionButton } from "./FileUploadActionButton";
import styles from "../FileUploadField.module.css";
export var EmptyThinArea = function (_ref) {
  var disabled = _ref.disabled,
    messageProps = _ref.messageProps,
    buttonType = _ref.buttonType,
    buttonSize = _ref.buttonSize,
    buttonIcon = _ref.buttonIcon,
    disableDragAndDropMessage = _ref.disableDragAndDropMessage,
    onAction = _ref.onAction,
    genericMessage = _ref.genericMessage,
    message = useTranslator()(getMessageProp("emptyUploadAreaMessage", messageProps, messages), genericMessage);
  return React.createElement(React.Fragment, null, React.createElement(FileUploadActionButton, {
    size: buttonSize,
    type: buttonType,
    icon: buttonIcon,
    messageProps: messageProps,
    disabled: disabled,
    onClick: onAction
  }), !disableDragAndDropMessage && React.createElement("div", {
    className: cx(styles.valueText, styles.paddedLeft)
  }, message));
};
EmptyThinArea.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "EmptyThinArea"
}, EmptyThinArea.__docgenInfo = {
  componentName: "EmptyThinArea",
  packageName: "@jutro/components",
  description: "",
  displayName: "EmptyThinArea",
  methods: [],
  actualName: "EmptyThinArea"
};