import PropTypes from "prop-types";
import { intlMessageShape } from "./intlMessageShape";
import { intlToShape } from "./toShape";
export var dropdownMenuLinkPropTypes = {
  id: PropTypes.string,
  to: intlToShape,
  href: intlMessageShape,
  target: PropTypes.string,
  rel: PropTypes.string,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape])
};