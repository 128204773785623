import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import isEmpty from "lodash/isEmpty";
import { isEmptyValue } from "@jutro/data";
import { findContentFromMetadata, prepareContentFromMetadata } from "../render/renderMetadataHelper";
export function validateFieldUsingMetadata(metadata) {
  var _ref = metadata.componentProps || {},
    required = _ref.required,
    validationMessages = _ref.validationMessages,
    value = _ref.value;
  return !!isEmpty(validationMessages) && (!0 !== required || !isEmptyValue(value));
}
export function findInvalidFieldsFromMetadata(contentMetadata, overrideProps, resolvers) {
  var rootMetadata = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
    skipHidden = !(arguments.length > 4 && void 0 !== arguments[4]) || arguments[4],
    preparedContent = prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, rootMetadata),
    fieldArray = findContentFromMetadata(preparedContent, function (item) {
      return "field" === item.type;
    }, skipHidden),
    invalidFields = _filterInstanceProperty(fieldArray).call(fieldArray, function (item) {
      return !validateFieldUsingMetadata(item);
    });
  return invalidFields;
}