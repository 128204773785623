import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "dataType", "inputFormat", "value", "onValueChange", "minYear", "maxYear", "model", "path", "dataPath", "openToDate", "messageProps", "todayButtonText"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { DATA_TYPE_STRING, dataTypeShapeWithNumber, getValidationRuleShape, dateValidationRuleShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { useBreakpoint } from "@jutro/layout";
import { useId, useEvent } from "@jutro/platform";
import { DateField } from "../DateField/DateField";
import { MIN_DEFAULT_YEAR, MAX_DEFAULT_YEAR } from "../DateField/DateFieldInternal";
import { YearFieldCustomHeader } from "./YearFieldCustomHeader";
import { yearValueToDataType, getYearFromValue, twoToFourDigitYear, getInputFormat, isValidYear, isNumberKey } from "./helpers";
import { messages } from "./YearField.messages";
export var YearField = function (props) {
  var _getInputFormat2,
    dateFieldRef = useRef(null),
    translator = useTranslator(),
    breakpointProps = useBreakpoint(props).breakpointProps,
    id = breakpointProps.id,
    dataType = breakpointProps.dataType,
    inputFormat = breakpointProps.inputFormat,
    value = breakpointProps.value,
    onValueChange = breakpointProps.onValueChange,
    minYear = breakpointProps.minYear,
    maxYear = breakpointProps.maxYear,
    model = breakpointProps.model,
    path = breakpointProps.path,
    dataPath = breakpointProps.dataPath,
    openToDate = breakpointProps.openToDate,
    messageProps = breakpointProps.messageProps,
    todayButtonText = breakpointProps.todayButtonText,
    other = _objectWithoutProperties(breakpointProps, _excluded),
    uniqueId = useId({
      default: id
    }),
    notifyChange = function (newValue) {
      onValueChange && onValueChange(newValue, model || path, {
        id: id,
        dataPath: dataPath
      });
    },
    onValueChangeHandler = useEvent(function (event) {
      var _getInputFormat,
        dateFieldInternal = dateFieldRef.current;
      if (null == dateFieldInternal || dateFieldInternal.handleChangeRaw(event), isValidYear(event.target.value, null === (_getInputFormat = getInputFormat(inputFormat)) || void 0 === _getInputFormat ? void 0 : _getInputFormat.length)) {
        var yearValue = twoToFourDigitYear(event.target.value, minYear, maxYear),
          formattedValue = yearValueToDataType(yearValue, dataType);
        notifyChange(formattedValue);
      } else notifyChange(void 0);
    }),
    onValueSelectHandler = useEvent(function (date) {
      var dateFieldInternal = dateFieldRef.current;
      null == dateFieldInternal || dateFieldInternal.setFocus();
      var formattedValue = yearValueToDataType(String(date.getFullYear()), dataType);
      notifyChange(formattedValue);
    }),
    minDateValidationMessage = (null == messageProps ? void 0 : messageProps.validationMinYear) || messages.validationMinYear,
    maxDateValidationMessage = (null == messageProps ? void 0 : messageProps.validationMaxYear) || messages.validationMaxYear;
  return React.createElement(DateField, _extends({}, other, {
    ref: dateFieldRef,
    id: uniqueId,
    dataType: "object",
    inputFormat: inputFormat,
    value: getYearFromValue(value),
    onChangeRaw: onValueChangeHandler,
    onSelect: onValueSelectHandler,
    minDate: "".concat(minYear || MIN_DEFAULT_YEAR, "-01-01"),
    maxDate: "".concat(maxYear || MAX_DEFAULT_YEAR, "-12-31"),
    minYear: minYear || MIN_DEFAULT_YEAR,
    maxYear: maxYear || MAX_DEFAULT_YEAR,
    showYearPicker: !0,
    openToDate: getYearFromValue(openToDate),
    todayButtonText: todayButtonText || messages.todayButton,
    renderCustomHeader: function (customHeaderProps) {
      return React.createElement(YearFieldCustomHeader, customHeaderProps);
    },
    customInputProps: {
      type: "text",
      pattern: "[0-9]*",
      maxLength: (null === (_getInputFormat2 = getInputFormat(inputFormat)) || void 0 === _getInputFormat2 ? void 0 : _getInputFormat2.length) || 4,
      onKeyPress: isNumberKey
    },
    messageProps: _objectSpread({
      validationMinDate: translator(minDateValidationMessage, {
        value: minYear || MIN_DEFAULT_YEAR
      }),
      validationMaxDate: translator(maxDateValidationMessage, {
        value: maxYear || MAX_DEFAULT_YEAR
      })
    }, messageProps),
    model: model,
    path: path,
    dataPath: dataPath
  }));
};
YearField.displayName = "YearField", YearField.propTypes = _objectSpread(_objectSpread({}, DateField.propTypes), {}, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.shape({
    year: PropTypes.number
  })]),
  dataType: dataTypeShapeWithNumber,
  inputFormat: getValidationRuleShape(PropTypes.oneOf(["yyyy", "yy"])),
  maxYear: dateValidationRuleShape,
  minYear: dateValidationRuleShape,
  openToDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])
}), YearField.defaultProps = _objectSpread(_objectSpread({}, DateField.defaultProps), {}, {
  dataType: DATA_TYPE_STRING,
  inputFormat: "yyyy"
}), YearField.__docgenInfo = {
  description: "@metadataType field",
  methods: [],
  displayName: "YearField",
  props: {
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "dataTypeShapeWithNumber"
      },
      required: !1,
      description: "Format of the value"
    },
    inputFormat: {
      defaultValue: {
        value: "'yyyy'",
        computed: !1
      },
      type: {
        name: "custom",
        raw: "getValidationRuleShape(PropTypes.oneOf(['yyyy', 'yy']))"
      },
      required: !1,
      description: "Format of the year can be YYYY for four digits year or YY for two digits year"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Value to display in control"
    },
    maxYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets maxYear validation on YearField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: maxYear: [SomeYear, "a custom message"].'
    },
    minYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets minYear validation on YearField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: minYear: [SomeYear, "a custom message"].'
    },
    openToDate: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "instanceOf",
          value: "Date"
        }]
      },
      required: !1,
      description: 'If this prop is set and no year is selected, year picker will be opened with year range containing this year.\nYear range has always length of 12 and is calculated exclusively based on this date using the following formula:\nendYear = ceil(year / 12) * 12, startYear = endYear - 11, where "year" is taken from this prop.\nFor instance if this prop\'s value is 2000, year range will be 1993 - 2004.'
    }
  },
  composes: ["../DateField/DateField"]
}, YearField.__docgenInfo = {
  componentName: "YearField",
  packageName: "@jutro/components",
  description: "",
  displayName: "YearField",
  methods: [],
  actualName: "YearField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Value to display in control"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }, {
          value: "'number'",
          computed: !1
        }]
      },
      required: !1,
      description: "Format of the value",
      defaultValue: {
        value: "'string'",
        computed: !1
      }
    },
    inputFormat: {
      type: {
        name: "custom",
        raw: "getValidationRuleShape(PropTypes.oneOf(['yyyy', 'yy']))"
      },
      required: !1,
      description: "Format of the year can be YYYY for four digits year or YY for two digits year",
      defaultValue: {
        value: "'yyyy'",
        computed: !1
      }
    },
    maxYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets maxYear validation on YearField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: maxYear: [SomeYear, "a custom message"].'
    },
    minYear: {
      type: {
        name: "custom",
        raw: "dateValidationRuleShape"
      },
      required: !1,
      description: 'Used by @jutro/validation package: Sets minYear validation on YearField.\nThis prop can also be used to set the custom message overwrite.\nThis can be done by using a tuple with the first value as true and the second value as the custom message. For example: minYear: [SomeYear, "a custom message"].'
    },
    openToDate: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "instanceOf",
          value: "Date"
        }]
      },
      required: !1,
      description: 'If this prop is set and no year is selected, year picker will be opened with year range containing this year.\nYear range has always length of 12 and is calculated exclusively based on this date using the following formula:\nendYear = ceil(year / 12) * 12, startYear = endYear - 11, where "year" is taken from this prop.\nFor instance if this prop\'s value is 2000, year range will be 1993 - 2004.'
    }
  },
  composes: ["../DateField/DateField"]
};