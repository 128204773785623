import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useState } from "react";
export var useControlled = function (_ref) {
  var controlledValue = _ref.value,
    defaultValue = _ref.defaultValue,
    _useState = useState(defaultValue),
    _useState2 = _slicedToArray(_useState, 2),
    valueInner = _useState2[0],
    setValueInner = _useState2[1];
  return [void 0 === controlledValue ? valueInner : controlledValue, function (newValue) {
    controlledValue || setValueInner(newValue);
  }];
};