import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import { useEffect, useMemo } from "react";
import { useTranslator } from "@jutro/locale";
import isPlainObject from "lodash/isPlainObject";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils.js";
import { countryMessages } from "./IntlPhoneNumberField.messages";
export var useInitializeIntlPhoneNumber = function (inputEl, defaultCountry, disabled, placeholder, noDropdown) {
  var translator = useTranslator(),
    localizedCountries = useMemo(function () {
      var localizedCountriesKeys = _Object$keys(countryMessages);
      return _reduceInstanceProperty(localizedCountriesKeys).call(localizedCountriesKeys, function (obj, key) {
        return _objectSpread(_objectSpread({}, obj), {}, _defineProperty({}, key, translator(countryMessages[key])));
      }, {});
    }, [countryMessages]);
  useEffect(function () {
    var input = inputEl.current;
    if (input) {
      var intlPhoneNumberEl = intlTelInput(input, {
        initialCountry: defaultCountry,
        preferredCountries: [defaultCountry],
        separateDialCode: !noDropdown,
        autoHideDialCode: !noDropdown,
        allowDropdown: !disabled,
        nationalMode: !1,
        formatOnDisplay: !0,
        localizedCountries: localizedCountries,
        autoPlaceholder: placeholder || "" === placeholder ? "off" : "aggressive"
      });
      if (intlPhoneNumberEl) {
        var dialCode = document.querySelector(".iti__selected-dial-code");
        null == dialCode || dialCode.setAttribute("role", "textbox"), null == dialCode || dialCode.setAttribute("aria-label", "selected country code"), null == dialCode || dialCode.setAttribute("title", "country code textbox");
      }
      var separator = document.createElement("div");
      return separator.style.width = "0", separator.style.height = "0", separator.style.position = "absolute", separator.innerText = " ", input.insertAdjacentElement("beforebegin", separator), function () {
        intlPhoneNumberEl.destroy(), separator.remove(), input.style.paddingLeft = null;
      };
    }
  }, [defaultCountry, disabled, inputEl, placeholder, noDropdown]);
};
export var useSetNumberOnBlur = function (value, intlPhoneNumberInstance, isFocused) {
  useEffect(function () {
    var _value$countryCode;
    intlPhoneNumberInstance() && (isFocused || (isPlainObject(value) ? (null !== (_value$countryCode = value.countryCode) && void 0 !== _value$countryCode && _value$countryCode.code && intlPhoneNumberInstance().setCountry(value.countryCode.code), null != value && value.phoneNumber ? intlPhoneNumberInstance().setNumber(value.phoneNumber) : intlPhoneNumberInstance().setNumber("")) : value ? intlPhoneNumberInstance().setNumber(value) : intlPhoneNumberInstance().setNumber("")));
  }, [intlPhoneNumberInstance, isFocused, value]);
};