import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["navLink"];
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import omit from "lodash/omit";
import get from "lodash/get";
export var removeNavLinkIcon = function (_ref) {
  var navLink = _ref.navLink,
    rest = _objectWithoutProperties(_ref, _excluded);
  return navLink ? _objectSpread(_objectSpread({}, rest), {}, {
    navLink: omit(navLink, "icon")
  }) : rest;
};
export var shouldShowIcons = function (routes) {
  var showOnNavBarRoutes = _filterInstanceProperty(routes).call(routes, function (route) {
    return get(route, "showOnNavBar", !0);
  });
  return showOnNavBarRoutes.every(function (_ref2) {
    return _ref2.navLink;
  }) && showOnNavBarRoutes.every(function (_ref3) {
    return _ref3.navLink.icon;
  });
};