import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import { warning } from "@jutro/logger";
var SAFE_PREFIXES = ["/", "http", "https", "mailto:", "tel:"],
  DANGEROUS_PROPS = ["href", "to", "action"];
export function isSafeUrl(url) {
  return !url || SAFE_PREFIXES.some(function (prefix) {
    return _startsWithInstanceProperty(url).call(url, prefix);
  });
}
export function sanitizeUrl(url) {
  var _context,
    allowNoLeadingSlash = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
  return url && "string" != typeof url || !isSafeUrl(url) && !allowNoLeadingSlash ? (warning(_concatInstanceProperty(_context = "'".concat(url, "' is not a safe url value. The only allowed urls are the ones starting with: ")).call(_context, SAFE_PREFIXES.join(", "))), "") : url;
}
export function sanitizeRouterTo(to) {
  return "string" == typeof to ? sanitizeUrl(to) : to ? _objectSpread(_objectSpread({}, to), {}, {
    pathname: to.pathname && sanitizeUrl(to.pathname)
  }) : to;
}
export function sanitizeProps(props) {
  return mapValues(props, sanitizeProp);
}
function sanitizeProp(value, key) {
  return _includesInstanceProperty(DANGEROUS_PROPS).call(DANGEROUS_PROPS, key) && "string" == typeof value ? sanitizeUrl(value) : value;
}
export function removeDangerousProps(props) {
  return omit(props, "dangerouslySetInnerHTML");
}