import { defineMessages } from "react-intl";
export var messages = defineMessages({
  editButtonLabel: {
    id: "jutro-components.SettingsCard.editButtonLabel",
    defaultMessage: "Edit"
  },
  cancelButtonLabel: {
    id: "jutro-components.SettingsCard.cancelButtonLabel",
    defaultMessage: "Cancel"
  },
  saveButtonLabel: {
    id: "jutro-components.SettingsCard.saveButtonLabel",
    defaultMessage: "Save"
  }
});