import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["header", "renderHeader"],
  _excluded2 = ["cell", "renderCell"];
import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import cx from "classnames";
import { metadataTypes } from "@jutro/uimetadata";
import { intlMessageShape } from "@jutro/prop-types";
import { warning } from "@jutro/logger";
import styles from "./TableColumn.module.css";
export var TableColumn = function () {
  return "development" === process.env.NODE_ENV && warning("Component <TableColumn /> should never render"), React.createElement(React.Fragment, null);
};
TableColumn.propTypes = {
  id: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.func, intlMessageShape]),
  renderHeader: PropTypes.func,
  headerClassName: PropTypes.string,
  headerAriaLabel: intlMessageShape,
  cell: PropTypes.oneOfType([PropTypes.func, intlMessageShape]),
  renderCell: PropTypes.func,
  cellClassName: PropTypes.string,
  visible: PropTypes.bool,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  width: PropTypes.number,
  columnProportion: PropTypes.number,
  sortable: PropTypes.bool,
  renderEditCell: PropTypes.func,
  editCellClass: PropTypes.string,
  columnClassName: PropTypes.string,
  onFilter: PropTypes.func,
  path: PropTypes.string,
  onSort: PropTypes.func
}, TableColumn.defaultProps = {
  columnProportion: 1
}, TableColumn.displayName = "TableColumn";
var textAlignMapping = {
  left: styles.textLeft,
  right: styles.textRight,
  center: styles.textCenter
};
TableColumn.renderHeader = function (props, translator) {
  var headerClassName = props.headerClassName,
    textAlign = props.textAlign,
    id = props.id,
    scope = props.scope;
  if (!1 === props.visible) return null;
  var content = function (props, translator) {
      var header = props.header,
        renderHeader = props.renderHeader,
        other = _objectWithoutProperties(props, _excluded),
        content = header || renderHeader;
      return isFunction(content) && (other.translator = translator, content = content(other)), translator && (content = translator(content)), content;
    }(props, translator),
    headerClasses = cx(headerClassName, getTextAlignClass(textAlign));
  return React.createElement("th", {
    key: id,
    className: headerClasses,
    scope: scope
  }, content);
}, TableColumn.renderCell = function (row, index, props, translator) {
  var cellClassName = props.cellClassName,
    textAlign = props.textAlign,
    id = props.id;
  if (!1 === props.visible) return null;
  var content = function (row, index, props, translator) {
      var cell = props.cell,
        renderCell = props.renderCell,
        other = _objectWithoutProperties(props, _excluded2),
        content = cell || renderCell;
      return isFunction(content) && (other.translator = translator, content = content(row, index, other)), translator && (content = translator(content)), content;
    }(row, index, props, translator),
    cellClasses = cx(styles.tableCell, getTextAlignClass(textAlign), cellClassName);
  return React.createElement("td", {
    key: id + index,
    className: cellClasses
  }, content);
};
var getTextAlignClass = function (textAlign) {
  return textAlignMapping[textAlign];
};
TableColumn.metadataType = metadataTypes.CONTAINER, TableColumn.__docgenInfo = {
  description: "TableColumn\n@returns {null} - The TableColumn component does not render anything\n\n@metadataType container",
  methods: [{
    name: "renderHeader",
    docblock: "Render the <th> cell translating the content or rendering the header provided by props\n@param {object} props - The set of props that will be used by TableColumn\n@param {Function} translator - The function used to translate strings\n@returns {React.ReactElement} - the header cell",
    modifiers: ["static"],
    params: [{
      name: "props",
      description: "The set of props that will be used by TableColumn",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "translator",
      description: "The function used to translate strings",
      type: {
        name: "Function"
      },
      optional: !1
    }],
    returns: {
      description: "the header cell",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the <th> cell translating the content or rendering the header provided by props"
  }, {
    name: "renderCell",
    docblock: "Render the <td> translating the content or rendering the cell provided by props\n@param {object} row - Each row in the data table\n@param {Number} index - The index of the current row\n@param {object} props - The set of props that will be used by TableColumn\n@param {Function} translator - The function used to translate strings\n@returns {React.ReactElement} -",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "index",
      description: "The index of the current row",
      type: {
        name: "Number"
      },
      optional: !1
    }, {
      name: "props",
      description: "The set of props that will be used by TableColumn",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "translator",
      description: "The function used to translate strings",
      type: {
        name: "Function"
      },
      optional: !1
    }],
    returns: {
      description: "-",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the <td> translating the content or rendering the cell provided by props"
  }],
  displayName: "TableColumn",
  props: {
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: "Proportion of the column. Doesn't work when `width` is defined."
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Identifier"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "String or callback(props) to render the column header in the table"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback(props) to render the column header in the table"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for header <th>"
    },
    headerAriaLabel: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "aria-label attribute of header"
    },
    cell: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "String or callback(row, index, props) to render the column cell for a row in the table"
    },
    renderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback(row, index, props) to render the column cell for a row in the table"
    },
    cellClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for cell (in order to use it in DataTable, specificity of the selector should be higher than 5 classnames)"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Is this column shown in the table?"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any TableColumn property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any TableColumn property for use at the 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any TableColumn property for use at the 'tablet', 'phoneWide', and 'phone' breakpoint"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "The value of the text-align css property (default 'center')"
    },
    width: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The width of the current column, when `undefined` columns will share the\ntable accordingly to each column's `columnProportion` prop"
    },
    sortable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "To specify if the column is sortable, when `undefined` the column is sortable"
    },
    renderEditCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback(row, index, props) to render the column cell for a row in the table in the edit mode"
    },
    editCellClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for a column cell in the edit mode"
    },
    columnClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the column"
    },
    onFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Column filter function"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of the view model, it also acts as an accessor for row data"
    },
    onSort: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be invoked on column sort click"
    }
  }
}, TableColumn.__docgenInfo = {
  componentName: "TableColumn",
  packageName: "@jutro/datatable",
  description: "TableColumn",
  displayName: "TableColumn",
  methods: [{
    name: "renderHeader",
    docblock: "Render the <th> cell translating the content or rendering the header provided by props\n@param {object} props - The set of props that will be used by TableColumn\n@param {Function} translator - The function used to translate strings\n@returns {React.ReactElement} - the header cell",
    modifiers: ["static"],
    params: [{
      name: "props",
      description: "The set of props that will be used by TableColumn",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "translator",
      description: "The function used to translate strings",
      type: {
        name: "Function"
      },
      optional: !1
    }],
    returns: {
      description: "the header cell",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the <th> cell translating the content or rendering the header provided by props"
  }, {
    name: "renderCell",
    docblock: "Render the <td> translating the content or rendering the cell provided by props\n@param {object} row - Each row in the data table\n@param {Number} index - The index of the current row\n@param {object} props - The set of props that will be used by TableColumn\n@param {Function} translator - The function used to translate strings\n@returns {React.ReactElement} -",
    modifiers: ["static"],
    params: [{
      name: "row",
      description: "Each row in the data table",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "index",
      description: "The index of the current row",
      type: {
        name: "Number"
      },
      optional: !1
    }, {
      name: "props",
      description: "The set of props that will be used by TableColumn",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "translator",
      description: "The function used to translate strings",
      type: {
        name: "Function"
      },
      optional: !1
    }],
    returns: {
      description: "-",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the <td> translating the content or rendering the cell provided by props"
  }],
  actualName: "TableColumn",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Identifier"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "String or callback(props) to render the column header in the table"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback(props) to render the column header in the table"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for header <th>"
    },
    headerAriaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "aria-label attribute of header"
    },
    cell: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "String or callback(row, index, props) to render the column cell for a row in the table"
    },
    renderCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback(row, index, props) to render the column cell for a row in the table"
    },
    cellClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class name for cell (in order to use it in DataTable, specificity of the selector should be higher than 5 classnames)"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Is this column shown in the table?"
    },
    phone: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any TableColumn property for use at the 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any TableColumn property for use at the 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: !1,
      description: "Include any TableColumn property for use at the 'tablet', 'phoneWide', and 'phone' breakpoint"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'center'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "The value of the text-align css property (default 'center')"
    },
    width: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The width of the current column, when `undefined` columns will share the\ntable accordingly to each column's `columnProportion` prop"
    },
    columnProportion: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Proportion of the column. Doesn't work when `width` is defined.",
      defaultValue: {
        value: "1",
        computed: !1
      }
    },
    sortable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "To specify if the column is sortable, when `undefined` the column is sortable"
    },
    renderEditCell: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback(row, index, props) to render the column cell for a row in the table in the edit mode"
    },
    editCellClass: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Custom class name for a column cell in the edit mode"
    },
    columnClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Class name for the column"
    },
    onFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Column filter function"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The full path of the view model, it also acts as an accessor for row data"
    },
    onSort: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to be invoked on column sort click"
    }
  }
};