import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import { getAccessToken, decodeJWTTokenPayload } from "@jutro/auth";
import { REQUEST_HANDLER_TYPE } from "../HttpRequestBuilder";
export var authTokenHandler = _defineProperty({}, REQUEST_HANDLER_TYPE.AUTH, _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
  var accessToken, _decodeJWTTokenPayloa, tenantId, tempTenantId, uid;
  return _regeneratorRuntime.wrap(function (_context) {
    for (;;) switch (_context.prev = _context.next) {
      case 0:
        return _context.next = 2, getAccessToken();
      case 2:
        return accessToken = _context.sent, _decodeJWTTokenPayloa = decodeJWTTokenPayload(accessToken), tenantId = _decodeJWTTokenPayloa.tenant_id, tempTenantId = _decodeJWTTokenPayloa.temp_tenant_id, uid = _decodeJWTTokenPayloa.uid, _context.abrupt("return", {
          headers: {
            Authorization: "Bearer ".concat(accessToken),
            "GW-Tenant": String("guidewire-hub" !== tenantId ? tenantId : tempTenantId),
            "GW-User": String(uid)
          }
        });
      case 5:
      case "end":
        return _context.stop();
    }
  }, _callee);
})));