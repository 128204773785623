import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["content"];
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context13;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context13 = Object.prototype.toString.call(o)).call(_context13, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import get from "lodash/get";
import omit from "lodash/omit";
import isPlainObject from "lodash/isPlainObject";
import mapValues from "lodash/mapValues";
import { log, makeUniqueWarningLog } from "@jutro/logger";
import { ResponsiveElement, IntlElement } from "@jutro/components";
import { isSupportedHTMLElement, isSupportedHTMLContainer, iterateMetadata, resolveContentReference } from "@jutro/uimetadata";
import { logDeprecationMessage, removeDangerousProps } from "@jutro/platform";
import { resolveComponentFromDatatype, resolveComponentFromName } from "./componentMap";
import { UnresolvedComponent } from "./UnresolvedComponent/UnresolvedComponent";
var callbackRegex = /^(on|render)[A-Z]/,
  classNameRegex = /^classNames?$|\w+ClassNames?$/;
export function resolveCallbackProps(props, callbackMap) {
  return resolvePropsWithMap(props, callbackMap, isValidCallbackProp, resolveCallback);
}
export function resolveClassNamesProps(props, classNameMap) {
  return resolvePropsWithMap(props, classNameMap, isValidClassNameProp, resolveClassNames);
}
export function resolveNestedPropClassNames(prop, callbackMap, classNameMap) {
  var _context,
    entries = _filterInstanceProperty(_context = _Object$entries(prop)).call(_context, function (_ref) {
      var val = _slicedToArray(_ref, 2)[1];
      return "object" === _typeof(val) && !!val.className;
    });
  if (!isEmpty(entries)) {
    var routeSpecificProps = _reduceInstanceProperty(entries).call(entries, function (props, _ref3) {
      var componentWithClassNameToResolve = _slicedToArray(_ref3, 1)[0];
      return _objectSpread(_objectSpread({}, props), function (props, classNameMap, routeComponent) {
        return props[routeComponent] ? _objectSpread(_objectSpread({}, props), {}, _defineProperty({}, routeComponent, resolveClassNamesProps(props[routeComponent], classNameMap))) : props;
      }(prop, classNameMap, componentWithClassNameToResolve));
    }, prop);
    return resolveProps(routeSpecificProps, callbackMap, classNameMap);
  }
  return resolveProps(prop, callbackMap, classNameMap);
}
export function resolveProps(props, callbackMap, classNameMap) {
  return resolveCallbackProps(resolveClassNamesProps(props, classNameMap), callbackMap);
}
var resolvePropsWithMap = function (props, map, matcher, resolver) {
  var _context2;
  return _reduceInstanceProperty(_context2 = _Object$keys(props)).call(_context2, function (result, key) {
    var originalValue = props[key],
      id = props.id,
      newValue = matcher(key, originalValue) ? resolver(id, originalValue, map) : originalValue;
    return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, key, newValue));
  }, {});
};
function isValidCallbackProp(callbackProp, callbackValue) {
  return !!callbackProp.match(callbackRegex) && !!callbackValue && (isString(callbackValue) || callbackValue instanceof Object && callbackValue.callback);
}
function isValidClassNameProp(classNameProp, classNameValue) {
  return !!classNameProp.match(classNameRegex) && !!classNameValue && (isString(classNameValue) || isPlainObject(classNameValue));
}
function mapLookup(map, key) {
  var defaultValue = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
  if (key) return map && map[key] || defaultValue;
}
export function resolveClassName(id, className, classNameMap) {
  return mapLookup(classNameMap, className, className);
}
export function resolveClassNames(id, classNames, classNameMap) {
  var resolver = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : resolveClassName;
  return isString(classNames) ? resolveStringClassName(id, classNames, classNameMap, resolver) : isPlainObject(classNames) ? mapValues(classNames, function (value) {
    return resolveStringClassName(id, value, classNameMap, resolver);
  }) : classNames;
}
function resolveStringClassName(id, classNames, classNameMap, resolver) {
  var oldNames = classNames.split(" ");
  return _mapInstanceProperty(oldNames).call(oldNames, function (className) {
    return resolver(id, className, classNameMap);
  }).join(" ");
}
export function resolveCallback(id, callbackName, callbackMap) {
  return mapLookup(callbackMap, callbackName);
}
var deprecationWarningForComponentCasing = makeUniqueWarningLog("Support for componentMaps with case-insensitive keys has been deprecated. Adjust the keys in your componentMap to match the name of the component");
export function resolveComponent(component, datatype, componentMap) {
  var componentInfo;
  if (component || datatype) return componentInfo || !component || isString(component) || (componentInfo = {
    component: component
  }), isString(component) && (!componentInfo && componentMap && (componentInfo = mapLookup(componentMap, component)), !componentInfo && componentMap && (componentInfo = mapLookup(componentMap, component.toLowerCase())) && deprecationWarningForComponentCasing(), componentInfo || (componentInfo = resolveComponentFromName(component)), componentInfo || (componentInfo = {
    component: component
  })), isString(datatype) && (!componentInfo && componentMap && (componentInfo = mapLookup(componentMap, datatype.toLowerCase())), componentInfo || (componentInfo = resolveComponentFromDatatype(datatype, !1)), componentInfo || log.warning('unable to resolve component by datatype: "'.concat(datatype, '"'))), componentInfo;
}
function getContentArray(contentMetadata) {
  return isArray(contentMetadata) ? contentMetadata : contentMetadata instanceof Object && contentMetadata.content && isArray(contentMetadata.content) ? contentMetadata.content : [];
}
export function prepareComponentFromMetadata(metadata, overrideProps) {
  var _context3,
    resolvers = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
    rootMetadata = arguments.length > 3 ? arguments[3] : void 0,
    metadataPath = arguments.length > 4 ? arguments[4] : void 0;
  if (!metadata) return null;
  if ("string" == typeof metadata) return {
    id: metadataPath,
    component: "React.Fragment",
    content: metadata,
    componentProps: {
      path: metadataPath
    }
  };
  if (React.isValidElement(metadata)) return metadata;
  var dataProps,
    baseDatatype,
    baseComponent,
    id = metadata.id,
    type = metadata.type,
    datatype = metadata.datatype,
    component = metadata.component,
    _metadata$componentPr = metadata.componentProps,
    componentProps = void 0 === _metadata$componentPr ? {} : _metadata$componentPr,
    content = metadata.content,
    contentAfter = metadata.contentAfter,
    contentBefore = metadata.contentBefore,
    contentEmpty = metadata.contentEmpty,
    contentRepeat = metadata.contentRepeat,
    contentRepeatAfter = metadata.contentRepeatAfter,
    contentRepeatBefore = metadata.contentRepeatBefore,
    contentLayout = metadata.contentLayout,
    selfLayout = metadata.selfLayout,
    _prepareOverrides = prepareOverrides(id, type, componentProps.path, overrideProps),
    contentOverride = _prepareOverrides.content,
    propOverrides = _objectWithoutProperties(_prepareOverrides, _excluded),
    additionalProps = propOverrides,
    fieldPath = additionalProps && additionalProps.path || componentProps && componentProps.path,
    hasDataProps = _includesInstanceProperty(_context3 = ["element", "field", "container", "iterable", "layout"]).call(_context3, type) && fieldPath;
  if (hasDataProps && resolvers.resolveDataProps) {
    var resolvedDataProps = resolvers.resolveDataProps(id, fieldPath);
    resolvedDataProps && (baseDatatype = resolvedDataProps.datatype, baseComponent = resolvedDataProps.component, dataProps = resolvedDataProps.componentProps);
  }
  var componentInfo = prepareComponentInfo(component || baseComponent, datatype || baseDatatype, type, content, _objectSpread(_objectSpread(_objectSpread({}, dataProps), componentProps), additionalProps), resolvers, metadataPath),
    Component = componentInfo && componentInfo.component,
    componentMapProps = componentInfo && componentInfo.componentProps;
  resolvers.resolveCallbackMap && (additionalProps = prepareCallbackOverrides(componentProps, additionalProps));
  var preparedChildrenLayout,
    combinedProps = removeDangerousProps(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, dataProps), componentMapProps), componentProps), additionalProps)),
    children = contentOverride || content,
    resolvedContent = contentOverride || resolveContent(metadata, resolvers);
  if ("iterable" === type && contentRepeat && !1 !== combinedProps.visible) {
    var repeatPath = contentRepeat.path || combinedProps.path,
      repeatData = contentRepeat.data || combinedProps.data || resolvers.resolveValue && resolvers.resolveValue(id, repeatPath);
    resolvedContent = iterateMetadata({
      contentAfter: contentAfter,
      contentRepeatAfter: contentRepeatAfter,
      contentBefore: contentBefore,
      contentRepeatBefore: contentRepeatBefore,
      contentEmpty: contentEmpty,
      contentRepeat: contentRepeat,
      repeatData: repeatData,
      repeatPath: repeatPath
    });
  }
  if (isArray(resolvedContent)) {
    var preparedContent = prepareContentFromMetadata({
      content: resolvedContent,
      contentLayout: contentLayout
    }, overrideProps, resolvers, rootMetadata, metadataPath);
    children = preparedContent.content, preparedChildrenLayout = preparedContent.contentLayout;
  }
  void 0 !== Component && prepareComponentProps(id, type, Component, combinedProps, resolvers);
  var preparedSelfLayout = selfLayout ? prepareLayout(selfLayout, resolvers) : void 0,
    result = {
      id: id,
      type: type,
      datatype: datatype,
      component: Component,
      componentProps: isEmpty(combinedProps) ? void 0 : combinedProps,
      content: children,
      contentLayout: preparedChildrenLayout,
      selfLayout: preparedSelfLayout
    };
  return omitBy(result, isUndefined);
}
function prepareLayout(layout, resolvers) {
  var componentInfo = prepareComponentInfo(layout.component, void 0, "layout", void 0, void 0, resolvers);
  return resolvers && prepareComponentProps(null, null, layout.component, layout.componentProps, resolvers), _objectSpread(_objectSpread({}, layout), {}, {
    componentProps: removeDangerousProps(layout.componentProps),
    component: componentInfo && componentInfo.component
  });
}
function prepareComponentInfo(component, datatype, type, content, componentProps, resolvers, metadataPath) {
  var transformComponentMap;
  if (isString(component) && "element" === type && isSupportedHTMLElement(component)) {
    var hasIntlContent = content && void 0 !== content.id && void 0 !== content.defaultMessage,
      _ref5 = componentProps || {},
      phone = _ref5.phone,
      phoneWide = _ref5.phoneWide,
      tablet = _ref5.tablet,
      hasBreakpointProps = phone || phoneWide || tablet;
    hasBreakpointProps && hasIntlContent ? transformComponentMap = _defineProperty({}, component, {
      component: ResponsiveElement,
      componentProps: {
        tag: component,
        parentTag: IntlElement
      }
    }) : hasIntlContent ? transformComponentMap = _defineProperty({}, component, {
      component: IntlElement,
      componentProps: {
        tag: component
      }
    }) : hasBreakpointProps && (transformComponentMap = _defineProperty({}, component, {
      component: ResponsiveElement,
      componentProps: {
        tag: component
      }
    }));
  }
  var componentResolver = resolvers && resolvers.resolveComponent || resolveComponent,
    componentMap = resolvers && resolvers.resolveComponentMap,
    componentInfo = componentResolver(component, datatype, transformComponentMap ? _objectSpread(_objectSpread({}, transformComponentMap), componentMap) : componentMap, type, metadataPath, componentProps);
  return componentInfo && !componentInfo.component ? {
    component: componentInfo
  } : componentInfo;
}
function prepareOverrides(id, type, path, overrideProps) {
  var additionalProps = {},
    resolveOverrides = function (key) {
      var overrides = overrideProps[key];
      return "function" == typeof overrides ? overrides(id, path) : overrides;
    };
  return overrideProps && overrideProps["@all"] && (additionalProps = _objectSpread(_objectSpread({}, additionalProps), resolveOverrides("@all"))), overrideProps && overrideProps["@".concat(type)] && (additionalProps = _objectSpread(_objectSpread({}, additionalProps), resolveOverrides("@".concat(type)))), overrideProps && overrideProps[id] && (additionalProps = _objectSpread(_objectSpread({}, additionalProps), resolveOverrides(id))), additionalProps;
}
function prepareCallbackOverrides(componentProps, additionalProps) {
  var resolvedProps = additionalProps;
  return componentProps && _Object$keys(componentProps).forEach(function (callbackProp) {
    var callbackValue = componentProps[callbackProp];
    isValidCallbackProp(callbackProp, callbackValue) && (resolvedProps[callbackProp] = callbackValue);
  }), resolvedProps;
}
function prepareComponentProps(id, type, component, props, resolvers) {
  var _context4,
    _context5,
    _componentProps$value,
    _context6,
    _context7,
    _componentProps$defau,
    componentProps = props,
    _ref6 = componentProps || {},
    path = _ref6.path,
    startPath = _ref6.startPath,
    endPath = _ref6.endPath,
    _ref7 = (null == component ? void 0 : component.defaultProps) || {},
    startPathValueProp = _ref7.startPathValueProp,
    endPathValueProp = _ref7.endPathValueProp;
  "element" !== type && "field" !== type || !resolvers.resolveValue || (path && (componentProps.value = resolvers.resolveValue(id, path)), startPath && endPath && (componentProps.value = (_defineProperty(_componentProps$value = {}, startPathValueProp, resolvers.resolveValue(_concatInstanceProperty(_context4 = "".concat(id, "_")).call(_context4, startPathValueProp), startPath)), _defineProperty(_componentProps$value, endPathValueProp, resolvers.resolveValue(_concatInstanceProperty(_context5 = "".concat(id, "_")).call(_context5, endPathValueProp), endPath)), _componentProps$value)));
  (("container" === type || "iterable" === type) && path && resolvers.resolveValue && (componentProps.data = resolvers.resolveValue(id, path)), "field" === type && resolvers.resolveDefaultValue) && (path && (componentProps.defaultValue = resolvers.resolveDefaultValue(id, path)), startPath && endPath && (componentProps.defaultValue = (_defineProperty(_componentProps$defau = {}, startPathValueProp, resolvers.resolveDefaultValue(_concatInstanceProperty(_context6 = "".concat(id, "_")).call(_context6, startPathValueProp), startPath)), _defineProperty(_componentProps$defau, endPathValueProp, resolvers.resolveDefaultValue(_concatInstanceProperty(_context7 = "".concat(id, "_")).call(_context7, endPathValueProp), endPath)), _componentProps$defau)));
  if (resolvers.resolveClassName || resolvers.resolveClassNameMap) {
    var classNameResolver = resolvers.resolveClassName || resolveClassName,
      classNameMap = resolvers.resolveClassNameMap;
    if (componentProps) {
      var _context8;
      _filterInstanceProperty(_context8 = _Object$keys(componentProps)).call(_context8, function (prop) {
        return isValidClassNameProp(prop, componentProps[prop]);
      }).forEach(function (classNameProp) {
        componentProps[classNameProp] = resolveClassNames(id, componentProps[classNameProp], classNameMap, classNameResolver);
      });
      var callbackMap = resolvers.resolveCallbackMap,
        routes = props.routes;
      routes && (componentProps.routes = _mapInstanceProperty(routes).call(routes, function (route) {
        return resolveNestedPropClassNames(route, callbackMap, classNameMap);
      }));
    }
  }
  prepareCallbacksInComponentProps({
    resolvers: resolvers,
    id: id,
    componentProps: componentProps
  }), "field" === type && resolvers.resolveValidation && (componentProps.validationMessages = resolvers.resolveValidation(id, path, componentProps && componentProps.value)), "field" === type && resolvers.resolveFormValidation && (componentProps.errorMessage = resolvers.resolveFormValidation(path, componentProps && componentProps.value, id));
}
export var prepareCallbacksInComponentProps = function (_ref8) {
  var resolvers = _ref8.resolvers,
    id = _ref8.id,
    componentProps = _ref8.componentProps;
  if (resolvers.resolveCallback || resolvers.resolveCallbackMap) {
    var _context9,
      callbackResolver = resolvers.resolveCallback || resolveCallback,
      callbackMap = resolvers.resolveCallbackMap;
    if (!isEmpty(componentProps)) return _filterInstanceProperty(_context9 = _Object$keys(componentProps)).call(_context9, function (callbackProp) {
      return isValidCallbackProp(callbackProp, componentProps[callbackProp]);
    }).forEach(function (callbackProp) {
      var callbackValue = componentProps[callbackProp],
        callbackFunction = callbackResolver(id, get(callbackValue, "callback", callbackValue), callbackMap);
      callbackMap && !callbackFunction && log.warning("unable to resolve callback: ".concat(_JSON$stringify(callbackValue, null, 2)));
      var wrappedFn = callbackValue.callback && callbackFunction ? function () {
        for (var _context10, _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return callbackFunction.apply(void 0, _concatInstanceProperty(_context10 = [_objectSpread({}, callbackValue.callbackProps)]).call(_context10, args));
      } : callbackFunction;
      componentProps[callbackProp] = wrappedFn;
    }), _objectSpread({}, componentProps);
  }
  return componentProps;
};
export function prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, rootMetadata) {
  var metadataPath = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : "",
    contentArray = getContentArray(contentMetadata),
    content = _mapInstanceProperty(contentArray).call(contentArray, function (item, i) {
      var _context11;
      return prepareComponentFromMetadata(resolveContentReference(item, rootMetadata), overrideProps, resolvers, rootMetadata, _concatInstanceProperty(_context11 = "".concat(metadataPath ? "".concat(metadataPath, ".") : metadataPath, "content.")).call(_context11, i));
    }),
    contentLayoutMetadata = contentMetadata && contentMetadata.contentLayout,
    contentLayout = contentLayoutMetadata ? prepareLayout(contentLayoutMetadata, resolvers) : void 0;
  return {
    content: content,
    contentLayout: contentLayout
  };
}
export function renderComponentFromMetadata(metadata) {
  var showHidden = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
  if (!metadata) return null;
  if (React.isValidElement(metadata)) return metadata;
  var children,
    renderOutput,
    id = metadata.id,
    Component = metadata.component,
    componentProps = metadata.componentProps,
    content = metadata.content,
    contentLayout = metadata.contentLayout,
    selfLayout = metadata.selfLayout,
    _ref9 = componentProps || {},
    visible = _ref9.visible,
    path = _ref9.path,
    key = id || path,
    refinedcomponentProps = componentProps;
  if (!showHidden && (!1 === visible || "false" === visible)) return null;
  showHidden || (refinedcomponentProps = omit(componentProps, ["visible"])), children = isArray(content) ? renderContent(content, contentLayout, showHidden) : content;
  var componentString = Component && Component.toString(),
    isIntentionalFragment = Component && (_includesInstanceProperty(componentString).call(componentString, "react.fragment") || _includesInstanceProperty(componentString).call(componentString, "React.Fragment"));
  if (isIntentionalFragment) renderOutput = React.createElement(React.Fragment, {
    key: id
  }, children);else if (isComponentUnresolved(Component)) renderOutput = React.createElement(UnresolvedComponent, {
    id: id,
    key: key,
    componentName: Component
  });else {
    var sanitizedProps = removeDangerousProps(refinedcomponentProps),
      props = _Object$assign(sanitizedProps, {
        resolvers: Component.isCodelessComponent ? sanitizedProps.resolvers : void 0
      });
    renderOutput = React.createElement(Component, _extends({
      id: key,
      key: key
    }, props), children);
  }
  return selfLayout ? renderLayout(renderOutput, _objectSpread(_objectSpread({}, selfLayout), {}, {
    componentProps: _objectSpread({
      key: key
    }, selfLayout.componentProps)
  })) : renderOutput;
}
export function renderContent(contentMetadata, contentLayout) {
  var showHidden = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
    content = _mapInstanceProperty(contentMetadata).call(contentMetadata, function (metadata) {
      return renderComponentFromMetadata(metadata, showHidden);
    });
  return isEmpty(contentLayout) ? content : renderLayout(content, contentLayout);
}
export function renderLayout(content, contentLayout) {
  var LayoutComponent = contentLayout.component,
    componentProps = contentLayout.componentProps;
  return LayoutComponent ? React.createElement(LayoutComponent, removeDangerousProps(componentProps), content) : content;
}
export function renderContentFromMetadataInternal(contentMetadata, overrideProps, resolvers) {
  var showHidden = arguments.length > 3 && void 0 !== arguments[3] && arguments[3],
    _prepareContentFromMe = prepareContentFromMetadata(contentMetadata, overrideProps, resolvers, contentMetadata),
    content = _prepareContentFromMe.content,
    _prepareContentFromMe2 = _prepareContentFromMe.contentLayout,
    contentLayout = void 0 === _prepareContentFromMe2 ? {} : _prepareContentFromMe2;
  return renderContent(content, contentLayout, showHidden);
}
export function validateMetadata() {
  logDeprecationMessage("validateMetadata", "", "validateMetadata functionality has been removed due to the security issues connected with Ajv.");
}
export function findComponentFromMetadata(metadata, filterFn) {
  var skipHidden = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2],
    list = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : [];
  if (!metadata) return list;
  var component = metadata.component,
    componentProps = metadata.componentProps,
    content = metadata.content,
    _ref10 = componentProps || {},
    value = _ref10.value,
    visible = _ref10.visible;
  if (skipHidden && !1 === visible) return list;
  if (filterFn && !filterFn(metadata) || list.push(metadata), isArray(content)) {
    if (skipHidden && component && component.isContentVisible) {
      var isContentVisible = component.isContentVisible(value, componentProps);
      if (!isContentVisible) return list;
    }
    findContentFromMetadata(content, filterFn, skipHidden, list);
  }
  return list;
}
export function findContentFromMetadata(contentMetadata, filterFn) {
  var skipHidden = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2],
    list = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : [],
    contentArray = getContentArray(contentMetadata);
  return _reduceInstanceProperty(contentArray).call(contentArray, function (foundArray, item) {
    return findComponentFromMetadata(item, filterFn, skipHidden, foundArray);
  }, list);
}
export function resolveContent(metadata) {
  var _context12,
    _ref11 = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
    contentResolver = _ref11.resolveContent,
    resolveContentOptions = _ref11.resolveContentOptions;
  if (!contentResolver && !resolveContentOptions) return metadata.content;
  var content = _reduceInstanceProperty(_context12 = resolveContentOptions || ["content"]).call(_context12, function (resolvedContent, path) {
    return resolvedContent || get(metadata, path);
  }, void 0);
  return contentResolver && contentResolver(content) || content;
}
export var isComponentUnresolved = function (Component) {
  return null == Component || isString(Component) && !(isSupportedHTMLElement(Component) || isSupportedHTMLContainer(Component));
};
export function findComponentPropsById(meta, id) {
  if ((null == meta ? void 0 : meta.id) === id) return (null == meta ? void 0 : meta.componentProps) || null;
  var content = null == meta ? void 0 : meta.content;
  if (Array.isArray(content)) {
    var _step,
      _iterator = _createForOfIteratorHelper(content);
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var foundItem = findComponentPropsById(_step.value, id);
        if (foundItem) return foundItem;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  return null;
}
renderComponentFromMetadata.__docgenInfo = {
  description: "Renders a single component from metadata\nInvoked with resolved metadata returned from 'prepareComponent()'\n\n@param {object|null} metadata - resolved metadata for a single component\n@param {boolean} [showHidden=false] - show hidden content in results\n@returns {React.Element|null} - React component instance",
  methods: [],
  displayName: "renderComponentFromMetadata"
}, renderContent.__docgenInfo = {
  description: "Renders an array of components from metadata\nInvoked with resolved metadata returned from 'prepareContent()'\n\n@param {Array<any>} contentMetadata - array of resolved metadata\n@param {object} contentLayout - layout to be applied to content\n@param {boolean} [showHidden=false] - show hidden content in results\n@returns {React.Element} - React component instance",
  methods: [],
  displayName: "renderContent"
}, renderLayout.__docgenInfo = {
  description: "Renders a layout around the provided content\nInvoked with resolved metadata returned from 'prepareContent()'\n\n@param {React.Element} content - React content to render in layout\n@param {object} contentLayout - layout to be applied to content\n@returns {React.Element} - React component instance",
  methods: [],
  displayName: "renderLayout"
}, renderContentFromMetadataInternal.__docgenInfo = {
  description: "Renders content metadata\n\n@param {object|Array} contentMetadata - content metadata or an array of metadata\n@param {object} [overrideProps] - override props by all, type or id\n@param {object} resolvers - a collection of resolve functions and maps\n@param {boolean} [showHidden=false] - show hidden content in results\n@returns {React.Element} React component instances",
  methods: [],
  displayName: "renderContentFromMetadataInternal"
}, renderComponentFromMetadata.__docgenInfo = {
  componentName: "renderComponentFromMetadata",
  packageName: "@jutro/uiconfig",
  description: "Renders a single component from metadata\nInvoked with resolved metadata returned from 'prepareComponent()'",
  displayName: "renderComponentFromMetadata",
  methods: [],
  actualName: "renderComponentFromMetadata"
}, renderContent.__docgenInfo = {
  componentName: "renderContent",
  packageName: "@jutro/uiconfig",
  description: "Renders an array of components from metadata\nInvoked with resolved metadata returned from 'prepareContent()'",
  displayName: "renderContent",
  methods: [],
  actualName: "renderContent"
}, renderLayout.__docgenInfo = {
  componentName: "renderLayout",
  packageName: "@jutro/uiconfig",
  description: "Renders a layout around the provided content\nInvoked with resolved metadata returned from 'prepareContent()'",
  displayName: "renderLayout",
  methods: [],
  actualName: "renderLayout"
}, renderContentFromMetadataInternal.__docgenInfo = {
  componentName: "renderContentFromMetadataInternal",
  packageName: "@jutro/uiconfig",
  description: "Renders content metadata",
  displayName: "renderContentFromMetadataInternal",
  methods: [],
  actualName: "renderContentFromMetadataInternal"
};