import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { createRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { validationMessageShape, deprecated } from "@jutro/prop-types";
import { getValueForInput } from "@jutro/data";
import isUndefined from "lodash/isUndefined";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import styles from "./InputField.module.css";
import { FieldIcon } from "../FieldComponent/FieldIcon";
import { getKeyPressHandler } from "../../../accessibility/getKeyPressHandler";
export var InputField = function (_FieldComponent) {
  _inherits(InputField, FieldComponent);
  var _super = _createSuper(InputField);
  function InputField() {
    var _context, _this;
    _classCallCheck(this, InputField);
    for (var _len = arguments.length, props = new Array(_len), _key = 0; _key < _len; _key++) props[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, props)), _defineProperty(_assertThisInitialized(_this), "handleClick", getKeyPressHandler(function () {
      var onEnter = _this.props.onEnter;
      onEnter && onEnter.apply(void 0, arguments);
    }, ["Enter"])), _this.inputRef = createRef(), _this;
  }
  return _createClass(InputField, [{
    key: "getValidationConfig",
    value: function () {
      return _get(_getPrototypeOf(InputField.prototype), "getValidationConfig", this).call(this, {
        propsList: ["pattern"]
      });
    }
  }, {
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(InputField.prototype), "render", this).call(this);
    }
  }, {
    key: "focus",
    value: function () {
      var _this$inputRef$curren, _this$inputRef$curren2;
      null === (_this$inputRef$curren = this.inputRef.current) || void 0 === _this$inputRef$curren || null === (_this$inputRef$curren2 = _this$inputRef$curren.focus) || void 0 === _this$inputRef$curren2 || _this$inputRef$curren2.call(_this$inputRef$curren);
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var value = breakpointProps.value,
        maxLength = breakpointProps.maxLength,
        disabled = breakpointProps.disabled,
        editable = breakpointProps.editable,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        inputType = breakpointProps.inputType,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        name = breakpointProps.name,
        focusHandlers = this.getInputFocusHandlers(),
        fieldUniqueId = this.fieldUniqueId,
        icon = breakpointProps.icon,
        iconPosition = breakpointProps.iconPosition,
        registerValidation = breakpointProps.registerValidation,
        validationMessages = this.getValidationMessages(value),
        messageStyle = this.getValidationMessageStyle(validationMessages),
        inputStyle = cx(styles.input, _defineProperty({
          disabled: disabled
        }, messageStyle, !options.isValid), !isUndefined(registerValidation) && !options.isValid && styles.labpreviewBorder, controlClassName),
        inputName = !name && id ? id : name,
        inputAutoComplete = name ? "on" : "off",
        accessibleDisabledProps = disabled && isAccessibleDisabled("accessibleDisabled.fields") ? _objectSpread(_objectSpread({}, muteCallbacks(_objectSpread(_objectSpread({}, focusHandlers), {}, {
          onChange: this.handleChange,
          onKeyPress: this.handleClick,
          onClick: this.handleClick
        }))), {}, {
          "aria-disabled": !0,
          "aria-readonly": !0,
          readOnly: !0,
          disabled: void 0
        }) : {};
      return React.createElement(FieldIcon, {
        icon: icon,
        iconPosition: iconPosition,
        disabled: disabled
      }, React.createElement("input", _extends({
        id: fieldUniqueId,
        ref: this.inputRef,
        type: inputType,
        className: inputStyle,
        maxLength: maxLength,
        value: getValueForInput(inputType, value),
        onChange: this.handleChange,
        onKeyPress: this.handleClick
      }, focusHandlers, {
        "aria-disabled": !editable,
        disabled: disabled,
        readOnly: !editable,
        "aria-readonly": !editable,
        required: required,
        autoComplete: inputAutoComplete,
        name: inputName
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), {
        "data-testid": testId || id
      }, accessibleDisabledProps)));
    }
  }]), InputField;
}();
_defineProperty(InputField, "propTypes", _objectSpread(_objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number
}, FieldIcon.propTypes), {}, {
  onEnter: PropTypes.func,
  editable: PropTypes.bool,
  name: PropTypes.string,
  pattern: deprecated(PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(["email", "alphanumeric", "lowercase", "uppercase", "url"]), PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(["email", "alphanumeric", "lowercase", "uppercase", "url"]), validationMessageShape]))]), void 0, "Please do not use pattern as it is used by @jutro/validation package that was deprecated")
})), _defineProperty(InputField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  editable: !0
})), _defineProperty(InputField, "contextType", FieldComponent.contextType), InputField.__docgenInfo = {
  description: "Renders an input element.\n@typedef {typeof InputField.propTypes} InputFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<InputFieldPropTypes>>\n@extends FieldIcon<PropTypes.InferProps<IconPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "focus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "InputField",
  props: {
    editable: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to false, the input field is read-only, but the user can tab\nto it, highlight it, and copy the text from it"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "String or number passed as value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Default value"
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Max length for the input field"
    },
    onEnter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to call when the Enter key is pressed"
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "This name will be passed to the input name attribute and switched on\nautocomplete, if this prop is not set, the name attribute is set to id by\ndefault and autocomplete is off"
    },
    pattern: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.oneOfType([\n        PropTypes.string,\n        PropTypes.oneOf([\n            'email',\n            'alphanumeric',\n            'lowercase',\n            'uppercase',\n            'url',\n        ]),\n        PropTypes.arrayOf(\n            PropTypes.oneOfType([\n                PropTypes.string,\n                PropTypes.oneOf([\n                    'email',\n                    'alphanumeric',\n                    'lowercase',\n                    'uppercase',\n                    'url',\n                ]),\n                validationMessageShape,\n            ])\n        ),\n    ]),\n    undefined,\n    'Please do not use pattern as it is used by @jutro/validation package that was deprecated'\n)"
      },
      required: !1,
      description: "A pattern that must be followed for validation purposes. (This can only be used with `@jutro/validation`).\nUse some of the available presets or define your own regex pattern to test against."
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
}, InputField.__docgenInfo = {
  componentName: "InputField",
  packageName: "@jutro/components",
  description: "Renders an input element.",
  displayName: "InputField",
  methods: [{
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "focus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "InputField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "String or number passed as value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: !1,
      description: "Default value"
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Max length for the input field"
    },
    onEnter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback to call when the Enter key is pressed"
    },
    editable: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If set to false, the input field is read-only, but the user can tab\nto it, highlight it, and copy the text from it",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    name: {
      type: {
        name: "string"
      },
      required: !1,
      description: "This name will be passed to the input name attribute and switched on\nautocomplete, if this prop is not set, the name attribute is set to id by\ndefault and autocomplete is off"
    },
    pattern: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "enum",
          value: [{
            value: "'email'",
            computed: !1
          }, {
            value: "'alphanumeric'",
            computed: !1
          }, {
            value: "'lowercase'",
            computed: !1
          }, {
            value: "'uppercase'",
            computed: !1
          }, {
            value: "'url'",
            computed: !1
          }]
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "enum",
              value: [{
                value: "'email'",
                computed: !1
              }, {
                value: "'alphanumeric'",
                computed: !1
              }, {
                value: "'lowercase'",
                computed: !1
              }, {
                value: "'uppercase'",
                computed: !1
              }, {
                value: "'url'",
                computed: !1
              }]
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "@deprecated A pattern that must be followed for validation purposes. (This can only be used with `@jutro/validation`).\nUse some of the available presets or define your own regex pattern to test against.",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
};