import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import isEqual from "lodash/isEqual";
export var getInitiallyOpenedAccordions = function (accordionStates, defaultOpenedId) {
  return (null == accordionStates ? void 0 : accordionStates.length) ? accordionStates : defaultOpenedId ? [defaultOpenedId] : [];
};
export var computeOpenedAccordions = function () {
  var _context,
    previouslyOpenedAccordions = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
    accordionId = arguments.length > 1 ? arguments[1] : void 0,
    closeOthers = arguments.length > 2 ? arguments[2] : void 0,
    shouldExpand = !_includesInstanceProperty(previouslyOpenedAccordions).call(previouslyOpenedAccordions, accordionId);
  return closeOthers ? shouldExpand ? [accordionId] : [] : shouldExpand ? _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(previouslyOpenedAccordions), [accordionId]) : _filterInstanceProperty(previouslyOpenedAccordions).call(previouslyOpenedAccordions, function (current) {
    return !isEqual(current, accordionId);
  });
};