import { useEffect } from "react";
import { tsmLanguagesLoader } from "./tsm/tsmLanguagesLoader";
import { isTSMEnabled } from "./tsm/tsmHelpers";
export var LanguagesLoader = function (_ref) {
  var onLoad = _ref.onLoad;
  return useEffect(function () {
    if (isTSMEnabled()) {
      var hasAppUnmounted = !1;
      return tsmLanguagesLoader().then(function () {
        return !hasAppUnmounted && onLoad();
      }), function () {
        hasAppUnmounted = !0;
      };
    }
    onLoad();
  }, [onLoad]), null;
};
LanguagesLoader.displayName = "LanguagesLoader";