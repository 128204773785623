import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import { useAuth } from "@jutro/auth";
import { ErrorNotice } from "@jutro/components";
import { messages } from "./AuthErrorPage.messages";
var managedErrors = {
  access_denied: {
    name: messages.accessDenied,
    message: messages.accessDeniedMessage
  },
  missing_auth: {
    name: messages.authErrorTitle,
    message: messages.authErrorMessage
  }
};
export var AuthErrorPage = function (_ref) {
  var _useMessageDetails = function (error) {
      var auth = useAuth(),
        _error$name = error.name,
        name = void 0 === _error$name ? messages.genericError : _error$name,
        errorCode = error.errorCode,
        _error$message = error.message,
        message = void 0 === _error$message ? error.toString() : _error$message,
        _error$actions = error.actions,
        actions = void 0 === _error$actions ? auth ? [{
          label: messages.logout,
          callback: auth.logout
        }] : [] : _error$actions;
      return errorCode in managedErrors ? _objectSpread(_objectSpread({}, managedErrors[errorCode]), {}, {
        actions: actions
      }) : {
        name: name,
        message: message,
        actions: actions
      };
    }(_ref.error),
    name = _useMessageDetails.name,
    message = _useMessageDetails.message,
    actions = _useMessageDetails.actions;
  return React.createElement(ErrorNotice, {
    noticeStyle: "fullHeight",
    mainMessage: name,
    detailedMessage: message,
    actions: actions
  });
};
AuthErrorPage.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "AuthErrorPage"
}, AuthErrorPage.__docgenInfo = {
  componentName: "AuthErrorPage",
  packageName: "@jutro/app",
  description: "",
  displayName: "AuthErrorPage",
  methods: [],
  actualName: "AuthErrorPage"
};