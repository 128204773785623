import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
export default function (isValidationIgnored) {
  return useMemo(function () {
    return function (arg0) {
      return function (_ref) {
        var data = _ref.data,
          parentPath = _ref.parentPath,
          onValidationChange = _ref.onValidationChange,
          isValidationIgnored = _ref.isValidationIgnored,
          fieldValidationMessagesRef = useRef(null),
          fieldValidationMessages = fieldValidationMessagesRef.current,
          _useState = useState(fieldValidationMessages),
          _useState2 = _slicedToArray(_useState, 2),
          pageMessages = _useState2[0],
          setPageMessages = _useState2[1],
          _useState3 = useState(!1),
          _useState4 = _slicedToArray(_useState3, 2),
          hasDoneInitialValidation = _useState4[0],
          setHasDoneInitialValidation = _useState4[1],
          onFieldValidationChanged = useCallback(function (isValid, path, message) {
            var _context,
              fullPath = parentPath ? _concatInstanceProperty(_context = "".concat(parentPath, ".")).call(_context, path) : path,
              messages = _objectSpread({}, fieldValidationMessagesRef.current);
            isValid ? delete messages[fullPath] : messages[fullPath] = [message], setPageMessages(_objectSpread({}, messages)), fieldValidationMessagesRef.current = _objectSpread({}, messages), hasDoneInitialValidation || setHasDoneInitialValidation(!0);
          }, [fieldValidationMessagesRef, hasDoneInitialValidation]);
        return useEffect(function () {
          if (!isValidationIgnored) {
            var areFieldsValid = isEmpty(fieldValidationMessages);
            onValidationChange && onValidationChange(areFieldsValid, fieldValidationMessages);
          }
        }, [data, onValidationChange, fieldValidationMessages, pageMessages, isValidationIgnored]), isValidationIgnored ? null : onFieldValidationChanged;
      }(_objectSpread(_objectSpread({}, arg0), {}, {
        isValidationIgnored: isValidationIgnored
      }));
    };
  }, [isValidationIgnored]);
}