import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _objectDestructuringEmpty from "@babel/runtime-corejs3/helpers/objectDestructuringEmpty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
var _excluded = ["id", "nodes", "renderNodes"];
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { Fragment } from "react";
import { TreeNode } from "./TreeNode";
export var defaultRenderNodes = function (_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
  return React.createElement(TreeViewNodes, props);
};
var TreeViewNodes = function (_ref2) {
  var nodes = _ref2.nodes;
  return React.createElement(Fragment, null, _mapInstanceProperty(nodes).call(nodes, function (_ref3) {
    var id = _ref3.id,
      childNodes = _ref3.nodes,
      _ref3$renderNodes = _ref3.renderNodes,
      RenderNodes = void 0 === _ref3$renderNodes ? defaultRenderNodes : _ref3$renderNodes,
      node = _objectWithoutProperties(_ref3, _excluded);
    return React.createElement(TreeNode, _extends({
      key: id,
      id: id
    }, node), childNodes && React.createElement(RenderNodes, {
      nodeId: id,
      nodes: childNodes
    }));
  }));
};
defaultRenderNodes.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "defaultRenderNodes"
}, defaultRenderNodes.__docgenInfo = {
  componentName: "defaultRenderNodes",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultRenderNodes",
  methods: [],
  actualName: "defaultRenderNodes"
}, TreeViewNodes.__docgenInfo = {
  componentName: "TreeViewNodes",
  packageName: "@jutro/components",
  description: "",
  displayName: "TreeViewNodes",
  methods: [],
  actualName: "TreeViewNodes"
};