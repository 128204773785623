import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React from "react";
import PropTypes from "prop-types";
import { useTranslator } from "@jutro/locale";
import { messages } from "./Pagination.messages";
import styles from "./Pagination.module.css";
import { PaginationSelect } from "./PaginationSelect";
import { SHOW_ALL_PAGES } from "./utils";
export var PageSizes = function (_ref) {
  var _context,
    id = _ref.id,
    numberOfRows = _ref.numberOfRows,
    onSelectPageSizeChange = _ref.onSelectPageSizeChange,
    pageSize = _ref.pageSize,
    pageSizeOptions = _ref.pageSizeOptions,
    pageSizeSelectedOption = _ref.pageSizeSelectedOption,
    translator = useTranslator(),
    options = _mapInstanceProperty(_context = _filterInstanceProperty(pageSizeOptions).call(pageSizeOptions, function (option) {
      return option < numberOfRows;
    })).call(_context, function (option) {
      return {
        code: option,
        name: option
      };
    }),
    transformedPageSizeOptions = _concatInstanceProperty(options).call(options, {
      code: SHOW_ALL_PAGES,
      name: translator(messages.showAll)
    });
  return !(numberOfRows < pageSize) && React.createElement("div", {
    className: styles.pageSizeSelectWrapper
  }, React.createElement(PaginationSelect, {
    id: "".concat(id, "_pageSizeSelect"),
    onValueChange: onSelectPageSizeChange,
    value: pageSizeSelectedOption,
    availableValues: transformedPageSizeOptions
  }), React.createElement("div", {
    className: styles.resultOfMessage
  }, React.createElement("span", null, translator(messages.resultOf), " "), numberOfRows));
};
PageSizes.propTypes = {
  id: PropTypes.string.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeSelectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelectPageSizeChange: PropTypes.func
}, PageSizes.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "PageSizes",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    pageSizeOptions: {
      type: {
        name: "array"
      },
      required: !0,
      description: "List of page size options"
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: !0,
      description: "The total number of rows"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Number of items per single page"
    },
    pageSizeSelectedOption: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Number of records per single page"
    },
    onSelectPageSizeChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle page size change"
    }
  }
}, PageSizes.__docgenInfo = {
  componentName: "PageSizes",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "PageSizes",
  methods: [],
  actualName: "PageSizes",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Component unique identifier"
    },
    pageSizeOptions: {
      type: {
        name: "array"
      },
      required: !0,
      description: "List of page size options"
    },
    numberOfRows: {
      type: {
        name: "number"
      },
      required: !0,
      description: "The total number of rows"
    },
    pageSize: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Number of items per single page"
    },
    pageSizeSelectedOption: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "Number of records per single page"
    },
    onSelectPageSizeChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle page size change"
    }
  }
};