import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { getMessageProp } from "@jutro/platform";
import buttonStyles from "../../../button/Button.module.css";
import { isAccessibleDisabled } from "../../../../accessibleDisabled";
import { Button } from "../../../button/Button";
import { messages } from "../FileUploadField.messages";
export var FileUploadActionButton = function (_ref) {
  var size = _ref.size,
    type = _ref.type,
    disabled = _ref.disabled,
    onClick = _ref.onClick,
    _ref$icon = _ref.icon,
    icon = void 0 === _ref$icon ? "gw-cloud-upload" : _ref$icon,
    messageProps = _ref.messageProps,
    uploadFilesMessage = getMessageProp("uploadFilesMessage", messageProps, messages),
    accessibleDisabledButtonProps = disabled && !isAccessibleDisabled("accessibleDisabled.button") && isAccessibleDisabled("accessibleDisabled.fields") ? {
      disabled: void 0,
      "aria-disabled": !0,
      onClick: void 0,
      actionType: "button",
      className: buttonStyles.disabled
    } : {};
  return React.createElement(Button, _extends({
    icon: icon,
    type: type,
    size: size,
    onClick: onClick,
    disabled: disabled
  }, accessibleDisabledButtonProps), uploadFilesMessage);
};
FileUploadActionButton.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "FileUploadActionButton",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-cloud-upload'",
        computed: !1
      },
      required: !1
    }
  }
}, FileUploadActionButton.__docgenInfo = {
  componentName: "FileUploadActionButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "FileUploadActionButton",
  methods: [],
  actualName: "FileUploadActionButton",
  props: {
    icon: {
      defaultValue: {
        value: "'gw-cloud-upload'",
        computed: !1
      },
      required: !1
    }
  }
};