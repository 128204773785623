import { defineMessages } from "react-intl";
export var messages = defineMessages({
  requiredField: {
    id: "jutro-components.widgets.inputs.This is a required field",
    defaultMessage: "This is a required field"
  },
  optional: {
    id: "jutro-components.widgets.inputs.Optional",
    defaultMessage: "(Optional)"
  },
  multipleErrors: {
    id: "jutro-components.widgets.inputs.Number of errors for this field",
    defaultMessage: "Number of errors for this field: {value}"
  },
  multipleErrorsWithLabel: {
    id: "jutro-components.widgets.inputs.with label.Number of errors for the field",
    defaultMessage: 'Number of errors for the field "{label}": {value}'
  },
  multipleWarnings: {
    id: "jutro-components.widgets.inputs.Number of warnings for this field",
    defaultMessage: "Number of warnings for this field: {value}"
  },
  multipleWarningsWithLabel: {
    id: "jutro-components.widgets.inputs.with label.Number of warnings for the field",
    defaultMessage: 'Number of warnings for the field "{label}": {value}'
  }
});