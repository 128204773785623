import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import { useState, useCallback, useRef } from "react";
export var useModalPromise = function () {
  var _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isOpened = _useState2[0],
    setOpened = _useState2[1],
    promise = useRef({}),
    show = useCallback(_asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
      return _regeneratorRuntime.wrap(function (_context) {
        for (;;) switch (_context.prev = _context.next) {
          case 0:
            return setOpened(!0), _context.prev = 1, _context.next = 4, new _Promise(function (resolve, reject) {
              _Object$assign(promise.current, {
                resolve: resolve,
                reject: reject
              });
            });
          case 4:
            return _context.abrupt("return", _context.sent);
          case 5:
            return _context.prev = 5, setOpened(!1), _context.finish(5);
          case 8:
          case "end":
            return _context.stop();
        }
      }, _callee, null, [[1,, 5, 8]]);
    })), []);
  return [isOpened, show, useCallback(function (result) {
    return promise.current.resolve(result);
  }, [promise]), useCallback(function (error) {
    return promise.current.reject(error);
  }, [promise])];
};