import React from "react";
import { Portal } from "react-overlays";
import styles from "./GenericDatePicker.module.css";
export var PopperContainer = function (_ref) {
  var children = _ref.children,
    labelId = _ref.labelId,
    calendarId = _ref.calendarId;
  return React.createElement(Portal, null, React.createElement("div", {
    id: calendarId,
    role: "dialog",
    "aria-hidden": !children,
    "aria-labelledby": labelId,
    className: styles.datePicker
  }, children));
};
PopperContainer.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "PopperContainer"
}, PopperContainer.__docgenInfo = {
  componentName: "PopperContainer",
  packageName: "@jutro/components",
  description: "",
  displayName: "PopperContainer",
  methods: [],
  actualName: "PopperContainer"
};