import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useMemo, useReducer } from "react";
import { bindActionCreators } from "../helper";
import { reducer } from "./reducer";
import * as actions from "./actions";
export var useAccessibility = function (initialState) {
  var _useReducer = useReducer(reducer, initialState),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    state = _useReducer2[0],
    dispatch = _useReducer2[1];
  return [state, useMemo(function () {
    return bindActionCreators(dispatch, actions);
  }, [])];
};