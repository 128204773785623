import { defineMessages } from "react-intl";
export var messages = defineMessages({
  googleMaps: {
    id: "jutro-components.widgets.MapArea.GoogleMaps",
    defaultMessage: "Google Maps"
  },
  mapMarker: {
    id: "jutro-components.widgets.MapArea.MapMarker",
    defaultMessage: "Map marker"
  }
});