import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React from "react";
import isArray from "lodash/isArray";
import { logDeprecationMessage } from "./logDeprecationMessage";
export function deprecateComponent(Component, deprecationLogMessages) {
  var deprecated = deprecationLogMessages.deprecated,
    alternative = deprecationLogMessages.alternative,
    extraMessage = deprecationLogMessages.extraMessage,
    dropTargetVersion = deprecationLogMessages.dropTargetVersion,
    componentName = Component.displayName || "Component",
    DeprecatedComponent = function (props) {
      var _context;
      return (!props.noDeprecationWarnings || isArray(props.noDeprecationWarnings) && !_includesInstanceProperty(_context = props.noDeprecationWarnings).call(_context, componentName)) && logDeprecationMessage(deprecated, alternative, extraMessage, dropTargetVersion), React.createElement(Component, props);
    };
  return DeprecatedComponent.displayName = componentName, DeprecatedComponent.propTypes = Component.propTypes, DeprecatedComponent.defaultProps = Component.defaultProps, DeprecatedComponent;
}