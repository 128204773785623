import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { warning } from "@jutro/logger";
import { ErrorBoundary, Loader, ModalNextEmitter } from "@jutro/components";
import { getConfigValue } from "@jutro/config";
import { AuthErrorPage } from "./routing/AuthErrorPage";
import { RootRoute } from "./routing/RootRoute";
import { RoutingContent } from "./routing/RoutingContent";
import { MessagesContextProvider } from "./internationalization/MessagesContext";
import { GlobalizedContent, AuthenticatedGlobalizedContent } from "./internationalization/GlobalizedContent";
import { ApplicationContent } from "./ApplicationContent";
import { applyHOCs } from "./ApplicationRootHOCs";
export var ApplicationRoot = function (_Component) {
  _inherits(ApplicationRoot, Component);
  var _super = _createSuper(ApplicationRoot);
  function ApplicationRoot(props) {
    var _this;
    return _classCallCheck(this, ApplicationRoot), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "authEnabled", void 0), _defineProperty(_assertThisInitialized(_this), "renderBody", function (_ref) {
      var _ref$isAuthenticated = _ref.isAuthenticated,
        isAuthenticated = void 0 === _ref$isAuthenticated || _ref$isAuthenticated,
        _ref2 = _this.props,
        mainComponent = _ref2.main,
        mainComponentProps = _ref2.mainComponentProps,
        disableModalProvider = _ref2.disableModalProvider,
        modalEmitter = _ref2.modalEmitter,
        modalFunctions = _ref2.modalFunctions,
        disableToastProvider = _ref2.disableToastProvider,
        toastTrigger = _ref2.toastTrigger,
        loaderComponent = _ref2.loaderComponent,
        hideSkipNavigation = _ref2.hideSkipNavigation,
        appName = _ref2.appName;
      return React.createElement(React.Fragment, null, isAuthenticated && React.createElement(AuthenticatedGlobalizedContent, null), React.createElement(ApplicationContent, {
        mainComponent: mainComponent,
        mainComponentProps: mainComponentProps,
        disableToastProvider: disableToastProvider,
        toastTrigger: toastTrigger,
        disableModalProvider: disableModalProvider,
        modalEmitter: modalEmitter,
        modalFunctions: modalFunctions,
        loaderComponent: loaderComponent,
        isAuthenticated: isAuthenticated,
        hideSkipNavigation: hideSkipNavigation,
        appName: appName
      }));
    }), _this.authEnabled = getConfigValue("JUTRO_AUTH_ENABLED", !1), _this;
  }
  return _createClass(ApplicationRoot, [{
    key: "render",
    value: function () {
      var _ref3 = this.props,
        authErrorComponent = _ref3.authErrorComponent,
        messageLoader = _ref3.messageLoader,
        coreMessageLoader = _ref3.coreMessageLoader,
        appName = _ref3.appName,
        intlTextComponent = _ref3.intlTextComponent,
        disableAuthForApplicationRoot = _ref3.disableAuthForApplicationRoot,
        globalizationSettings = _ref3.globalizationSettings,
        saveDefaultLanguageAndLocaleToLocalStorage = _ref3.saveDefaultLanguageAndLocaleToLocalStorage,
        refreshOnLanguageOrLocaleChange = _ref3.refreshOnLanguageOrLocaleChange,
        routerComponent = _ref3.Router,
        routerHistory = _ref3.routerHistory,
        routerBasename = _ref3.routerBasename,
        trackingCallback = _ref3.trackingCallback,
        modalEmitter = _ref3.modalEmitter,
        ErrorBoundaryComponent = _ref3.errorBoundary,
        authComponent = _ref3.authComponent,
        rootHOCs = _ref3.rootHOCs,
        authEnabled = Boolean(this.authEnabled && !disableAuthForApplicationRoot);
      return React.createElement(ErrorBoundaryComponent, {
        noticeStyle: "fullHeight"
      }, applyHOCs(React.createElement(RoutingContent, {
        routerComponent: routerComponent,
        routerBasename: routerBasename,
        routerHistory: routerHistory,
        trackingCallback: trackingCallback,
        modalEmitter: modalEmitter
      }, React.createElement(MessagesContextProvider, null, React.createElement(GlobalizedContent, {
        globalizationSettings: globalizationSettings,
        appName: appName,
        messageLoader: messageLoader,
        coreMessageLoader: coreMessageLoader,
        intlTextComponent: intlTextComponent,
        persistToURL: refreshOnLanguageOrLocaleChange,
        persistToLocalStorage: saveDefaultLanguageAndLocaleToLocalStorage
      }, React.createElement(RootRoute, {
        path: "/",
        render: this.renderBody,
        authEnabled: authEnabled,
        authErrorComponent: authErrorComponent,
        authComponent: authComponent
      })))), rootHOCs));
    }
  }]), ApplicationRoot;
}();
_defineProperty(ApplicationRoot, "propTypes", {
  messageLoader: PropTypes.func,
  coreMessageLoader: PropTypes.func,
  intlTextComponent: PropTypes.string,
  errorBoundary: PropTypes.elementType,
  loaderComponent: PropTypes.elementType,
  authComponent: PropTypes.elementType,
  main: PropTypes.func.isRequired,
  mainComponentProps: PropTypes.object,
  trackingCallback: PropTypes.func,
  Router: PropTypes.oneOf([BrowserRouter, MemoryRouter]),
  routerHistory: PropTypes.object,
  routerBasename: PropTypes.string,
  rootHOCs: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  refreshOnLanguageOrLocaleChange: PropTypes.bool,
  saveDefaultLanguageAndLocaleToLocalStorage: PropTypes.bool,
  authErrorComponent: PropTypes.func,
  modalEmitter: PropTypes.instanceOf(ModalNextEmitter),
  globalizationSettings: PropTypes.shape({
    getAvailableLanguages: PropTypes.func.isRequired,
    getAvailableLocales: PropTypes.func.isRequired,
    getDefaultCountryCode: PropTypes.func.isRequired,
    getDefaultCurrency: PropTypes.func.isRequired,
    getDefaultTimeZone: PropTypes.func.isRequired,
    getPreferredLanguage: PropTypes.func.isRequired,
    getPreferredLocale: PropTypes.func.isRequired,
    onGlobalizationChange: PropTypes.func
  })
}), _defineProperty(ApplicationRoot, "defaultProps", {
  messageLoader: function (locale) {
    return warning("No application specific message resources loaded for locale: ".concat(locale)), {};
  },
  coreMessageLoader: function (locale) {
    return warning("No core message resources loaded for locale: ".concat(locale)), {};
  },
  errorBoundary: ErrorBoundary,
  loaderComponent: Loader,
  routerBasename: getConfigValue("JUTRO_ROUTER_BASENAME", ""),
  refreshOnLanguageOrLocaleChange: !1,
  saveDefaultLanguageAndLocaleToLocalStorage: !0,
  authErrorComponent: AuthErrorPage,
  modalEmitter: new ModalNextEmitter()
}), ApplicationRoot.__docgenInfo = {
  description: "A root-level component that takes care of application-level\nconcerns for you:\n-- internationalization\n-- routing\n-- error handling (uncaught)\nIf the application requires a custom HOC, rootHOCs render prop\ncan be used, e.g. <ApplicationRoot rootHOCs={composeRedux(store)} />.\nArray of HOCs are applied in order starting with first to last and then\nErrorBoundary is added.\n\n@extends Component<{}>\n@param messages",
  methods: [{
    name: "renderBody",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{\n    isAuthenticated = true,\n}",
      type: null
    }],
    returns: {
      type: {
        name: "ReactNode"
      }
    }
  }],
  displayName: "ApplicationRoot",
  props: {
    messageLoader: {
      defaultValue: {
        value: "(locale: string): Record<string, string> => {\n    warning(\n        `No application specific message resources loaded for locale: ${locale}`\n    );\n\n    return {};\n}",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    coreMessageLoader: {
      defaultValue: {
        value: "(locale: string): Record<string, string> => {\n    warning(`No core message resources loaded for locale: ${locale}`);\n\n    return {};\n}",
        computed: !1
      },
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    errorBoundary: {
      defaultValue: {
        value: "ErrorBoundary",
        computed: !0
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "",
      tsType: {
        name: "ReactComponentClass",
        raw: "React.ComponentClass"
      }
    },
    loaderComponent: {
      defaultValue: {
        value: "Loader",
        computed: !0
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: ""
    },
    routerBasename: {
      defaultValue: {
        value: "getConfigValue('JUTRO_ROUTER_BASENAME', '')",
        computed: !0
      },
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    refreshOnLanguageOrLocaleChange: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    },
    saveDefaultLanguageAndLocaleToLocalStorage: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: ""
    },
    authErrorComponent: {
      defaultValue: {
        value: "AuthErrorPage",
        computed: !0
      },
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    modalEmitter: {
      defaultValue: {
        value: "new ModalNextEmitter()",
        computed: !1
      },
      type: {
        name: "instanceOf",
        value: "ModalNextEmitter"
      },
      required: !1,
      description: ""
    },
    intlTextComponent: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    authComponent: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: ""
    },
    main: {
      type: {
        name: "func"
      },
      required: !0,
      description: "",
      tsType: {
        name: "ApplicationContentProps['mainComponent']",
        raw: "ApplicationContentProps['mainComponent']"
      }
    },
    mainComponentProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    trackingCallback: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    Router: {
      type: {
        name: "enum",
        value: [{
          value: "import { BrowserRouter, MemoryRouter } from 'react-router-dom';",
          computed: !0
        }, {
          value: "import { BrowserRouter, MemoryRouter } from 'react-router-dom';",
          computed: !0
        }]
      },
      required: !1,
      description: ""
    },
    routerHistory: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    rootHOCs: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "arrayOf",
          value: {
            name: "func"
          }
        }]
      },
      required: !1,
      description: "",
      tsType: {
        name: "RootHOCsType"
      }
    },
    globalizationSettings: {
      type: {
        name: "shape",
        value: {
          getAvailableLanguages: {
            name: "func",
            required: !0
          },
          getAvailableLocales: {
            name: "func",
            required: !0
          },
          getDefaultCountryCode: {
            name: "func",
            required: !0
          },
          getDefaultCurrency: {
            name: "func",
            required: !0
          },
          getDefaultTimeZone: {
            name: "func",
            required: !0
          },
          getPreferredLanguage: {
            name: "func",
            required: !0
          },
          getPreferredLocale: {
            name: "func",
            required: !0
          },
          onGlobalizationChange: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    },
    appName: {
      required: !0,
      tsType: {
        name: "IntlMessageShape"
      },
      description: ""
    }
  }
}, ApplicationRoot.__docgenInfo = {
  componentName: "ApplicationRoot",
  packageName: "@jutro/app",
  description: "A root-level component that takes care of application-level\nconcerns for you:\n-- internationalization\n-- routing\n-- error handling (uncaught)\nIf the application requires a custom HOC, rootHOCs render prop\ncan be used, e.g. <ApplicationRoot rootHOCs={composeRedux(store)} />.\nArray of HOCs are applied in order starting with first to last and then\nErrorBoundary is added.",
  displayName: "ApplicationRoot",
  methods: [{
    name: "renderBody",
    docblock: null,
    modifiers: [],
    params: [{
      name: "{ isAuthenticated = true, }",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "ApplicationRoot",
  props: {
    messageLoader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "(locale) => {\n    warning(`No application specific message resources loaded for locale: ${locale}`);\n    return {};\n}",
        computed: !1
      }
    },
    coreMessageLoader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "(locale) => {\n    warning(`No core message resources loaded for locale: ${locale}`);\n    return {};\n}",
        computed: !1
      }
    },
    intlTextComponent: {
      type: {
        name: "string"
      },
      required: !1,
      description: ""
    },
    errorBoundary: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "ErrorBoundary",
        computed: !0
      }
    },
    loaderComponent: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "Loader",
        computed: !0
      }
    },
    authComponent: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: ""
    },
    main: {
      type: {
        name: "func"
      },
      required: !0,
      description: ""
    },
    mainComponentProps: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    trackingCallback: {
      type: {
        name: "func"
      },
      required: !1,
      description: ""
    },
    Router: {
      type: {
        name: "enum",
        value: [{
          value: "import { BrowserRouter, MemoryRouter } from 'react-router-dom';",
          computed: !0
        }, {
          value: "import { BrowserRouter, MemoryRouter } from 'react-router-dom';",
          computed: !0
        }]
      },
      required: !1,
      description: ""
    },
    routerHistory: {
      type: {
        name: "object"
      },
      required: !1,
      description: ""
    },
    routerBasename: {
      type: {
        name: "string"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "getConfigValue('JUTRO_ROUTER_BASENAME', '')",
        computed: !0
      }
    },
    rootHOCs: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "arrayOf",
          value: {
            name: "func"
          }
        }]
      },
      required: !1,
      description: ""
    },
    refreshOnLanguageOrLocaleChange: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    saveDefaultLanguageAndLocaleToLocalStorage: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    authErrorComponent: {
      type: {
        name: "func"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "AuthErrorPage",
        computed: !0
      }
    },
    modalEmitter: {
      type: {
        name: "instanceOf",
        value: "ModalNextEmitter"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "new ModalNextEmitter()",
        computed: !1
      }
    },
    globalizationSettings: {
      type: {
        name: "shape",
        value: {
          getAvailableLanguages: {
            name: "func",
            required: !0
          },
          getAvailableLocales: {
            name: "func",
            required: !0
          },
          getDefaultCountryCode: {
            name: "func",
            required: !0
          },
          getDefaultCurrency: {
            name: "func",
            required: !0
          },
          getDefaultTimeZone: {
            name: "func",
            required: !0
          },
          getPreferredLanguage: {
            name: "func",
            required: !0
          },
          getPreferredLocale: {
            name: "func",
            required: !0
          },
          onGlobalizationChange: {
            name: "func",
            required: !1
          }
        }
      },
      required: !1,
      description: ""
    }
  }
};