import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["index", "dragging"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import RCSlider, { Range as RCRange } from "rc-slider";
import { FormattedNumber } from "react-intl";
import cx from "classnames";
import capitalize from "lodash/capitalize";
import { error, warning } from "@jutro/logger";
import { DATA_TYPE_NUMBER } from "@jutro/prop-types";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { sliderValuePropType } from "./sliderUtils";
import { SliderIndicatorsContainer } from "./SliderIndicatorsContainer";
import { SliderHandle } from "./SliderHandle";
import styles from "./Slider.module.css";
import { messages } from "./Slider.messages";
var HANDLE_TOOLTIP_VISIBILITY = ["always", "ondrag", "onfocus", "onblur"];
export var Slider = function (_FieldComponent) {
  _inherits(Slider, FieldComponent);
  var _super = _createSuper(Slider);
  function Slider() {
    var _context, _this;
    _classCallCheck(this, Slider);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "renderValue", function (value) {
      return React.createElement(FormattedNumber, {
        value: value
      });
    }), _defineProperty(_assertThisInitialized(_this), "renderValueRange", function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        start = _ref2[0],
        end = _ref2[1];
      return React.createElement(React.Fragment, null, _this.renderValue(start), "-", _this.renderValue(end));
    }), _defineProperty(_assertThisInitialized(_this), "onValueChanged", function (value) {
      _this.notifyChange(value);
    }), _this;
  }
  return _createClass(Slider, [{
    key: "value",
    get: function () {
      var _this$props = this.props,
        min = _this$props.min,
        max = _this$props.max,
        range = _this$props.range,
        value = _this$props.value;
      if (!range) return Number.isFinite(value) ? value : min;
      var _ref3 = Array.isArray(value) ? value : [],
        _ref4 = _slicedToArray(_ref3, 2),
        _ref4$ = _ref4[0],
        start = void 0 === _ref4$ ? min : _ref4$,
        _ref4$2 = _ref4[1];
      return [start, void 0 === _ref4$2 ? max : _ref4$2];
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        range = breakpointProps.range,
        className = breakpointProps.className,
        value = this.value;
      return React.createElement("div", _extends({
        id: id,
        className: className
      }, this.generateReadOnlyAccessibilityProperties()), range ? this.renderValueRange(value) : this.renderValue(value));
    }
  }, {
    key: "validateProps",
    value: function () {
      var _context5,
        _this$props2 = this.props,
        min = _this$props2.min,
        step = _this$props2.step,
        minimumRange = _this$props2.minimumRange,
        max = _this$props2.max,
        range = _this$props2.range,
        value = this.value;
      if (range) {
        var _context2, _context3;
        if (value[0] < min || value[0] > max) warning(_concatInstanceProperty(_context2 = "Value prop: First value is not between min: ".concat(min, " and max: ")).call(_context2, max));
        if (value[1] < min || value[1] > max) warning(_concatInstanceProperty(_context3 = "Value prop: Second value is not between min: ".concat(min, " and max: ")).call(_context3, max));
        value[0] > value[1] && error("Value prop: First value is greater than second value");
      } else if (value < min || value > max) {
        var _context4;
        warning(_concatInstanceProperty(_context4 = "Value prop: Value is not between min: ".concat(min, " and max: ")).call(_context4, max));
      }
      (Number.isFinite(max) && Number.isFinite(min) || error("Min and max must be finite numbers."), min > max) && error(_concatInstanceProperty(_context5 = "Min prop: Value ".concat(min, " is greater than max prop value: ")).call(_context5, max, "."));
      step < 0 && error("Step prop: ".concat(step, " is not valid.")), step > max - min && warning("Step prop: ".concat(step, " is not valid.")), (minimumRange < 0 || minimumRange > max - min) && error("MinimumRange prop: ".concat(minimumRange, " is not valid."));
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, _ref5) {
      var _this2 = this,
        labelId = _ref5.labelId;
      this.validateProps();
      var min = breakpointProps.min,
        max = breakpointProps.max,
        showIndicators = breakpointProps.showIndicators,
        step = breakpointProps.step,
        showNotches = breakpointProps.showNotches,
        range = breakpointProps.range,
        minimumRange = breakpointProps.minimumRange,
        handleTooltip = breakpointProps.handleTooltip,
        onUpdate = breakpointProps.onUpdate,
        disabled = breakpointProps.disabled,
        controlClassName = breakpointProps.controlClassName,
        onFocus = breakpointProps.onFocus,
        onBlur = breakpointProps.onBlur,
        value = this.value;
      this.styles = styles;
      var handleTooltipClassName = "tooltip".concat(capitalize(handleTooltip)),
        classes = cx(this.styles.slider, _defineProperty({}, this.styles.disabled, disabled), this.styles[handleTooltipClassName], controlClassName),
        componentProps = {
          min: min,
          max: max,
          step: step,
          value: value,
          pushable: minimumRange,
          disabled: disabled,
          dots: showNotches,
          onChange: this.onValueChanged,
          onUpdate: onUpdate,
          handle: function (_ref6) {
            var _context6,
              _context7,
              _context8,
              index = _ref6.index,
              handleProps = (_ref6.dragging, _objectWithoutProperties(_ref6, _excluded)),
              props = _this2.props,
              fieldUniqueId = _this2.fieldUniqueId,
              translator = _this2.translator,
              handleId = 0 === index ? fieldUniqueId : _concatInstanceProperty(_context6 = "".concat(fieldUniqueId, "-")).call(_context6, index),
              rangeHandleLabelId = "".concat(handleId, "-range"),
              handleTestId = 0 === index ? props.testId || props.id : _concatInstanceProperty(_context7 = "".concat(props.testId || props.id, "-")).call(_context7, index);
            return React.createElement(React.Fragment, {
              key: index
            }, props.range && React.createElement("span", {
              className: styles.hidden,
              id: rangeHandleLabelId
            }, translator(0 === index ? messages.startOfRange : messages.endOfRange)), React.createElement(SliderHandle, _extends({
              id: handleId,
              key: index
            }, handleProps, {
              className: styles.handle,
              tooltipClassName: styles.tooltip,
              labelId: props.range ? _concatInstanceProperty(_context8 = "".concat(labelId, " ")).call(_context8, rangeHandleLabelId) : labelId,
              testId: handleTestId,
              onFocus: onFocus,
              onBlur: onBlur
            })));
          },
          allowCross: !1,
          labelId: labelId
        },
        Component = range ? RCRange : RCSlider,
        indicatorsProps = {
          min: min,
          max: max,
          value: value,
          range: range,
          containerClassName: this.styles.indicators,
          indicatorClassName: this.styles.indicator
        },
        slider = React.createElement(Component, componentProps);
      return React.createElement("div", {
        className: classes,
        "data-testid": "slider-container"
      }, showIndicators ? React.createElement(SliderIndicatorsContainer, indicatorsProps, slider) : slider);
    }
  }, {
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(Slider.prototype), "render", this).call(this);
    }
  }]), Slider;
}();
_defineProperty(Slider, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: sliderValuePropType.isRequired,
  defaultValue: sliderValuePropType,
  showIndicators: PropTypes.bool,
  step: PropTypes.number,
  showNotches: PropTypes.bool,
  range: PropTypes.bool,
  minimumRange: PropTypes.number,
  handleTooltip: PropTypes.oneOf(HANDLE_TOOLTIP_VISIBILITY),
  onUpdate: PropTypes.func
})), _defineProperty(Slider, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  showIndicators: !0,
  range: !1,
  handleTooltip: HANDLE_TOOLTIP_VISIBILITY[0],
  dataType: DATA_TYPE_NUMBER
})), Slider.__docgenInfo = {
  description: "@typedef {typeof Slider.propTypes} SliderPropTypes\n@extends FieldComponent<PropTypes.InferProps<SliderPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "value",
    docblock: "Returns value\n@returns {number | number[]}",
    modifiers: ["get"],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "Array",
          elements: [{
            name: "number"
          }]
        }]
      }
    },
    description: "Returns value"
  }, {
    name: "renderValue",
    docblock: "Renders formatted number value\n@param {number} value\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders formatted number value"
  }, {
    name: "renderValueRange",
    docblock: "@param {[ number, number ]} range\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "[start, end]"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }],
    returns: null
  }, {
    name: "onValueChanged",
    docblock: "Handles value change\n@param {number} value",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: null,
    description: "Handles value change"
  }, {
    name: "validateProps",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }, {
      name: "{ labelId }",
      type: null
    }],
    returns: null
  }],
  displayName: "Slider",
  props: {
    showIndicators: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the min and max values on either end of the slider"
    },
    range: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the range selector"
    },
    handleTooltip: {
      defaultValue: {
        value: "HANDLE_TOOLTIP_VISIBILITY[0]",
        computed: !0
      },
      type: {
        name: "enum",
        value: [{
          value: "'always'",
          computed: !1
        }, {
          value: "'ondrag'",
          computed: !1
        }, {
          value: "'onfocus'",
          computed: !1
        }, {
          value: "'onblur'",
          computed: !1
        }]
      },
      required: !1,
      description: "Prop to specify when handle tooltip should be visible\n- `ondrag` - tooltip is visible only when user drags handle\n- `onfocus` - tooltip is visible only when handle is focused\n- `onblur` - tooltip is visible only when handle is blured\n- `always` - tooltip is always visible"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_NUMBER",
        computed: !0
      },
      required: !1
    },
    min: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "custom",
        raw: "sliderValuePropType.isRequired"
      },
      required: !1,
      description: "Current slider value (to create a fully controlled component)"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: !1,
      description: "Initial slider value (to create an uncontrolled component)"
    },
    step: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Step between consecutive values"
    },
    showNotches: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays notches all along the slider"
    },
    minimumRange: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Prop to specify minimum range size in case of range selection"
    },
    onUpdate: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value change is completed (to create an uncontrolled component)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, Slider.__docgenInfo = {
  componentName: "Slider",
  packageName: "@jutro/components",
  description: "",
  displayName: "Slider",
  methods: [{
    name: "value",
    docblock: "Returns value\n@returns {number | number[]}",
    modifiers: ["get"],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "Array",
          elements: [{
            name: "number"
          }]
        }]
      }
    },
    description: "Returns value"
  }, {
    name: "renderValue",
    docblock: "Renders formatted number value\n@param {number} value\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders formatted number value"
  }, {
    name: "renderValueRange",
    docblock: "@param {[ number, number ]} range\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "[start, end]"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "onValueChanged",
    docblock: "Handles value change\n@param {number} value",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "number"
      },
      optional: !1
    }],
    returns: null,
    description: "Handles value change"
  }, {
    name: "validateProps",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }, {
      name: "{ labelId }",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "Slider",
  metadataType: "field",
  props: {
    min: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: !0,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: !0,
      description: "Current slider value (to create a fully controlled component)"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: !1,
      description: "Initial slider value (to create an uncontrolled component)"
    },
    showIndicators: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the min and max values on either end of the slider",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    step: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Step between consecutive values"
    },
    showNotches: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays notches all along the slider"
    },
    range: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the range selector",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    minimumRange: {
      type: {
        name: "number"
      },
      required: !1,
      description: "Prop to specify minimum range size in case of range selection"
    },
    handleTooltip: {
      type: {
        name: "enum",
        value: [{
          value: "'always'",
          computed: !1
        }, {
          value: "'ondrag'",
          computed: !1
        }, {
          value: "'onfocus'",
          computed: !1
        }, {
          value: "'onblur'",
          computed: !1
        }]
      },
      required: !1,
      description: "Prop to specify when handle tooltip should be visible\n- `ondrag` - tooltip is visible only when user drags handle\n- `onfocus` - tooltip is visible only when handle is focused\n- `onblur` - tooltip is visible only when handle is blured\n- `always` - tooltip is always visible",
      defaultValue: {
        value: "HANDLE_TOOLTIP_VISIBILITY[0]",
        computed: !0
      }
    },
    onUpdate: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when value change is completed (to create an uncontrolled component)"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_NUMBER",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};