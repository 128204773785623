import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import { LayoutContainer, layoutComponentPropTypes, layoutComponentDefaultProps } from "./LayoutContainer";
export var OneToOneColumnLayout = function (props) {
  var children = props.children;
  return React.createElement(LayoutContainer, _extends({}, props, {
    columns: ["minmax(0, 1fr)", "minmax(0, 1fr)"]
  }), children);
};
OneToOneColumnLayout.propTypes = _objectSpread({}, layoutComponentPropTypes), OneToOneColumnLayout.defaultProps = _objectSpread({}, layoutComponentDefaultProps), OneToOneColumnLayout.__docgenInfo = {
  description: "Renders a two half width columns layout with flex inside and Card as a container by default\n\n@metadataType layout",
  methods: [],
  displayName: "OneToOneColumnLayout",
  composes: ["./LayoutContainer"]
}, OneToOneColumnLayout.__docgenInfo = {
  componentName: "OneToOneColumnLayout",
  packageName: "@jutro/components",
  description: "Renders a two half width columns layout with flex inside and Card as a container by default",
  displayName: "OneToOneColumnLayout",
  methods: [],
  actualName: "OneToOneColumnLayout",
  metadataType: "layout",
  composes: ["./LayoutContainer"]
};