import { defineMessages } from "react-intl";
export var messages = defineMessages({
  noDataText: {
    id: "jutro-datatable.DataTable.Pagination.NoDataText",
    defaultMessage: "No rows found"
  },
  loadingText: {
    id: "jutro-datatable.DataTable.Pagination.LoadingText",
    defaultMessage: "Loading..."
  },
  expandedRowLabel: {
    id: "jutro-datatable.DataTable.Expander.ExpandedRowLabel",
    defaultMessage: "Collapse row"
  },
  collapsedRowLabel: {
    id: "jutro-datatable.DataTable.Expander.CollapsedRowLabel",
    defaultMessage: "Expand row"
  },
  tableLabel: {
    id: "jutro-datatable.DataTable.TableLabel",
    defaultMessage: "Data Table"
  },
  searchPlaceholder: {
    id: "jutro-datatable.DataTable.SearchPlaceholder",
    defaultMessage: "Search..."
  },
  selectAllRows: {
    id: "jutro-datatable.DataTable.SelectAllRows",
    defaultMessage: "Select all rows"
  },
  selectOneRow: {
    id: "jutro-datatable.DataTable.selectOneRow",
    defaultMessage: "Select row number {rowNumber}"
  },
  sortableColumnHeader: {
    id: "jutro-datatable.DataTable.sortableColumnHeader",
    defaultMessage: "sortable column header"
  },
  sorted: {
    id: "jutro-datatable.DataTable.sorted",
    defaultMessage: "sorted"
  },
  sortedBy: {
    id: "jutro-datatable.DataTable.sortedBy",
    defaultMessage: "sorted by"
  },
  ascending: {
    id: "jutro-datatable.DataTable.ascending",
    defaultMessage: "ascending"
  },
  descending: {
    id: "jutro-datatable.DataTable.descending",
    defaultMessage: "descending"
  },
  columnsConfig: {
    id: "jutro-datatable.DataTable.columnsConfig",
    defaultMessage: "Customize Columns"
  },
  expanderColumn: {
    id: "jutro-datatable.DataTable.expanderColumn",
    defaultMessage: "Expander Column"
  },
  actionsColumn: {
    id: "jutro-datatable.DataTable.actionsColumn",
    defaultMessage: "Actions Column"
  },
  singleSelectionColumn: {
    id: "jutro-datatable.DataTable.singleSelectionColumn",
    defaultMessage: "Single Selection Column"
  }
});