import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from "react";
import { GenericSelectComponents as components } from "../../GenericSelectControl/GenericSelectControl";
export var LookupDropdownIndicator = function (props) {
  var customStyles = props.selectProps.customStyles;
  return React.createElement(components.DropdownIndicator, _extends({}, props, {
    className: customStyles.selectIcon,
    icon: "gw-search"
  }));
};
LookupDropdownIndicator.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupDropdownIndicator"
}, LookupDropdownIndicator.__docgenInfo = {
  componentName: "LookupDropdownIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupDropdownIndicator",
  methods: [],
  actualName: "LookupDropdownIndicator"
};