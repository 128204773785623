import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _Number$MAX_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/max-safe-integer";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import isUndefined from "lodash/isUndefined";
import cx from "classnames";
import { IntlContext, CurrencyContext } from "@jutro/locale";
import { ContextConsumer } from "@jutro/platform";
import { isEmptyValue, getDecimalRegex, isSafeNumber } from "@jutro/data";
import { DATA_TYPE_OBJECT, currencyDataTypeShape, currencyValueShape } from "@jutro/prop-types";
import { BreakpointTrackerContext } from "@jutro/layout";
import { CurrencyValue } from "../../values/CurrencyValue";
import { valueParserMap } from "../../values/FormattedCurrency";
import { isAccessibleDisabled, muteCallbacks } from "../../../accessibleDisabled";
import currencyStyles from "./CurrencyField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { messages } from "./CurrencyField.messages";
export var CurrencyField = function (_FieldComponent) {
  _inherits(CurrencyField, FieldComponent);
  var _super = _createSuper(CurrencyField);
  function CurrencyField() {
    var _context, _this;
    _classCallCheck(this, CurrencyField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "state", {}), _defineProperty(_assertThisInitialized(_this), "renderCurrencyFieldComponent", function (breakpoint, currencyContext) {
      return _this.currencyContext = currencyContext, _this.renderFieldComponent(breakpoint);
    }), _defineProperty(_assertThisInitialized(_this), "handleChange", function (evt) {
      var targetValue = evt.target.value;
      if (_this.inputRegex.test(targetValue) || isEmptyValue(targetValue)) {
        var newAmount = "text" === evt.target.type ? _trimInstanceProperty(targetValue).call(targetValue) : targetValue;
        "string" == typeof newAmount && !isSafeNumber(newAmount) || newAmount > _Number$MAX_SAFE_INTEGER || _this.updateValue(newAmount);
      }
    }), _defineProperty(_assertThisInitialized(_this), "updateValue", function (newValue) {
      var dataType = _this.props.dataType;
      _this.notifyChange(valueParserMap[dataType].newValue(newValue, _this.getCurrency()));
    }), _defineProperty(_assertThisInitialized(_this), "handleOnBlur", function (evt, accessibleDisabled) {
      accessibleDisabled ? _this.setState({
        accessibleDisabledFocused: !1
      }) : (_this.updateInputValueToNumber(), _this.setState({
        editMode: !1
      }), _this.getInputFocusHandlers().onBlur(evt));
    }), _defineProperty(_assertThisInitialized(_this), "handleOnFocus", function (evt, accessibleDisabled) {
      accessibleDisabled ? _this.setState({
        accessibleDisabledFocused: !0
      }) : (_this.setState({
        editMode: !0
      }), _this.getInputFocusHandlers().onFocus(evt));
    }), _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (event) {
      if (38 === event.keyCode || 40 === event.keyCode) {
        var numberValue,
          amount = _this.getValue();
        isEmptyValue(amount) ? numberValue = 0 : (numberValue = Math.floor(Number(amount)), Number.isNaN(numberValue) && (numberValue = 0));
        var newVal = 38 === event.keyCode ? numberValue + 1 : numberValue - 1;
        _this.updateValue(newVal), event.preventDefault();
      }
    }), _this;
  }
  return _createClass(CurrencyField, [{
    key: "render",
    value: function () {
      return React.createElement(ContextConsumer, {
        contexts: [BreakpointTrackerContext, CurrencyContext]
      }, this.renderCurrencyFieldComponent);
    }
  }, {
    key: "inputRegex",
    get: function () {
      var showFractions = this.props.showFractions;
      return getDecimalRegex(showFractions ? 2 : 0);
    }
  }, {
    key: "getCurrency",
    value: function () {
      var _this$props = this.props,
        value = _this$props.value,
        defaultCurrency = _this$props.defaultCurrency,
        dataType = _this$props.dataType;
      return valueParserMap[dataType].getCurrency(value, defaultCurrency || this.currencyContext.defaultCurrency);
    }
  }, {
    key: "getFractionDigits",
    value: function () {
      return this.props.showFractions ? void 0 : 0;
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        currencyDisplay = breakpointProps.currencyDisplay,
        showFractions = breakpointProps.showFractions,
        controlClassName = breakpointProps.controlClassName,
        currency = this.getCurrency(),
        classes = cx(currencyStyles.currency, controlClassName);
      return React.createElement(CurrencyValue, _extends({
        id: id,
        className: classes,
        amount: this.getValue(),
        currency: currency,
        currencyDisplay: currencyDisplay,
        placeholder: FieldComponent.defaultROEmptyValue,
        showFractions: showFractions
      }, this.generateReadOnlyAccessibilityProperties()));
    }
  }, {
    key: "getCurrencySymbol",
    value: function (intl) {
      var _context2,
        formattedValue = this.formatValue(0, {
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }, intl),
        currencySymbol = _trimInstanceProperty(_context2 = formattedValue.replace(/\d/g, "")).call(_context2);
      return {
        startsWithSymbol: _startsWithInstanceProperty(formattedValue).call(formattedValue, currencySymbol),
        currencySymbol: currencySymbol
      };
    }
  }, {
    key: "getValue",
    value: function (value) {
      var _this$props2 = this.props,
        valueProp = _this$props2.value,
        dataType = _this$props2.dataType;
      return valueParserMap[dataType].getValue(null != value ? value : valueProp);
    }
  }, {
    key: "formatValue",
    value: function (value, params, intl) {
      if ("-" === value) return value;
      var currency = this.getCurrency(),
        formatConfig = _objectSpread({
          currency: 3 !== currency.length ? "USD" : currency
        }, params);
      return intl.formatters.getNumberFormat(intl.locale, formatConfig).format(value);
    }
  }, {
    key: "updateInputValueToNumber",
    value: function () {
      var inputVal = this.getValue();
      if ("-" !== inputVal) {
        var newVal = isEmptyValue(inputVal) ? null : Number(inputVal);
        this.updateValue(newVal);
      }
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function (intl) {
      var label = this.props.label,
        translator = this.translator,
        formattedPlaceholder = this.formattedPlaceholder(intl),
        currency = this.getCurrency();
      return _objectSpread(_objectSpread({}, _get(_getPrototypeOf(CurrencyField.prototype), "generateAccessibilityProperties", this).call(this)), {}, {
        "aria-placeholder": formattedPlaceholder,
        placeholder: formattedPlaceholder,
        "aria-label": translator(messages.currencyFieldAriaLabel, {
          label: translator(label),
          currency: currency
        })
      });
    }
  }, {
    key: "formatConfig",
    value: function () {
      var minimumFractionDigits = this.getFractionDigits(),
        maximumFractionDigits = this.getFractionDigits();
      return {
        style: "currency",
        currencyDisplay: "symbol",
        currency: this.getCurrency(),
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
      };
    }
  }, {
    key: "formattedPlaceholder",
    value: function (intl) {
      var _context3,
        placeholder = this.props.placeholder,
        currencySymbol = this.getCurrencySymbol(intl).currencySymbol,
        formattedPlaceholder = placeholder;
      placeholder && !Number.isNaN(+placeholder) && (formattedPlaceholder = _trimInstanceProperty(_context3 = intl.formatNumber(placeholder, this.formatConfig()).replace(currencySymbol, "")).call(_context3));
      return formattedPlaceholder;
    }
  }, {
    key: "renderComponent",
    value: function (props, intl, options) {
      var _context4,
        _this2 = this,
        disabled = props.disabled,
        required = props.required,
        controlClassName = props.controlClassName,
        currencyDisplay = props.currencyDisplay,
        testId = props.testId,
        id = props.id,
        registerValidation = props.registerValidation,
        _this$state = this.state,
        editMode = _this$state.editMode,
        accessibleDisabledFocused = _this$state.accessibleDisabledFocused,
        fieldUniqueId = this.fieldUniqueId,
        _this$getCurrencySymb2 = this.getCurrencySymbol(intl),
        startsWithSymbol = _this$getCurrencySymb2.startsWithSymbol,
        currencySymbol = _this$getCurrencySymb2.currencySymbol,
        inputVal = this.getValue();
      (isEmptyValue(inputVal) && (inputVal = ""), editMode || isEmptyValue(inputVal) || "-" === inputVal) || (inputVal = _trimInstanceProperty(_context4 = intl.formatNumber(inputVal, this.formatConfig()).replace(currencySymbol, "")).call(_context4));
      var validationMessages = this.getValidationMessages(inputVal),
        messageStyle = this.getValidationMessageStyle(validationMessages),
        wrapperClasses = cx(inputStyles.input, currencyStyles.currency, currencyStyles.inputContainer, _defineProperty({}, currencyStyles.focusWithin, editMode || accessibleDisabledFocused), _defineProperty({}, messageStyle, !options.isValid), _defineProperty({}, messageStyle, !options.isValid), {
          disabled: disabled
        }, controlClassName, !isUndefined(registerValidation) && !options.isValid && currencyStyles.labpreviewBorder),
        inputClasses = cx(currencyStyles.currencyInput),
        currencyValue = "symbol" === currencyDisplay ? this.getCurrencySymbol(intl).currencySymbol : this.getCurrency(),
        symbol = React.createElement("div", {
          className: currencyStyles.currencySymbol
        }, React.createElement("span", null, currencyValue)),
        accessibleDisabled = disabled && isAccessibleDisabled("accessibleDisabled.fields"),
        accessibleDisabledProps = accessibleDisabled ? _objectSpread(_objectSpread({}, muteCallbacks({
          onChange: this.handleChange,
          onKeyDown: this.handleKeyDown
        })), {}, {
          "aria-disabled": !0,
          "aria-readonly": !0,
          readOnly: !0,
          disabled: void 0,
          onBlur: function (evt) {
            return _this2.handleOnBlur(evt, accessibleDisabled);
          },
          onFocus: function (evt) {
            return _this2.handleOnFocus(evt, accessibleDisabled);
          }
        }) : {},
        inputField = React.createElement("input", _extends({
          id: fieldUniqueId,
          "data-testid": testId || id,
          type: "text",
          className: inputClasses,
          value: inputVal,
          onChange: this.handleChange,
          disabled: disabled,
          required: required,
          inputMode: "decimal",
          autoComplete: "off",
          onBlur: this.handleOnBlur,
          onFocus: this.handleOnFocus,
          onKeyDown: this.handleKeyDown
        }, this.generateDataPathProperty(), this.generateAccessibilityProperties(intl), accessibleDisabledProps));
      return React.createElement("div", {
        className: wrapperClasses
      }, startsWithSymbol && symbol, inputField, !startsWithSymbol && symbol);
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps, options) {
      var _this3 = this;
      return React.createElement(IntlContext.Consumer, null, function (intl) {
        return _this3.renderComponent(breakpointProps, intl, options);
      });
    }
  }]), CurrencyField;
}();
_defineProperty(CurrencyField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  value: currencyValueShape,
  defaultCurrency: PropTypes.string,
  currencyDisplay: PropTypes.oneOf(["code", "name", "symbol"]),
  showFractions: PropTypes.bool,
  defaultValue: currencyValueShape,
  dataType: currencyDataTypeShape
})), _defineProperty(CurrencyField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  currencyDisplay: "symbol",
  showFractions: !0,
  dataType: DATA_TYPE_OBJECT
})), _defineProperty(CurrencyField, "contextType", FieldComponent.contextType), CurrencyField.__docgenInfo = {
  description: "Use it to enter or display a monetary value. The amount is displayed based on the locale.\n\nIf you want to use it to display the localized value as text, and not an input, set `readOnly` to `true`.\n\nThe component can handle dataTypes of `object` or `number` which is set by dataType prop.\n\n@typedef {typeof CurrencyField.propTypes} CurrencyFieldPropTypes\n@extends FieldComponent<PropTypes.InferProps<CurrencyFieldPropTypes>>\n\n@metadataType field",
  methods: [{
    name: "renderCurrencyFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      type: null
    }, {
      name: "currencyContext",
      type: null
    }],
    returns: null
  }, {
    name: "inputRegex",
    docblock: "creates regex for input field.\nAllows decimal point '.' and 0 to 2 numbers to the right if showFractions is true.\nDoes not allow a decimal point if showFractions is false,\n\n@returns {Function} regex for input field",
    modifiers: ["get"],
    params: [],
    returns: {
      description: "regex for input field",
      type: {
        name: "Function"
      }
    },
    description: "creates regex for input field.\nAllows decimal point '.' and 0 to 2 numbers to the right if showFractions is true.\nDoes not allow a decimal point if showFractions is false,"
  }, {
    name: "handleChange",
    docblock: "Override FieldComponent.handleChange to deal with value as an object\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Override FieldComponent.handleChange to deal with value as an object"
  }, {
    name: "getCurrency",
    docblock: "Returns currency\n@returns {object}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Returns currency"
  }, {
    name: "getFractionDigits",
    docblock: "Returns faction digits\n@returns {(string|undefined)}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "string"
        }, null]
      }
    },
    description: "Returns faction digits"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "getCurrencySymbol",
    docblock: "Returns the currency symbol and its position\n\n@param {object} intl - i18n context\n@returns {object}",
    modifiers: [],
    params: [{
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Returns the currency symbol and its position"
  }, {
    name: "getValue",
    docblock: "Helper to return the value\n\n@param {*} [value]\n@returns {number | string}",
    modifiers: [],
    params: [{
      name: "value"
    }],
    returns: null,
    description: "Helper to return the value"
  }, {
    name: "formatValue",
    docblock: "Formatter helper to return the value with dots, commas and spaces based on locale and configs provided\n\n@param {number | string} value\n@param {object} params\n@param {number} params.value\n@param {string} params.currency\n@param {number} params.minimumFractionDigits\n@param {number} params.maximumFractionDigits\n@param {object} intl - i18n context\n@returns {string}",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "params",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "string"
      }
    },
    description: "Formatter helper to return the value with dots, commas and spaces based on locale and configs provided"
  }, {
    name: "updateValue",
    docblock: "Helper function that calls notify change with the correct format depending on value prop dataType\n@param {number|string} newValue",
    modifiers: [],
    params: [{
      name: "newValue",
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }],
    returns: null,
    description: "Helper function that calls notify change with the correct format depending on value prop dataType"
  }, {
    name: "updateInputValueToNumber",
    docblock: "Helper function that sets value amount to dataType number",
    modifiers: [],
    params: [],
    returns: null,
    description: "Helper function that sets value amount to dataType number"
  }, {
    name: "handleOnBlur",
    docblock: 'Updates the input type to "text" when user leaves the input and call default FieldComponent.onBlur method\n\n@param {object} evt - React event wrapper',
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "accessibleDisabled"
    }],
    returns: null,
    description: 'Updates the input type to "text" when user leaves the input and call default FieldComponent.onBlur method'
  }, {
    name: "handleOnFocus",
    docblock: 'Updates the input type to "number" when user focus in the input and call default FieldComponent.onFocus method\n\n@param {object} evt - React event wrapper',
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "accessibleDisabled"
    }],
    returns: null,
    description: 'Updates the input type to "number" when user focus in the input and call default FieldComponent.onFocus method'
  }, {
    name: "handleKeyDown",
    docblock: "Specific onKeyDown handler function for number inputs\n\n@param {object} event - React event wrapper",
    modifiers: [],
    params: [{
      name: "event",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Specific onKeyDown handler function for number inputs"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@param {object} intl - i18n context\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [{
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "formatConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "formattedPlaceholder",
    docblock: null,
    modifiers: [],
    params: [{
      name: "intl",
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: "Render control for this component.\n\n@param {object} props - breakpoint-specific props\n@param {object} intl - i18n context\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "props",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: null
    }, {
      name: "options",
      type: null
    }],
    returns: null
  }],
  displayName: "CurrencyField",
  props: {
    currencyDisplay: {
      defaultValue: {
        value: "'symbol'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'code'",
          computed: !1
        }, {
          value: "'name'",
          computed: !1
        }, {
          value: "'symbol'",
          computed: !1
        }]
      },
      required: !1,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'"
    },
    showFractions: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the fraction part of the amount"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "currencyDataTypeShape"
      },
      required: !1,
      description: "Sets the datatype type of the value prop. When set to 'string' accepts\nstrings containing currency symbol i.e., '123.23 usd'"
    },
    value: {
      type: {
        name: "custom",
        raw: "currencyValueShape"
      },
      required: !1,
      description: "Value to display in the Currency field"
    },
    defaultCurrency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default currency"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "currencyValueShape"
      },
      required: !1,
      description: "Default value"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, CurrencyField.__docgenInfo = {
  componentName: "CurrencyField",
  packageName: "@jutro/components",
  description: "Use it to enter or display a monetary value. The amount is displayed based on the locale.\n\nIf you want to use it to display the localized value as text, and not an input, set `readOnly` to `true`.\n\nThe component can handle dataTypes of `object` or `number` which is set by dataType prop.",
  displayName: "CurrencyField",
  methods: [{
    name: "renderCurrencyFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      optional: void 0,
      type: null
    }, {
      name: "currencyContext",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "inputRegex",
    docblock: "creates regex for input field.\nAllows decimal point '.' and 0 to 2 numbers to the right if showFractions is true.\nDoes not allow a decimal point if showFractions is false,\n\n@returns {Function} regex for input field",
    modifiers: ["get"],
    params: [],
    returns: {
      description: "regex for input field",
      type: {
        name: "Function"
      }
    },
    description: "creates regex for input field.\nAllows decimal point '.' and 0 to 2 numbers to the right if showFractions is true.\nDoes not allow a decimal point if showFractions is false,"
  }, {
    name: "handleChange",
    docblock: "Override FieldComponent.handleChange to deal with value as an object\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Override FieldComponent.handleChange to deal with value as an object"
  }, {
    name: "getCurrency",
    docblock: "Returns currency\n@returns {object}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Returns currency"
  }, {
    name: "getFractionDigits",
    docblock: "Returns faction digits\n@returns {(string|undefined)}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "string"
        }, null]
      }
    },
    description: "Returns faction digits"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "getCurrencySymbol",
    docblock: "Returns the currency symbol and its position\n\n@param {object} intl - i18n context\n@returns {object}",
    modifiers: [],
    params: [{
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Returns the currency symbol and its position"
  }, {
    name: "getValue",
    docblock: "Helper to return the value\n\n@param {*} [value]\n@returns {number | string}",
    modifiers: [],
    params: [{
      name: "value"
    }],
    returns: null,
    description: "Helper to return the value"
  }, {
    name: "formatValue",
    docblock: "Formatter helper to return the value with dots, commas and spaces based on locale and configs provided\n\n@param {number | string} value\n@param {object} params\n@param {number} params.value\n@param {string} params.currency\n@param {number} params.minimumFractionDigits\n@param {number} params.maximumFractionDigits\n@param {object} intl - i18n context\n@returns {string}",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }, {
      name: "params",
      description: null,
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: null,
      type: {
        name: "string"
      }
    },
    description: "Formatter helper to return the value with dots, commas and spaces based on locale and configs provided"
  }, {
    name: "updateValue",
    docblock: "Helper function that calls notify change with the correct format depending on value prop dataType\n@param {number|string} newValue",
    modifiers: [],
    params: [{
      name: "newValue",
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: !1
    }],
    returns: null,
    description: "Helper function that calls notify change with the correct format depending on value prop dataType"
  }, {
    name: "updateInputValueToNumber",
    docblock: "Helper function that sets value amount to dataType number",
    modifiers: [],
    params: [],
    returns: null,
    description: "Helper function that sets value amount to dataType number"
  }, {
    name: "handleOnBlur",
    docblock: 'Updates the input type to "text" when user leaves the input and call default FieldComponent.onBlur method\n\n@param {object} evt - React event wrapper',
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "accessibleDisabled"
    }],
    returns: null,
    description: 'Updates the input type to "text" when user leaves the input and call default FieldComponent.onBlur method'
  }, {
    name: "handleOnFocus",
    docblock: 'Updates the input type to "number" when user focus in the input and call default FieldComponent.onFocus method\n\n@param {object} evt - React event wrapper',
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "accessibleDisabled"
    }],
    returns: null,
    description: 'Updates the input type to "number" when user focus in the input and call default FieldComponent.onFocus method'
  }, {
    name: "handleKeyDown",
    docblock: "Specific onKeyDown handler function for number inputs\n\n@param {object} event - React event wrapper",
    modifiers: [],
    params: [{
      name: "event",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: null,
    description: "Specific onKeyDown handler function for number inputs"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@param {object} intl - i18n context\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [{
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "formatConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "formattedPlaceholder",
    docblock: null,
    modifiers: [],
    params: [{
      name: "intl",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: "Render control for this component.\n\n@param {object} props - breakpoint-specific props\n@param {object} intl - i18n context\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "props",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: !1
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: !1
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: void 0,
      type: null
    }, {
      name: "options",
      optional: void 0,
      type: null
    }],
    returns: null
  }],
  actualName: "CurrencyField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: !1
            },
            currency: {
              name: "string",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Value to display in the Currency field"
    },
    defaultCurrency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default currency"
    },
    currencyDisplay: {
      type: {
        name: "enum",
        value: [{
          value: "'code'",
          computed: !1
        }, {
          value: "'name'",
          computed: !1
        }, {
          value: "'symbol'",
          computed: !1
        }]
      },
      required: !1,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'",
      defaultValue: {
        value: "'symbol'",
        computed: !1
      }
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays the fraction part of the amount",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: !1
            },
            currency: {
              name: "string",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Default value"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: !1
        }, {
          value: "'string'",
          computed: !1
        }, {
          value: "'number'",
          computed: !1
        }, {
          value: "'gw-rest-string'",
          computed: !1
        }, {
          value: "'gw-rest-object'",
          computed: !1
        }]
      },
      required: !1,
      description: "Sets the datatype type of the value prop. When set to 'string' accepts\nstrings containing currency symbol i.e., '123.23 usd'",
      defaultValue: {
        value: "'object'",
        computed: !1
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};