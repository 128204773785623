import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import { useCallback, useEffect, useState } from "react";
import { sortWithLocale, useLanguage } from "@jutro/locale";
import isEqual from "lodash/isEqual";
export var useSortedFileNames = function (uploadedFiles, uploadState, filesErrors) {
  var language = useLanguage().language,
    _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    sortedFileNames = _useState2[0],
    setSortedFileNames = _useState2[1],
    _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    processedUploadedFiles = _useState4[0],
    setProcessedUploadedFiles = _useState4[1],
    _useState5 = useState({}),
    _useState6 = _slicedToArray(_useState5, 2),
    processedUploadState = _useState6[0],
    setProcessedUploadState = _useState6[1],
    _useState7 = useState({}),
    _useState8 = _slicedToArray(_useState7, 2),
    processedFilesErrors = _useState8[0],
    setProcessedFilesErrors = _useState8[1],
    sortFilesByStatus = useCallback(function (files, states, errors) {
      var _context,
        _context2,
        fileNames = _sortInstanceProperty(_context = _mapInstanceProperty(files).call(files, function (_ref) {
          return _ref.name;
        })).call(_context, sortWithLocale(language)),
        fileNamesByStatus = groupFileNamesByStatus(fileNames, states, errors);
      setSortedFileNames(_concatInstanceProperty(_context2 = []).call(_context2, _toConsumableArray(fileNamesByStatus.failed), _toConsumableArray(fileNamesByStatus.progress), _toConsumableArray(fileNamesByStatus.selection), _toConsumableArray(fileNamesByStatus.completed))), updateProcessedData(files, states, errors);
    }, [language]);
  useEffect(function () {
    (!isEqual(processedUploadedFiles, uploadedFiles) || !isEqual(processedUploadState, uploadState) || !isEqual(processedFilesErrors, filesErrors)) && sortFilesByStatus(uploadedFiles, uploadState, filesErrors);
  }, [uploadedFiles, uploadState, filesErrors, processedUploadedFiles, processedUploadState, processedFilesErrors, sortFilesByStatus]);
  var groupFileNamesByStatus = function (fileNames, states, errors) {
      return _reduceInstanceProperty(fileNames).call(fileNames, function (acc, name) {
        return errors[name] ? acc.failed.push(name) : states[name] ? acc[states[name]].push(name) : acc.selection.push(name), acc;
      }, {
        selection: [],
        progress: [],
        completed: [],
        cancelled: [],
        failed: []
      });
    },
    updateProcessedData = function (processedFiles, processedStatuses, processedErrors) {
      setProcessedUploadedFiles(processedFiles), setProcessedUploadState(processedStatuses), setProcessedFilesErrors(processedErrors);
    };
  return sortedFileNames;
};