import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import { PhoneNumberUtil } from "google-libphonenumber";
import { log } from "@jutro/logger";
export var formatPhoneNumberAndCountryCode = function (phoneNumber, countryCodeIso2) {
  var phoneUtils = PhoneNumberUtil.getInstance();
  try {
    var _rawInput$getCountryC;
    return [(null === (_rawInput$getCountryC = phoneUtils.parseAndKeepRawInput(phoneNumber, countryCodeIso2).getCountryCode()) || void 0 === _rawInput$getCountryC ? void 0 : _rawInput$getCountryC.toString()) || "", phoneUtils.format(phoneUtils.parse(phoneNumber, countryCodeIso2))];
  } catch (error) {
    var _context;
    return log.warning(_concatInstanceProperty(_context = "Unable to format phone number '".concat(phoneNumber, "': ")).call(_context, error.message)), [];
  }
};