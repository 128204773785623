import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { themeConfigShape } from "@jutro/prop-types";
import { DropdownSelectField } from "../../inputs/DropdownSelectField/DropdownSelectField";
export var omittedDropdownProps = ["value", "defaultValue", "availableValues", "dataType", "autoTrim", "onValueChange", "model", "path", "validationMessages", "dataPath", "validator", "inputType"];
export var themeChooserPropTypes = _objectSpread(_objectSpread({}, omit((null == DropdownSelectField ? void 0 : DropdownSelectField.propTypes) || {}, omittedDropdownProps)), {}, {
  availableThemes: PropTypes.arrayOf(themeConfigShape.isRequired).isRequired,
  onThemeChange: PropTypes.func,
  theme: themeConfigShape,
  defaultTheme: themeConfigShape,
  skipPropagation: PropTypes.bool
});
export var themeChooserDefaultProps = _objectSpread(_objectSpread({}, omit((null == DropdownSelectField ? void 0 : DropdownSelectField.defaultProps) || {}, omittedDropdownProps)), {}, {
  availableThemes: [],
  skipPropagation: !1,
  label: "",
  id: "ThemeChooser"
});
export var themeChooserStorybookPropNames = ["id", "availableThemes", "onThemeChange", "className", "label", "theme", "skipPropagation"];