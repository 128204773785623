import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useState, useEffect } from "react";
import { NextButton, PrevButton } from "../GenericDatePicker/Header";
import { messages } from "./YearField.messages";
import yearPickerStyles from "./YearField.module.css";
function calculateYearRange(date) {
  var _context,
    selectedYear = date.getFullYear(),
    yearRangeEnd = 12 * Math.ceil(selectedYear / 12);
  return _concatInstanceProperty(_context = "".concat(yearRangeEnd - 12 + 1, " - ")).call(_context, yearRangeEnd);
}
export var YearFieldCustomHeader = React.memo(function (_ref) {
  var date = _ref.date,
    increaseYear = _ref.increaseYear,
    decreaseYear = _ref.decreaseYear,
    nextYearButtonDisabled = _ref.nextYearButtonDisabled,
    prevYearButtonDisabled = _ref.prevYearButtonDisabled,
    _useState = useState(function () {
      return calculateYearRange(date);
    }),
    _useState2 = _slicedToArray(_useState, 2),
    yearRange = _useState2[0],
    setYearRange = _useState2[1];
  return useEffect(function () {
    setYearRange(calculateYearRange(date));
  }, [date]), React.createElement(React.Fragment, null, React.createElement(PrevButton, {
    onClick: decreaseYear,
    disabled: prevYearButtonDisabled,
    label: messages.previousYearRange
  }), React.createElement("strong", {
    className: yearPickerStyles.headerYearRange
  }, yearRange), React.createElement(NextButton, {
    onClick: increaseYear,
    disabled: nextYearButtonDisabled,
    label: messages.nextYearRange
  }));
});