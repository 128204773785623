import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import PropTypes from "prop-types";
import { dropdownMenuLinkPropTypes } from "./dropdownMenuLinkPropTypes";
import { availableValuesIdDisplayNameObject } from "./availableValuePropTypes";
import { intlMessageShape } from "./intlMessageShape";
import { appSwitcherItemsObject } from "./appSwitcherRoutePropTypes";
import { linkShape } from "./linkShape";
import { avatarShape } from "./avatarPropTypes";
import { languageSelectorShape } from "./languageSelectorPropTypes";
import { routesShape } from "./routePropTypes";
import { lookupOptionTypeShape } from "./LookupFieldPropTypes";
var lookupOptionShape = PropTypes.shape(_objectSpread(_objectSpread({}, availableValuesIdDisplayNameObject), {}, {
    type: PropTypes.string.isRequired
  })),
  dropdownMenuLinkShape = PropTypes.shape(_objectSpread(_objectSpread({}, dropdownMenuLinkPropTypes), {}, {
    title: intlMessageShape
  }));
export var applicationHeaderPropTypes = {
  className: PropTypes.string,
  logoSrc: PropTypes.string,
  logoUrl: PropTypes.string,
  logoTitle: intlMessageShape,
  logoAlt: intlMessageShape,
  onSearchValueChange: PropTypes.func,
  onLoadValues: PropTypes.func,
  searchAvailableValues: PropTypes.arrayOf(lookupOptionShape.isRequired),
  searchOptionTypes: PropTypes.arrayOf(lookupOptionTypeShape),
  showNotifications: PropTypes.bool,
  notificationChildren: PropTypes.node,
  showAvatar: PropTypes.bool,
  avatarChildren: PropTypes.node,
  avatarProps: avatarShape,
  commonAvatarRoutes: PropTypes.arrayOf(dropdownMenuLinkShape.isRequired),
  searchFieldPlaceholder: intlMessageShape,
  showAppSwitcher: PropTypes.bool,
  appSwitcherItems: appSwitcherItemsObject,
  useAuthInfo: PropTypes.bool,
  showHelp: PropTypes.bool,
  helpUrl: PropTypes.oneOfType([intlMessageShape, linkShape]),
  helpPopoverItems: PropTypes.arrayOf(PropTypes.object),
  callbackMap: PropTypes.shape({}),
  appSwitcherFooterText: intlMessageShape,
  appSwitcherFooterUrl: intlMessageShape,
  onAppSwitcherFooterClick: PropTypes.func,
  appSwitcherSearchEnabled: PropTypes.bool,
  appSwitcherCollapsibleGroupsThreshold: PropTypes.number,
  appSwitcherHideFooter: PropTypes.bool,
  burgerMenuRoutes: routesShape,
  showLanguageSelector: PropTypes.bool,
  languageSelectorProps: languageSelectorShape,
  renderBurgerMenuCustomItem: PropTypes.func,
  renderCustomComponent: PropTypes.func
};