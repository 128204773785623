import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useRef, useCallback, useState } from "react";
import isFunction from "lodash/isFunction";
export function bindActionCreators(dispatch) {
  var _context,
    actions = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return _reduceInstanceProperty(_context = _Object$entries(actions)).call(_context, function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      name = _ref2[0],
      action = _ref2[1];
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, name, function () {
      return dispatch(action.apply(void 0, arguments));
    }));
  }, {});
}
export function useControlledReducer(_ref3) {
  var reducer = _ref3.reducer,
    initialState = _ref3.initialState,
    controlledState = _ref3.controlledState,
    onStateChange = _ref3.onStateChange,
    _ref3$updateInControl = _ref3.updateInControlledMode,
    updateInControlledMode = void 0 !== _ref3$updateInControl && _ref3$updateInControl,
    store = useRef({
      state: initialState
    }).current,
    _useState = useState(0),
    setState = _slicedToArray(_useState, 2)[1],
    update = useCallback(function () {
      return setState(function (state) {
        return state + 1;
      });
    }, []);
  void 0 !== controlledState && (store.state = isFunction(controlledState) ? controlledState(store.state) : controlledState), store.reducer = reducer, store.dispatch = function (action) {
    var oldState = store.state,
      newState = store.reducer(oldState, action);
    newState !== oldState && (onStateChange && onStateChange(newState), controlledState && !updateInControlledMode || (store.state = newState, update()));
  };
  var dispatch = useCallback(function (action) {
    return store.dispatch(action);
  }, [store]);
  return [store.state, dispatch];
}