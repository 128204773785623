import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _get from "@babel/runtime-corejs3/helpers/get";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React from "react";
import PropTypes from "prop-types";
import { intlMessageShape, validationMessageShape, booleanValidationRuleShape, deprecated } from "@jutro/prop-types";
import { renderWithBreakpoint } from "@jutro/layout";
import get from "lodash/get";
import styles from "./FileUploadField.module.css";
import { FieldComponent } from "../FieldComponent/FieldComponent";
import { messages } from "./FileUploadField.messages";
import { FileUploadFieldValidationImplementation } from "./FileUploadFieldValidationImplementation";
import { OldFileUploadFieldValidationImplementation } from "./OldFileUploadFieldValidationImplementation";
import { TYPE_THIN, getFileName } from "./utils";
import { FileUploadFieldInternal } from "./FileUploadFieldInternal";
import { FileUploadFieldReadonly } from "./FileUploadFieldReadonly";
export var FileUploadField = function (_FieldComponent) {
  _inherits(FileUploadField, FieldComponent);
  var _super = _createSuper(FileUploadField);
  function FileUploadField(props) {
    var _thisSuper, _this;
    _classCallCheck(this, FileUploadField), _this = _super.call(this, props), _defineProperty(_assertThisInitialized(_this), "state", _objectSpread(_objectSpread({}, FieldComponent.defaultState), {}, {
      errorMessage: void 0
    })), _defineProperty(_assertThisInitialized(_this), "validationImplementation", void 0), _defineProperty(_assertThisInitialized(_this), "handleErrorStateChange", function (prevErrorState, errorState) {
      var _this$props = _this.props,
        onValidationChange = _this$props.onValidationChange,
        model = _this$props.model,
        path = _this$props.path,
        value = _this$props.value;
      if (prevErrorState !== errorState) {
        var isValid = !errorState && _get((_thisSuper = _assertThisInitialized(_this), _getPrototypeOf(FileUploadField.prototype)), "validate", _thisSuper).call(_thisSuper, value, !0),
          currentValidationMessages = _this.getValidationMessages() || [];
        null == onValidationChange || onValidationChange(isValid, model || path, currentValidationMessages), _this.valid = isValid;
      }
    }), _defineProperty(_assertThisInitialized(_this), "handleChange", function (e, isMultipleUpload, multipleErrorMessages) {
      return _this.validationImplementation.handleChange(e, isMultipleUpload, multipleErrorMessages);
    }), _defineProperty(_assertThisInitialized(_this), "handleClearFile", function (fileName) {
      var _this$props2 = _this.props,
        onFileClear = _this$props2.onFileClear,
        value = _this$props2.value;
      if (null == onFileClear || onFileClear(fileName), "string" == typeof value || "string" == typeof (null == value ? void 0 : value.name) || 1 === (null == value ? void 0 : value.length)) return _this.validationImplementation.handleClearValue();
    });
    var ValidationImplementation = props.registerValidation ? new FileUploadFieldValidationImplementation() : new OldFileUploadFieldValidationImplementation();
    return _this.validationImplementation = ValidationImplementation.bind(_assertThisInitialized(_this)), _this;
  }
  return _createClass(FileUploadField, [{
    key: "componentDidUpdate",
    value: function (prevProps, prevState) {
      this.validationImplementation.componentDidUpdate(prevProps, prevState);
    }
  }, {
    key: "render",
    value: function () {
      return _get(_getPrototypeOf(FileUploadField.prototype), "render", this).call(this);
    }
  }, {
    key: "getValidationConfig",
    value: function () {
      for (var _get2, _context, _context2, _this2 = this, _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      var validationConfig = (_get2 = _get(_getPrototypeOf(FileUploadField.prototype), "getValidationConfig", this)).call.apply(_get2, _concatInstanceProperty(_context = [this]).call(_context, args));
      return _objectSpread(_objectSpread({}, validationConfig), {}, {
        propsList: _concatInstanceProperty(_context2 = ["required", "accept", "maxFileSizeKB", "maxLength", "uploadError", "uploadErrors"]).call(_context2, _toConsumableArray(get(validationConfig, "propsList", []))),
        options: get(validationConfig, "options", []),
        onIsValid: function (file) {
          var _this2$props$onUpload, _this2$props;
          Array.isArray(file) || null === (_this2$props$onUpload = (_this2$props = _this2.props).onUpload) || void 0 === _this2$props$onUpload || _this2$props$onUpload.call(_this2$props, file);
        }
      });
    }
  }, {
    key: "getValidationMessages",
    value: function () {
      return this.validationImplementation.getValidationMessages();
    }
  }, {
    key: "renderControlReadOnly",
    value: function (breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        controlClassName = breakpointProps.controlClassName,
        fileName = value && getFileName(value);
      return React.createElement(FileUploadFieldReadonly, _extends({
        id: id,
        className: controlClassName,
        fileName: fileName || FieldComponent.defaultROEmptyValue
      }, this.generateReadOnlyAccessibilityProperties()));
    }
  }, {
    key: "renderControl",
    value: function (breakpointProps) {
      var _this$validationImple,
        _this3 = this,
        id = breakpointProps.id,
        testId = breakpointProps.testId,
        value = breakpointProps.value,
        maxLength = breakpointProps.maxLength,
        disabled = breakpointProps.disabled,
        readonly = breakpointProps.readonly,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        accept = breakpointProps.accept,
        messageProps = breakpointProps.messageProps,
        type = breakpointProps.type,
        imageSource = breakpointProps.imageSource,
        buttonType = breakpointProps.buttonType,
        buttonSize = breakpointProps.buttonSize,
        buttonIcon = breakpointProps.buttonIcon,
        disableDragAndDrop = breakpointProps.disableDragAndDrop,
        uploadState = breakpointProps.uploadState,
        onCancel = breakpointProps.onCancel,
        completed = breakpointProps.completed,
        total = breakpointProps.total,
        onUpload = breakpointProps.onUpload,
        maxNumberOfFiles = breakpointProps.maxNumberOfFiles,
        maxFileSizeKB = breakpointProps.maxFileSizeKB,
        progressErrorMessage = breakpointProps.progressErrorMessage,
        uploadError = breakpointProps.uploadError,
        uploadErrors = breakpointProps.uploadErrors,
        dataPath = breakpointProps.dataPath,
        validationMessages = null !== (_this$validationImple = this.validationImplementation.getValidationMessages((null == uploadError ? void 0 : uploadError[1]) || progressErrorMessage, !0)) && void 0 !== _this$validationImple ? _this$validationImple : [],
        renderValidationMessages = function () {
          return _this3.validationImplementation.renderMessages(validationMessages);
        },
        _ref = this.getInputFocusHandlers(),
        onFocus = _ref.onFocus,
        onBlur = _ref.onBlur,
        inputA11yProps = this.generateAccessibilityProperties();
      return renderWithBreakpoint(function (breakpoint) {
        return React.createElement(FileUploadFieldInternal, {
          id: id,
          testId: testId,
          fieldUniqueId: _this3.fieldUniqueId,
          onChange: _this3.handleChange,
          breakpoint: breakpoint,
          onFileClear: _this3.handleClearFile,
          onUpload: onUpload,
          value: value,
          maxLength: maxLength,
          disabled: disabled,
          readonly: readonly,
          controlClassName: controlClassName,
          required: required,
          accept: accept,
          messageProps: messageProps,
          type: type,
          imageSource: imageSource,
          buttonType: buttonType,
          buttonSize: buttonSize,
          buttonIcon: buttonIcon,
          disableDragAndDrop: disableDragAndDrop,
          uploadState: uploadState,
          onCancel: onCancel,
          completed: completed,
          total: total,
          onInputFocus: onFocus,
          onInputBlur: onBlur,
          renderValidationMessages: renderValidationMessages,
          dataPath: dataPath,
          inputA11yProps: inputA11yProps,
          maxNumberOfFiles: maxNumberOfFiles,
          maxFileSizeKB: maxFileSizeKB,
          uploadErrors: uploadErrors
        });
      });
    }
  }]), FileUploadField;
}();
_defineProperty(FileUploadField, "displayName", "FileUploadField"), _defineProperty(FileUploadField, "propTypes", _objectSpread(_objectSpread({}, FieldComponent.propTypes), {}, {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, validationMessageShape]))]),
  type: deprecated(PropTypes.oneOf(["thin", "large"]).isRequired, void 0, "type prop should not be set to thin value on desktop. In the future FileUploadField component will automatically set large type for desktop and thin for mobile devices."),
  imageSource: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, validationMessageShape]))]),
  maxFileSizeKB: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, validationMessageShape]))]),
  onUpload: PropTypes.func,
  messageProps: PropTypes.shape({
    emptyUploadAreaMessage: intlMessageShape,
    uploadFilesMessage: intlMessageShape,
    uploadedFilesMessage: intlMessageShape,
    removeFileMessage: intlMessageShape,
    maxLengthMessage: intlMessageShape,
    maxFileSizeKBMessage: intlMessageShape,
    incorrectFileTypeMessage: intlMessageShape,
    uploadSelectedMessage: intlMessageShape,
    uploadProgressMessage: intlMessageShape,
    uploadCompletedMessage: intlMessageShape,
    uploadCancelledMessage: deprecated(intlMessageShape, void 0, "cancel state will not be shown in the future."),
    uploadFailedMessage: intlMessageShape,
    backgroundUploadFileMessage: intlMessageShape,
    placeholder: intlMessageShape
  }),
  buttonType: PropTypes.oneOf(["filled", "outlined", "text"]),
  buttonSize: PropTypes.oneOf(["small", "medium"]),
  buttonIcon: PropTypes.string,
  disableDragAndDrop: deprecated(PropTypes.bool, void 0, "disableDragAndDrop prop should not be set to false on desktop. In the future FileUploadField component will automatically set drag and drop area for desktop and disable drag and drop for mobile devices."),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(PropTypes.number)]),
  completed: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(PropTypes.number)]),
  onCancel: deprecated(PropTypes.func, void 0, "Use onFileClear instead. onCancel will be removed in the future together will all indicators of cancelled state."),
  onFileClear: PropTypes.func,
  uploadState: PropTypes.oneOfType([PropTypes.oneOf(["selection", "progress", "completed", "cancelled", "failed"]), PropTypes.objectOf(PropTypes.oneOf(["selection", "progress", "completed", "cancelled", "failed"]))]),
  progressErrorMessage: intlMessageShape,
  uploadError: PropTypes.oneOfType([booleanValidationRuleShape, PropTypes.objectOf(booleanValidationRuleShape)]),
  maxNumberOfFiles: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["unlimited"])]),
  uploadErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
})), _defineProperty(FileUploadField, "defaultProps", _objectSpread(_objectSpread({}, FieldComponent.defaultProps), {}, {
  controlClassName: styles.fileUploadField,
  type: TYPE_THIN,
  placeholder: messages.placeholder,
  buttonType: "outlined",
  buttonSize: "small",
  maxNumberOfFiles: 1
})), _defineProperty(FileUploadField, "contextType", FieldComponent.contextType), FileUploadField.__docgenInfo = {
  description: "Renders a control which allows the user to upload a file. Allows you to specify validation and actions for specific events.\n\n@metadataType field",
  methods: [{
    name: "handleErrorStateChange",
    docblock: null,
    modifiers: [],
    params: [{
      name: "prevErrorState",
      type: {
        name: "unknown"
      }
    }, {
      name: "errorState",
      type: {
        name: "unknown"
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    }
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      type: {
        name: "Array",
        elements: [{
          name: "unknown"
        }],
        raw: "unknown[]"
      }
    }],
    returns: {
      type: {
        name: "unknown"
      }
    }
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.",
    modifiers: [],
    params: [{
      name: "e",
      type: {
        name: "union",
        raw: "React.ChangeEvent | React.DragEvent | DataTransfer | File[]",
        elements: [{
          name: "ReactChangeEvent",
          raw: "React.ChangeEvent"
        }, {
          name: "ReactDragEvent",
          raw: "React.DragEvent"
        }, {
          name: "DataTransfer"
        }, {
          name: "Array",
          elements: [{
            name: "File"
          }],
          raw: "File[]"
        }]
      }
    }, {
      name: "isMultipleUpload",
      optional: !0,
      type: {
        name: "boolean"
      }
    }, {
      name: "multipleErrorMessages",
      optional: !0,
      type: {
        name: "Array",
        elements: [{
          name: "string"
        }],
        raw: "string[]"
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    },
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns Validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "Validation messages",
      type: {
        name: "union",
        raw: "unknown[] | undefined",
        elements: [{
          name: "Array",
          elements: [{
            name: "unknown"
          }],
          raw: "unknown[]"
        }, {
          name: "undefined"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: {
        name: "intersection",
        raw: "Omit<\n    PropTypes.InferProps<typeof FileUploadField.propTypes>,\n    keyof OwnFileUploadFieldProps\n> &\n    OwnFileUploadFieldProps & {\n        phone: OwnFileUploadFieldProps;\n        phoneWide: OwnFileUploadFieldProps;\n        tablet: OwnFileUploadFieldProps;\n    }",
        elements: [{
          name: "Omit",
          elements: [{
            name: "PropTypes.InferProps",
            elements: [{}],
            raw: "PropTypes.InferProps<typeof FileUploadField.propTypes>"
          }, {
            name: "unknown"
          }],
          raw: "Omit<\n    PropTypes.InferProps<typeof FileUploadField.propTypes>,\n    keyof OwnFileUploadFieldProps\n>"
        }, {
          name: "intersection",
          raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
          elements: [{
            name: "Pick",
            elements: [{
              name: "FileUploadCommonProps"
            }, {
              name: "union",
              raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
              elements: [{
                name: "literal",
                value: "'id'"
              }, {
                name: "literal",
                value: "'testId'"
              }, {
                name: "literal",
                value: "'value'"
              }, {
                name: "literal",
                value: "'maxLength'"
              }, {
                name: "literal",
                value: "'disabled'"
              }, {
                name: "literal",
                value: "'readonly'"
              }, {
                name: "literal",
                value: "'controlClassName'"
              }, {
                name: "literal",
                value: "'required'"
              }, {
                name: "literal",
                value: "'accept'"
              }, {
                name: "literal",
                value: "'messageProps'"
              }, {
                name: "literal",
                value: "'type'"
              }, {
                name: "literal",
                value: "'imageSource'"
              }, {
                name: "literal",
                value: "'buttonType'"
              }, {
                name: "literal",
                value: "'buttonSize'"
              }, {
                name: "literal",
                value: "'buttonIcon'"
              }, {
                name: "literal",
                value: "'disableDragAndDrop'"
              }, {
                name: "literal",
                value: "'uploadState'"
              }, {
                name: "literal",
                value: "'onCancel'"
              }, {
                name: "literal",
                value: "'completed'"
              }, {
                name: "literal",
                value: "'total'"
              }, {
                name: "literal",
                value: "'onUpload'"
              }, {
                name: "literal",
                value: "'maxNumberOfFiles'"
              }, {
                name: "literal",
                value: "'dataPath'"
              }, {
                name: "literal",
                value: "'maxFileSizeKB'"
              }, {
                name: "literal",
                value: "'onFileClear'"
              }]
            }],
            raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
          }, {
            name: "signature",
            type: "object",
            raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
            signature: {
              properties: [{
                key: "uploadError",
                value: {
                  name: "BooleanPropValidationShape",
                  required: !1
                }
              }, {
                key: "progressErrorMessage",
                value: {
                  name: "IntlMessageShape",
                  required: !1
                }
              }, {
                key: "uploadErrors",
                value: {
                  name: "Record",
                  elements: [{
                    name: "string"
                  }, {
                    name: "Array",
                    elements: [{
                      name: "string"
                    }],
                    raw: "string[]"
                  }],
                  raw: "Record<string, string[]>",
                  required: !1
                }
              }]
            }
          }],
          required: !0
        }, {
          name: "signature",
          type: "object",
          raw: "{\n    phone: OwnFileUploadFieldProps;\n    phoneWide: OwnFileUploadFieldProps;\n    tablet: OwnFileUploadFieldProps;\n}",
          signature: {
            properties: [{
              key: "phone",
              value: {
                name: "intersection",
                raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                elements: [{
                  name: "Pick",
                  elements: [{
                    name: "FileUploadCommonProps"
                  }, {
                    name: "union",
                    raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
                    elements: [{
                      name: "literal",
                      value: "'id'"
                    }, {
                      name: "literal",
                      value: "'testId'"
                    }, {
                      name: "literal",
                      value: "'value'"
                    }, {
                      name: "literal",
                      value: "'maxLength'"
                    }, {
                      name: "literal",
                      value: "'disabled'"
                    }, {
                      name: "literal",
                      value: "'readonly'"
                    }, {
                      name: "literal",
                      value: "'controlClassName'"
                    }, {
                      name: "literal",
                      value: "'required'"
                    }, {
                      name: "literal",
                      value: "'accept'"
                    }, {
                      name: "literal",
                      value: "'messageProps'"
                    }, {
                      name: "literal",
                      value: "'type'"
                    }, {
                      name: "literal",
                      value: "'imageSource'"
                    }, {
                      name: "literal",
                      value: "'buttonType'"
                    }, {
                      name: "literal",
                      value: "'buttonSize'"
                    }, {
                      name: "literal",
                      value: "'buttonIcon'"
                    }, {
                      name: "literal",
                      value: "'disableDragAndDrop'"
                    }, {
                      name: "literal",
                      value: "'uploadState'"
                    }, {
                      name: "literal",
                      value: "'onCancel'"
                    }, {
                      name: "literal",
                      value: "'completed'"
                    }, {
                      name: "literal",
                      value: "'total'"
                    }, {
                      name: "literal",
                      value: "'onUpload'"
                    }, {
                      name: "literal",
                      value: "'maxNumberOfFiles'"
                    }, {
                      name: "literal",
                      value: "'dataPath'"
                    }, {
                      name: "literal",
                      value: "'maxFileSizeKB'"
                    }, {
                      name: "literal",
                      value: "'onFileClear'"
                    }]
                  }],
                  raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
                }, {
                  name: "signature",
                  type: "object",
                  raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                  signature: {
                    properties: [{
                      key: "uploadError",
                      value: {
                        name: "BooleanPropValidationShape",
                        required: !1
                      }
                    }, {
                      key: "progressErrorMessage",
                      value: {
                        name: "IntlMessageShape",
                        required: !1
                      }
                    }, {
                      key: "uploadErrors",
                      value: {
                        name: "Record",
                        elements: [{
                          name: "string"
                        }, {
                          name: "Array",
                          elements: [{
                            name: "string"
                          }],
                          raw: "string[]"
                        }],
                        raw: "Record<string, string[]>",
                        required: !1
                      }
                    }]
                  }
                }],
                required: !0
              }
            }, {
              key: "phoneWide",
              value: {
                name: "intersection",
                raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                elements: [{
                  name: "Pick",
                  elements: [{
                    name: "FileUploadCommonProps"
                  }, {
                    name: "union",
                    raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
                    elements: [{
                      name: "literal",
                      value: "'id'"
                    }, {
                      name: "literal",
                      value: "'testId'"
                    }, {
                      name: "literal",
                      value: "'value'"
                    }, {
                      name: "literal",
                      value: "'maxLength'"
                    }, {
                      name: "literal",
                      value: "'disabled'"
                    }, {
                      name: "literal",
                      value: "'readonly'"
                    }, {
                      name: "literal",
                      value: "'controlClassName'"
                    }, {
                      name: "literal",
                      value: "'required'"
                    }, {
                      name: "literal",
                      value: "'accept'"
                    }, {
                      name: "literal",
                      value: "'messageProps'"
                    }, {
                      name: "literal",
                      value: "'type'"
                    }, {
                      name: "literal",
                      value: "'imageSource'"
                    }, {
                      name: "literal",
                      value: "'buttonType'"
                    }, {
                      name: "literal",
                      value: "'buttonSize'"
                    }, {
                      name: "literal",
                      value: "'buttonIcon'"
                    }, {
                      name: "literal",
                      value: "'disableDragAndDrop'"
                    }, {
                      name: "literal",
                      value: "'uploadState'"
                    }, {
                      name: "literal",
                      value: "'onCancel'"
                    }, {
                      name: "literal",
                      value: "'completed'"
                    }, {
                      name: "literal",
                      value: "'total'"
                    }, {
                      name: "literal",
                      value: "'onUpload'"
                    }, {
                      name: "literal",
                      value: "'maxNumberOfFiles'"
                    }, {
                      name: "literal",
                      value: "'dataPath'"
                    }, {
                      name: "literal",
                      value: "'maxFileSizeKB'"
                    }, {
                      name: "literal",
                      value: "'onFileClear'"
                    }]
                  }],
                  raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
                }, {
                  name: "signature",
                  type: "object",
                  raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                  signature: {
                    properties: [{
                      key: "uploadError",
                      value: {
                        name: "BooleanPropValidationShape",
                        required: !1
                      }
                    }, {
                      key: "progressErrorMessage",
                      value: {
                        name: "IntlMessageShape",
                        required: !1
                      }
                    }, {
                      key: "uploadErrors",
                      value: {
                        name: "Record",
                        elements: [{
                          name: "string"
                        }, {
                          name: "Array",
                          elements: [{
                            name: "string"
                          }],
                          raw: "string[]"
                        }],
                        raw: "Record<string, string[]>",
                        required: !1
                      }
                    }]
                  }
                }],
                required: !0
              }
            }, {
              key: "tablet",
              value: {
                name: "intersection",
                raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                elements: [{
                  name: "Pick",
                  elements: [{
                    name: "FileUploadCommonProps"
                  }, {
                    name: "union",
                    raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
                    elements: [{
                      name: "literal",
                      value: "'id'"
                    }, {
                      name: "literal",
                      value: "'testId'"
                    }, {
                      name: "literal",
                      value: "'value'"
                    }, {
                      name: "literal",
                      value: "'maxLength'"
                    }, {
                      name: "literal",
                      value: "'disabled'"
                    }, {
                      name: "literal",
                      value: "'readonly'"
                    }, {
                      name: "literal",
                      value: "'controlClassName'"
                    }, {
                      name: "literal",
                      value: "'required'"
                    }, {
                      name: "literal",
                      value: "'accept'"
                    }, {
                      name: "literal",
                      value: "'messageProps'"
                    }, {
                      name: "literal",
                      value: "'type'"
                    }, {
                      name: "literal",
                      value: "'imageSource'"
                    }, {
                      name: "literal",
                      value: "'buttonType'"
                    }, {
                      name: "literal",
                      value: "'buttonSize'"
                    }, {
                      name: "literal",
                      value: "'buttonIcon'"
                    }, {
                      name: "literal",
                      value: "'disableDragAndDrop'"
                    }, {
                      name: "literal",
                      value: "'uploadState'"
                    }, {
                      name: "literal",
                      value: "'onCancel'"
                    }, {
                      name: "literal",
                      value: "'completed'"
                    }, {
                      name: "literal",
                      value: "'total'"
                    }, {
                      name: "literal",
                      value: "'onUpload'"
                    }, {
                      name: "literal",
                      value: "'maxNumberOfFiles'"
                    }, {
                      name: "literal",
                      value: "'dataPath'"
                    }, {
                      name: "literal",
                      value: "'maxFileSizeKB'"
                    }, {
                      name: "literal",
                      value: "'onFileClear'"
                    }]
                  }],
                  raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
                }, {
                  name: "signature",
                  type: "object",
                  raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                  signature: {
                    properties: [{
                      key: "uploadError",
                      value: {
                        name: "BooleanPropValidationShape",
                        required: !1
                      }
                    }, {
                      key: "progressErrorMessage",
                      value: {
                        name: "IntlMessageShape",
                        required: !1
                      }
                    }, {
                      key: "uploadErrors",
                      value: {
                        name: "Record",
                        elements: [{
                          name: "string"
                        }, {
                          name: "Array",
                          elements: [{
                            name: "string"
                          }],
                          raw: "string[]"
                        }],
                        raw: "Record<string, string[]>",
                        required: !1
                      }
                    }]
                  }
                }],
                required: !0
              }
            }]
          }
        }],
        alias: "FileUploadFieldProps"
      }
    }],
    returns: {
      type: {
        name: "ReactReactElement",
        raw: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "handleClearFile",
    docblock: null,
    modifiers: [],
    params: [{
      name: "fileName",
      optional: !0,
      type: {
        name: "string"
      }
    }],
    returns: {
      type: {
        name: "void"
      }
    }
  }, {
    name: "renderControl",
    docblock: "Render control for this component.",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      type: {
        name: "intersection",
        raw: "Omit<\n    PropTypes.InferProps<typeof FileUploadField.propTypes>,\n    keyof OwnFileUploadFieldProps\n> &\n    OwnFileUploadFieldProps & {\n        phone: OwnFileUploadFieldProps;\n        phoneWide: OwnFileUploadFieldProps;\n        tablet: OwnFileUploadFieldProps;\n    }",
        elements: [{
          name: "Omit",
          elements: [{
            name: "PropTypes.InferProps",
            elements: [{}],
            raw: "PropTypes.InferProps<typeof FileUploadField.propTypes>"
          }, {
            name: "unknown"
          }],
          raw: "Omit<\n    PropTypes.InferProps<typeof FileUploadField.propTypes>,\n    keyof OwnFileUploadFieldProps\n>"
        }, {
          name: "intersection",
          raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
          elements: [{
            name: "Pick",
            elements: [{
              name: "FileUploadCommonProps"
            }, {
              name: "union",
              raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
              elements: [{
                name: "literal",
                value: "'id'"
              }, {
                name: "literal",
                value: "'testId'"
              }, {
                name: "literal",
                value: "'value'"
              }, {
                name: "literal",
                value: "'maxLength'"
              }, {
                name: "literal",
                value: "'disabled'"
              }, {
                name: "literal",
                value: "'readonly'"
              }, {
                name: "literal",
                value: "'controlClassName'"
              }, {
                name: "literal",
                value: "'required'"
              }, {
                name: "literal",
                value: "'accept'"
              }, {
                name: "literal",
                value: "'messageProps'"
              }, {
                name: "literal",
                value: "'type'"
              }, {
                name: "literal",
                value: "'imageSource'"
              }, {
                name: "literal",
                value: "'buttonType'"
              }, {
                name: "literal",
                value: "'buttonSize'"
              }, {
                name: "literal",
                value: "'buttonIcon'"
              }, {
                name: "literal",
                value: "'disableDragAndDrop'"
              }, {
                name: "literal",
                value: "'uploadState'"
              }, {
                name: "literal",
                value: "'onCancel'"
              }, {
                name: "literal",
                value: "'completed'"
              }, {
                name: "literal",
                value: "'total'"
              }, {
                name: "literal",
                value: "'onUpload'"
              }, {
                name: "literal",
                value: "'maxNumberOfFiles'"
              }, {
                name: "literal",
                value: "'dataPath'"
              }, {
                name: "literal",
                value: "'maxFileSizeKB'"
              }, {
                name: "literal",
                value: "'onFileClear'"
              }]
            }],
            raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
          }, {
            name: "signature",
            type: "object",
            raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
            signature: {
              properties: [{
                key: "uploadError",
                value: {
                  name: "BooleanPropValidationShape",
                  required: !1
                }
              }, {
                key: "progressErrorMessage",
                value: {
                  name: "IntlMessageShape",
                  required: !1
                }
              }, {
                key: "uploadErrors",
                value: {
                  name: "Record",
                  elements: [{
                    name: "string"
                  }, {
                    name: "Array",
                    elements: [{
                      name: "string"
                    }],
                    raw: "string[]"
                  }],
                  raw: "Record<string, string[]>",
                  required: !1
                }
              }]
            }
          }],
          required: !0
        }, {
          name: "signature",
          type: "object",
          raw: "{\n    phone: OwnFileUploadFieldProps;\n    phoneWide: OwnFileUploadFieldProps;\n    tablet: OwnFileUploadFieldProps;\n}",
          signature: {
            properties: [{
              key: "phone",
              value: {
                name: "intersection",
                raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                elements: [{
                  name: "Pick",
                  elements: [{
                    name: "FileUploadCommonProps"
                  }, {
                    name: "union",
                    raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
                    elements: [{
                      name: "literal",
                      value: "'id'"
                    }, {
                      name: "literal",
                      value: "'testId'"
                    }, {
                      name: "literal",
                      value: "'value'"
                    }, {
                      name: "literal",
                      value: "'maxLength'"
                    }, {
                      name: "literal",
                      value: "'disabled'"
                    }, {
                      name: "literal",
                      value: "'readonly'"
                    }, {
                      name: "literal",
                      value: "'controlClassName'"
                    }, {
                      name: "literal",
                      value: "'required'"
                    }, {
                      name: "literal",
                      value: "'accept'"
                    }, {
                      name: "literal",
                      value: "'messageProps'"
                    }, {
                      name: "literal",
                      value: "'type'"
                    }, {
                      name: "literal",
                      value: "'imageSource'"
                    }, {
                      name: "literal",
                      value: "'buttonType'"
                    }, {
                      name: "literal",
                      value: "'buttonSize'"
                    }, {
                      name: "literal",
                      value: "'buttonIcon'"
                    }, {
                      name: "literal",
                      value: "'disableDragAndDrop'"
                    }, {
                      name: "literal",
                      value: "'uploadState'"
                    }, {
                      name: "literal",
                      value: "'onCancel'"
                    }, {
                      name: "literal",
                      value: "'completed'"
                    }, {
                      name: "literal",
                      value: "'total'"
                    }, {
                      name: "literal",
                      value: "'onUpload'"
                    }, {
                      name: "literal",
                      value: "'maxNumberOfFiles'"
                    }, {
                      name: "literal",
                      value: "'dataPath'"
                    }, {
                      name: "literal",
                      value: "'maxFileSizeKB'"
                    }, {
                      name: "literal",
                      value: "'onFileClear'"
                    }]
                  }],
                  raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
                }, {
                  name: "signature",
                  type: "object",
                  raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                  signature: {
                    properties: [{
                      key: "uploadError",
                      value: {
                        name: "BooleanPropValidationShape",
                        required: !1
                      }
                    }, {
                      key: "progressErrorMessage",
                      value: {
                        name: "IntlMessageShape",
                        required: !1
                      }
                    }, {
                      key: "uploadErrors",
                      value: {
                        name: "Record",
                        elements: [{
                          name: "string"
                        }, {
                          name: "Array",
                          elements: [{
                            name: "string"
                          }],
                          raw: "string[]"
                        }],
                        raw: "Record<string, string[]>",
                        required: !1
                      }
                    }]
                  }
                }],
                required: !0
              }
            }, {
              key: "phoneWide",
              value: {
                name: "intersection",
                raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                elements: [{
                  name: "Pick",
                  elements: [{
                    name: "FileUploadCommonProps"
                  }, {
                    name: "union",
                    raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
                    elements: [{
                      name: "literal",
                      value: "'id'"
                    }, {
                      name: "literal",
                      value: "'testId'"
                    }, {
                      name: "literal",
                      value: "'value'"
                    }, {
                      name: "literal",
                      value: "'maxLength'"
                    }, {
                      name: "literal",
                      value: "'disabled'"
                    }, {
                      name: "literal",
                      value: "'readonly'"
                    }, {
                      name: "literal",
                      value: "'controlClassName'"
                    }, {
                      name: "literal",
                      value: "'required'"
                    }, {
                      name: "literal",
                      value: "'accept'"
                    }, {
                      name: "literal",
                      value: "'messageProps'"
                    }, {
                      name: "literal",
                      value: "'type'"
                    }, {
                      name: "literal",
                      value: "'imageSource'"
                    }, {
                      name: "literal",
                      value: "'buttonType'"
                    }, {
                      name: "literal",
                      value: "'buttonSize'"
                    }, {
                      name: "literal",
                      value: "'buttonIcon'"
                    }, {
                      name: "literal",
                      value: "'disableDragAndDrop'"
                    }, {
                      name: "literal",
                      value: "'uploadState'"
                    }, {
                      name: "literal",
                      value: "'onCancel'"
                    }, {
                      name: "literal",
                      value: "'completed'"
                    }, {
                      name: "literal",
                      value: "'total'"
                    }, {
                      name: "literal",
                      value: "'onUpload'"
                    }, {
                      name: "literal",
                      value: "'maxNumberOfFiles'"
                    }, {
                      name: "literal",
                      value: "'dataPath'"
                    }, {
                      name: "literal",
                      value: "'maxFileSizeKB'"
                    }, {
                      name: "literal",
                      value: "'onFileClear'"
                    }]
                  }],
                  raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
                }, {
                  name: "signature",
                  type: "object",
                  raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                  signature: {
                    properties: [{
                      key: "uploadError",
                      value: {
                        name: "BooleanPropValidationShape",
                        required: !1
                      }
                    }, {
                      key: "progressErrorMessage",
                      value: {
                        name: "IntlMessageShape",
                        required: !1
                      }
                    }, {
                      key: "uploadErrors",
                      value: {
                        name: "Record",
                        elements: [{
                          name: "string"
                        }, {
                          name: "Array",
                          elements: [{
                            name: "string"
                          }],
                          raw: "string[]"
                        }],
                        raw: "Record<string, string[]>",
                        required: !1
                      }
                    }]
                  }
                }],
                required: !0
              }
            }, {
              key: "tablet",
              value: {
                name: "intersection",
                raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n> & {\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                elements: [{
                  name: "Pick",
                  elements: [{
                    name: "FileUploadCommonProps"
                  }, {
                    name: "union",
                    raw: "| 'id'\n| 'testId'\n| 'value'\n| 'maxLength'\n| 'disabled'\n| 'readonly'\n| 'controlClassName'\n| 'required'\n| 'accept'\n| 'messageProps'\n| 'type'\n| 'imageSource'\n| 'buttonType'\n| 'buttonSize'\n| 'buttonIcon'\n| 'disableDragAndDrop'\n| 'uploadState'\n| 'onCancel'\n| 'completed'\n| 'total'\n| 'onUpload'\n| 'maxNumberOfFiles'\n| 'dataPath'\n| 'maxFileSizeKB'\n| 'onFileClear'",
                    elements: [{
                      name: "literal",
                      value: "'id'"
                    }, {
                      name: "literal",
                      value: "'testId'"
                    }, {
                      name: "literal",
                      value: "'value'"
                    }, {
                      name: "literal",
                      value: "'maxLength'"
                    }, {
                      name: "literal",
                      value: "'disabled'"
                    }, {
                      name: "literal",
                      value: "'readonly'"
                    }, {
                      name: "literal",
                      value: "'controlClassName'"
                    }, {
                      name: "literal",
                      value: "'required'"
                    }, {
                      name: "literal",
                      value: "'accept'"
                    }, {
                      name: "literal",
                      value: "'messageProps'"
                    }, {
                      name: "literal",
                      value: "'type'"
                    }, {
                      name: "literal",
                      value: "'imageSource'"
                    }, {
                      name: "literal",
                      value: "'buttonType'"
                    }, {
                      name: "literal",
                      value: "'buttonSize'"
                    }, {
                      name: "literal",
                      value: "'buttonIcon'"
                    }, {
                      name: "literal",
                      value: "'disableDragAndDrop'"
                    }, {
                      name: "literal",
                      value: "'uploadState'"
                    }, {
                      name: "literal",
                      value: "'onCancel'"
                    }, {
                      name: "literal",
                      value: "'completed'"
                    }, {
                      name: "literal",
                      value: "'total'"
                    }, {
                      name: "literal",
                      value: "'onUpload'"
                    }, {
                      name: "literal",
                      value: "'maxNumberOfFiles'"
                    }, {
                      name: "literal",
                      value: "'dataPath'"
                    }, {
                      name: "literal",
                      value: "'maxFileSizeKB'"
                    }, {
                      name: "literal",
                      value: "'onFileClear'"
                    }]
                  }],
                  raw: "Pick<\n    FileUploadCommonProps,\n    | 'id'\n    | 'testId'\n    | 'value'\n    | 'maxLength'\n    | 'disabled'\n    | 'readonly'\n    | 'controlClassName'\n    | 'required'\n    | 'accept'\n    | 'messageProps'\n    | 'type'\n    | 'imageSource'\n    | 'buttonType'\n    | 'buttonSize'\n    | 'buttonIcon'\n    | 'disableDragAndDrop'\n    | 'uploadState'\n    | 'onCancel'\n    | 'completed'\n    | 'total'\n    | 'onUpload'\n    | 'maxNumberOfFiles'\n    | 'dataPath'\n    | 'maxFileSizeKB'\n    | 'onFileClear'\n>"
                }, {
                  name: "signature",
                  type: "object",
                  raw: "{\n    // boolean validation rule from @jutro/validation\n    uploadError?: BooleanPropValidationShape;\n    progressErrorMessage?: IntlMessageShape;\n    // Per-file errors for multiple upload\n    uploadErrors?: Record<string, string[]>;\n}",
                  signature: {
                    properties: [{
                      key: "uploadError",
                      value: {
                        name: "BooleanPropValidationShape",
                        required: !1
                      }
                    }, {
                      key: "progressErrorMessage",
                      value: {
                        name: "IntlMessageShape",
                        required: !1
                      }
                    }, {
                      key: "uploadErrors",
                      value: {
                        name: "Record",
                        elements: [{
                          name: "string"
                        }, {
                          name: "Array",
                          elements: [{
                            name: "string"
                          }],
                          raw: "string[]"
                        }],
                        raw: "Record<string, string[]>",
                        required: !1
                      }
                    }]
                  }
                }],
                required: !0
              }
            }]
          }
        }],
        alias: "FileUploadFieldProps"
      }
    }],
    returns: {
      type: {
        name: "ReactReactNode",
        raw: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }],
  displayName: "FileUploadField",
  props: {
    controlClassName: {
      defaultValue: {
        value: "styles.fileUploadField",
        computed: !0
      },
      required: !1
    },
    type: {
      defaultValue: {
        value: "TYPE_THIN",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.oneOf(\n        // TYPE_THIN, TYPE_LARGE. Must be specified in this file due to docgen limitations.\n        ['thin', 'large']\n    ).isRequired,\n    undefined,\n    'type prop should not be set to thin value on desktop. In the future FileUploadField component will automatically set large type for desktop and thin for mobile devices.'\n)"
      },
      required: !1,
      description: "File upload field type ('thin', 'large'). Applies only for single file upload."
    },
    placeholder: {
      defaultValue: {
        value: "messages.placeholder",
        computed: !0
      },
      required: !1
    },
    buttonType: {
      defaultValue: {
        value: "'outlined'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'filled'",
          computed: !1
        }, {
          value: "'outlined'",
          computed: !1
        }, {
          value: "'text'",
          computed: !1
        }]
      },
      required: !1,
      description: "`type` prop for the rendered button"
    },
    buttonSize: {
      defaultValue: {
        value: "'small'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }]
      },
      required: !1,
      description: "`size` prop for the rendered button"
    },
    maxNumberOfFiles: {
      defaultValue: {
        value: "1",
        computed: !1
      },
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "enum",
          value: [{
            value: "'unlimited'",
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "Max number of files that can be added to file upload input. Default to 1 (single file upload). Pass 'unlimited' to not restrict number of files."
    },
    accept: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "File type filter (e.g., '.md,.pdf' or 'audio/*')"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to an image to show in drag and drop area"
    },
    maxLength: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Max length for the input field"
    },
    maxFileSizeKB: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Max file size in KB for the input field"
    },
    onUpload: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when valid file is selected"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          emptyUploadAreaMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for drag and drop",
            required: !1
          },
          uploadFilesMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for upload file",
            required: !1
          },
          uploadedFilesMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for uploaded file",
            required: !1
          },
          removeFileMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for removing file",
            required: !1
          },
          maxLengthMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for file name max length",
            required: !1
          },
          maxFileSizeKBMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for max file size",
            required: !1
          },
          incorrectFileTypeMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Error message to show if the file type is incorrect",
            required: !1
          },
          uploadSelectedMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message displayed when a file is selected",
            required: !1
          },
          uploadProgressMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message displayed while uploading",
            required: !1
          },
          uploadCompletedMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message displayed after the upload is complete",
            required: !1
          },
          uploadCancelledMessage: {
            name: "custom",
            raw: "deprecated(\n    intlMessageShape,\n    undefined,\n    'cancel state will not be shown in the future.'\n)",
            description: "Message displayed when the upload is cancelled",
            required: !1
          },
          uploadFailedMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message displayed when the upload failed to finish",
            required: !1
          },
          backgroundUploadFileMessage: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message displayed on the disabled button in the large field",
            required: !1
          },
          placeholder: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Message for the placeholder",
            required: !1
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    buttonIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "`icon` prop for the rendered button"
    },
    disableDragAndDrop: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.bool,\n    undefined,\n    'disableDragAndDrop prop should not be set to false on desktop. In the future FileUploadField component will automatically set drag and drop area for desktop and disable drag and drop for mobile devices.'\n)"
      },
      required: !1,
      description: "Disables the drag and drop functionality of the component. Applies only for single file upload."
    },
    total: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "objectOf",
          value: {
            name: "number"
          }
        }]
      },
      required: !1,
      description: "Total size in bytes of the selected file to upload"
    },
    completed: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "objectOf",
          value: {
            name: "number"
          }
        }]
      },
      required: !1,
      description: "Current bytes uploaded"
    },
    onCancel: {
      type: {
        name: "custom",
        raw: "deprecated(\n    PropTypes.func,\n    undefined,\n    'Use onFileClear instead. onCancel will be removed in the future together will all indicators of cancelled state.'\n)"
      },
      required: !1,
      description: "Action triggered when the user clicks the cancel button while uploading"
    },
    onFileClear: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Action triggered when the user clears the selected file"
    },
    uploadState: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'selection'",
            computed: !1
          }, {
            value: "'progress'",
            computed: !1
          }, {
            value: "'completed'",
            computed: !1
          }, {
            value: "'cancelled'",
            computed: !1
          }, {
            value: "'failed'",
            computed: !1
          }]
        }, {
          name: "objectOf",
          value: {
            name: "enum",
            value: [{
              value: "'selection'",
              computed: !1
            }, {
              value: "'progress'",
              computed: !1
            }, {
              value: "'completed'",
              computed: !1
            }, {
              value: "'cancelled'",
              computed: !1
            }, {
              value: "'failed'",
              computed: !1
            }]
          }
        }]
      },
      required: !1,
      description: "Current state of upload"
    },
    progressErrorMessage: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The user-defined error message to show on failed upload used by old validation",
      tsType: {
        name: "IntlMessageShape"
      }
    },
    uploadError: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "booleanValidationRuleShape"
        }, {
          name: "objectOf",
          value: {
            name: "custom",
            raw: "booleanValidationRuleShape"
          }
        }]
      },
      required: !1,
      description: "Upload server error defined as tuple [hasError, errorMessage]",
      tsType: {
        name: "BooleanPropValidationShape"
      }
    },
    uploadErrors: {
      type: {
        name: "objectOf",
        value: {
          name: "arrayOf",
          value: {
            name: "string"
          }
        }
      },
      required: !1,
      description: "Extra per-file errors, if multiple file upload is enabled.",
      tsType: {
        name: "Record",
        elements: [{
          name: "string"
        }, {
          name: "Array",
          elements: [{
            name: "string"
          }],
          raw: "string[]"
        }],
        raw: "Record<string, string[]>"
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
}, FileUploadField.__docgenInfo = {
  componentName: "FileUploadField",
  packageName: "@jutro/components",
  description: "Renders a control which allows the user to upload a file. Allows you to specify validation and actions for specific events.",
  displayName: "FileUploadField",
  methods: [{
    name: "handleErrorStateChange",
    docblock: null,
    modifiers: [],
    params: [{
      name: "prevErrorState",
      optional: void 0,
      type: null
    }, {
      name: "errorState",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "getValidationConfig",
    docblock: null,
    modifiers: [],
    params: [{
      name: "...args",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.",
    modifiers: [],
    params: [{
      name: "e"
    }, {
      name: "isMultipleUpload"
    }, {
      name: "multipleErrorMessages"
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns Validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "Validation messages",
      type: null
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.",
    modifiers: [],
    params: [{
      name: "breakpointProps"
    }],
    returns: null,
    description: "Render readonly control for this component."
  }, {
    name: "handleClearFile",
    docblock: null,
    modifiers: [],
    params: [{
      name: "fileName",
      optional: void 0,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.",
    modifiers: [],
    params: [{
      name: "breakpointProps"
    }],
    returns: null,
    description: "Render control for this component."
  }],
  actualName: "FileUploadField",
  metadataType: "field",
  props: {
    accept: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "string"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "File type filter (e.g., '.md,.pdf' or 'audio/*')"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'thin'",
          computed: !1
        }, {
          value: "'large'",
          computed: !1
        }]
      },
      required: !1,
      description: "@deprecated File upload field type ('thin', 'large'). Applies only for single file upload.",
      defaultValue: {
        value: "'thin'",
        computed: !1
      },
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Path to an image to show in drag and drop area"
    },
    maxLength: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Max length for the input field"
    },
    maxFileSizeKB: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "arrayOf",
          value: {
            name: "union",
            value: [{
              name: "number"
            }, {
              name: "custom",
              raw: "validationMessageShape"
            }]
          }
        }]
      },
      required: !1,
      description: "Max file size in KB for the input field"
    },
    onUpload: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback when valid file is selected"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          emptyUploadAreaMessage: {
            name: "union",
            description: "Message for drag and drop",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadFilesMessage: {
            name: "union",
            description: "Message for upload file",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadedFilesMessage: {
            name: "union",
            description: "Message for uploaded file",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          removeFileMessage: {
            name: "union",
            description: "Message for removing file",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          maxLengthMessage: {
            name: "union",
            description: "Message for file name max length",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          maxFileSizeKBMessage: {
            name: "union",
            description: "Message for max file size",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          incorrectFileTypeMessage: {
            name: "union",
            description: "Error message to show if the file type is incorrect",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadSelectedMessage: {
            name: "union",
            description: "Message displayed when a file is selected",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadProgressMessage: {
            name: "union",
            description: "Message displayed while uploading",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadCompletedMessage: {
            name: "union",
            description: "Message displayed after the upload is complete",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadCancelledMessage: {
            name: "union",
            description: "@deprecated Message displayed when the upload is cancelled",
            required: !1,
            deprecationInfo: {
              version: "@next",
              mapTo: null
            },
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          uploadFailedMessage: {
            name: "union",
            description: "Message displayed when the upload failed to finish",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          backgroundUploadFileMessage: {
            name: "union",
            description: "Message displayed on the disabled button in the large field",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          },
          placeholder: {
            name: "union",
            description: "Message for the placeholder",
            required: !1,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: !1
                },
                defaultMessage: {
                  name: "string",
                  required: !1
                },
                args: {
                  name: "shape",
                  value: {},
                  required: !1
                }
              }
            }]
          }
        }
      },
      required: !1,
      description: "Message props(error message/aria-label)"
    },
    buttonType: {
      type: {
        name: "enum",
        value: [{
          value: "'filled'",
          computed: !1
        }, {
          value: "'outlined'",
          computed: !1
        }, {
          value: "'text'",
          computed: !1
        }]
      },
      required: !1,
      description: "`type` prop for the rendered button",
      defaultValue: {
        value: "'outlined'",
        computed: !1
      }
    },
    buttonSize: {
      type: {
        name: "enum",
        value: [{
          value: "'small'",
          computed: !1
        }, {
          value: "'medium'",
          computed: !1
        }]
      },
      required: !1,
      description: "`size` prop for the rendered button",
      defaultValue: {
        value: "'small'",
        computed: !1
      }
    },
    buttonIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "`icon` prop for the rendered button"
    },
    disableDragAndDrop: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "@deprecated Disables the drag and drop functionality of the component. Applies only for single file upload.",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    total: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "objectOf",
          value: {
            name: "number"
          }
        }]
      },
      required: !1,
      description: "Total size in bytes of the selected file to upload"
    },
    completed: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "objectOf",
          value: {
            name: "number"
          }
        }]
      },
      required: !1,
      description: "Current bytes uploaded"
    },
    onCancel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "@deprecated Action triggered when the user clicks the cancel button while uploading",
      deprecationInfo: {
        version: "@next",
        mapTo: null
      }
    },
    onFileClear: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Action triggered when the user clears the selected file"
    },
    uploadState: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'selection'",
            computed: !1
          }, {
            value: "'progress'",
            computed: !1
          }, {
            value: "'completed'",
            computed: !1
          }, {
            value: "'cancelled'",
            computed: !1
          }, {
            value: "'failed'",
            computed: !1
          }]
        }, {
          name: "objectOf",
          value: {
            name: "enum",
            value: [{
              value: "'selection'",
              computed: !1
            }, {
              value: "'progress'",
              computed: !1
            }, {
              value: "'completed'",
              computed: !1
            }, {
              value: "'cancelled'",
              computed: !1
            }, {
              value: "'failed'",
              computed: !1
            }]
          }
        }]
      },
      required: !1,
      description: "Current state of upload"
    },
    progressErrorMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The user-defined error message to show on failed upload used by old validation"
    },
    uploadError: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "booleanValidationRuleShape"
        }, {
          name: "objectOf",
          value: {
            name: "custom",
            raw: "booleanValidationRuleShape"
          }
        }]
      },
      required: !1,
      description: "Upload server error defined as tuple [hasError, errorMessage]"
    },
    maxNumberOfFiles: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "enum",
          value: [{
            value: "'unlimited'",
            computed: !1
          }]
        }]
      },
      required: !1,
      description: "Max number of files that can be added to file upload input. Default to 1 (single file upload). Pass 'unlimited' to not restrict number of files.",
      defaultValue: {
        value: "1",
        computed: !1
      }
    },
    uploadErrors: {
      type: {
        name: "objectOf",
        value: {
          name: "arrayOf",
          value: {
            name: "string"
          }
        }
      },
      required: !1,
      description: "Extra per-file errors, if multiple file upload is enabled."
    },
    controlClassName: {
      defaultValue: {
        value: "styles.fileUploadField",
        computed: !0
      },
      required: !1
    },
    placeholder: {
      defaultValue: {
        value: "messages.placeholder",
        computed: !0
      },
      required: !1
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};