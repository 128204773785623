import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime-corejs3/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import { isJsonLikeContentType } from "../helper";
import { CONTENT_TYPE } from "../common";
export function defaultErrorResponseHandler(_x) {
  return _defaultErrorResponseHandler.apply(this, arguments);
}
function _defaultErrorResponseHandler() {
  return (_defaultErrorResponseHandler = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(response) {
    var message, error, contentType;
    return _regeneratorRuntime.wrap(function (_context) {
      for (;;) switch (_context.prev = _context.next) {
        case 0:
          if (error = {
            status: response.status,
            statusText: response.statusText || "<none>"
          }, !(contentType = response.headers.get(CONTENT_TYPE))) {
            _context.next = 13;
            break;
          }
          if (!isJsonLikeContentType(contentType)) {
            _context.next = 9;
            break;
          }
          return _context.next = 6, response.json();
        case 6:
          message = _context.sent, _context.next = 13;
          break;
        case 9:
          return _context.next = 11, response.text();
        case 11:
          _context.t0 = _context.sent, message = {
            message: _context.t0
          };
        case 13:
          return _context.abrupt("return", _Promise.reject(_objectSpread(_objectSpread({}, error), message)));
        case 14:
        case "end":
          return _context.stop();
      }
    }, _callee);
  }))).apply(this, arguments);
}