import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import forEachRight from "lodash/forEachRight";
import { createContextConsumerHOC } from "@jutro/platform";
import { extendWithBreakpointValues, getBreakpointPxValue, getBreakpoints } from "./breakpoint.utils";
export var BreakpointTrackerContext = React.createContext("desktop");
export function withBreakpoint(ComponentToWrap) {
  return createContextConsumerHOC({
    component: ComponentToWrap,
    context: BreakpointTrackerContext,
    mapContextToProps: function (existingProps, breakpoint) {
      return breakpoint;
    }
  });
}
export function renderWithBreakpoint(render) {
  return React.createElement(BreakpointTrackerContext.Consumer, null, function (breakpoint) {
    return render(breakpoint);
  });
}
var BreakpointTracker = function (_Component) {
  _inherits(BreakpointTracker, Component);
  var _super = _createSuper(BreakpointTracker);
  function BreakpointTracker() {
    var _context, _this;
    _classCallCheck(this, BreakpointTracker);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
    return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "state", {}), _defineProperty(_assertThisInitialized(_this), "breakpoints", void 0), _defineProperty(_assertThisInitialized(_this), "resizeTimer", null), _defineProperty(_assertThisInitialized(_this), "throttleResize", function () {
      !_this.resizeTimer && _this.props.delayMs && (_this.resizeTimer = window.setTimeout(_this.handleResize, _this.props.delayMs));
    }), _defineProperty(_assertThisInitialized(_this), "handleResize", function () {
      _this.resizeTimer = null;
      var newBreakpoint = _this.getBreakpoint();
      _this.state.breakpoint !== newBreakpoint && _this.setState({
        breakpoint: newBreakpoint
      });
    }), _this;
  }
  return _createClass(BreakpointTracker, [{
    key: "componentDidMount",
    value: function () {
      this.breakpoints = getBreakpoints(), window.addEventListener("resize", this.throttleResize), window.addEventListener("orientationchange", this.handleResize), this.setState({
        breakpoint: this.getBreakpoint()
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function () {
      window.removeEventListener("resize", this.throttleResize), window.removeEventListener("orientationchange", this.handleResize);
    }
  }, {
    key: "getBreakpoint",
    value: function () {
      var _this2 = this,
        breakpoint = "desktop",
        breakpointNames = _Object$keys(this.breakpoints);
      return forEachRight(breakpointNames, function (breakpointName) {
        var breakpointValue = _this2.breakpoints[breakpointName];
        window.matchMedia && window.matchMedia("(max-width: ".concat(breakpointValue, "px)")).matches && (breakpoint = breakpointName);
      }), breakpoint;
    }
  }, {
    key: "render",
    value: function () {
      var breakpoint = this.state.breakpoint;
      return breakpoint ? React.createElement(BreakpointTrackerContext.Provider, {
        value: breakpoint
      }, this.props.children) : null;
    }
  }]), BreakpointTracker;
}();
_defineProperty(BreakpointTracker, "propTypes", {
  delayMs: PropTypes.number
}), _defineProperty(BreakpointTracker, "defaultProps", {
  delayMs: 50
}), _defineProperty(BreakpointTracker, "applyBreakpointOverrides", extendWithBreakpointValues), _defineProperty(BreakpointTracker, "getBreakpoints", getBreakpoints), _defineProperty(BreakpointTracker, "getBreakpointPxValue", getBreakpointPxValue);
export { BreakpointTracker as default };
renderWithBreakpoint.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "renderWithBreakpoint"
}, BreakpointTracker.__docgenInfo = {
  description: "",
  methods: [{
    name: "getBreakpoint",
    docblock: null,
    modifiers: [],
    params: [],
    returns: {
      type: {
        name: "DeviceType"
      }
    }
  }, {
    name: "throttleResize",
    docblock: null,
    modifiers: [],
    params: [],
    returns: {
      type: {
        name: "void"
      }
    }
  }, {
    name: "handleResize",
    docblock: null,
    modifiers: [],
    params: [],
    returns: {
      type: {
        name: "void"
      }
    }
  }],
  displayName: "BreakpointTracker",
  props: {
    delayMs: {
      defaultValue: {
        value: "50",
        computed: !1
      },
      type: {
        name: "number"
      },
      required: !1,
      description: ""
    }
  }
}, renderWithBreakpoint.__docgenInfo = {
  componentName: "renderWithBreakpoint",
  packageName: "@jutro/layout",
  description: "",
  displayName: "renderWithBreakpoint",
  methods: [],
  actualName: "renderWithBreakpoint"
}, BreakpointTracker.__docgenInfo = {
  componentName: "BreakpointTracker",
  packageName: "@jutro/layout",
  description: "",
  displayName: "BreakpointTracker",
  methods: [{
    name: "getBreakpoint",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "throttleResize",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleResize",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "BreakpointTracker",
  props: {
    delayMs: {
      type: {
        name: "number"
      },
      required: !1,
      description: "",
      defaultValue: {
        value: "50",
        computed: !1
      }
    }
  }
};