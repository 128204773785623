import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "className", "tag", "datatype", "path", "placeholder", "value", "onValueChange", "prefix", "suffix"];
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import React from "react";
import PropTypes from "prop-types";
import { isEmptyValue } from "@jutro/data";
import { intlMessageShape } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { DateValue } from "./DateValue";
import { CurrencyValue } from "./CurrencyValue";
import { NumberValue } from "./NumberValue";
import { FieldComponent } from "../inputs/FieldComponent/FieldComponent";
export var fieldValueComponentMap = {
  currency: {
    component: CurrencyValue
  },
  date: {
    component: DateValue
  },
  "date-time": {
    component: DateValue
  },
  decimal: {
    component: NumberValue,
    componentProps: {
      format: "decimal",
      showFractions: !0
    }
  },
  integer: {
    component: NumberValue,
    componentProps: {
      format: "decimal",
      showFractions: !1
    }
  },
  number: {
    component: NumberValue,
    componentProps: {
      format: "decimal",
      showFractions: !0
    }
  },
  percent: {
    component: NumberValue,
    componentProps: {
      format: "percent",
      showFractions: !1
    }
  },
  year: {
    component: NumberValue
  }
};
var fieldValuePropTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  datatype: PropTypes.string,
  path: PropTypes.string,
  placeholder: intlMessageShape,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
  prefix: intlMessageShape,
  suffix: intlMessageShape,
  tag: PropTypes.string
};
export var FieldValue = function (props) {
  var id = props.id,
    className = props.className,
    Tag = props.tag,
    datatype = props.datatype,
    placeholder = (props.path, props.placeholder),
    value = props.value,
    prefix = (props.onValueChange, props.prefix),
    suffix = props.suffix,
    other = _objectWithoutProperties(props, _excluded),
    translator = useTranslator(),
    componentInfo = fieldValueComponentMap[datatype];
  if (!componentInfo) {
    return React.createElement(Tag, {
      id: id,
      title: value,
      className: className,
      "data-read-only": !0
    }, prefix && translator(prefix), isEmptyValue(value) ? translator(placeholder) : value instanceof Object ? _JSON$stringify(value) : value.toString(), suffix && translator(suffix));
  }
  var ValueComponent = componentInfo.component,
    componentProps = componentInfo.componentProps;
  return React.createElement(ValueComponent, _extends({}, componentProps, {
    id: id,
    className: className,
    tag: Tag,
    placeholder: placeholder,
    value: value,
    prefix: prefix,
    suffix: suffix
  }, other));
};
FieldValue.propTypes = fieldValuePropTypes, FieldValue.defaultProps = {
  tag: "span",
  placeholder: FieldComponent.defaultROEmptyValue
}, FieldValue.__docgenInfo = {
  description: "Renders a formatted value using the `tag` and `datatype` property to wrap\nthe value.\n\n@type {React.FC<PropTypes.InferProps<typeof fieldValuePropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FieldValue",
  props: {
    tag: {
      defaultValue: {
        value: "'span'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "The html tag to use when rendering the outermost element of this component"
    },
    placeholder: {
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The string to display if 'value' is undefined/null"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    datatype: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint for the datatype of the value"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint path to value"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "object"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "The value to display"
    },
    prefix: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Optional suffix message to be attached at the end the value"
    }
  }
}, FieldValue.__docgenInfo = {
  componentName: "FieldValue",
  packageName: "@jutro/components",
  description: "Renders a formatted value using the `tag` and `datatype` property to wrap\nthe value.",
  displayName: "FieldValue",
  methods: [],
  actualName: "FieldValue",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    datatype: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint for the datatype of the value"
    },
    path: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Hint path to value"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The string to display if 'value' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "object"
        }, {
          name: "bool"
        }]
      },
      required: !1,
      description: "The value to display"
    },
    prefix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Optional suffix message to be attached at the end the value"
    },
    tag: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'span'",
        computed: !1
      }
    }
  }
};