import React, { memo } from "react";
import { Route } from "react-router-dom";
import { AuthRoute } from "./AuthRoute";
var RootRouteInternal = function (props) {
  return "authEnabled" in props && props.authEnabled ? React.createElement(AuthRoute, {
    path: props.path,
    render: props.render,
    authErrorComponent: props.authErrorComponent,
    authComponent: props.authComponent
  }) : React.createElement(Route, {
    path: props.path,
    render: props.render
  });
};
RootRouteInternal.displayName = "RootRoute";
export var RootRoute = memo(RootRouteInternal);
RootRouteInternal.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "RootRoute"
}, RootRouteInternal.__docgenInfo = {
  componentName: "RootRoute",
  packageName: "@jutro/app",
  description: "",
  displayName: "RootRoute",
  methods: [],
  actualName: "RootRouteInternal"
};