import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import { log } from "@jutro/logger";
export function applyHOCs(body, rootHOCs) {
  var _context;
  return _reduceInstanceProperty(_context = function (rootHOCs) {
    var hocs = [];
    return rootHOCs && (isArray(rootHOCs) ? hocs = _toConsumableArray(rootHOCs) : hocs.push(rootHOCs)), hocs;
  }(rootHOCs)).call(_context, function (jsx, hocFunc) {
    return isFunction(hocFunc) ? hocFunc(jsx) : (log.warning("rootHOC is not a function. HOC will not be applied"), jsx);
  }, body);
}