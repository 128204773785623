import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { forwardRef, isValidElement } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isString from "lodash/isString";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import styles from "./InfoLabel.module.css";
import { Icon } from "../Icon/Icon";
import { InlineLabel } from "../InlineLabel/InlineLabel";
export var availableInfoLabelTypes = ["success", "error", "warning", "info", "neutral"];
var infoLabelPropTypes = {
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  id: PropTypes.string,
  message: intlMessageShape,
  type: PropTypes.oneOf(availableInfoLabelTypes)
};
export var InfoLabel = forwardRef(function (_ref, ref) {
  var children = _ref.children,
    className = _ref.className,
    icon = _ref.icon,
    iconPosition = _ref.iconPosition,
    id = _ref.id,
    message = _ref.message,
    type = _ref.type,
    translator = useTranslator(),
    typeClassName = type && _includesInstanceProperty(availableInfoLabelTypes).call(availableInfoLabelTypes, type) ? type : "info",
    content = children || translator(message),
    hasOnlyIcon = icon && !content,
    hasOnlyShortContent = !icon && function (content) {
      return isString(content) && 1 === content.length;
    }(content),
    classes = cx(styles.infoLabel, styles[typeClassName], _defineProperty({}, styles.simple, hasOnlyIcon || hasOnlyShortContent), className),
    iconClasses = cx(styles.icon, _defineProperty({}, styles.rightIcon, "right" === iconPosition)),
    iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: iconClasses
    });
  return React.createElement(InlineLabel, {
    id: id,
    icon: iconComponent,
    iconPosition: iconPosition,
    className: classes,
    ref: ref
  }, React.createElement("span", null, isValidElement(content) ? content : translator(content)));
});
InfoLabel.propTypes = infoLabelPropTypes, InfoLabel.defaultProps = {
  iconPosition: "left",
  type: "info"
}, InfoLabel.displayName = "InfoLabel", InfoLabel.__docgenInfo = {
  description: 'Displays an "InfoLabel" element with child components, such as text or images.\n\n@metadataType element',
  methods: [],
  displayName: "InfoLabel",
  props: {
    iconPosition: {
      defaultValue: {
        value: "'left'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text",
      tsType: {
        name: "union",
        raw: "'left' | 'right'",
        elements: [{
          name: "literal",
          value: "'left'"
        }, {
          name: "literal",
          value: "'right'"
        }]
      }
    },
    type: {
      defaultValue: {
        value: "'info'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'info'",
          computed: !1
        }, {
          value: "'neutral'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set custom flavor (success, info, neutral, etc.)",
      tsType: {
        name: "union",
        raw: "| 'success'\n| 'error'\n| 'warning'\n| 'info'\n| 'neutral'",
        elements: [{
          name: "literal",
          value: "'success'"
        }, {
          name: "literal",
          value: "'error'"
        }, {
          name: "literal",
          value: "'warning'"
        }, {
          name: "literal",
          value: "'info'"
        }, {
          name: "literal",
          value: "'neutral'"
        }]
      }
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "custom",
          raw: "intlMessageShape"
        }]
      },
      required: !1,
      description: "The children elements to render inside the InfoLabel",
      tsType: {
        name: "union",
        raw: "React.ReactNode | IntlMessageShape",
        elements: [{
          name: "ReactReactNode",
          raw: "React.ReactNode"
        }, {
          name: "IntlMessageShape"
        }]
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component",
      tsType: {
        name: "string"
      }
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The short-name of the optional Material icons",
      tsType: {
        name: "string"
      }
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component unique identifier",
      tsType: {
        name: "string"
      }
    },
    message: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Label message that is either a string or an object representing internationalized message ({id: `key`, defaultMessage: `default text`})\nwill be shown when children are not provided",
      tsType: {
        name: "IntlMessageShape"
      }
    }
  }
}, InfoLabel.__docgenInfo = {
  componentName: "InfoLabel",
  packageName: "@jutro/components",
  description: 'Displays an "InfoLabel" element with child components, such as text or images.',
  displayName: "InfoLabel",
  methods: [],
  actualName: "InfoLabel",
  metadataType: "element",
  props: {
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: !1
              },
              defaultMessage: {
                name: "string",
                required: !1
              },
              args: {
                name: "shape",
                value: {},
                required: !1
              }
            }
          }]
        }]
      },
      required: !1,
      description: "The children elements to render inside the InfoLabel"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The short-name of the optional Material icons"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }]
      },
      required: !1,
      description: "Where the icon is placed relative to the text",
      defaultValue: {
        value: "'left'",
        computed: !1
      }
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component unique identifier"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Label message that is either a string or an object representing internationalized message ({id: `key`, defaultMessage: `default text`})\nwill be shown when children are not provided"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'info'",
          computed: !1
        }, {
          value: "'neutral'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set custom flavor (success, info, neutral, etc.)",
      defaultValue: {
        value: "'info'",
        computed: !1
      }
    }
  }
};