import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React from "react";
import PropTypes from "prop-types";
import { Button, DropdownMenu, DropdownMenuLink } from "@jutro/components";
import { uniqueInnerId } from "@jutro/platform";
import styles from "./Pagination.module.css";
export var PaginationSelect = function (_ref) {
  var availableValues = _ref.availableValues,
    displayValue = _ref.displayValue,
    id = _ref.id,
    isOpen = _ref.isOpen,
    onValueChange = _ref.onValueChange,
    value = _ref.value,
    handleClick = function (evt) {
      evt.preventDefault();
      var menuCode = evt.currentTarget.dataset.code;
      onValueChange(menuCode);
    },
    options = _mapInstanceProperty(availableValues).call(availableValues, function (_ref3) {
      var code = _ref3.code,
        name = _ref3.name;
      return React.createElement(DropdownMenuLink, {
        key: code,
        value: code,
        onClick: handleClick,
        className: styles.paginationMenuItem,
        "data-code": code,
        disabled: code === value
      }, name);
    });
  return React.createElement(DropdownMenu, {
    id: "paginationSelectDropdownMenu-".concat(id),
    isOpen: isOpen,
    renderTrigger: function (props, toggleMenu) {
      var _context,
        paginationId = props.id,
        paginationSelectButtonId = uniqueInnerId(paginationId, "paginationSelectButtonId").paginationSelectButtonId;
      return React.createElement(Button, {
        id: "".concat(paginationSelectButtonId, "_paginationSelectButton"),
        type: "text",
        onClick: function () {
          return toggleMenu();
        },
        icon: displayValue ? void 0 : "gw-expand-more",
        iconPosition: "right",
        className: styles.paginationSelect,
        "aria-expanded": props.isOpen,
        "data-testid": "pagination-select",
        ref: props.ref
      }, displayValue || _mapInstanceProperty(_context = _filterInstanceProperty(availableValues).call(availableValues, function (_ref2) {
        return _ref2.code === value;
      })).call(_context, function (o) {
        return o.name.defaultMessage || o.name;
      }));
    },
    menuClassName: styles.paginationMenu
  }, options);
};
PaginationSelect.propTypes = {
  id: PropTypes.string,
  availableValues: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  isOpen: PropTypes.bool,
  displayValue: PropTypes.string
}, PaginationSelect.defaultProps = {
  isOpen: !1
}, PaginationSelect.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "PaginationSelect",
  props: {
    isOpen: {
      defaultValue: {
        value: "false",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the pagination select menu is currently visible"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    availableValues: {
      type: {
        name: "array"
      },
      required: !0,
      description: "Array of choice objects to display; choice objects contains 'code' and 'name'"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle selection change"
    },
    displayValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "If set, replaces text on the select triggering button"
    }
  }
}, PaginationSelect.__docgenInfo = {
  componentName: "PaginationSelect",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "PaginationSelect",
  methods: [],
  actualName: "PaginationSelect",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to identify the component"
    },
    availableValues: {
      type: {
        name: "array"
      },
      required: !0,
      description: "Array of choice objects to display; choice objects contains 'code' and 'name'"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback used to handle selection change"
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the pagination select menu is currently visible",
      defaultValue: {
        value: "false",
        computed: !1
      }
    },
    displayValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "If set, replaces text on the select triggering button"
    }
  }
};