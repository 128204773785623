import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";
import { placementOptions } from "@jutro/prop-types";
import { useTranslator } from "@jutro/locale";
import { Tooltip } from "../Tooltip/Tooltip";
import styles from "./InlineNotification.module.css";
import { Link } from "../Link/Link";
export var InlineNotificationTooltip = function (_ref) {
  var title = _ref.title,
    message = _ref.message,
    id = _ref.id,
    linkProps = _ref.linkProps,
    isTooltipEnabled = _ref.isTooltipEnabled,
    textElementRef = useRef(null),
    _useState = useState(!1),
    _useState2 = _slicedToArray(_useState, 2),
    isMessageTruncated = _useState2[0],
    setIsMessageTruncated = _useState2[1],
    translator = useTranslator(),
    compareSize = function () {
      if (textElementRef.current) {
        var compare = textElementRef.current.scrollWidth > textElementRef.current.offsetWidth || textElementRef.current.scrollHeight > textElementRef.current.offsetHeight;
        setIsMessageTruncated(compare);
      }
    };
  useEffect(function () {
    return compareSize(), window.addEventListener("resize", compareSize), function () {
      window.removeEventListener("resize", compareSize);
    };
  }, []);
  var renderMessageAndLink = function (_ref2) {
      var _ref2$isTooltip = _ref2.isTooltip,
        isTooltip = void 0 !== _ref2$isTooltip && _ref2$isTooltip;
      return React.createElement(React.Fragment, null, translator(message), (null == linkProps ? void 0 : linkProps.children) && ((null == linkProps ? void 0 : linkProps.to) || (null == linkProps ? void 0 : linkProps.href) || (null == linkProps ? void 0 : linkProps.onClick)) && React.createElement(React.Fragment, null, " ", React.createElement(Link, _extends({}, linkProps, {
        textClassName: cx(isTooltip && styles.linkSize),
        activeClassName: cx(isTooltip && styles.activeLink)
      }))));
    },
    renderContents = function () {
      return React.createElement("div", {
        role: isTooltipEnabled ? "tooltip" : void 0,
        ref: textElementRef,
        className: cx(_defineProperty({}, styles.tooltipContainer, isTooltipEnabled))
      }, translator(title), renderMessageAndLink({}));
    },
    otherProps = {
      content: renderMessageAndLink({
        isTooltip: !0
      })
    };
  return message && React.createElement(Tooltip, _extends({
    id: "notification-tooltip-".concat(id),
    trigger: isMessageTruncated ? "mouseenter" : "",
    placement: placementOptions[8]
  }, otherProps), renderContents()) || renderContents();
};
InlineNotificationTooltip.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "InlineNotificationTooltip",
  props: {
    title: {
      required: !0,
      tsType: {
        name: "ReactReactElement",
        raw: "React.ReactElement"
      },
      description: ""
    },
    message: {
      required: !0,
      tsType: {
        name: "IntlMessageShape"
      },
      description: ""
    },
    id: {
      required: !0,
      tsType: {
        name: "string"
      },
      description: ""
    },
    linkProps: {
      required: !1,
      tsType: {
        name: "LinkProps"
      },
      description: ""
    },
    isTooltipEnabled: {
      required: !1,
      tsType: {
        name: "boolean"
      },
      description: ""
    }
  }
}, InlineNotificationTooltip.__docgenInfo = {
  componentName: "InlineNotificationTooltip",
  packageName: "@jutro/components",
  description: "",
  displayName: "InlineNotificationTooltip",
  methods: [],
  actualName: "InlineNotificationTooltip"
};