import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { LocaleContext, getNativeLocaleLanguage, useTranslator, useLanguage } from "@jutro/locale";
import { DropdownMenuButton } from "../DropdownMenu/DropdownMenuButton";
import { DropdownMenuLink } from "../DropdownMenu/DropdownMenuLink";
import { useI18nDropdown, getAvailableLanguages } from "../GlobalizationChooser/useI18nDropdown";
import { messages } from "./LanguageSelector.messages";
import styles from "./LanguageSelector.module.css";
var languageSelectorPropTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  toggleClassName: PropTypes.string,
  languageValue: PropTypes.string,
  skipPropagation: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideDropdownIndicator: PropTypes.bool,
  availableLanguageValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  renderLanguageLabel: PropTypes.func,
  onLanguageValueChange: PropTypes.func
};
export var LanguageSelector = function (_ref) {
  var _context,
    _context2,
    id = _ref.id,
    className = _ref.className,
    controlClassName = _ref.controlClassName,
    toggleClassName = _ref.toggleClassName,
    languageValue = _ref.languageValue,
    availableLanguageValues = _ref.availableLanguageValues,
    _ref$renderLanguageLa = _ref.renderLanguageLabel,
    renderLanguageLabel = void 0 === _ref$renderLanguageLa ? getNativeLocaleLanguage : _ref$renderLanguageLa,
    onLanguageValueChange = _ref.onLanguageValueChange,
    skipPropagation = _ref.skipPropagation,
    hideDropdownIndicator = _ref.hideDropdownIndicator,
    hideLabel = _ref.hideLabel,
    translator = useTranslator(),
    _useLanguage = useLanguage(),
    language = _useLanguage.language,
    availableLanguages = _useLanguage.availableLanguages,
    languageOnChangeCallback = _useLanguage.languageOnChangeCallback,
    localeOnChangeCallback = useContext(LocaleContext).localeOnChangeCallback,
    dropdownProps = useI18nDropdown({
      value: languageValue || language,
      availableValues: getAvailableLanguages(availableLanguageValues, availableLanguages),
      renderValueLabel: renderLanguageLabel,
      skipPropagation: skipPropagation,
      onValueChange: function (newLanguage) {
        var defaultLocale = newLanguage;
        !skipPropagation && defaultLocale && (null == localeOnChangeCallback || localeOnChangeCallback(defaultLocale)), onLanguageValueChange && onLanguageValueChange({
          language: newLanguage,
          defaultLocale: defaultLocale
        });
      },
      serviceCallback: languageOnChangeCallback
    }),
    currentLanguageLabel = renderLanguageLabel(dropdownProps.value),
    ariaLabel = _concatInstanceProperty(_context = "".concat(translator(messages.languageSelectorLabel), ": ")).call(_context, currentLanguageLabel),
    toggleButtonClasses = cx(styles.toggleButton, hideDropdownIndicator && styles.hideDropdownIndicator, toggleClassName);
  return React.createElement(DropdownMenuButton, {
    id: id,
    buttonText: hideLabel ? void 0 : currentLanguageLabel,
    icon: "gw-translate",
    className: className,
    toggleButtonClassName: toggleButtonClasses,
    "aria-label": ariaLabel,
    menuClassName: controlClassName
  }, _mapInstanceProperty(_context2 = dropdownProps.availableValues).call(_context2, function (item) {
    var _context3;
    return React.createElement(DropdownMenuLink, {
      key: item.id,
      lang: _sliceInstanceProperty(_context3 = item.id).call(_context3, 0, 2),
      onClick: function () {
        dropdownProps.onValueChange(item.id);
      }
    }, item.displayName);
  }));
};
LanguageSelector.propTypes = languageSelectorPropTypes, LanguageSelector.__docgenInfo = {
  description: "Language Selector Component\n\n@metadataType element",
  methods: [],
  displayName: "LanguageSelector",
  props: {
    renderLanguageLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Method for rendering language label based on language code"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Id of component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Css class passed to container"
    },
    controlClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Css class passed to control element"
    },
    toggleClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Css class passed to toggle button"
    },
    languageValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default language value (uses language from LanguageContext if this value is not provided)"
    },
    skipPropagation: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true - prevents updating LocalizationService - just triggers onValueChange"
    },
    hideLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides button label with currently selected language"
    },
    hideDropdownIndicator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides dropdown indicator"
    },
    availableLanguageValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "List of available languages to select (uses languages from LanguageContext if this list is not provided)"
    },
    onLanguageValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback triggered on language value change"
    }
  }
}, LanguageSelector.__docgenInfo = {
  componentName: "LanguageSelector",
  packageName: "@jutro/components",
  description: "Language Selector Component",
  displayName: "LanguageSelector",
  methods: [],
  actualName: "LanguageSelector",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Id of component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Css class passed to container"
    },
    controlClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Css class passed to control element"
    },
    toggleClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Css class passed to toggle button"
    },
    languageValue: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default language value (uses language from LanguageContext if this value is not provided)"
    },
    skipPropagation: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true - prevents updating LocalizationService - just triggers onValueChange"
    },
    hideLabel: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides button label with currently selected language"
    },
    hideDropdownIndicator: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hides dropdown indicator"
    },
    availableLanguageValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: !1,
      description: "List of available languages to select (uses languages from LanguageContext if this list is not provided)"
    },
    renderLanguageLabel: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Method for rendering language label based on language code",
      defaultValue: {
        value: "getNativeLocaleLanguage",
        computed: !0
      }
    },
    onLanguageValueChange: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Callback triggered on language value change"
    }
  }
};