import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import { getConfigValue } from "@jutro/config";
import { datadogLogs } from "@datadog/browser-logs";
import loadDataDog from "../dataDog";
import { subscribe } from "../EventProvider";
import JUTRO_TOPICS from "../jutroTopics";
export default function () {
  var datadogSever,
    clientToken = getConfigValue("JUTRO_DATA_DOG_CLIENT_TOKEN"),
    dataDogServiceName = getConfigValue("JUTRO_DATA_DOG_SERVICE_NAME"),
    dataDogEnv = getConfigValue("JUTRO_DATA_DOG_ENV"),
    dataDogAppVersion = getConfigValue("JUTRO_DATA_DOG_APP_VERSION"),
    dataDogLogLevel = getConfigValue("JUTRO_DATA_DOG_FULL_LOG"),
    dataDogSite = getConfigValue("JUTRO_DATA_DOG_SITE");
  if (clientToken) {
    if ("EU" === dataDogSite) datadogSever = "datadoghq.eu";else datadogSever = "datadoghq.com";
    loadDataDog(clientToken, dataDogServiceName, dataDogEnv, dataDogAppVersion, datadogSever);
    var UNKNOWN_ERROR = JUTRO_TOPICS.UNKNOWN_ERROR,
      FETCH_ERROR = JUTRO_TOPICS.FETCH_ERROR,
      FETCH_REQUEST = JUTRO_TOPICS.FETCH_REQUEST,
      FETCH_RESPONSE = JUTRO_TOPICS.FETCH_RESPONSE,
      VALUE_CHANGED = JUTRO_TOPICS.VALUE_CHANGED,
      FIELD_BLUR = JUTRO_TOPICS.FIELD_BLUR,
      BUTTON_CLICKED = JUTRO_TOPICS.BUTTON_CLICKED,
      LINK_CLICKED = JUTRO_TOPICS.LINK_CLICKED;
    subscribe([UNKNOWN_ERROR, FETCH_ERROR], function (event, topic) {
      datadogLogs.logger.error(_JSON$stringify(event), {
        sourceCategory: topic
      });
    }), subscribe([FETCH_REQUEST, FETCH_RESPONSE], function (event, topic) {
      datadogLogs.logger.info(_JSON$stringify(event), {
        sourceCategory: topic
      });
    }), dataDogLogLevel && (subscribe([VALUE_CHANGED, FIELD_BLUR], function (event, topic) {
      datadogLogs.logger.info(_JSON$stringify(event), {
        sourceCategory: topic
      });
    }), subscribe([BUTTON_CLICKED, LINK_CLICKED], function (event, topic) {
      datadogLogs.logger.info(_JSON$stringify(event), {
        sourceCategory: topic
      });
    }));
  }
}