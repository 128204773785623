import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["id", "renderTrigger", "align", "isFlipEnabled", "onClosed"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import React from "react";
import PropTypes from "prop-types";
import { intlMessageShape, linkShape } from "@jutro/prop-types";
import { PopoverContainer } from "../PopoverContainer/PopoverContainer";
import styles from "./NotificationAction.module.css";
import { Popover } from "../Popover/Popover";
import { popperPlacement } from "../Popper/types";
var notificationActionPropTypes = {
  id: PropTypes.string.isRequired,
  title: intlMessageShape,
  headerLink: linkShape,
  footerLink: linkShape,
  className: PropTypes.string,
  children: PropTypes.node,
  renderTrigger: PropTypes.func.isRequired,
  align: PropTypes.oneOf(popperPlacement),
  isFlipEnabled: PropTypes.bool,
  onClosed: PropTypes.func
};
export var NotificationAction = function (_ref) {
  var id = _ref.id,
    renderTrigger = _ref.renderTrigger,
    align = _ref.align,
    isFlipEnabled = _ref.isFlipEnabled,
    onClosed = _ref.onClosed,
    popoverProps = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Popover, {
    id: id,
    renderTrigger: renderTrigger,
    align: align,
    className: styles.popper,
    isFlipEnabled: isFlipEnabled,
    onClosed: onClosed
  }, React.createElement(PopoverContainer, _objectSpread({
    className: styles.popoverContainer,
    internalClassNames: {
      title: styles.popoverTitle,
      headerLink: styles.popoverHeaderLink,
      body: styles.popoverBody,
      footerLink: styles.popoverFooterLink
    }
  }, popoverProps)));
};
NotificationAction.propTypes = notificationActionPropTypes, NotificationAction.defaultProps = _objectSpread({}, Popover.defaultProps), NotificationAction.__docgenInfo = {
  description: "NotificationAction\n@type {React.FC<PropTypes.InferProps<typeof notificationActionPropTypes>>}\n\n@metadataType action",
  methods: [],
  displayName: "NotificationAction",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify this component."
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Notification popover title"
    },
    headerLink: {
      type: {
        name: "custom",
        raw: "linkShape"
      },
      required: !1,
      description: "Description for the header link"
    },
    footerLink: {
      type: {
        name: "custom",
        raw: "linkShape"
      },
      required: !1,
      description: "Description for the footer link"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Notification popover content"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    align: {
      type: {
        name: "enum",
        computed: !0,
        value: "popperPlacement"
      },
      required: !1,
      description: "Popover alignment (relative to trigger element)"
    },
    isFlipEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the popover flips when it is about to overflow the visible area"
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    }
  }
}, NotificationAction.__docgenInfo = {
  componentName: "NotificationAction",
  packageName: "@jutro/components",
  description: "NotificationAction",
  displayName: "NotificationAction",
  methods: [],
  actualName: "NotificationAction",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Used to identify this component."
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Notification popover title"
    },
    headerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          },
          onClick: {
            name: "func",
            required: !1
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Description for the header link"
    },
    footerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: !0
          },
          onClick: {
            name: "func",
            required: !1
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: !1
          }
        }
      },
      required: !1,
      description: "Description for the footer link"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Notification popover content"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "Function to render a trigger element. The trigger element takes three arguments:\n- An object of props for the component\n- An onCLick callback function\n- A boolean variable to control the state of visibility, like `isVisible`"
    },
    align: {
      type: {
        name: "enum",
        computed: !1,
        value: [{
          value: "'auto'",
          computed: !1
        }, {
          value: "'auto-end'",
          computed: !1
        }, {
          value: "'auto-start'",
          computed: !1
        }, {
          value: "'bottom'",
          computed: !1
        }, {
          value: "'bottom-end'",
          computed: !1
        }, {
          value: "'bottom-start'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }, {
          value: "'left-end'",
          computed: !1
        }, {
          value: "'left-start'",
          computed: !1
        }, {
          value: "'right'",
          computed: !1
        }, {
          value: "'right-end'",
          computed: !1
        }, {
          value: "'right-start'",
          computed: !1
        }, {
          value: "'top'",
          computed: !1
        }, {
          value: "'top-end'",
          computed: !1
        }, {
          value: "'top-start'",
          computed: !1
        }]
      },
      required: !1,
      description: "Popover alignment (relative to trigger element)"
    },
    isFlipEnabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, the popover flips when it is about to overflow the visible area"
    },
    onClosed: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function called when the notification disappears"
    }
  }
};