import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["children", "className", "contentClassName", "fluid"];
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import omit from "lodash/omit";
import styles from "./Main.module.css";
import { Container } from "../container/Container";
var mainPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fluid: PropTypes.bool,
  contentClassName: PropTypes.string
};
export var Main = function (_ref) {
  var children = _ref.children,
    className = _ref.className,
    contentClassName = _ref.contentClassName,
    fluid = _ref.fluid,
    other = _objectWithoutProperties(_ref, _excluded);
  return React.createElement("main", _extends({
    tabIndex: -1,
    className: cx(styles.main, className)
  }, omit(other, "dangerouslySetInnerHTML")), React.createElement(Container, {
    className: cx(styles.container, contentClassName),
    fluid: fluid
  }, children));
};
Main.propTypes = mainPropTypes, Main.__docgenInfo = {
  description: "Main content of the page.",
  methods: [],
  displayName: "Main",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for component."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The container's content."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Span the entire width."
    },
    contentClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for content"
    }
  }
}, Main.__docgenInfo = {
  componentName: "Main",
  packageName: "@jutro/components",
  description: "Main content of the page.",
  displayName: "Main",
  methods: [],
  actualName: "Main",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for component."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The container's content."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Span the entire width."
    },
    contentClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for content"
    }
  }
};