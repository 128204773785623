import { defineMessages } from "react-intl";
export var messages = defineMessages({
  required: {
    id: "jutro-validation.inputs.This is a required field",
    defaultMessage: "This is a required field"
  },
  requiredWithLabel: {
    id: "jutro-validation.inputs.With label.is a required field",
    defaultMessage: "{label} is a required field"
  },
  minValue: {
    id: "jutro-validation.inputs.The minimum allowed value is",
    defaultMessage: "The minimum allowed value is {value}"
  },
  maxValue: {
    id: "jutro-validation.inputs.The maximum allowed value is",
    defaultMessage: "The maximum allowed value is {value}"
  },
  email: {
    id: "jutro-validation.inputs.Not a valid email",
    defaultMessage: "Not a valid email"
  },
  alphanumeric: {
    id: "jutro-validation.inputs.Must be alphanumeric characters",
    defaultMessage: "Must be alphanumeric characters"
  },
  lowercase: {
    id: "jutro-validation.inputs.Must be lower case characters",
    defaultMessage: "Must be lower case characters"
  },
  uppercase: {
    id: "jutro-validation.inputs.Must be uppercase characters",
    defaultMessage: "Must be upper case characters"
  },
  url: {
    id: "jutro-validation.inputs.Not a valid url",
    defaultMessage: "Not a valid url"
  },
  regex: {
    id: "jutro-validation.inputs.Not a valid match",
    defaultMessage: "Not a valid match for {value}"
  },
  incompleteInput: {
    id: "jutro-validation.inputs.Incomplete input",
    defaultMessage: "Incomplete input"
  },
  invalidPhone: {
    id: "jutro-validation.inputs.The provided phone number is not valid",
    defaultMessage: "The provided phone number is not valid"
  },
  maxFileNameLengthMessage: {
    id: "jutro-validation.inputs.Filename cannot exceed characters",
    defaultMessage: "Filename cannot exceed {value} characters"
  },
  maxFileSizeKBMessage: {
    id: "jutro-validation.inputs.File cannot exceed KB in size",
    defaultMessage: "File cannot exceed {value}KB in size"
  },
  incorrectFileTypeMessage: {
    id: "jutro-validation.inputs.File type not supported",
    defaultMessage: "File type not supported"
  },
  maxDate: {
    id: "jutro-validation.inputs.DatePicker.maxDate",
    defaultMessage: "The latest allowed date is {maxDate}"
  },
  minDate: {
    id: "jutro-validation.inputs.DatePicker.minDate",
    defaultMessage: "The earliest allowed date is {minDate}"
  },
  invalidDate: {
    id: "jutro-validation.inputs.DatePicker.invalidDate",
    defaultMessage: "The input is not a valid date"
  },
  uploadError: {
    id: "jutro-validation.inputs.FileUpload.uploadError",
    defaultMessage: "There was an error uploading your file"
  },
  minYear: {
    id: "jutro-validation.inputs.Year.The minimum allowed year is",
    defaultMessage: "The minimum allowed year is {year}"
  },
  maxYear: {
    id: "jutro-validation.inputs.Year.The maximum allowed year is",
    defaultMessage: "The maximum allowed year is {year}"
  },
  validatePhone: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.PhoneNumberIncorrect",
    defaultMessage: "Phone number is incorrect."
  },
  validatePhoneError0: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.IsPossible",
    defaultMessage: "Most likely the country does not allow phone numbers with these digits."
  },
  validatePhoneError1: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.InvalidCountryCode",
    defaultMessage: "Country code does not match any country."
  },
  validatePhoneError2: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.TooShort",
    defaultMessage: "The phone number is too short."
  },
  validatePhoneError3: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.TooLong",
    defaultMessage: "The phone number is too long."
  },
  validatePhoneError4: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.IsPossibleLocalOnly",
    defaultMessage: "Enter a number with characters specific to the country."
  },
  validatePhoneError5: {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.InvalidLength",
    defaultMessage: "Make sure you have a proper phone number for the selected country."
  },
  "validatePhoneError-99": {
    id: "jutro-validation.inputs.IntlPhoneNumberField.validatePhoneError.UnknownError",
    defaultMessage: "Unknown error."
  }
});