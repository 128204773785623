import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./TextHighlight.module.css";
export var TextHighlight = function (_ref) {
  var label = _ref.label,
    highlightParts = _ref.highlightParts,
    highlightClassName = _ref.highlightClassName,
    _ref$highlightTag = _ref.highlightTag,
    HighlightTag = void 0 === _ref$highlightTag ? "mark" : _ref$highlightTag,
    className = _ref.className,
    _ref$tag = _ref.tag,
    Tag = void 0 === _ref$tag ? "span" : _ref$tag;
  return React.createElement(Fragment, null, React.createElement("span", {
    className: styles.screenReaderLabel
  }, label), React.createElement(Tag, {
    className: className,
    "aria-hidden": !0
  }, _mapInstanceProperty(highlightParts).call(highlightParts, function (_ref2, index) {
    var start = _ref2.start,
      end = _ref2.end;
    return React.createElement(Fragment, {
      key: start
    }, 0 === index && _sliceInstanceProperty(label).call(label, 0, start), React.createElement(HighlightTag, {
      className: cx(styles.highlight, highlightClassName)
    }, _sliceInstanceProperty(label).call(label, start, end)), index !== highlightParts.length - 1 ? _sliceInstanceProperty(label).call(label, end, highlightParts[index + 1].start) : _sliceInstanceProperty(label).call(label, end));
  })));
};
TextHighlight.propTypes = {
  label: PropTypes.string.isRequired,
  highlightParts: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  }).isRequired).isRequired,
  highlightClassName: PropTypes.string,
  highlightTag: PropTypes.elementType,
  className: PropTypes.string,
  tag: PropTypes.elementType
}, TextHighlight.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "TextHighlight",
  props: {
    highlightTag: {
      defaultValue: {
        value: "'mark'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Tag, which will be used for highlighted parts.\nBy default, 'mark' element will be used."
    },
    tag: {
      defaultValue: {
        value: "'span'",
        computed: !1
      },
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Tag, which will be used for label wrapper.\nBy default, 'span' element will be used."
    },
    label: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Translated label whose parts will be highlighted"
    },
    highlightParts: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            start: {
              name: "number",
              required: !0
            },
            end: {
              name: "number",
              required: !0
            }
          }
        }
      },
      required: !0,
      description: "Array of indices that will be highlighted.\nEach item represent interval, which is closed from left side (start) and open from right side (end).\nFor instance, if label is 'abcdefghi' and highlightParts are [{ start: 1, end: 3 }, { start: 6, end: 7 }]\nhighlighted parts will be 'bc' and 'g'."
    },
    highlightClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Classname applied to highlight tags"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Classname applied to label wrapper tag"
    }
  }
}, TextHighlight.__docgenInfo = {
  componentName: "TextHighlight",
  packageName: "@jutro/components",
  description: "",
  displayName: "TextHighlight",
  methods: [],
  actualName: "TextHighlight",
  props: {
    label: {
      type: {
        name: "string"
      },
      required: !0,
      description: "Translated label whose parts will be highlighted"
    },
    highlightParts: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            start: {
              name: "number",
              required: !0
            },
            end: {
              name: "number",
              required: !0
            }
          }
        }
      },
      required: !0,
      description: "Array of indices that will be highlighted.\nEach item represent interval, which is closed from left side (start) and open from right side (end).\nFor instance, if label is 'abcdefghi' and highlightParts are [{ start: 1, end: 3 }, { start: 6, end: 7 }]\nhighlighted parts will be 'bc' and 'g'."
    },
    highlightClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Classname applied to highlight tags"
    },
    highlightTag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Tag, which will be used for highlighted parts.\nBy default, 'mark' element will be used.",
      defaultValue: {
        value: "'mark'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Classname applied to label wrapper tag"
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: !1,
      description: "Tag, which will be used for label wrapper.\nBy default, 'span' element will be used.",
      defaultValue: {
        value: "'span'",
        computed: !1
      }
    }
  }
};