import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["tag", "parentTag", "content", "children", "dangerouslySetInnerHTML"];
import React from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "@jutro/layout";
var responsiveElementPropTypes = {
  tag: PropTypes.string.isRequired,
  parentTag: PropTypes.string,
  children: PropTypes.node
};
export var ResponsiveElement = function (props) {
  var breakpointProps = useBreakpoint(props).breakpointProps,
    Tag = breakpointProps.tag,
    ParentTag = breakpointProps.parentTag,
    content = breakpointProps.content,
    children = breakpointProps.children,
    others = (breakpointProps.dangerouslySetInnerHTML, _objectWithoutProperties(breakpointProps, _excluded));
  return ParentTag ? React.createElement(ParentTag, _extends({
    tag: Tag
  }, others), content || children) : React.createElement(Tag, others, content || children);
};
ResponsiveElement.propTypes = responsiveElementPropTypes, ResponsiveElement.__docgenInfo = {
  description: "ResponsiveElement\n@type {React.FC<PropTypes.InferProps<typeof responsiveElementPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "ResponsiveElement",
  props: {
    tag: {
      type: {
        name: "string"
      },
      required: !0,
      description: "The html tag to use when rendering the translated message"
    },
    parentTag: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The html tag to use when rendering the translated message"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: ""
    }
  }
}, ResponsiveElement.__docgenInfo = {
  componentName: "ResponsiveElement",
  packageName: "@jutro/components",
  description: "ResponsiveElement",
  displayName: "ResponsiveElement",
  methods: [],
  actualName: "ResponsiveElement",
  metadataType: "element",
  props: {
    tag: {
      type: {
        name: "string"
      },
      required: !0,
      description: "The html tag to use when rendering the translated message"
    },
    parentTag: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The html tag to use when rendering the translated message"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: ""
    }
  }
};