import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import React from "react";
import cx from "classnames";
import { GenericSelectComponents as components } from "../../GenericSelectControl/GenericSelectControl";
export var LookupControl = function (props) {
  var customStyles = props.selectProps.customStyles,
    isFocused = props.isFocused;
  return React.createElement(components.Control, _extends({}, props, {
    className: cx(_defineProperty({}, customStyles.controlFocused, customStyles.controlFocused && isFocused), customStyles.control)
  }));
};
LookupControl.__docgenInfo = {
  description: "",
  methods: [],
  displayName: "LookupControl"
}, LookupControl.__docgenInfo = {
  componentName: "LookupControl",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupControl",
  methods: [],
  actualName: "LookupControl"
};