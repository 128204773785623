var _context;
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { IntlContext, useTranslator } from "@jutro/locale";
import { LiveRegion } from "../../accessibility/LiveRegion/LiveRegion";
import { InfoLabel } from "../InfoLabel/InfoLabel";
import styles from "./Badge.module.css";
var availableInfoLabelTypes = ["success", "error", "warning", "info", "neutral"];
export var availableBadgeTypes = _concatInstanceProperty(_context = []).call(_context, availableInfoLabelTypes, ["notification", "primary", "inverse"]);
export var defaultBadgeType = "notification";
export var Badge = function (_ref) {
  var value = _ref.value,
    maxValue = _ref.maxValue,
    className = _ref.className,
    id = _ref.id,
    type = _ref.type,
    liveRegionContext = _ref.liveRegionContext,
    formatNumber = (useContext(IntlContext) || {}).formatNumber,
    translator = useTranslator(),
    badgeType = type && _includesInstanceProperty(availableBadgeTypes).call(availableBadgeTypes, type) ? type : defaultBadgeType,
    infoLabelType = badgeType && function (type) {
      return availableInfoLabelTypes.some(function (infoLabelType) {
        return infoLabelType === type;
      });
    }(badgeType) ? badgeType : void 0,
    badgeContent = function (value, maxValue, formatNumber) {
      if (formatNumber) return maxValue && value > maxValue ? "".concat(formatNumber(maxValue), "+") : formatNumber(value);
    }(value, maxValue, formatNumber),
    classes = cx(styles.badge, styles[badgeType], className),
    liveRegionContextClass = styles.liveRegionContext;
  return React.createElement(LiveRegion, {
    context: translator(liveRegionContext),
    contextClass: liveRegionContextClass
  }, React.createElement(InfoLabel, {
    id: id,
    type: infoLabelType,
    className: classes
  }, badgeContent));
};
Badge.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(availableBadgeTypes),
  liveRegionContext: PropTypes.string
}, Badge.defaultProps = {
  type: defaultBadgeType
}, Badge.__docgenInfo = {
  description: 'Displays a "badge" element with child components, such as text or images.\n\n@type {React.FC<PropTypes.InferProps<typeof badgePropTypes>>}\n\n@metadataType element',
  methods: [],
  displayName: "Badge",
  props: {
    type: {
      defaultValue: {
        value: "'notification'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'info'",
          computed: !1
        }, {
          value: "'neutral'",
          computed: !1
        }, {
          value: "'notification'",
          computed: !1
        }, {
          value: "'primary'",
          computed: !1
        }, {
          value: "'inverse'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set custom flavor (success, general, neutral, etc.)",
      tsType: {
        name: "union",
        raw: "InfoLabelType | 'notification' | 'primary' | 'inverse'",
        elements: [{
          name: "InfoLabelType"
        }, {
          name: "literal",
          value: "'notification'"
        }, {
          name: "literal",
          value: "'primary'"
        }, {
          name: "literal",
          value: "'inverse'"
        }]
      }
    },
    value: {
      type: {
        name: "number"
      },
      required: !0,
      description: "The value of the Badge",
      tsType: {
        name: "number"
      }
    },
    maxValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The maximum value for the badge\nif value prop is greater than `maxValue`, then the Badge will display 'maxValue+'",
      tsType: {
        name: "number"
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component",
      tsType: {
        name: "string"
      }
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component unique identifier",
      tsType: {
        name: "string"
      }
    },
    liveRegionContext: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to describe the live region for assistive technologies (e.g., Unread Emails)",
      tsType: {
        name: "string"
      }
    }
  }
}, Badge.__docgenInfo = {
  componentName: "Badge",
  packageName: "@jutro/components",
  description: 'Displays a "badge" element with child components, such as text or images.',
  displayName: "Badge",
  methods: [],
  actualName: "Badge",
  metadataType: "element",
  props: {
    value: {
      type: {
        name: "number"
      },
      required: !0,
      description: "The value of the Badge"
    },
    maxValue: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The maximum value for the badge\nif value prop is greater than `maxValue`, then the Badge will display 'maxValue+'"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    },
    id: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Component unique identifier"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: !1
        }, {
          value: "'error'",
          computed: !1
        }, {
          value: "'warning'",
          computed: !1
        }, {
          value: "'info'",
          computed: !1
        }, {
          value: "'neutral'",
          computed: !1
        }, {
          value: "'notification'",
          computed: !1
        }, {
          value: "'primary'",
          computed: !1
        }, {
          value: "'inverse'",
          computed: !1
        }]
      },
      required: !1,
      description: "Phrase allowing to set custom flavor (success, general, neutral, etc.)",
      defaultValue: {
        value: "'notification'",
        computed: !1
      }
    },
    liveRegionContext: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Used to describe the live region for assistive technologies (e.g., Unread Emails)"
    }
  }
};